/**
 * CisionPressReleaseListPage
 * @module pages/CisionPressReleaseListPage
 */

import { useReducer, useEffect, useState, useRef } from 'react';
import {
	composeQueryString,
	renderElementBasedOnEditMode,
	scrollToTop,
	setUrlLocation,
} from 'common/helpers';
import Breadcrumbs from 'components/framework/Breadcrumbs';
import { usePressRelease } from 'api';
import RichText from 'components/ui/RichText';
import {
	Fieldset,
	Legend,
	Label,
	DefaultCheckbox,
	SquareCheckIcon,
	SquareIcon,
	Span,
	SelectWrapper,
	SelectLabel,
	Select,
	ContentWrapper,
	LeftContentWrapper,
	RightContentWrapper,
	PagerWrapper,
} from './CisionPressReleaseListPage.styles';
import Layout from 'components/ui/Layout';
import { NewsList, NewsListItem } from 'components/common/NewsList';
import { useMedia } from 'hooks';
import listFilterReducer from 'reducers/listFilterReducer';
import {
	CisionPressReleaseItemModel,
	CisionPressreleaseListPageModel,
} from 'types/page-types';

import { FacetItemModel, FacetsItemModel } from 'types/filter-types';

const CisionPressReleaseListPage = (props: CisionPressreleaseListPageModel) => {
	const {
		heading,
		text,
		isInEditMode,
		breadcrumbs,
		items,
		pagination,
		filter,
	} = props;
	const isMobile = useMedia('(max-width: 490px)');
	const mobileView = useMedia('(max-width: 768px)');
	const tabletView = useMedia('(max-width: 890px)');
	const [query, setQuery] = useState<string | null>(null);
	const preventHistoryEntry = useRef(false);
	let didRender = useRef(false);
	const [response, error, loading] = usePressRelease(
		query,
		window && window.location ? window.location.pathname : null
	);

	// Handle filter, query and pagination.
	const [state, dispatch] = useReducer(listFilterReducer, {
		sortBy: filter.sortBy,
		display: pagination.display,
		query: filter.query,
		currentPage: pagination.currentPage,
		facets: filter.facets as FacetsItemModel[],
	});

	useEffect(() => {
		const hasRendered = didRender.current;
		const queryString = composeQueryString(
			state.query,
			state.sortBy,
			state.display,
			state.currentPage,
			state.facets
		);
		// Only set the query to fetch new data if it's not the first render.
		didRender.current
			? setQuery(queryString ? queryString : null)
			: (didRender.current = !didRender.current);
		if (queryString !== query) {
			setUrlLocation(
				queryString,
				state.query,
				state.sortBy,
				state.display,
				state.currentPage,
				state.facets,
				null,
				hasRendered,
				preventHistoryEntry.current
			);
		}
	}, [query, state]);

	const handleFacet = (
		facetId: string,
		itemId: string,
		value: any,
		isSelect = false
	) => {
		const facet = state.facets?.find((f: FacetsItemModel) => f.id === facetId);
		const item = facet?.items.find((i: FacetItemModel) => i.id === itemId);
		if (isSelect) {
			facet?.items.forEach((item: FacetItemModel) => {
				item.active = false;
			});
		}
		item && (item.active = value);

		dispatch({ type: 'FACETS', facets: state.facets ?? [], currentPage: 1 });
	};

	const handlePagination = (value: number) => {
		scrollToTop(tabletView ? 0 : 800);
		dispatch({ type: 'CURRENT_PAGE', currentPage: value });
	};

	const itemArray = response?.items || items;
	const paginationObject = response?.pagination || pagination;
	return (
		<main id="main-content">
			<Layout backgroundColor="white">
				{breadcrumbs && <Breadcrumbs links={breadcrumbs} />}
				<LeftContentWrapper>
					{heading &&
						renderElementBasedOnEditMode(
							isInEditMode,
							'heading',
							'h1',
							{
								className:
									'font-standard mb-6 mt-8 font-normal text-h2 md:text-h1 text-blue',
							},
							heading
						)}
					{mobileView && text && (
						<RichText name="text" text={text} isInEditMode={isInEditMode} />
					)}
				</LeftContentWrapper>
				<ContentWrapper>
					<RightContentWrapper>
						<form>
							{filter &&
								filter.facets &&
								filter.facets.length > 0 &&
								filter.facets.map((facet, index) => {
									return (
										<div key={index}>
											{facet.type === 'checkbox' ? (
												<Fieldset aria-live="polite">
													{facet.name && <Legend>{facet.name}</Legend>}
													{facet.items &&
														facet.items.length > 0 &&
														facet.items.map((item, key) => (
															<Label htmlFor={item.id} key={key}>
																<DefaultCheckbox
																	type="checkbox"
																	value={item.id}
																	name={item.name}
																	id={item.id}
																	checked={item.active}
																	onChange={() => {
																		handleFacet(
																			facet.id,
																			item.id,
																			!item.active
																		);
																	}}
																/>
																<SquareIcon aria-hidden={true} />
																<SquareCheckIcon aria-hidden={true} />
																<Span>{item.name}</Span>
															</Label>
														))}
												</Fieldset>
											) : facet.type === 'dropdown' ? (
												<SelectWrapper>
													{facet.name && (
														<SelectLabel>{facet.name}</SelectLabel>
													)}
													{facet.items && facet.items.length > 0 && (
														<Select
															onChange={(e) => {
																handleFacet(
																	facet.id,
																	e.target.value,
																	true,
																	true
																);
															}}
														>
															{facet.items.map((item, key) => (
																<option
																	value={item.id}
																	selected={item.active}
																	key={key}
																>
																	{item.name}
																</option>
															))}
														</Select>
													)}
												</SelectWrapper>
											) : (
												false
											)}
										</div>
									);
								})}
						</form>
					</RightContentWrapper>
					<LeftContentWrapper>
						{!mobileView && text && (
							<RichText name="text" text={text} isInEditMode={isInEditMode} />
						)}
						{itemArray && itemArray.length > 0 && (
							<NewsList borderTop={true}>
								{itemArray
									.slice(0, pagination.display)
									.map((item: CisionPressReleaseItemModel, index: number) => (
										<NewsListItem
											key={index}
											category={item.category}
											date={item.date}
											title={item.text}
											url={item.link}
										/>
									))}
							</NewsList>
						)}
						<PagerWrapper
							nrOfPages={
								paginationObject.pageCount ? paginationObject.pageCount : 0
							}
							currentPage={
								paginationObject.currentPage ? paginationObject.currentPage : 0
							}
							onPageChange={handlePagination}
							total={!error && !loading ? paginationObject.total : 0}
							isMobile={isMobile}
						/>
					</LeftContentWrapper>
				</ContentWrapper>
			</Layout>
		</main>
	);
};

export default CisionPressReleaseListPage;
