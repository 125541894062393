import styled from 'styled-components';
import { greyDarker, blue, white } from 'theme/colors';
import { Paragraph } from 'components/ui/Typography';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { fontWeightLight } from 'theme/typography';

export const Expander = styled.div`
	display: flex;
	padding: 0.1rem 0;
	position: relative;

	&::before {
		display: inline-block;
		position: absolute;
		content: '';
		height: 0.1rem;
		width: 100%;
		background-color: ${greyDarker};
		top: 1.3rem;
	}
`;

export const Div = styled.div`
	margin: 0 auto;
	z-index: 100;
	padding: 0 1rem;
	background: ${white};
`;

export const ShowMore = styled(Paragraph)`
	font-weight: ${fontWeightLight};
	margin: 0 0.2rem 0 0;
	color: ${blue};
	display: inline-block;
`;

export const IconDown = styled(FiChevronDown)`
	vertical-align: middle;
	color: ${blue};
	font-size: 1.6rem;
`;

export const IconUp = styled(FiChevronUp)`
	vertical-align: middle;
	color: ${blue};
	font-size: 1.6rem;
`;

export const Link = styled.a`
	text-decoration: none;

	&:hover ${ShowMore} {
		text-decoration: underline;
	}
`;
