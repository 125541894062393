export default ({ className, ...htmlAttributes }: { className?: string }) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		stroke="currentColor"
		strokeWidth="2"
		strokeLinecap="round"
		strokeLinejoin="round"
		className={className}
		{...htmlAttributes}
	>
		<path
			d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
			fill="currentColor"
		></path>
		<circle cx="12" cy="7" r="4" fill="currentColor"></circle>
	</svg>
);
