/**
 * ProductDetailPage
 * @module pages/ProductDetailPage
 */

import { useFilterSearch } from 'api';
import clsx from 'clsx';
import {
	composeQueryString,
	renderElementBasedOnEditMode,
	setUrlLocation,
} from 'common/helpers';
import Breadcrumbs from 'components/framework/Breadcrumbs';
import FilterFieldSet from 'components/framework/FilterFieldSet';
import { LocalizationContext } from 'context/localization.context';
import Icon from 'components/ui/Icon';
import Layout from 'components/ui/Layout/Layout';
import { usePopState } from 'hooks/usePopState';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useReducer } from 'react';
import listFilterReducer from 'reducers/listFilterReducer';
import {
	FacetItemModel,
	FacetsItemModel,
	FilterStateAction,
} from 'types/filter-types';

import { DocumentSearchPageModel } from 'types/page-types';

import { SearchDocumentModel } from 'types/common';

import Button from 'components/ui/Button';
import { iconTypes } from 'components/ui/Icon/Icon';
import { Cell, Grid } from 'components/ui/Grid';
import ContentArea from 'components/framework/ContentArea';
import RichText from 'components/ui/RichText';

const DocumentSearchPage = ({
	breadcrumbs,
	pagination,
	documents,
	filter,
	heading,
	preamble,
	text,
	contentArea,
}: DocumentSearchPageModel) => {
	const { t }: any = useContext(LocalizationContext);
	const [sortBy, setSortBy] = useState<string>('');
	// const [showMoreActive, setShowMoreActive] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState((filter && filter.query) || '');
	const [sortConfig, setSortConfig] = useState<any>({
		name: 'Desc',
		entryname: 'Desc',
		documenttype: 'Desc',
		documentformat: 'Desc',
	});
	const [expandedArray, setExpandedArray] = useState<any>([]);
	const [query, setQuery] = useState<string | null>(null);
	const [documentArray, setDocuments] = useState<any>(documents);
	const [response, error, loading] = useFilterSearch(
		query,
		window && window.location ? window.location.pathname : null,
		false,
		true
	);

	// Handle filter, query and pagination.
	const [state, dispatch] = useReducer(listFilterReducer, {
		sortBy: filter.sortBy,
		display: pagination.display,
		query: filter.query,
		currentPage: pagination.currentPage,
		group: filter.group,
		facets: filter.facets,
		sortDir: filter.sortDir,
	});

	const handleSort = (id: string, sortDir: string = 'Asc') => {
		setSortBy(id);
		dispatch({
			type: 'SORT_BY',
			sortBy: id,
			sortDir: sortDir,
		} as FilterStateAction);
		sortConfig[id] = sortDir === 'Asc' ? 'Desc' : 'Asc';
		setSortConfig(sortConfig);
	};

	let didRender = useRef(false);
	const preventHistoryEntry = useRef(false);

	const popState = usePopState(state);
	const popStateString = JSON.stringify(popState);
	useEffect(() => {
		if (popState) {
			dispatch({ type: 'SET_STATE', payload: popState });
			preventHistoryEntry.current = true;
		}
	}, [popState, popStateString]);

	useEffect(() => {
		const hasRendered = didRender.current;
		const queryString = composeQueryString(
			state.query,
			state.sortBy,
			state.display,
			state.currentPage,
			state.facets,
			state.group,
			state.sortDir
		);
		// Only set the query to fetch new data if it's not the first render.
		didRender.current
			? setQuery(queryString ? queryString : null)
			: (didRender.current = !didRender.current);
		if (queryString !== query) {
			setUrlLocation(
				queryString,
				state.query,
				state.sortBy,
				state.display,
				state.currentPage,
				state.facets,
				null,
				state.sortDir,
				hasRendered,
				preventHistoryEntry.current
			);
		}
	}, [query, state]);

	useEffect(() => {
		if (response && response?.documents) {
			setDocuments([...response?.documents]);
		}

		if (response && response.filter && response.filter.facets) {
			dispatch({ type: 'FACETS', facets: response.filter.facets });
		}

		if (response && response.filter && response.filter.query) {
			dispatch({ type: 'QUERY', query: response.filter.query });
		}
	}, [response]);

	const handleFacet = (
		facetId: string,
		itemId: string,
		value: any,
		isSelect = false
	) => {
		const facet = state.facets?.find((f: FacetsItemModel) => f.id === facetId);
		const item = facet?.items.find((i: FacetItemModel) => i.id === itemId);

		if (isSelect) {
			facet?.items.forEach((item: FacetItemModel) => {
				item.active = false;
			});
		}
		item && (item.active = value);
		dispatch({ type: 'FACETS', facets: state.facets ?? [], currentPage: 1 });
	};

	const handleShowMoreClick = (value: number) => {
		dispatch({ type: 'CURRENT_PAGE', currentPage: value });
	};

	const handleSearchOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);
	};

	const handleSearch = (value: string) => {
		if (state.query !== value) {
			dispatch({ type: 'QUERY', query: value });
		}
	};

	const paginationObject = response?.pagination || pagination;
	return (
		<main id="main-content">
			<Layout backgroundColor="white">
				{breadcrumbs && <Breadcrumbs links={breadcrumbs} />}
				<Grid padding={false}>
					<Cell span={12} desktop={8}>
						{heading &&
							renderElementBasedOnEditMode(
								true,
								'heading',
								'h1',
								{
									className:
										'font-standard mb-6 mt-8 font-normal text-h2 md:text-h1 text-blue',
								},
								heading
							)}
						{preamble &&
							renderElementBasedOnEditMode(
								true,
								'preamble',
								'p',
								{ className: 'mb-8 font-medium text-p' },
								preamble
							)}
					</Cell>
					<Cell span={12} desktop={8}>
						{text && (
							<RichText
								name="text"
								text={text}
								isInEditMode={true}
								className="mb-8"
							/>
						)}
					</Cell>
				</Grid>
				<div className="bg-grey p-4 mb-6">
					<label
						className="block border-blue border-b-1 mb-4 pb-2 text-blue text-p font-semibold"
						htmlFor="document-search-input"
					>
						{t('documentsearchpage/searchfordocument')}
					</label>
					<div className="relative">
						<Icon
							icon="search"
							className="text-blue absolute top-0 left-0 mt-3.5 ml-3.5 w-4 h-4 md:w-5 md:h-5"
						/>
						<input
							onKeyDown={(e) => {
								if (e.keyCode === 13 && handleSearch) {
									handleSearch && handleSearch(searchTerm);
								}
							}}
							value={searchTerm}
							onChange={(e) => handleSearchOnChange(e)}
							placeholder={t('searchpage/searchfieldplaceholder')}
							className={
								'pt-3 pb-3 pl-12 w-full md:pr-6 h-12 rounded border border-greyDark'
							}
							id="document-search-input"
						/>
					</div>
					<form>
						<fieldset>
							<legend className="block border-blue border-b-1 mb-4 pb-2 pt-6 w-full text-blue text-p font-semibold">
								{t('documentsearchpage/filtering')}
							</legend>
							{state.facets &&
								state.facets.map((fieldset: FacetsItemModel, i: number) => (
									<React.Fragment key={i}>
										{fieldset.items.length > 0 && (
											<>
												{fieldset.type === 'dropdown' && (
													<div
														className={clsx(
															'w-full md:w-1/3 mb-4 md:mb-0 block md:inline-block',
															i + 1 > 3 && 'mt-4',
															(i + 1) % 3 !== 0 && 'md:pr-4'
														)}
													>
														{fieldset.name && (
															<label className="text-2xl leading-9 font-bold block mb-3">
																{fieldset.name}
															</label>
														)}
														{fieldset.items && fieldset.items.length > 0 && (
															<div className="relative">
																<select
																	className="w-full p-4 py-3 text-p appearance-none "
																	onChange={(
																		event: React.ChangeEvent<HTMLSelectElement>
																	): void =>
																		handleFacet(
																			fieldset.id,
																			event.target.value,
																			true,
																			true
																		)
																	}
																	onClick={() => {
																		const expandArray = expandedArray.find(
																			(ea: any) => ea === fieldset.id
																		)
																			? expandedArray.filter(
																					(i: any) => i !== fieldset.id
																			  )
																			: [...expandedArray, fieldset.id];
																		setExpandedArray(expandArray);
																	}}
																	onBlur={() =>
																		setExpandedArray(
																			expandedArray.filter(
																				(i: any) => i !== fieldset.id
																			)
																		)
																	}
																>
																	{fieldset.items.map(
																		(item: FacetItemModel, key: number) => (
																			<option
																				value={item.id}
																				selected={item.active}
																				key={key}
																			>
																				{item.name}
																			</option>
																		)
																	)}
																</select>

																<Icon
																	icon="chevron"
																	size={1}
																	direction={
																		expandedArray?.find(
																			(i: any) => i === fieldset.id
																		)
																			? 'down'
																			: 'up'
																	}
																	className="absolute top-5 right-5 pointer-events-none"
																/>
															</div>
														)}
													</div>
												)}
												{fieldset.type === 'checkbox' && (
													<FilterFieldSet
														alternateDisplay={true}
														className="mt-6 mb-6 smMax:mt-4 smMax:mb-4"
														name={fieldset.name}
														items={fieldset.items}
														facetId={fieldset.id}
														key={fieldset.name}
														defaultNumberToShow={6}
														onChange={handleFacet}
													/>
												)}
											</>
										)}
									</React.Fragment>
								))}
						</fieldset>
					</form>
				</div>

				{!loading && error && (
					<div className="my-6 mx-0 text-center w-full">
						<p className="text-p">{t('shared/filter/errormessage')}</p>
					</div>
				)}
				<div className="relative flex mb-10 w-full flex-col overflow-x-auto">
					<table className="border-b-1 border-grey">
						<tr className="text-left">
							<th
								onClick={() => handleSort('entryname', sortConfig.entryname)}
								className="border-r-1 border-grey bg-blue text-white text-p p-4 cursor-pointer relative hover:underline max-w-75"
							>
								{t('documentsearchpage/productvariant')}
								<Icon
									className="h-4 ml-1"
									icon={`sort${sortConfig.entryname}ending` as iconTypes}
								/>
							</th>
							<th
								onClick={() => handleSort('name', sortConfig.name)}
								className="border-r-1 border-grey bg-blue text-white text-p p-4 cursor-pointer relative hover:underline max-w-75"
							>
								{t('documentsearchpage/document')}
								<Icon
									className="h-4 ml-1"
									icon={`sort${sortConfig.name}ending` as iconTypes}
								/>
							</th>
							<th
								onClick={() =>
									handleSort('documenttype', sortConfig.documenttype)
								}
								className="border-r-1 border-grey bg-blue text-white text-p p-4 cursor-pointer relative hover:underline max-w-75"
							>
								{t('documentsearchpage/doctype')}
								<Icon
									className="h-4 ml-1"
									icon={`sort${sortConfig.documenttype}ending` as iconTypes}
								/>
							</th>
							<th className="border-r-1 border-grey bg-blue text-white text-p p-4">
								{t('documentsearchpage/size')}
							</th>
							<th
								onClick={() =>
									handleSort('documentformat', sortConfig.documentformat)
								}
								className="border-r-1 border-grey bg-blue text-white text-p p-4 cursor-pointer relative hover:underline max-w-75"
							>
								{t('documentsearchpage/format')}
								<Icon
									className="h-4 ml-1"
									icon={`sort${sortConfig.documentformat}ending` as iconTypes}
								/>
							</th>
							<th className="border-r-1 border-grey bg-blue text-white text-p p-4 max-w-75">
								{t('documentsearchpage/download')}
							</th>
						</tr>
						{!loading &&
							documentArray
								?.filter((sd: SearchDocumentModel) => sd !== undefined)
								.map((d: SearchDocumentModel, i: number) => (
									<tr className={clsx(i % 2 === 0 && 'bg-grey')} key={i}>
										<td className="border-r-1 border-grey text-p p-4 max-w-75 truncate">
											{d.entryName}
										</td>
										<td className="border-r-1 border-grey text-p p-4 max-w-75 truncate">
											<a
												href={d.downloadLink}
												className="text-blue font-medium"
												target={d.format === '.pdf' ? '_blank' : ''}
											>
												{d.name}
											</a>
										</td>
										<td className="border-r-1 border-grey text-p p-4 max-w-75 truncate">
											{d.type}
										</td>
										<td className="border-r-1 border-grey text-p p-4 max-w-75 truncate">
											{d.size}
										</td>
										<td className="border-r-1 border-grey text-p p-4 max-w-75 truncate">
											{d.format}
										</td>
										<td className="text-p text-blue text-center p-4 max-w-75 truncate">
											<a href={d.downloadLink} download>
												<Icon icon="download" />
												<span className="sr-only">{d.name}</span>
											</a>
										</td>
									</tr>
								))}
					</table>
					{loading && (
						<div className="my-6 mx-0 text-center w-full">
							<Icon icon="loader" animate="spin" size={2} />
						</div>
					)}
					<div className="w-full text-center">
						{paginationObject.pageCount > 1 &&
							paginationObject.currentPage < paginationObject.pageCount && (
								<Button
									className="w-40"
									type="button"
									buttonColor="orange"
									onClick={() =>
										handleShowMoreClick(paginationObject.currentPage + 1)
									}
								>
									{t('documentsearchpage/loadmore')}
								</Button>
							)}
					</div>
				</div>
			</Layout>
			<ContentArea contentArea={contentArea} />
		</main>
	);
};

export default DocumentSearchPage;
