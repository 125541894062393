import { useFetch } from './index.ts';

export const useFilterQuotations = ({ url, query, prevent = false }) => {
	if (process.env.NODE_ENV === 'development' && url) {
		url = 'http://localhost:4000/api/quotation-history';
	}
	return useFetch({
		url: `${url}?${query}`,
		responseDefault: {},
		method: 'post',
		disableCancel: true,
		preventRequest:
			query === null ||
			typeof query === 'undefined' ||
			url === null ||
			typeof url === 'undefined' ||
			prevent,
	});
};
