import React, { useState, useContext } from 'react';
import { LocalizationContext } from 'context/localization.context';

import {
	MilkrunWrapper,
	MilkRunToggleWrapper,
	MilkrunRichText,
	MilkrunText,
	MilkRunIcon,
} from './Milkrun.styles';
import {
	ToggleIcon,
	ToggleButton,
	IconWrapper,
} from '../Contacts/Contacts.styles';

const Milkrun = ({ milkRun }) => {
	const { t } = useContext(LocalizationContext);
	const [milkRunOpen, setMilkRunOpen] = useState(false);

	const toggleMilkrun = () => {
		setMilkRunOpen(!milkRunOpen);
	};

	return (
		<MilkrunWrapper padTop={milkRunOpen}>
			<MilkrunRichText
				text={milkRun}
				open={milkRunOpen}
				aria-hidden={!milkRunOpen}
			/>
			<MilkRunToggleWrapper isOpen={milkRunOpen}>
				<ToggleButton onClick={toggleMilkrun}>
					<IconWrapper aria-hidden>
						<MilkRunIcon aria-hidden />
					</IconWrapper>
					<MilkrunText>
						{milkRunOpen
							? t('contactpage/hidemilkrun')
							: t('contactpage/showmilkrun')}
					</MilkrunText>
					<ToggleIcon isOpen={milkRunOpen} />
				</ToggleButton>
			</MilkRunToggleWrapper>
		</MilkrunWrapper>
	);
};

export default Milkrun;
