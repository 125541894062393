/**
 * MyPagesMenuDropdown
 * @module components/MyPagesMenuDropdown
 */

import React, { useState, useContext } from 'react';
import { LocalizationContext } from 'context/localization.context';

import clsx from 'clsx';
import { LinkModel } from 'types';

interface Props {
	myPagesLink: LinkModel;
	logoutLink: string;
}

const MyPagesMenuDropdown: React.FC<Props> = ({ myPagesLink, logoutLink }) => {
	const { t }: any = useContext(LocalizationContext);
	const [myPagesDropdownOpen, setMyPagesDropdownOpen] = useState(false);

	return (
		<div
			className="relative"
			onMouseLeave={(e) => {
				setMyPagesDropdownOpen(false);
			}}
			onMouseEnter={(e) => {
				setMyPagesDropdownOpen(true);
			}}
		>
			<a
				className={clsx(
					'relative inline-block text-white font-semibold uppercase text-p py-6 px-4 mr-10 ml-auto whitespace-no-wrap',
					'hover:underline'
				)}
				href={myPagesLink?.link}
				onKeyDown={(e: any) => {
					if (e.keyCode === 13) {
						e.preventDefault();
						if (myPagesDropdownOpen && window && window.location) {
							window.location.href = myPagesLink?.link;
						} else {
							setMyPagesDropdownOpen(true);
						}
					}
				}}
				onClick={(e: any) => {
					if (window.Modernizr && window.Modernizr.touchevents) {
						if (
							!myPagesDropdownOpen ||
							(myPagesDropdownOpen && e.target.tagName !== 'A')
						) {
							e.preventDefault();
						}
						setMyPagesDropdownOpen(true);
					}
				}}
			>
				{myPagesLink?.text}
			</a>
			{logoutLink && (
				<div
					className={clsx(
						'my-page-dropdown absolute w-full bg-white border border-greyDarker z-300',
						myPagesDropdownOpen ? 'h-auto visible' : 'hidden h-0'
					)}
				>
					<ul className="p-2">
						<li>
							<a
								href={logoutLink}
								className="text-p text-black m-0 p-0 hover:underline"
							>
								{t('shared/header/logout')}
							</a>
						</li>
					</ul>
				</div>
			)}
		</div>
	);
};

export default MyPagesMenuDropdown;
