import React from 'react';
import {
	Field,
	TextArea,
	Checkbox,
	RadioButton,
	Select,
	Button,
	Fieldset,
} from 'components/ui/Form';

/**
 * Custom hook for cloning and rendering children with custom props in form
 * @param {Boolean} showError - Boolean indicating if field errors should be shown or hidden
 */
export const useChildClones = (showError, visibleFields) => {
	const formFieldNameCounter = {};

	/**
	 * Returns true if element is a Form field type.
	 */
	const isValidFormElement = (element) => {
		switch (element.type) {
			case Checkbox:
			case Field:
			case TextArea:
			case RadioButton:
			case Button:
			case Select:
			case Fieldset:
				return true;
			default:
				return false;
		}
	};

	const renderChildren = (
		children,
		initialValues,
		resetForm,
		onFieldChange,
		formRef
	) => {
		if (!children) {
			return null;
		}

		const renderedChildren = React.Children.map(children, (child) => {
			const props = child && child.props ? { ...child.props } : {};

			if (!React.isValidElement(child)) {
				return child;
			}

			if (isValidFormElement(child) || child.props.useFormRef) {
				const { name } = child.props;
				props.onFieldChange = onFieldChange;
				props.resetForm = resetForm;
				props.showError = showError;
				props.visibleFields = visibleFields;

				// Only send refernece to the form to form elements with prop useFormRef added
				if (child.props.useFormRef) {
					props.formRef = formRef;
				}

				if (initialValues[name]) {
					props.defaultValue = initialValues[name];
				}

				if (!formFieldNameCounter[name]) {
					formFieldNameCounter[name] = 1;
				} else {
					formFieldNameCounter[name] += 1;
				}
			}

			if (child.props && child.props.children) {
				props.children = renderChildren(
					child.props.children,
					initialValues,
					resetForm,
					onFieldChange,
					formRef
				);
			}

			return React.cloneElement(child, props);
		});

		if (renderedChildren.length === 1) {
			return renderedChildren[0];
		} else {
			return renderedChildren;
		}
	};

	return [renderChildren, formFieldNameCounter];
};
