import Axios, { CancelTokenSource } from 'axios';
import { AppThunk } from 'store';
import {
	AddNotesModel,
	AjaxMethodTypes,
	CheckoutReqestDataModel,
} from 'types/common';

import { ArticleDeliveryDatesModel } from 'types/article';
import { CartObjectModel, CartObjectTypes } from 'types/cart-types';
import { OrderMatrixObjectModel } from 'types/matrix-types';

export let cancelToken: CancelTokenSource = Axios.CancelToken.source();
export const cancelPricingRequest = () => {
	if (cancelToken) {
		cancelToken.cancel();
	}

	const CancelToken = Axios.CancelToken;
	cancelToken = CancelToken.source();
};

interface ApiUrlModel {
	cartObjectType?: CartObjectTypes;
	cartId?: string;
	article?: boolean;
	note?: boolean;
	checkout?: boolean;
	punchout?: boolean;
	withPrice?: boolean;
	priceReloadCounter?: number;
	empty?: boolean;
	itemCode?: string;
	lineItemId?: number;
	sort?: string;
	deliveryMethod?: string;
	location?: string;
	desiredDate?: string;
	tab?: string;
	singleShipment?: boolean;
	date?: boolean;
	table?: boolean;
}

export const getApiUrl = ({
	cartObjectType,
	cartId = '',
	article = false,
	note = false,
	empty = false,
	withPrice = false,
	priceReloadCounter = 0,
	checkout = false,
	punchout = false,
	itemCode = '',
	lineItemId,
	sort = '',
	deliveryMethod = '',
	location = '',
	desiredDate = '',
	tab = '',
	table = false,
	singleShipment,
	date,
}: ApiUrlModel): AppThunk => (dispatch: any, getState: any) => {
	const apiBaseUrl = getState().cart.apiBaseUrl;
	let query = '';

	return query.concat(
		`${apiBaseUrl}/cart`,
		cartId && `/${cartId}`,
		checkout ? '/checkout' : '',
		punchout ? '/punchout' : '',
		article ? '/article' : '',
		note ? '/note' : '',
		table ? '/table' : '',
		itemCode && `/${itemCode}`,
		lineItemId ? `/${lineItemId}` : '',
		date ? '/date' : '',
		cartObjectType ? `?type=${cartObjectType}` : '',
		withPrice ? `&withprice=${withPrice}` : '',
		priceReloadCounter ? `&priceReloadCounter=${priceReloadCounter}` : '',
		empty ? '&empty=true' : '',
		sort && `&sort=${sort}`,
		deliveryMethod && `&deliverymethod=${deliveryMethod}`,
		location ? `&location=${location}` : '',
		desiredDate && `&desireddate=${desiredDate}`,
		tab && `&tab=${tab}`,
		singleShipment === true
			? `&singleshipment=true`
			: singleShipment === false
			? `&singleshipment=false`
			: ''
	);
};

export const FetchCartApi = async (
	apiUrl: string,
	cancelSource?: CancelTokenSource
): Promise<CartObjectModel> => {
	const { data } = await Axios({
		method: 'GET',
		url: apiUrl,
		cancelToken: cancelSource?.token,
	});

	return data;
};

export const AddToCartApi = async (
	method: AjaxMethodTypes,
	apiUrl: string,
	articleList?: any,
	cancelSource?: CancelTokenSource
): Promise<CartObjectModel | OrderMatrixObjectModel> => {
	const { data } = await Axios({
		method,
		url: apiUrl,
		data: {
			articleList: articleList,
		},
		cancelToken: cancelSource?.token,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
		},
	});
	return data;
};

export const sendTableDataApi = async (
	method: AjaxMethodTypes,
	apiUrl: string,
	body: string,
	cancelSource?: CancelTokenSource
): Promise<any> => {
	let parsedBody;
	try {
		parsedBody = JSON.parse(body);
	} catch (error) {
		console.error('Error parsing JSON:', error.message);
	}

	const { data } = await Axios({
		method,
		url: apiUrl,
		data: parsedBody,
		cancelToken: cancelSource?.token,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
		},
	});
	return data;
};

export const RemoveItemApi = async (
	apiUrl: string,
	cancelSource?: CancelTokenSource
): Promise<CartObjectModel> => {
	const { data } = await Axios.delete(apiUrl, {
		cancelToken: cancelSource?.token,
	});
	return data;
};

export const ClearCartApi = async (
	apiUrl: string,
	cancelSource?: CancelTokenSource
): Promise<CartObjectModel | OrderMatrixObjectModel> => {
	const { data } = await Axios.patch(apiUrl, {
		cancelToken: cancelSource?.token,
	});
	return data;
};

export const SaveCartApi = async (
	saveCartData: { name: string; overwrite: boolean; shared: boolean },
	apiUrl: string,
	cancelSource?: CancelTokenSource
): Promise<{ errorCode: number; message: string }> => {
	const { data } = await Axios({
		method: 'POST',
		url: apiUrl,
		data: {
			...saveCartData,
		},
		cancelToken: cancelSource?.token,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
		},
	});
	return data;
};

export const AddNotesApi = async (
	apiUrl: string,
	notesData: AddNotesModel[],
	cancelSource?: CancelTokenSource
): Promise<CartObjectModel> => {
	const { data } = await Axios({
		method: 'PATCH',
		url: apiUrl,
		data: {
			notes: notesData,
		},
		cancelToken: cancelSource?.token,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
		},
	});
	return data;
};

export const UpdateWithArticleDeliveryDates = async (
	apiUrl: string,
	updateDeliveryDates: ArticleDeliveryDatesModel[],
	cancelSource?: CancelTokenSource
): Promise<CartObjectModel> => {
	const { data } = await Axios({
		method: 'PATCH',
		url: apiUrl,
		data: {
			dates: updateDeliveryDates,
		},
		cancelToken: cancelSource?.token,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
		},
	});
	return data;
};

export const DeleteCartApi = async (
	apiUrl: string
): Promise<{ statusCode: number; message: string }> => {
	const { data } = await Axios.delete(apiUrl);
	return data;
};

export const UpdateCartApi = async (
	apiUrl: string,
	cancelSource?: CancelTokenSource
): Promise<CartObjectModel> => {
	const { data } = await Axios.patch(apiUrl, {
		cancelToken: cancelSource?.token,
	});
	return data;
};

export const CheckoutApi = async (
	apiUrl: string,
	requestData?: CheckoutReqestDataModel
): Promise<any> => {
	const { data } = await Axios.post(apiUrl, requestData);
	return data;
};
