import store from 'store';

/**
 * Renders an element based on whether or not we're in EPI's edit mode:
 * -If we're in edit mode, add data-epi-property-name attribute to element and always render
 * -If we're on site, render element if we have content, return empty string if no content
 *
 * @param {Boolean} isInEditMode - True if in Epi's edit mode, false if on site
 * @param {String} name - The name of the field in EPI
 * @param {String} Tag - The tag name, i.e. 'p', 'div' etc...
 * @param {Object} props - The props as an object to add to the HTML element, ie. className, id etc.
 * @param {String} content - The innerHTML of the tag
 */
export const renderElementBasedOnEditMode = (
	isInEditMode,
	name,
	Tag,
	props,
	content
) => {
	if (isInEditMode) {
		return (
			<Tag {...props} data-epi-property-name={name}>
				{content}
			</Tag>
		);
	} else {
		return content || props.dangerouslySetInnerHTML ? (
			<Tag {...props}>{content}</Tag>
		) : (
			''
		);
	}
};

/**
 * Sets a cookie.
 * @param {String} cname - The name of the cookie.
 * @param {String} cvalue - The value of the cookie.
 * @param {Number|Null} exdays - The number of days until the cookie should expire (can be null).
 * @param {String|Null} path - The path to set the cookie to (can be null).
 * @public
 */
export const setCookie = (cname, cvalue, exdays, path) => {
	let d = new Date(),
		cookieContent = cname + '=' + cvalue,
		expires;

	if (exdays !== null && exdays !== undefined) {
		d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
		expires = '; expires=' + d.toUTCString();
		cookieContent += expires;
	}

	if (path !== null && path !== undefined) {
		cookieContent += '; path=' + path;
	}
	document.cookie = cookieContent;
};

/**
 * Gets a cookie.
 * @param {String} cname - The name of the cookie.
 * @public
 */
export const getCookie = (cname) => {
	let name = cname + '=',
		ca = document.cookie.split(';');

	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}

	return '';
};

/*
 * Compose query string for search and filter.
 */
export const composeQueryString = (
	query,
	sortBy,
	display,
	currentPage,
	facets,
	group,
	sortDir,
	article
) => {
	let queryParams = [];

	if (query && query !== '') {
		queryParams.push(`query=${query}`);
	}
	if (sortBy || sortBy === 0) {
		queryParams.push(`sort=${sortBy}`);
	}
	if (display) {
		queryParams.push(`display=${display}`);
	}
	if (currentPage) {
		queryParams.push(`page=${currentPage}`);
	}
	if (sortDir) {
		queryParams.push(`sortDir=${sortDir}`);
	}
	if (facets && facets.length > 0) {
		const facetParams = composeFacetUrlParams(facets);
		facetParams && queryParams.push(facetParams);
	}
	if (group || group === 0) {
		queryParams.push(`group=${group}`);
	}
	if (article) {
		queryParams.push(`article=${article}`);
	}

	const queryString = queryParams.join('&');
	return queryString;
};

export const composeFacetUrlParams = (facets) => {
	let params = {};
	facets.forEach((fieldset) => {
		fieldset.items.forEach((facet) => {
			if (facet.active) {
				if (params[fieldset.id]) {
					params[fieldset.id].push(facet.id);
				} else {
					params[fieldset.id] = [facet.id];
				}
			}
		});
	});
	const esc = encodeURIComponent;
	const queryString = Object.keys(params)
		.filter((key) => params[key].length > 0 && params[key][0])
		.map(
			(key) => esc(key) + '=' + params[key].map((value) => `${value}`).join(',')
		)
		.join('&');
	return queryString || null;
};

/*
 * Get a param from window.location;
 */
export const getURLParameter = (name) => {
	if (!window || !window.location || !window.location.search) return null;
	return (
		decodeURIComponent(
			(new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
				window.location.search
			) || [null, ''])[1].replace(/\+/g, '%20')
		) || null
	);
};

/*
 * Set the window.location based on query and filter options.
 */
export const setUrlLocation = (
	queryString,
	query,
	sortBy,
	display,
	currentPage,
	facets,
	group,
	sortDir,
	hasRendered,
	noUpdate,
	hash,
	article
) => {
	if (noUpdate !== true) {
		let windowLocation = window.location.pathname + '?' + queryString;
		if (hash) {
			windowLocation += hash;
		}

		if (hasRendered) {
			window.history.pushState(
				{
					search: query,
					sortBy: {
						sortBy,
						display,
						facets,
						group,
						sortDir,
					},
					page: currentPage,
					article: article,
				},
				'',
				windowLocation
			);
		} else {
			window.history.replaceState(
				{
					search: query,
					sortBy: {
						sortBy,
						display,
						facets,
						group,
						sortDir,
					},
					page: currentPage,
					modal: article,
				},
				'',
				windowLocation
			);
		}
	}
};

export const formatDate = (dateString) => {
	if (!dateString || dateString === '') {
		return '';
	}
	let date = new Date(dateString);
	return date.toISOString().slice(0, 10);
};

export const parseAndFormatRows = (value) => {
	const items = [];
	const rows = value.split('\n');
	rows.forEach((row) => {
		const item = row.split('\t');
		if (row[0] && row[2]) {
			items.push({
				code: item[0],
				quantity: parseInt(item[1]),
			});
		}
	});
	return items && items.length > 0 ? items : [];
};

export const formElementsAreEqual = (f1, f2) => {
	return f1 && f1.value && f2 && f2.value && f1.value === f2.value;
};

export const scrollToTop = (pixel = 0, smooth) => {
	if (window) {
		window.scrollTo({ top: pixel, behavior: smooth ? 'smooth' : 'auto' });
	}
};

export const getHeadingLevel = (level) => {
	switch (level) {
		case 1:
			return 'h1';
		case 2:
			return 'h2';
		case 3:
			return 'h3';
		case 4:
			return 'h4';
		case 5:
			return 'h5';
		case 6:
			return 'h6';
		default:
			return 'h2';
	}
};

export const t = (term) => {
	const state = store().getState().localization.translations;
	return state[`/frontend/${term}`]
		? state[`/frontend/${term}`]
		: `/frontend/${term}`;
};

// type WarehouseModel[]
export const getSelectedWarehouse = (locations) => {
	return locations?.find((item) => item.selected === true)?.id;
};

export const getSelectedDeliveryMethod = (deliveryMethods) => {
	return deliveryMethods?.find((item) => item.selected === true)
		?.deliveryMethodId;
};

export const getPreloadedState = (model) => {
	let preloadedState = {};
	if (model.framework.siteType === 'Lindab') {
		preloadedState = {
			...preloadedState,
			cart: {
				cartObject: model.cartObject,
				apiBaseUrl: model.framework.api.cartApiBaseUrl,
				priceReloadCounter: 0,
			},
			user: {
				userObject: model.framework.header.userObject,
				apiBaseUrl: model.framework.api.userApiBaseUrl,
			},
		};
		if (model.modelType === 'OrderMatrixLandingPage') {
			preloadedState = {
				...preloadedState,
				ordermatrix: {
					orderMatrixObject: model.orderMatrixObject,
					activeTabObj: model.orderMatrixObject?.orderMatrixTabPages?.filter(
						(tab) => tab.isActive
					)[0],
					activeTabQuantity: model.orderMatrixObject?.orderMatrixTabPages?.filter(
						(tab) => tab.isActive
					)[0]?.quantity,
					totalQuantity: model.orderMatrixObject?.orderMatrixTabPages?.reduce(
						(accumulator, currentValue) => accumulator + currentValue.quantity,
						0
					),
				},
			};
		}
	}

	return preloadedState;
};

export class SaveCartError extends Error {
	constructor(errorCode, ...params) {
		super(...params);
		this.errorCode = errorCode;
	}
}

export class SendTableError extends Error {
	constructor(errorCode, message, ...params) {
		super(...params);
		this.errorCode = errorCode;
		this.message = message;
	}
}

export class CheckoutError extends Error {
	constructor(errorCode, ...params) {
		super(...params);
		this.errorCode = errorCode;
	}
}
export class PunchOutError extends Error {
	constructor(errorCode, ...params) {
		super(...params);
		this.errorCode = errorCode;
	}
}
export class ReloadCartPriceError extends Error {
	constructor(errorCode, ...params) {
		super(...params);
		this.errorCode = errorCode;
	}
}
export class CancelCartPriceError extends Error {
	constructor(errorCode, ...params) {
		super(...params);
		this.errorCode = errorCode;
	}
}

export class GenericWarning extends Error {
	constructor(errorCode, ...params) {
		super(...params);
		this.errorCode = errorCode;
	}
}

export class QuickImportArticlesError extends Error {}
export class ServerError extends Error {}
