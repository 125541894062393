/**
 * PressCalendariumBlock
 * @module components/PressCalendariumBlock
 */
import PropTypes from 'prop-types';
import { Grid, Cell } from 'components/ui/Grid';
import Button from 'components/ui/Button';
import { Heading } from './PressCalendariumBlock.styles.js';
import PressReleaseItem from './PressReleaseItem.js';
import CalendarItem from './CalendarItem.js';
import Layout from 'components/ui/Layout/Layout';
import Icon from 'components/ui/Icon';

const PressCalendariumBlock = ({
	latestPressReleasesText,
	calendarText,
	pressReleases,
	calendarEvents,
	allPressReleasesText,
	allPressReleasesLink,
	allCalendarEventsText,
	allCalendarEventsLink,
}) => (
	<Layout>
		<Grid>
			<Cell span={12} tablet={6}>
				<Heading>{latestPressReleasesText}</Heading>
				{pressReleases.map((release, i) => {
					return <PressReleaseItem {...release} key={i} />;
				})}
				<Button type="link" url={allPressReleasesLink} buttonColor="orange">
					{allPressReleasesText}
					<Icon
						icon="chevrons"
						direction="left"
						size={1.25}
						className="ml-2 -mt-1"
					/>
				</Button>
			</Cell>
			<Cell span={12} tablet={6}>
				<Heading>{calendarText}</Heading>
				{calendarEvents.map((calendarEvent, i) => {
					return <CalendarItem {...calendarEvent} key={i} />;
				})}
				<Button type="link" url={allCalendarEventsLink} buttonColor="orange">
					{allCalendarEventsText}
					<Icon
						icon="chevrons"
						direction="left"
						size={1.25}
						className="ml-2 -mt-1"
					/>
				</Button>
			</Cell>
		</Grid>
	</Layout>
);

PressCalendariumBlock.propTypes = {
	block: PropTypes.shape({
		latestPressReleasesText: PropTypes.string,
		calendarText: PropTypes.string,
		pressReleases: PropTypes.arrayOf(
			PropTypes.shape({
				date: PropTypes.string,
				name: PropTypes.string,
				link: PropTypes.string,
			})
		),
		calendarEvents: PropTypes.arrayOf(
			PropTypes.shape({
				date: PropTypes.string,
				name: PropTypes.string,
				month: PropTypes.string,
				day: PropTypes.string,
				addToCalendarStartDate: PropTypes.string,
				addToCalendarEndDate: PropTypes.string,
				addToCalendarLocation: PropTypes.string,
				link: PropTypes.string,
			})
		),
		allPressReleasesText: PropTypes.string,
		allPressReleasesLink: PropTypes.string,
		allCalendarEventsText: PropTypes.string,
		allCalendarEventsLink: PropTypes.string,
	}),
};

export default PressCalendariumBlock;
