import React from 'react';
import styled from 'styled-components';
import { deviceSmallMax } from 'theme/mediaQueries';
import { fontWeightMedium, fontWeightSemiBold } from 'theme/typography';
import { greyDarker, blue } from 'theme/colors';
import { spacing2x, spacing1x } from 'theme/spacings';
import { FiUsers, FiPlusSquare, FiMinusSquare } from 'react-icons/fi';
import { IconCss } from '../LocationRow.styles';

export const ContactWrapper = styled.div`
	padding: ${(props) =>
		props.padTop ? `${spacing2x} ${spacing2x} 0 ${spacing2x}` : '0 1.6rem'};
	overflow: hidden;
	@media (max-width: ${deviceSmallMax}) {
		padding: 0;
	}
`;

export const ContactRowsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
	height: ${(props) => (props.isOpen ? 'auto' : '0')};
	padding: 0;
	margin: 0;
`;
export const ContactRow = styled.div`
	display: flex;
	flex-wrap: nowrap;
	min-width: 100%;
	align-items: center;
`;

export const LeftContent = styled.div`
	width: 15.2rem;
	height: 8.4rem;
	min-width: 15.2rem;
	margin-right: 2rem;
	@media (max-width: ${deviceSmallMax}) {
		width: auto;
		min-width: 8rem;
	}
`;

export const ContactImage = styled.img`
	width: 6rem;
	height: 6rem;
	border-radius: 50%;
	float: right;
	margin-top: 1.2rem;
	border: solid 1px ${greyDarker};
	@media (max-width: ${deviceSmallMax}) {
		float: none;
		margin-left: 1rem;
	}
`;

export const RightContent = styled.div`
	border-top: ${(props) => (props.first ? `1px solid ${greyDarker}` : 'none')};
	border-bottom: 1px solid ${greyDarker};
	display: flex;
	width: 100%;
	min-width: 100%;
	@media (max-width: 1000px) {
		flex-wrap: wrap;
	}
`;

export const ToggleRow = styled.div`
	display: flex;
	flex-wrap: none;
	text-align: right;
	margin: ${(props) =>
		props.isOpen
			? `${spacing2x} ${spacing2x} 0 ${spacing2x}`
			: `0 ${spacing2x}`};
	min-width: 100%;
	justify-content: flex-end;
	@media (max-width: ${deviceSmallMax}) {
		padding: 0 1.6rem 0 0;
	}
`;

export const TextWrapper = styled.div`
	display: flex;
	flex: 1 0 50%;
	flex-wrap: wrap;
	min-width: 27rem;
	max-width: 27rem;
	padding: ${spacing2x} 0;
	@media (max-width: 1000px) {
		padding: ${spacing1x} 0 0 0;
	}
`;
export const ContactInfo = styled.div`
	padding: ${spacing2x} 0;
	@media (max-width: 1000px) {
		padding: ${spacing1x} 0;
	}
`;

export const Text = styled.p`
	padding: 0;
	margin: 0;
	font-size: 1.6rem;
	line-height: 2.4rem;
	width: 100%;
	font-weight: ${(props) =>
		props.bold ? fontWeightSemiBold : fontWeightMedium};

	> a {
		text-decoration: none;
		color: ${blue};

		:hover {
			text-decoration: underline;
		}
	}
	@media (max-width: 1000px) {
		padding: 0;
	}
`;

export const UsersIcon = styled(FiUsers)`
	${IconCss};
	margin-top: 0;
	margin-bottom: 1rem;
`;

export const ToggleIcon = (props) => {
	const { isOpen } = props;
	return isOpen ? <ToggleCloseIcon /> : <ToggleOpenIcon />;
};

export const ToggleOpenIcon = styled(FiPlusSquare)`
	${IconCss};
	color: ${blue};
`;
export const ToggleCloseIcon = styled(FiMinusSquare)`
	${IconCss};
	color: ${blue};
`;

export const ToggleButton = styled.button`
	border: 0;
	padding: 0;
	font-size: 100%;
	font-family: inherit;
	background-color: transparent;
	display: flex;
	cursor: pointer;
	> svg {
		margin-left: ${spacing1x};
		margin-top: 0.6rem;
	}
`;

export const ToggleText = styled.p`
	font-size: 1.6rem;
	line-height: 2.4rem;
	padding: 0;
	margin: 0;
	color: ${blue};
`;

export const IconWrapper = styled.div`
	height: 2.4rem;
`;
