/**
 * RteBlock
 * @module components/RteBlock
 */
import RichText from 'components/ui/RichText';
import Layout from 'components/ui/Layout';

interface RteBlockData {
	text: string;
	backgroundColor: string;
}

const RteBlock = ({ text, backgroundColor }: RteBlockData) => (
	<Layout backgroundColor={backgroundColor}>
		<div className="max-w-full sm:max-w-2/3">
			<RichText
				className={backgroundColor === 'blue' ? 'text-white' : 'text-black'}
				text={text}
			/>
		</div>
	</Layout>
);

export default RteBlock;
