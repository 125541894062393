/**
 * LinkList
 * @module components/LinkList
 */

import { useContext } from 'react';
import { List, Item, Link, Heading } from './LinkList.styles';
import {
	FiFile,
	FiDownload,
	FiLink,
	FiExternalLink,
	FiFilm,
} from 'react-icons/fi';
import { LocalizationContext } from 'context/localization.context';
import { LinkModel } from 'types/common';

interface LinkListLinkModel extends LinkModel {
	type: string;
}

interface LinkListModel {
	documents: LinkListLinkModel[];
	className?: string;
	expanded: boolean;
}

const LinkList = ({ documents, expanded, className }: LinkListModel) => {
	const { t }: any = useContext(LocalizationContext);
	const renderIcon = (icon: string) => {
		switch (icon) {
			case 'document':
				return <FiFile />;
			case 'download':
				return <FiDownload />;
			case 'external-link':
				return <FiExternalLink />;
			case 'internal-link':
				return <FiLink />;
			case 'video':
				return <FiFilm />;
			default:
				return false;
		}
	};
	return (
		<div className={className}>
			<Heading>{t('shared/linklist/relatedlinks')}</Heading>
			<List>
				{documents.map((item, i) => {
					return (
						<Item key={`link-${i}`}>
							{renderIcon(item.type)}
							<Link as="a" href={item.link} target="_blank">
								{item.text}
							</Link>
						</Item>
					);
				})}
			</List>
		</div>
	);
};

export default LinkList;
