import styled from 'styled-components';
import { Paragraph, H3Css } from 'components/ui/Typography';
import * as colors from 'theme/colors';
import { black, blue, greyDarkest, greyDark, grey } from 'theme/colors';
import {
	spacing1x,
	spacing05x,
	spacing3x,
	spacing2x,
	spacing4x,
} from 'theme/spacings';
import { fontWeightMedium } from 'theme/typography';
import { deviceSmallMax, deviceMediumMax } from 'theme/mediaQueries';
import RichTextWrapper from 'components/ui/RichText';
import Button from 'components/ui/Button';
import {
	FaFacebookF,
	FaTwitter,
	FaLinkedinIn,
	FaEnvelope,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
const IconCss = `
    color: ${greyDarkest};
    background-color: ${grey};
    font-size: 3.5rem;
    padding: ${spacing1x};
    margin-left: ${spacing05x};

    :hover {
        background-color: ${greyDark};
    }

    @media (max-width: 520px) {
        margin-left: 0;
        margin-right: ${spacing05x};
    }
`;

export const PublishInfoWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 66%;
	@media (max-width: ${deviceMediumMax}) {
		width: 100%;
	}
	@media (max-width: 520px) {
		display: block;
	}
`;

export const Wrapper = styled.div`
	@media (max-width: 520px) {
		margin-top: ${spacing2x};
	}
`;

export const Label = styled.span`
	text-transform: uppercase;
	background-color: ${({ color }) =>
		color === 'orange' ? colors.orangeLight : colors[color]};
	color: ${colors.white};
	font-size: 1.3rem;
	padding: ${spacing05x} ${spacing1x};
	margin-right: ${spacing1x};
	font-weight: ${fontWeightMedium};
`;

export const PublishText = styled.span`
	font-size: 1.6rem;
	color: ${black};
	opacity: 0.5;
	text-decoration: none;
`;

export const SocialLink = styled.a`
	margin: 0;
`;

export const FacebookIcon = styled(FaFacebookF)`
	${IconCss};
`;

export const TwitterIcon = styled(FaXTwitter)`
	${IconCss};
`;

export const LinedInIcon = styled(FaLinkedinIn)`
	${IconCss};
`;

export const EmailIcon = styled(FaEnvelope)`
	${IconCss};
`;

export const Image = styled.img`
	width: 66%;

	@media (max-width: ${deviceMediumMax}) {
		width: 100%;
	}
`;

export const Preamble = styled(Paragraph)`
	max-width: 66%;
	font-weight: ${fontWeightMedium};
	font-size: 2.2rem;
	line-height: 2.8rem;
	margin-bottom: ${spacing4x};

	@media (max-width: ${deviceMediumMax}) {
		max-width: 100%;
	}
	@media (max-width: ${deviceSmallMax}) {
		margin: ${spacing05x} 0;
	}
`;

export const RichText = styled(RichTextWrapper)`
	max-width: 66%;
	@media (max-width: ${deviceMediumMax}) {
		max-width: 100%;
	}
	@media (max-width: ${deviceSmallMax}) {
		margin: ${spacing05x} 0;
	}

	h2 {
		${H3Css};
		margin-bottom: ${spacing1x};
		color: ${blue};
	}

	p {
		margin: 0 0 ${spacing2x};
	}
`;

export const ButtonWrapper = styled.div`
	max-width: 66%;
	display: flex;
	justify-content: flex-end;
	margin: ${spacing3x} 0;
	flex-wrap: wrap;
	@media (max-width: ${deviceMediumMax}) {
		max-width: 100%;
	}
`;

export const SaveButton = styled(Button)`
	margin: ${spacing1x} 0 ${spacing1x} ${spacing1x};
`;
export const ReturnButton = styled(Button)`
	margin: ${spacing1x} 0;
	svg {
		margin: 0 ${spacing1x} 0 0;
	}
`;
