/**
 * QuotationHistoryFilter
 * @module components/QuotationHistoryFilter
 */

import { useContext, useState, useEffect } from 'react';
import { LocalizationContext } from 'context/localization.context';
import { useMedia } from 'hooks';

import Icon from '../Icon';
import Button from '../Button';
import clsx from 'clsx';
import DatePickerInput from '../DatePickerInput';
import { FacetsItemModel } from 'types/filter-types';

interface QuotationHistoryFilterProps {
	filter: any;

	onFilter: Function;

	onReset: () => void;

	loading?: boolean;

	activeMarket: string;

	language?: string;
}

const QuotationHistoryFilter: React.FC<QuotationHistoryFilterProps> = ({
	filter,
	onFilter,
	onReset,
	loading,
	activeMarket,
	language,
}) => {
	const { t }: any = useContext(LocalizationContext);
	const [showFilter, setShowFilter] = useState(false);
	const isMobile = useMedia('(max-width: 767px)');

	const [filterState, setFilterState] = useState(filter);

	useEffect(() => {
		setFilterState(filter);
	}, [filter]);

	const toggleFilters = () => {
		setShowFilter(!showFilter);
	};

	const handleFilterInputChange = (e: any) => {
		const newState = { ...filterState };
		newState[e.target.name] = e.target.value;
		setFilterState(newState);
	};

	const adjustForTimezone = (date: Date) => {
		let timeOffsetInMS = date.getTimezoneOffset() * 60000;
		date.setTime(date.getTime() - timeOffsetInMS);
		return date;
	};

	const getDateString = (date: Date) => {
		return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
			'0' + date.getDate()
		).slice(-2)}`;
	};

	const handleDateChange = (name: string, value: Date) => {
		const newState = { ...filterState };
		newState[name] = (value && getDateString(adjustForTimezone(value))) || null;
		setFilterState(newState);
	};

	const handleFacet = (facetId: any, itemId: any, value: any) => {
		const newFacets = [...filterState.facets];

		const facet = newFacets.find((f) => f.id === facetId);
		const item = facet.items.find((i: any) => i.id === itemId);
		item && (item.active = value);

		setFilterState({ ...filterState, facets: newFacets });
	};

	const handleApplyFilter = (e: any) => {
		e.preventDefault();
		onFilter && onFilter(filterState);
	};

	const handleReset = (e: any) => {
		e.preventDefault();
		const newState = { ...filterState };
		Object.keys(newState).forEach((key) => {
			if (key === 'facets') {
				newState.facets.forEach((facet: FacetsItemModel) => {
					facet.items &&
						facet.items.forEach((item) => {
							item.active = false;
						});
				});
			} else {
				newState[key] = '';
			}
		});
		setFilterState(newState);
		onReset && onReset();
	};

	const renderInput = (
		id: string,
		label: string,
		placeholder: string,
		value: string
	) => {
		return (
			<div className="relative mb-2 mt-1">
				<span className="sr-only">{label}</span>

				<input
					className="relative pl-7 flex-1 py-1 border rounded text-sm w-full"
					placeholder={placeholder}
					id={id}
					name={id}
					value={value}
					onChange={handleFilterInputChange}
					autoComplete="off"
					type="text"
				/>
				<Icon
					icon="filter"
					className="text-greyDark absolute mt-1 bottom-3 left-3 h-4"
					aria-hidden="true"
				/>
			</div>
		);
	};

	return (
		<div className="bg-grey px-6 py-4">
			{isMobile && (
				<div>
					<span className="font-semibold text-h5">
						{t('quotationhistorypage/filterquotation')}
					</span>
					<button
						className="cursor-pointer float-right mb-3"
						onClick={toggleFilters}
						title={t('shared/filter/showfilter')}
					>
						<span className="sr-only">{t('shared/filter/showfilter')}</span>
						<Icon
							size={2}
							icon="sliders"
							aria-hidden="true"
							className="text-greyDarkest"
						/>
					</button>
				</div>
			)}
			<form
				className={clsx(
					!showFilter && isMobile
						? 'hidden h-0 absolute'
						: 'visible auto static',
					'flex w-full gap-8 flex-col sm:flex-row'
				)}
				action="#"
				method="post"
				onSubmit={handleApplyFilter}
				autoComplete={'off'}
			>
				<fieldset className="w-full sm:w-1/2">
					{!isMobile && (
						<legend className="text-p font-semibold m-0">
							{t('quotationhistorypage/filterquotation')}
						</legend>
					)}
					<div className="flex gap-8 mt-3px">
						<div className="w-full">
							{renderInput(
								'quotationId',
								t('quotationhistorypage/quotationnumber'),
								t('quotationhistorypage/quotationnumber'),
								filterState && filterState.quotationId
							)}
							{renderInput(
								'customerRequisition',
								t('quotationhistorypage/requisitionnumber'),
								t('quotationhistorypage/requisitionnumber'),
								filterState && filterState.customerRequisition
							)}
							{renderInput(
								'email',
								t('quotationhistorypage/email'),
								t('quotationhistorypage/email'),
								filterState && filterState.email
							)}
						</div>
						<div className="w-full">
							{renderInput(
								'projectId',
								t('quotationhistorypage/projectnumber'),
								t('quotationhistorypage/projectnumber'),
								filterState && filterState.projectId
							)}
							{renderInput(
								'projectName',
								t('quotationhistorypage/projectname'),
								t('quotationhistorypage/projectname'),
								filterState && filterState.projectName
							)}
						</div>
					</div>
				</fieldset>
				<fieldset className="w-full sm:w-1/4">
					<legend className="text-p font-semibold m-0">
						{t('quotationhistorypage/expirydate')}
					</legend>
					<div className="flex my-2">
						<label
							className="text-p m-0 p-0 leading-14 w-1/5 flex-1-1-20"
							htmlFor="expiryFrom"
						>
							{t('shared/order/from')}
						</label>
						<DatePickerInput
							className="m-0 flex-1-1-80 h-8"
							id={'expiryFrom'}
							minDate={new Date('1970-01-01')}
							selected={
								filterState && filterState.expiryFrom
									? new Date(filterState.expiryFrom)
									: undefined
							}
							onChange={(date: Date) => handleDateChange('expiryFrom', date)}
							activeMarket={activeMarket}
							language={language}
							size="small"
						/>
					</div>
					<div className="flex my-2">
						<label
							className="text-p m-0 p-0 leading-14 w-1/5 flex-1-1-20"
							htmlFor="expiryTo"
						>
							{t('shared/order/to')}
						</label>
						<DatePickerInput
							className="m-0 flex-1-1-80 h-8"
							id={'expiryTo'}
							minDate={new Date('1970-01-01')}
							selected={
								filterState && filterState.expiryTo
									? new Date(filterState.expiryTo)
									: undefined
							}
							onChange={(date: Date) => handleDateChange('expiryTo', date)}
							activeMarket={activeMarket}
							language={language}
							size="small"
						/>
					</div>
				</fieldset>

				<div className="w-full sm:w-1/4 mt-4">
					<Button
						className="pl-0 h-8 flex items-center justify-center w-full -mb-1"
						type="submit"
						buttonColor="orange"
						disabled={loading}
					>
						<Icon icon="search" aria-hidden="true" className="mr-2" />

						{t('orderhistorypage/applyfilter')}
					</Button>

					<Button
						className="pl-0 h-8 flex items-center justify-center w-full"
						type="button"
						buttonColor={'greyDarker'}
						onClick={handleReset}
						disabled={loading}
					>
						<Icon icon="redo" aria-hidden="true" className="mr-2" />

						{t('shared/filter/resetfilter')}
					</Button>
				</div>
			</form>
		</div>
	);
};

export default QuotationHistoryFilter;
