/**
 * ImageGalleryBlock
 * @module components/ImageGalleryBlock
 */

import React, { useState } from 'react';
import { Grid, Cell } from 'components/ui/Grid';
import Layout from 'components/ui/Layout';
import clsx from 'clsx';
import { ButtonColorTypes, LinkModel } from 'types/common';
import { ImageGalleryBlockItemModel } from 'types/block-types';
import Icon from 'components/ui/Icon';
import Button from 'components/ui/Button';
import { ImageGalleryBlockItem, ImageGalleryBlockModal } from '.';

interface Props {
	button?: LinkModel;
	columnAlignment?: 'left' | 'center' | 'right';
	items?: ImageGalleryBlockItemModel[];
	translations: any;
}

const ImageGalleryBlock = ({
	button,
	columnAlignment,
	items,
	translations,
}: Props) => {
	const [
		activeItem,
		setActiveItem,
	] = useState<ImageGalleryBlockItemModel | null>(null);

	const [modalActive, setModalActive] = useState(false);

	const handleToggleModal = () => {
		setModalActive(!modalActive);
	};
	const handleClick = (itemIndex: number) => {
		if (items && items.length > 0) {
			setActiveItem(items[itemIndex]);
		}
		handleToggleModal();
	};

	const showPreviousMedia = (e: any) => {
		e.preventDefault();
		if (items && items.length > 0) {
			const currentItemIndex = activeItem && items.indexOf(activeItem);
			if (currentItemIndex !== null && currentItemIndex > 0) {
				const previousItemIndex = currentItemIndex - 1;
				setActiveItem(items[previousItemIndex]);
			} else {
				setActiveItem(items[items.length - 1]);
			}
		}
	};

	const showNextMedia = (e: any) => {
		e.preventDefault();
		if (items && items.length > 0) {
			const currentItemIndex = activeItem && items.indexOf(activeItem);
			if (currentItemIndex !== null && currentItemIndex < items.length - 1) {
				const nextItemIndex = currentItemIndex + 1;
				setActiveItem(items[nextItemIndex]);
			} else {
				setActiveItem(items[0]);
			}
		}
	};
	return (
		<>
			<div className="mb-4 md:mb-10">
				<Layout>
					<Grid
						padding={false}
						className={clsx(
							columnAlignment === 'center' && 'justify-center',
							columnAlignment === 'left' && 'justify-start',
							columnAlignment === 'right' && 'justify-end'
						)}
					>
						{items?.map((item: ImageGalleryBlockItemModel, index: number) => {
							return (
								<Cell
									span={12}
									tablet={6}
									desktop={4}
									className="my-4 md:my-10 "
									key={index}
								>
									<ImageGalleryBlockItem
										heading={item.heading}
										text={item.text}
										image={item.image}
										onClick={handleClick}
										itemIndex={index}
										translations={translations}
									/>
								</Cell>
							);
						})}
					</Grid>
					{button && (
						<div className="my-6 text-center">
							<Button
								type="link"
								buttonColor={(button.color as ButtonColorTypes) || 'blue'}
								url={button.link}
								target={button.target}
							>
								<Icon
									icon="chevrons"
									direction="left"
									size={1.25}
									className="mr-2"
								/>
								<span>{button.text}</span>
							</Button>
						</div>
					)}
				</Layout>
			</div>
			{modalActive && activeItem?.image && (
				<ImageGalleryBlockModal
					image={activeItem.image}
					onToggle={handleToggleModal}
					showPreviousMedia={showPreviousMedia}
					showNextMedia={showNextMedia}
					items={items}
					setActiveItem={setActiveItem}
					translations={translations}
				/>
			)}
		</>
	);
};

export default ImageGalleryBlock;
