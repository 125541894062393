import clsx from 'clsx';
import { FacetItemModel } from 'types/filter-types';

const FilterByRegion = (props: {
	alternateDisplay?: boolean;
	item: FacetItemModel;
	facetId: string;
	onChange: (facetId: string, id: string, active: boolean) => void;
	className?: string;
}) => {
	const { facetId, item, onChange, alternateDisplay, className } = props;

	const handleClick = () => {
		onChange(facetId, item.id, !item.active);
	};
	return (
		<li className={clsx(item.active && 'btn-filter-region-active', className)}>
			<button
				type="button"
				name={`${facetId}-${item.id}`}
				id={`${facetId}-${item.id}`}
				onClick={handleClick}
				className="btn-filter-region"
			>
				<span>
					{item.name} <span className="text-greyDarkest">({item.count})</span>
				</span>
			</button>
		</li>
	);
};

export default FilterByRegion;
