/**
 * LanguageSelector
 * @module components/LanguageSelector
 */

import { useState, useContext } from 'react';
import { GlobeIcon, ArrowDown, ArrowUp } from './LanguageSelector.styles';
import { LocalizationContext } from 'context/localization.context';

import Modal from 'components/ui/Modal';
import clsx from 'clsx';
import Icon from 'components/ui/Icon';

interface LanguageSelectorModel {
	modalHeading: string;
	heading: string;
	preamble: string;
	mobile: boolean;
	languages: LanguageItemModel[];
}

interface LanguageItemModel {
	isHeading: boolean;
	marketId: string;
	language: string;
	languageName: string;
	link: string;
	text: string;
	active: boolean;
	isLink: boolean;
	target: string;
}

const renderDropDownItem = (item: LanguageItemModel | undefined) => {
	if (item) {
		const countryLink = `/assets/images/flags/${item.language}.svg`;
		if (item.isLink) {
			return (
				<a
					className={clsx(
						'text-sm ml-2 my-2 no-underline hover:no-underline',
						item.active
							? 'pointer-events-none font-bold'
							: 'hover:text-blue cursor-pointer pointer-events-auto font-normal'
					)}
					href={item.link}
					target={item.target ? item.target : '_self'}
				>
					{item.language && (
						<img src={countryLink} className="inline-block" alt="" />
					)}
					{' ' + item.text}
				</a>
			);
		} else {
			return (
				<span
					className={clsx(
						'text-sm my-2 text-blue',
						item.isLink ? 'font-normal' : 'font-bold'
					)}
				>
					{item.text}
				</span>
			);
		}
	}
};

const splitToChunks = (
	array: LanguageItemModel[],
	parts: number
): LanguageItemModel[][] => {
	let result: LanguageItemModel[][] = [];
	for (let i = parts; i > 0; i--) {
		result.push(array.splice(0, Math.ceil(array.length / i)));
	}
	return result;
};

const LanguageSelector = ({
	languages,
	mobile,
	heading,
	preamble,
	modalHeading,
}: LanguageSelectorModel) => {
	const { t }: any = useContext(LocalizationContext);
	const [expanded, setExpanded] = useState(false);
	const active = languages?.find((x) => x.active);

	let languagesLeftCol: LanguageItemModel[] = [];
	let languagesRightCol: LanguageItemModel[] = [];
	let counter = 0;
	languages.forEach((l) => {
		l.isHeading && counter++;
		counter > 1 ? languagesRightCol.push(l) : languagesLeftCol.push(l);
	});

	return (
		<div
			className={clsx(
				mobile === true
					? 'w-full border-right-none border-left-none'
					: 'border-l-2 border-r-2 border-solid border-greyDark',
				'p-2 inline-block relative bg-white'
			)}
		>
			{expanded && (
				<Modal
					heading={modalHeading}
					title={modalHeading}
					width="large"
					roundedCorners={true}
					toggleModal={() => setExpanded(!expanded)}
				>
					<h2
						className={
							'text-blue font-normal md:mr-0 md:mb-2 md:ml-0 mb-3 m-0 text-h2'
						}
					>
						{heading}
					</h2>
					<p className="text-p mb-4">{preamble}</p>
					<div className="inline-flex flex-wrap flex-col w-full lg:w-1/4">
						{languagesLeftCol.map((lang, i) => {
							return (
								<span className="w-full" key={i}>
									{lang.isLink && (
										<span className="text-blue">
											<Icon
												icon="chevron"
												color="#0075BF"
												direction="left"
												size={0.75}
											/>
											{lang.active && (
												<Icon
													className="-ml-1"
													icon="chevron"
													color="#0075BF"
													direction="left"
													size={0.75}
												/>
											)}
										</span>
									)}

									{renderDropDownItem(lang)}
								</span>
							);
						})}
					</div>

					<div className="block w-full lg:inline-flex lg:w-3/4">
						{splitToChunks(
							languagesRightCol.filter((l) => !l.isHeading),
							3
						).map((l, j) => {
							const languageHeading = languagesRightCol.find(
								(l) => l.isHeading
							);

							return (
								<div
									key={j}
									className="inline-flex flex-wrap w-full lg:w-1/3 flex-col"
								>
									{languageHeading && (
										<span className={clsx('h-7 lg:block', j !== 0 && 'hidden')}>
											{j === 0 && renderDropDownItem(languageHeading)}
										</span>
									)}
									{l.map((lang, i) => {
										return (
											<span key={i}>
												{lang.isLink && (
													<span className="text-blue">
														<Icon
															icon="chevron"
															color="#0075BF"
															direction="left"
															size={0.75}
														/>
														{lang.active && (
															<Icon
																className="-ml-1"
																icon="chevron"
																color="#0075BF"
																direction="left"
																size={0.75}
															/>
														)}
													</span>
												)}
												{renderDropDownItem(lang)}
											</span>
										);
									})}
								</div>
							);
						})}
					</div>
				</Modal>
			)}

			<span
				className="sr-only"
				id={mobile ? 'langlabelmobile' : 'langlabeldesktop'}
			>
				{t('shared/header/languagechooser')}
			</span>
			<button
				className="bg-none border-none text-inherit font-inherit p-0 cursor-pointer flex items-baseline"
				onClick={() => setExpanded(!expanded)}
				aria-haspopup="listbox"
				aria-expanded={expanded}
				aria-controls={mobile ? 'langselectmobile' : 'langselectdesktop'}
				aria-labelledby={mobile ? 'langlabelmobile' : 'langlabeldesktop'}
			>
				<GlobeIcon aria-hidden="true" />
				<p
					className="text-sm overflow-ellipsis max-w-55 whitespace-nowrap overflow-hidden my-2 ml-6 lgSmallerMax:ml-4"
					title={active?.text}
				>
					{active?.text}
				</p>
				{expanded ? (
					<ArrowUp aria-hidden="true" />
				) : (
					<ArrowDown aria-hidden="true" />
				)}
			</button>
		</div>
	);
};

export default LanguageSelector;
