/**
 * Accordion
 */

import clsx from 'clsx';
import React from 'react';
import { useState } from 'react';
import Icon from '../../ui/Icon';

interface Props {
	id: string;

	/** Title of the accordion */
	title: string;

	/** Content area */
	children: any[];
}

const Accordion = ({ id, title, children }: Props): JSX.Element => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<button
				aria-expanded={open}
				aria-controls={`accordion-content-${id}`}
				id={`accordion-button-${id}`}
				className={clsx(
					open && 'text-blue border-blue',
					'w-full flex justify-start space-x-5 items-center max-w-site mx-auto cursor-pointer p-3.5 border-b-2 hover:text-blue text-gray-700'
				)}
				onClick={() => setOpen(!open)}
			>
				<div>
					<Icon icon={open ? 'minus' : 'plus'} size={1} />
				</div>
				<div className="text-h4">{title}</div>
			</button>

			{open && (
				<div
					id={`accordion-content-${id}`}
					aria-labelledby={`accordion-button-${id}`}
					className="py-7 border-b-2"
				>
					{children}
				</div>
			)}
		</>
	);
};

export default Accordion;
