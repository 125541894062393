/**
 * PromoBlock
 * @module components/PromoBlock
 */
import clsx from 'clsx';
import { MouseEvent, useState } from 'react';

import { PromoBlockModel } from 'types/block-types';
import PromoContent from './PromoContent';

const PromoVideoFull = (props: PromoBlockModel) => {
	const { media, backgroundColor, heading } = props;
	const theme = backgroundColor;
	const [canPlay, setCanPlay] = useState(false);
	const handleClick = (e: MouseEvent<HTMLVideoElement>) => {
		let videoElement = e.currentTarget;
		if (videoElement) {
			videoElement.paused ? videoElement.play() : videoElement.pause();
		}
	};

	const getIframeSrc = () => {
		if (
			window &&
			window.CookieInformation &&
			window.CookieInformation.getConsentGivenFor &&
			window.CookieInformation.getConsentGivenFor('cookie_cat_marketing')
		) {
			return `${media?.video?.link}${
				media?.video?.autoplay ? '?autoplay=1&mute=1' : ''
			}`;
		} else {
			if (process.env.NODE_ENV === 'development') {
				return `${media?.video?.link}${
					media?.video?.autoplay ? '?autoplay=1&mute=1' : ''
				}`;
			} else {
				return '';
			}
		}
	};

	return (
		<section
			className={clsx(
				'mdMax:mt-0 mdMax:pt-7.5 max-w-360 my-0 mx-auto',
				theme ? `bg-${theme}` : 'bg-white'
			)}
		>
			<div className={clsx('mdMax:flex-wrap mdMax:flex-row my-0 mx-auto flex')}>
				{media && media?.video && (
					<div className={clsx('relative w-full sm:h-auto md:h-115')}>
						<div className="md:h-full w-full relative">
							{media.type === 'youtube' || media.type === 'twentythree' ? (
								<iframe
									className="md:h-115 w-full object-cover object-center md:inset-0 w-full h-47.5"
									src={getIframeSrc()}
									data-consent-src={`${media?.video.link}${
										media.video.autoplay ? '?autoplay=1&mute=1' : ''
									}`}
									title={`${media.type} - ${heading}`}
									data-category-consent="cookie_cat_marketing"
									allowFullScreen
									frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								></iframe>
							) : (
								<>
									{media.videoPosterImage && (
										<img
											className={clsx(
												'w-full object-cover object-center absolute md:max-h-full max-h-45 md:h-115',
												canPlay ? 'animate-fadeOut' : ''
											)}
											src={media.videoPosterImage}
											alt="placeholder loading video"
										/>
									)}
									<video
										playsInline
										className="md:h-115 w-full object-cover object-center md:max-h-full max-h-45"
										autoPlay={media?.video.autoplay}
										muted
										loop
										onClick={handleClick}
										onCanPlayThrough={(e) => {
											setCanPlay(true);
											if (e.currentTarget && e.currentTarget.play) {
												e.currentTarget.play();
											}
										}}
									>
										<source src={media?.video.link} type="video/mp4" />
									</video>
								</>
							)}
						</div>
						<div className="max-w-297.5 my-0 mx-auto relative">
							{heading && (
								<PromoContent
									videoPreference={media?.videoPreference}
									fullWidth={true}
									theme={theme}
									{...props}
								/>
							)}
						</div>
					</div>
				)}
			</div>
		</section>
	);
};

export default PromoVideoFull;
