/**
 * Fieldset
 * @module components/Fieldset
 */

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFieldValidation } from 'hooks';
import { useFormValue } from 'context/form.context';

const Fieldset = props => {
	const {
		label,
		description,
		children,
		id,
		required,
		validationMessage,
		errorRef,
		isInvalid,
		showError,
		visibleFields,
		className,
		visibleLabel,
	} = props;

	const [{ invalidFields }, dispatch] = useFormValue();
	const [valid, setValid] = useState(false);
	const [touched, setTouched] = useState(false);
	const fieldRef = useRef(null);
	const [validateField, showFieldError, hideFieldError] = useFieldValidation(
		fieldRef,
		props
	);

	useEffect(() => {
		const validatedField = validateField('fieldset');

		if (validatedField.message) {
			setValid(false);
			dispatch({ type: 'FIELD_INVALID', field: validatedField });
		} else {
			setValid(true);

			if (invalidFields.includes(validatedField.id)) {
				dispatch({ type: 'FIELD_VALID', field: validatedField });
			}
		}

		const partOfVisibleFields =
			visibleFields &&
			visibleFields.filter(fieldName => fieldName.includes(id));

		if (touched && !valid) {
			showFieldError(validatedField, 'fieldset');
		} else if (
			(showError &&
				!valid &&
				visibleFields &&
				partOfVisibleFields &&
				partOfVisibleFields.length > 0) ||
			(showError && !valid && !visibleFields)
		) {
			setTouched(true);
			showFieldError(validatedField, 'fieldset');
		} else {
			hideFieldError('fieldset');
		}
	}, [
		valid,
		dispatch,
		touched,
		showError,
		setValid,
		validateField,
		showFieldError,
		hideFieldError,
		invalidFields,
		visibleFields,
		id,
	]);

	const errorClassname = isInvalid
		? 'form__label--invalid'
		: 'form__label--invalid form__label--hidden';

	return (
		<fieldset
			className={className ? `${className} form__fieldset` : 'form__fieldset'}
			aria-invalid={!valid}
			id={id}
			title={description || null}
			ref={fieldRef}
			onBlur={() => setTouched(true)}
		>
			<legend
				className={`form__fieldset__legend${
					!visibleLabel ? ' is-visuallyhidden' : ''
				}`}
			>
				{label}
				{required && (
					<abbr className="form__required-mark" title="Obligatoriskt">
						*
					</abbr>
				)}
			</legend>

			<div className="form__fieldset__options">{children}</div>

			<span
				className={errorClassname}
				tabIndex="-1"
				ref={errorRef}
				aria-live="polite"
				id={`form${id}__desc`}
			>
				{validationMessage || ''}
			</span>
		</fieldset>
	);
};

Fieldset.propTypes = {
	id: PropTypes.string,
	description: PropTypes.string,
	validationMessage: PropTypes.string,
	required: PropTypes.bool,
	isInvalid: PropTypes.bool,
	label: PropTypes.string,
	errorRef: PropTypes.any,
	showError: PropTypes.bool,
	className: PropTypes.string,
	visibleFields: PropTypes.any,
	visibleLabel: PropTypes.bool,
};

export default Fieldset;
