import styled from 'styled-components';
import {
	H1Css,
	H2Css,
	H3Css,
	H4Css,
	H5Css,
	paragraphCss,
} from 'components/ui/Typography';
import { spacing2x } from 'theme/spacings';
import { fontWeightSemiBold } from 'theme/typography';
import { blue } from 'theme/colors';

export const RichTextWrapper = styled.div`
	h1 {
		${H1Css};
	}
	h2 {
		${H2Css};
	}
	h3 {
		${H3Css};
	}
	h4 {
		${H4Css};
	}
	h5 {
		${H5Css};
	}

	p {
		${paragraphCss};

		strong,
		b {
			font-weight: ${fontWeightSemiBold};

			a {
				font-weight: ${fontWeightSemiBold};
			}
		}
	}

	.align-left {
		float: left;
		margin: ${spacing2x} ${spacing2x} ${spacing2x} 0;
	}

	.align-right {
		float: right;
		margin: ${spacing2x} 0 ${spacing2x} ${spacing2x};
	}

	.align-center {
		display: block;
		margin: ${spacing2x} auto;
	}
	a {
		${paragraphCss};
		color: ${blue};
		text-decoration: none;
		:hover {
			text-decoration: underline;
		}
	}

	.youtube-wrapper {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		height: 0;
		> iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	img {
		max-width: 100%;
	}
`;
