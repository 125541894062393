import ContentArea from 'components/framework/ContentArea';
import { Paragraph } from 'components/ui/Typography';
import FilterFieldSet from 'components/framework/FilterFieldSet/FilterFieldSet';
import FilterField from 'components/ui/FilterField/FilterField';
import Layout from 'components/ui/Layout';
import Text from 'components/ui/Text';
import React, { useContext } from 'react';
import { ContentAreaModelTypes } from 'types/block-types';
import { LocationRowModel } from 'types/common';
import { FacetsItemModel, FilterState } from 'types/filter-types';
import Icon from 'components/ui/Icon';
import NewLocationRow from 'components/framework/NewLocationRow';
import Pager from 'components/ui/Pager';
import { LocalizationContext } from 'context/localization.context';
import Breadcrumbs from 'components/framework/Breadcrumbs';

interface Props {
	filter: FilterState;
	subHeading?: string;
	isInEditMode: boolean;
	locations: LocationRowModel[];
	pagination: any;
	topContentArea?: ContentAreaModelTypes[];
	contentArea?: ContentAreaModelTypes[];
	slideContentArea?: ContentAreaModelTypes[];
	state: FilterState;
	// input search props
	handleSearch: (value: string) => void;
	inputplaceholder?: string;
	// filter buttons props
	onChangeFacet: (facetId: string, itemId: string, value: boolean) => void;
	showFilter: boolean;
	loading?: any;
	error?: any;
	hasLocations: boolean;
	handlePagination?: (value: number) => void;
	isMobile?: boolean;
	breadcrumbs?: any;
}

const NewContactPage = ({
	subHeading,
	topContentArea,
	slideContentArea,
	state,
	handleSearch,
	breadcrumbs,
	inputplaceholder,
	onChangeFacet,
	showFilter = false,
	loading,
	error,
	isInEditMode = false,
	filter,
	locations,
	pagination,
	contentArea,
	hasLocations,
	handlePagination,
	isMobile,
}: Props) => {
	const { t }: any = useContext(LocalizationContext);

	return (
		<>
			<Layout backgroundColor="white">
				{breadcrumbs && <Breadcrumbs links={breadcrumbs} />}
			</Layout>
			<ContentArea contentArea={topContentArea} />
			<Layout className="overflow-visible" backgroundColor="white">
				<div className="grid grid-cols-12 gap-4 xl:gap-11">
					<section
						id="filter-contact"
						className="col-span-12 sm:col-span-7 lg:col-span-8 order-2 sm:order-1"
					>
						{subHeading && (
							<div className="flex justify-center sm:block">
								<Text
									as="h3"
									className="floating-block-subHeading text-center max-w-50 mb-4 sm:text-left sm:max-w-none"
								>
									{subHeading}
								</Text>
							</div>
						)}

						<FilterField
							method="GET"
							action="/"
							id="search-form"
							name="search-form"
							searchIcon
							value={state.query ?? ''}
							onSearch={handleSearch}
							placeholder={inputplaceholder}
							className="w-full xl:w-768px"
							colorIcon="#2A2A2A"
						/>

						{state &&
							state.facets &&
							state.facets?.some((f) => f?.items?.length > 0) && (
								<form
									className="w-full xl:w-768px mb-4"
									id="filter-form-by-categories"
								>
									{state.facets.map((fieldset: FacetsItemModel, i: number) => (
										<React.Fragment key={i.toString()}>
											{fieldset.name && fieldset.items.length > 0 && (
												<FilterFieldSet
													name={fieldset.name}
													items={fieldset.items}
													facetId={fieldset.id}
													key={fieldset.name}
													onChange={onChangeFacet}
													showFieldset={showFilter}
													defaultNumberToShow={8}
													isNewContactPage
												/>
											)}
										</React.Fragment>
									))}
								</form>
							)}
						{!loading && error && (
							<div className="my-6 mx-0 text-center w-full">
								<Paragraph>{t('shared/filter/errormessage')}</Paragraph>
							</div>
						)}
						{loading && (
							<div className="my-6 mx-0 text-center w-full">
								<Icon icon="loader" animate="spin" size={2} />
							</div>
						)}
						{hasLocations && !error && !loading
							? locations.map((item: LocationRowModel, i: number) => (
									<NewLocationRow {...item} key={i} query={state.query ?? ''} />
							  ))
							: !error &&
							  !loading && (
									<div className="my-6 mx-0 text-center w-full">
										<Paragraph>{t('shared/filter/noresultmessage')}</Paragraph>
									</div>
							  )}

						<div className="my-5 w-full">
							<Pager
								nrOfPages={pagination.pageCount ? pagination.pageCount : 0}
								currentPage={
									pagination.currentPage ? pagination.currentPage : 0
								}
								onPageChange={handlePagination}
								total={!error && !loading ? pagination.total : 0}
								isMobile={isMobile}
							/>
						</div>
					</section>
					<section
						id="floating-menu"
						className="order-1 mt-56 sm:order-2 place-items-center col-span-12 sm:col-span-5 lg:col-span-4"
					>
						<ContentArea contentArea={slideContentArea} />
					</section>
				</div>
			</Layout>
		</>
	);
};

export default NewContactPage;
