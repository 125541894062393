/**
 * CatalogPage
 * @module pages/CatalogPage
 */

import React from 'react';
import ContentArea from 'components/framework/ContentArea';
import SectionHeader from 'components/ui/SectionHeader';

const CatalogPage = props => {
	const { heading, text, contentArea, topContentArea, isInEditMode } = props;
	return (
		<main id="main-content">
			<ContentArea contentArea={topContentArea} />
			<SectionHeader
				heading={heading}
				text={text}
				size={'large'}
				isInEditMode={isInEditMode}
			/>
			<ContentArea contentArea={contentArea} />
		</main>
	);
};

export default CatalogPage;
