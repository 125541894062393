import clsx from 'clsx';
import Icon from 'components/ui/Icon';
import { FacetItemModel } from 'types/filter-types';

const FilterOption = (props: {
	alternateDisplay?: boolean;
	isOrderHistory?: boolean;
	item: FacetItemModel;
	facetId: string;
	onChange: (facetId: string, id: string, active: boolean) => void;
}) => {
	const { facetId, item, onChange, alternateDisplay, isOrderHistory } = props;
	const handleCheckbox = () => {
		onChange(facetId, item.id, !item.active);
	};
	return (
		<label
			className={clsx(
				'cursor-pointer relative mr-0 mb-2 items-center',
				alternateDisplay ? 'inline-flex mr-6' : 'flex',
				isOrderHistory ? 'mt-1 ' : 'mt-2'
			)}
			htmlFor={`${facetId}-${item.id}`}
		>
			<input
				className="opacity-0 hidden"
				type="checkbox"
				value={item.id}
				name={`${facetId}-${item.id}`}
				id={`${facetId}-${item.id}`}
				checked={item.active}
				onChange={handleCheckbox}
			/>
			{item.active ? (
				<Icon icon="checkSquare" size={1.25} className="text-blue" />
			) : (
				<Icon icon="square" size={1.25} className="text-greyDarkest" />
			)}

			<span className="ml-3 text-p whitespace-no-wrap flex justify-between w-full">
				<span className="text-black whitespace-no-wrap">{item.name}</span>
				<span className="text-greyDarkest whitespace-no-wrap">
					{item.count}
				</span>
			</span>
		</label>
	);
};

export default FilterOption;
