import styled from 'styled-components';
import { FiHelpCircle } from 'react-icons/fi';
import { greyDarker, greyDarkest, grey } from 'theme/colors';
import { spacing1x, spacing2x } from 'theme/spacings';
import { paragraphCss } from '../Typography';

interface ButtonProps {
	noPadding?: boolean;
	expanded?: boolean;
}

export const TooltipWrapper = styled.span`
	display: inline-block;
	position: relative;
`;

export const Button = styled.button<ButtonProps>`
	border: 0;
	vertical-align: top;
	background-color: transparent;
	padding: ${({ noPadding }) => (noPadding ? '0' : '0 0.8rem ')};
	cursor: pointer;
	font-size: 1.6rem;
	color: ${greyDarker};
	&:hover,
	&:focus {
		color: ${greyDarkest};
	}

	& * {
		pointer-events: none;
	}
	:before {
		content: '';
		display: ${({ expanded }) => (expanded ? 'block' : 'none')};
		position: absolute;
		bottom: -0.5rem;
		left: 50%;
		margin-left: -1rem;
		border-width: 1rem;
		border-style: solid;
		border-color: transparent transparent ${grey} transparent;
	}
`;

export const Icon = styled(FiHelpCircle)`
	vertical-align: middle;
`;

export const Popout = styled.div`
	${paragraphCss}
	background-color: ${grey};
	pointer-events: none;
	z-index: 1000;
	box-shadow: 0 0.6rem 1rem -0.5rem rgba(0, 0, 0, 0.38);
	width: auto;
	white-space: nowrap;
	text-align: center;
	border-radius: 0.2rem;
	padding: ${spacing1x} ${spacing2x};
	position: absolute;
	top: 1.5rem;
	left: 50%;
	transform: translateX(-50%);
`;
