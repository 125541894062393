import React from 'react';
export default ({ className }: { className?: string }) => (
	<svg width="16" height="16" viewBox="0 0 16 16" className={className}>
		<path
			d="m4.05278108 6 .94721892.81433153-2.921 3.55966847 7.921.001v1.25l-7.921-.001 2.921 3.5616685-.94721892.8143315-.45402378-.5601385c-2.23558434-2.7580868-3.42959938-4.2311679-3.58213627-4.4193558l-.01662103-.0205057.002-.001zm7.89443782-6 4.0507811 4.999.002.001-.016621.02050571c-.1525369.18818792-1.346552 1.66126902-3.5821363 4.41935574l-.4540238.56013855-.9472189-.81433153 2.921-3.56166847-7.921.001v-1.25l7.921-.001-2.921-3.55966847z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);
