/**
 * Loader
 * @module components/Loader
 */
import { FaSpinner } from 'react-icons/fa';
import clsx from 'clsx';

interface LoaderModel {
	screenreaderText?: string;
	size?: 'small' | 'large';
	className?: string;
}

const Loader = ({ screenreaderText, size, className }: LoaderModel) => (
	<span
		className={clsx(
			className,
			'block text-center animate-rotate',
			size === 'large' && 'text-8xl leading-24 py-4 m-auto',
			size === 'small' && 'text-h3 leading-10 my-0 mx-auto'
		)}
	>
		<FaSpinner />
		<span className="sr-only">{screenreaderText || 'Laddar...'}</span>
	</span>
);

export default Loader;
