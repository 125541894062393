/**
 * LogoOnlyLayout
 */
import LogoHeader from 'components/framework/LogoHeader';
import PageFooter from 'components/framework/PageFooter';

interface Props {
	header?: object;

	footer?: object;

	api?: object;
}
const LogoOnlyLayout: React.FC<Props> = ({ children, header, api, footer }) => {
	return (
		<>
			<LogoHeader header={header} api={api} />
			{children}
			{footer && <PageFooter footer={footer} className="" />}
		</>
	);
};
export default LogoOnlyLayout;
