/**
 * SectionHeader
 * @module components/SectionHeader
 */

import { renderElementBasedOnEditMode } from 'common/helpers';
import {
	SectionHeaderLayout,
	SectionHeaderWrapper,
} from './SectionHeader.styles';

import RichText from 'components/ui/RichText';
import CustomExternalScript from 'components/framework/CustomExternalScript';

interface Sectionheader {
	title?: string;
	heading: string;
	headingLevel?: string;
	text?: string;
	size: 'small' | 'large' | 'default';
	isInEditMode?: boolean;
	className?: string;
}

const SectionHeader = (props: Sectionheader) => {
	const { heading, text, size, isInEditMode, headingLevel, className } = props;
	return (
		<SectionHeaderLayout className={className}>
			<SectionHeaderWrapper as="section">
				{heading &&
					renderElementBasedOnEditMode(
						isInEditMode || false,
						'heading',
						'h1',
						{
							className:
								'font-standard mb-6 mt-6 font-normal text-h2 md:text-h1 text-blue',
							as: headingLevel,
						},
						heading
					)}

				<RichText
					name="text"
					text={text}
					size={size}
					isInEditMode={isInEditMode}
				/>
			</SectionHeaderWrapper>
			<CustomExternalScript />
		</SectionHeaderLayout>
	);
};

export default SectionHeader;
