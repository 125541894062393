/**
 * PromoBlock
 * @module components/PromoBlock
 */

import clsx from 'clsx';
import { MouseEvent, useState } from 'react';

import { PromoBlockModel } from 'types/block-types';
import { VideoPreferenceTypes } from 'types/common';
import PromoContent from './PromoContent';
import ResponsiveIframe from 'components/ui/ResponsiveIframe';

const isVideoRight = (videoPreference: VideoPreferenceTypes | undefined) => {
	return videoPreference && videoPreference.includes('videoRight');
};

// const isVideoLeft = (videoPreference: VideoPreferenceTypes | undefined) => {
// 	return videoPreference && videoPreference.includes('videoLeft');
// };

const PromoVideoOutside = (props: PromoBlockModel) => {
	const { media, backgroundColor, heading } = props;
	const theme = backgroundColor;
	const [canPlay, setCanPlay] = useState(false);
	const handleClick = (e: MouseEvent<HTMLVideoElement>) => {
		let videoElement = e.currentTarget;
		if (videoElement) {
			videoElement.paused ? videoElement.play() : videoElement.pause();
		}
	};

	const videoRight = isVideoRight(media?.videoPreference);

	const getIframeSrc = () => {
		if (
			window &&
			window.CookieInformation &&
			window.CookieInformation.getConsentGivenFor &&
			window.CookieInformation.getConsentGivenFor('cookie_cat_marketing')
		) {
			return `${media?.video?.link}${
				media?.video?.autoplay ? '?autoplay=1&mute=1' : ''
			}`;
		} else return '';
	};

	return (
		<section
			className={clsx(
				'mdMax:mt-0 mdMax:pt-7.5 my-0 mx-auto',
				theme ? `bg-${theme}` : 'bg-white'
			)}
		>
			<div
				className={clsx(
					'mdMax:flex-wrap mdMax:flex-row my-0 mx-auto flex max-w-297.5',
					videoRight ? 'flex-row-reverse' : 'flex-row'
				)}
			>
				{media && media?.video && (
					<>
						<div
							className={clsx(
								'w-1/2 h-full inline-block mdMax:w-full promoWrapper'
							)}
						>
							{media.type === 'youtube' || media.type === 'twentythree' ? (
								<ResponsiveIframe
									src={getIframeSrc()}
									data-consent-src={`${media?.video.link}${
										media.video.autoplay ? '?autoplay=1&mute=1' : ''
									}`}
									data-category-consent="cookie_cat_marketing"
									title={`${media.type} - ${heading}`}
								/>
							) : (
								<>
									{media.videoPosterImage && (
										<img
											className={clsx(
												'w-full max-h-300 object-cover object-center absolute h-full',
												canPlay ? 'animate-fadeOut' : ''
											)}
											src={media.videoPosterImage}
											alt="placeholder loading video"
										/>
									)}
									<video
										playsInline
										className="md:h-115 w-full object-cover object-center md:max-h-full max-h-45"
										autoPlay={media?.video.autoplay}
										muted
										loop
										onClick={handleClick}
										onCanPlayThrough={(e) => {
											setCanPlay(true);
											if (e.currentTarget && e.currentTarget.play) {
												e.currentTarget.play();
											}
										}}
									>
										<source src={media?.video.link} type="video/mp4" />
									</video>
								</>
							)}
						</div>
						<PromoContent
							videoPreference={media?.videoPreference}
							theme={theme}
							contentLeft={videoRight}
							{...props}
						/>
					</>
				)}
			</div>
		</section>
	);
};

export default PromoVideoOutside;
