/**
 * CustomerModal
 * @module components/CustomerModal
 */

import { useContext, useEffect, useMemo, useState } from 'react';
import { LocalizationContext } from 'context/localization.context';
import Modal from 'components/ui/Modal';
import { useSortBy, useTable } from 'react-table';
import { handleKeyboardNavigation, handleSortByKeyDown } from '../Table';

import {
	SortAscIcon,
	SortDscIcon,
	SortWrapper,
	Table,
	TableData,
	TableHead,
	TableHeadContent,
	TableHeadRow,
	TableRow,
	VisuallyHiddenCaption,
} from '../Table/table.styles';
import { FiLogIn } from 'react-icons/fi';
import { useDebounce } from 'hooks';
import { customerSearch } from 'api/customersearch';
import Icon from '../Icon';

interface Props {
	/** Toggle modal callback */
	onToggle: any;

	onChange: any;
}

const CustomerModal = ({ onToggle, onChange }: Props) => {
	const { t }: any = useContext(LocalizationContext);
	const [query, setQuery] = useState({ name: '', number: '', email: '' });
	const [customers, setCustomers] = useState([]);
	const [loading, setLoading] = useState(false);

	const onNameChange = (newName: string) => {
		setQuery((prevQuery) => ({
			...prevQuery,
			name: newName,
		}));
	};

	const onNumberChange = (newNumber: string) => {
		setQuery((prevQuery) => ({
			...prevQuery,
			number: newNumber,
		}));
	};

	const onEmailChange = (newEmail: string) => {
		setQuery((prevQuery) => ({
			...prevQuery,
			email: newEmail,
		}));
	};

	const getCustomers = async () => {
		setLoading(true);
		try {
			const data = await customerSearch('/sv/api/v2', query);

			if (data) {
				setCustomers(data.items);
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const handleSearch = () => {
		if (query.email !== '' || query.name !== '' || query.number !== '') {
			getCustomers();
		} else {
			setCustomers([]);
		}
	};

	const data = useMemo(() => customers, [customers]);

	const columns = useMemo(
		() => [
			{ Header: t('shared/header/customernumber'), accessor: 'externId' },
			{ Header: t('shared/header/companyname'), accessor: 'name' },
			{ Header: t('shared/header/email'), accessor: 'email' },
		],
		//eslint-disable-next-line
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable(
		{
			columns,
			data,
		},
		useSortBy
	);

	const handleCustomerClick = (e: React.MouseEvent, item: any) => {
		if (item.name) {
			e.preventDefault();
		}

		if (onChange) {
			onChange(item);
		}

		onToggle();
	};

	const handleKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			handleSearch();
		}
	};

	return (
		<Modal
			toggleModal={onToggle}
			heading={t('shared/header/searchcustomer')}
			title={t('shared/header/searchcustomer')}
			width="large"
		>
			<div style={{ minHeight: 300 }}>
				<div className="flex md:flex-row flex-col items-center justify-between mb-10">
					<div className="flex md:flex-row flex-col items-center justify-between w-full gap-2">
						<input
							onKeyPress={handleKeyPress}
							onChange={(e) => onNumberChange(e.target.value)}
							type="text"
							className="border rounded-md px-3 py-2 text-sm col-3 w-full"
							placeholder={t('shared/header/customernumber')}
						/>

						<input
							onKeyPress={handleKeyPress}
							onChange={(e) => onNameChange(e.target.value)}
							type="text"
							className="border rounded-md px-3 py-2 text-sm col-3 w-full"
							placeholder={t('shared/header/companyname')}
						/>

						<input
							onKeyPress={handleKeyPress}
							onChange={(e) => onEmailChange(e.target.value)}
							type="text"
							className="border rounded-md px-3 py-2 text-sm col-3 w-full"
							placeholder={t('shared/header/email')}
						/>
					</div>
					<button
						onClick={() => handleSearch()}
						className="md:ml-8 w-full md:w-auto mt-5 md:mt-0 text-xsm text-white hover:bg-blue-hover font-standard button bg-blue border-blue border-2 border-solid font-bold align-middle text-center cursor-pointer inline-block uppercase py-2 px-3 rounded-lg no-underline smMax:py-0.5 smMax:px-1.5 smMax:text-sm disabled:text-greyDarker disabled:bg-grey disabled:border-grey disabled:border-solid disabled:border-2 smMax:undefined"
					>
						{t('shared/header/search')}
					</button>
				</div>
				{loading ? (
					<div className="text-center md:mt-25 mt-10">
						<Icon icon="loader" animate="spin" size={5} aria-hidden={true} />
					</div>
				) : (
					<>
						{customers.length > 0 ? (
							<div
								style={{ maxHeight: 300, overflowY: 'auto', paddingRight: 8 }}
							>
								<Table {...getTableProps({ role: 'grid' })}>
									<VisuallyHiddenCaption>
										${t('shared/header/searchresult')}
									</VisuallyHiddenCaption>
									<TableHeadRow style={{ position: 'sticky', top: 0 }}>
										{headerGroups.map((headerGroup) => (
											<TableHead {...headerGroup.getHeaderGroupProps()}>
												{headerGroup.headers.map((column, i) => (
													<TableHeadContent
														{...column.getHeaderProps(
															column.getSortByToggleProps({
																title: `${t('shared/table/sortby')} ${
																	column.Header
																}`,
																role: 'button',
																tabIndex: 0,
															})
														)}
														aria-sort={
															column.isSorted && !column.isSortedDesc
																? t('shared/table/ascending')
																: t('shared/table/descending')
														}
														data-header-col={i}
														onKeyDown={(e) => {
															if (column.canSort) {
																return handleSortByKeyDown(
																	e,
																	column,
																	i,
																	rows.length,
																	columns.length
																);
															}
														}}
													>
														{column.render('Header')}
														<SortWrapper>
															<SortAscIcon
																isSorted={column.isSorted}
																isDescending={column.isSortedDesc}
															/>
															<SortDscIcon
																isSorted={column.isSorted}
																isDescending={column.isSortedDesc}
															/>
														</SortWrapper>
													</TableHeadContent>
												))}
												<TableHeadContent></TableHeadContent>
											</TableHead>
										))}
									</TableHeadRow>
									<tbody {...getTableBodyProps()}>
										{rows.map((row, i) => {
											prepareRow(row);
											return (
												<TableRow {...row.getRowProps()}>
													{row.cells.map((cell: any, index) => {
														return (
															<td
																{...cell.getCellProps({
																	tabIndex: i === 0 && index === 0 ? 0 : -1,
																})}
																onKeyDown={(e) => {
																	return handleKeyboardNavigation(
																		e,
																		rows.length,
																		columns.length,
																		i
																	);
																}}
																data-row={i}
																data-col={index}
															>
																<TableData>{cell.render('Cell')}</TableData>
															</td>
														);
													})}
													<td>
														<button
															onClick={(e) =>
																handleCustomerClick(e, row.original)
															}
															className="mx-auto w-full text-xsm text-white hover:bg-orange-hover font-standard button bg-orange border-orange border-2 border-solid font-bold align-middle text-center cursor-pointer inline-block uppercase py-1 px-2 rounded-lg"
														>
															<FiLogIn size={14} />{' '}
															{t('shared/header/switchtocustomer')}
														</button>
													</td>
												</TableRow>
											);
										})}
									</tbody>
								</Table>
							</div>
						) : (
							<h4 className="text-center md:mt-25 mt-10">
								{t('shared/header/customermodaltext')}
							</h4>
						)}
					</>
				)}
			</div>
		</Modal>
	);
};

export default CustomerModal;
