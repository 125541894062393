/**
 * MyNewsDeskListPage
 * @module pages/MyNewsDeskListPage
 */
import { useReducer, useEffect, useState, useRef } from 'react';
import Layout from 'components/ui/Layout';
import ContentArea from 'components/framework/ContentArea';
import Breadcrumbs from 'components/framework/Breadcrumbs';
import ImageBlock from 'components/framework/ImageBlock';
import { Grid, Cell } from 'components/ui/Grid';
import { usePressRelease } from 'api';
import { NewsBlockItemModel } from 'types/block-types';
import { FacetItemModel, FacetsItemModel } from 'types/filter-types';
import { MyNewsDeskListPageModel } from 'types/page-types';
import listFilterReducer from 'reducers/listFilterReducer';
import {
	composeQueryString,
	renderElementBasedOnEditMode,
	setUrlLocation,
} from 'common/helpers';
import IconSquare from 'components/ui/Icon/icons/IconSquare';
import IconCheckSquare from 'components/ui/Icon/icons/IconCheckSquare';
import {
	Select,
	DefaultCheckbox,
} from '../CisionPressReleaseListPage/CisionPressReleaseListPage.styles';
import RichText from 'components/ui/RichText';
import clsx from 'clsx';

const MyNewsDeskListPage = ({
	heading,
	breadcrumbs,
	isInEditMode,
	text,
	items,
	pagination,
	contentArea,
	filter,
}: MyNewsDeskListPageModel) => {
	const [query, setQuery] = useState<string | null>();
	const preventHistoryEntry = useRef(false);

	let didRender = useRef(false);
	const [response] = usePressRelease(
		query,
		window && window.location ? window.location.pathname : null
	);

	const [state, dispatch] = useReducer(listFilterReducer, {
		sortBy: filter.sortBy,
		group: undefined,
		display: 21,
		query: filter.query,
		currentPage: 1,
		facets: filter.facets as FacetsItemModel[],
	});

	useEffect(() => {
		const hasRendered = didRender.current;
		const queryString = composeQueryString(
			state.query,
			state.sortBy,
			state.display,
			state.currentPage,
			state.facets
		);
		// Only set the query to fetch new data if it's not the first render.
		didRender.current
			? setQuery(queryString ? queryString : null)
			: (didRender.current = !didRender.current);
		if (queryString !== query) {
			setUrlLocation(
				queryString,
				state.query,
				state.sortBy,
				state.display,
				state.currentPage,
				state.facets,
				null,
				hasRendered,
				preventHistoryEntry.current
			);
		}
	}, [query, state]);

	const handleFacet = (
		facetId: string,
		itemId: string,
		value: boolean,
		isSelect = false
	) => {
		const facet = state.facets?.find(
			(f: FacetsItemModel) => f.id === facetId
		) as FacetsItemModel;
		const item = facet.items.find((i: FacetItemModel) => i.id === itemId);
		if (isSelect) {
			facet.items.forEach((item) => {
				item.active = false;
			});
		}
		item && (item.active = value);
		//@ts-ignore
		dispatch({ type: 'FACETS', facets: state.facets });
	};

	const itemArray = (response.items as NewsBlockItemModel[]) || items;

	return (
		<main id="main-content">
			<Layout backgroundColor="white">
				{breadcrumbs && <Breadcrumbs className="" links={breadcrumbs} />}
				<Grid padding={false}>
					<Cell span={12} desktop={8} className="mr-4/12">
						{heading &&
							renderElementBasedOnEditMode(
								isInEditMode,
								'heading',
								'h1',
								{
									className:
										'font-standard mb-6 mt-2 font-regular text-h2 md:mt-8 md:text-h1 text-blue',
								},
								heading
							)}
					</Cell>
					<Cell span={12}>
						{text && (
							<RichText name="text" text={text} isInEditMode={isInEditMode} />
						)}
					</Cell>
					<Cell className="flex justify-end" span={12}>
						<form>
							{filter &&
								filter.facets &&
								filter.facets.length > 0 &&
								filter.facets.map((facet: any, index: number) => {
									return (
										<div
											className={clsx(
												'mb-4',
												facet.type === 'dropdown' &&
													'smMax:w-full w-65 inline-block ml-8 smMax:ml-0'
											)}
											key={index}
										>
											{facet.type === 'checkbox' ? (
												<fieldset
													className="border-none p-0 mb-6"
													aria-live="polite"
												>
													{facet.name && (
														<legend className="text-2xl leading-9 font-bold mb-3">
															{facet.name}
														</legend>
													)}
													{facet.items &&
														facet.items.length > 0 &&
														facet.items.map((item: any, key: number) => (
															<label
																className="block relative"
																htmlFor={item.id}
																key={key}
															>
																<DefaultCheckbox
																	type="checkbox"
																	value={item.id}
																	name={item.name}
																	id={item.id}
																	checked={item.active}
																	onChange={() => {
																		handleFacet(
																			facet.id,
																			item.id,
																			!item.active
																		);
																	}}
																/>
																<IconSquare
																	className="mt-0.5 absolute left-0 text-blue"
																	aria-hidden={true}
																/>
																<IconCheckSquare
																	className="mt-0.5 absolute left-0 hidden text-blue"
																	aria-hidden={true}
																/>
																<span className="text-3xl ml ml-3">
																	{item.name}
																</span>
															</label>
														))}
												</fieldset>
											) : facet.type === 'dropdown' ? (
												<div>
													{facet.name && (
														<label className="text-2xl leading-9 font-bold block mb-3">
															{facet.name}
														</label>
													)}
													{facet.items && facet.items.length > 0 && (
														<Select
															onChange={(
																event: React.ChangeEvent<HTMLSelectElement>
															): void =>
																handleFacet(
																	facet.id,
																	event.target.value,
																	true,
																	true
																)
															}
														>
															{facet.items.map((item: any, key: number) => (
																<option value={item.id} key={key}>
																	{item.name}
																</option>
															))}
														</Select>
													)}
												</div>
											) : (
												false
											)}
										</div>
									);
								})}
						</form>
					</Cell>
				</Grid>
				<Grid padding={false} className="mb-5 md:mb-12">
					{itemArray &&
						itemArray.length > 0 &&
						itemArray.map((item, index: number) => {
							return (
								<Cell
									span={12}
									tablet={6}
									desktop={4}
									key={index}
									className="my-5"
								>
									<ImageBlock
										link={item.link}
										heading={item.heading}
										preamble={item.preamble}
										image={item.image}
										highlight={item.highlight}
										publishedDate={item.published}
										headingSize="large"
									/>
								</Cell>
							);
						})}
				</Grid>
			</Layout>

			<ContentArea contentArea={contentArea} />
		</main>
	);
};

export default MyNewsDeskListPage;
