import { SelectField } from 'components/ui/Form/Form.styles';
import Pager from 'components/ui/Pager';
import { FaCheckSquare } from 'react-icons/fa';
import { FiSquare } from 'react-icons/fi';
import styled from 'styled-components';
import { blue } from 'theme/colors';
import { deviceSmallMax } from 'theme/mediaQueries';
import { spacing1x, spacing2x, spacing5x } from 'theme/spacings';

export const ContentWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;

	@media (max-width: ${deviceSmallMax}) {
		display: block;
	}
`;

export const RightContentWrapper = styled.div`
	width: 25%;
	@media (max-width: ${deviceSmallMax}) {
		width: 100%;
	}
`;

export const LeftContentWrapper = styled.div`
	width: 66%;
	@media (max-width: ${deviceSmallMax}) {
		width: 100%;
	}
`;

export const Fieldset = styled.fieldset`
	border: 0;
	padding: 0;
	margin-bottom: ${spacing2x};
`;

export const Legend = styled.legend`
	font-size: 1.6rem;
	line-height: 2.4rem;
	font-weight: bold;
	margin-bottom: ${spacing1x};
`;

export const Label = styled.label`
	display: block;
	position: relative;
`;

export const SquareIcon = styled(FiSquare)`
	color: ${blue};
	margin-top: 0.1rem;
	position: absolute;
	left: 0;
`;

export const SquareCheckIcon = styled(FaCheckSquare)`
	display: none;
	color: ${blue};
	margin-top: 0.1rem;
	position: absolute;
	left: 0;
`;

export const DefaultCheckbox = styled.input`
	opacity: 0;
	position: relative;

	:checked + ${SquareIcon} {
		display: none;
	}

	:checked ~ ${SquareCheckIcon} {
		display: inline-block;
	}
`;

export const Span = styled.span`
	font-size: 1.8rem;
	line-height: 2.2rem;
	margin-left: ${spacing1x};
`;

export const SelectWrapper = styled.div`
	margin-bottom: ${spacing2x};
`;

export const SelectLabel = styled.label`
	font-size: 1.6rem;
	line-height: 2.4rem;
	font-weight: bold;
	display: block;
	margin-bottom: ${spacing1x};
`;

export const Select = styled(SelectField)``;

export const PagerWrapper = styled(Pager)`
	margin: ${spacing5x} 0;
`;
