/**
 * ProductMatrixRow
 */

import { usePrevious } from 'hooks';
import React, { useEffect, useState } from 'react';
import {
	ProductMatrixPropertyModel,
	ProductMatrixRowModel,
} from 'types/matrix-types';

interface Props {
	/** An article row */
	row: ProductMatrixRowModel;

	/** Array with table headers */
	headers: ProductMatrixPropertyModel[];

	visibleColumns: string[];

	/** Callback function for quantity change */
	onBlur: Function;
}

/** Main description for this component. */
const ProductMatrixRow = ({
	row,
	headers,
	visibleColumns,
	onBlur,
}: Props): JSX.Element => {
	// Store quantity value when on change
	const [displayQuantity, setDisplayQuantity] = useState<number>(row.quantity);

	// Store quantity value when on blur
	const [blurQuantity, setBlurQuantity] = useState<number>(row.quantity);

	// Get previous on blur quantity value
	const previousBlurQuantity = usePrevious(blurQuantity);

	const handleQuantityChange = (e: any) => {
		let value: number = parseInt(e.target.value);

		setDisplayQuantity(value);
	};
	const handleOnBlur = (e: any) => {
		let currentValue: number = displayQuantity;
		if (currentValue < 0 || isNaN(currentValue)) {
			currentValue = 0;
		}
		if (currentValue !== previousBlurQuantity) {
			onBlur(row.articleNumber, row.source, previousBlurQuantity, currentValue);
		}
		setDisplayQuantity(currentValue);
		setBlurQuantity(currentValue);
	};

	const getColSpan = () => {
		let name = visibleColumns?.includes('name');
		let articleNumber = visibleColumns?.includes('articleNumber');

		if (name && articleNumber) return 3;
		else if (!name && !articleNumber) return 5;
		else return 4;
	};

	const renderColumn = (column: string) => {
		let isVisible = visibleColumns?.includes(column);

		switch (column) {
			case 'name':
			case 'articleNumber':
				if (isVisible) {
					return (
						<td className="py-3 pr-3 pl-2 whitespace-nowrap">
							{(row as any)[column]}
						</td>
					);
				}
				break;

			case 'customizedText':
				if ((row as any)[column]) {
					return (
						<td className="py-3 pr-3 pl-2" colSpan={getColSpan()}>
							{isVisible && (row as any)[column]}
						</td>
					);
				}
				break;
			case 'dimension':
			case 'length':
			case 'material':
				if (!(row as any)['customizedText']) {
					if (isVisible) {
						return <td className="py-3 pr-3 pl-2">{(row as any)[column]}</td>;
					}
				}
				break;
			case 'quantity':
				let hiddenColumns = headers?.length - visibleColumns?.length;
				if (!visibleColumns?.includes('customizedText')) {
					hiddenColumns = hiddenColumns - 1;
				}
				return (
					<>
						{!(row as any)['customizedText'] && hiddenColumns > 0 && (
							<td className="py-3 pr-3 pl-2" colSpan={hiddenColumns}></td>
						)}
						<td className="float-right pt-2">
							<input
								type="number"
								aria-label={'quantity'}
								value={displayQuantity}
								className="text-right w-12 min-w-12 p-1 rounded"
								defaultValue={(row as any)[column]}
								onChange={handleQuantityChange}
								onBlur={handleOnBlur}
							/>
						</td>
					</>
				);

			default:
				break;
		}
	};

	useEffect(() => {
		setDisplayQuantity(row.quantity);
		setBlurQuantity(row.quantity);
	}, [row]);

	return (
		<tr>
			{headers?.map((column, index) => {
				return (
					<React.Fragment key={index}>
						{renderColumn(column.name)}
					</React.Fragment>
				);
			})}
		</tr>
	);
};

export default ProductMatrixRow;
