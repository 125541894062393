/**
 * MegaMenu
 * @module components/MegaMenu
 */
import { useState, useContext } from 'react';
import { LocalizationContext } from 'context/localization.context';
import MegaMenuItem from './MegaMenuItem';
import { useDebounce } from 'hooks';
import { MegaMenuItemModel } from 'types/common';
interface Props {
	menuItems: MegaMenuItemModel[];
}
const MegaMenu: React.FC<Props> = ({ menuItems }) => {
	const { t }: any = useContext(LocalizationContext);
	const [currentExpanded, setCurrentExpanded] = useState(-1);
	const debouncedCurrentExpanded = useDebounce(currentExpanded, 10);
	return (
		<div
			className="bg-blue overflow-hidden"
			id="menu"
			aria-label={t('shared/header/menuarialabel')}
		>
			<ul className="text-p">
				{menuItems.map((item: MegaMenuItemModel, i: number) => {
					return (
						<MegaMenuItem
							item={item}
							key={i}
							index={i}
							setCurrentExpanded={setCurrentExpanded}
							currentExpanded={debouncedCurrentExpanded}
						/>
					);
				})}
			</ul>
		</div>
	);
};
export default MegaMenu;
