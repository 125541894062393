/**
 * MyAddressesPage
 * @module pages/MyAddressesPage
 */

import React, { useMemo, useContext, useState, useRef } from 'react';
import { LocalizationContext } from 'context/localization.context';
import { useTable, useSortBy } from 'react-table';
import { saveAddressChanges, deleteAddress } from 'api/addresses';
import {
	handleSortByKeyDown,
	handleKeyboardNavigation,
} from 'components/ui/Table';

import ContentArea from 'components/framework/ContentArea';
import SectionHeader from 'components/ui/SectionHeader';
import Modal from 'components/ui/Modal';
import Layout from 'components/ui/Layout';
import Breadcrumbs from 'components/framework/Breadcrumbs';

import {
	Table,
	TableHeadRow,
	TableHead,
	TableHeadContent,
	TableData,
	TableRow,
	SortWrapper,
	SortAscIcon,
	SortDscIcon,
} from 'components/ui/Table/table.styles';

import {
	Body,
	VisuallyHidden,
	DeleteAddressButton,
	DeleteAddressIcon,
	EditAddressLink,
	ModalInputWrapper,
	ModalInputField,
	ModalTextArea,
	ModalLabel,
	SaveChangesButton,
	SaveChangesButtonWrapper,
	DeleteDialogButtonWrapper,
	DeleteDialogButton,
	UndeletableButton,
	UndeletablePopout,
	DeleteDialogMessage,
	ErrorText,
	ErrorTextWrapper,
} from './MyAddressesPage.styles';
import { toast } from 'react-toastify';
import { FiTrash2 } from 'react-icons/fi';

const MyAddressesPage = ({
	addresses,
	breadcrumbs,
	contentArea,
	heading,
	text,
	editLink,
	deleteLink,
	isInEditMode,
}) => {
	const { t } = useContext(LocalizationContext);
	const [addressesState, setAddressesState] = useState(addresses);
	const [modalItem, setModalItem] = useState(null);
	const [deleteItem, setDeleteItem] = useState(null);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const data = useMemo(() => addressesState, [addressesState]);
	const columns = useMemo(
		() => [
			{ Header: t('myaddressespage/name'), accessor: 'name' },
			{ Header: t('myaddressespage/customer'), accessor: 'organizationName' },
			{ Header: t('myaddressespage/address'), accessor: 'street' },
			{ Header: t('myaddressespage/postalcode'), accessor: 'postalCode' },
			{ Header: t('myaddressespage/city'), accessor: 'city' },
		],
		//eslint-disable-next-line
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable(
		{
			columns,
			data,
		},
		useSortBy
	);

	const closeModal = () => {
		setModalItem(null);
	};

	const handleModalInputChange = (e) => {
		const { item } = modalItem;
		const input = document.getElementById(e.target.name);

		const newItem = { ...item };
		newItem[e.target.name] = e.target.value.trimStart();
		if (e.target.value.trimStart() === '') {
			input && input.setCustomValidity('invalid');
			document.getElementById(`val-msg-${e.target.name}`).innerText = t(
				'shared/form/requiredfieldmessage'
			);
		} else {
			input && input.setCustomValidity('');
			document.getElementById(`val-msg-${e.target.name}`).innerText = '';
		}
		setModalItem({ ...modalItem, item: newItem });
	};

	const showToast = (message, isSuccess) => {
		toast(message, {
			type: isSuccess ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
			closeDelay: 1500,
		});
	};

	const validateAddressChanges = () => {
		const modal = document.querySelector('#editaddressmodal');
		const elements = modal.querySelectorAll('input,textarea');
		const invalidElement =
			elements &&
			Array.from(elements).find((el) => el.value.trimStart() === '');

		if (invalidElement) {
			invalidElement.focus();
			return false;
		}

		return true;
	};

	const handleSaveChanges = (e) => {
		e.preventDefault();
		if (
			modalItem &&
			modalItem.item &&
			modalItem.item.isEditable &&
			validateAddressChanges(modalItem)
		) {
			setIsLoading(true);
			saveAddressChanges(editLink, modalItem.item).then((response) => {
				if (response === true) {
					const newState = [...addressesState];
					const changedItem = newState.find(
						(a) => a.addressId === modalItem.item.addressId
					);
					Object.keys(changedItem).forEach((key) => {
						changedItem[key] = modalItem.item[key];
					});
					setAddressesState(newState);
					closeModal();
					setModalItem(null);
					showToast(t('myaddressespage/adresseditedmessage'), true);
					setIsLoading(false);
				} else {
					showToast(t('shared/toast/servererror'));
					setIsLoading(false);
				}
			});
		}
	};

	const handleDeleteAddress = (addressId, organizationId) => {
		setIsLoading(true);
		deleteAddress(deleteLink, addressId, organizationId).then((response) => {
			if (response === true) {
				const newState = [...addressesState].filter(
					(a) => a.addressId !== addressId
				);
				setAddressesState(newState);
				showToast(t('myaddressespage/removedaddressmessage'), true);
				setIsLoading(false);
				setDeleteDialogOpen(false);
				setDeleteItem(null);
			} else {
				showToast(t('shared/toast/servererror'));
				setIsLoading(false);
			}
		});
	};
	const popoutRef = useRef();
	const handleUndeletablePopout = (e) => {
		const element = e.currentTarget;
		if (element) {
			const domRect = element.getBoundingClientRect();
			if (domRect && popoutRef.current && window) {
				popoutRef.current.style.top = `${domRect.top + window.scrollY + 15}px`;
				popoutRef.current.style.left = `${
					domRect.right - popoutRef.current.scrollWidth / 2
				}px`;
				popoutRef.current.style.visibility = 'visible';
			}
		}
	};

	return (
		<main id="main-content">
			<Layout backgroundColor="white">
				{breadcrumbs && <Breadcrumbs links={breadcrumbs} />}
			</Layout>
			<SectionHeader
				heading={heading}
				text={text}
				size={'large'}
				isInEditMode={isInEditMode}
			/>
			<Layout backgroundColor="white">
				<Body>
					<Table {...getTableProps({ role: 'grid' })}>
						<VisuallyHidden as="caption">{heading}</VisuallyHidden>
						<TableHeadRow>
							{headerGroups.map((headerGroup) => (
								<TableHead {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column, i) => (
										<TableHeadContent
											{...column.getHeaderProps(
												column.getSortByToggleProps({
													title: `${t('shared/table/sortby')} ${column.Header}`,
													role: 'button',
													tabIndex: 0,
												})
											)}
											aria-sort={
												column.isSorted && !column.isSortedDesc
													? t('shared/table/ascending')
													: t('shared/table/descending')
											}
											data-header-col={i}
											onKeyDown={(e) => {
												if (column.canSort) {
													return handleSortByKeyDown(
														e,
														column,
														i,
														rows.length,
														columns.length
													);
												}
											}}
										>
											{column.render('Header')}
											<SortWrapper>
												<SortAscIcon
													isSorted={column.isSorted}
													isDescending={column.isSortedDesc}
												/>
												<SortDscIcon
													isSorted={column.isSorted}
													isDescending={column.isSortedDesc}
												/>
											</SortWrapper>
										</TableHeadContent>
									))}
									<TableHeadContent
										tabIndex={0}
										data-header-col={data && data.length + 2}
										onKeyDown={(e) => {
											return handleSortByKeyDown(
												e,
												null,
												data.length + 2,
												rows.length,
												columns.length
											);
										}}
									>
										<VisuallyHidden>
											{t('myaddressespage/deleteaddress')}
										</VisuallyHidden>
									</TableHeadContent>
								</TableHead>
							))}
						</TableHeadRow>
						<tbody {...getTableBodyProps()}>
							{rows.map((row, i) => {
								prepareRow(row);
								const original = row.original;
								return (
									<TableRow {...row.getRowProps()}>
										{row.cells.map((cell, index) => {
											return (
												<td
													{...cell.getCellProps({
														tabIndex: i === 0 && index === 0 ? 0 : -1,
													})}
													onKeyDown={(e) => {
														return handleKeyboardNavigation(
															e,
															rows.length,
															columns.length,
															i
														);
													}}
													data-row={i}
													data-col={index}
												>
													{cell.column.id === 'name' ? (
														<TableData>
															<EditAddressLink
																href="#"
																onClick={() => {
																	setModalItem({
																		item: original,
																		isOpen: true,
																	});
																}}
															>
																{cell.value}
															</EditAddressLink>
														</TableData>
													) : (
														<TableData>{cell.render('Cell')}</TableData>
													)}
												</td>
											);
										})}
										<td
											role="cell"
											data-row={i}
											data-col={row.cells.length}
											onKeyDown={(e) => {
												return handleKeyboardNavigation(
													e,
													rows.length,
													columns.length,
													i
												);
											}}
										>
											{original && original.isEditable ? (
												<DeleteAddressButton
													title={t('myaddressespage/deleteaddress')}
													onClick={(e) => {
														e.preventDefault();
														setDeleteItem(original);
														setDeleteDialogOpen(true);
													}}
													onKeyDown={(e) => {
														if (e.key === 'Enter' || e.key === ' ') {
															setDeleteItem(original);
															setDeleteDialogOpen(true);
														}
													}}
												>
													<VisuallyHidden>
														{t('myaddressespage/deleteaddress')}
													</VisuallyHidden>
													<DeleteAddressIcon />
												</DeleteAddressButton>
											) : (
												<UndeletableButton
													onClick={handleUndeletablePopout}
													onMouseEnter={handleUndeletablePopout}
													onMouseLeave={(e) => {
														popoutRef.current.style.visibility = 'hidden';
													}}
												>
													<VisuallyHidden>
														{t('myaddressespage/undeletableaddressmessage')}
													</VisuallyHidden>
													<FiTrash2 />
												</UndeletableButton>
											)}
										</td>
									</TableRow>
								);
							})}
						</tbody>
					</Table>
				</Body>
			</Layout>
			<UndeletablePopout
				style={{ visibility: 'hidden' }}
				aria-hidden={true}
				ref={popoutRef}
			>
				{t('myaddressespage/undeletableaddressmessage')}
			</UndeletablePopout>
			<ContentArea contentArea={contentArea} />
			{modalItem && modalItem.isOpen && (
				<Modal
					title={t('myaddressespage/editaddress')}
					heading={t('myaddressespage/editaddress')}
					width="medium"
					toggleModal={closeModal}
				>
					<form
						action={editLink}
						method="post"
						id="editaddressmodal"
						onSubmit={handleSaveChanges}
						autoComplete="off"
					>
						<ModalLabel htmlFor={'name'}>
							{t('myaddressespage/name')}
						</ModalLabel>
						<ModalInputField
							id="name"
							name="name"
							aria-describedby="val-msg-name"
							value={modalItem && modalItem.item.name}
							onChange={handleModalInputChange}
							readOnly={
								modalItem && modalItem.item && !modalItem.item.isEditable
							}
							autoComplete="off"
							required
						/>
						<ErrorText
							id="val-msg-name"
							displayBlock
							aria-live="polite"
						></ErrorText>
						<ModalLabel htmlFor={'street'}>
							{t('myaddressespage/address')}
						</ModalLabel>
						<ModalTextArea
							id="street"
							name="street"
							aria-describedby="val-msg-street"
							value={modalItem && modalItem.item.street}
							onChange={handleModalInputChange}
							readOnly={
								modalItem && modalItem.item && !modalItem.item.isEditable
							}
							autoComplete="off"
							required
						/>
						<ErrorText
							id="val-msg-street"
							displayBlock
							aria-live="polite"
						></ErrorText>
						<ModalInputWrapper>
							<ModalLabel htmlFor={'postalCode'}>
								{t('myaddressespage/postalcode')}
							</ModalLabel>
							<ModalInputField
								id="postalCode"
								name="postalCode"
								aria-describedby="val-msg-postalCode"
								value={modalItem && modalItem.item.postalCode}
								onChange={handleModalInputChange}
								readOnly={
									modalItem && modalItem.item && !modalItem.item.isEditable
								}
								autoComplete="off"
								required
							/>
						</ModalInputWrapper>
						<ModalInputWrapper>
							<ModalLabel htmlFor={'city'}>
								{t('myaddressespage/city')}
							</ModalLabel>
							<ModalInputField
								id="city"
								name="city"
								aria-describedby="val-msg-city"
								value={modalItem && modalItem.item.city}
								onChange={handleModalInputChange}
								readOnly={
									modalItem && modalItem.item && !modalItem.item.isEditable
								}
								autoComplete="off"
								required
							/>
						</ModalInputWrapper>
						<div>
							<ErrorTextWrapper>
								<ErrorText
									id="val-msg-postalCode"
									displayBlock
									aria-live="polite"
								></ErrorText>
							</ErrorTextWrapper>
							<ErrorTextWrapper>
								<ErrorText
									id="val-msg-city"
									displayBlock
									aria-live="polite"
								></ErrorText>
							</ErrorTextWrapper>
						</div>
						<div>
							<ModalLabel htmlFor={'country'}>
								{t('myaddressespage/country')}
							</ModalLabel>
							<ModalInputField
								id="country"
								name="country"
								value={modalItem && modalItem.item.countryName}
								autoComplete="off"
								readOnly={true}
							/>
						</div>
						<SaveChangesButtonWrapper>
							<SaveChangesButton
								type="submit"
								disabled={
									isLoading ||
									(modalItem && modalItem.item && !modalItem.item.isEditable)
								}
								buttonColor="blue"
							>
								{isLoading
									? t('myaddressespage/savingchanges')
									: t('myaddressespage/savechanges')}
							</SaveChangesButton>
							`
						</SaveChangesButtonWrapper>
					</form>
				</Modal>
			)}
			{deleteDialogOpen && (
				<Modal
					title={t('myaddressespage/deletedialogtitle')}
					heading={t('myaddressespage/deletedialogtitle')}
					width="small"
					toggleModal={() => setDeleteDialogOpen(false)}
				>
					<DeleteDialogMessage>
						{t('myaddressespage/deletedialogmessage')}
					</DeleteDialogMessage>
					<DeleteDialogButtonWrapper>
						<DeleteDialogButton
							type="button"
							buttonColor="grey"
							onClick={() => {
								setDeleteItem(null);
								setDeleteDialogOpen(false);
							}}
							disabled={isLoading}
						>
							{t('myaddressespage/deletedialogcancel')}
						</DeleteDialogButton>
						<DeleteDialogButton
							type="button"
							buttonColor="blue"
							onClick={(e) => {
								handleDeleteAddress(
									deleteItem.addressId,
									deleteItem.organizationId
								);
							}}
							disabled={isLoading}
						>
							{t('myaddressespage/deleteaddress')}
						</DeleteDialogButton>
					</DeleteDialogButtonWrapper>
				</Modal>
			)}
		</main>
	);
};

export default MyAddressesPage;
