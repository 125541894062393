/**
 * CartPage
 */

import { useContext, useEffect, useRef, useState } from 'react';
import Layout from 'components/ui/Layout';
import { useDispatch, useSelector } from 'react-redux';
import {
	FetchCart,
	AddToCart,
	selectCartObject,
	ClearCart,
	SaveCart,
	UpdateCart,
	RemoveItem,
	selectCartLoading,
	AddNotes,
	selectCartPriceReloadCounter,
	cartUpdatePriceReloadCounter,
	PunchOut,
} from 'store/modules/cart';
import Breadcrumbs from 'components/framework/Breadcrumbs';

import { ShoppingCartPageModel } from 'types/page-types';
import {
	LinkModel,
	AddNotesModel,
	ErrorObjectModel,
	WarehouseModel,
} from 'types/common';
import { SortPropertyModel } from 'types/filter-types';
import { ArticleModel } from 'types/article';

import SectionHeader from 'components/ui/SectionHeader';
import CustomExternalScript from 'components/framework/CustomExternalScript';
import QuickAddToCart from 'components/ui/QuickAddToCart';
import FilterBar from 'components/ui/FilterBar';
import {
	CancelCartPriceError,
	GenericWarning,
	getSelectedDeliveryMethod,
	getURLParameter,
	PunchOutError,
	ReloadCartPriceError,
	SaveCartError,
	ServerError,
} from 'common/helpers';
import CartArticleRow from 'components/framework/CartArticleRow';
import { Cell, Grid } from 'components/ui/Grid';
import Tooltip from 'components/ui/Tooltip';
import DatePickerInput from 'components/ui/DatePickerInput';
import { getDay } from 'date-fns';
import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import SaveCartModal from 'components/ui/SaveCartModal';
import { toast } from 'react-toastify';
import QuickImportArticlesModal from 'components/ui/QuickImportArticlesModal';
import ArticleDetail from 'components/common/ArticleDetail';
import { selectUserObject } from 'store/modules/user';
import { LocalizationContext } from 'context/localization.context';
import ContentArea from 'components/framework/ContentArea';
import clsx from 'clsx';
import AddGoodsmarkModal from 'components/ui/AddGoodsmarkModal';
import AlertModal from 'components/ui/AlertModal';
import { DeliveryMethodModel } from 'types/cart-types';

const CartPage = ({
	heading,
	breadcrumbs,
	text,
	filter,
	desiredDeliveryDateTooltip,
	desiredPickupDateTooltip,
	leadTimeTooltip,
	checkoutButton,
	punchoutButton,
	saveButton,
	deleteButton,
	framework,
	links,
	contentArea,
	exportLinks,
}: ShoppingCartPageModel): JSX.Element => {
	const {
		cartStatus,
		errorList,
		withPrice,
		articleList,
		total,
		desiredStartDate,
		desiredDate,
		leadTime,
		availableDeliveryMethods,
		locations,
		goodsMarkingHidden,
		singleShipment,
	} = useSelector(selectCartObject);
	const cartObject = useSelector(selectCartObject);
	const cartLoading = useSelector(selectCartLoading);
	const cartPriceReloadCounter = useSelector(selectCartPriceReloadCounter);
	const userObject = useSelector(selectUserObject);
	const { t } = useContext<any>(LocalizationContext);
	const dispatch = useDispatch();
	const didRender = useRef(false);
	const [goodsmarkModalOpen, setGoodsmarkModalOpen] = useState(false);
	const [clearGoodsmarkModalOpen, setClearGoodsmarkModalOpen] = useState(false);
	const [importArticlesModalOpen, setImportArticlesModalOpen] = useState(false);
	const [emptyCartModalOpen, setEmptyCartModalOpen] = useState(false);
	const [saveCartModalOpen, setSaveCartModalOpen] = useState(false);
	const [articleModal, setArticleModal] = useState({
		isActive: false,
		link: null,
	});
	const [redirectingToCheckout, setRedirectingToCheckout] = useState(false);
	const [redirectingToPunchout, setRedirectingToPunchout] = useState(false);
	const [overwriteCartModal, setOverwriteCartModal] = useState({
		isActive: false,
		cartName: '',
		message: '',
	});
	const [reloadPricesModal, setReloadPricesModal] = useState({
		isActive: false,
		hasReloadButton: true,
		message: '',
	});
	// null: field untouched, "": toutched & valid, "error message": touched & invalid
	const [errorMessages, setErrorMessages] = useState<{
		[key: string]: string | null;
	}>({
		name: null,
		city: null,
		postalCode: null,
		street: null,
		goodsReceptionContactName: null,
		goodsReceptionPhoneNumber: null,
		projectName: null,
		projectNumber: null,
		comment: null,
		customerRequisition: null,
	});

	const sortDefault = getURLParameter('sort') || filter?.sortBy;
	const [sortSelection, setSortSelection] = useState<any>(sortDefault);

	// Temp variables

	const hasArticles = articleList && articleList.length > 0;

	const getInitialCheckedBoxes = () => {
		let initArticleList: any = [];
		articleList?.forEach((item: ArticleModel) => {
			initArticleList.push({
				itemCode: item.itemCode,
				lineItemId: item.lineItemId,
				comments: item.notes.comments || '',
				selected: false,
			});
		});
		return initArticleList;
	};
	const [toolbarActive, setToolbarActive] = useState(false);
	const [checkedBoxes, setCheckedBoxes] = useState<
		{
			itemCode: string;
			lineItemId: number;
			comments: string;
			selected: boolean;
		}[]
	>(getInitialCheckedBoxes());

	const isAllChecked = () => {
		for (let index in checkedBoxes) {
			if (!checkedBoxes[index].selected) {
				return false;
			}
		}
		return true;
	};

	const isAtLeastOneChecked = () => {
		for (let index in checkedBoxes) {
			if (checkedBoxes[index].selected) {
				return true;
			}
		}
		return false;
	};

	const handleCheckSingle = (itemCode: string, lineItemId: number) => {
		let newCheckedBoxes: any = [...checkedBoxes];

		for (let index in checkedBoxes) {
			if (
				checkedBoxes[index].itemCode === itemCode &&
				checkedBoxes[index].lineItemId === lineItemId
			) {
				newCheckedBoxes[index].selected = !checkedBoxes[index].selected;
				setCheckedBoxes(newCheckedBoxes);
				setToolbarActive(isAtLeastOneChecked());
			}
		}
	};

	const handleCheckAll = () => {
		let newCheckedBoxes: any = [];

		if (isAllChecked()) {
			setCheckedBoxes(getInitialCheckedBoxes());
			setToolbarActive(false);
		} else {
			articleList?.forEach((item: ArticleModel) => {
				newCheckedBoxes.push({
					itemCode: item.itemCode,
					lineItemId: item.lineItemId,
					comments: item.notes.comments || '',
					selected: true,
				});
			});
			setCheckedBoxes(newCheckedBoxes);
			setToolbarActive(true);
		}
	};
	const handleDeliveryMethodChange = async (e: any) => {
		try {
			await dispatch(
				UpdateCart({
					selectedSort: sortSelection,
					selectedDeliveryMethod: e.target.value,
				})
			);
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/servererror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		}
	};

	const adjustForTimezone = (date: Date) => {
		let timeOffsetInMS = date.getTimezoneOffset() * 60000;
		date.setTime(date.getTime() - timeOffsetInMS);
		return date;
	};
	const getDateString = (date: Date) => {
		return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
			'0' + date.getDate()
		).slice(-2)}`;
	};
	const handleDateChanged = async (date: Date) => {
		try {
			await dispatch(
				UpdateCart({
					selectedSort: sortSelection,
					selectedDesiredDate: getDateString(adjustForTimezone(date)),
				})
			);
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/servererror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		}
	};
	const handleWarehouseChange = async (e: any) => {
		try {
			await dispatch(
				UpdateCart({
					selectedSort: sortSelection,
					selectedLocation: e.target.value,
				})
			);
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/servererror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		}
	};

	const handleSortChange = async (sortBy: any) => {
		if (sortBy !== sortSelection) {
			window?.history?.pushState(
				{},
				document.title,
				`${window.location.pathname}?sort=${sortBy}`
			);
			setSortSelection(sortBy);

			try {
				await dispatch(UpdateCart({ selectedSort: sortBy }));
			} catch (err) {
				if (err instanceof ServerError) {
					toast(t('shared/toast/servererror'), { type: toast.TYPE.ERROR });
				} else if (err instanceof GenericWarning) {
					toast(err.message, { type: toast.TYPE.WARNING });
				}
			}
		}
	};

	const handlePunchout = async () => {
		setRedirectingToPunchout(true);
		let messages: { [key: string]: string | null } = { ...errorMessages };
		setErrorMessages(messages);
		try {
			const data = await dispatch(PunchOut());
			window.location.href = data.url;
		} catch (err) {
			if (err instanceof PunchOutError) {
				setRedirectingToPunchout(false);
				toast(err.message, { type: toast.TYPE.ERROR });
			} else if (err instanceof ServerError) {
				setRedirectingToPunchout(false);
				toast(err.message, { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				setRedirectingToPunchout(false);
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		}
	};

	const handleQuantityChange = async (
		item: ArticleModel,
		itemCode: string,
		currentQuantity: number
	) => {
		try {
			if (currentQuantity && currentQuantity !== item.quantity) {
				const articleList = [
					{
						itemCode: itemCode,
						quantity: currentQuantity,
						lineItemId: item.lineItemId,
						configId: item.configId,
						configDescription: item.configDescription,
					},
				];
				await dispatch(AddToCart('PATCH', articleList, sortSelection));
			}
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/cartupdateerror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		}
	};

	const handleClearCart = async () => {
		try {
			await dispatch(ClearCart());
			toast(t('cartpage/carthasbeenemptied'), {
				type: toast.TYPE.SUCCESS,
			});
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/servererror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		} finally {
			setEmptyCartModalOpen(false);
		}
	};

	const handleSaveCart = async (cartName: string, shared: boolean) => {
		try {
			await dispatch(SaveCart(cartName, false, shared));
			toast(t('shared/toast/savecartsuccess'), {
				type: toast.TYPE.SUCCESS,
			});
		} catch (err) {
			// SaveCartError - Cart already exists
			if (err instanceof SaveCartError) {
				setOverwriteCartModal({
					isActive: true,
					cartName,
					message: err.message,
				});
			} else if (err instanceof ServerError) {
				toast(t('shared/toast/savecarterror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		} finally {
			setSaveCartModalOpen(false);
			!withPrice && fetchCartObject();
		}
	};

	const handleOverwriteCart = async (shared: boolean) => {
		try {
			await dispatch(SaveCart(overwriteCartModal.cartName, true, shared));
			toast(t('shared/toast/savecartsuccess'), {
				type: toast.TYPE.SUCCESS,
			});
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/savecarterror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		} finally {
			setOverwriteCartModal({ ...overwriteCartModal, isActive: false });
		}
	};

	const handleRemoveArticle = async (itemCode: string, lineItemId: number) => {
		try {
			await dispatch(RemoveItem(itemCode, lineItemId, sortSelection));
			toast(t('shared/toast/cartdeletesuccess'), {
				type: toast.TYPE.SUCCESS,
			});
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/cartdeleteerror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		}
	};

	const handleSelectedGoodsmark = async (goodsmarking?: string) => {
		try {
			let notesArray: AddNotesModel[] = [];
			checkedBoxes?.forEach((item) => {
				if (item.selected) {
					notesArray.push({
						itemCode: item.itemCode,
						lineItemId: item.lineItemId,
						comments: item.comments,
						goodsMarking: goodsmarking || '',
					});
				}
			});
			await dispatch(AddNotes(notesArray, sortSelection));
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/cartaddnoteserror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		} finally {
			if (goodsmarkModalOpen) {
				setGoodsmarkModalOpen(false);
			} else if (clearGoodsmarkModalOpen) {
				setClearGoodsmarkModalOpen(false);
			}
			setCheckedBoxes(getInitialCheckedBoxes());
			setToolbarActive(false);
		}
	};

	const handleReloadPrices = async () => {
		try {
			await dispatch(cartUpdatePriceReloadCounter(cartPriceReloadCounter + 1));
			fetchCartObject();
		} catch (err) {
		} finally {
			setReloadPricesModal({ ...reloadPricesModal, isActive: false });
		}
	};

	const fetchCartObject = async () => {
		try {
			await dispatch(FetchCart(sortSelection));
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/servererror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof ReloadCartPriceError) {
				setReloadPricesModal({
					isActive: true,
					hasReloadButton: true,
					message: err.message,
				});
			} else if (err instanceof CancelCartPriceError) {
				setReloadPricesModal({
					isActive: true,
					hasReloadButton: false,
					message: err.message,
				});
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		}
	};

	const handleKeyDown = (e: any) => {
		if (e.target.type === 'checkbox' && e.keyCode === 13) {
			e.preventDefault();
			e.target.click();
		}
	};

	useEffect(() => {
		if (didRender.current) {
			fetchCartObject();
		}
		if (!didRender.current && typeof userObject !== 'undefined') {
			didRender.current = true;
		}
		//eslint-disable-next-line
	}, [userObject]);

	useEffect(() => {
		if (cartStatus === 'invalid' && errorList && errorList.length > 0) {
			errorList?.forEach((error: ErrorObjectModel) => {
				const errormsg = `Error ${error.errorCode} - ${error.message}`;
				toast(errormsg, {
					type: toast.TYPE.ERROR,
				});
			});
		}
		//eslint-disable-next-line
	}, [cartStatus]);

	useEffect(() => {
		setCheckedBoxes(getInitialCheckedBoxes());
		setToolbarActive(false);
		//eslint-disable-next-line
	}, [articleList]);

	useEffect(() => {
		if (withPrice === false) {
			fetchCartObject();
		}
		//eslint-disable-next-line
	}, [cartObject]);

	useEffect(() => {
		let items: any = [];

		articleList?.map((art) => {
			return items.push({
				item_name: art.itemName,
				item_id: art.itemCode,
				price: art.prices.grossPriceFormatted,
				item_variant: art.attributeName,
				quantity: art.quantity,
			});
		});

		if (window.dataLayer) {
			window.dataLayer.push({
				event: 'view_cart',
				domain: framework.header.activeMarket,
				ecommerce: {
					items: items,
				},
			});
		}
		//eslint-disable-next-line
	}, []);

	return (
		<>
			<main id="main-content">
				<Layout backgroundColor="white" className="overflow-visible">
					{breadcrumbs && <Breadcrumbs links={breadcrumbs} className="" />}
					<SectionHeader
						heading={heading}
						text={text}
						size={'large'}
						isInEditMode={false}
					/>
					<CustomExternalScript />
					<div className="w-full max-w-full py-2">
						<QuickAddToCart
							api={framework.api}
							sortSelection={sortSelection}
							fetchCartObject={fetchCartObject}
							activeMarket={framework.header.activeMarket}
						/>
						<Button
							className="smMax:min-w-full"
							noMarginBottom="mb-0"
							buttonColor="blue"
							onClick={(e) => {
								e.preventDefault();
								setImportArticlesModalOpen(true);
							}}
						>
							{t('quickadd/importfromexcel')}
						</Button>
						{links &&
							links.length > 0 &&
							links.map((item, index) => (
								<Button
									className="smMax:min-w-full md:ml-4"
									noMarginBottom="mb-2"
									type="link"
									buttonColor="blue"
									url={item.link}
									key={index}
									target={item.target}
								>
									{item.text}
								</Button>
							))}
					</div>
					<FilterBar
						sortByItems={filter?.sortProperties as SortPropertyModel[]}
						defaultSortByItem={sortSelection}
						onFilter={handleSortChange}
					/>
					{!goodsMarkingHidden && articleList && articleList.length > 0 && (
						<div className="md:px-4 py-4 flex border-b border-greyDarker">
							<label htmlFor="select-all-articles" className="flex">
								<input
									id="select-all-articles"
									type="checkbox"
									className="w-4.5 h-4.5"
									checked={isAllChecked() || false}
									onClick={() => handleCheckAll()}
								/>
								<span className="text-p align-top ml-2">
									{t('shared/goodsmark/selectallrows')}
								</span>
							</label>
							<div className="md:flex ml-4 md:ml-0">
								<p
									className={clsx(
										!toolbarActive && 'hidden',
										'text-p flex md:ml-6 md:pl-6 mb-1 md:mb-0 md:border-l md:border-black'
									)}
								>
									<span>
										<Icon
											icon="edit"
											className="mr-2"
											color="#2A2A2A"
											size={1.25}
										/>
									</span>
									<button
										onClick={(e) => {
											e.preventDefault();
											setGoodsmarkModalOpen(true);
										}}
										className="text-blue hover:underline"
									>
										{t('shared/goodsmark/editgoodsmark')}
									</button>
								</p>
								<p
									className={clsx(
										!toolbarActive && 'hidden',
										'text-p flex md:ml-6 md:pl-6 md:border-l md:border-black'
									)}
								>
									<span>
										<Icon
											icon="trash"
											className="mr-2"
											color="#2A2A2A"
											size={1.25}
										/>
									</span>
									<button
										onClick={(e) => {
											e.preventDefault();
											setClearGoodsmarkModalOpen(true);
										}}
										className="text-blue hover:underline"
									>
										{t('shared/goodsmark/deletegoodsmark')}
									</button>
								</p>
							</div>
						</div>
					)}
					{articleList &&
						articleList.length > 0 &&
						articleList.map((item: ArticleModel, index: number) => {
							return (
								<CartArticleRow
									key={index}
									{...item}
									handleRemoveArticle={handleRemoveArticle}
									handleQuantityChange={handleQuantityChange}
									handleOpenArticleModal={setArticleModal}
									handleCheck={handleCheckSingle}
									checked={checkedBoxes[index]?.selected}
									certificates={item?.certificates}
									cartLoading={cartLoading}
									sort={sortSelection}
									activeMarket={framework.header.activeMarket}
									language={framework.language}
								/>
							);
						})}
					{hasArticles &&
						window &&
						window.document &&
						document &&
						exportLinks?.map((exportLink: LinkModel, index: number) => {
							return (
								<div className="flex justify-end mt-4" key={index}>
									<a
										href={exportLink?.link}
										download
										className="text-p text-blue"
									>
										<Icon
											className="mr-2 mb-2"
											icon="download"
											size={2}
											aria-hidden="true"
										/>
										{exportLink?.text}
									</a>
								</div>
							);
						})}
					<Grid padding={false} className="pb-8">
						<Cell span={12} tablet={6} className="lg:pl-1/12 mt-8">
							{leadTime && (
								<div>
									<p className="text-p font-bold inline-block">
										{t('cartpage/estimatedleadtime')}
									</p>
									{leadTimeTooltip && (
										<Tooltip
											title={t('cartpage/descriptionofestimatedleadtime')}
										>
											{leadTimeTooltip}
										</Tooltip>
									)}
									<p className="text-p">{`${leadTime} ${t(
										'cartpage/businessdaysafterorderconfirmation'
									)}`}</p>
								</div>
							)}
						</Cell>
						<Cell span={12} tablet={6} className="mt-8 lg:pr-1/12">
							<fieldset className="">
								{availableDeliveryMethods &&
									availableDeliveryMethods?.length > 0 && (
										<>
											{' '}
											<legend className="text-p font-bold mb-2">
												{t('cartpage/choosedeliverymethod')}
											</legend>
											<p className="text-p">
												{t('cartpage/pleasechoosedesireddeliverymethod')}
											</p>
										</>
									)}{' '}
								{availableDeliveryMethods?.map(
									(method: DeliveryMethodModel, index: number) => {
										return (
											<div className="my-4 mx-0" key={index}>
												{(typeof singleShipment === 'undefined' ||
													method.deliveryMethodId !== 'partialdelivery') && (
													<label
														htmlFor={`${method.deliveryMethodId}-option`}
														className="text-p block font-bold"
													>
														<input
															id={`${method.deliveryMethodId}-option`}
															type="radio"
															name="delivery-method"
															value={method.deliveryMethodId}
															onChange={(e) => {
																handleDeliveryMethodChange(e);
															}}
															defaultChecked={
																method.deliveryMethodId ===
																getSelectedDeliveryMethod(
																	availableDeliveryMethods
																)
															}
														/>
														<span className="ml-2">{method.title}</span>
													</label>
												)}
												{getSelectedDeliveryMethod(availableDeliveryMethods) ===
													method.deliveryMethodId &&
													method.stockInfoText && (
														<p className="text-red text-p ml-5 mt-2">
															{method.stockInfoText}
														</p>
													)}

												{getSelectedDeliveryMethod(availableDeliveryMethods) ===
													'delivery' &&
													method.deliveryMethodId === 'delivery' &&
													typeof singleShipment !== 'undefined' && (
														<label className="ml-8 mt-2 flex items-center gap-4 justify-start">
															<input
																type="checkbox"
																id="singleShipment"
																checked={!singleShipment}
																onChange={(event) => {
																	dispatch(
																		UpdateCart({
																			singleShipment: !event.target.checked,
																		})
																	);
																}}
																onKeyDown={handleKeyDown}
															/>
															<span className="text-p">
																{t('checkoutpage/allowsplitdeliveries')}
															</span>
														</label>
													)}
											</div>
										);
									}
								)}
							</fieldset>
							{locations && locations.length > 0 && (
								<div className="my-6">
									<label
										className="block text-p font-bold mb-2"
										htmlFor="warehouse-select"
									>
										{(getSelectedDeliveryMethod(availableDeliveryMethods) ===
											'delivery' ||
											getSelectedDeliveryMethod(availableDeliveryMethods) ===
												'partialdelivery') &&
											t('cartpage/selectdesiredwarehousefordelivery')}
										{getSelectedDeliveryMethod(availableDeliveryMethods) ===
											'pickup' && t('cartpage/selectdesiredwarehouseforpickup')}
									</label>
									<select
										id="warehouse-select"
										className="font-standard text-p p-3 border rounded border-greyDark w-full placeholder-greyDarkest"
										onChange={handleWarehouseChange}
									>
										{locations?.map(
											(location: WarehouseModel, index: number) => {
												return (
													<option
														key={index}
														value={location.id}
														selected={location.selected}
													>
														{location.name}
													</option>
												);
											}
										)}
									</select>
								</div>
							)}
							<div className="my-6">
								<label
									className="block text-p font-bold mb-2"
									htmlFor="desireddate"
								>
									{(getSelectedDeliveryMethod(availableDeliveryMethods) ===
										'delivery' ||
										getSelectedDeliveryMethod(availableDeliveryMethods) ===
											'partialdelivery') && (
										<>
											{t('cartpage/selectdesireddeliverydate')}
											{
												<Tooltip
													title={t('cartpage/descriptionofdesireddeliverydate')}
												>
													{desiredDeliveryDateTooltip}
												</Tooltip>
											}
										</>
									)}
									{getSelectedDeliveryMethod(availableDeliveryMethods) ===
										'pickup' && (
										<>
											{t('cartpage/selectdesiredpickupdate')}
											{
												<Tooltip
													title={t('cartpage/descriptionofdesiredpickupdate')}
												>
													{desiredPickupDateTooltip}
												</Tooltip>
											}
										</>
									)}
								</label>
								{desiredDate && (
									<DatePickerInput
										id={'desireddate'}
										selected={new Date(desiredDate as string)}
										minDate={new Date(desiredStartDate as string)}
										onChange={(date: Date) => handleDateChanged(date)}
										daysDisabled={(date: Date) =>
											getDay(date) !== 0 && getDay(date) !== 6
										}
										activeMarket={framework.header.activeMarket}
										language={framework.language}
										className="mb-4"
									/>
								)}

								{total?.amountNetPriceFormatted && (
									<div className="flex justify-between items-baseline">
										<p className="text-p font-bold inline-block">
											{t('cartpage/total')}
										</p>
										<p className="text-h3 md:text-h2-large text-blue font-normal my-2 md:m-0">
											{`
														${total?.amountNetPriceFormatted} 
													 ${total?.currency}`}
										</p>
									</div>
								)}
								{total?.amountGrossPriceFormatted && (
									<div className="flex justify-between items-baseline">
										<p className="text-p">{t('cartpage/grosstotal')}</p>
										<p className="text-p">
											{`${total?.amountGrossPriceFormatted} ${total?.currency}`}
										</p>
									</div>
								)}
								{total?.amountWebShopDiscountFormatted && (
									<div className="flex justify-between items-baseline">
										<p className="text-p">{t('cartpage/webshopdiscount')}</p>
										<p className="text-p">
											{`- ${total.amountWebShopDiscountFormatted} ${total?.currency}`}
										</p>
									</div>
								)}

								{/* {checkoutErrorMessage && (
								<CheckoutErrorMessage displayBlock>
									{checkoutErrorMessage}
								</CheckoutErrorMessage>
							)} */}
							</div>
						</Cell>
						<Cell span={12} className="mr-1/12">
							<div className="flex justify-end my-8 flex-wrap items-baseline">
								{hasArticles && (
									<Button
										type="button"
										className="flex justify-end ml-2 mt-2 md:mt-0 whitespace-no-wrap "
										buttonColor="grey"
										onClick={(e) => {
											e.preventDefault();
											setEmptyCartModalOpen(true);
										}}
									>
										{deleteButton.text}
										<Icon icon="trash" size={2} aria-hidden="true" />
									</Button>
								)}

								<Button
									type="button"
									className="flex justify-end ml-2 mt-2 md:mt-0 whitespace-no-wrap "
									buttonColor="grey"
									onClick={(e) => {
										e.preventDefault();
										setSaveCartModalOpen(true);
									}}
									disabled={!hasArticles}
								>
									{saveButton.text}
									<Icon icon="save" size={2} aria-hidden="true" />
								</Button>

								{checkoutButton?.text && checkoutButton?.link && (
									<Button
										type="link"
										buttonColor="green"
										url={checkoutButton.link}
										onClick={(e) => {
											setRedirectingToCheckout(true);
										}}
										className="ml-2"
										disabled={!hasArticles || redirectingToCheckout}
									>
										<div className="flex justify-center items-center">
											{checkoutButton.text}
											{redirectingToCheckout ? (
												<Icon
													icon="loader"
													animate="spin"
													size={2}
													aria-hidden="false"
													color="white"
												/>
											) : (
												<Icon
													icon="chevrons"
													direction="left"
													size={2}
													aria-hidden="true"
													className="align-middle"
												/>
											)}
										</div>
									</Button>
								)}

								{punchoutButton?.text && punchoutButton?.link && (
									<Button
										type="link"
										buttonColor="green"
										url={punchoutButton?.link}
										onClick={(e) => {
											e.preventDefault();
											setRedirectingToPunchout(true);
											handlePunchout();
										}}
										className="ml-2"
										disabled={!hasArticles || redirectingToCheckout}
									>
										<div className="flex justify-center items-center">
											{punchoutButton?.text}
											{redirectingToPunchout ? (
												<Icon
													icon="loader"
													animate="spin"
													size={2}
													aria-hidden="false"
													color="white"
												/>
											) : (
												<Icon
													icon="chevrons"
													direction="left"
													size={2}
													aria-hidden="true"
													className="align-middle"
												/>
											)}
										</div>
									</Button>
								)}
							</div>

							<ContentArea contentArea={contentArea} />
						</Cell>
					</Grid>
				</Layout>
			</main>
			{importArticlesModalOpen && (
				<QuickImportArticlesModal
					onToggle={() => setImportArticlesModalOpen(false)}
					api={framework.api}
					sortSelection={sortSelection}
				/>
			)}
			{emptyCartModalOpen && (
				<AlertModal
					title={t('cartpage/deletedialogtitle')}
					heading={t('cartpage/deletedialogtitle')}
					message={t('cartpage/deletedialogmessage')}
					cancelButtonText={t('shared/general/cancel')}
					actionButtonText={t('cartpage/emptycart')}
					onToggle={() => setEmptyCartModalOpen(false)}
					onClick={handleClearCart}
				/>
			)}
			{saveCartModalOpen && (
				<SaveCartModal
					onToggle={() => {
						setSaveCartModalOpen(false);
						!withPrice && fetchCartObject();
					}}
					onSave={handleSaveCart}
				/>
			)}
			{overwriteCartModal.isActive && (
				<AlertModal
					title={t('cartpage/overwritecart')}
					heading={t('cartpage/overwritecart')}
					message={overwriteCartModal.message}
					cancelButtonText={t('shared/general/cancel')}
					actionButtonText={t('cartpage/overwrite')}
					onToggle={() =>
						setOverwriteCartModal({ ...overwriteCartModal, isActive: false })
					}
					onClick={handleOverwriteCart}
				/>
			)}
			{articleModal.isActive && (
				<ArticleDetail
					onToggle={() => {
						setArticleModal({ link: null, isActive: false });
						!withPrice && fetchCartObject();
					}}
					articleApiUrl={articleModal.link}
					disableControls
					activeMarket={framework.header.activeMarket}
				/>
			)}
			{goodsmarkModalOpen && (
				<AddGoodsmarkModal
					onToggle={() => setGoodsmarkModalOpen(false)}
					handleAddGoodsmark={handleSelectedGoodsmark}
					warningMessage={t('shared/goodsmark/warningmessage')}
				/>
			)}
			{clearGoodsmarkModalOpen && (
				<AlertModal
					title={t('shared/goodsmark/deletegoodsmark')}
					heading={t('shared/goodsmark/deletegoodsmark')}
					message={t('shared/goodsmark/deletegoodsmarkdialogmessage')}
					cancelButtonText={t('shared/general/cancel')}
					actionButtonText={t('shared/goodsmark/deletegoodsmark')}
					onToggle={() => setClearGoodsmarkModalOpen(false)}
					onClick={handleSelectedGoodsmark}
				/>
			)}
			{reloadPricesModal.isActive && (
				<AlertModal
					title={t('cartpage/reloadpricedialogtitle')}
					heading={t('cartpage/reloadpricedialogtitle')}
					message={reloadPricesModal.message}
					cancelButtonText={t('cartpage/deletedialogcancel')}
					actionButtonText={
						reloadPricesModal.hasReloadButton && t('cartpage/reload')
					}
					onToggle={() =>
						setReloadPricesModal({ ...reloadPricesModal, isActive: false })
					}
					onClick={reloadPricesModal.hasReloadButton && handleReloadPrices}
				/>
			)}
		</>
	);
};

export default CartPage;
