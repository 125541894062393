/**
 * NewsList
 * @module components/NewsList
 */

import clsx from 'clsx';

interface NewsListModel {
	children: JSX.Element | JSX.Element[];
	borderTop: boolean;
}

const NewsList = ({ children, borderTop }: NewsListModel) => (
	<ul
		className={clsx(
			'pt-3',
			borderTop && 'border-t border-solid border-greyDarker'
		)}
	>
		{children}
	</ul>
);

export default NewsList;
