/**
 * ColumnBlock
 * @module components/ColumnBlock
 */

import LazyLoad from 'react-lazyload';
import { Grid, Cell } from 'components/ui/Grid';
import { getHeadingLevel } from 'common/helpers.js';
import clsx from 'clsx';

import { ImageModel, ButtonColorTypes, LinkModel } from 'types/common';
import { ColumnBlockItemModel } from 'types/block-types';
import Icon from 'components/ui/Icon';
import Button from 'components/ui/Button';

interface Props {
	button?: LinkModel;
	headingLevel: number;
	layout: 'line' | 'column';
	columnAlignment: 'left' | 'center' | 'right';
	items: ColumnBlockItemModel[];
}

const renderImage = (
	image: ImageModel,
	link: string,
	layout: 'line' | 'column'
) => {
	let linkProps = {};
	if (link) {
		linkProps = { as: 'a', href: link };
	}
	return (
		<div
			className={clsx(
				'relative columnblock--wrapper',
				layout === 'line'
					? ' col-span-3 md:col-span-2  pl-3 lg:pl-6'
					: 'md:h-60 w-full h-full'
			)}
			{...linkProps}
		>
			<picture className="flex justify-start h-full items-center">
				{image.webpSizes && (
					<source
						type="image/webp"
						srcSet={`${image.webpSizes[0]} 800w, ${image.webpSizes[1]} 400w`}
						sizes="(min-width: 768px) 60vw, (max-width: 767px) 70vw, 800px"
					/>
				)}
				{image.sizes && (
					<img
						src={image.src}
						srcSet={`${image.sizes[0]} 800w, ${image.sizes[1]} 400w`}
						sizes="(min-width: 768px) 70vw, (max-width: 767px) 30vw, 800px"
						alt={image.alt ? image.alt : ''}
						className={clsx(
							'object-center image-objectfit',
							layout === 'line' ? 'w-16 h-full' : 'w-full h-full'
						)}
					/>
				)}
			</picture>
		</div>
	);
};

const ColumnBlock = ({
	items,
	button,
	layout = 'column',
	headingLevel,
	columnAlignment,
}: Props) => {
	if (!items || items.length === 0) {
		return null;
	}

	const Heading = getHeadingLevel(headingLevel);
	return (
		<div className={clsx(layout === 'column' && 'mb-4 md:mb-10')}>
			{layout === 'column' ? (
				<Grid
					padding={false}
					className={clsx(
						columnAlignment === 'center' && 'justify-center',
						columnAlignment === 'left' && 'justify-start',
						columnAlignment === 'right' && 'justify-end',
						'px-2'
					)}
				>
					{items.map((item, i) => {
						const { heading, link, linkB, linkC, linkD, image } = item;
						return (
							<Cell
								span={12}
								tablet={6}
								desktop={4}
								className="my-4 md:my-10 "
								key={`${heading}-${i}`}
							>
								<div className="h-full columnblock--shadow">
									<LazyLoad>
										<div className="h-full flex min-h-full items-stretch flex-col">
											{image &&
												renderImage(
													image,
													link && link.link ? link.link : '',
													layout
												)}
											<div className="columnblock--wrapper h-full p-4 break-words">
												{item.heading && (
													<Heading className="text-h4 md:text-h3 mb-2 text-blue font-light">
														{item.heading}
													</Heading>
												)}
												{item.text && (
													<p className="font-light text-p">{item.text}</p>
												)}
												{link && (
													<a
														className="text-p text-orange font-light mt-3 flex items-center hover:underline"
														target={
															link.linkType === 'Document'
																? '_blank'
																: link.target
														}
														href={link.link}
													>
														{link.linkType === 'Document' ? (
															<Icon
																icon="file"
																className="mr-2"
																aria-hidden={true}
															/>
														) : (
															<Icon
																icon="chevron"
																direction="left"
																size={0.75}
																className="mr-2"
																aria-hidden={true}
															/>
														)}
														<span>{link.text}</span>
													</a>
												)}
												{linkB && (
													<a
														className="text-p text-orange font-light mt-3  flex items-center hover:underline"
														target={
															linkB.linkType === 'Document'
																? '_blank'
																: linkB.target
														}
														href={linkB.link}
													>
														{linkB.linkType === 'Document' ? (
															<Icon
																icon="file"
																className="mr-2"
																aria-hidden={true}
															/>
														) : (
															<Icon
																icon="chevron"
																direction="left"
																size={0.75}
																className="mr-2"
																aria-hidden={true}
															/>
														)}
														<span>{linkB.text}</span>
													</a>
												)}
												{linkC && (
													<a
														className="text-p text-orange font-light mt-3 flex items-center hover:underline"
														target={
															linkC.linkType === 'Document'
																? '_blank'
																: linkC.target
														}
														href={linkC.link}
													>
														{linkC.linkType === 'Document' ? (
															<Icon
																icon="file"
																className="mr-2"
																aria-hidden={true}
															/>
														) : (
															<Icon
																icon="chevron"
																direction="left"
																size={0.75}
																className="mr-2"
																aria-hidden={true}
															/>
														)}
														<span>{linkC.text}</span>
													</a>
												)}
												{linkD && (
													<a
														className="text-p text-orange font-light mt-3 flex items-center hover:underline"
														target={
															linkD.linkType === 'Document'
																? '_blank'
																: linkD.target
														}
														href={linkD.link}
													>
														{linkD.linkType === 'Document' ? (
															<Icon
																icon="file"
																className="mr-2"
																aria-hidden={true}
															/>
														) : (
															<Icon
																icon="chevron"
																direction="left"
																size={0.75}
																className="mr-2"
																aria-hidden={true}
															/>
														)}
														<span>{linkD.text}</span>
													</a>
												)}
											</div>
										</div>
									</LazyLoad>
								</div>
							</Cell>
						);
					})}
				</Grid>
			) : (
				<>
					{items.map((item) => {
						const { link, image } = item;
						return (
							<div className="h-full mx-auto max-w-site border-1 border-gray my-2 pt-3 pb-3 pr-3 lg:pt-6 lg:pr-6 lg:pb-6">
								<LazyLoad>
									<div className="h-full grid grid-cols-12 min-h-full items-stretch">
										{image &&
											renderImage(
												image,
												link && link.link ? link.link : '',
												layout as 'line' | 'column'
											)}
										<div className="columnblock--wrapper col-span-9 md:col-span-10 flex justify-between h-full break-words">
											<div className="flex flex-col">
												{item.heading && (
													<Heading className="text-sm md:text-2xl font-semibold">
														{item.heading}
													</Heading>
												)}
												{item.text && (
													<p className="font-light text-xsm md:text-p">
														{item.text}
													</p>
												)}
											</div>
											<div className="flex justify-center items-center">
												{link && (
													<a
														href={link.link}
														className="h-8 w-8 md:w-12 md:h-12 bg-gray-200 rounded-md flex justify-center items-center"
													>
														<Icon
															icon="externalLink2"
															direction="left"
															size={0.75}
															aria-hidden={true}
														/>
													</a>
												)}
											</div>
										</div>
									</div>
								</LazyLoad>
							</div>
						);
					})}
				</>
			)}
			{button && (
				<div className="text-center my-6">
					<Button
						type="link"
						buttonColor={(button.color as ButtonColorTypes) || 'blue'}
						url={button.link}
						target={button.target}
					>
						<Icon
							icon="chevrons"
							direction="left"
							size={1.25}
							className="mr-2"
						/>
						<span>{button.text}</span>
					</Button>
				</div>
			)}
		</div>
	);
};

export default ColumnBlock;
