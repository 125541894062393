/**
 * PromoBlock
 * @module components/PromoBlock
 */

import {
	PromoPatternWrapper,
	PromoPattern,
	PromoPattern2,
	PromoImage,
} from './PromoBlock.styles';

import PromoContent from './PromoContent';
import { PromoBlockModel } from 'types/block-types';
import { ImagePreferenceTypes } from 'types/common';
import clsx from 'clsx';

const isImageRight = (imagePreference: ImagePreferenceTypes | undefined) => {
	return imagePreference && imagePreference.includes('imageRight');
};

const isImageOutside = (imagePreference: ImagePreferenceTypes | undefined) => {
	return imagePreference && imagePreference.includes('Outside');
};

const PromoImageOutside = (props: PromoBlockModel) => {
	const { backgroundColor, backgroundPattern, media } = props;

	const theme = backgroundColor;
	const imageRight = isImageRight(media?.imagePreference);
	const imageOutside = isImageOutside(media?.imagePreference);

	const hasImage =
		media?.image &&
		media.image.src &&
		media.image.sizes &&
		media.image.sizes.length > 0 &&
		media.image.webpSizes &&
		media.image.webpSizes.length > 0;

	return (
		<section
			className={clsx(
				'mdMax:mt-0 mdMax:pt-7.5 max-w-360 mx-auto',
				imageOutside ? 'mt-22.5 pt-0' : 'my-0 mt-0 pt-22.5',
				theme ? `bg-${theme}` : 'bg-white'
			)}
		>
			<div
				className={clsx(
					'mdMax:flex-wrap mdMax:flex-row max-w-297.5 my-0 mx-auto flex',
					imageRight ? 'flex-row-reverse' : 'flex-row'
				)}
			>
				<div
					className={clsx(
						'w-1/2 h-full inline-block relative mdMax:w-full promoWrapper'
					)}
				>
					<PromoPatternWrapper aria-hidden>
						{backgroundPattern &&
							backgroundPattern !== 'none' &&
							(backgroundPattern === 'circle' ? (
								<PromoPattern theme={theme} />
							) : (
								<PromoPattern2 theme={theme} />
							))}
					</PromoPatternWrapper>
					{hasImage && media?.image?.webpSizes && media.image.sizes && (
						<a href={media?.imageLink} target={media?.imageLinkTarget}>
							<picture
								className={clsx(
									'h-full',
									props.toggleOnImage &&
										props.toggleImageGallery &&
										'cursor-pointer'
								)}
								onClick={
									props.toggleOnImage ? props.toggleImageGallery : undefined
								}
							>
								<source
									type="image/webp"
									srcSet={`${media.image.webpSizes[0]} 1600w,${media.image.webpSizes[1]} 1200w, ${media.image.webpSizes[2]} 800w, ${media.image.webpSizes[3]} 400w`}
									sizes="(max-width: 768px) 70vw, (max-width: 1200px) 60vw, 40vw"
								/>
								<PromoImage
									src={media.image.src}
									srcSet={`${media.image.sizes[0]} 1600w,${media.image.sizes[1]} 1200w, ${media.image.sizes[2]} 800w, ${media.image.sizes[3]} 400w`}
									sizes="(max-width: 768px) 70vw, (max-width: 1200px) 60vw, 40vw"
									alt={media.image.alt}
								/>
							</picture>
						</a>
					)}
				</div>
				<PromoContent
					theme={theme}
					imageOutside={imageOutside}
					contentLeft={imageRight}
					{...props}
				/>
			</div>
		</section>
	);
};

export default PromoImageOutside;
