import { LinkButton } from 'components/ui/Button/Button.styles';
import styled from 'styled-components';
import { spacing5x } from 'theme/spacings';

export const ButtonShowMore = styled(LinkButton)`
	margin: ${spacing5x} 0;

	a {
		text-decoration: none;
		color: white;
	}
`;
