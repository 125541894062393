/**
 * ProductCategoryPage
 * @module pages/ProductCategoryPage
 */

import React, {
	useState,
	useContext,
	useEffect,
	useRef,
	useReducer,
} from 'react';
import Layout from 'components/ui/Layout';
import Breadcrumbs from 'components/framework/Breadcrumbs';
import { renderElementBasedOnEditMode, scrollToTop } from 'common/helpers';

import {
	Preamble,
	RichText,
	LinkButton,
	InputField,
} from './ProductCategoryPage.styles';

import { Paragraph } from 'components/ui/Typography';
import { Grid, Cell } from 'components/ui/Grid';
import ImageBlock from 'components/framework/ImageBlock';
import ContentArea from 'components/framework/ContentArea';
import { LocalizationContext } from 'context/localization.context';
import { useFilterSearch } from 'api';
import { useMedia } from 'hooks';
import { composeQueryString, setUrlLocation } from 'common/helpers';
import listFilterReducer from 'reducers/listFilterReducer';
import CustomExternalScript from 'components/framework/CustomExternalScript';
import FilterBar from 'components/ui/FilterBar';
import { usePopState } from 'hooks/usePopState';
import {
	CategoryList,
	CategoryListItem,
} from 'components/framework/CategoryList';
import Icon from 'components/ui/Icon';
import Pager from 'components/ui/Pager';

const ProductCategoryPage = ({
	pagination,
	products,
	breadcrumbs,
	heading,
	preamble,
	text,
	links,
	filter,
	subcategories,
	isInEditMode,
	contentArea,
	framework,
	mainContentArea,
}) => {
	const { t } = useContext(LocalizationContext);
	// Handle filter, query and pagination.
	const [state, dispatch] = useReducer(listFilterReducer, {
		sortBy: filter.sortBy,
		display: pagination.display,
		query: filter.query,
		currentPage: pagination.currentPage,
	});

	const [query, setQuery] = useState(null);
	const [response, error, loading] = useFilterSearch(
		query,
		window && window.location ? window.location.pathname : null
	);

	const isMobile = useMedia('(max-width: 490px)');

	const didRender = useRef(false);
	const preventHistoryEntry = useRef(false);

	const popState = usePopState(state);
	const popStateString = JSON.stringify(popState);
	useEffect(() => {
		if (popState) {
			dispatch({ type: 'SET_STATE', payload: popState });
			preventHistoryEntry.current = true;
		}
	}, [popState, popStateString]);

	useEffect(() => {
		const hasRendered = didRender.current;
		const queryString = composeQueryString(
			state.query,
			state.sortBy,
			state.display,
			state.currentPage
		);
		// Only set the query to fetch new data if it's not the first render.
		didRender.current
			? setQuery(queryString ? queryString : null)
			: (didRender.current = !didRender.current);

		setUrlLocation(
			queryString,
			state.query,
			state.sortBy,
			state.display,
			state.currentPage,
			null,
			null,
			hasRendered,
			preventHistoryEntry.current
		);
		if (preventHistoryEntry.current) {
			preventHistoryEntry.current = !preventHistoryEntry.current;
		}
	}, [state]);

	const handleFilterRequest = (sortBy, display) => {
		dispatch({ type: 'SORT_BY', sortBy: sortBy });
		dispatch({ type: 'DISPLAY', display: display });
	};

	const handleSearch = (value) => {
		if (state.query !== value) {
			dispatch({ type: 'QUERY', query: value });
		}
	};

	const handlePagination = (value) => {
		scrollToTop();
		dispatch({ type: 'CURRENT_PAGE', currentPage: value });
	};

	const paginationObject = response.pagination || pagination;

	const productsArray = response.products || products;

	useEffect(() => {
		if (productsArray.length > 0 && window.dataLayer) {
			let items = [];

			productsArray.map((art) => {
				return items.push({
					item_name: art.name,
					item_id: art.id,
					item_category: breadcrumbs[1]?.text,
					item_category2: breadcrumbs[2]?.text,
					quantity: 1,
				});
			});

			window.dataLayer.push({
				event: 'view_item_list',
				domain: framework.header.activeMarket,
				ecommerce: {
					items: items,
				},
			});
		}
		//eslint-disable-next-line
	}, [productsArray]);

	return (
		<main id="main-content">
			<Layout backgroundColor="white">
				<Breadcrumbs links={breadcrumbs} />

				{heading &&
					renderElementBasedOnEditMode(
						isInEditMode,
						'heading',
						'h1',
						{
							className:
								'font-standard mb-6 mt-2 font-regular text-h2 md:mt-8 md:text-h1 text-blue',
						},
						heading
					)}

				{preamble &&
					renderElementBasedOnEditMode(
						isInEditMode,
						'preamble',
						Preamble,
						{ size: 'large' },
						preamble
					)}

				{text && (
					<RichText name="text" text={text} isInEditMode={isInEditMode} />
				)}
				{links && links.length > 0 && (
					<div className="flex flex-col items-start mt-10">
						{links.map((item, index) => (
							<LinkButton
								type="link"
								buttonColor="orange"
								url={item.link}
								target={item.target}
								key={index}
							>
								<Icon
									icon="chevrons"
									aria-hidden={true}
									direction="left"
									size={1.25}
									className="mr-1"
								/>
								{item.text}
							</LinkButton>
						))}
					</div>
				)}
				<ContentArea contentArea={mainContentArea} />
				<CustomExternalScript />
				<div className="md:my-4 my-8 mx-0 md:mt-12">
					{subcategories && subcategories.length > 0 && (
						<h2 className="text-blue text-h4 font-normal mb-2">
							{t('productcategorypage/subcategories')}
						</h2>
					)}
					<CategoryList>
						{subcategories?.map((item, index) => (
							<CategoryListItem key={index} url={item.link} text={item.text} />
						))}
					</CategoryList>
				</div>
				<InputField
					method="GET"
					action="/"
					id="filter"
					name="filter"
					value={state.query}
					onSearch={handleSearch}
				/>
				<div className="mb-4">
					<FilterBar
						defaultDisplayOption={state.display}
						defaultSortByItem={state.sortBy || ''}
						onFilter={handleFilterRequest}
						displayOptions={[16, 32, 48]}
						sortByItems={filter.sortProperties || []}
					/>
				</div>

				<Grid padding={false}>
					{error && (
						<div className="w-full text-center">
							<Paragraph>{t('shared/filter/errormessage')}</Paragraph>
						</div>
					)}
					{loading && (
						<div className="w-full text-center">
							<Icon icon="loader" animate="spin" size={2} />
						</div>
					)}
					{productsArray && !loading && !error && productsArray.length > 0
						? productsArray.map((item) => (
								<Cell
									span={12}
									tablet={4}
									desktop={3}
									className="my-5"
									key={item.id}
								>
									<ImageBlock
										link={item.link}
										heading={item.name}
										shortDescription={item.shortDescription}
										image={item.image}
										highlight={item.highlight}
										preamble={item.category}
										headingSize="small"
									/>
								</Cell>
						  ))
						: !error &&
						  !loading && (
								<div className="w-full text-center">
									<Paragraph>{t('shared/filter/noresultmessage')}</Paragraph>
								</div>
						  )}
				</Grid>
				<div className="my-10">
					<Pager
						nrOfPages={
							paginationObject.pageCount ? paginationObject.pageCount : 0
						}
						currentPage={
							paginationObject.currentPage ? paginationObject.currentPage : 0
						}
						total={!error && !loading ? paginationObject.total : 0}
						onPageChange={handlePagination}
						isMobile={isMobile}
					/>
				</div>
			</Layout>
			<ContentArea contentArea={contentArea} />
		</main>
	);
};
export default ProductCategoryPage;
