import { useFetch } from 'api';

export const useLogin = ({ url, form, antiForgery }) => {
	const preventRequest = typeof url === 'undefined' || url === '';
	let body = {};
	if (window && window.FormData) {
		body = new FormData(form);
		body.append('__RequestVerificationToken', antiForgery);
	}
	const hash = JSON.stringify(body);
	return useFetch({
		url: `${url}`,
		responseDefault: {},
		method: 'post',
		preventRequest: preventRequest,
		disableCancel: true,
		data: body,
		datHash: hash,
	});
};
