/**
 * CisionOwnershipPage
 * @module pages/CisionOwnershipPage
 */

import { useContext, useEffect, useState } from 'react';
import Layout from 'components/ui/Layout';
import SectionHeader from 'components/ui/SectionHeader';
import { LocalizationContext } from 'context/localization.context';
import Breadcrumbs from 'components/framework/Breadcrumbs';
import {
	FacebookIcon,
	TwitterIcon,
	LinkedInIcon,
	EmailIcon,
} from './CisionOwnershipPage.styles';
import ContentArea from 'components/framework/ContentArea';
import { CisionOwnerShipPageModel } from 'types/page-types';

const CisionOwnershipPage = (props: CisionOwnerShipPageModel) => {
	const {
		heading,
		isInEditMode,
		breadcrumbs,
		contentAreaTop,
		contentAreaBottom,
	} = props;
	const { t }: any = useContext(LocalizationContext);
	const [windowUrl, setWindowUrl] = useState('');
	useEffect(() => {
		setWindowUrl(window.location.href);
	}, []);

	return (
		<main id="main-content">
			<Layout backgroundColor="white">
				{breadcrumbs && <Breadcrumbs links={breadcrumbs} />}
				<ContentArea contentArea={contentAreaTop} />
				<div className="flex ssmMax:block ssmMax:mb-2">
					<div className="w-full">
						<SectionHeader
							heading={heading}
							size={'large'}
							isInEditMode={isInEditMode}
						/>
					</div>
					<div className="flex pt-16 ssmMax:pt-0 smMax:pt-10 smMax:pl-2">
						<a
							className="m-0"
							href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
								windowUrl
							)}`}
							title={t('shared/socialmedia/shareonfacebook')}
							target="_blank"
						>
							<FacebookIcon />
						</a>
						<a
							className="m-0"
							href={`https://twitter.com/home?status=${encodeURIComponent(
								document.title
							)}%20${encodeURIComponent(windowUrl)}`}
							title={t('shared/socialmedia/shareontwitter')}
							target="_blank"
						>
							<TwitterIcon />
						</a>
						<a
							className="m-0"
							href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
								windowUrl
							)}`}
							title={t('shared/socialmedia/shareonlinkedin')}
							target="_blank"
						>
							<LinkedInIcon />
						</a>
						<a
							className="m-0"
							href={`mailto:?subject=[title]&body=${encodeURIComponent(
								windowUrl
							)}`}
							title={t('shared/socialmedia/sharewithemail')}
							target="_blank"
						>
							<EmailIcon />
						</a>
					</div>
				</div>
				<div className="container">
					<div id="ownership">
						<ul id="ownershipTabs" className="nav shareholder-nav-tabs">
							<li className="shareholder-nav-item active">
								<a
									href="#target-largest"
									className="shareholder-nav-link active show"
									data-toggle="tab"
								>
									{t('cisionownershippage/largestshareholders')}
								</a>
							</li>
							<li className="shareholder-nav-item">
								<a
									href="#target-grouped"
									className="shareholder-nav-link"
									data-toggle="tab"
								>
									{t('cisionownershippage/largestgroupedshareholders')}
								</a>
							</li>
							<li className="shareholder-nav-item">
								<a
									href="#target-sharesizegroups"
									className="shareholder-nav-link"
									data-toggle="tab"
								>
									{t('cisionownershippage/shareranges')}
								</a>
							</li>
							<li className="shareholder-nav-item">
								<a
									href="#target-area"
									className="shareholder-nav-link"
									data-toggle="tab"
								>
									{t('cisionownershippage/geographicaldistribution')}
								</a>
							</li>
						</ul>

						<div className="tab-content">
							<div className="tab-pane" id="target-sharesizegroups"></div>
							<div className="tab-pane active" id="target-largest">
								<div id="container-largest-shareholders-chart"></div>
								<div id="target-largestshareholders"></div>
							</div>
							<div className="tab-pane" id="target-area">
								<div id="target-shareholderareas-chart"></div>
								<div id="target-shareholderareas"></div>
							</div>
							<div className="tab-pane" id="target-grouped">
								<div id="container-largest-grouped-shareholders-chart"></div>
								<div id="target-largestgroupedshareholders"></div>
							</div>
						</div>
					</div>
				</div>
				<ContentArea className="pt-4" contentArea={contentAreaBottom} />
			</Layout>
		</main>
	);
};

export default CisionOwnershipPage;
