import React from 'react';
export default ({ className, ...htmlAttributes }: { className?: string }) => (
	<svg
		width="24px"
		height="24px"
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		strokeWidth="2"
		strokeLinecap="round"
		strokeLinejoin="round"
		className={className}
		style={{ fill: 'none' }}
		{...htmlAttributes}
	>
		<circle cx="11" cy="11" r="8"></circle>
		<line x1="21" y1="21" x2="16.65" y2="16.65"></line>
		<line x1="11" y1="8" x2="11" y2="14"></line>
		<line x1="8" y1="11" x2="14" y2="11"></line>
	</svg>
);
