import styled, { css } from 'styled-components';
import { deviceSmallMax } from 'theme/mediaQueries';
import { fontWeightBold, fontWeightNormal } from 'theme/typography';
import { grey, greyDarker, black, blue } from 'theme/colors';
import { spacing05x, spacing3x, spacing2x, spacing1x } from 'theme/spacings';
import { FaChevronRight } from 'react-icons/fa';

const textCss = css`
	font-size: 1.6rem;
	line-height: 2.4rem;
	font-weight: ${({ bold }) => (bold ? fontWeightBold : fontWeightNormal)};
	margin: ${spacing05x} 0 ${spacing05x};
`;

export const Article = styled.article`
	display: flex;
	padding: ${spacing2x};
	border-bottom: 0.1rem solid ${greyDarker};

	@media (max-width: ${deviceSmallMax}) {
		justify-content: space-between;
		padding: 0;
	}
`;

export const Image = styled.div`
	display: flex;
	border: 0.2rem solid ${grey};
	height: 14rem;
	width: 14rem;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;

	svg {
		font-size: 8rem;
		opacity: 0.1;
		@media (max-width: 1150px) {
			height: 5.6rem;
			width: 5.6rem;
		}
		@media (max-width: ${deviceSmallMax}) {
			height: 5.6rem;
			width: 5.6rem;
		}
	}
	@media (max-width: 1150px) {
		height: 8.6rem;
		width: 8.6rem;
	}
	@media (max-width: ${deviceSmallMax}) {
		height: 8.6rem;
		width: 8.6rem;
		order: 2;
	}

	@media (max-width: ${deviceSmallMax}) {
		margin-top: ${spacing3x};
	}
`;

export const Img = styled.img`
	max-width: 100%;
	max-height: 100%;
	@media (max-width: 1150px) {
		height: 8.6rem;
		width: 8.6rem;
	}
`;

export const ContentImg = styled(Img)`
	padding: 0.4rem;
	width: auto;
	@media (max-width: 1150px) {
		width: auto;
	}
`;

export const Body = styled.div`
	padding: 0 ${spacing2x};
	display: block;
	min-height: 14rem;

	@media (max-width: ${deviceSmallMax}) {
		order: 1;
		padding: ${spacing2x} ${spacing2x} ${spacing2x} 0;
	}
`;

export const LinkWrapper = styled.a`
	text-decoration: none;
	:hover {
		h1 {
			text-decoration: underline;
		}
		p {
			text-decoration: underline;
		}
	}
`;

export const Heading = styled.h2`
	font-size: 2.5rem;
	color: ${blue};
	font-weight: ${fontWeightNormal};
	margin-top: 0;
	margin-bottom: ${spacing1x};
`;

export const Text = styled.p`
	${textCss}
	color: ${black};
	svg {
		margin-right: ${spacing1x};
	}

	@media (max-width: ${deviceSmallMax}) {
		font-size: 1.3rem;
		margin: -0.1rem 0;
	}
`;

export const Value = styled.p`
	${textCss}
	color: ${black};
	position: absolute;
	left: 14rem;
	top: 0;
	margin: 0;
`;

export const SeparatorIcon = styled(FaChevronRight)`
	font-size: 1rem;
	vertical-align: middle;
	margin: 0 0.5rem;
`;
