import React from 'react';
import { FormProvider } from './context/Form.context';
import Form from './Form';

const FormComponent = ({ initialValues, children, ...props }) => {
	const initialState = {
		values: initialValues || {},
		validationMessages: {},
		invalidFields: [],
	};

	return (
		<FormProvider initialState={initialState}>
			<Form initialValues={initialValues || {}} {...props}>
				{children}
			</Form>
		</FormProvider>
	);
};

export default FormComponent;
