/**
 * PageFooter
 * @module components/PageFooter
 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
	FooterContainer,
	BottomWrapper,
	LogoImg,
	Text,
	ColumnsWrapper,
	Column,
	Heading,
	Link,
	SocialLink,
	LinkWrapper,
	FacebookIcon,
	TwitterIcon,
	YoutubeIcon,
	InstagramIcon,
	LinkedinIcon,
	RichTextWrapper,
} from './PageFooter.styles';
import logotype from './logotype_white.svg';
import { LocalizationContext } from 'context/localization.context';
import useWindowSize from 'hooks/useWindowSize';
import Layout from 'components/ui/Layout';
import Icon from 'components/ui/Icon';
import { ViewContext } from 'context/view.context';

const renderIcon = (item) => {
	switch (item.modelType) {
		case 'FacebookLink':
			return <FacebookIcon />;
		case 'TwitterLink':
			return <TwitterIcon />;
		case 'YoutubeLink':
			return <YoutubeIcon />;
		case 'InstagramLink':
			return <InstagramIcon />;
		case 'LinkedInLink':
			return <LinkedinIcon />;
		default:
			return false;
	}
};

const PageFooter = ({ footer, className }) => {
	const { columns, socialLinks, copyright, links } = footer;
	const { t } = useContext(LocalizationContext);
	const isMobile = useWindowSize(1150);
	const { view, handleViewChange } = useContext(ViewContext);

	return (
		<footer className={className}>
			<FooterContainer>
				<Layout backgroundColor="blue">
					<LogoImg
						src={footer.logoSrc ?? logotype}
						alt={footer.logoAlt ?? t('shared/footer/lindablogotype')}
					/>
					{columns && columns.length > 0 && (
						<ColumnsWrapper>
							{columns.map((column, i) => (
								<Column key={`column-${i}`}>
									{column.heading && (
										<Heading as="h2" size="large">
											{column.heading}
										</Heading>
									)}
									{column.text && (
										<RichTextWrapper
											dangerouslySetInnerHTML={{ __html: column.text }}
										/>
									)}
								</Column>
							))}
						</ColumnsWrapper>
					)}
				</Layout>

				<Layout backgroundColor="indigo">
					<BottomWrapper>
						<LinkWrapper>
							{copyright && <Text size="small">{copyright}</Text>}

							{links &&
								links.length > 0 &&
								links.map((item, i) => (
									<React.Fragment key={`link-${i}`}>
										<Link href={item.link}>{item.text}</Link>
									</React.Fragment>
								))}
						</LinkWrapper>

						{socialLinks && socialLinks.length > 0 && (
							<LinkWrapper>
								<button
									className="mr-10 focus:outline-none cursor-pointer"
									title={
										view === 'desktop' || (!isMobile && view === 'unset')
											? t('shared/footer/switchtodesktop')
											: t('shared/footer/switchtomobile')
									}
									onClick={() =>
										handleViewChange(
											view === 'desktop' || (!isMobile && view === 'unset')
												? 'mobile'
												: 'desktop'
										)
									}
								>
									<Icon
										icon={
											view === 'desktop' || (!isMobile && view === 'unset')
												? 'toggleDesktop'
												: 'toggleMobile'
										}
										size={2}
									/>
								</button>
								{socialLinks.map((item, i) => (
									<SocialLink
										href={item.link}
										title={item.text ? item.text : ''}
										key={`sociallink-${i}`}
									>
										{renderIcon(item)}
									</SocialLink>
								))}
							</LinkWrapper>
						)}
					</BottomWrapper>
				</Layout>
			</FooterContainer>
		</footer>
	);
};

PageFooter.propTypes = {
	footer: PropTypes.shape({
		logoSrc: PropTypes.string,
		logoAlt: PropTypes.string,
		columns: PropTypes.arrayOf(
			PropTypes.shape({
				heading: PropTypes.string,
				text: PropTypes.string,
			})
		),
		socialLinks: PropTypes.arrayOf(
			PropTypes.shape({
				link: PropTypes.string,
				text: PropTypes.string,
				modelType: PropTypes.string,
			})
		),
		copyright: PropTypes.string,
		links: PropTypes.arrayOf(
			PropTypes.shape({
				link: PropTypes.string,
				text: PropTypes.string,
			})
		),
	}),
};

export default PageFooter;
