/**
 * CalendariumPage
 * @module pages/CalendariumPage
 */

import { useContext, useEffect, useState } from 'react';
import Layout from 'components/ui/Layout';
import { renderElementBasedOnEditMode } from 'common/helpers';
import { LocalizationContext } from 'context/localization.context';
import Breadcrumbs from 'components/framework/Breadcrumbs';

import Icon from 'components/ui/Icon';
import { CalendariumPageModel } from 'types/page-types';
import Button from 'components/ui/Button';
import RichText from 'components/ui/RichText';

const CalendariumPage = ({
	breadcrumbs,
	heading,
	preamble,
	text,
	eventDateFormatted,
	backToCalendariumListPageText,
	linkToCalendariumListPage,
	isInEditMode,
}: CalendariumPageModel) => {
	const { t }: any = useContext(LocalizationContext);
	const [windowUrl, setWindowUrl] = useState('');
	useEffect(() => {
		setWindowUrl(window.location.href);
	}, []);

	const renderSocialLinks = () => {
		return (
			<div className="flex space-x-1">
				<a
					className="m-0 flex items-center justify-center w-9 h-9 bg-grey hover:bg-greyDarker"
					href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
						windowUrl
					)}`}
					title={t('shared/socialmedia/shareonfacebook')}
					target="_blank"
				>
					<Icon icon="facebook" size={2} className="text-greyDarkest" />
				</a>
				<a
					className="m-0 flex items-center justify-center w-9 h-9 bg-grey hover:bg-greyDarker"
					href={`https://twitter.com/home?status=${encodeURIComponent(
						document.title
					)}%20${encodeURIComponent(windowUrl)}`}
					title={t('shared/socialmedia/shareontwitter')}
					target="_blank"
				>
					<Icon icon="twitterX" size={2} className="text-greyDarkest" />
				</a>
				<a
					className="m-0 flex items-center justify-center w-9 h-9 bg-grey hover:bg-greyDarker"
					href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
						windowUrl
					)}`}
					title={t('shared/socialmedia/shareonlinkedin')}
					target="_blank"
				>
					<Icon icon="linkedin" size={2} className="text-greyDarkest" />
				</a>
				<a
					className="m-0 flex items-center justify-center w-9 h-9 bg-grey hover:bg-greyDarker"
					href={`mailto:?subject=[title]&body=${encodeURIComponent(windowUrl)}`}
					title={t('shared/socialmedia/sharewithemail')}
					target="_blank"
				>
					<Icon icon="mail" size={2} className="text-greyDarkest" />
				</a>
			</div>
		);
	};
	return (
		<main id="main-content">
			<Layout backgroundColor="white">
				{breadcrumbs && <Breadcrumbs links={breadcrumbs} />}
				{heading &&
					renderElementBasedOnEditMode(
						isInEditMode,
						'heading',
						'h1',
						{
							className:
								'font-standard mb-6 mt-2 font-regular text-h2 md:mt-8 md:text-h1 text-blue',
						},
						heading
					)}
				<div className="md:flex block justify-between w-full md:w-2/3">
					<div className="md:mt-0 mt-4">
						{eventDateFormatted && (
							<div className="opacity-50 text-black no-underline text-2xl">
								{eventDateFormatted}
							</div>
						)}
					</div>

					<div className="md:mt-0 mt-4">{renderSocialLinks()}</div>
				</div>
				{preamble &&
					renderElementBasedOnEditMode(
						isInEditMode,
						'preamble',
						'p',
						{
							size: 'large',
							className:
								'md:w-2/3 font-medium w-full text-4xl leading-10 mb-10 mt-5',
						},
						preamble
					)}

				{text && (
					<div className="w-full md:w-2/3">
						<RichText name="text" text={text} isInEditMode={isInEditMode} />
					</div>
				)}
				<div className="flex md:w-2/3 justify-end flex-wrap w-full my-6">
					<Button
						url={linkToCalendariumListPage}
						type="link"
						buttonColor="grey"
						className="pl-0"
					>
						<Icon icon="chevrons" className="mr-3" />

						{backToCalendariumListPageText}
					</Button>
				</div>
			</Layout>
		</main>
	);
};

export default CalendariumPage;
