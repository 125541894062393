export const spacing05x = '0.4rem';
export const spacing1x = '0.8rem';
export const spacing2x = '1.6rem';
export const spacing3x = '2.4rem';
export const spacing4x = '3.2rem';
export const spacing5x = '4rem';
export const spacing6x = '4.8rem';
export const spacing7x = '5.6rem';
export const spacing8x = '6.4rem';
export const spacing9x = '7.2rem';
export const spacing10x = '8rem';
export const spacing12x = '9.6rem';
export const spacing15x = '12rem';
