import { useState, useEffect } from 'react';

const useWindowSize = (threshold: number): boolean => {
	const [isWindowSizeSmaller, setIsWindowSizeSmaller] = useState(
		window.innerWidth < threshold
	);

	useEffect(() => {
		const handleResize = (): void => {
			setIsWindowSizeSmaller(window.innerWidth < threshold);
		};

		handleResize(); // Initial check

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [threshold]);

	return isWindowSizeSmaller;
};

export default useWindowSize;
