/**
 * MyNewsDeskItemPage
 * @module pages/MyNewsDeskItemPage
 */

import React, { useContext, useEffect, useState } from 'react';
import ContentArea from 'components/framework/ContentArea';
import Layout from 'components/ui/Layout';
import { renderElementBasedOnEditMode } from 'common/helpers';
import { LocalizationContext } from 'context/localization.context';
import { FiChevronsLeft, FiDownload } from 'react-icons/fi';
import Breadcrumbs from 'components/framework/Breadcrumbs';
import {
	PublishInfoWrapper,
	Wrapper,
	Label,
	PublishText,
	SocialLink,
	FacebookIcon,
	TwitterIcon,
	LinedInIcon,
	EmailIcon,
	Image,
	Preamble,
	RichText,
	ButtonWrapper,
	ReturnButton,
	SaveButton,
} from './MyNewsDeskItemPage.styles';

const MyNewsDeskItemPage = ({
	breadcrumbs,
	heading,
	preamble,
	text,
	highlight,
	publishDate,
	image,
	returnButton,
	saveAsPdfButton,
	contentArea,
	isInEditMode,
}) => {
	const { t } = useContext(LocalizationContext);
	const [windowUrl, setWindowUrl] = useState('');
	useEffect(() => {
		setWindowUrl(window.location.href);
	}, []);

	const renderSocialLinks = () => {
		return (
			<>
				<SocialLink
					href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
						windowUrl
					)}`}
					title={t('shared/socialmedia/shareonfacebook')}
					target="_blank"
				>
					<FacebookIcon />
				</SocialLink>
				<SocialLink
					href={`https://twitter.com/home?status=${encodeURIComponent(
						document.title
					)}%20${encodeURIComponent(windowUrl)}`}
					title={t('shared/socialmedia/shareontwitter')}
					target="_blank"
				>
					<TwitterIcon />
				</SocialLink>
				<SocialLink
					href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
						windowUrl
					)}`}
					title={t('shared/socialmedia/shareonlinkedin')}
					target="_blank"
				>
					<LinedInIcon />
				</SocialLink>
				<SocialLink
					href={`mailto:?subject=[title]&body=${encodeURIComponent(windowUrl)}`}
					title={t('shared/socialmedia/sharewithemail')}
					target="_blank"
				>
					<EmailIcon />
				</SocialLink>
			</>
		);
	};

	return (
		<main id="main-content">
			<Layout backgroundColor="white">
				{breadcrumbs && <Breadcrumbs links={breadcrumbs} />}
				{heading &&
					renderElementBasedOnEditMode(
						isInEditMode,
						'heading',
						'h1',
						{
							className:
								'font-standard mb-6 mt-8 font-normal text-h2 md:text-h1 text-blue',
						},
						heading
					)}
				<PublishInfoWrapper>
					<Wrapper>
						{highlight && (
							<Label color={highlight.color}>{highlight.text}</Label>
						)}
						{publishDate && <PublishText>{publishDate}</PublishText>}
					</Wrapper>

					<Wrapper>{renderSocialLinks()}</Wrapper>
				</PublishInfoWrapper>
				{image && image.src && image.sizes.length > 0 && (
					<picture>
						<Image
							src={image.src}
							srcSet={`${image.sizes[0]} 800w, ${image.sizes[1]} 400w`}
							sizes="(max-width: 767px) 70vw, 800px"
							alt={image.alt ? image.alt : ''}
						/>
					</picture>
				)}

				{preamble &&
					renderElementBasedOnEditMode(
						isInEditMode,
						'preamble',
						Preamble,
						{ size: 'large' },
						preamble
					)}

				{text && (
					<RichText name="text" text={text} isInEditMode={isInEditMode} />
				)}

				<ButtonWrapper>
					<ReturnButton url={returnButton.link} type="link" buttonColor="grey">
						<FiChevronsLeft />
						{returnButton.text}
					</ReturnButton>
					<SaveButton
						url={saveAsPdfButton.link}
						type="link"
						buttonColor="orange"
						target="_blank"
					>
						{saveAsPdfButton.text}
						<FiDownload />
					</SaveButton>
				</ButtonWrapper>
			</Layout>

			<ContentArea contentArea={contentArea} />
		</main>
	);
};

export default MyNewsDeskItemPage;
