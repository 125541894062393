/**
 * SearchField
 * @module components/SearchField
 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LocalizationContext } from 'context/localization.context';

import { FiSearch, FiXCircle } from 'react-icons/fi';
import {
	Form,
	Button,
	VisuallyHidden,
	InputField,
	ClearButton,
} from './SearchField.styles';

const SearchField = ({
	placeholder,
	id,
	name,
	onChange,
	onClear,
	onSubmit,
	onFocus,
	fieldSize,
}) => {
	const { t } = useContext(LocalizationContext);

	return (
		<Form method="get" onSubmit={onSubmit}>
			<label htmlFor={id}>
				<VisuallyHidden>{t('shared/header/searchlabel')}</VisuallyHidden>
			</label>
			<InputField
				placeholder={placeholder}
				id={id}
				name={name}
				fieldSize={fieldSize}
				onChange={onChange}
				onFocus={onFocus}
				autoComplete="off"
			/>
			<Button type="submit">
				<FiSearch />
				<VisuallyHidden>{t('shared/header/searchphrase')}</VisuallyHidden>
			</Button>
			<ClearButton
				type="button"
				onClick={(e) => {
					e.preventDefault();
					const input = document.getElementById(id);
					input.value = '';
					onClear && onClear();
				}}
			>
				<FiXCircle />
				<VisuallyHidden>{t('shared/header/clearsearchphrase')}</VisuallyHidden>
			</ClearButton>
		</Form>
	);
};

SearchField.propTypes = {
	placeholder: PropTypes.string,
	action: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string,
};

export default SearchField;
