import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DictionaryModel } from 'types/common';

interface LocalizationState {
	translations?: DictionaryModel;
	error: string | null;
	loading: boolean;
}

const initialState: LocalizationState = {
	translations: {},
	error: null,
	loading: false,
};

const slice = createSlice({
	name: 'localization',
	initialState,
	reducers: {
		LocalizationInit: (state, action: PayloadAction<any>) => {
			state.loading = true;
			state.translations = action.payload.translations;
		},
	},
});

export const { LocalizationInit } = slice.actions;

export default slice.reducer;
