/**
 * LoginPage
 * @module pages/LoginPage
 */

import { useContext, useState, useEffect, useRef } from 'react';
import Layout from 'components/ui/Layout';
import { renderElementBasedOnEditMode } from 'common/helpers';
import { LocalizationContext } from 'context/localization.context';
import CustomExternalScript from 'components/framework/CustomExternalScript';
import RichText from 'components/ui/RichText';
import clsx from 'clsx';
import Button from 'components/ui/Button';
import ContentArea from 'components/framework/ContentArea';
import { useLogin } from 'api';
import useCookie from 'react-use-cookie';
import { Cell, Grid } from 'components/ui/Grid';
import Icon from 'components/ui/Icon';
import { LoginPageModel } from 'types/page-types';

const LoginPage = ({
	heading,
	text,
	button,
	forgotPasswordLink,
	requestAccountLink,
	contentArea,
	returnUrl,
}: LoginPageModel): JSX.Element => {
	const { t }: any = useContext(LocalizationContext);
	const [rememberMeCookie, setRememberMeCookie] = useCookie(
		'rememberMeCookie',
		''
	);
	const formRef = useRef<any>();
	const [username, setUsername] = useState(rememberMeCookie || '');
	const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [isChecked, setIsChecked] = useState(rememberMeCookie !== '' || false);
	const [loginRequest, setLoginRequest] = useState<any>({});
	const [loginResponse, loginError, loginLoading] = useLogin(loginRequest);

	useEffect(() => {
		if ((loginResponse as any).modelType === 'LoginResponse') {
			if ((loginResponse as any).success) {
				window.location.href = (loginResponse as any).redirectUrl;
			} else {
				setErrorMessage((loginResponse as any).errorMessage);
			}
		}
		if (loginError) {
			setErrorMessage('shared/toast/servererror');
		}
		setPassword('');
		setLoginRequest({});
		//eslint-disable-next-line
	}, [loginResponse, loginError]);

	const handleCheckbox = () => {
		setIsChecked(!isChecked);
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (username.length === 0 || password.length === 0) {
			setErrorMessage(t('loginpage/errormessage'));
		} else {
			setErrorMessage('');
			if (isChecked) {
				setRememberMeCookie(username);
			} else {
				setRememberMeCookie('');
			}
			const antiForgeryElement = document.querySelector(
				'#antiforgery > input[name="__RequestVerificationToken"]'
			);
			const antiforgeryValue =
				antiForgeryElement && (antiForgeryElement as HTMLInputElement).value;
			setLoginRequest({
				url: button && button.link,
				form: formRef.current,
				antiForgery: antiforgeryValue,
			});
		}
	};

	return (
		<form method="POST" action="" onSubmit={handleSubmit} ref={formRef}>
			<main id="main-content">
				<Layout backgroundColor="white">
					{heading &&
						renderElementBasedOnEditMode(
							true,
							'heading',
							'h1',
							{
								className:
									'font-standard mb-6 mt-10 font-normal text-h2 md:text-h1 text-blue',
							},
							heading
						)}
					<Grid padding={false} className="mb-16">
						<Cell span={12} tablet={6} className="lg:pr-1/12">
							{text && (
								<RichText
									isInEditMode={true}
									name="text"
									size="large"
									text={text}
								/>
							)}
							<CustomExternalScript />
						</Cell>
						<Cell span={12} tablet={6} className="lg:pr-1/12">
							<div className="relative mb-4">
								<label
									className="text-p text-black font-bold block mb-2"
									htmlFor="username"
								>
									{t('loginpage/usernameoremail')}
								</label>
								<input
									className="w-full pl-10 pr-6 py-2 border border-greyDark rounded text-p placeholder-greyDarkest"
									placeholder={t('loginpage/usernameoremailplaceholder')}
									id="username"
									name="username"
									value={username}
									onChange={(e) => setUsername(e.target.value)}
								/>
								<Icon
									icon="user"
									size={1.25}
									color="#949494"
									className="absolute top-0 left-0 mt-10.5 ml-3"
									aria-hidden={true}
								/>
							</div>
							<div className="relative mb-4">
								<label
									className="text-p text-black font-bold block mb-2"
									htmlFor="password"
								>
									{t('loginpage/password')}
								</label>
								<input
									className="w-full pl-10 pr-6 py-2 border border-greyDark rounded text-p placeholder-greyDarkest"
									placeholder={t('loginpage/passwordplaceholder')}
									id="password"
									name="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									type="password"
								/>
								<Icon
									icon="lock"
									size={1.25}
									color="#949494"
									className="absolute top-0 left-0 mt-10.5 ml-3"
									aria-hidden={true}
								/>
							</div>
							<label className="flex items-baseline cursor-pointer relative mb-4">
								<input
									type="checkbox"
									name="rememberMe"
									id="rememberMe"
									className="relative opacity-0"
									onChange={handleCheckbox}
								/>
								<Icon
									icon={isChecked ? 'checkSquare' : 'square'}
									size={1.25}
									className={clsx(
										'absolute left-0 focused-sibling:outline-black',
										isChecked ? 'text-blue' : 'text-greyDarkest'
									)}
									aria-hidden={true}
								/>
								<span className="ml-3 text-p">{t('loginpage/rememberme')}</span>
							</label>
							<input type="hidden" name="returnUrl" value={returnUrl} />

							{errorMessage && (
								<p className="text-h4 mb-4 text-red">{errorMessage}</p>
							)}

							{button && button.text && button.link && (
								<Button
									type="submit"
									buttonColor="blue"
									disabled={loginLoading || false}
									className="mb-4"
								>
									{button.text}
								</Button>
							)}

							{forgotPasswordLink && (
								<div className="block">
									<a
										href={forgotPasswordLink.link}
										className="text-p text-blue hover:underline"
									>
										{forgotPasswordLink.text}
									</a>
								</div>
							)}
							{requestAccountLink && (
								<div className="block">
									<a
										href={requestAccountLink.link}
										className="text-p text-blue hover:underline"
									>
										{requestAccountLink.text}
									</a>
								</div>
							)}
						</Cell>
					</Grid>
				</Layout>
				{contentArea && <ContentArea contentArea={contentArea} />}
			</main>
		</form>
	);
};

export default LoginPage;
