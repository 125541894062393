import { RefObject } from 'react';
import { FieldPropsModel } from 'types/common';

/**
 * Custom hook for validating a field
 * @param {Node} fieldRef - The field node
 * @param {Object} fieldProps - The field component props
 */

export interface FieldModel {
	id: string;
	label: string;
	message: string;
}

export const useFieldValidation = (
	fieldRef: RefObject<HTMLSelectElement> | null,
	fieldProps: FieldPropsModel
) => {
	const {
		required,
		pattern,
		label,
		id,
		type,
		validationMessage,
		patternMessage,
		single,
		multiple,
		min,
		max,
		rangeMessage,
	} = fieldProps;

	const validateField = (value: string | number) => {
		const message = '';
		let field = { id, label, message };

		const fieldIsNotValid = value !== '' && !fieldRef?.current?.checkValidity();
		const requiredFieldIsNotFilledIn =
			(required && value === '') ||
			(required && value === 'fieldset' && !fieldsetHasOneCheckedInput());
		const fieldDoesNotMatchPattern =
			!value.toString().match(pattern ? pattern : '') && value !== '';
		const singleInputIsNotChecked = single && required && !value;
		const fieldIsNotWithinRange =
			((value as number) - (min ? min : 0)) *
				((value as number) - (max ? max : 0)) >
			0;

		if (type && type.toLowerCase() === 'url' && fieldIsNotValid) {
			field.message = validationMessage || 'Ange en giltig url';
		} else if (type && type.toLowerCase() === 'email' && fieldIsNotValid) {
			field.message =
				patternMessage || validationMessage || 'Ange en giltig e-postadress';
		} else if (requiredFieldIsNotFilledIn || singleInputIsNotChecked) {
			field.message = validationMessage || 'Obligatoriskt fält';
		} else if (pattern && fieldDoesNotMatchPattern) {
			field.message =
				patternMessage || 'Vänligen matcha det förväntade formatet.';
		} else if (rangeMessage && fieldIsNotWithinRange) {
			field.message =
				rangeMessage || 'Vänligen matcha det förväntade formatet.';
		}

		return field;
	};

	/**
	 * Checks if any input (radio/checkbox) within the fieldset group has been checked
	 */
	const fieldsetHasOneCheckedInput = () => {
		var allInputs = fieldRef?.current?.querySelectorAll('input'),
			hasOneValidInput = false;

		allInputs?.forEach((field: { checked: any }) => {
			if (field.checked) {
				hasOneValidInput = true;
			}
		});

		return hasOneValidInput;
	};

	/**
	 * Displays a field error with message
	 * @param {Node} field - Field object with message, id and label
	 * @param {Boolean} fieldType - The field type
	 */
	const showFieldError = (field: FieldModel, fieldType?: string) => {
		let label = document.querySelector(`#form${id}__desc`);

		if (fieldRef === null && multiple) {
			fieldRef = document.querySelector('.select__control') as any;
		}

		if (fieldType === 'fieldset') {
			const invalidLabel = fieldRef?.current?.querySelector(
				'.form__label--invalid'
			);
			if (label && invalidLabel !== undefined) {
				label = invalidLabel;
			}
		} else if (fieldType === 'multiselect') {
			const fieldNode = document.querySelector('.select__control');
			fieldNode && fieldNode.classList.add('form__field--invalid');
		} else {
			fieldRef?.current?.classList.add('form__field--invalid');
		}

		if (label) {
			label.textContent = field.message;
			label.classList.remove('form__label--hidden');
		}
	};

	/**
	 * Hides field error and clears message
	 * @param {Boolean} fieldType - The field type
	 */
	const hideFieldError = (fieldType: string) => {
		let label = document.querySelector(`#form${id}__desc`);

		if (fieldRef === null && multiple) {
			fieldRef = document.querySelector('.select__control') as any;
		}

		if (fieldType === 'fieldset') {
			const invalidLabel = fieldRef?.current?.querySelector(
				'.form__label--invalid'
			);
			if (label && invalidLabel !== undefined) {
				label = invalidLabel;
			}
		} else if (fieldType === 'multiselect') {
			const fieldNode = document.querySelector('.select__control');
			fieldNode && fieldNode.classList.remove('form__field--invalid');
		} else if (fieldType !== 'fieldset') {
			fieldRef?.current?.classList.remove('form__field--invalid');
		}

		if (label) {
			label.textContent = '';
			label.classList.add('form__label--hidden');
		}
	};

	return [validateField, showFieldError, hideFieldError];
};
