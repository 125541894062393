/**
 * AlertModal
 */

import React from 'react';
import Button from '../Button';
import Modal from '../Modal';

interface Props {
	/** Modal title */
	title: string;

	/** Modal heading */
	heading: string;

	/** Message text showing in the alert modal */
	message: string;

	/** Text to cancel button */
	cancelButtonText?: string;

	/** Toogle modal callback */
	onToggle: any;

	/** Text to optional action button */
	actionButtonText?: string;

	/** Callback to optional action button */
	onClick?: any;
}

/** Main description for this component. */
const AlertModal = ({
	title,
	heading,
	message,
	cancelButtonText,
	actionButtonText,
	onToggle,
	onClick,
}: Props): JSX.Element => {
	return (
		<Modal
			title={title}
			heading={heading}
			width="small"
			toggleModal={() => {
				onToggle && onToggle();
			}}
		>
			<p className="text-p font-bold mb-4">{message}</p>
			<div className="text-right">
				{cancelButtonText && (
					<Button
						type="button"
						buttonColor="grey"
						onClick={() => {
							onToggle && onToggle();
						}}
					>
						{cancelButtonText}
					</Button>
				)}
				{actionButtonText && (
					<Button
						type="button"
						buttonColor="blue"
						className="ml-4"
						onClick={() => {
							onClick && onClick();
						}}
					>
						{actionButtonText}
					</Button>
				)}
			</div>
		</Modal>
	);
};

export default AlertModal;
