import { H4Css, paragraphCss } from 'components/ui/Typography';
import styled from 'styled-components';
import { black } from 'theme/colors';
import { spacing05x, spacing1x } from 'theme/spacings';

export const Li = styled.li`
	list-style: none;
	border-bottom: 0.1rem solid #d0d0d0;
	padding-bottom: 1.2rem;
	margin-bottom: 1.2rem;
`;

export const Link = styled.a`
	text-decoration: none;

	&:hover {
		h2 {
			text-decoration: underline;
		}
	}
`;

export const Text = styled.p`
	${paragraphCss};
	margin: 0 0 ${spacing05x};
	color: ${black};
`;

export const Category = styled.span`
	font-weight: bold;
	margin-right: ${spacing1x};
`;

export const Title = styled.h2`
	${H4Css};
	margin: 0;
	font-weight: bold;
`;
