import styled from 'styled-components';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { FaHome } from 'react-icons/fa';
import { greyDarker, black, greyDarkest } from 'theme/colors';
import { deviceSmallMax } from 'theme/mediaQueries';
import RichText from 'components/ui/RichText';
import { spacing05x, spacing3x } from 'theme/spacings';

export const BreadCrumbsList = styled.ol`
	list-style: none;
	display: inline-block;
	padding: 0;
	margin: ${spacing3x} 0 0 0;

	:last-child:after {
		content: '';
	}
	@media (max-width: 1010px) {
		margin: 0;
	}
	@media (max-width: ${deviceSmallMax}) {
		padding: 1rem 0;
	}
`;

export const BreadCrumbsItem = styled.li`
	display: inline-block;
	padding-left: ${spacing05x};
	color: ${greyDarker};

	@media (max-width: ${deviceSmallMax}) {
		display: none;
		:last-of-type {
			display: inline-block;
			svg {
				margin-left: 0;
			}
		}
	}
`;

export const SeperatorIcon = styled(FiChevronRight)`
	font-size: 1.5rem;
	vertical-align: middle;
	margin: 0 0.5rem;
	@media (max-width: ${deviceSmallMax}) {
		display: none;
	}
`;

export const HomeIcon = styled(FaHome)`
	font-size: 1.6rem;
	color: ${greyDarkest};
	opacity: 0.5;
	vertical-align: middle;
	margin-right: 0.5rem;
`;

export const PreviousPageIcon = styled(FiChevronLeft)`
	display: none;

	@media (max-width: ${deviceSmallMax}) {
		display: inline-block;
		font-size: 1.5rem;
		vertical-align: middle;
		margin: 0 0.5rem;
	}
`;

export const BreadCrumbsLink = styled.a`
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
	&:active {
		text-decoration: underline;
	}
`;

export const LinkRichText = styled(RichText)`
	display: inline-block;
	font-size: 1.6rem;
	color: ${black};
	opacity: 0.5;
`;
