import styled from 'styled-components';
import { fontFamily } from 'theme/typography';
import { ToastContainer } from 'react-toastify';

export const StyledToastContainer = styled(ToastContainer)`
	z-index: 9999;
	> div {
		font-family: ${fontFamily} !important;
		z-index: 9999;
	}
`;
