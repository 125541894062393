/**
 * Cart
 * @module components/Cart
 */

import { useContext } from 'react';
import { CartIcon, Items } from './Cart.styles';
import { LocalizationContext } from 'context/localization.context';
import { ViewContext } from 'context/view.context';
import { useSelector } from 'react-redux';
import { selectCartObject } from 'store/modules/cart';

import useWindowSize from 'hooks/useWindowSize';
import { white, blue } from 'theme/colors';

interface Props {
	/** Link to CartPage */
	link: string;

	/** Link target */
	target?: string;

	cartDisabled: boolean;
}

const Cart = ({ link, target, cartDisabled }: Props) => {
	const { t } = useContext<any>(LocalizationContext);
	const { items } = useSelector(selectCartObject);

	const isMobile = useWindowSize(1150);

	const { view } = useContext<any>(ViewContext);

	return (
		<a target={target || '_self'} href={link || '/'} className="no-underline">
			{cartDisabled ? (
				<span>
					<CartIcon
						style={{
							color:
								(isMobile || view === 'mobile') && view !== 'desktop'
									? blue
									: white,
							marginRight:
								(isMobile || view === 'mobile') && view !== 'desktop'
									? '0rem'
									: '2rem',
						}}
						opacity="0.5"
					/>
					<span className="sr-only">{t('shared/header/carthidden')}</span>
				</span>
			) : (
				<>
					<span>
						<CartIcon
							style={{
								color:
									(isMobile || view === 'mobile') && view !== 'desktop'
										? blue
										: white,
								marginRight:
									(isMobile || view === 'mobile') && view !== 'desktop'
										? '0rem'
										: '2rem',
							}}
						/>
						<span className="sr-only">{t('shared/header/showcart')}</span>
					</span>
					<span>
						<span className="sr-only">{t('shared/header/cartitems')}</span>
						<Items>{items}</Items>
					</span>
				</>
			)}
		</a>
	);
};

export default Cart;
