/**
 * FormStep
 */

import { EpiserverFormsPageModel } from 'types/page-types';
import { NextButton, PreviousButton } from './FormButtons';

interface Step {
	title: string;
	description?: string;
	index: number;
}

interface FormStepProp {
	step: Step;
	formId: string;
	nrOfSteps: number;
	currentStep: number;
	children: JSX.Element | JSX.Element[];
	onPrevious: (state: EpiserverFormsPageModel) => void;
	translations: any;
}

const FormStep = ({
	step,
	formId,
	nrOfSteps,
	currentStep,
	children,
	onPrevious,
	translations,
}: FormStepProp) => {
	const { title, description, index } = step;

	const stepId = `form-${formId}-step-${index}`;
	const isFirstStep = index === 0;
	const isLastStep = index + 2 === nrOfSteps;

	const previousIndex = isFirstStep ? undefined : index - 1;
	const previousId = isFirstStep
		? undefined
		: `form-${formId}-step-${previousIndex}`;

	const nextIndex = isLastStep ? undefined : index + 1;
	const nextId = isLastStep ? undefined : `form-${formId}-step-${nextIndex}`;

	return (
		<section
			id={stepId}
			key={stepId}
			aria-hidden={index !== currentStep}
			hidden={index !== currentStep}
		>
			{title && (
				<header>
					<h1 tabIndex={-1}>{title}</h1>
					{description && <p>{description}</p>}
				</header>
			)}
			{children}

			<div>
				{!isFirstStep && (
					<PreviousButton
						isExpanded={currentStep === previousIndex}
						previousIndex={previousIndex}
						previousId={previousId}
						id={`${formId}-step-${index}-prev-button`}
						onPrevious={onPrevious}
						translations={translations}
					/>
				)}
				{!isLastStep && (
					<NextButton
						isExpanded={currentStep === nextIndex}
						nextId={nextId}
						id={`${formId}-step-${index}-next-button`}
						translations={translations}
					/>
				)}
			</div>
		</section>
	);
};

export default FormStep;
