import styled from 'styled-components';
import { FiSquare, FiCheckSquare } from 'react-icons/fi';
import { Paragraph, H5 } from 'components/ui/Typography';
import { spacing1x, spacing2x } from 'theme/spacings';
import { blue, greyDarkest, black } from 'theme/colors';
import { fontWeightSemiBold } from 'theme/typography';
import { deviceSmallMax } from 'theme/mediaQueries';

export const SquareIcon = styled(FiSquare)`
	color: ${greyDarkest};
	margin-top: 0.1rem;
	position: absolute;
	left: 0;
`;
export const SquareCheckIcon = styled(FiCheckSquare)`
	display: none;
	color: ${blue};
	margin-top: 0.1rem;
	position: absolute;
	left: 0;
`;

export const Fieldset = styled.fieldset`
	border: 0;
	padding: 0;
`;

export const Heading = styled(H5)`
	font-weight: ${fontWeightSemiBold};
	margin: 0;
	@media (max-width: ${deviceSmallMax}) {
		font-size: 1.6rem;
	}
`;

export const DefaultCheckbox = styled.input`
	opacity: 0;
	position: relative;

	:checked + ${SquareIcon} {
		display: none;
	}

	:checked ~ ${SquareCheckIcon} {
		display: inline-block;
	}
`;

export const Body = styled(Paragraph)`
	width: 100%;
	display: inline-flex;
	justify-content: space-between;
	margin: 0 0 0 ${spacing2x};
`;

export const RightContent = styled.span`
	color: ${greyDarkest};
`;

export const LeftContent = styled.span`
	color: ${black};
`;

export const Button = styled(Paragraph)`
	border: 0;
	padding: 0;
	background: none;
	color: ${greyDarkest};
	cursor: pointer;
	outline: none;
	margin-top: 0;
	margin-left: ${spacing1x};

	:hover {
		text-decoration: underline;
	}

	@media (max-width: ${deviceSmallMax}) {
		margin-bottom: 0;
	}
`;
