import styled from 'styled-components';
import { blue, white, greyDark } from 'theme/colors';
import {
	spacing3x,
	spacing4x,
	spacing2x,
	spacing1x,
	spacing05x,
} from 'theme/spacings';
import Button from 'components/ui/Button';
import { deviceSmallMax } from 'theme/mediaQueries';

import Slider from 'react-slick';

export const ContentButton = styled(Button)`
	position: absolute;
	margin: 0;
	padding: ${spacing1x} ${spacing2x} ${spacing1x} ${spacing1x};
	bottom: ${`-${spacing4x}`};
	@media (max-width: ${deviceSmallMax}) {
		position: static;
		margin-top: ${spacing1x};
		& > svg {
			margin: 0 ${spacing05x} 0.1rem 0;
		}
	}
	& > svg {
		margin-right: ${spacing05x};
		margin-bottom: 0.1rem;
	}
`;

export const SliderWrapper = styled(Slider)`
	& > .slick-dots {
		position: absolute;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.25);
		width: auto;
		left: 50%;
		transform: translateX(-50%);
		padding: ${spacing05x} ${spacing3x} 0 ${spacing3x};
	}
	& > .slick-dots > .slick-active > div {
		background-color: ${white} !important;
		border: none;
	}
	& > .slick-dots > li > button:before {
		content: '';
		position: relative;
	}

	@media (max-width: ${deviceSmallMax}) {
		& > .slick-dots {
			position: relative;
			background-color: transparent;
		}
		& > .slick-dots > .slick-active > div {
			background-color: ${blue} !important;
			border: none;
		}
	}
`;

export const SliderDot = styled.div`
	border-radius: 50%;
	width: 1.2rem;
	height: 1.2rem;
	border: 0.2rem solid ${(props) => (props.selected ? white : white)};
	background-color: ${(props) => (props.selected ? white : 'transparent')};
	margin: ${spacing05x} 0;

	&:hover,
	:active {
		background-color: ${white};
	}
	@media (max-width: ${deviceSmallMax}) {
		border: 0.2rem solid ${greyDark};
		background-color: ${(props) => (props.selected ? white : 'transparent')};
	}
`;
