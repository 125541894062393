export default ({ className, ...htmlAttributes }: { className?: string }) => (
	<svg
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
		{...htmlAttributes}
	>
		<circle
			cx="7"
			cy="7"
			r="6"
			stroke="#0075BF"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7 3.66675V5.76401C7 6.52156 7.428 7.21408 8.10557 7.55287L9.66667 8.33341"
			stroke="#0075BF"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);
