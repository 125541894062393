import styled from 'styled-components';
import { spacing2x } from 'theme/spacings';

export const Wrapper = styled.div`
	display: flex;

	@media (max-width: 500px) {
		display: block;
		margin-bottom: ${spacing2x};
	}
`;

export const Header = styled.div`
	width: 100%;
`;
