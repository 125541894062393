import styled from 'styled-components';
import { grey, black, blue, orange, white } from 'theme/colors';
import { spacing1x, spacing2x } from 'theme/spacings';
import { FiBox, FiPlay, FiZoomIn } from 'react-icons/fi';
import { deviceMediumMax } from 'theme/mediaQueries';

type Overlay = 'Video' | 'Image' | '3DConfig';

interface PlayIconProps {
	iconsize: 'small' | 'medium' | 'large'; // Define the possible values for iconsize
}
interface BoxIconProps {
	iconsize: 'small' | 'medium' | 'large'; // Define the possible values for iconsize
}

interface ButtonProps {
	overlay?: Overlay;
	isIframe?: boolean;
}

interface ThumbnailWrapperProps {
	isActive?: boolean;
	overlay?: Overlay;
}

interface ViewWrapperProps {
	iframe3d?: boolean;
	overlay?: Overlay;
}

/* MediaThumbnail */

export const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const ThumbnailWrapper = styled.a<ThumbnailWrapperProps>`
	position: relative;
	height: 6rem;
	width: 10.3rem;
	outline: 0.2rem solid ${({ isActive }) => (isActive ? orange : grey)};
	display: inline-block;
	margin-right: ${spacing2x};
	margin-bottom: ${spacing1x};
	background-color: transparent;
	transition: all 0.25s ease-out;
	cursor: pointer;
	overflow: hidden;

	:focus {
		outline: 0.2rem solid ${blue};
	}

	:active {
		outline: none;
	}

	:hover {
		outline: 0.2rem solid ${blue};
	}

	@media (max-width: ${deviceMediumMax}) {
		height: 5.4rem;
		width: 8.6rem;
		margin-right: ${spacing1x};
	}
	${({ overlay }) =>
		(overlay === 'Video' || overlay === '3DConfig') &&
		`
	:after {
		content: ' ';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0; 
		right 0;
		background-color: rgba(0, 0, 0, 0.25);
	}
	`}
`;
export const VideoThumbnail = styled.div`
	display: inline-block;
	width: 100%;
	height: 100%;
	background-color: ${black};
	opacity: 0.15;
	position: relative;
`;

export const ToggleWrapper = styled.button`
	font-size: 1.6rem;
	color: ${blue};
	height: 6.4rem;
	width: 10.3rem;
	display: inline-block;
	outline: none;
	background-color: transparent;
	border: none;
	cursor: pointer;
	margin-bottom: 1rem;

	:focus {
		text-decoration: underline;
	}

	:hover {
		text-decoration: underline;
	}
`;

/* MediaView */

export const ViewWrapper = styled.div<ViewWrapperProps>`
	width: 100%;
	${({ iframe3d }) =>
		iframe3d &&
		`
    height: 548px;
  `}
	border: 0.2rem solid ${grey};
	position: relative;
	margin-bottom: ${spacing2x};
	overflow: hidden;
`;

export const ZoomInIcon = styled(FiZoomIn)`
	position: absolute;
	right: 1.6rem;
	bottom: 1.6rem;
	color: ${black};
	font-size: 2.4rem;
	opacity: 0.25;
`;

export const Button = styled.button<ButtonProps>`
	cursor: pointer;
	background-color: transparent;
	border: none;
	padding: 0;

	:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: ${({ overlay }) =>
			(overlay === 'Video' || overlay === '3DConfig') && 'rgba(0, 0, 0, 0.25)'};
	}
	:focus svg {
		outline: 0.2rem solid ${blue};
	}
`;

/* MediaModal */

export const VideoModal = styled(VideoThumbnail)``;

export const Image = styled.img`
	width: 100%;
	object-fit: cover;
	font-family: 'object-fit: cover;';

	:focus {
		outline: none;
	}
`;
export const PlayIcon = styled(FiPlay)<PlayIconProps>`
	position: absolute;
	z-index: 100;
	color: ${white};
	opacity: 0.75;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	font-size: ${({ iconsize }) =>
		iconsize === 'large' ? '8rem' : iconsize === 'medium' ? '5rem' : '2rem'};
	:hover {
		opacity: 1;
	}
`;

export const BoxIcon = styled(FiBox)<BoxIconProps>`
	position: absolute;
	z-index: 100;
	color: ${white};
	opacity: 0.75;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	font-size: ${({ iconsize }) =>
		iconsize === 'large' ? '8rem' : iconsize === 'medium' ? '5rem' : '2rem'};
	:hover {
		opacity: 1;
	}
`;
