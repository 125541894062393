/**
 * TextArea
 * @module components/Form/TextArea
 */

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFieldValidation } from 'hooks';
import { useFormValue } from 'context/form.context';
import { StyledTextArea } from './Form.styles';

const TextArea = (props) => {
	const {
		className,
		type,
		id,
		description,
		name,
		placeholder,
		defaultValue,
		required,
		readOnly,
		disabled,
		autoSubmit,
		onFieldChange,
		showError,
		visibleFields,
		translations,
	} = props;

	const [{ invalidFields }, dispatch] = useFormValue();
	const [value, setValue] = useState(defaultValue || '');
	const [valid, setValid] = useState(false);
	const [touched, setTouched] = useState(false);
	const fieldRef = useRef(null);
	const [validateField, showFieldError, hideFieldError] = useFieldValidation(
		fieldRef,
		props,
		translations
	);

	useEffect(() => {
		const validatedField = validateField(value);

		if (validatedField.message) {
			setValid(false);
			dispatch({ type: 'FIELD_INVALID', field: validatedField });
		} else {
			setValid(true);

			if (invalidFields.includes(validatedField.id)) {
				dispatch({ type: 'FIELD_VALID', field: validatedField });
			}
		}

		if (touched && !valid) {
			showFieldError(validatedField);
		} else if (
			(showError && !valid && visibleFields && visibleFields.includes(id)) ||
			(showError && !valid && !visibleFields)
		) {
			setTouched(true);
			showFieldError(validatedField);
		} else {
			hideFieldError();
		}
		//eslint-disable-next-line
	}, [touched, showError, value]);

	const onChange = (e) => {
		setValue(e.target.value);
		onFieldChange({
			name,
			value: e.target.value,
			type: 'TextArea',
			autoSubmit,
		});
	};

	return (
		<StyledTextArea
			className={className || ''}
			type={type}
			id={id}
			title={description}
			aria-invalid={!valid}
			name={name}
			required={required}
			disabled={disabled}
			readOnly={readOnly}
			placeholder={placeholder || ''}
			value={value}
			onChange={onChange}
			touched={touched}
			onBlur={() => setTouched(true)}
			ref={fieldRef}
			aria-describedby={`form${id}__desc`}
		/>
	);
};

TextArea.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	description: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	value: PropTypes.string,
	defaultValue: PropTypes.string,
	autoSubmit: PropTypes.bool,
	setFocus: PropTypes.bool,
	disabled: PropTypes.bool,
	onFieldChange: PropTypes.func,
	showError: PropTypes.bool,
	visibleFields: PropTypes.any,
};

export default TextArea;
