/**
 * RadioButton
 * @module components/RadioButton
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFieldValidation } from 'hooks';
import { useFormValue } from 'context/form.context';

const RadioButton = (props) => {
	const {
		id,
		name,
		defaultValue,
		onFieldChange,
		autoSubmit,
		value,
		single,
		showError,
		className,
		visibleFields,
		index,
		translations,
	} = props;
	const [checked, setChecked] = useState('');

	const [{ invalidFields }, dispatch] = useFormValue();
	const [valid, setValid] = useState(false);
	const [touched, setTouched] = useState(false);
	const fieldRef = useRef(null);
	const [validateField, showFieldError, hideFieldError] = useFieldValidation(
		fieldRef,
		props,
		translations
	);

	useEffect(() => {
		if (single) {
			const validatedField = validateField(checked);

			if (validatedField.message) {
				setValid(false);
				dispatch({ type: 'FIELD_INVALID', field: validatedField });
			} else {
				setValid(true);

				if (invalidFields.includes(validatedField.id)) {
					dispatch({ type: 'FIELD_VALID', field: validatedField });
				}
			}

			if (touched && !valid) {
				showFieldError(validatedField);
			} else if (
				(showError && !valid && visibleFields && visibleFields.includes(id)) ||
				(showError && !valid && !visibleFields)
			) {
				setTouched(true);
				showFieldError(validatedField);
			} else {
				hideFieldError();
			}
		}
	}, [
		valid,
		dispatch,
		touched,
		showError,
		setValid,
		validateField,
		showFieldError,
		hideFieldError,
		invalidFields,
		visibleFields,
		id,
		single,
		checked,
	]);

	const onChange = (e) => {
		setChecked(e.target.value);
		if (!touched) {
			setTouched(true);
		}

		onFieldChange({
			name,
			value: e.target.value,
			type: 'RadioButton',
			autoSubmit,
		});
	};

	return (
		<input
			type="radio"
			id={index >= 0 ? `${id}-${index}` : id}
			name={name}
			value={value}
			className={className}
			defaultChecked={value === defaultValue}
			ref={fieldRef}
			onChange={onChange}
			aria-describedby={`form${id}__desc`}
		/>
	);
};

RadioButton.propTypes = {
	type: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	autoSubmit: PropTypes.bool,
	onFieldChange: PropTypes.func,
	single: PropTypes.bool,
	showError: PropTypes.bool,
	visibleFields: PropTypes.any,
	index: PropTypes.number,
};

export default RadioButton;
