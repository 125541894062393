/**
 * Multiselect
 */

import React from 'react';
import { Fieldset, Checkbox } from '../index';

const Multiselect = ({
	id,
	description,
	required,
	label,
	isInvalid,
	validationMessage,
	options,
	onFieldChange,
}) => {
	return (
		<Fieldset
			id={id}
			description={description}
			required={required}
			label={label}
			isInvalid={isInvalid}
			validationMessage={validationMessage}
		>
			{options.map((opt, index) => {
				return (
					<label htmlFor={`${id}-${index}`} key={`${id}-${index}`}>
						<Checkbox
							id={id}
							index={index}
							name={id}
							value={opt.value}
							label={opt.caption}
							onFieldChange={onFieldChange}
						/>
						<span>{opt.caption}</span>
					</label>
				);
			})}
		</Fieldset>
	);
};

export default Multiselect;
