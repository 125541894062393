import React from 'react';
import styled, { css } from 'styled-components';
import Button from 'components/ui/Button';
import { ReactComponent as CirclePattern } from './circle-pattern.svg';
import { ReactComponent as Circle2Pattern } from './circle2-pattern.svg';

import { Paragraph } from 'components/ui/Typography';
import {
	spacing05x,
	spacing1x,
	spacing2x,
	spacing3x,
	spacing8x,
} from 'theme/spacings';
import {
	blue,
	blueLight,
	white,
	greyDarker,
	greyLight,
	black,
	blueDark,
} from 'theme/colors';
import { deviceSmallMax, deviceLargeMax } from 'theme/mediaQueries';
import { fontWeightLight } from 'theme/typography';

const theme = {
	blue: {
		headingColor: white,
		background: blue,
		color: white,
	},
	blueLight: {
		background: blueLight,
		headingColor: blue,
		color: black,
	},
	white: {
		headingColor: blue,
		background: white,
		color: black,
	},
	greyLight: {
		background: greyLight,
		headingColor: blue,
		color: black,
	},
};

export const PromoPatternWrapper = styled.div`
	height: 35.5rem;
	position: relative;
	@media (max-width: 981px) {
		height: 20rem;
	}
`;

const getPatternFill = (theme) => {
	switch (theme) {
		case 'blue':
			return white;
		case 'blueLight':
			return blue;
		default:
			return greyDarker;
	}
};

export const PromoPattern = styled(CirclePattern)`
	position: absolute;
	display: block;
	left: 50%;
	bottom: 0;
	max-height: 100%;
	max-width: 100%;
	transform: translateX(-50%);
	fill: ${(props) => getPatternFill(props.theme)};
	@media (max-width: ${deviceSmallMax}) {
		max-width: 80%;
	}
`;

export const PromoPattern2 = styled(Circle2Pattern)`
	position: absolute;
	display: block;
	left: 50%;
	bottom: 0;
	max-width: 100%;
	height: 100%;
	transform: translateX(-50%);
	fill: ${(props) => getPatternFill(props.theme)};

	@media (max-width: ${deviceSmallMax}) {
		height: 80%;
		max-width: 100%;
	}
`;

export const Picture = styled.picture`
	height: 100%;
`;

export const PromoImage = styled.img`
	object-fit: cover;
	font-family: 'object-fit: cover;';
	position: absolute;
	display: block;
	left: 50%;
	bottom: 0;
	max-height: 120%;
	min-height: 110%;
	max-width: 100%;
	transform: translateX(-50%);
	@media (max-width: ${deviceLargeMax}) {
		max-height: 100%;
		min-height: 100%;
	}
	@media (max-width: ${deviceSmallMax}) {
		max-height: 100%;
		min-height: 100%;
	}
`;

export const PromoContent = styled.div`
	padding-top: ${(props) => (props.outside ? '5.1rem' : 0)};
	padding-left: ${(props) => (props.contentLeft ? 0 : spacing2x)};
	padding-right: ${(props) => (props.contentLeft ? spacing2x : 0)};

	padding-bottom: 0;

	width: 50%;
	display: ${(props) =>
		props.videoPreference === 'videoLeft' ||
		props.videoPreference === 'videoRight'
			? 'flex'
			: 'inline-block'};
	flex-direction: column;
	align-self: center;
	color: ${(props) => theme[props.theme]?.color};
	@media (max-width: ${deviceLargeMax}) {
		padding: ${(props) =>
			props.outside
				? `5.1rem 0 ${spacing2x} ${spacing2x}`
				: `${spacing2x} 0 ${spacing2x} ${spacing3x} `};
	}
	@media (max-width: 981px) {
		width: 100%;
		padding: ${spacing2x};
	}
`;
export const PromoFullWidthContent = styled.div`
	position: absolute;
	width: 58rem;
	padding: ${spacing3x};
	right: 0;
	bottom: ${spacing8x};
	background-color: ${(props) =>
		!props.hasImage && theme[props.theme]?.background};
	display: inline-block;
	@media (max-width: ${deviceLargeMax}) {
		right: ${spacing2x};
		color: inherit;
	}
	@media (max-width: ${deviceSmallMax}) {
		width: 100%;
		position: static;
		padding-bottom: ${spacing2x};
		color: ${(props) => !props.hasImage && theme[props.theme]?.color};
		background-color: ${(props) =>
			!props.hasImage && theme[props.theme]?.background};
		color: ${(props) =>
			props.theme !== 'blue' ? theme[props.theme]?.color : 'white'};
	}
`;

export const PromoBlockContent = (props) => {
	return props.fullWidth ? (
		<PromoFullWidthContent {...props} />
	) : (
		<PromoContent {...props} />
	);
};

export const ButtonWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`;
export const FullWidthButtonWrapper = styled.div`
	display: flex;
	position: absolute;
	bottom: -3rem;
	@media (max-width: ${deviceSmallMax}) {
		flex-wrap: wrap;
		position: static;
	}
`;

export const PromoButtonWrapper = (props) => {
	return props && props.fullWidth ? (
		<FullWidthButtonWrapper>{props.children}</FullWidthButtonWrapper>
	) : (
		<ButtonWrapper>{props.children}</ButtonWrapper>
	);
};

const baseButtonStyling = css`
	margin: 0 ${spacing2x} ${spacing1x} 0;
	padding: ${spacing1x} ${spacing2x} ${spacing1x} ${spacing1x};
	display: flex;
	align-items: center;
	text-align: left;
	@media (max-width: ${deviceSmallMax}) {
		position: static;
		margin: 0 ${spacing2x} 0 0;
		margin-top: ${spacing1x};
		& > svg {
			margin-right: 0.4rem;
			margin-left: 0.4rem;
		}
	}
	& > svg {
		margin-right: 0.4rem;
		margin-left: 0.4rem;
		min-width: 2rem;
		min-height: 2rem;
	}
`;

const ContentButton = styled(Button)`
	${baseButtonStyling}
	font-size: 1.6rem;
`;

const Link = styled(ContentButton)`
	${baseButtonStyling}
	font-weight: ${fontWeightLight};
	font-size: 1.6rem;
	background-color: transparent;
	border: none;
	cursor: pointer;

	color: ${({ darkBackground }) => (darkBackground ? white : blueDark)};
	:hover {
		background-color: transparent;
		color: ${({ darkBackground }) => (darkBackground ? white : blueDark)};
		text-decoration: underline;
	}
	span {
		pointer-events: none;
	}
`;

export const PromoButton = (props) => {
	return props.styling === 'button' || props.fullWidth ? (
		<ContentButton {...props} />
	) : (
		<Link {...props} />
	);
};

//Typography

export const Heading = styled.div`
	color: ${(props) => theme[props.theme]?.headingColor};
	margin: 0 0 ${spacing1x} 0;
	@media (max-width: ${deviceSmallMax}) {
		color: ${(props) => theme[props.theme]?.headingColor};
		margin: ${spacing05x} 0;
	}
`;
export const FullWidthHeading = styled(Heading)`
	color: ${(props) => theme[props.theme]?.headingColor};

	@media (max-width: ${deviceSmallMax}) {
		color: ${(props) => theme[props.theme]?.headingColor};
	}
`;

export const Text = styled(Paragraph)`
	color: ${(props) => theme[props.theme]?.color};
	font-weight: ${fontWeightLight};
	margin-top: 0;
	margin-bottom: ${spacing3x};

	@media (max-width: ${deviceSmallMax}) {
		font-size: 1.4rem;
	}
`;

export const FullWidthText = styled(Text)`
	color: ${(props) =>
		props.theme === 'blue' && !props.hasImage
			? theme['white']?.color
			: theme[props.theme]?.color};

	@media (max-width: ${deviceSmallMax}) {
		color: ${(props) => theme[props.theme]?.color};
	}
`;

export const ContentHeading = (props) => {
	return props.fullWidth ? (
		<FullWidthHeading {...props} />
	) : (
		<Heading {...props} />
	);
};

export const ContentText = (props) => {
	return props.fullWidth ? <FullWidthText {...props} /> : <Text {...props} />;
};
