/**
 * Select
 * @module components/Select
 */

import React, { useRef, useState, useEffect } from 'react';
import ReactSelect from 'react-select';

import { useFieldValidation } from 'hooks';
import { useFormValue } from 'context/form.context';
import { SelectField, SelectPlaceholderOption } from './Form.styles';
import { FieldChangeModel, FieldGeneralPropsModel } from 'types/common';

/**
 * Formats the select options to work with ReactSelect component
 * @param {Array} options
 */

interface OptionsItemModel {
	text: string;
	value: string;
}

interface SelectModel extends FieldGeneralPropsModel {
	description?: string;
	name: string;
	options: OptionsItemModel[];
	placeholder?: string;
	selectedValue: string;
	defaultValue?: string | [];
	autoSubmit?: boolean;
	onFieldChange?: ({}: FieldChangeModel) => void;
	onChange: ({}: any, valid: boolean) => void;
	showError?: boolean;
	visibleFields?: any;
	className?: string;
	helpText?: string;
}

const formatMultiselectOptions = (options: OptionsItemModel[]) => {
	return options.map((option) => {
		return {
			value: option.value,
			label: option.text,
		};
	});
};

const Select = (props: SelectModel) => {
	const {
		id,
		name,
		options,
		multiple,
		defaultValue,
		description,
		placeholder,
		onFieldChange,
		autoSubmit,
		showError,
		validationMessage,
		label,
		visibleFields,
		className,
		helpText,
		selectedValue,
		required,
		onChange,
		...allProps
	} = props;

	const [{ invalidFields }, dispatch] = useFormValue();
	const [value, setValue] = useState<any>(defaultValue || selectedValue || '');

	const [valid, setValid] = useState(false);
	const [touched, setTouched] = useState(false);
	const fieldRef = useRef<HTMLSelectElement>(null);
	const fieldNode = multiple ? null : fieldRef;

	const [validateField, showFieldError, hideFieldError] = useFieldValidation(
		fieldNode,
		props
	);

	useEffect(() => {
		if (
			selectedValue !== null &&
			selectedValue !== '' &&
			typeof selectedValue !== 'undefined' &&
			selectedValue !== value
		) {
			setValue(selectedValue);
			onFieldChange &&
				onFieldChange({
					name,
					value: selectedValue,
					type: 'Select',
					autoSubmit: autoSubmit ? autoSubmit : false,
				});
		}
		//eslint-disable-next-line
	}, [selectedValue]);

	useEffect(() => {
		let validatedField = null;
		const message = '';
		if (multiple) {
			validatedField = { id, label, message };
			if (value.length === 0) {
				validatedField.message =
					validationMessage ||
					'Du måste fylla i det här fältet för att gå vidare.';
			}
		} else {
			validatedField = validateField(value);
		}

		if (validatedField && validatedField.message) {
			setValid(false);
			dispatch({ type: 'FIELD_INVALID', field: validatedField });
		} else {
			setValid(true);

			if (validatedField) {
				if (invalidFields.includes(validatedField?.id)) {
					dispatch({ type: 'FIELD_VALID', field: validatedField });
				}
			}
		}

		if (touched && !valid) {
			showFieldError(
				validatedField as any,
				multiple ? 'multiselect' : undefined
			);
		} else if (
			(showError && !valid && visibleFields && visibleFields.includes(id)) ||
			(showError && !valid && !visibleFields)
		) {
			setTouched(true);
			showFieldError(
				validatedField as any,
				multiple ? 'multiselect' : undefined
			);
		} else {
			hideFieldError(
				validatedField as any,
				multiple ? 'multiselect' : undefined
			);
		}
	}, [
		valid,
		dispatch,
		touched,
		showError,
		setValid,
		value,
		validateField,
		showFieldError,
		hideFieldError,
		invalidFields,
		id,
		label,
		validationMessage,
		multiple,
		visibleFields,
		className,
	]);

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		if (multiple) {
			setValue(e);
			onFieldChange &&
				onFieldChange({
					name,
					value,
					type: 'Select',
					autoSubmit: autoSubmit ? autoSubmit : false,
				});
		} else {
			const selectedOption = e.target.options[e.target.selectedIndex];
			setValue(selectedOption.value || selectedOption.text);
			onFieldChange &&
				onFieldChange({
					name,
					value: selectedOption.value || selectedOption.text,
					type: 'Select',
					autoSubmit: autoSubmit ? autoSubmit : false,
				});

			if (onChange) {
				onChange(
					{ [name]: selectedOption.value || selectedOption.text },
					valid
				);
			}
		}
	};

	if (multiple) {
		const formattedOptions = formatMultiselectOptions(options);
		const defaultValues = (defaultValue as []).map((option: string) => {
			return formattedOptions.filter((opt) => opt.value === option)[0];
		});

		return (
			//@ts-ignore
			<ReactSelect
				className={`form__item--Multiple${className ? ` ${className}` : ''}`}
				classNamePrefix="select"
				defaultValue={defaultValues}
				options={formattedOptions}
				isMulti
				name={name}
				placeholder={placeholder || 'Välj ett alternativ'}
				noOptionsMessage={() => 'Inga alternativ'}
				loadingMessage="Laddar"
				ref={fieldRef}
				onChange={handleChange}
				aria-describedby={`form${id}__desc`}
				aria-invalid={!valid}
			/>
		);
	}

	return (
		<SelectField
			id={id}
			name={name}
			title={description}
			aria-invalid={!valid}
			//@ts-ignore
			multiple={multiple}
			value={value}
			placeholder={placeholder}
			defaultValue={defaultValue}
			onChange={handleChange}
			onBlur={() => setTouched(true)}
			ref={fieldRef}
			aria-describedby={`form${id}__desc`}
			className={className}
			required={required}
			{...allProps}
		>
			{placeholder && (
				<SelectPlaceholderOption value="" disabled hidden>
					{placeholder}
				</SelectPlaceholderOption>
			)}
			{options.map(({ value, text }) => {
				return (
					<option value={value} key={value}>
						{text}
					</option>
				);
			})}
		</SelectField>
	);
};

export default Select;
