/**
 * Form
 * @module components/Form
 * @version 1.0
 */

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import 'formdata-polyfill';
import { useFormValue } from 'context/form.context';
import { useChildClones } from 'hooks';

/**
 * Generates a unique key (used by the reset-method).
 */
const getUniqueKey = () =>
	'_' +
	Math.random()
		.toString(36)
		.substr(2, 9);

const Form = props => {
	const {
		children,
		method,
		action,
		enctype,
		initialValues,
		className,
		id,
		onChange,
		onSubmit,
		sendDataType,
		defaultSubmit,
		multiStep,
		getCurrentStep,
	} = props;
	const [formKey, setFormKey] = useState(getUniqueKey());
	const [showError, setShowError] = useState(false);
	const [visibleFields, setVisibleFields] = useState(false);
	const formRef = useRef(null);

	let [
		{ values, invalidFields, validationMessages },
		dispatch,
	] = useFormValue();
	const [renderChildren, formFieldNameCounter] = useChildClones(
		showError,
		visibleFields
	);

	const resetForm = () => setFormKey(getUniqueKey());

	const onFieldChange = ({ name, value, type, autoSubmit, checked }) => {
		const valueIsArray =
			values[name] && Array.isArray(values[name]) && checked !== undefined;

		let newValue = value;

		// Handle checkboxes when they have array values
		if (type === 'Checkbox') {
			if (valueIsArray) {
				newValue = checked
					? [...values[name], value]
					: values[name].filter(item => item !== value);
			} else if (formFieldNameCounter[name] > 1 && checked !== undefined) {
				newValue = [value];
			} else if (checked === false) {
				newValue = '';
			}
		}

		dispatch({ type: 'FORM_UPDATE', values: { ...values, [name]: newValue } });

		if (autoSubmit) {
			onSubmit();
		}
		if (onChange) {
			onChange({ name, value: newValue }, { ...values, [name]: newValue });
		}
	};

	const handleOnSubmit = e => {
		if (sendDataType === 'formdata' && typeof FormData === 'undefined') {
			return true;
		}

		if (e && !defaultSubmit) {
			e.preventDefault();
		}

		let index = null;
		let messages = { ...validationMessages };
		let updatedInvalidFields = invalidFields;

		if (multiStep) {
			const [formData, stepIndex, currentStepFields] = getCurrentStep();
			setVisibleFields(currentStepFields);

			values = formData;
			index = stepIndex;
			updatedInvalidFields = invalidFields.filter(fieldId =>
				currentStepFields.some(field => field.indexOf(fieldId) >= 0)
			);

			messages = Object.keys(validationMessages)
				.filter(key => currentStepFields.some(field => field.indexOf(key) >= 0))
				.reduce((obj, key) => {
					return {
						...obj,
						[key]: validationMessages[key],
					};
				}, {});
		} else if (sendDataType === 'formdata') {
			values = new FormData(formRef.current);
		}

		if (updatedInvalidFields.length > 0) {
			setShowError(true);
		}

		if (onSubmit) {
			onSubmit(values, index, messages, updatedInvalidFields);
		}
	};

	return (
		<form
			key={formKey}
			className={className}
			method={method}
			action={action}
			encType={enctype}
			onSubmit={handleOnSubmit}
			ref={formRef}
			id={id}
			noValidate
		>
			{renderChildren(
				children,
				initialValues,
				resetForm,
				onFieldChange,
				formRef
			)}
		</form>
	);
};

Form.propTypes = {
	initialValues: PropTypes.object,
	method: PropTypes.string,
	action: PropTypes.string,
	enctype: PropTypes.string,
	sendDataType: PropTypes.string,
	onSubmit: PropTypes.func,
	onChange: PropTypes.func,
	defaultSubmit: PropTypes.bool,
};

Form.defaultProps = {
	method: 'post',
	action: '',
	enctype: 'application/x-www-form-urlencoded',
};

export default Form;
