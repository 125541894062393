/**
 * PromoBlock
 * @module components/PromoBlock
 */

import React from 'react';
import { Picture } from './PromoBlock.styles';
import PromoContent from './PromoContent';
import { PromoBlockModel } from 'types/block-types';
import clsx from 'clsx';

const PromoFullWidthImage = (props: PromoBlockModel) => {
	const { media, backgroundColor } = props;
	const theme = backgroundColor;
	const fullHeight =
		media?.imagePreference &&
		media.imagePreference === 'fullWidthBackgroundImageFullHeight';

	const hasImage =
		media?.image &&
		media.image.src &&
		media.image.sizes &&
		media.image.sizes.length > 0 &&
		media.image.webpSizes &&
		media.image.webpSizes.length > 0;

	return (
		<section
			className={clsx(
				'max-w-360 my-0 mx-auto relative',
				theme ? `bg-${theme}` : 'bg-white'
			)}
		>
			{hasImage && media?.image?.webpSizes && media.image.sizes && (
				<a href={media?.imageLink} target={media?.imageLinkTarget}>
					<Picture
						onClick={props.toggleOnImage ? props.toggleImageGallery : undefined}
					>
						<source
							type="image/webp"
							srcSet={`${media.image.webpSizes[0]} 1600w,${media.image.webpSizes[1]} 1200w, ${media.image.webpSizes[2]} 800w, ${media.image.webpSizes[3]} 400w`}
							sizes="(max-width: 768px) 80vw, (max-width: 768px) 60vw, 80vw"
						/>
						<img
							className={clsx(
								'image__object-fit block w-full',
								fullHeight ? 'max-h-auto' : 'max-h-113'
							)}
							src={media.image.src}
							srcSet={`${media.image.sizes[0]} 1600w,${media.image.sizes[1]} 1200w, ${media.image.sizes[2]} 800w, ${media.image.sizes[3]} 400w`}
							sizes="(max-width: 768px) 80vw, (max-width: 768px) 60vw, 80vw"
							alt={media.image.alt}
						/>
					</Picture>
				</a>
			)}
			<div
				className={clsx(
					'max-w-297.5 my-0 mx-auto relative',
					!hasImage && 'h-90'
				)}
			>
				<PromoContent
					fullWidth={true}
					theme={theme || 'transparent'}
					{...props}
				/>
			</div>
		</section>
	);
};

export default PromoFullWidthImage;
