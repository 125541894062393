/**
 * LocationRow
 * @module components/LocationRow
 */

import { useContext } from 'react';
import { LocalizationContext } from 'context/localization.context';
import Tooltip from 'components/ui/Tooltip';

import {
	AddressRichText,
	Body,
	CategoryWrapper,
	ContactText,
	ContentWrapper,
	Image,
	Img,
	LinkWrapper,
	TopContent,
	LeftContent,
	RightContent,
	BottomContent,
	OpeningHoursRichText,
	RowWrapper,
	InfoRow,
	Text,
	Heading,
	PhoneIcon,
	MailIcon,
	GlobeIcon,
	LargerContainer,
	SmallerContainer,
} from './LocationRow.styles';
import Contacts from './Contacts/Contacts';
import { useMedia } from 'hooks';
import Milkrun from './Milkrun/Milkrun';
import { LocationRowModel } from 'types/common';
import Icon from 'components/ui/Icon';

const LocationRow = ({
	categories,
	city,
	contacts,
	email,
	emailTooltip,
	email2,
	email2Tooltip,
	mapImageUrl,
	mapLinkUrl,
	openingHours,
	phone,
	phoneTooltip,
	phone2,
	phone2Tooltip,
	phone3,
	phone3Tooltip,
	streetAddress,
	title,
	trackClick,
	website,
	websiteTooltip,
	query,
	milkRun,
	hitId,
	trackId,
	...restProps
}: LocationRowModel) => {
	const { t }: any = useContext(LocalizationContext);
	const isMobile = useMedia('(max-width: 767px)');
	return (
		<RowWrapper>
			<InfoRow>
				{!isMobile && (
					<Image as="a" href={mapLinkUrl || '#'} target="_blank">
						<Img src={mapImageUrl} alt={t('contactpage/mapalt')} />
					</Image>
				)}
				<Body className="body">
					<TopContent>
						{isMobile && (
							<Image>
								<Img src={mapImageUrl} alt={t('contactpage/mapalt')} />
							</Image>
						)}
						<ContentWrapper>
							<LinkWrapper
								href={mapLinkUrl}
								target="_blank"
								rel="noreferrer noopener"
								onClick={(e) => {
									trackClick && hitId && trackId && trackClick(hitId, trackId);
								}}
							>
								{categories && (
									<CategoryWrapper>
										{city && (
											//@ts-ignore
											<Text as="span" bold="true">
												{city}
											</Text>
										)}
										{categories.city && (
											//@ts-ignore
											<Text as="span" bold="true">
												{categories.city}
											</Text>
										)}
									</CategoryWrapper>
								)}
								{title && <Heading>{title}</Heading>}
							</LinkWrapper>
							{isMobile && streetAddress && (
								<AddressRichText text={streetAddress} />
							)}
						</ContentWrapper>
					</TopContent>
					<LeftContent>
						{!isMobile && streetAddress && (
							<AddressRichText text={streetAddress} />
						)}
					</LeftContent>
					<RightContent>
						<LargerContainer>
							{phone && (
								<ContactText>
									<PhoneIcon aria-hidden />
									<a href={`tel:${phone}`}>{phone}</a>
									{phoneTooltip && (
										<Tooltip hover={true}>{phoneTooltip}</Tooltip>
									)}
								</ContactText>
							)}
							{phone2 && (
								<ContactText>
									<Icon
										className="mr-2 mt-0.5"
										fillCurrent={false}
										icon="shoppingCart"
									/>
									<a href={`tel:${phone2}`}>{phone2}</a>
									{phone2Tooltip && (
										<Tooltip hover={true}>{phone2Tooltip}</Tooltip>
									)}
								</ContactText>
							)}
							{phone3 && (
								<ContactText>
									<Icon
										className="mr-2 mt-0.5"
										fillCurrent={false}
										icon="truck"
									/>
									<a href={`tel:${phone3}`}>{phone3}</a>
									{phone3Tooltip && (
										<Tooltip hover={true}>{phone3Tooltip}</Tooltip>
									)}
								</ContactText>
							)}
							{email && (
								<ContactText>
									<MailIcon aria-hidden />
									<a href={`mailto:${email}`}>{email}</a>
									{emailTooltip && (
										<Tooltip hover={true}>{emailTooltip}</Tooltip>
									)}
								</ContactText>
							)}
							{email2 && (
								<ContactText>
									<MailIcon aria-hidden />
									<a href={`mailto:${email2}`}>{email2}</a>
									{email2Tooltip && (
										<Tooltip hover={true}>{email2Tooltip}</Tooltip>
									)}
								</ContactText>
							)}
							{website && (
								<ContactText>
									<GlobeIcon aria-hidden />
									<a href={website} target="_blank" rel="noreferrer noopener">
										{website}
									</a>
									{websiteTooltip && (
										<Tooltip hover={true}>{websiteTooltip}</Tooltip>
									)}
								</ContactText>
							)}
						</LargerContainer>
						<SmallerContainer>
							<OpeningHoursRichText text={openingHours} />
						</SmallerContainer>
					</RightContent>
				</Body>
			</InfoRow>
			<BottomContent>
				{contacts && contacts.length > 0 && (
					<Contacts contacts={contacts} query={query} />
				)}
				{milkRun && <Milkrun milkRun={milkRun} />}
			</BottomContent>
		</RowWrapper>
	);
};

export default LocationRow;
