import styled from 'styled-components';
import Layout from 'components/ui/Layout';
import {
	spacing1x,
	spacing2x,
	spacing3x,
	spacing6x,
	spacing7x,
} from 'theme/spacings';
import {
	deviceSmallMax,
	deviceMediumMin,
	deviceMediumLargeMin,
	deviceMediumLargeMax,
} from 'theme/mediaQueries';
import { white, blue } from 'theme/colors';
import { fontWeightSemiBold } from 'theme/typography';

export const Heading = styled.h1`
	border: 0;
	clip: rect(0 0 0 0);
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.1rem;
`;

export const Header = styled.header`
	max-width: 144rem;
	margin: 0 auto;
	> div {
		overflow: visible;
	}
	@media (min-width: ${deviceMediumMin}) {
		box-shadow: 0 0 8px 0 rgba(42, 42, 42, 0.2), 0 0 8px 0 rgba(42, 42, 42, 0.2);
	}
`;

export const HeaderLayout = styled(Layout)`
	@media (max-width: ${deviceSmallMax}) {
		border-bottom: 0.2rem solid ${blue};
	}
`;

export const LogoLink = styled.a`
	margin-right: ${spacing2x};
	@media (max-width: ${deviceSmallMax}) {
		padding-top: 0;
	}
`;

export const LogoImg = styled.img`
	max-width: inherit;
	@media (max-width: ${deviceSmallMax}) {
		height: 3.2rem;
		width: 9.8rem;
	}
`;

export const TopBar = styled.div`
	display: flex;
	position: relative;
	justify-content: space-between;
	padding: ${spacing3x} 0;

	@media (max-width: ${deviceSmallMax}) {
		padding: ${spacing2x} 0 ${spacing1x} 0;
	}
`;

export const LeftWrapper = styled.div`
	display: flex;
	width: 40%;
	align-items: center;
	@media (max-width: ${deviceSmallMax}) {
		width: 100%;
		flex-wrap: wrap;
		position: relative;
	}
`;

export const RightWrapper = styled.div`
	align-items: center;

	> div {
		margin-left: ${spacing3x};
	}
`;

export const MenuWrapper = styled.div`
	position: relative;
	background-color: ${blue};

	justify-content: space-between;
	max-width: 119rem;
	margin: 0 auto;
`;

export const LinkItem = styled.a`
	text-decoration: none;
	display: inline-block;
	padding: ${spacing3x} ${spacing2x};
	color: ${white};
	font-weight: ${fontWeightSemiBold};
	text-transform: uppercase;
	font-size: 2rem;
	margin-right: ${spacing6x};
	position: relative;
	margin-left: auto;

	@media (max-width: 1190px) {
		margin-right: ${spacing7x};
	}

	:hover {
		text-decoration: underline;
	}
`;

export const CartWrapper = styled.div`
	position: absolute;
	right: 0;
	top: 2rem;

	@media (max-width: 1190px) {
		right: 2rem;
	}

	@media screen and (min-width: ${deviceMediumLargeMin}) and (max-width: ${deviceMediumLargeMax}) {
		top: 2.1rem;
	}
`;

export const CartWrapperMobile = styled.div`
	position: absolute;
`;

export const MobileMenuWrapper = styled.div`
	position: absolute;
`;

export const MobileMegaMenuWrapper = styled.div`
	padding-top: ${({ noSearch, expanded }) =>
		expanded && !noSearch ? spacing1x : '0'};
	background-color: ${white};
	position: absolute;
	z-index: 200;
	left: 50%;
	transform: translateX(-50%);
	top: ${({ noSearch }) => (noSearch ? '4.7rem' : '7.8rem')};

	@media (max-width: 1150px) {
		top: ${({ noSearch }) => (noSearch ? '9.8rem' : '7.8rem')};
	}
	@media (max-width: 768px) {
		top: ${({ noSearch }) => (noSearch ? '4.7rem' : '7.8rem')};
	}
	height: ${(props) => (props.expanded ? '100vh' : '0')};
	min-height: ${(props) => (props.expanded ? '100vh' : '0')};
	overflow: ${(props) => (props.expanded ? 'scroll' : 'hidden')};
`;
export const MobileMenuOptionsWrapper = styled.div`
	padding: ${spacing2x};
	padding-top: ${spacing3x};
	margin-bottom: 10rem;
`;

export const MegaMenuLayout = styled.div`
	background-color: ${blue};
	position: relative;
	width: 100%;
`;

export const MyPagesLinkWrapper = styled.div`
	position: relative;
`;

export const TickerWrapperMobile = styled.div`
	@media (min-width: 900px) {
		display: none;
	}
	@media (min-width: ${deviceMediumMin}) and (max-width: 899px) {
		margin-top: -2.6rem;
	}
`;

export const TickerWrapperDesktop = styled.div`
	@media (min-width: 1151px) {
		margin-right: -16rem;
	}
	@media (min-width: 900px) and (max-width: 1150px) {
		margin-right: 8rem;
	}
	@media (max-width: 900px) {
		display: none;
	}
`;
