import styled, { css } from 'styled-components';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { fontWeightBold } from 'theme/typography';
import { black, grey, greyDark, orangeLight } from 'theme/colors';
import { spacing05x } from 'theme/spacings';

const PagerItemCss = css`
	display: inline-block;
	height: 4.2rem;
	width: 4.2rem;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 10%;
	text-decoration: none;
	font-size: 1.4rem;
	font-weight: ${fontWeightBold};
	color: ${black};
`;

const IconCss = css`
	color: ${black};
	background-color: ${grey};
	font-size: 1.8rem;
`;

export const PagerList = styled.ol`
	list-style: none;
	display: flex;
	padding: 0;
	margin: 0;
	position: relative;
	justify-content: center;
	@media (max-width: (width: 42rem)) {
		justify-content: space-evenly;
	}
`;

export const InactiveItemCss = css`
	display: inline-block;
	margin: 0 ${spacing05x};
	position: ${({ btn }) =>
		btn === 'prev' || btn === 'next' ? 'absolute' : false};
	left: ${({ btn }) => (btn === 'prev' ? '0' : false)};
	right: ${({ btn }) => (btn === 'next' ? '0' : false)};
	@media (max-width: 49rem) {
		position: static;
	}
	@media (max-width: 33rem) {
		/* display: ${({ btn }) =>
			btn === 'prev' || btn === 'next' ? 'inline-block' : 'none'}; */
	}
`;

export const InactiveItem = styled.li`
	${InactiveItemCss}
`;

export const PreviousPageIcon = styled(FiChevronLeft)`
	${IconCss}
`;

export const NextPageIcon = styled(FiChevronRight)`
	${IconCss}
`;

export const InactiveItemLink = styled.a`
	${PagerItemCss}
    background-color: ${grey};

	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}

	&:hover {
		background-color: ${({ btn }) =>
			btn === 'prev' || btn === 'next' ? greyDark : false};
	}

	&:hover ${PreviousPageIcon}, &:hover ${NextPageIcon}{
		background-color: ${greyDark};
	}
`;

export const ActiveItem = styled.li`
	display: inline-block;
	pointer-events: none;
	margin: 0 ${spacing05x};
`;

export const ActiveItemLink = styled.a`
    ${PagerItemCss}
    border: 0.25rem solid ${orangeLight};
	pointer-events: none;

`;

export const EmptyItem = styled.li`
	${InactiveItem}
    ${PagerItemCss}
    margin: 0 ${spacing05x};
`;
