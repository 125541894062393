import 'styles/fonts.scss';

export const fontFamily = 'raleway, sans-serif';

export const fontWeightSlim = 200;
export const fontWeightLight = 300;
export const fontWeightNormal = 400;
export const fontWeightMedium = 500;
export const fontWeightSemiBold = 600;
export const fontWeightBold = 700;
export const fontWeightVeryBold = 700;
export const fontWeightExtraBold = 800;
