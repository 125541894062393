import styled from 'styled-components';
import { Paragraph } from 'components/ui/Typography';
import { fontWeightBold, fontWeightNormal } from 'theme/typography';
import { grey, blue } from 'theme/colors';
import { spacing05x, spacing1x, spacing3x, spacing4x } from 'theme/spacings';
import { deviceSmallMax } from 'theme/mediaQueries';

export const Heading = styled(Paragraph)`
	font-weight: ${fontWeightBold};
	text-align: left;
`;

export const SpecificationList = styled(Paragraph)`
	border-collapse: collapse;
	width: 100%
	margin-bottom: ${spacing3x};
`;

export const Row = styled.tr`
	:nth-of-type(odd) {
		background-color: ${grey};
	}
`;

export const Property = styled.td`
	float: left;
	clear: left;
	text-align: left;
	width: 100%;
	padding: ${spacing05x} ${spacing4x} ${spacing05x} ${spacing1x};
	white-space: nowrap;

	:after {
		content: ':';
	}
`;

export const VisuallyHiddenTd = styled.td`
	border: 0;
	clip: rect(0 0 0 0);
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.1rem;
`;

export const Value = styled.td`
	margin: 0;
	width: 100%;
	padding: ${({ colorBlocks }) =>
		colorBlocks ? '0.6rem 3.2rem 0 3.2rem' : '0.4rem 3.2rem'};
	text-align: ${({ priceList }) => (priceList ? 'center' : 'inherit')};

	@media (max-width: ${deviceSmallMax}) {
		text-align: ${({ priceList }) => (priceList ? 'right' : 'inherit')};
		padding: 0.4rem 0.8rem 0.4rem 3.2rem;
	}
`;

export const BlueText = styled(Paragraph)`
	display: inline-block;
	font-size: 2.5rem;
	color: ${blue};
	font-weight: ${fontWeightNormal};
	margin: 0;

	@media (max-width: ${deviceSmallMax}) {
		font-size: 2rem;
	}
`;
