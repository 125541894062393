import React from 'react';
export default ({ className, ...htmlAttributes }: { className?: string }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="228"
		height="169"
		viewBox="0 0 228 169"
		fill="none"
		className={className}
		{...htmlAttributes}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M40 12C40 5.37256 45.3726 0 52 0H216C222.627 0 228 5.37256 228 12V125C228 131.627 222.627 137 216 137H161V157H174C176.209 157 178 158.791 178 161V165C178 167.209 176.209 169 174 169H94C91.791 169 90 167.209 90 165V161C90 158.791 91.791 157 94 157H107V137H72V119H211C215.418 119 219 115.418 219 111V19C219 14.5817 215.418 11 211 11H57C52.5815 11 49 14.5817 49 19V54H40V12Z"
			style={{ opacity: 0.5 }}
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0 73C0 66.3726 5.37258 61 12 61H53C59.6274 61 65 66.3726 65 73V157C65 163.627 59.6274 169 53 169H12C5.37258 169 0 163.627 0 157V73ZM6 78C6 75.7909 7.79086 74 10 74H55C57.2091 74 59 75.7909 59 78V149C59 151.209 57.2091 153 55 153H10C7.79086 153 6 151.209 6 149V78ZM32 164C34.2091 164 36 162.209 36 160C36 157.791 34.2091 156 32 156C29.7909 156 28 157.791 28 160C28 162.209 29.7909 164 32 164Z"
			fill="white"
		/>
	</svg>
);
