/**
 * OrderHistoryPage
 * @module pages/OrderHistoryPage
 */

import { useContext, useMemo, useState, useEffect, useRef } from 'react';
import { LocalizationContext } from 'context/localization.context';
import ContentArea from 'components/framework/ContentArea';
import SectionHeader from 'components/ui/SectionHeader';
import Layout from 'components/ui/Layout';
import OrderHistoryFilter from 'components/ui/OrderHistoryFilter';
import Breadcrumbs from 'components/framework/Breadcrumbs';

import Pager from 'components/ui/Pager';
import { useTable, useSortBy, usePagination } from 'react-table';
import {
	handleSortByKeyDown,
	handleKeyboardNavigation,
	formatAndGetCellValue,
} from 'components/ui/Table';

import {
	composeFacetUrlParams,
	getURLParameter,
	scrollToTop,
} from 'common/helpers';
import { useMedia } from 'hooks';
import { useFilterOrders } from 'api/orderhistory';
import { toast } from 'react-toastify';
import ExportDocuments from 'components/ui/ExportDocuments';
import { useDefaultPopState } from 'hooks/usePopState';
import { useSelector } from 'react-redux';
import { selectUserObject } from 'store/modules/user';
import Icon from 'components/ui/Icon';
import { OrderHistoryPageModel } from 'types/page-types';
import { OrderHistoryFilterModel } from 'types/order-history-filter';

const OrderHistoryPage: React.FC<OrderHistoryPageModel> = ({
	breadcrumbs,
	defaultCreatedFrom,
	heading,
	exportLinks,
	text,
	filter,
	orders,
	isInEditMode,
	contentArea,
	framework,
}) => {
	const { t }: any = useContext(LocalizationContext);
	const { customers, projects, warehouses } = useSelector(selectUserObject);
	const isMobile = useMedia('(max-width: 490px)');
	const [orderState, setOrderState] = useState(orders);
	const [filterRequest, setFilterRequest] = useState<any>(null);
	const [query, setQuery] = useState('');
	const currentPage = parseInt(getURLParameter('page') || '1');

	const didRender = useRef(false);
	const preventHistoryEntry = useRef(false);

	const [response, error, loading]: any = useFilterOrders(filterRequest ?? '');

	useEffect(() => {
		if (response && response.orders) {
			setOrderState(response.orders);
		} else if (error) {
			toast(t('shared/toast/servererror'), {
				type: toast.TYPE.ERROR,
				autoClose: 2500,
			});
		}
		setFilterRequest(null);
		//eslint-disable-next-line
	}, [response, error]);

	const data = useMemo(() => orderState, [orderState]);
	const columns = useMemo(
		() => [
			{ Header: t('orderhistorypage/order'), accessor: 'externalId' },
			{ Header: t('shared/order/orderdate'), accessor: 'date' },
			{ Header: t('shared/order/status'), accessor: 'status' },
			{
				Header: t('shared/order/goodsreceiver'),
				accessor: 'goodsReceiverName',
			},
			{ Header: t('orderhistorypage/project'), accessor: 'projectId' },
			{ Header: t('shared/order/projectname'), accessor: 'projectName' },
			{
				Header: t('shared/order/customerrequisition'),
				accessor: 'customerrequisition',
			},
		],
		//eslint-disable-next-line
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		pageOptions,
		gotoPage,
		state: { pageIndex },
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: !isNaN(currentPage) ? currentPage - 1 : 0,
				pageSize: 25,
				sortBy: [
					{
						id: 'date',
						desc: true,
					},
				],
			},
		},
		useSortBy,
		usePagination
	);

	const composeQuery = (
		filterState: OrderHistoryFilterModel,
		currentPageIndex: number
	) => {
		let query = '';
		if (filterState) {
			Object.keys(filterState).forEach((key) => {
				if (key === 'facets') {
					const facetQuery = composeFacetUrlParams(filterState[key]);
					if (facetQuery) {
						query += `${facetQuery}&`;
					}
				} else if (
					(filterState?.[
						key as keyof OrderHistoryFilterModel
					] as OrderHistoryFilterModel[keyof OrderHistoryFilterModel]) &&
					(filterState?.[
						key as keyof OrderHistoryFilterModel
					] as OrderHistoryFilterModel[keyof OrderHistoryFilterModel]) !== ''
				) {
					query += `${key}=${
						filterState?.[
							key as keyof OrderHistoryFilterModel
						] as OrderHistoryFilterModel[keyof OrderHistoryFilterModel]
					}&`;
				}
			});
		}
		if (currentPageIndex) {
			query += `page=${currentPageIndex}`;
		}
		//remove & at end of string if present;
		return query.replace(/&$/, '');
	};

	const composeAndSetQuery = (filterState: any) => {
		const query = composeQuery(filterState, pageIndex + 1);
		setQuery(query);
		setFilterRequest({ url: window.location.pathname, query: query });
	};

	const handleOnFilter = (filterState: any) => {
		composeAndSetQuery(filterState);
		gotoPage(0);
	};

	const resetFilter = () => {
		composeAndSetQuery({ createdFrom: defaultCreatedFrom });
	};

	const [filters, setFilters] = useState(filter);

	useEffect(() => {
		if (didRender.current) {
			handleOnFilter(filters);
		}
		//eslint-disable-next-line
	}, [customers, projects, warehouses]);

	//const overflowItem = response.overflow || overflow;

	const exportQuery = query || composeQuery(filters, pageIndex + 1);

	const popState: any = useDefaultPopState(filters);
	const popStateString = JSON.stringify(popState);
	useEffect(() => {
		if (popState) {
			preventHistoryEntry.current = true;
			composeAndSetQuery(popState.filters);
			if (popState.page && popState.page >= 1) {
				gotoPage(popState.page - 1);
			}
		}
		//eslint-disable-next-line
	}, [popStateString]);

	const handleOrderHistoryUrlLocation = (
		currentQuery: string,
		filters: any,
		pageIndex: number
	) => {
		const hasRendered = didRender.current;
		if (hasRendered && !preventHistoryEntry.current) {
			window.history.pushState(
				{
					data: currentQuery,
					filters: filters,
					page: pageIndex,
				},
				'',
				window.location.pathname + '?' + currentQuery
			);
		} else {
			window.history.replaceState(
				{
					data: currentQuery,
					filters: filters,
					page: pageIndex,
				},
				'',
				window.location.pathname + '?' + currentQuery
			);
		}
		if (!didRender.current) {
			didRender.current = true;
		}
		if (preventHistoryEntry.current) {
			didRender.current = false;
		}
	};

	//Set query to url on filterchange
	useEffect(() => {
		let currentQuery = '';
		if (response && response.filter) {
			currentQuery = composeQuery(response.filter, pageIndex + 1);
			// localStorage &&
			// 	localStorage.setItem(
			// 		'orderhistoryfilter',
			// 		JSON.stringify(response.filter)
			// 	);
			setFilters(response.filter);
		} else {
			currentQuery = composeQuery(filters, pageIndex + 1);
		}
		if (currentQuery !== query) {
			handleOrderHistoryUrlLocation(
				currentQuery,
				response.filter,
				pageIndex + 1
			);
			setQuery(currentQuery);
		}
		//eslint-disable-next-line
	}, [response, pageIndex]);

	useEffect(() => {
		const currentQuery = composeQuery(filters, pageIndex + 1);
		handleOrderHistoryUrlLocation(currentQuery, response.filter, pageIndex + 1);
		//eslint-disable-next-line
	}, [pageIndex, filters]);

	return (
		<main id="main-content">
			{breadcrumbs && (
				<Layout backgroundColor="white">
					<Breadcrumbs links={breadcrumbs} />
				</Layout>
			)}
			<SectionHeader
				heading={heading}
				text={text}
				size={'large'}
				isInEditMode={isInEditMode}
			/>
			{/* <Layout> // Not currently in use.
				<OverflowMessage overflow={overflowItem} />
			</Layout> */}
			<Layout backgroundColor="white">
				<div className="flex flex-col mb-6">
					<div className="bg-white w-full mb-6">
						<OrderHistoryFilter
							filter={filters}
							onFilter={handleOnFilter}
							onReset={resetFilter}
							loading={loading}
							activeMarket={framework.header.activeMarket}
							language={framework.language}
						/>
						{exportLinks && exportLinks?.length > 0 && (
							<ExportDocuments documents={exportLinks} query={exportQuery} />
						)}
					</div>
					<div className="w-full">
						<div className="max-w-full pb-6 overflow-y-auto">
							<table
								className="border-spacing-0 w-full"
								{...getTableProps({ role: 'grid' })}
							>
								<caption className="sr-only">{heading}</caption>
								<thead className="bg-blue">
									{headerGroups.map((headerGroup) => (
										<tr
											className="text-white text-2xl p-1"
											{...headerGroup.getHeaderGroupProps()}
										>
											{headerGroup.headers.map((column, i) => (
												<th
													className="px-4 py-2 text-2xl font-normal text-left whitespace-no-wrap"
													{...column.getHeaderProps(
														column.getSortByToggleProps({
															title: `${t('shared/table/sortby')} ${
																column.Header
															}`,
															role: 'button',
															tabIndex: 0,
														})
													)}
													aria-sort={
														column.isSorted && !column.isSortedDesc
															? t('shared/table/ascending')
															: t('shared/table/descending')
													}
													data-header-col={i}
													onKeyDown={(e) => {
														if (column.canSort) {
															return handleSortByKeyDown(
																e,
																column,
																i,
																page.length,
																columns.length
															);
														}
													}}
												>
													{column.render('Header')}
													<div className="relative w-2 inline-block ml-1">
														{column.isSorted && column.isSortedDesc && (
															<Icon icon="sortDescending" />
														)}
														{column.isSorted && !column.isSortedDesc && (
															<Icon icon="sortAscending" />
														)}
													</div>
												</th>
											))}
										</tr>
									))}
								</thead>
								{!loading && (
									<tbody {...getTableBodyProps()}>
										{page.map((row, i) => {
											prepareRow(row);
											return (
												<tr
													className="odd:bg-white even:bg-greyLight text-2xl"
													{...row.getRowProps()}
												>
													{row.cells.map((cell, index) => {
														return (
															<td
																className="px-4 py-2 whitespace-no-wrap"
																{...cell.getCellProps({})}
																onKeyDown={(e) => {
																	return handleKeyboardNavigation(
																		e,
																		page.length,
																		columns.length,
																		i
																	);
																}}
																data-row={i}
																data-col={index}
															>
																{formatAndGetCellValue(cell)}
															</td>
														);
													})}
												</tr>
											);
										})}
									</tbody>
								)}
							</table>
							{loading && (
								<div className="my-10 text-center w-full overflow-hidden">
									<Icon icon="loader" animate="spin" size={2} />
								</div>
							)}
						</div>
						{orderState && orderState && orderState.length === 0 && (
							<p className="text-center text-2xl my-4">
								{t('orderhistorypage/noresultmessage')}
							</p>
						)}
						<div className="my-2">
							<Pager
								nrOfPages={pageOptions.length}
								currentPage={pageIndex + 1}
								total={pageOptions.length}
								onPageChange={(value: any) => {
									scrollToTop();
									gotoPage(value - 1);
								}}
								isMobile={isMobile}
							/>
						</div>
					</div>
				</div>
			</Layout>
			<ContentArea contentArea={contentArea} />
		</main>
	);
};

export default OrderHistoryPage;
