import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddToCartModel } from 'types/cart-types';

import { OrderMatrixTabPageModel } from 'types/page-types';
import { OrderMatrixObjectModel } from 'types/matrix-types';
import { AjaxMethodTypes } from 'types/common';
import axios from 'axios';
import { AppThunk, RootState } from 'store';
import { AddToCartApi, ClearCartApi, getApiUrl } from 'store/api/cartApi';
import { GenericWarning, ServerError } from 'common/helpers';

interface OrderMatrixState {
	orderMatrixObject: OrderMatrixObjectModel;
	activeTabObj: OrderMatrixTabPageModel;
	activeTabQuantity: number;
	totalQuantity: number;
	error: string | null;
	loading: boolean;
}

const initialState: OrderMatrixState = {
	orderMatrixObject: {
		cartId: '',
		cartType: 'ordermatrix',
		cartStatus: 'invalid',
		orderMatrixTabPages: [],
		properties: [],
	},
	activeTabObj: {
		heading: '',
		id: '',
		isActive: true,
		quantity: 0,
	},
	totalQuantity: 0,
	activeTabQuantity: 0,
	error: null,
	loading: false,
};

const slice = createSlice({
	name: 'ordermatrix',
	initialState,
	reducers: {
		orderMatrixStart: (state) => {
			state.loading = true;
		},
		orderMatrixSuccess: (
			state,
			action: PayloadAction<OrderMatrixObjectModel>
		) => {
			state.orderMatrixObject = { ...action.payload };

			state.activeTabObj = {
				...action.payload?.orderMatrixTabPages?.filter(
					(tab) => tab.isActive
				)[0],
			};

			state.activeTabQuantity = action.payload?.orderMatrixTabPages?.filter(
				(tab) => tab.isActive
			)[0].quantity;

			state.totalQuantity = action.payload?.orderMatrixTabPages?.reduce(
				(accumulator, currentValue) => accumulator + currentValue.quantity,
				0
			);

			state.loading = false;
			state.error = null;
		},
		orderMatrixUpdateTotalQuanity: (state, action: PayloadAction<number>) => {
			state.totalQuantity = action.payload;
		},
		orderMatrixUpdateActiveTabQuanity: (
			state,
			action: PayloadAction<number>
		) => {
			state.activeTabQuantity = action.payload;
		},
		orderMatrixFailed: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.loading = false;
		},
	},
});

export const UpdateActiveTabQuantity = (
	articleList: { itemCode: string; quantity: number }[]
): AppThunk => (dispatch: any, getState: any) => {
	let activeTabQty: number = 0;
	activeTabQty = articleList.reduce(
		(accumulator, currentValue) => accumulator + currentValue.quantity,
		0
	);
	dispatch(orderMatrixUpdateActiveTabQuanity(activeTabQty));
};

export const UpdateTotalQuantity = (): AppThunk => (
	dispatch: any,
	getState: any
) => {
	const { activeTabQuantity } = getState().ordermatrix;
	const { orderMatrixTabPages } = getState().ordermatrix.orderMatrixObject;

	let totalQuantity = 0;
	orderMatrixTabPages.forEach((tab: OrderMatrixTabPageModel) => {
		if (tab.isActive) {
			totalQuantity += activeTabQuantity;
		} else {
			totalQuantity += tab.quantity;
		}
	});
	dispatch(orderMatrixUpdateTotalQuanity(totalQuantity));
};

export const ChangeTab = (
	apiUrl: string,
	selectedTab?: string
): AppThunk => async (dispatch: any, getState: any) => {
	let data;
	dispatch(orderMatrixStart());

	if (selectedTab) {
		apiUrl = `${apiUrl}?tab=${encodeURIComponent(selectedTab)}`;
	}
	try {
		data = await ChangeTabApi(apiUrl);
		dispatch(orderMatrixSuccess(data));
	} catch (err) {
		console.error(err);
		return;
	}
};

export const ChangeTabApi = async (
	apiUrl: string
): Promise<OrderMatrixObjectModel> => {
	const { data } = await axios({
		method: 'GET',
		url: apiUrl,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
		},
	});
	return data;
};

export const AddToOrderMatrixCart = (
	method: AjaxMethodTypes,
	articleList: AddToCartModel[]
): any => async (dispatch: any, getState: any) => {
	const { cartId, cartType } = getState().ordermatrix.orderMatrixObject;
	let apiUrl = dispatch(
		getApiUrl({
			cartObjectType: cartType,
			cartId,
			article: true,
		})
	);
	try {
		return await AddToCartApi(method, apiUrl, articleList);
	} catch (err) {
		console.error(err);
		return;
	}
};

export const ClearOrderMatrix = (apiUrl: string): AppThunk => async (
	dispatch: any,
	getState: any
) => {
	let data;
	try {
		data = await ClearCartApi(apiUrl);
		dispatch(orderMatrixSuccess(data as OrderMatrixObjectModel));
	} catch (err) {
		console.error(err);
		throw new ServerError();
	}
};

export const AddAllToCart = (apiUrl: string): any => async (
	dispatch: any,
	getState: any
) => {
	let data;
	dispatch(orderMatrixStart());

	try {
		data = await AddToCartApi('POST', apiUrl);
		console.log(data);
		dispatch(orderMatrixSuccess(data as OrderMatrixObjectModel));
	} catch (err: any) {
		const statusCode = err.response.status;
		const errorCode: number = err.response.data.errorCode;
		const errorMessage: string = err.response.data.message;

		console.log(statusCode, errorMessage);

		if (statusCode === 400) {
			if (errorCode === 510) {
				throw new GenericWarning(errorCode, errorMessage);
			}
		} else {
			throw new ServerError();
		}
	}
};

export const selectOrderMatrixData = (state: RootState) => state.ordermatrix;
export const selectOrderMatrixObject = (state: RootState) =>
	state.ordermatrix.orderMatrixObject;

export const {
	orderMatrixStart,
	orderMatrixSuccess,
	orderMatrixUpdateActiveTabQuanity,
	orderMatrixUpdateTotalQuanity,
	orderMatrixFailed,
} = slice.actions;

export default slice.reducer;
