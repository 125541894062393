/**
 * ImageGalleryBlockItem
 * @module components/ImageGalleryBlock
 */

import LazyLoad from 'react-lazyload';
import { getHeadingLevel } from 'common/helpers.js';
import { ImageModel } from 'types';

interface Props {
	heading: string;

	text?: string;

	image?: ImageModel;

	headingLevel?: number;

	onClick?: any;

	itemIndex: number;

	translations: any;
}

const ImageGalleryBlockItem = ({
	heading,
	text,
	image,
	headingLevel = 2,
	onClick = () => {},
	itemIndex,
	translations,
}: Props) => {
	const Heading = getHeadingLevel(headingLevel);

	return (
		<button
			className="h-full overflow-hidden columnblock--shadow group flex"
			onClick={() => onClick(itemIndex)}
			aria-label={translations['shared/mediagallery/openimageinmodal']}
		>
			<LazyLoad>
				<div className="h-full flex min-h-full items-stretch flex-col">
					{image && (
						<div className="relative w-full h-full md:h-60 transition duration-500 ease-in-out transform group-hover:-translate-y-1 group-hover:scale-110">
							<picture>
								{image.webpSizes && (
									<source
										type="image/webp"
										srcSet={`${image.webpSizes[2]} 800w, ${image.webpSizes[3]} 400w`}
										sizes="(min-width: 768px) 60vw, (max-width: 767px) 70vw, 800px"
									/>
								)}
								{image.sizes && (
									<img
										src={image.src}
										srcSet={`${image.sizes[2]} 800w, ${image.sizes[3]} 400w`}
										sizes="(min-width: 768px) 70vw, (max-width: 767px) 30vw, 800px"
										alt={image.alt ? image.alt : ''}
										className="w-full h-full object-center image-objectfit"
									/>
								)}
							</picture>
						</div>
					)}
					<div className="text-left h-full p-4 break-words">
						{heading && (
							<Heading className="text-h4 md:text-h3 mb-2 text-blue font-light">
								{heading}
							</Heading>
						)}
						{text && <p className="font-light text-p">{text}</p>}
					</div>
				</div>
			</LazyLoad>
		</button>
	);
};

export default ImageGalleryBlockItem;
