/**
 * QuickSearch
 * @module components/QuickSearch
 */

import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDebounce, useToggle } from 'hooks';
import { quickSearch } from 'api/quicksearch';
import SearchField from 'components/ui/SearchField';
import QuickSearchResult from './QuickSearchResult';
import { LocalizationContext } from 'context/localization.context';
import ArticleDetail from 'components/common/ArticleDetail';

import {
	QuickSearchWrapper,
	QuickSearchResultContainer,
	QuickSearchSpinner,
	NoResultMessage,
	SmallMessage,
} from './QuickSearch.styles';
import ArticleDetailConfiguration from 'components/common/ArticleDetailConfiguration';
import { confReset } from 'store/modules/configurator';
import { useDispatch } from 'react-redux';

const QuickSearch = (props) => {
	const {
		searchLink,
		quickSearchLink,
		searchPlaceholder,
		activeMarket,
	} = props;
	const { t } = useContext(LocalizationContext);
	const dispatch = useDispatch();

	const [articleModal, setArticleModal] = useState({
		isActive: false,
		link: null,
	});

	const [confModalOpen, setConfModalOpen] = useState(false);
	const [expanded, toggle, ref] = useToggle(false, true);
	const [query, setQuery] = useState('');
	const debouncedQuery = useDebounce(query, 750);
	const [response, setResponse] = useState([]);
	const [loading, setLoading] = useState(false);

	const toggleConfModal = () => {
		setConfModalOpen(false);
		dispatch(confReset());
	};

	const getSuggestions = async () => {
		try {
			setLoading(true);

			const data = await quickSearch(quickSearchLink, debouncedQuery, 5);
			if (data) {
				if (data.cancel) {
					setLoading(true);
				} else {
					setResponse(data);
					setLoading(false);
				}
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	useEffect(() => {
		if (debouncedQuery !== '' && debouncedQuery.length >= 2) {
			getSuggestions();
			!expanded && toggle();
		} else {
			setResponse([]);
		}
		//eslint-disable-next-line
	}, [debouncedQuery]);

	const redirectToSearchResult = (e) => {
		e && e.preventDefault();
		window.location.href = `${searchLink}?query=${query}&display=16`;
	};

	const responseHasResult = response && response.length && response.length > 0;
	return (
		<QuickSearchWrapper ref={ref}>
			<SearchField
				id={'quicksearch'}
				name="quicksearch"
				placeholder={searchPlaceholder}
				onChange={(e) => {
					setQuery(e.target.value);
				}}
				onClear={() => {
					setQuery('');
					toggle();
				}}
				onFocus={() => {
					responseHasResult && toggle();
				}}
				onSubmit={redirectToSearchResult}
				aria-controls="quicksearchresult"
				aria-expanded={expanded}
			/>
			<QuickSearchResultContainer
				expanded={debouncedQuery !== '' && expanded}
				id="quicksearchresult"
				aria-live="polite"
				aria-hidden={!expanded}
			>
				{!loading && !!expanded && !!responseHasResult && (
					<SmallMessage>
						{t('shared/search/pressenterformoreresults')}
					</SmallMessage>
				)}
				{loading ? (
					<QuickSearchSpinner size="small" />
				) : responseHasResult ? (
					response.map((result, key) => {
						return (
							<QuickSearchResult
								{...result}
								key={key}
								openModal={setArticleModal}
								toggleConfModal={() => setConfModalOpen(!confModalOpen)}
							/>
						);
					})
				) : (
					<NoResultMessage>{t('shared/search/noresult')}</NoResultMessage>
				)}
			</QuickSearchResultContainer>
			{confModalOpen && (
				<ArticleDetailConfiguration closeModal={toggleConfModal} />
			)}

			{articleModal.isActive && (
				<ArticleDetail
					onToggle={() => setArticleModal({ link: null, open: false })}
					articleApiUrl={articleModal.link}
					activeMarket={activeMarket}
				/>
			)}
		</QuickSearchWrapper>
	);
};

QuickSearch.propTypes = {
	searchLink: PropTypes.string,
	quickSearchLink: PropTypes.string,
	searchPlaceholder: PropTypes.string,
};

export default QuickSearch;
