import styled from 'styled-components';
import { H3, H4, Paragraph, paragraphCss } from 'components/ui/Typography';

import * as colors from 'theme/colors';
import { deviceSmallMax } from 'theme/mediaQueries';
import { spacing1x, spacing05x } from 'theme/spacings';
import { fontWeightMedium } from 'theme/typography';

export const ImageWrapper = styled.div`
	position: relative;
	/* height is temporary */
	height: 20.8rem;
	@media (max-width: ${deviceSmallMax}) {
		height: 16rem;
	}
`;

interface HeadingModel {
	as: string;
	size: string;
}

export const Heading = styled(H4).attrs((props: HeadingModel) => ({
	as: props.as,
	size: props.size,
}))`
	${H3};
	color: ${colors.blue};
	font-weight: ${fontWeightMedium};
	margin: ${spacing1x} 0 0 0;
`;

export const Description = styled.span`
	${paragraphCss};
	color: ${colors.blue};
`;

export const BlockLink = styled.a`
	display: block;
	text-decoration: none;

	:hover ${Heading} {
		text-decoration: underline;
	}
`;

export const Text = styled(Paragraph)`
	color: ${colors.black};
	margin: ${spacing05x} 0 0 0;
`;

interface LabelModel {
	color: string;
}

export const Label = styled.span.attrs((props: LabelModel) => ({
	color: props.color,
}))`
	text-transform: uppercase;
	background-color: ${({ color }) =>
		color === 'orange' ? colors.orangeLight : (colors as any)[color]};
	color: ${colors.white};
	font-size: 1.3rem;
	padding: ${spacing05x} ${spacing1x};
	bottom: 0.9rem;
	left: -0.5rem;
	position: absolute;
`;

export const Image = styled.img`
	width: 100%;
	/* height is temporary */
	height: 100%;
	object-fit: cover;
	font-family: 'object-fit: cover;';
	position: relative;
	transition: all 2s ease-out;
	border: 0.1rem solid ${colors.greyDark};
`;

export const PublishedDate = styled(Paragraph)`
	margin: ${spacing05x} 0 -${spacing1x} 0;
	color: ${colors.greyDarker};
`;
