import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ArticleDeliveryDatesModel } from 'types/article';
import { AddToCartModel, CartObjectModel } from 'types/cart-types';
import {
	AjaxMethodTypes,
	CheckoutReqestDataModel,
	AddNotesModel,
} from 'types/common';

import { AppThunk, RootState } from 'store';
import {
	CancelCartPriceError,
	CheckoutError,
	GenericWarning,
	getSelectedDeliveryMethod,
	getSelectedWarehouse,
	PunchOutError,
	ReloadCartPriceError,
	SaveCartError,
	SendTableError,
	ServerError,
} from 'common/helpers';
import {
	AddToCartApi,
	FetchCartApi,
	RemoveItemApi,
	ClearCartApi,
	SaveCartApi,
	AddNotesApi,
	DeleteCartApi,
	UpdateCartApi,
	CheckoutApi,
	getApiUrl,
	cancelToken,
	cancelPricingRequest,
	UpdateWithArticleDeliveryDates,
	sendTableDataApi,
} from 'store/api/cartApi';
import Axios from 'axios';

interface CartState {
	cartObject: CartObjectModel;
	apiBaseUrl: string;
	error: string | null;
	loading: boolean;
	priceReloadCounter: number;
}

interface UpdateCartModel {
	selectedSort?: string;
	selectedDeliveryMethod?: string;
	selectedLocation?: string;
	selectedDesiredDate?: string;
	singleShipment?: boolean;
}

const initialState: CartState = {
	cartObject: {
		cartId: '',
		cartType: 'slim',
		cartLink: {
			text: '',
			link: '',
		},
		cartDisabled: false,
		cartHidden: false,
		goodsMarkingHidden: false,
		items: 0,
		cartStatus: 'invalid',
		shipments: [],
		singleShipment: false,
	},
	apiBaseUrl: '',
	error: null,
	loading: false,
	priceReloadCounter: 0,
};

const slice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		cartStart: (state) => {
			state.loading = true;
		},
		cartSuccess: (state, action: PayloadAction<CartObjectModel>) => {
			state.cartObject = { ...state.cartObject, ...action.payload };
			state.loading = false;
			state.priceReloadCounter = 0;
		},
		cartFailed: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.loading = false;
		},
		cartUpdatePriceReloadCounter: (state, action: PayloadAction<number>) => {
			state.priceReloadCounter = action.payload;
		},
	},
});

//---------------------------------- Fetch CartObject --------------------------------------------------

export const FetchCart = (sort?: string): AppThunk => async (
	dispatch: any,
	getState: any
) => {
	const {
		cartId,
		cartType,
		availableDeliveryMethods,
		locations,
		desiredDate,
		withPrice,
		singleShipment,
	} = getState().cart.cartObject;
	const priceReloadCounter = getState().cart.priceReloadCounter;

	cancelPricingRequest();

	dispatch(cartStart());
	const location = getSelectedWarehouse(locations);
	const deliveryMethod = getSelectedDeliveryMethod(availableDeliveryMethods);

	let apiUrl = dispatch(
		getApiUrl({
			cartObjectType: cartType,
			cartId,
			sort,
			deliveryMethod,
			location,
			desiredDate,
			withPrice: !withPrice,
			priceReloadCounter,
			singleShipment,
		})
	);
	try {
		let data = await FetchCartApi(apiUrl, cancelToken);
		await dispatch(cartSuccess(data));
	} catch (err: any) {
		dispatch(cartFailed(err.toString()));
		const statusCode = err.response.status;
		const errorCode: number = err.response.data.errorCode;
		const errorMessage: string = err.response.data.message;

		if (Axios.isCancel(err)) {
			console.log('Request cancelled: ', err.message);
		}
		// Expcted errors
		else if (statusCode === 400) {
			// 700 - Reload prices
			if (errorCode === 700) {
				throw new ReloadCartPriceError(errorCode, errorMessage);
			}
			// 800 - Cancel reload prices
			else if (errorCode === 800) {
				throw new CancelCartPriceError(errorCode, errorMessage);
			} else if (errorCode === 510) {
				throw new GenericWarning(errorCode, errorMessage);
			}
		} else {
			throw new ServerError();
		}
	}
};

//---------------------------------- Fetch SavedCartObject --------------------------------------------------

export const FetchSavedCart = (
	cartId: string,
	withPrice?: boolean,
	sort?: string
): any => async (dispatch: any, getState: any) => {
	const priceReloadCounter = getState().cart.priceReloadCounter;
	dispatch(cartStart());

	let apiUrl = dispatch(
		getApiUrl({
			cartObjectType: 'saved',
			cartId,
			withPrice: !withPrice,
			sort,
			priceReloadCounter,
		})
	);
	try {
		return await FetchCartApi(apiUrl, cancelToken);
	} catch (err: any) {
		const statusCode = err.response.status;
		const errorCode: number = err.response.data.errorCode;
		const errorMessage: string = err.response.data.message;
		if (Axios.isCancel(err)) {
			console.log('Request cancelled: ', err.message);
		}
		// Expcted errors
		else if (statusCode === 400) {
			// 700 - Reload prices
			if (errorCode === 700) {
				throw new ReloadCartPriceError(errorCode, errorMessage);
			}
			// 800 - Cancel reload prices
			else if (errorCode === 800) {
				throw new CancelCartPriceError(errorCode, errorMessage);
			} else if (errorCode === 510) {
				throw new GenericWarning(errorCode, errorMessage);
			}
		} else {
			throw new ServerError();
		}
	}
};

export const sendCartTable = (
	method: AjaxMethodTypes,
	body: string,
	sort?: string
): any => async (dispatch: any, getState: any) => {
	const { cartId } = getState().cart.cartObject;

	let apiUrl = dispatch(
		getApiUrl({
			cartId,
			table: true,
		})
	);
	try {
		let data = await sendTableDataApi(method, apiUrl, body, cancelToken);
		return data;
	} catch (err: any) {
		const statusCode = err.response.status;
		const errorCode: number = err.response.data.errorCode;
		const errorMessage: string = err.response.data.message;

		// Expcted errors
		if (statusCode === 400) {
			// Quick import articles - Transfer warning
			if (errorCode === 500) {
				throw new SendTableError(errorCode, errorMessage);
			}
		} else {
			throw new ServerError();
		}
	}
};

//---------------------------- add one or more items---------------------------

export const AddToCart = (
	method: AjaxMethodTypes,
	articleList: AddToCartModel[],
	sort?: string
): AppThunk => async (dispatch: any, getState: any) => {
	const {
		cartId,
		cartType,
		availableDeliveryMethods,
		locations,
		desiredDate,
	} = getState().cart.cartObject;

	dispatch(cartStart());
	const location = getSelectedWarehouse(locations);
	const deliveryMethod = getSelectedDeliveryMethod(availableDeliveryMethods);

	if (cartType !== 'slim') {
		cancelPricingRequest();
	}

	let apiUrl = dispatch(
		getApiUrl({
			cartObjectType: cartType,
			cartId,
			article: true,
			sort,
			deliveryMethod,
			location,
			desiredDate,
		})
	);
	try {
		let data = await AddToCartApi(method, apiUrl, articleList, cancelToken);

		dispatch(cartSuccess(data as CartObjectModel));
	} catch (err: any) {
		const statusCode = err.response.status;
		const errorCode: number = err.response.data.errorCode;
		const errorMessage: string = err.response.data.message;

		console.error(err);
		// Expcted errors
		if (statusCode === 400) {
			// Quick import articles - Transfer warning
			if (errorCode === 510) {
				throw new GenericWarning(errorCode, errorMessage);
			}
		} else {
			throw new ServerError();
		}
	}
};

//--------------------------- Add item to Saved cart ---------------------------

export const AddToSavedCart = (
	cartId: string,
	method: AjaxMethodTypes,
	articleList: AddToCartModel[],
	sort?: string
): any => async (dispatch: any, getState: any) => {
	cancelPricingRequest();

	let apiUrl = dispatch(
		getApiUrl({
			cartObjectType: 'saved',
			cartId,
			article: true,
			sort,
		})
	);
	try {
		return await AddToCartApi(method, apiUrl, articleList, cancelToken);
	} catch (err: any) {
		const statusCode = err.response.status;
		const errorCode: number = err.response.data.errorCode;
		const errorMessage: string = err.response.data.message;

		console.error(err);
		// Expcted errors
		if (statusCode === 400) {
			// Quick import articles - Transfer warning
			if (errorCode === 510) {
				throw new GenericWarning(errorCode, errorMessage);
			}
		} else {
			throw new ServerError();
		}
	}
};

// ---------------------------Remove item - add one or more items---------------------------

export const RemoveItem = (
	itemCode: string,
	lineItemId: number,
	sort: string
): AppThunk => async (dispatch: any, getState: any) => {
	const {
		cartType,
		cartId,
		availableDeliveryMethods,
		locations,
		desiredDate,
	} = getState().cart.cartObject;
	const location = getSelectedWarehouse(locations);
	const deliveryMethod = getSelectedDeliveryMethod(availableDeliveryMethods);
	cancelPricingRequest();
	dispatch(cartStart());

	let apiUrl = dispatch(
		getApiUrl({
			cartObjectType: cartType,
			cartId,
			itemCode,
			lineItemId,
			article: true,
			sort,
			deliveryMethod,
			location,
			desiredDate,
		})
	);

	try {
		let data = await RemoveItemApi(apiUrl, cancelToken);
		dispatch(cartSuccess(data));
	} catch (err: any) {
		console.error(err);

		const statusCode = err.response.status;
		const errorCode: number = err.response.data.errorCode;
		const errorMessage: string = err.response.data.message;
		if (statusCode === 400) {
			if (errorCode === 510) {
				throw new GenericWarning(errorCode, errorMessage);
			}
		} else {
			throw new ServerError();
		}
	}
};

// ------------------------------ Clear cart ------------------------------

export const ClearCart = (): AppThunk => async (
	dispatch: any,
	getState: any
) => {
	const { cartType, cartId } = getState().cart.cartObject;

	cancelPricingRequest();

	dispatch(cartStart());

	let apiUrl = dispatch(
		getApiUrl({
			cartObjectType: cartType,
			cartId,
			empty: true,
		})
	);

	try {
		let data = await ClearCartApi(apiUrl, cancelToken);
		dispatch(cartSuccess(data as CartObjectModel));
	} catch (err: any) {
		console.error(err);
		const statusCode = err.response.status;
		const errorCode: number = err.response.data.errorCode;
		const errorMessage: string = err.response.data.message;
		if (statusCode === 400) {
			if (errorCode === 510) {
				throw new GenericWarning(errorCode, errorMessage);
			}
		} else {
			throw new ServerError();
		}
	}
};

// ------------------------------ Save cart ------------------------------

export const SaveCart = (
	name: string,
	overwrite: boolean = false,
	shared: boolean
): AppThunk => async (dispatch: any, getState: any) => {
	const { cartId, cartType } = getState().cart.cartObject;

	cancelPricingRequest();

	let saveCartData = { name, overwrite, shared };
	let apiUrl = dispatch(
		getApiUrl({
			cartObjectType: cartType,
			cartId,
		})
	);

	try {
		await SaveCartApi(saveCartData, apiUrl, cancelToken);
	} catch (err: any) {
		const statusCode = err.response.status;
		const errorCode: number = err.response.data.errorCode;
		const errorMessage: string = err.response.data.message;

		console.error(err);
		// Expcted errors
		if (statusCode === 400) {
			// Cart already exists - Overwrite cart errorCode and message
			if (errorCode === 600) {
				throw new SaveCartError(errorCode, errorMessage);
			}

			if (errorCode === 510) {
				throw new GenericWarning(errorCode, errorMessage);
			}
		} else {
			throw new ServerError();
		}
		return;
	}
};
// -------------------------- Uppdating article delivery date -----------------------------

export const UpdateArticleDeliveryDates = (
	deliveryDates: ArticleDeliveryDatesModel[]
): AppThunk => async (dispatch: any, getState: any) => {
	const { cartType, cartId } = getState().cart.cartObject;

	cancelPricingRequest();

	let apiUrl = dispatch(
		getApiUrl({
			cartObjectType: cartType,
			cartId,
			article: true,
			date: true,
		})
	);

	try {
		let data = await UpdateWithArticleDeliveryDates(
			apiUrl,
			deliveryDates,
			cancelToken
		);
		dispatch(cartSuccess(data as CartObjectModel));
	} catch (err: any) {
		console.error(err);
		const statusCode = err.response.status;
		const errorCode: number = err.response.data.errorCode;
		const errorMessage: string = err.response.data.message;
		if (statusCode === 400) {
			if (errorCode === 510) {
				throw new GenericWarning(errorCode, errorMessage);
			}
		} else {
			throw new ServerError();
		}
	}
};

// ---------------------------Add notes - goodsmark and comment ---------------------------

export const AddNotes = (
	notesData: AddNotesModel[],
	selectedSort?: string
): AppThunk => async (dispatch: any, getState: any) => {
	const {
		cartType,
		cartId,
		availableDeliveryMethods,
		locations,
		singleShipment,
		desiredDate,
	} = getState().cart.cartObject;

	cancelPricingRequest();

	const location = getSelectedWarehouse(locations);
	const deliveryMethod = getSelectedDeliveryMethod(availableDeliveryMethods);

	let apiUrl = dispatch(
		getApiUrl({
			cartObjectType: cartType,
			cartId,
			article: true,
			note: true,
			sort: selectedSort || '',
			deliveryMethod,
			location,
			desiredDate,
			singleShipment,
		})
	);

	try {
		let data = await AddNotesApi(apiUrl, notesData, cancelToken);
		dispatch(cartSuccess(data as CartObjectModel));
	} catch (err: any) {
		console.error(err);
		const statusCode = err.response.status;
		const errorCode: number = err.response.data.errorCode;
		const errorMessage: string = err.response.data.message;
		if (statusCode === 400) {
			if (errorCode === 510) {
				throw new GenericWarning(errorCode, errorMessage);
			}
		} else {
			throw new ServerError();
		}
	}
};

// --------------------------- Delete cart ------------------------------------

export const DeleteCart = (cartId: string): AppThunk => async (
	dispatch: any,
	getState: any
) => {
	const { cartType } = getState().cart.cartObject;

	let apiUrl = dispatch(
		getApiUrl({
			cartObjectType: cartType,
			cartId,
		})
	);

	try {
		await DeleteCartApi(apiUrl);
	} catch (err: any) {
		console.error(err);
		const statusCode = err.response.status;
		const errorCode: number = err.response.data.errorCode;
		const errorMessage: string = err.response.data.message;
		if (statusCode === 400) {
			if (errorCode === 510) {
				throw new GenericWarning(errorCode, errorMessage);
			}
		} else {
			throw new ServerError();
		}
	}
};

// ---------------------------Update Cart - Sort/location/deliveryMethod/desiredDate---------------------------
// ---------------------------Single shipment - cartType/cartId/desiredDate/singleShipment

export const UpdateCart = ({
	selectedSort = '',
	selectedDeliveryMethod = '',
	selectedLocation = '',
	selectedDesiredDate = '',
	singleShipment,
}: UpdateCartModel): AppThunk => async (dispatch: any, getState: any) => {
	const {
		cartType,
		cartId,
		locations,
		availableDeliveryMethods,
		desiredDate,
	} = getState().cart.cartObject;

	cancelPricingRequest();

	dispatch(cartStart());

	const deliveryMethod =
		selectedDeliveryMethod ||
		getSelectedDeliveryMethod(availableDeliveryMethods);

	const location = getSelectedWarehouse(locations);

	let apiUrl = dispatch(
		getApiUrl({
			cartObjectType: cartType,
			cartId,
			sort: selectedSort,
			deliveryMethod,
			location: selectedLocation || location,
			desiredDate: selectedDesiredDate || desiredDate,
			singleShipment: singleShipment,
		})
	);

	try {
		let data = await UpdateCartApi(apiUrl, cancelToken);
		dispatch(cartSuccess(data));
	} catch (err: any) {
		console.error(err);
		const statusCode = err.response.status;
		const errorCode: number = err.response.data.errorCode;
		const errorMessage: string = err.response.data.message;
		if (statusCode === 400) {
			if (errorCode === 510) {
				throw new GenericWarning(errorCode, errorMessage);
			}
		} else {
			throw new ServerError();
		}
	}
};

// ---------------------------Update Saved Cart - Sort ---------------------------
export const UpdateSavedCart = (
	cartId: string,
	selectedSort: string = ''
): any => async (dispatch: any) => {
	let apiUrl = dispatch(
		getApiUrl({
			cartId,
			cartObjectType: 'saved',
			sort: selectedSort,
		})
	);
	try {
		return await UpdateCartApi(apiUrl);
	} catch (err: any) {
		console.error(err);
		const statusCode = err.response.status;
		const errorCode: number = err.response.data.errorCode;
		const errorMessage: string = err.response.data.message;
		if (statusCode === 400) {
			if (errorCode === 500) {
				throw new CheckoutError(errorCode, errorMessage);
			}
			if (errorCode === 510) {
				throw new GenericWarning(errorCode, errorMessage);
			}
		} else {
			throw new ServerError();
		}
	}
};

// --------------------------- Proceed punchout-------------------------------------------------

export const PunchOut = (): any => async (dispatch: any, getState: any) => {
	const { cartId } = getState().cart.cartObject;

	dispatch(cartStart());

	let apiUrl = dispatch(
		getApiUrl({
			cartId,
			punchout: true,
		})
	);

	try {
		return await CheckoutApi(apiUrl);
	} catch (err: any) {
		dispatch(cartFailed(err.toString()));
		const statusCode = err.response.status;
		const errorCode: number = err.response.data.errorCode;
		const errorMessage: string = err.response.data.message;

		console.error(err);
		// Expcted errors
		if (statusCode === 400) {
			// Couldn't proceed checkout
			if (errorCode === 500) {
				throw new PunchOutError(errorCode, errorMessage);
			}
			if (errorCode === 510) {
				throw new GenericWarning(errorCode, errorMessage);
			}
		} else {
			throw new ServerError();
		}
		return;
	}
};

// --------------------------- Proceed Checkout -------------------------------------------------

export const Checkout = (requestData: CheckoutReqestDataModel): any => async (
	dispatch: any,
	getState: any
) => {
	const { cartId } = getState().cart.cartObject;

	dispatch(cartStart());

	let apiUrl = dispatch(
		getApiUrl({
			cartId,
			checkout: true,
		})
	);

	try {
		return await CheckoutApi(apiUrl, requestData);
	} catch (err: any) {
		dispatch(cartFailed(err.toString()));
		const statusCode = err.response.status;
		const errorCode: number = err.response.data.errorCode;
		const errorMessage: string = err.response.data.message;

		console.error(err);
		// Expcted errors
		if (statusCode === 400) {
			// Couldn't proceed checkout
			if (errorCode === 500) {
				throw new CheckoutError(errorCode, errorMessage);
			}

			if (errorCode === 510) {
				throw new GenericWarning(errorCode, errorMessage);
			}
		} else {
			throw new ServerError();
		}
		return;
	}
};

export const selectCartObject = (state: RootState) => state.cart.cartObject;
export const selectCartLoading = (state: RootState) => state.cart.loading;
export const selectCartPriceReloadCounter = (state: RootState) =>
	state.cart.priceReloadCounter;

export const {
	cartStart,
	cartSuccess,
	cartFailed,
	cartUpdatePriceReloadCounter,
} = slice.actions;

export default slice.reducer;
