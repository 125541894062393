/**
 * FormButtons
 */

import { Button } from 'components/ui/FormEpi';
import StandardButton from 'components/ui/Button';
import { EpiserverFormsPageModel } from 'types';

interface NextButtonProp {
	nextId?: string;
	isExpanded: boolean;
	id: string | number;
	translations: any;
}

interface PreviousButtonProp {
	previousIndex?: number;
	previousId?: string;
	isExpanded: boolean;
	id: string | number;
	onPrevious: (state: EpiserverFormsPageModel) => void;
	translations: any;
}

const NextButton = ({
	nextId,
	isExpanded,
	id,
	translations,
}: NextButtonProp) => {
	return (
		<Button
			type="submit"
			name="submit"
			aria-controls={nextId}
			aria-expanded={isExpanded}
			id={id}
		>
			{translations['/shared/form/next']}
		</Button>
	);
};

const PreviousButton = ({
	previousIndex,
	previousId,
	isExpanded,
	id,
	onPrevious,
	translations,
}: PreviousButtonProp) => {
	return (
		<StandardButton
			type="button"
			//@ts-ignore
			color="blue"
			id={id}
			aria-controls={previousId}
			aria-expanded={isExpanded}
			onClick={() =>
				onPrevious({
					currentStep: previousIndex,
					invalidFields: [],
				})
			}
		>
			{translations['/shared/form/previous']}
		</StandardButton>
	);
};

export { PreviousButton, NextButton };
