import React, { useEffect, useState } from 'react';

export const ViewContext = React.createContext({});
const ViewProvider = (props) => {
	const [view, setView] = useState('unset');

	const handleViewChange = (value) => {
		setView(value);
		localStorage.setItem('view', value);
	};

	useEffect(() => {
		const storedValue = localStorage.getItem('view');

		if (storedValue !== null) {
			setView(storedValue);
		}
	}, []);

	return (
		<ViewContext.Provider
			value={{
				view: view,
				handleViewChange: handleViewChange,
			}}
		>
			{props.children}
		</ViewContext.Provider>
	);
};

export default ViewProvider;
