/**
 * RichText
 * @module components/RichText
 */

import { renderElementBasedOnEditMode } from 'common/helpers';
import { RichTextWrapper } from './RichText.styles';
import clsx from 'clsx';

interface RichTextmodel {
	backgroundColor?: string;
	boldText?: any;
	footerCss?: any;
	text?: string;
	name?: string;
	size?: 'small' | 'large' | 'default';
	isInEditMode?: boolean;
	className?: string;
}

const RichText = (props: RichTextmodel) => {
	const {
		boldText,
		text,
		size,
		isInEditMode,
		name,
		className,
		footerCss,
	} = props;

	return (
		<>
			{text
				? renderElementBasedOnEditMode(
						isInEditMode || false,
						name || '',
						RichTextWrapper,
						{
							className: clsx(className, 'richtext'),
							size,
							boldText,
							footerCss,
							dangerouslySetInnerHTML: { __html: text },
						},
						//@ts-ignore
						null
				  )
				: null}
		</>
	);
};

export default RichText;
