import { useEffect, useState } from 'react';
import { FilterState } from 'types/filter-types';

export const usePopState = (state: FilterState) => {
	const [popState, setPopState] = useState<FilterState | null>(null);
	const stringState = JSON.stringify(state);
	useEffect(() => {
		const onChange = (e: any) => {
			if (e.state) {
				const savedState = e.state.sortBy;
				setPopState({
					sortBy: savedState.sortBy,
					display: savedState.display,
					query: e.state.search,
					currentPage: e.state.page,
					facets: savedState.facets,
					group: savedState.group,
				});
			}
		};

		window.addEventListener('popstate', onChange);

		return () => {
			window.removeEventListener('popstate', onChange);
		};
		//eslint-disable-next-line
	}, [stringState]);
	return popState;
};

export const useDefaultPopState = (state: FilterState) => {
	const [popState, setPopState] = useState(null);
	const stringState = JSON.stringify(state);
	useEffect(() => {
		const onChange = (e: any) => {
			if (e.state) {
				setPopState(e.state);
			}
		};

		window.addEventListener('popstate', onChange);

		return () => {
			window.removeEventListener('popstate', onChange);
		};
		//eslint-disable-next-line
	}, [stringState]);
	return popState;
};
