import styled from 'styled-components';
import { white, blueDark, indigo } from 'theme/colors';
import { spacing05x, spacing2x } from 'theme/spacings';
import { H2, Paragraph, H3Css } from 'components/ui/Typography';
import { deviceSmallMax } from 'theme/mediaQueries';
import { fontWeightMedium, fontWeightSemiBold } from 'theme/typography';

export const Heading = styled(H2)`
	color: ${blueDark};
	margin: ${spacing2x} 0;
	font-weight: ${fontWeightMedium};
	@media (max-width: ${deviceSmallMax}) {
		${H3Css}
		color: ${blueDark};
		margin: ${spacing05x} 0;
	}
`;

export const Link = styled.a`
	font-weight: ${fontWeightSemiBold};
	font-size: 1.8rem;
	line-height: 2.4rem;
	text-decoration: none;
	color: ${blueDark};
	margin: 0;
	display: flex;

	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
`;

export const Text = styled(Paragraph)`
	margin: 0 0 ${spacing05x} 0;
`;

export const ReleaseItemStyle = styled.div`
	border-bottom: 0.1rem solid #d0d0d0;
	padding: ${spacing2x} 0;
`;

export const CalendarItemStyle = styled.div`
	display: flex;
	border-bottom: 0.1rem solid #d0d0d0;
	padding: ${spacing2x} 0;
`;

export const DateStyle = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 0 0 3px 3px;
	background-color: #ffffff;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	height: 54px;
	width: 40px;
`;
export const DateHeader = styled.div`
	background-color: ${indigo};
	color: ${white};
	font-size: 1rem;
	font-weight: ${fontWeightMedium};
	line-height: 1.8rem;
	letter-spacing: 0;
	text-align: center;
	text-transform: uppercase;
	height: 18px;
	width: 40px;
`;
export const DateContent = styled.div`
	color: ${indigo};
	font-size: 2.2rem;
	font-weight: ${fontWeightSemiBold};
	line-height: 4rem;
	text-align: center;
`;

export const CalendarEventStyle = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 2rem;
`;
