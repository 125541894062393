import clsx from 'clsx';
import { FacetItemModel } from 'types/filter-types';

const FilterByLocationType = (props: {
	item: FacetItemModel;
	facetId: string;
	onChange: (facetId: string, id: string, active: boolean) => void;
	className?: string;
}) => {
	const { facetId, item, onChange, className } = props;

	const handleClick = () => {
		onChange(facetId, item.id, !item.active);
	};
	return (
		<li>
			<button
				type="button"
				name={`${facetId}-${item.id}`}
				id={`${facetId}-${item.id}`}
				onClick={handleClick}
				className={clsx(
					item.active ? 'btn-all-filter-loc-type' : 'btn-filter-loc-type',
					className
				)}
			>
				<span className="whitespace-no-wrap">{item.name}</span>
			</button>
		</li>
	);
};

export default FilterByLocationType;
