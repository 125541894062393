/**
 * MediaBlock
 */

import clsx from 'clsx';
import { getHeadingLevel } from 'common/helpers';
import { MediaBlockModel } from 'types/block-types';

/** Main description for this component. */
const MediaBlock = (props: MediaBlockModel) => {
	const {
		image,
		id,
		translations,
		heading,
		headingLevel,
		headingStyleLevel,
		heightLevel,
	} = props;
	const Heading = getHeadingLevel(headingLevel);

	const headingCss =
		headingStyleLevel === 1
			? 'text-h2 md:text-h1'
			: `text-${getHeadingLevel(headingStyleLevel)}`;
	return (
		<div
			id="top-image"
			className={clsx(
				'relative w-full sm:h-auto mx-auto max-w-360',
				heightLevel === 'low' && 'md:h-45',
				heightLevel === 'medium' && 'md:h-65',
				heightLevel === 'normal' && 'md:h-120'
			)}
		>
			<picture id={id}>
				<source
					type="image/webp"
					srcSet={
						image.webpSizes &&
						`${image.webpSizes[0]} 1600w,${image.webpSizes[1]} 1200w, ${image.webpSizes[2]} 800w, ${image.webpSizes[3]} 400w`
					}
					sizes="(min-width: 768px) 60vw, (max-width: 767px) 70vw, 800px"
				/>
				<img
					className="h-full w-full object-cover sm:max-h-45 md:max-h-full"
					src={image.src}
					srcSet={
						image.sizes &&
						`${image.sizes[0]} 1600w,${image.sizes[1]} 1200w, ${image.sizes[2]} 800w, ${image.sizes[3]} 400w`
					}
					sizes="(min-width: 768px) 60vw, (max-width: 767px) 70vw, 800px"
					alt={image.alt}
				/>
			</picture>
			<div
				className={clsx(
					'absolute heading-in-media-block sm:w-auto my-auto mx-0 md:left-48 bg-white-whiteOpacity px-5 py-2.5',
					heightLevel === 'low' && 'md:pb-5 pt-5',
					heightLevel === 'medium' && 'top-1/10',
					heightLevel === 'normal' && 'top-1/5'
				)}
			>
				<Heading className={clsx('text-blue font-normal', headingCss)}>
					{heading}
				</Heading>
			</div>
		</div>
	);
};

export default MediaBlock;
