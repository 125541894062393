/**
 * CustomExternalScript
 * @module components/CustomExternalScript
 */

import { CustomExternalScriptContainer } from './CustomExternalScript.styles';

const CustomExternalScript = () => {
	return (
		<CustomExternalScriptContainer id="external-script"></CustomExternalScriptContainer>
	);
};

export default CustomExternalScript;
