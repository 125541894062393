export default ({ className, ...htmlAttributes }: { className?: string }) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		strokeWidth="2"
		strokeLinecap="round"
		strokeLinejoin="round"
		className={className}
		// style={{ fill: 'none' }}
		{...htmlAttributes}
	>
		<rect x="1" y="3" width="15" height="13"></rect>
		<polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
		<circle cx="5.5" cy="18.5" r="2.5"></circle>
		<circle cx="18.5" cy="18.5" r="2.5"></circle>
	</svg>
);
