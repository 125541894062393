import styled, { css } from 'styled-components';
import { Paragraph } from 'components/ui/Typography';
import { darken } from 'polished';
import { deviceSmallMax } from 'theme/mediaQueries';
import * as colors from 'theme/colors';
import { fontWeightBold } from 'theme/typography';
import { spacing1x } from 'theme/spacings';

export const buttonBaseStyle = css`
	color: ${colors.white};
	font-weight: ${fontWeightBold};
	vertical-align: middle;
	text-align: center;
	padding: ${spacing1x} 1.2rem;
	display: inline-block;
	border-radius: 0.5rem;
	text-transform: uppercase;
	transition: background-color 0.4s ease-out, border-color 0.4s ease-out;
	cursor: pointer;
	text-decoration: none;
	font-size: 1.6rem;
	line-height: 2.4rem;
	& svg {
		color: inherit;
		font-size: 2rem;
		vertical-align: sub;
		margin-left: 1rem;
	}
	@media (max-width: ${deviceSmallMax}) {
		font-size: 1.3rem;
		line-height: 2rem;
		padding: 0.2rem 0.6rem;
		& svg {
			font-size: 1.6rem;
		}
	}
`;

export const DefaultButton = styled(Paragraph)`
	${buttonBaseStyle}
	background-color: ${({ buttonColor }) => colors[buttonColor]};
	color: ${({ buttonColor }) =>
		buttonColor !== 'white' && buttonColor !== 'grey'
			? colors['white']
			: colors['greyDarkest']};
	border: 0.2rem solid ${({ buttonColor }) => colors[buttonColor]};
	:hover {
		background-color: ${({ buttonColor }) => darken('0.1', colors[buttonColor])};
	}

	:disabled {
		color: ${colors.greyDarker};
		background-color: ${colors.grey};
		border: 0.2rem solid ${colors.grey};
		cursor: inherit;
	}
`;

export const LinkButton = styled(DefaultButton)``;
