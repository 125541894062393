import styled, { css } from 'styled-components';
import { deviceSmallMax } from 'theme/mediaQueries';
import {
	fontWeightBold,
	fontWeightNormal,
	fontWeightSemiBold,
	fontWeightMedium,
} from 'theme/typography';
import { grey, greyDarker, black, blue } from 'theme/colors';
import { spacing05x, spacing2x, spacing1x } from 'theme/spacings';
import { Paragraph } from 'components/ui/Typography';
import { FiPhone, FiUsers, FiGlobe, FiMail } from 'react-icons/fi';
import RichText from 'components/ui/RichText';

const textCss = css`
	font-size: 1.6rem;
	line-height: 2.4rem;
	font-weight: ${({ bold }) => (bold ? fontWeightBold : fontWeightNormal)};
	margin: ${spacing05x} 0 ${spacing05x};
`;

export const RowWrapper = styled.div`
	border-bottom: 0.1rem solid ${greyDarker};
`;

export const InfoRow = styled.div`
	display: flex;
	padding: ${spacing2x};

	@media (max-width: ${deviceSmallMax}) {
		justify-content: space-between;
		padding: 0;
		margin: ${spacing2x} 0;
	}
`;

export const Image = styled.div`
	display: flex;
	border: 0.2rem solid ${grey};
	height: 15.2rem;
	width: 15.2rem;
	min-width: 15.2rem;
	justify-content: center;
	align-items: center;
	order: 0;
	margin-right: ${spacing2x};

	@media (max-width: ${deviceSmallMax}) {
		margin-right: 0;
		margin-left: ${spacing1x};
		order: 2;
	}
`;

export const LinkWrapper = styled.a`
	width: 100%;
	display: block;
	text-decoration: none;
	:hover {
		h2 {
			text-decoration: underline;
		}
		p {
			text-decoration: underline;
		}
	}
`;

export const Heading = styled.h2`
	font-size: 2.5rem;
	color: ${blue};
	font-weight: ${fontWeightNormal};
	margin-top: ${spacing05x};
	margin-bottom: ${spacing1x};
`;

export const Text = styled.p`
	${textCss}
    font-weight: ${props =>
			props.bold ? fontWeightSemiBold : fontWeightMedium};
    color: ${black};
    margin-right: ${spacing1x};
	white-space: nowrap;

	svg {
		margin-right: ${spacing1x};
	}

	@media (max-width: ${deviceSmallMax}) {
		font-size: 1.3rem;
        margin: 0;
        margin-right: ${spacing1x}
	}

`;

export const Img = styled.img`
	max-width: 100%;
	max-height: 100%;
	object-fit: cover;
	font-family: 'object-fit: cover;';
`;

export const Body = styled.div`
	padding: 0;
	display: flex;
	min-height: 14rem;
	flex-wrap: wrap;
	width: 100%;

	@media (max-width: ${deviceSmallMax}) {
		padding: 0;
	}
`;
export const TopContent = styled.div`
	display: flex;
	flex: 1 0 100%;
	width: 100%;
	@media (max-width: ${deviceSmallMax}) {
		justify-content: space-between;
	}
`;
export const LeftContent = styled.div`
	display: flex;
	flex: 0 1 30%;
	width: 30%;
	flex-wrap: wrap;
	@media (max-width: ${deviceSmallMax}) {
		flex: 1 0 100%;
	}
`;
export const RightContent = styled.div`
	flex: 0 1 70%;
	width: 70%;
	display: flex;
	@media (max-width: ${deviceSmallMax}) {
		margin-top: ${spacing2x};
		flex: 1 0 100%;
	}
`;

export const BottomContent = styled.div`
	flex: 1 0 100%;
	width: 100%;
	padding-bottom: 1.6rem;
`;

export const IconCss = css`
	width: 1.6rem;
	height: 1.6rem;
	color: ${black};
	margin-right: ${spacing1x};
	margin-top: 0.2rem;
`;

export const PhoneIcon = styled(FiPhone)`
	${IconCss};
`;
export const GlobeIcon = styled(FiGlobe)`
	${IconCss};
`;
export const MailIcon = styled(FiMail)`
	${IconCss};
`;
export const FriendsIcon = styled(FiUsers)`
	${IconCss};
`;

export const LargerContainer = styled.div`
	flex: 1 0 60%;
	max-width: 60%;
`;
export const SmallerContainer = styled.div`
	flex: 1 0 40%;
	max-width: 40%;
`;

export const OpeningHoursRichText = styled(RichText)`
	text-align: left;
	font-size: 1.6rem;
	p {
		margin-top: 0;
		font-size: 1.6rem;
		:first-child {
			margin-top: 0rem;
		}
	}
	@media (max-width: ${deviceSmallMax}) {
		font-size: 1.4rem;
		p {
			font-size: 1.4rem;
		}
	}
`;
export const AddressRichText = styled(RichText)`
	font-size: 1.6rem;
	margin-top: -0.2rem;
	p {
		font-size: 1.6rem;
	}
	@media (max-width: ${deviceSmallMax}) {
		font-size: 1.4rem;
		p {
			font-size: 1.4rem;
		}
	}
`;

export const ContactText = styled(Paragraph)`
	font-size: 1.6rem;
	margin: 0;
	padding: 0;
	flex: 0 1 100%;
	display: flex;
	> a {
		text-decoration: none;
		color: ${blue};
		overflow-wrap: break-word;
		max-width: 80%;

		:hover {
			text-decoration: underline;
		}
	}

	@media (max-width: ${deviceSmallMax}) {
		font-size: 1.4rem;
	}
`;
export const CategoryWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const ContentWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
`;
