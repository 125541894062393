import styled from 'styled-components';
import {
	FaFacebookF,
	FaTwitter,
	FaLinkedinIn,
	FaEnvelope,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { greyDarkest, greyDark, grey, blue, orange } from 'theme/colors';
import { spacing05x, spacing1x, spacing2x, spacing5x } from 'theme/spacings';
import RichTextWrapper from 'components/ui/RichText';
import { Paragraph } from 'components/ui/Typography';
import { FiSquare, FiCheckSquare } from 'react-icons/fi';
import { buttonBaseStyle } from 'components/ui/Button/Button.styles';

const IconCss = `
    color: ${greyDarkest};
    background-color: ${grey};
    font-size: 3.5rem;
    padding: ${spacing1x};
    margin-left: ${spacing05x};

    :hover {
        background-color: ${greyDark};
    }
`;

export const Wrapper = styled.div`
	display: flex;

	@media (max-width: 500px) {
		display: block;
		margin-bottom: ${spacing2x};
	}
`;

export const SocialWrapper = styled.div`
	display: flex;
	padding-top: ${spacing5x};

	@media (max-width: 755px) {
		padding-top: 4rem;
		padding-left: ${spacing2x};
	}
	@media (max-width: 500px) {
		padding-top: 0;
	}
`;

export const FacebookIcon = styled(FaFacebookF)`
	${IconCss};
`;

export const TwitterIcon = styled(FaXTwitter)`
	${IconCss};
`;

export const LinkedInIcon = styled(FaLinkedinIn)`
	${IconCss};
`;

export const EmailIcon = styled(FaEnvelope)`
	${IconCss};
`;

export const RichText = styled(RichTextWrapper)`
	max-width: 100%;
`;

export const SquareIcon = styled(FiSquare)`
	color: ${greyDarkest};
	margin-top: 0.1rem;
	position: absolute;
	left: 0;
`;
export const SquareCheckIcon = styled(FiCheckSquare)`
	display: none;
	color: ${blue};
	margin-top: 0.1rem;
	position: absolute;
	left: 0;
`;
export const Checkbox = styled.input`
	opacity: 0;
	position: relative;

	:checked + ${SquareIcon} {
		display: none;
	}

	:checked ~ ${SquareCheckIcon} {
		display: inline-block;
	}
`;
export const CheckboxWrapper = styled.label`
	margin-top: ${spacing05x};
	display: flex;
	cursor: pointer;
	position: relative;
`;

export const CheckText = styled(Paragraph)`
	margin-top: 0;
	margin-left: ${spacing2x};
`;

export const Button = styled.button`
	${buttonBaseStyle};
	background-color: ${orange};
	margin: ${spacing2x} 0;
`;
