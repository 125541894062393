/**
 * ArticleDetailConfiguration
 * @module components/ArticleDetailConfiguration
 */

import LinkList from 'components/framework/LinkList';
import MediaGallery from 'components/framework/MediaGallery';
import SpecificationsList from 'components/framework/SpecificationsList';
import Modal from 'components/ui/Modal';
import { LocalizationContext } from 'context/localization.context';
import { useContext, useEffect, useState } from 'react';
import {
	GenericWarning,
	renderElementBasedOnEditMode,
	ServerError,
} from 'common/helpers';
import Button from 'components/ui/Button';
import Tooltip from 'components/ui/Tooltip';
import Icon from 'components/ui/Icon';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import ProductCounter from 'components/ui/ProductCounter';
import RichText from 'components/ui/RichText';

import { AddToCartModel } from 'types/cart-types';
import {
	BreadCrumbsModel,
	ConfNumberModel,
	ConfSelectModel,
	ConfTextModel,
} from 'types/common';

import {
	selectConfContent,
	selectConfOptions,
	selectConfSummary,
	selectConfQuantity,
	selectConfigurator,
	selectConfConfigId,
	selectConfItemId,
	fetchConfValidate,
	selectConfFieldLoading,
	selectConfPriceLoading,
	selectConfPrices,
	fetchConfPrices,
} from 'store/modules/configurator';
import { useDispatch, useSelector } from 'react-redux';
import { useMedia } from 'hooks';
import { SeparatorIcon } from 'components/framework/ItemRow/ItemRow.styles';
import { AddToCart, selectCartObject } from 'store/modules/cart';
import { toastWithLink } from 'common/toastHelpers';

interface ArticleDetailConfPageModel {
	closeModal: () => void;
}

const ArticleDetailConfiguration = ({
	closeModal,
}: ArticleDetailConfPageModel): JSX.Element => {
	const { t } = useContext<any>(LocalizationContext);
	const { loading } = useSelector(selectConfigurator);
	const {
		mediaGallery,
		documents,
		rangeSpecifications,
		unit,
		currency,
		itemName,
		itemCode,
		longDescription,
		shortDescription,
		name,
		framework,
		breadcrumbs,
		copyUrl,
	} = useSelector(selectConfContent);
	const { cartDisabled, cartHidden, cartLink } = useSelector(selectCartObject);
	const confOptions = useSelector(selectConfOptions);
	const summary = useSelector(selectConfSummary);
	const quantity = useSelector(selectConfQuantity);
	const configId = useSelector(selectConfConfigId);
	const itemId = useSelector(selectConfItemId);
	const prices = useSelector(selectConfPrices);
	const priceLoading = useSelector(selectConfPriceLoading);
	const fieldLoading = useSelector(selectConfFieldLoading);
	const [values, setValues] = useState<
		{ id: string; value: string; type: string }[]
	>([]);
	const [errorMessages, setErrorMessages] = useState<any>({});
	const [agreementsConfirmed, setAgreementsConfirmed] = useState<boolean>(
		false
	);
	const [autoValidation, setAutoValidation] = useState<boolean>(true);
	const dispatch = useDispatch();
	const [currentQuantity, setCurrentQuantity] = useState<number>(quantity || 1);
	const [currentValues, setCurrentValues] = useState<any>({});
	const isMobile = useMedia('(max-width: 767px)');

	const handleAddToCart = async () => {
		try {
			let articleList: AddToCartModel[] = [
				{
					itemCode: itemCode || '',
					quantity: currentQuantity,
					configId: configId,
					configDescription: summary,
				},
			];
			await dispatch(AddToCart('POST', articleList));
			toastWithLink(
				t('shared/toast/cartaddsuccess'),
				cartLink?.link,
				toast.TYPE.SUCCESS
			);

			if (itemCode !== undefined && window.dataLayer) {
				window.dataLayer.push({
					event: 'add_to_cart',
					domain: framework?.header.activeMarket,
					ecommerce: {
						items: [
							{
								item_name: itemName,
								item_id: itemCode,
								price: prices && prices.dictionary['Bruttopris'],
								quantity: 1,
								item_category: breadcrumbs && breadcrumbs[0].text,
								item_category2: breadcrumbs && breadcrumbs[1].text,
							},
						],
					},
				});
			}
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/cartupdateerror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		}
	};

	const validateField = (
		type: string,
		value: any,
		min?: number,
		max?: number
	) => {
		let message: string = '';
		if ((type !== 'text' && value === '') || null) {
			message = t('shared/form/requiredfieldmessage');
		}
		if (type === 'number' && value !== '' && min && max) {
			const fieldIsNotWithinRange =
				(parseInt(value) - min) * (parseInt(value) - max) > 0;
			if (fieldIsNotWithinRange)
				message = `${t('articledetailconfpage/rangemessage')} ${min}-${max}`;
		}
		return message;
	};

	const getValues = (id: string, value: string) => {
		return values.map((item: { id: string; value: string; type: string }) => {
			if (item.id === id) {
				return { ...item, value: value };
			} else return item;
		});
	};

	const handleOnChange = (e: any, fieldIndex: number) => {
		const { type, id, value, max, min } = e.target;
		setCurrentValues({ ...currentValues, [id]: value });
		let message: string = '';
		message = validateField(type, value, min, max);
		setErrorMessages({ ...errorMessages, [id]: message });

		// Select - Make a request right after an option is selected
		if (type === 'select-one') {
			if (itemId && quantity !== undefined && autoValidation) {
				dispatch(
					fetchConfValidate(
						framework?.api.productConfiguratorValidate,
						getValues(id, value),
						itemId,
						quantity,
						fieldIndex
					)
				);
			} else {
				setValues(getValues(id, value));
			}
		}
	};

	const handleOnBlur = (e: any, defaultValue: string, fieldIndex: number) => {
		const { id, value } = e.target;
		if (errorMessages[`${id}`] === '' && value !== defaultValue) {
			if (itemId && quantity !== undefined && autoValidation) {
				dispatch(
					fetchConfValidate(
						framework?.api.productConfiguratorValidate,
						getValues(id, value),
						itemId,
						quantity,
						fieldIndex
					)
				);
			} else {
				setValues(getValues(id, value));
			}
		}
	};

	const setMessagesAndValues = () => {
		let message: string = '';
		let initialMessages: { [key: string]: string } = {};
		let defaultValues: { [key: string]: string } = {};
		confOptions?.forEach((item: any) => {
			if (item.type === 'number' && !item.disabled) {
				message = validateField(
					item.type,
					item.value,
					parseInt(item.minValue),
					parseInt(item.maxValue)
				);
			} else {
				message = validateField(item.type, item.value);
			}
			initialMessages = { ...initialMessages, [item.id]: message };
			defaultValues = { ...defaultValues, [item.id]: item.value };
		});
		setErrorMessages(initialMessages);
		setCurrentValues(defaultValues);
	};

	useEffect(() => {
		setMessagesAndValues();
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		setMessagesAndValues();
		let tmpValues: { id: string; value: string; type: string }[] = [];
		confOptions?.forEach((item: any) => {
			tmpValues.push({
				id: item.id,
				value: item.value,
				type: item.type,
			});
		});
		setValues(tmpValues);
		//eslint-disable-next-line
	}, [confOptions]);

	useEffect(() => {
		if (quantity) {
			setCurrentQuantity(quantity);
		}
	}, [quantity]);

	const handleKeyDown = (e: any) => {
		if (
			(e.target.type === 'number' || e.target.type === 'text') &&
			e.keyCode === 13
		) {
			e.target.blur();
		}
		if (e.target.type === 'checkbox' && e.keyCode === 13) {
			e.preventDefault();
			e.target.click();
		}
	};

	const renderBreadCrumbs = (breadcrumbs: BreadCrumbsModel[]) => {
		return (
			<ul className="my-4 p-0 inline-block list-none text-greyDarker w-full">
				{breadcrumbs.map((item, key) => {
					const shouldHaveSeparator = key !== breadcrumbs.length - 1;
					return (
						<li className="inline-block text-greyDarker" key={key}>
							<a
								className="text-black text-p text-opacity-50 no-underline hover:underline active:underline"
								href={item.link}
								key={key}
							>
								{item.text}
							</a>
							{shouldHaveSeparator && <SeparatorIcon aria-hidden />}
						</li>
					);
				})}
			</ul>
		);
	};

	useEffect(() => {
		if (!cartDisabled && configId) {
			dispatch(fetchConfPrices(framework?.api.productConfiguratorPrice));
		}
		//eslint-disable-next-line
	}, [configId]);

	return (
		<Modal
			heading={`${t('articledetailconfpage/configurate')} - ${
				itemName !== undefined ? itemName : ''
			} #${itemCode !== undefined ? itemCode : ''}`}
			title={t('articledetail/contentdescription')}
			width="large"
			toggleModal={closeModal}
			hasBreadcrumbs={breadcrumbs ? true : false}
			itemCode={itemCode}
			copyUrl={copyUrl}
		>
			{loading ? (
				<div className="text-center">
					<Icon icon="loader" animate="spin" size={5} aria-hidden={true} />
				</div>
			) : (
				<div>
					<div className="flex-col md:flex-row md:flex md:flex-wrap">
						{breadcrumbs && renderBreadCrumbs(breadcrumbs)}
						<div className="w-full p-0 md:w-1/2 md:pr-4">
							{!isMobile && mediaGallery && (
								<MediaGallery
									currentValues={currentValues}
									mediaGallery={mediaGallery}
								/>
							)}
							{!isMobile && documents && (
								<LinkList
									documents={documents}
									expanded={false}
									className="m-0"
								/>
							)}
						</div>
						<div className="w-full p-0 md:w-1/2 md:pl-4">
							<h1>
								{name &&
									framework?.inEditMode &&
									renderElementBasedOnEditMode(
										false,
										'name',
										'span',
										{ className: 'text-orange text-h3 block mt-3' },
										name
									)}
								{shortDescription &&
									framework?.inEditMode &&
									renderElementBasedOnEditMode(
										false,
										'shortDescription',
										'span',
										{ className: 'text-blue text-h2-large md:block mb-3' },
										shortDescription
									)}
							</h1>
							{longDescription && (
								<RichText
									name="longDescription"
									text={longDescription}
									isInEditMode={true}
								/>
							)}
							{isMobile && mediaGallery && (
								<div className="p-0 w-full">
									<MediaGallery
										currentValues={currentValues}
										mediaGallery={mediaGallery}
									/>{' '}
								</div>
							)}
							{isMobile && documents && (
								<LinkList
									documents={documents}
									expanded={false}
									className="m-0"
								/>
							)}
							{!cartDisabled ? (
								<form>
									{rangeSpecifications && (
										<SpecificationsList
											specifications={rangeSpecifications}
											heading={t(
												'shared/specificationslist/rangespecifications'
											)}
											className="md:mb-10"
										/>
									)}
									<fieldset className="relative configurator">
										<legend className="font-bold text-p">
											{t('articledetailconfpage/configurateyourarticle')}
										</legend>

										<div
											className={clsx(
												'md:ml-2',
												fieldLoading && 'confarticle-overlay'
											)}
										>
											<div className="relative">
												{fieldLoading && (
													<div className="text-center absolute top-1/2 left-23/50 z-10">
														<Icon
															icon="loader"
															animate="spin"
															size={4}
															aria-hidden={true}
														/>
													</div>
												)}
												{confOptions?.map(
													(
														field:
															| ConfSelectModel
															| ConfNumberModel
															| ConfTextModel,
														index: number
													) => {
														return (
															<div className="mb-2" key={index}>
																<label
																	className="font-bold text-p"
																	htmlFor={field.id}
																>
																	{field.name}
																</label>

																{field.type === 'select' && field.options ? (
																	<>
																		<select
																			id={field.id}
																			className="font-standard text-sm px-3 py-2 border rounded border-greyDark w-full placeholder-greyDarkest"
																			disabled={
																				!autoValidation ? false : field.disabled
																			}
																			required={true}
																			defaultValue={field.value}
																			value={currentValues[field.id]}
																			onChange={(e) => {
																				handleOnChange(e, index);
																			}}
																		>
																			{field.options?.map(
																				(option: any, index: number) => {
																					return (
																						<option
																							value={option.value}
																							key={index}
																						>
																							{option.name}
																						</option>
																					);
																				}
																			)}
																		</select>
																		{(!autoValidation || !field.disabled) && (
																			<span
																				className="text-red text-p"
																				aria-live="polite"
																			>
																				{errorMessages[field.id]}
																			</span>
																		)}
																	</>
																) : field.type === 'number' ? (
																	<>
																		<input
																			step="any"
																			type="number"
																			id={field.id}
																			className="font-standard text-sm px-3 py-2 border rounded border-greyDark w-full placeholder-greyDarkest"
																			defaultValue={field.value}
																			value={currentValues[field.id]}
																			disabled={
																				!autoValidation ? false : field.disabled
																			}
																			min={field.minValue}
																			max={field.maxValue}
																			required={true}
																			onWheel={(e) => {
																				// Cast e.target to HTMLInputElement to access .blur()
																				const target = e.target as HTMLInputElement;
																				target.blur();
																			}}
																			placeholder={`min. ${field.minValue} ${t(
																				'articledetailconfpage/and'
																			)} max. ${field.maxValue}`}
																			onChange={(e) => {
																				handleOnChange(e, index);
																			}}
																			onBlur={(e) => {
																				handleOnBlur(e, field.value, index);
																			}}
																			onKeyDown={handleKeyDown}
																		/>
																		{(!autoValidation || !field.disabled) && (
																			<span
																				className="text-red text-p"
																				aria-live="polite"
																			>
																				{errorMessages[field.id]}
																			</span>
																		)}
																	</>
																) : field.type === 'text' ? (
																	<>
																		<input
																			type="text"
																			id={field.id}
																			defaultValue={field.value}
																			value={currentValues[field.id]}
																			className="font-standard text-sm px-3 py-2 border rounded border-greyDark w-full placeholder-greyDarkest"
																			onChange={(e) => {
																				handleOnChange(e, index);
																			}}
																			onBlur={(e) => {
																				handleOnBlur(e, field.value, index);
																			}}
																			onKeyDown={handleKeyDown}
																			disabled={field.disabled}
																		/>
																		{(!autoValidation || !field.disabled) && (
																			<span
																				className="text-red text-p"
																				aria-live="polite"
																			>
																				{errorMessages[field.id]}
																			</span>
																		)}
																	</>
																) : null}
															</div>
														);
													}
												)}
											</div>
											<div className="flex justify-between items-baseline">
												<label className="flex items-baseline cursor-pointer relative">
													<input
														type="checkbox"
														id="autoValidation"
														className="relative opacity-0"
														onChange={() => setAutoValidation(!autoValidation)}
														onKeyDown={handleKeyDown}
													/>
													<Icon
														icon={autoValidation ? 'checkSquare' : 'square'}
														size={1.25}
														className={clsx(
															'absolute left-0 focused-sibling:outline-black ',
															autoValidation ? 'text-blue' : 'text-greyDarkest'
														)}
														aria-hidden={true}
													/>
													<span className="ml-3 text-p">
														{t('articledetailconfpage/validateautomatically')}
													</span>
												</label>
												<Button
													buttonColor="orange"
													disabled={autoValidation}
													onClick={() => {
														if (itemId && quantity !== undefined) {
															dispatch(
																fetchConfValidate(
																	framework?.api.productConfiguratorValidate,
																	values,
																	itemId,
																	quantity,
																	-1
																)
															);
														}
													}}
												>
													{t('articledetailconfpage/validate')}
												</Button>
											</div>
											<h2 className="font-bold text-p">
												{t('articledetailconfpage/summary')}
												<Tooltip title={t('articledetailconfpage/summary')}>
													{t('articledetailconfpage/summarytooltip')}
												</Tooltip>
											</h2>
											<p className="text-p">{summary}</p>
											{prices && !priceLoading && (
												<SpecificationsList
													specifications={prices}
													heading={t('articledetailpage/price')}
													prices={true}
													unit={unit ? unit : ''}
													currency={currency ? currency : ''}
												/>
											)}
											{priceLoading && (
												<div className="text-center my-4">
													<Icon
														icon="loader"
														animate="spin"
														size={2}
														aria-hidden={true}
													/>
												</div>
											)}
											{configId && !cartHidden && (
												<>
													<h2 className="font-bold text-p">
														{t('articledetailconfpage/readytoorder')}
														<Tooltip
															title={t('articledetailconfpage/readytoorder')}
														>
															{t('articledetailconfpage/readytoordertooltip')}
														</Tooltip>
													</h2>
													<label className="flex items-baseline cursor-pointer relative">
														<input
															type="checkbox"
															className="relative opacity-0"
															id="agreement"
															onChange={() =>
																setAgreementsConfirmed(!agreementsConfirmed)
															}
															onKeyDown={handleKeyDown}
														/>
														<Icon
															icon={
																agreementsConfirmed ? 'checkSquare' : 'square'
															}
															size={1.25}
															className={clsx(
																'absolute left-0 focused-sibling:outline-black',
																agreementsConfirmed
																	? 'text-blue'
																	: 'text-greyDarkest'
															)}
															aria-hidden={true}
														/>
														<span className="ml-3 text-p">
															{t('articledetailconfpage/agreementtext')}
														</span>
													</label>
													<div className="flex justify-end">
														<div className="my-auto mx-0">
															<ProductCounter
																id={`Counter-${itemId}`}
																productName={itemName || ''}
																itemCode={itemCode || ''}
																quantity={currentQuantity}
																onChange={(itemCode: string, count: number) => {
																	setCurrentQuantity(count);
																}}
															/>
														</div>
														<div className="mb-2 ml-4">
															<Button
																type="button"
																disabled={!agreementsConfirmed}
																buttonColor="blue"
																onClick={() => {
																	handleAddToCart();
																}}
															>
																{isMobile
																	? t('shared/articlerow/add')
																	: t('shared/articlerow/addtocart')}
																<Icon
																	icon="cart"
																	size={1.25}
																	aria-hidden="true"
																/>
															</Button>
														</div>
													</div>
												</>
											)}
										</div>
									</fieldset>
								</form>
							) : (
								<p className="text-p my-8">
									{t('shared/articlerow/needtobeloggedintoconfigure')}
								</p>
							)}
						</div>
					</div>
				</div>
			)}
		</Modal>
	);
};

export default ArticleDetailConfiguration;
