import { useFetch } from './index.ts';

export const useAutoComplete = (query, url) => {
	const preventRequest =
		url === null ||
		typeof url === 'undefined' ||
		query === null ||
		typeof query === 'undefined' ||
		query === '';

	return useFetch({
		url: `${url}?term=${query}`,
		responseDefault: {},
		method: 'get',
		disableCancel: true,
		preventRequest: preventRequest,
		dataHash: query,
	});
};
