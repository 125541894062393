/**
 * MediaView
 * @module components/MediaView
 */

import { SetStateAction, useContext, useEffect, useRef } from 'react';
import { LocalizationContext } from 'context/localization.context';
import { MediaGalleryItemModel } from 'types/common';
import {
	ViewWrapper,
	Image,
	ZoomInIcon,
	Button,
	PlayIcon,
} from './MediaGallery.styles';
import { Dispatch } from 'react';
import { useState } from 'react';

export interface MediaViewProps {
	setAutoplay: Dispatch<SetStateAction<number>>;
	currentValues?: Record<string, unknown>;
	item: MediaGalleryItemModel;
	toggleModal: () => void;
}

const MediaView = ({
	item,
	currentValues,
	toggleModal,
	setAutoplay,
}: MediaViewProps) => {
	const { t } = useContext(LocalizationContext as any);
	const mediaViewIframeRef = useRef<HTMLIFrameElement>(null);

	const [isLoading, setIsLoading] = useState(true); // Initial3d iframe loading state

	useEffect(() => {
		const handleReadyMessage = (event: MessageEvent) => {
			if (event.data?.ready) {
				setIsLoading(false); // set iframe loading to false
			}
		};

		window.addEventListener('message', handleReadyMessage);

		// Cleanup function to remove event listener on unmount
		return () => {
			window.removeEventListener('message', handleReadyMessage);
		};
	}, []);

	const handleOnLoad = () => {
		setIsLoading(true);
	};

	useEffect(() => {
		if (item.type === '3DConfig' && mediaViewIframeRef && !isLoading) {
			const iframeWindow = mediaViewIframeRef?.current?.contentWindow;
			iframeWindow?.window?.postMessage(
				JSON.stringify(currentValues),
				item?.url as string
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentValues, item?.type, mediaViewIframeRef, isLoading]);

	return (
		<ViewWrapper iframe3d={item.type === '3DConfig'} id="media-view">
			{item.type === '3DConfig' && (
				<>
					<iframe
						className="block h-full w-full py-6 mx-auto"
						title={item.title}
						id={item?.id}
						onLoad={handleOnLoad}
						src={item.url}
						ref={mediaViewIframeRef}
					></iframe>
					<Button
						type="button"
						onClick={toggleModal}
						title={`${t('shared/mediagallery/openimageinmodal')}`}
					>
						<ZoomInIcon />
					</Button>
				</>
			)}
			{item.type === 'Image' && (
				<>
					<picture>
						<source
							type="image/webp"
							srcSet={`${item.webpSizes[0]} 800w, ${item.webpSizes[1]} 400w`}
							sizes="60vw"
						/>
						<Image
							src={item.src}
							srcSet={`${item.sizes[0]} 800w, ${item.sizes[1]} 400w`}
							sizes="60vw"
							alt={item.alt ? item.alt : ''}
						/>
					</picture>

					<Button
						type="button"
						onClick={toggleModal}
						title={`${t('shared/mediagallery/openimageinmodal')}`}
					>
						<ZoomInIcon />
					</Button>
				</>
			)}
			{item.type === 'Video' && (
				<>
					<Image src={item.thumbnail} alt={item.alt ? item.alt : ''} />

					<Button
						type="button"
						overlay={item.type}
						onClick={() => {
							toggleModal();
							setAutoplay(1);
						}}
						title={`${t('shared/mediagallery/openvideoinmodal')}`}
					>
						<PlayIcon iconsize="medium" />
						<ZoomInIcon />
					</Button>
				</>
			)}
		</ViewWrapper>
	);
};

export default MediaView;
