import styled from 'styled-components';
import { white } from 'theme/colors';
import { toast } from 'react-toastify';

export const ToastLink = styled.a`
	text-decoration: underline;
	color: ${white};
`;

export const toastWithLink = (linkText, url, type) => {
	let href = '';
	switch (typeof url) {
		case 'object':
			if (url && url.link) {
				href = url.link;
			}
			break;
		case 'string':
			href = url;
			break;
		default:
			href = '';
	}
	toast(
		<ToastLink href={href} target={'_self'}>
			{linkText}
		</ToastLink>,
		{
			type: type,
			closeDelay: 2500,
		}
	);
};
