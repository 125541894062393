/**
 * FilterField
 * @module components/FilterField
 */

import { useContext, useState, useEffect } from 'react';
import { LocalizationContext } from 'context/localization.context';

import { FiFilter, FiSearch } from 'react-icons/fi';
import { FilterFieldButton, InputField } from './FilterField.styles';
import { useDebounce } from 'hooks';
import clsx from 'clsx';

interface FilterFieldModel {
	method: string;
	action: string;
	id: string;
	name: string;
	onSearch: (query: string) => void;
	className?: string;
	value: string;
	searchIcon?: boolean;
	colorIcon?: string;
	placeholder?: string;
}

const FilterField = ({
	method,
	action,
	id,
	name,
	onSearch,
	className,
	value,
	searchIcon,
	colorIcon,
	placeholder,
}: FilterFieldModel) => {
	const { t }: any = useContext(LocalizationContext);
	const [query, setQuery] = useState(value);
	const debouncedQuery = useDebounce(query, 300);

	const handleSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();

		if (onSearch) {
			onSearch(debouncedQuery);
		}
	};

	useEffect(() => {
		if (onSearch) {
			onSearch(debouncedQuery);
		}
	}, [onSearch, debouncedQuery]);

	useEffect(() => {
		if (value !== debouncedQuery) {
			setQuery(value);
		}
		//eslint-disable-next-line
	}, [value]);

	return (
		<form
			method={method}
			action={action}
			className={clsx(className, 'relative w-full mb-4')}
			onSubmit={handleSubmit}
		>
			<label htmlFor={id}>
				<span className="sr-only">{t('shared/filter/filterlabel')}</span>
			</label>
			<InputField
				placeholder={placeholder || t('shared/filter/placeholder')}
				id={id}
				name={name}
				value={query}
				onChange={(e) => setQuery(e.target.value)}
			/>
			<div className="-mt-9 ml-2 ">
				{searchIcon ? (
					<FiSearch color={colorIcon} />
				) : (
					<FiFilter color={colorIcon} />
				)}{' '}
			</div>

			<span className="sr-only">{t('shared/filter/filterphrase')}</span>
		</form>
	);
};

export default FilterField;
