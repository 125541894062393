/**
 * ForgotPasswordPage
 * @module pages/ForgotPasswordPage
 */

import { useRef, useState, useEffect, useContext } from 'react';
import Layout from 'components/ui/Layout';
import { LocalizationContext } from 'context/localization.context';
import ContentArea from 'components/framework/ContentArea';
import Button from 'components/ui/Button';
import { useChangePassword } from 'api/forgotpassword';
import RichText from 'components/ui/RichText';
import { renderElementBasedOnEditMode } from 'common/helpers';
import { Cell, Grid } from 'components/ui/Grid';
import clsx from 'clsx';
import Icon from 'components/ui/Icon';
import { LoginPageModel } from 'types/page-types';

const ForgotPasswordPage = ({
	heading,
	text,
	button,
	contentArea,
}: LoginPageModel): JSX.Element => {
	const { t }: any = useContext(LocalizationContext);
	const formRef = useRef<any>();
	const [message, setMessage] = useState({ message: '', isError: false });
	const [submitted, setSubmitted] = useState(false);

	const [passwordRequest, setPasswordRequest] = useState<any>({});
	const [response, error, loading] = useChangePassword(passwordRequest);
	const [key, setKey] = useState(1);

	useEffect(() => {
		if (error) {
			setMessage({ message: t('shared/toast/servererror'), isError: true });
		} else if (!(response as any).success) {
			setMessage({ message: (response as any).responseText, isError: true });
		} else if ((response as any).success) {
			setMessage({ message: (response as any).responseText, isError: false });
		}
		// change key to reset form inputs
		setKey(key + 1);
		//eslint-disable-next-line
	}, [error, (response as any).responseText, (response as any).success]);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		const antiForgeryElement = document.querySelector(
			'#antiforgery > input[name="__RequestVerificationToken"]'
		);
		const antiforgeryValue =
			antiForgeryElement && (antiForgeryElement as HTMLInputElement).value;
		setPasswordRequest({
			url: button && button.link,
			form: formRef.current,
			antiForgery: antiforgeryValue,
		});
		setSubmitted(true);
	};

	return (
		<main id="main-content">
			<form
				method="POST"
				action={button.link}
				onSubmit={handleSubmit}
				ref={formRef}
				key={key}
			>
				<Layout backgroundColor="white">
					{heading &&
						renderElementBasedOnEditMode(
							true,
							'heading',
							'h1',
							{
								className:
									'font-standard mb-6 mt-10 font-normal text-h2 md:text-h1 text-blue',
							},
							heading
						)}
					<Grid padding={false} className="mb-16">
						<Cell span={12} tablet={6} className="lg:pr-1/12">
							{text && (
								<RichText
									isInEditMode={true}
									name="text"
									size="large"
									text={text}
								/>
							)}
						</Cell>
						<Cell span={12} tablet={6} className="lg:pr-1/12">
							<div className="relative mb-4">
								<label
									className="text-p text-black font-bold block mb-2"
									htmlFor="email"
								>
									{t('forgotpasswordpage/email')}
								</label>
								<input
									className="w-full px-10 py-2 border border-greyDark rounded text-p placeholder-greyDarkest"
									placeholder={t('forgotpasswordpage/emailplaceholder')}
									id="email"
									name="email"
									type="email"
									required
								/>
								<Icon
									icon="user"
									size={1.25}
									color="#949494"
									className="absolute top-0 left-0 mt-10.5 ml-3"
									aria-hidden={true}
								/>
							</div>

							{message && message.message && (
								<p
									className={clsx(
										'text-h4 mb-4',
										message.isError ? 'text-red' : 'text-green'
									)}
								>
									{message.message}
								</p>
							)}

							{button && button.text && button.link && (
								<Button
									type="submit"
									buttonColor="blue"
									disabled={loading || submitted}
								>
									{button.text}
								</Button>
							)}
						</Cell>
					</Grid>
				</Layout>
				{contentArea && <ContentArea contentArea={contentArea} />}
			</form>
		</main>
	);
};

export default ForgotPasswordPage;
