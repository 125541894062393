/**
 * SavedCartPage
 * @module pages/SavedCartPage
 */

import { cloneElement, useContext, useEffect, useRef, useState } from 'react';
import {
	CancelCartPriceError,
	GenericWarning,
	getURLParameter,
	ReloadCartPriceError,
	ServerError,
} from 'common/helpers';
import Layout from 'components/ui/Layout';
import Breadcrumbs from 'components/framework/Breadcrumbs';
import FilterBar from 'components/ui/FilterBar';
import Tooltip from 'components/ui/Tooltip';
import ArticleDetail from 'components/common/ArticleDetail';
import { toast } from 'react-toastify';
import SectionHeader from 'components/ui/SectionHeader';
import { AddToCartModel, CartObjectModel } from 'types/cart-types';
import { ArticleModel } from 'types/article';
import { SavedCartPageModel } from 'types/page-types';
import { SortPropertyModel } from 'types/filter-types';
import { useDispatch, useSelector } from 'react-redux';
import {
	AddToCart,
	AddToSavedCart,
	cartUpdatePriceReloadCounter,
	FetchSavedCart,
	selectCartObject,
	selectCartPriceReloadCounter,
	UpdateSavedCart,
} from 'store/modules/cart';
import CartArticleRow from 'components/framework/CartArticleRow';
import { Cell, Grid } from 'components/ui/Grid';
import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import { selectUserObject } from 'store/modules/user';
import { LocalizationContext } from 'context/localization.context';
import AlertModal from 'components/ui/AlertModal';

const SavedCartPage = ({
	heading,
	breadcrumbs,
	text,
	filter,
	leadTimeTooltip,
	savedCartObject: defaultSavedCartObject,
	addAllToCartButton,
	framework,
}: SavedCartPageModel): JSX.Element => {
	const { t } = useContext<any>(LocalizationContext);
	const dispatch = useDispatch();
	const userObject = useSelector(selectUserObject);
	const cartObject = useSelector(selectCartObject);
	const didRender = useRef(false);
	const [savedCartObject, setSavedCartObject] = useState<CartObjectModel>(
		defaultSavedCartObject
	);
	const [articleModal, setArticleModal] = useState({
		isActive: false,
		link: null,
	});

	const [reloadPricesModal, setReloadPricesModal] = useState({
		isActive: false,
		hasReloadButton: true,
		message: '',
	});

	const [cartLoading, setCartLoading] = useState<boolean>(false);
	const cartPriceReloadCounter = useSelector(selectCartPriceReloadCounter);

	// Handle sort change
	const sortDefault = getURLParameter('sort') || filter?.sortBy;
	const [sortSelection, setSortSelection] = useState<any>(sortDefault);

	const handleSortChange = async (sortBy: any) => {
		if (sortBy !== sortSelection) {
			window?.history?.pushState(
				{},
				document.title,
				`${window.location.pathname}?sort=${sortBy}`
			);
			setSortSelection(sortBy);

			try {
				let data = await dispatch(
					UpdateSavedCart(savedCartObject.cartId, sortBy)
				);
				setSavedCartObject(data);
			} catch (err) {
				if (err instanceof ServerError) {
					toast(t('shared/toast/servererror'), { type: toast.TYPE.ERROR });
				} else if (err instanceof GenericWarning) {
					toast(err.message, { type: toast.TYPE.WARNING });
				}
			}
		}
	};

	const handleQuantityChange = async (
		item: ArticleModel,
		itemCode: string,
		currentQuantity: number
	) => {
		try {
			setCartLoading(true);
			if (currentQuantity !== item.quantity) {
				const articleList = [
					{
						itemCode: itemCode,
						quantity: currentQuantity,
						lineItemId: item.lineItemId,
						configId: item.configId,
						configDescription: item.configDescription,
					},
				];
				const data = await dispatch(
					AddToSavedCart(
						savedCartObject.cartId,
						'PATCH',
						articleList,
						sortSelection
					)
				);
				setSavedCartObject(data);
			}
			setCartLoading(false);
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/cartupdateerror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		}
	};

	const handleAddAllToCart = async () => {
		try {
			let articleList: AddToCartModel[] = [];
			savedCartObject.articleList?.forEach((item) => {
				articleList.push({
					itemCode: item.itemCode,
					quantity: item.quantity,
					lineItemId: item.lineItemId,
					configId: item.configId,
					configDescription: item.configDescription,
					goodsMarking: item.notes.goodsMarking,
					comments: item.notes.comments,
				});
			});
			await dispatch(AddToCart('POST', articleList));
			toast(t('savedcartpage/alladdedtocartmessage'), {
				type: toast.TYPE.SUCCESS,
			});
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/cartupdateerror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		}
	};

	const handleReloadPrices = async () => {
		try {
			await dispatch(cartUpdatePriceReloadCounter(cartPriceReloadCounter + 1));
			fetchCartObject();
		} catch (err) {
		} finally {
			setReloadPricesModal({ ...reloadPricesModal, isActive: false });
		}
	};

	const fetchCartObject = async () => {
		try {
			setCartLoading(true);
			const data = await dispatch(
				FetchSavedCart(
					savedCartObject.cartId,
					savedCartObject.withPrice,
					sortSelection
				)
			);
			if (data) {
				setSavedCartObject(data);
			}
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/servererror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof ReloadCartPriceError) {
				setReloadPricesModal({
					isActive: true,
					hasReloadButton: true,
					message: err.message,
				});
			} else if (err instanceof CancelCartPriceError) {
				setReloadPricesModal({
					isActive: true,
					hasReloadButton: false,
					message: err.message,
				});
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		} finally {
			setCartLoading(false);
		}
	};

	useEffect(() => {
		if (didRender.current) {
			fetchCartObject();
		}
		if (!didRender.current && typeof userObject !== 'undefined') {
			didRender.current = true;
		}
		//eslint-disable-next-line
	}, [userObject]);

	useEffect(() => {
		if (savedCartObject?.withPrice === false) {
			fetchCartObject();
		}
		//eslint-disable-next-line
	}, [savedCartObject, cartObject]);

	return (
		<>
			<main id="main-content">
				<Layout backgroundColor="white">
					{breadcrumbs && <Breadcrumbs links={breadcrumbs} />}
					<SectionHeader
						heading={heading}
						text={text}
						size={'large'}
						isInEditMode={false}
					/>
					<FilterBar
						sortByItems={filter?.sortProperties as SortPropertyModel[]}
						defaultSortByItem={sortSelection}
						onFilter={handleSortChange}
					/>
					{savedCartObject?.articleList?.map(
						(item: ArticleModel, index: number) => {
							return (
								<CartArticleRow
									key={index}
									{...item}
									handleQuantityChange={handleQuantityChange}
									certificates={item?.certificates}
									handleOpenArticleModal={setArticleModal}
									addToCartBtnDisabled={false}
									notesModalDisabled={true}
									cartLoading={cartLoading}
								/>
							);
						}
					)}
					<Grid padding={false} className="mb-8">
						<Cell span={12} tablet={6} className="pl-1/12 mt-8">
							{savedCartObject?.leadTime && (
								<div>
									<p className="text-p font-bold inline-block">
										{t('cartpage/estimatedleadtime')}
									</p>
									{leadTimeTooltip && (
										<Tooltip
											title={t('cartpage/descriptionofestimatedleadtime')}
										>
											{leadTimeTooltip}
										</Tooltip>
									)}
									{savedCartObject?.leadTime && (
										<p className="text-p">{`${savedCartObject.leadTime} ${t(
											'cartpage/businessdaysafterorderconfirmation'
										)}`}</p>
									)}
								</div>
							)}
						</Cell>
						<Cell span={12} tablet={6} className="mt-8 pr-1/12">
							{
								<>
									{savedCartObject?.total?.amountNetPriceFormatted && (
										<div className="flex justify-between items-baseline">
											<p className="text-p font-bold inline-block">
												{t('cartpage/total')}
											</p>
											<p className="text-h3 md:text-h2-large text-blue font-normal my-2 md:m-0">
												{`
														${savedCartObject.total.amountNetPriceFormatted} 
													 ${savedCartObject.total?.currency}`}
											</p>
										</div>
									)}
									{savedCartObject?.total?.amountGrossPriceFormatted && (
										<div className="flex justify-between items-baseline">
											<p className="text-p">{t('cartpage/grosstotal')}</p>
											<p className="text-p">
												{`${savedCartObject.total.amountGrossPriceFormatted} ${savedCartObject.total?.currency}`}
											</p>
										</div>
									)}
									{savedCartObject?.total?.amountWebShopDiscountFormatted && (
										<div className="flex justify-between items-baseline">
											<p className="text-p">{t('cartpage/webshopdiscount')}</p>
											<p className="text-p">
												{`- ${savedCartObject.total.amountWebShopDiscountFormatted} ${savedCartObject.total?.currency}`}
											</p>
										</div>
									)}
								</>
							}
						</Cell>
						<Cell span={12} className="mr-1/12">
							<div className="flex justify-end my-8 flex-wrap items-baseline">
								<Button
									type="button"
									className="flex justify-end ml-2 mt-2 md:mt-0 whitespace-no-wrap "
									buttonColor="blue"
									onClick={handleAddAllToCart}
								>
									{addAllToCartButton.text}
									<Icon icon="cart" size={1.25} aria-hidden="true" />
								</Button>
							</div>
						</Cell>
					</Grid>
				</Layout>
			</main>
			{articleModal.isActive && (
				<ArticleDetail
					onToggle={() => {
						setArticleModal({ link: null, isActive: false });
						!savedCartObject.withPrice && fetchCartObject();
					}}
					articleApiUrl={articleModal.link}
					disableControls
					activeMarket={framework.header.activeMarket}
				/>
			)}
			{reloadPricesModal.isActive && (
				<AlertModal
					title={t('cartpage/reloadpricedialogtitle')}
					heading={t('cartpage/reloadpricedialogtitle')}
					message={reloadPricesModal.message}
					cancelButtonText={t('cartpage/deletedialogcancel')}
					actionButtonText={
						reloadPricesModal.hasReloadButton && t('cartpage/reload')
					}
					onToggle={() =>
						setReloadPricesModal({ ...reloadPricesModal, isActive: false })
					}
					onClick={reloadPricesModal.hasReloadButton && handleReloadPrices}
				/>
			)}
		</>
	);
};

export default SavedCartPage;
