/**
 * ArchiveBlock
 */

import { Grid, Cell } from 'components/ui/Grid';
import Icon from 'components/ui/Icon';

interface Props {
	/** Array of reports  */
	reports: Array<{
		name: string;
		linkToPdf: string;
		informationType: string;
	}>;

	/** The heading/year of a report list */
	year: string;

	/** Pass an optional className */
	className?: string;
}

const ArchiveBlock = ({ reports, year, className }: Props): JSX.Element => (
	<div className={className}>
		<Grid padding={false}>
			<Cell span={12}>
				<div className="flex items-center mb-2 bg-indigo h-12">
					{year && (
						<h2 className="text-white font-semibold pl-4 text-h4">{year}</h2>
					)}
				</div>
			</Cell>
			<Cell span={12} tablet={6}>
				{reports?.map((report, i) => {
					if (report.informationType !== 'RDV') {
						return (
							<a
								className="text-p text-blue my-4 flex cursor-pointer hover:underline"
								key={i}
								href={report.linkToPdf}
								target="_blank"
							>
								<span className="mr-2 -mt-1">
									<Icon icon="file" size={2} color="#0075BF" />
								</span>
								<span>{report.name}</span>
							</a>
						);
					} else return null;
				})}
			</Cell>
			<Cell span={12} tablet={6}>
				{reports?.map((report, i) => {
					if (report.informationType === 'RDV') {
						return (
							<a
								className="text-p text-blue my-4 flex cursor-pointer hover:underline"
								key={i}
								href={report.linkToPdf}
								target="_blank"
							>
								<span className="mr-2 -mt-1">
									<Icon icon="file" size={2} color="#0075BF" />
								</span>
								<span>{report.name}</span>
							</a>
						);
					} else return null;
				})}
			</Cell>
		</Grid>
	</div>
);

export default ArchiveBlock;
