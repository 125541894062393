/**
 * Button
 */
import Button from 'components/ui/Button';

interface ButtonModel {
	type: 'button' | 'submit' | 'reset';
	name: string;
	id: string | number;
	resetForm?: () => void;
	children: JSX.Element | JSX.Element[];
}

const FormEpiButton = ({
	type = 'button',
	name,
	id,
	resetForm,
	children,
	...propsToTransfer
}: ButtonModel) => {
	if (type === 'reset') {
		return (
			<Button
				type={type}
				name={name}
				id={id.toString()}
				{...propsToTransfer}
				onClick={resetForm}
				buttonColor="orange"
			>
				{children}
			</Button>
		);
	} else {
		return (
			<Button
				type={type}
				name={name}
				id={id.toString()}
				{...propsToTransfer}
				buttonColor="blue"
			>
				{children}
			</Button>
		);
	}
};

export default FormEpiButton;
