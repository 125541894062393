import styled, { css } from 'styled-components';
import { blue, white, greyDark } from 'theme/colors';
import { spacing1x, spacing2x } from 'theme/spacings';
import { paragraphCss } from '../../ui/Typography';
import { FiGlobe, FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { fontWeightBold, fontWeightNormal } from 'theme/typography';

export const LanguageSelectorWrapper = styled.div`
	padding: ${spacing1x};
	border-right: ${(props) =>
		props.mobile ? 'none' : `0.2rem solid ${greyDark}`};
	border-left: ${(props) =>
		props.mobile ? 'none' : `0.2rem solid ${greyDark}`};
	display: inline-block;
	position: relative;
	width: ${(props) => props.mobile && '100%'};
	background-color: ${white};
`;

const textCss = css`
	${paragraphCss};
	margin-top: ${spacing1x};
	margin-bottom: ${spacing1x};
	display: inline-block;
	font-size: 14px;
	font-weight: ${(props) => (props.bold ? fontWeightBold : fontWeightNormal)};
	color: ${(props) => (props.active ? blue : 'inherit')};
`;

export const Text = styled.p`
	${textCss};
`;

export const ToggleText = styled.p`
	${textCss};
	max-width: 22rem;
	margin-left: 2.5rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	@media (max-width: 1150px) {
		margin-left: 1.5rem;
	}
`;

export const Link = styled.a`
	${textCss};
	text-decoration: none;
	&:hover {
		color: ${blue};
		text-decoration: underline;
	}
`;

export const GlobeIcon = styled(FiGlobe)`
	color: ${blue};
	margin-right: ${spacing1x};
	position: absolute;
	display: inline-block;
	width: 1.8rem;
	top: 50%;
	transform: translateY(-50%);
	@media (max-width: 1150px) {
		top: 0.4rem;
		left: 1rem;
		position: relative;
		transform: none;
	}
`;

const arrowStyling = css`
	color: ${blue};
	position: relative;
	top: ${spacing2x};
	margin-left: ${spacing1x};
	display: inline-block;
	transform: translateY(-50%);
	width: 1.8rem;
`;

export const ArrowUp = styled(FiChevronUp)`
	${arrowStyling};
`;

export const ArrowDown = styled(FiChevronDown)`
	${arrowStyling};
`;

export const LanguageSelectorDropDown = styled.ul`
	position: ${(props) => (props.mobile ? 'static' : 'absolute')};
	padding: 0 ${spacing1x};
	width: ${(props) => (props.mobile ? '100%' : 'calc(100% + 0.4rem)')};
	max-height: ${(props) =>
		props.offSetHeight > 1
			? `calc(100vh - ${props.offSetHeight + 150}px)`
			: '90vh'};
	overflow-y: auto;
	border: ${(props) => (props.mobile ? 'none' : `0.2rem solid ${greyDark}`)};
	border-top: none;
	left: ${(props) => (props.mobile ? 0 : '50%')};
	transform: ${(props) => (props.mobile ? 'none' : 'translateX(-50%)')};
	z-index: 300;
	background-color: ${white};
	margin-top: 0.6rem;
`;

export const DropdownItem = styled.li`
	border-top: 0.2rem solid ${greyDark};
`;

export const DropDownToggle = styled.button`
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	display: flex;
	align-items: baseline;
`;

export const VisuallyHidden = styled.span`
	border: 0;
	clip: rect(0 0 0 0);
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.1rem;
`;
