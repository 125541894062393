import { useFetch } from './index.ts';
import axios from 'axios';
import Axios from 'axios';

export const useFilterSearch = (
	query,
	url,
	prevent = false,
	documentSearch = false
) => {
	if (process.env.NODE_ENV === 'development' && url) {
		if (query && query.includes('group=')) {
			url = 'http://localhost:4000/api/searchpage';
		} else if (documentSearch) {
			url = 'http://localhost:4000/api/documentsearch';
		} else {
			url = 'http://localhost:4000/api/search';
		}
	}
	return useFetch({
		url: `${url}?${query}`,
		responseDefault: {},
		method: 'post',
		disableCancel: true,
		preventRequest:
			query === null ||
			typeof query === 'undefined' ||
			url === null ||
			typeof url === 'undefined' ||
			prevent,
	});
};

export const usePressRelease = (query, url, prevent = false) => {
	if (process.env.NODE_ENV === 'development' && url) {
		url = `http://localhost:4000/api/pressreleaselist`;
	}
	return useFetch({
		url: `${url}?${query}`,
		responseDefault: {},
		method: 'post',
		disableCancel: true,
		preventRequest:
			query === null ||
			typeof query === 'undefined' ||
			url === null ||
			typeof url === 'undefined' ||
			prevent,
	});
};

export const usePriceStockDetail = ({
	articlesArray,
	companyId,
	customerId,
	projectId,
	warehouseId,
	url,
	desiredDeliveryDate,
	preventRequest,
	includeTotal = false,
}) => {
	const itemCodes =
		articlesArray &&
		articlesArray.length > 0 &&
		articlesArray.map((item) => {
			return {
				code: item.itemCode,
				quantity: item.quantity || 1,
				configId: item.configId || '',
				lineItemId: item.lineItemId || 0,
			};
		});

	const body = itemCodes && {
		itemCodes: itemCodes,
		companyId: companyId || '',
		customerId: customerId || '',
		projectId: projectId || '',
		warehouseId: warehouseId || '',
		desiredDeliveryDate: desiredDeliveryDate || '',
		readThroughCache: true,
		includeTotal: includeTotal,
	};

	const dataHash = JSON.stringify(body);
	let result = useFetch({
		url: `${url}`,
		responseDefault: {},
		method: 'post',
		preventRequest:
			url === null ||
			typeof url === 'undefined' ||
			body === null ||
			itemCodes === false ||
			preventRequest,
		data: body,
		dataHash: dataHash,
	});

	return result;
};

export const fetchPriceStockDetail = async ({
	articlesArray,
	companyId,
	customerId,
	projectId,
	warehouseId,
	url,
	desiredDeliveryDate,
	includeTotal = false,
}) => {
	const itemCodes =
		articlesArray &&
		articlesArray.length > 0 &&
		articlesArray.map((item) => {
			return {
				code: item.itemCode,
				quantity: item.quantity || 1,
				configId: item.configId || '',
				lineItemId: item.lineItemId || 0,
			};
		});

	const body = itemCodes && {
		itemCodes: itemCodes,
		companyId: companyId || '',
		customerId: customerId || '',
		projectId: projectId || '',
		warehouseId: warehouseId || '',
		desiredDeliveryDate: desiredDeliveryDate || '',
		readThroughCache: true,
		includeTotal: includeTotal,
	};

	const dataHash = JSON.stringify(body);

	try {
		const result = await Axios({
			url: `${url}`,
			method: 'post',
			data: body,
			dataHash: dataHash,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'content-type': 'application/json; charset=utf-8',
			},
		});

		return result.data || null;
	} catch (err) {
		console.error(err);
		return null;
	}
};

export const fetchArticleDetail = async (url) => {
	if (process.env.NODE_ENV === 'development' && url) {
		url = `http://localhost:4000${url}`;
	}
	try {
		const result = await Axios({
			url: `${url}`,
			method: 'get',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'content-type': 'application/json; charset=utf-8',
			},
		});

		return result.data || null;
	} catch (err) {
		console.error(err);
		return null;
	}
};

export const trackClick = (url, trackObject) => {
	let query = '';
	Object.keys(trackObject).forEach((key, i) => {
		query += `${key}=${trackObject[key]}&`;
	});
	const request = `${url}?${query}`;

	axios.post(request).catch((error) => {});
};
