/**
 * ResponsiveIframe
 */

import React from 'react';
import clsx from 'clsx';

interface Props {
	/** Iframe src */
	src: string;

	/** The aspect ratio of the video:
	 *
	 * - 16:9 - most common format, used by YouTube videos.
	 * - 4:3 - used mostly by older videos.
	 */
	aspectRatio?: '16:9' | '4:3';

	title: string;

	/** Cookie Information - custom attribute */
	'data-consent-src'?: string;

	/** Cookie Information - custom attribute */
	'data-category-consent'?: string;
}

/** Used to render a responsive iframe. */
const ResponsiveIframe: React.FC<Props> = ({
	src,
	title,
	aspectRatio = '16:9',
	'data-consent-src': dataConsentSrc,
	'data-category-consent': dataCategoryConsent,
}) => {
	return (
		<div
			data-cid="ResponsiveIframe"
			className={clsx(
				'relative overflow-hidden bg-greyLighter',
				aspectRatio === '16:9' ? 'iframe-16-9' : 'iframe-other'
			)}
		>
			<iframe
				src={src}
				data-consent-src={dataConsentSrc}
				data-category-consent={dataCategoryConsent}
				title={title}
				className="absolute inset-0 w-full h-full"
				allowFullScreen
			/>
		</div>
	);
};

export default ResponsiveIframe;
