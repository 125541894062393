/**
 * SearchsummarySummary
 * @module components/SearchsummarySummary
 */

import React, { useContext } from 'react';
import { LocalizationContext } from 'context/localization.context';

interface Props {
	query: string;
	pagination?: any;
	suggestions: string[];
	handleSuggestion: any;
	className?: string;
}
const renderSuggestions = (suggestions: string[], handleSuggestion: any) => {
	return suggestions.map((item: string, key: number) => {
		const onClick = (e: any) => {
			e.preventDefault();
			handleSuggestion && handleSuggestion(item);
		};

		return (
			<a
				onClick={onClick}
				key={key}
				href="#"
				className="text-blue mr-2 hover:underline"
			>
				{item}?
			</a>
		);
	});
};

const SearchResultSummary = ({
	query,
	pagination,
	suggestions,
	handleSuggestion,
	className,
}: Props) => {
	const { t }: any = useContext(LocalizationContext);

	let summary = '';
	if (pagination) {
		summary += `${pagination.total || 0} ${
			pagination.total === 1
				? t('shared/searchsummary/hit')
				: t('shared/searchsummary/hits')
		}`;
		if (query) summary += ` ${t('shared/searchsummary/for')} '${query}'`;
		summary += '.';
		if (pagination.total > 0) {
			const onFirstPage = pagination.currentPage === 1;
			const onSecondPage = pagination.currentPage === 2;

			summary += ` ${t('shared/searchsummary/displaying')}`;
			if (onFirstPage) {
				summary += ` 1 - ${pagination.display}`;
			} else if (onSecondPage) {
				summary += ` ${pagination.display + 1} - ${
					pagination.display * pagination.currentPage
				}`;
			} else {
				summary += ` ${
					pagination.display * (pagination.currentPage - 1) + 1
				} - ${pagination.display * pagination.currentPage} `;
			}
			summary += '.';
		}
	}

	return (
		<div className={className}>
			<p className="text-p">
				<span>{summary}</span>
				{suggestions && suggestions.length > 0 && (
					<span>
						{` ${t('shared/searchsummary/didyoumean')} `}
						{renderSuggestions(suggestions, handleSuggestion)}
					</span>
				)}
			</p>
		</div>
	);
};
export default SearchResultSummary;
