/**
 * ReportBlock
 * @module components/ReportBlock
 */

import { ImageModel } from 'types/common';
import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';

interface ReportBlockModel {
	modelType: string;
	heading: string;
	date: string;
	name: string;
	linkToPdf: string;
	image: ImageModel;
	buttonText: string;
	buttonLink: string;
}

const ReportBlock = ({
	heading,
	date,
	name,
	linkToPdf,
	image,
	buttonText,
	buttonLink,
}: ReportBlockModel) => (
	<div className="flex pr-10">
		{image && (
			<div className="h-45 min-h-45 w-35 min-w-35 lg:w-45 lg:min-w-45 lg:h-62.5 lg:min-h-62.5">
				<img
					src={image.src}
					alt={image.alt}
					className="image__object-fit w-full h-full"
				/>
			</div>
		)}
		{heading && (
			<div className="ml-6">
				{date && (
					<p className="mb-2 text-greyDarkest font-light text-p">{date}</p>
				)}
				<h2 className="text-h2 text-black mb-2 font-medium">{heading}</h2>
				{name && (
					<a
						href={linkToPdf}
						target="_blank"
						className="block text-h4 text-blue hover:underline lg:mb-8"
					>
						<span>
							<Icon icon="file" size={1.25} className="mr-2 -mt-1" />
						</span>
						{name}
					</a>
				)}
				{buttonLink && (
					<Button buttonColor={'orange'} type="link" url={buttonLink}>
						{buttonText}
					</Button>
				)}
			</div>
		)}
	</div>
);

export default ReportBlock;
