import styled from 'styled-components';
import { Input } from 'components/ui/Form/Form.styles';
import { spacing1x, spacing5x, spacing3x } from 'theme/spacings';
import { greyDarker, blue } from 'theme/colors';

interface FilterFieldButtonProps {
	searchIcon?: boolean;
}

export const FilterFieldButton = styled.button<FilterFieldButtonProps>`
	background-color: transparent;
	border: none;
	position: absolute;
	top: ${(props) => props.searchIcon && '14px'};
	left: ${(props) => (props.searchIcon ? '18px' : '0')};
	padding: ${(props) => !props.searchIcon && `${spacing1x} 1rem`};

	@media (max-width: 500px) {
		top: ${(props) => props.searchIcon && '10px'};
	}

	@media (max-width: 890px) {
		padding-top: 0;
		padding-bottom: 0;
	}

	svg {
		font-size: 2.2rem;
		height: 2.4rem;
		width: 2.4rem;
		color: ${(props) => (props.searchIcon ? blue : greyDarker)};
		@media (max-width: 890px) {
			font-size: 1.8rem;
			height: 1.6rem;
			width: 1.6rem;
		}
	}

	@media (max-width: 890px) {
		padding-top: 0;
		padding-bottom: 0;
	}
`;

export const InputField = styled(Input)`
	width: 100%;
	height: 4rem !important;
	padding: ${spacing1x} ${spacing3x} ${spacing1x} ${spacing5x};
`;
