/**
 * CategoryList
 * @module components/CategoryList
 */

import clsx from 'clsx';

interface Props {
	/** Pass an optional className */
	className?: string;

	children?: any;
}

const CategoryList = ({ className, children }: Props): JSX.Element => {
	return <ul className={clsx(className)}>{children}</ul>;
};

export default CategoryList;
