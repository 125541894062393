/**
 * SubscribePage
 * @module pages/SubscribePage
 */

import { useContext, useEffect, useState } from 'react';
import Layout from 'components/ui/Layout';
import SectionHeader from 'components/ui/SectionHeader';
import { LocalizationContext } from 'context/localization.context';
import { Grid, Cell } from 'components/ui/Grid';
import {
	Wrapper,
	SocialWrapper,
	FacebookIcon,
	TwitterIcon,
	LinkedInIcon,
	EmailIcon,
	RichText,
	CheckboxWrapper,
	SquareIcon,
	SquareCheckIcon,
	Checkbox,
	CheckText,
	Button,
} from './SubscribePage.styles';

import Breadcrumbs from 'components/framework/Breadcrumbs';
import Icon from 'components/ui/Icon';
import { SubscribePageModel } from 'types/page-types';

const SubscribePage = ({
	heading,
	breadcrumbs,
	preamble,
	text,
	isInEditMode,
}: SubscribePageModel) => {
	const { t }: any = useContext(LocalizationContext);
	const [windowUrl, setWindowUrl] = useState('');
	useEffect(() => {
		setWindowUrl(window.location.href);
	}, []);

	return (
		<main id="main-content">
			<Layout backgroundColor="white">
				{breadcrumbs && <Breadcrumbs links={breadcrumbs} />}
				<Grid padding={false}>
					<Cell span={12}>
						<Wrapper>
							<div className="w-full">
								<SectionHeader
									heading={heading}
									size={'large'}
									isInEditMode={isInEditMode}
								/>
							</div>
							<SocialWrapper>
								<a
									className="m-0"
									href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
										windowUrl
									)}`}
									title={t('shared/socialmedia/shareonfacebook')}
									target="_blank"
								>
									<FacebookIcon />
								</a>
								<a
									className="m-0"
									href={`https://twitter.com/home?status=${encodeURIComponent(
										document.title
									)}%20${encodeURIComponent(windowUrl)}`}
									title={t('shared/socialmedia/shareontwitter')}
									target="_blank"
								>
									<TwitterIcon />
								</a>
								<a
									className="m-0"
									href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
										windowUrl
									)}`}
									title={t('shared/socialmedia/shareonlinkedin')}
									target="_blank"
								>
									<LinkedInIcon />
								</a>
								<a
									className="m-0"
									href={`mailto:?subject=[title]&body=${encodeURIComponent(
										windowUrl
									)}`}
									title={t('shared/socialmedia/sharewithemail')}
									target="_blank"
								>
									<EmailIcon />
								</a>
							</SocialWrapper>
						</Wrapper>
					</Cell>
					<Cell span={12} desktop={8} className="mr-4/12">
						<p className="text-black text-preamble tracking-normal">
							{preamble}
						</p>
						{text && (
							<RichText name="text" text={text} isInEditMode={isInEditMode} />
						)}
					</Cell>
					<Cell span={12} desktop={4} className="mr-8/12 my-8">
						<form
							method="post"
							name="PageForm"
							action="//publish.ne.cision.com/Subscription/SubscribeWithCaptcha"
							id="subscribeForm"
						>
							<input
								type="hidden"
								name="subscriptionUniqueIdentifier"
								value="66112afeff"
							/>
							<input type="hidden" name="replylanguage" value="sv" />

							<fieldset className="border-0 p-0 my-4">
								<legend className="text-p font-semibold border-0">
									{t('subscribepage/choosetypeofpressrelease')}
								</legend>
								<CheckboxWrapper>
									<Checkbox
										type="checkbox"
										name="informationtype"
										value="kmk,rpt,rdv"
										id="interim"
									/>
									<SquareIcon aria-hidden={true} />
									<SquareCheckIcon aria-hidden={true} />
									<CheckText as="span">{t('subscribepage/reports')}</CheckText>
								</CheckboxWrapper>
								<CheckboxWrapper>
									<Checkbox
										type="checkbox"
										name="informationtype"
										value="prm"
										id="press"
									/>
									<SquareIcon aria-hidden={true} />
									<SquareCheckIcon aria-hidden={true} />
									<CheckText as="span">
										{t('subscribepage/pressreleases')}
									</CheckText>
								</CheckboxWrapper>
								<CheckboxWrapper>
									<Checkbox
										type="checkbox"
										name="informationtype"
										value="nbr"
										id="news"
									/>
									<SquareIcon aria-hidden={true} />
									<SquareCheckIcon aria-hidden={true} />
									<CheckText as="span">
										{t('subscribepage/newsletters')}
									</CheckText>
								</CheckboxWrapper>
							</fieldset>
							<fieldset className="border-0 p-0 my-4">
								<legend className="text-p font-semibold border-0">
									{t('subscribepage/chooselanguage')}
								</legend>
								<CheckboxWrapper>
									<Checkbox
										type="checkbox"
										name="language"
										value="en"
										id="LanguageEn"
									/>
									<SquareIcon aria-hidden={true} />
									<SquareCheckIcon aria-hidden={true} />
									<CheckText as="span">{t('subscribepage/english')}</CheckText>
								</CheckboxWrapper>
								<CheckboxWrapper>
									<Checkbox
										type="checkbox"
										name="language"
										value="sv"
										id="LanguageSv"
									/>
									<SquareIcon aria-hidden={true} />
									<SquareCheckIcon aria-hidden={true} />
									<CheckText as="span">{t('subscribepage/swedish')}</CheckText>
								</CheckboxWrapper>
							</fieldset>

							<div>
								<label
									className="text-h6 w-full font-bold my-2 relative"
									htmlFor="name"
								>
									{t('subscribepage/name')}
								</label>
								<input
									placeholder={t('subscribepage/yourname')}
									id="name"
									name="name"
									type="text"
									className="font-standard text-p px-3 py-2 border rounded border-greyDark w-full placeholder-greyDarkest"
								/>
								<label
									className="text-h6 w-full font-bold my-2 relative"
									htmlFor="companyname"
								>
									{t('subscribepage/company')}
								</label>
								<input
									placeholder={t('subscribepage/yourcompanyname')}
									id="companyname"
									name="companyname"
									type="text"
									className="font-standard text-p px-3 py-2 border rounded border-greyDark w-full placeholder-greyDarkest"
								/>
								<label
									className="text-h6 w-full font-bold my-2 relative"
									htmlFor="email"
								>
									{t('subscribepage/email')}
								</label>
								<input
									placeholder={t('subscribepage/youremail')}
									id="email"
									name="email"
									type="text"
									className="font-standard text-p px-3 py-2 border rounded border-greyDark w-full placeholder-greyDarkest"
								/>
								<div className="my-4">
									<div
										className="g-recaptcha"
										data-sitekey="6LfpKWMaAAAAAHbgyXCe8thJiM3frHGzKv42o-mF"
										data-size="normal"
										data-theme="light"
									></div>
								</div>
								<Button
									type="submit"
									//@ts-ignore
									buttonColor="orange"
									id="sendForm"
								>
									{t('subscribepage/subscribe')}
								</Button>
							</div>
						</form>
						<div
							className="modal"
							id="subscriptionSuccessModal"
							tabIndex={-1}
							role="dialog"
							aria-live="polite"
						>
							<div className="mx-auto my-20 w-150">
								<div className="bg-white">
									<div className="h-17 w-full bg-blue px-5 relative">
										<h2 className="text-white pt-6 pb-5">
											{t('subscribepage/congratulations')}
										</h2>
										<button
											type="button"
											className="absolute w-10 h-10 right-0 top-0 mr-5 mt-3"
											data-dismiss="modal"
											aria-label="Close"
										>
											<Icon icon="close" size={2} color="#ffffff" />
										</button>
									</div>
									<div className="py-8 px-5">
										<p>{t('subscribepage/congratulationstext')}</p>
									</div>
								</div>
							</div>
						</div>
						<div
							className="modal"
							id="subscriptionFailedModal"
							tabIndex={-1}
							role="dialog"
							aria-live="polite"
						>
							<div className="mx-auto my-20 w-150">
								<div className="bg-white">
									<div className="h-17 w-full bg-blue px-5 relative">
										<h2 className="text-white pt-6 pb-5">
											{t('subscribepage/error')}
										</h2>
										<button
											type="button"
											className="absolute w-10 h-10 right-0 top-0 mr-5 mt-3"
											data-dismiss="modal"
											aria-label="Close"
										>
											<Icon icon="close" size={2} color="#ffffff" />
										</button>
									</div>
									<div className="py-8 px-5">
										<p>{t('subscribepage/errortext')}</p>
									</div>
								</div>
							</div>
						</div>
					</Cell>
				</Grid>
			</Layout>
		</main>
	);
};

export default SubscribePage;
