/**
 * SaveCartModal
 * @module components/SaveCartModal
 */

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { LocalizationContext } from 'context/localization.context';
import Modal from 'components/ui/Modal';
import { Label, InputField, Wrapper, SaveButton } from './SaveCartModal.styles';
import Icon from 'components/ui/Icon';
import clsx from 'clsx';

const SaveCartModal = ({ onToggle, onSave }) => {
	const { t } = useContext(LocalizationContext);
	const [cartName, setCartName] = useState('');
	const [shared, setShared] = useState(false);
	return (
		<Modal
			heading={t('cartpage/savecart')}
			title={t('cartpage/savecart')}
			width="small"
			toggleModal={() => {
				onToggle && onToggle();
			}}
		>
			<Label as="label" size="large" htmlFor="cartname">
				{t('cartpage/cartname')}
			</Label>
			<InputField
				id="cartname"
				value={cartName}
				onChange={(e) => setCartName(e.target.value)}
				required
			></InputField>
			<label className="flex items-baseline cursor-pointer relative">
				<input
					type="checkbox"
					id="saveSelectedAddress"
					className="relative opacity-0"
					onChange={() => setShared(!shared)}
				/>
				<Icon
					icon={shared ? 'checkSquare' : 'square'}
					size={1.25}
					className={clsx(
						'absolute left-0 focused-sibling:outline-black ',
						shared ? 'text-blue' : 'text-greyDarkest'
					)}
					aria-hidden={true}
				/>
				<span className="ml-4 text-p">{t('cartpage/sharedcart')}</span>
			</label>
			<Wrapper>
				<SaveButton
					type="button"
					buttonColor="blue"
					disabled={cartName === ''}
					onClick={() => {
						onSave && onSave(cartName, shared);
					}}
				>
					{t('cartpage/savecart')}
				</SaveButton>
			</Wrapper>
		</Modal>
	);
};

SaveCartModal.propTypes = {
	onToggle: PropTypes.func,
	onSave: PropTypes.func,
};

export default SaveCartModal;
