/**
 * PromoBlock
 * @module components/PromoBlock
 */

import React from 'react';
import {
	PromoBlockContent,
	ContentHeading,
	PromoButtonWrapper,
	PromoButton,
} from './PromoBlock.styles';

import RichText from 'components/ui/RichText';

import { FiChevronsRight } from 'react-icons/fi';
import { getHeadingLevel } from 'common/helpers';
import { useDispatch } from 'react-redux';
import { fetchConfArticle } from 'store/modules/configurator';
import { PromoBlockModel } from 'types/block-types';
import { BackgroundColorTypes } from 'types/common';
import clsx from 'clsx';

export interface PromoContentModel extends PromoBlockModel {
	theme?: BackgroundColorTypes;
	imageOutside?: boolean;
	contentLeft?: boolean;
	fullWidth?: boolean;
	videoPreference?: string;
}

const PromoContent = (props: PromoContentModel) => {
	const {
		theme,
		imageOutside,
		contentLeft,
		heading,
		text,
		buttons,
		fullWidth,
		confArticle,
		toggleModal,
		headingLevel,
		headingStyleLevel,
		videoPreference,
		toggleImageGallery,
		toggleOnImage,
	} = props;

	const dispatch = useDispatch();

	const handleClick = async (
		e: MouseEvent,
		url: string,
		index: number,
		imageGallery: any
	) => {
		try {
			if (confArticle && index === 0) {
				e.preventDefault();
				toggleModal && toggleModal();
				await dispatch(fetchConfArticle(url));
			}

			if (!toggleOnImage && toggleImageGallery && imageGallery) {
				e.preventDefault();
				toggleImageGallery();
			}
		} catch (err) {
			console.log(err);
		}
	};

	const headingCss =
		headingStyleLevel === 1
			? 'text-h2 md:text-h1'
			: `text-${getHeadingLevel(headingStyleLevel)}`;

	return (
		<PromoBlockContent
			videoPreference={videoPreference}
			theme={theme}
			outside={imageOutside}
			fullWidth={fullWidth}
			contentLeft={contentLeft}
		>
			{heading && (
				<ContentHeading
					fullWidth={fullWidth}
					theme={theme}
					as={getHeadingLevel(headingLevel)}
					className={headingCss}
				>
					{heading}
				</ContentHeading>
			)}
			{text && (
				<RichText
					className={clsx(
						'text-p font-light mb-6',
						theme === 'blue' ? 'text-white' : 'text-black'
					)}
					text={text}
				/>
			)}
			{buttons && (
				<PromoButtonWrapper fullWidth={fullWidth}>
					{buttons.map((button, i) => {
						return (
							<PromoButton
								key={`promobutton-${i}`}
								type={'link'}
								buttonColor={button.color}
								darkBackground={theme === 'blue'}
								url={button.link}
								styling={'button'}
								target={button.target}
								fullWidth={fullWidth}
								onClick={(e: MouseEvent) =>
									handleClick(e, button.link, i, button.imageGallery)
								}
							>
								<FiChevronsRight />
								<span>{button.text}</span>
							</PromoButton>
						);
					})}
				</PromoButtonWrapper>
			)}
		</PromoBlockContent>
	);
};

export default PromoContent;
