import frameworkData from 'data/static/framework';
import translationsData from 'data/static/translations';

/**
 * Returns page data based on pathname
 * ! Only used for frontend mockup data
 */
export const getPageData = () => {
	let path = window.location.pathname.slice(1);

	if (path === '') {
		path = 'start';
	} else if (path === 'styleguide') {
		return;
	}

	let data = {
		framework: {
			...frameworkData,
			localization: translationsData,
		},
	};

	return import(`data/pages/${path}`)
		.then(pageData => {
			data = { ...data, ...pageData.default };
			return data;
		})
		.catch(() => {
			return import(`data/pages/404`).then(pageData => {
				data = { ...data, ...pageData.default };
				return data;
			});
		});
};
