import styled from 'styled-components';
import { grey, black, greyDarker } from 'theme/colors';
import { FaTimesCircle } from 'react-icons/fa';
import { spacing05x, spacing1x } from 'theme/spacings';
import { fontWeightBold, fontWeightLight } from 'theme/typography';
import { Paragraph } from '../Typography';

export const RemoveIcon = styled(FaTimesCircle)`
	font-size: 1.3rem;
	display: inline-block;
	vertical-align: middle;
	color: ${greyDarker};
`;

export const Item = styled.li`
	background-color: ${grey};
	padding: 0 ${spacing05x} 0 ${spacing05x};
	border: 0.2rem solid ${grey};
	border-radius: 0.3rem;
	list-style: none;
	display: inline-block;
	margin-right: ${spacing1x};

	&:hover ${RemoveIcon} {
		color: ${black};
	}
`;

export const Button = styled.a`
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
`;

export const Name = styled(Paragraph)`
	font-size: 1.3rem;
	display: inline-block;
	margin: 0;
	vertical-align: middle;
	color: ${black};
	font-weight: ${fontWeightLight};
`;

export const Option = styled(Name)`
	font-weight: ${fontWeightBold};
	margin-left: ${spacing05x};
	margin-right: ${spacing05x};
`;
