/**
 * PromoBlock
 * @module components/PromoBlock
 */

import PromoFullWidthImage from './PromoFullWidthImage';
import PromoImageOutside from './PromoImageOutside';
import LazyLoad from 'react-lazyload';
import { ImageGalleryBlockModel, PromoBlockModel } from 'types/block-types';

import PromoVideoFull from './PromoVideoFull';
import PromoVideoOutside from './PromoVideoOutside';
import { useState } from 'react';
import { ImageGalleryBlockModal } from '../ImageGalleryBlock';

interface PromoBlockWrapperModel {
	block: PromoBlockModel;
}

const PromoBlock = ({ block }: PromoBlockWrapperModel) => {
	const [showImageGallery, setShowImageGallery] = useState(false);
	const [imageGallery, setImageGallery] = useState<ImageGalleryBlockModel>();
	const [activeItem, setActiveItem] = useState(
		imageGallery?.imageGalleryBlockItems[0] ?? null
	);
	const [toggleOnImage, setToggleOnImage] = useState(false);

	// Check if any buttons have an image gallery attached to it

	if (block && block.buttons) {
		block.buttons.forEach((button) => {
			if (button.imageGallery && !imageGallery) {
				setImageGallery(button.imageGallery);
				setToggleOnImage(false);
			}
		});
	}

	// Check if any media has image gallery

	if (block && block.media && block.media.imageGallery && !imageGallery) {
		setImageGallery(block.media.imageGallery);
		setToggleOnImage(true);
	}

	block.toggleImageGallery = () => setShowImageGallery(!showImageGallery);
	block.toggleOnImage = toggleOnImage;

	const showPreviousMedia = (e: any) => {
		e.preventDefault();
		if (
			imageGallery?.imageGalleryBlockItems &&
			imageGallery?.imageGalleryBlockItems.length > 0
		) {
			const currentItemIndex =
				activeItem && imageGallery?.imageGalleryBlockItems.indexOf(activeItem);
			if (currentItemIndex !== null && currentItemIndex > 0) {
				const previousItemIndex = currentItemIndex - 1;
				setActiveItem(imageGallery?.imageGalleryBlockItems[previousItemIndex]);
			} else {
				setActiveItem(
					imageGallery?.imageGalleryBlockItems[
						imageGallery?.imageGalleryBlockItems.length - 1
					]
				);
			}
		}
	};

	const showNextMedia = (e: any) => {
		e.preventDefault();
		if (
			imageGallery?.imageGalleryBlockItems &&
			imageGallery?.imageGalleryBlockItems.length > 0
		) {
			const currentItemIndex =
				activeItem && imageGallery?.imageGalleryBlockItems.indexOf(activeItem);

			if (
				currentItemIndex !== null &&
				currentItemIndex < imageGallery?.imageGalleryBlockItems.length - 1
			) {
				const nextItemIndex = currentItemIndex + 1;
				setActiveItem(imageGallery?.imageGalleryBlockItems[nextItemIndex]);
			} else {
				setActiveItem(imageGallery?.imageGalleryBlockItems[0]);
			}
		}
	};

	!activeItem &&
		imageGallery &&
		setActiveItem(imageGallery.imageGalleryBlockItems[0]);

	return (
		<>
			{block.media?.image &&
			block.media?.imagePreference?.includes('fullWidthBackgroundImage') ? (
				<PromoFullWidthImage {...block} />
			) : block.media?.video ? (
				block.media?.videoPreference?.includes('videoFull') ? (
					<PromoVideoFull {...block} />
				) : (
					<PromoVideoOutside {...block} />
				)
			) : (
				<PromoImageOutside {...block} />
			)}

			{imageGallery && showImageGallery && activeItem && (
				<ImageGalleryBlockModal
					image={activeItem.image}
					onToggle={() => setShowImageGallery(!showImageGallery)}
					showPreviousMedia={(e: MouseEvent) => showPreviousMedia(e)}
					showNextMedia={(e: MouseEvent) => showNextMedia(e)}
					items={imageGallery.imageGalleryBlockItems}
					setActiveItem={(e: any) => setActiveItem(e)}
					translations={imageGallery.translations}
				/>
			)}
		</>
	);
};

export default PromoBlock;
