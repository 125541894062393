import styled from 'styled-components';
import { deviceSmallMax, deviceLargeMax } from 'theme/mediaQueries';
import { spacing10x, spacing3x, spacing2x } from 'theme/spacings';

export const Content = styled.div`
	margin: 0 auto;
	max-width: ${deviceLargeMax};

	@media (max-width: ${deviceLargeMax}) {
		margin: 0 ${spacing10x};
	}

	@media (max-width: 1220px) {
		margin: 0 ${spacing3x};
	}

	@media (max-width: ${deviceSmallMax}) {
		margin: 0 ${spacing2x};
	}
`;
