import styled, { css } from 'styled-components';
import { Paragraph } from 'components/ui/Typography';
import { fontWeightBold } from 'theme/typography';
import { black, grey, blue } from 'theme/colors';
import { spacing05x, spacing1x } from 'theme/spacings';

const icons = css`
	& svg {
		color: ${black};
		vertical-align: middle;
		font-size: 1.6rem;
		margin-bottom: 0.5rem;
	}
`;

export const Heading = styled(Paragraph)`
	font-weight: ${fontWeightBold};
	text-align: left;
`;

export const List = styled.ul`
	list-style: none;
	padding: 0;
	margin-top: 0;
`;

export const Item = styled.li`
	${icons};
	:nth-of-type(odd) {
		background-color: ${grey};
	}
	padding: ${spacing05x} ${spacing1x};
`;

export const Link = styled(Paragraph)`
	text-decoration: none;
	color: ${blue};
	:hover {
		text-decoration: underline;
	}
	margin-left: ${spacing1x};
`;
