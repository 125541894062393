/**
 * Field
 */

import React, { useState, useRef, useEffect } from 'react';
import { useFieldValidation } from '../hooks/useFieldValidation';

import { useFormValue } from '../context/Form.context';
import { Input } from '../Form.styles';

const Field = (props) => {
	const {
		className,
		placeholder,
		description,
		onFieldChange,
		defaultValue,
		autoSubmit,
		name,
		id,
		showError,
		setFocus,
		visibleFields,
		noValidate,
		type,
		label,
		describedby,
		required,
		resetForm,
		patternMessage,
		validationMessage,
		isValid,
		onBlur,
		translations,
		...allProps
	} = props;

	const [{ invalidFields }, dispatch] = useFormValue();
	const [value, setValue] = useState(defaultValue || '');
	const [valid, setValid] = useState(false);
	const [touched, setTouched] = useState(false);
	const fieldRef = useRef(null);
	const [validateField, showFieldError, hideFieldError] = useFieldValidation(
		fieldRef,
		props,
		translations
	);

	useEffect(() => {
		const validatedField = validateField(value);
		const fieldIsValid = !validatedField.message;
		console.log(validatedField);
		if (validatedField.message) {
			setValid(false);
			dispatch({ type: 'FIELD_INVALID', field: validatedField });
			if (isValid) {
				isValid(false, id);
			}
		} else {
			setValid(true);

			if (invalidFields.includes(validatedField.id)) {
				dispatch({ type: 'FIELD_VALID', field: validatedField });
			}
			if (isValid) {
				isValid(true, id);
			}
		}

		if (!noValidate) {
			if (touched && !fieldIsValid) {
				showFieldError(validatedField);
			} else if (
				(showError &&
					!fieldIsValid &&
					visibleFields &&
					visibleFields.includes(id)) ||
				(showError && !fieldIsValid && !visibleFields)
			) {
				setTouched(true);
				showFieldError(validatedField);
			} else {
				hideFieldError();
			}

			if (setFocus) {
				fieldRef.current.focus();
			}
		}
		//eslint-disable-next-line
	}, [touched, showError, value, setFocus, visibleFields, required]);

	const onChange = (e) => {
		const target = e.target;

		setValue(target.value);
		onFieldChange({
			name,
			value: target.value,
			type: 'Field',
			autoSubmit,
		});
	};

	const describedByAttribute = `form${id}__desc${
		describedby ? ` ${describedby}` : ''
	}`;

	return (
		<Input
			className={className || ''}
			title={description}
			placeholder={placeholder || ''}
			aria-invalid={!valid}
			value={value}
			name={name}
			required={required}
			onChange={onChange}
			onBlur={() => {
				setTouched(true);
				if (onBlur) onBlur({ [name]: value }, valid);
			}}
			ref={fieldRef}
			id={id}
			aria-describedby={!noValidate ? describedByAttribute : undefined}
			type={type === null ? 'text' : type === 'fileupload' ? 'file' : type}
			{...allProps}
		/>
	);
};

export default Field;
