/**
 * RteWithImageBlock
 * @module components/RteWithImageBlock
 */

import Layout from 'components/ui/Layout';
import { RteWithImageBlockModel } from 'types/block-types';
import clsx from 'clsx';

const RteWithImageBlock = (block: RteWithImageBlockModel) => {
	const {
		name,
		image,
		text,
		backgroundColor,
		isLandscapeImage,
		isLandscapeImageBig,
	} = block;

	return (
		<Layout
			className={clsx(isLandscapeImageBig ? 'pt-0' : 'pt-12')}
			backgroundColor={backgroundColor}
		>
			<article
				className={clsx(
					'block md:flex max-w-full m-0',
					backgroundColor === 'blue' && isLandscapeImageBig && 'bigLandscape',
					isLandscapeImageBig && 'md:max-w-full',
					!isLandscapeImageBig && 'md:max-w-2/3 pb-3'
				)}
			>
				{image && image.src && (
					<div
						className={clsx(
							isLandscapeImageBig
								? 'h-full w-full md:w-1/2 inline-block relative'
								: isLandscapeImage
								? 'w-full h-auto min-w-40 min-h-25 md:w-65 md:min-w-100 md:min-h-43 md:h-42.5'
								: 'w-25 min-w-25 h-40 min-h-40 md:w-42.5 md:min-w-42.5 md:h-65 md:min-h-65',
							'mr-4'
						)}
					>
						<img
							className={clsx(
								'h-full object-cover',
								isLandscapeImageBig ? 'my-0 mx-auto h-auto w-3/4' : 'w-full'
							)}
							src={image.src}
							alt={image.alt || ''}
						/>
					</div>
				)}
				<div
					className={clsx(
						'w-full md:w-1/2 inline-block relative',
						isLandscapeImageBig && 'pt-5'
					)}
				>
					{name && (
						<h2
							className={clsx(
								'text-h3 mb-1',
								backgroundColor === 'blue' && 'text-white'
							)}
						>
							{name}
						</h2>
					)}
					{text && (
						<div
							className={clsx(
								'richtext',
								backgroundColor === 'blue' ? 'text-white' : 'text-black'
							)}
							dangerouslySetInnerHTML={{ __html: text }}
						/>
					)}
				</div>
			</article>
		</Layout>
	);
};

export default RteWithImageBlock;
