import styled from 'styled-components';
import { spacing3x } from 'theme/spacings';
import { deviceSmallMax } from 'theme/mediaQueries';

export const Body = styled.div`
	max-width: 60%;
	padding-bottom: ${spacing3x};
	overflow-y: auto;

	@media (max-width: ${deviceSmallMax}) {
		max-width: 100%;
	}
`;

export const VisuallyHiddenCaption = styled.caption`
	border: 0;
	clip: rect(0 0 0 0);
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.1rem;
`;
