import { useFetch } from 'api';

const getHash = (elements) => {
	if (!elements) return {};

	return [].reduce.call(
		elements,
		(data, element) => {
			if (element.value) {
				data[element.name] = element.value;
			}
			return data;
		},
		{}
	);
};

export const useChangePassword = ({ url, form, antiForgery }) => {
	const preventRequest = typeof url === 'undefined' || url === '';
	let body = {};
	if (window && window.FormData) {
		body = new FormData(form);
		body.append('__RequestVerificationToken', antiForgery);
	}
	let formValues = getHash(form && form.elements);
	const hash = JSON.stringify(formValues);

	return useFetch({
		url: `${url}`,
		responseDefault: {},
		method: 'post',
		preventRequest: preventRequest,
		disableCancel: true,
		data: body,
		datHash: hash,
	});
};
