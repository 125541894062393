/**
 * ProductDivisionPage
 * @module pages/ProductDivisionPage
 */

import React from 'react';
import Layout from 'components/ui/Layout';
import ContentArea from 'components/framework/ContentArea';
import Breadcrumbs from 'components/framework/Breadcrumbs';
import ImageBlock from 'components/framework/ImageBlock';
import { Grid, Cell } from 'components/ui/Grid';
import CustomExternalScript from 'components/framework/CustomExternalScript';
import SectionHeader from 'components/ui/SectionHeader';

const ProductDivisionPage = ({
	heading,
	breadcrumbs,
	isInEditMode,
	text,
	categories,
	contentArea,
}) => (
	<main id="main-content">
		<Layout backgroundColor="white">
			<Breadcrumbs links={breadcrumbs} />
			<SectionHeader
				heading={heading}
				text={text}
				size={'large'}
				isInEditMode={false}
			/>
			<CustomExternalScript />
			<Grid padding={false}>
				{categories.map((item, index) => {
					return (
						<Cell span={12} tablet={6} desktop={4} className="my-5" key={index}>
							<ImageBlock
								link={item.link}
								heading={item.heading}
								preamble={item.preamble}
								image={item.image}
								highlight={item.highlight}
								headingSize="large"
							/>
						</Cell>
					);
				})}
			</Grid>
		</Layout>

		<ContentArea contentArea={contentArea} />
	</main>
);

export default ProductDivisionPage;
