import styled from 'styled-components';
import { FiMenu, FiX } from 'react-icons/fi';
import { deviceSmallMax } from 'theme/mediaQueries';
import { blue } from 'theme/colors';

export const MenuIcon = styled(FiMenu)`
	color: ${blue};
	font-size: 3.2rem;

	@media (max-width: ${deviceSmallMax}) {
		display: inline-block;
		font-size: 2.4rem;
	}
`;
export const XIcon = styled(FiX)`
	color: ${blue};
	font-size: 3.2rem;

	@media (max-width: ${deviceSmallMax}) {
		display: inline-block;
		font-size: 2.4rem;
	}
`;

export const MenuLink = styled.a`
	text-decoration: none;
`;

export const VisuallyHiddenSpan = styled.span`
	border: 0;
	clip: rect(0 0 0 0);
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.1rem;
`;
