import { useEffect } from 'react';

//  This hook checks if the url contains a hash and if so scrolls to that position, this is to fix a bug with anchorlinks on chrome on some mobile devices.
export function useAnchorLinkNavigation() {
	useEffect(() => {
		if (window && window.navigator && window.document) {
			let navigator = window.navigator;
			var isChrome =
				/Chrome/.test(navigator.userAgent) &&
				/Google Inc/.test(navigator.vendor);
			if (window.location.hash && isChrome) {
				setTimeout(function() {
					var hash = window.location.hash;
					window.location.hash = '';
					window.location.hash = hash;
				}, 300);
			}
		}
	}, []);
}
