/**
 * Ticker
 * @module components/Ticker
 */

import React from 'react';
import {
	TickerWrapper,
	Date,
	PriceWrapper,
	Delta,
	ArrowDownRight,
	ArrowRight,
	ArrowUpRight,
} from './Ticker.styles';

interface Props {
	/** The Ticker date  */
	date?: string;

	/** The Ticker price  */
	price?: number;

	/** The Ticker trade currency  */
	tradeCurrency?: string;

	/** The Ticker delta value  */
	delta?: number;
}

const Ticker = ({ date, price, tradeCurrency, delta }: Props): JSX.Element => {
	return (
		<TickerWrapper>
			{date && <Date>{date}</Date>}
			<PriceWrapper>
				{price && <span>{`${price.toFixed(2)} ${tradeCurrency}`}</span>}
				{delta && delta < 0 ? (
					<Delta deltaColor="errorRed">
						{delta.toFixed(2)}
						<ArrowDownRight aria-hidden={true} />
					</Delta>
				) : delta && delta > 0 ? (
					<Delta deltaColor="green">
						{delta.toFixed(2)}
						<ArrowUpRight aria-hidden={true} />
					</Delta>
				) : delta && delta == 0 ? (
					<Delta deltaColor="blue">
						{delta.toFixed(2)}
						<ArrowRight aria-hidden={true} />
					</Delta>
				) : (
					false
				)}
			</PriceWrapper>
		</TickerWrapper>
	);
};

export default Ticker;
