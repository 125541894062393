import React from 'react';
import ArticlePage from './ArticlePage';
import StartPage from './StartPage';
import CatalogPage from './CatalogPage';
import ErrorPage404 from './ErrorPage404';
import ProductDivisionPage from './ProductDivisionPage';
import ProductCategoryPage from './ProductCategoryPage';
import ProductDetailPage from './ProductDetailPage';
import CheckoutPage from './CheckoutPage';
import CartPage from './CartPage';
import OrderConfirmationPage from './OrderConfirmationPage';
import LoginPage from './LoginPage';
import SearchPage from './SearchPage';
import MyPage from './MyPage';
import MyProjectsPage from './MyProjectsPage';
import MyAccountPage from './MyAccountPage';
import MyAddressesPage from './MyAddressesPage';
import OrderDetailsPage from './OrderDetailsPage';
import QuotationDetailsPage from './QuotationDetailsPage';
import MyNewsDeskItemPage from './MyNewsDeskItemPage';
import MyNewsDeskListPage from './MyNewsDeskListPage';
import OrderHistoryPage from './OrderHistoryPage';
import QuotationHistoryPage from './QuotationHistoryPage';
import SavedCartPage from './SavedCartPage';
import MySavedCartsPage from './MySavedCartsPage';
import ContactPage from './ContactPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import ResetPasswordPage from './ResetPasswordPage';
import CisionPressReleaseListPage from './CisionPressReleaseListPage';
import CisionPressReleasePage from './CisionPressReleasePage';
import CisionFinancialReportsPage from './CisionFinancialReportsPage';
import CisionOwnershipPage from './CisionOwnershipPage';
import CalendariumListPage from './CalendariumListPage';
import CalendariumPage from './CalendariumPage';
import SubscribePage from './SubscribePage';
import CisionStockDiagramPage from './CisionStockDiagramPage';
import OrderMatrixLandingPage from './OrderMatrixLandingPage';
import DocumentSearchPage from './DocumentSearchPage';

const Pages = {
	ArticlePage,
	StartPage,
	CatalogPage,
	ErrorPage404,
	ProductDivisionPage,
	ProductCategoryPage,
	ProductDetailPage,
	CheckoutPage,
	CartPage,
	OrderConfirmationPage,
	LoginPage,
	SearchPage,
	MyPage,
	MyProjectsPage,
	MyAccountPage,
	MyAddressesPage,
	OrderDetailsPage,
	QuotationDetailsPage,
	MyNewsDeskItemPage,
	MyNewsDeskListPage,
	OrderHistoryPage,
	QuotationHistoryPage,
	SavedCartPage,
	MySavedCartsPage,
	ContactPage,
	ForgotPasswordPage,
	ResetPasswordPage,
	CisionPressReleaseListPage,
	CisionPressReleasePage,
	CisionFinancialReportsPage,
	CisionOwnershipPage,
	CalendariumListPage,
	CalendariumPage,
	SubscribePage,
	CisionStockDiagramPage,
	OrderMatrixLandingPage,
	DocumentSearchPage,
	NotImplemented: () => (
		<p>The requested page type has not yet been implemented.</p>
	),
};

export const get = (model) => {
	if (model) {
		let page = Pages[model.modelType];

		if (page) {
			return page;
		}
	}

	return Pages['NotImplemented'];
};
