/**
 * MobileMenu
 * @module components/MobileMenu
 */

import React, { useState, useContext, useEffect } from 'react';
import {
	MenuLink,
	MenuIcon,
	XIcon,
	VisuallyHiddenSpan,
} from './MobileMenu.styles';
import { LocalizationContext } from 'context/localization.context';
import { ViewContext } from 'context/view.context';
import useWindowSize from 'hooks/useWindowSize';

const MobileMenu = (props) => {
	const { onChange } = props;
	const [expanded, setExpanded] = useState(false);
	const { t } = useContext(LocalizationContext);
	const { view } = useContext(ViewContext);
	const toggleMenu = () => {
		setExpanded(!expanded);
	};

	useEffect(() => {
		if (onChange) {
			onChange(expanded);
		}
	}, [onChange, expanded]);

	const isMobile = useWindowSize(1150);

	return (
		<MenuLink
			href="/"
			aria-expanded={expanded}
			aria-controls={'mobilenav'}
			onClick={(e) => {
				e.preventDefault();
				toggleMenu();
			}}
		>
			{expanded ? (
				<span>
					<XIcon
						style={{
							display:
								(isMobile || view === 'mobile') && view !== 'desktop'
									? 'inline-flex'
									: 'none',
						}}
					/>
					<VisuallyHiddenSpan>
						{t('shared/header/closemenu')}
					</VisuallyHiddenSpan>
				</span>
			) : (
				<span>
					<MenuIcon
						style={{
							display:
								(isMobile || view === 'mobile') && view !== 'desktop'
									? 'inline-flex'
									: 'none',
						}}
					/>
					<VisuallyHiddenSpan>{t('shared/header/showmenu')}</VisuallyHiddenSpan>
				</span>
			)}
		</MenuLink>
	);
};

export default MobileMenu;
