import { CellLink } from './table.styles';

export const focusCell = (newrow, newcol) => {
	let tgt = document.querySelector(
		'[data-row="' + newrow + '"][data-col="' + newcol + '"]'
	);
	if (tgt && tgt.getAttribute('role') === 'cell') {
		document
			.querySelectorAll('[role=cell]')
			.forEach((el) => el.setAttribute('tabindex', '-1'));

		tgt.setAttribute('tabindex', '0');
		tgt.focus();
		return true;
	} else {
		return false;
	}
};

export const focusTableHead = (colIndex) => {
	document
		.querySelectorAll('[role=cell]')
		.forEach((el) => el.setAttribute('tabindex', '-1'));
	const tableHead = document.querySelector(
		'[data-header-col="' + colIndex + '"]'
	);
	tableHead && tableHead.focus();
};

const handleCtrlHome = (e, currentRow) => {
	if (e.ctrlKey) {
		focusCell(0, 0);
	} else {
		focusCell(currentRow, 0);
	}
};

const handleCtrlEnd = (e, maxRow, maxColumn, currentRow) => {
	maxRow = maxRow - 1;
	maxColumn = maxColumn - 1;
	if (e.ctrlKey) {
		focusCell(maxRow, maxColumn);
	} else {
		focusCell(currentRow, maxColumn);
	}
};

export const handleKeyboardNavigation = (
	e,
	maxRows,
	maxColumns,
	currentRow
) => {
	switch (e.key) {
		case 'ArrowRight':
			focusCell(
				parseInt(e.target.getAttribute('data-row')),
				parseInt(e.target.getAttribute('data-col')) + 1
			);
			break;
		case 'ArrowLeft':
			focusCell(
				parseInt(e.target.getAttribute('data-row')),
				parseInt(e.target.getAttribute('data-col')) - 1
			);
			break;
		case 'ArrowDown':
			focusCell(
				parseInt(e.target.getAttribute('data-row')) + 1,
				parseInt(e.target.getAttribute('data-col'))
			);
			break;
		case 'ArrowUp':
			const success = focusCell(
				parseInt(e.target.getAttribute('data-row') - 1),
				parseInt(e.target.getAttribute('data-col'))
			);
			if (!success) {
				focusTableHead(parseInt(e.target.getAttribute('data-col')));
			}
			break;
		case 'Home':
			handleCtrlHome(e, currentRow);
			break;
		case 'End':
			handleCtrlEnd(e, maxRows, maxColumns, currentRow);
			break;
		case 'PageDown':
			focusCell(maxRows - 1, 0);
			break;
		case 'PageUp':
			if (currentRow !== 0) {
				focusCell(0, 0);
			}
			break;
		case 'Tab':
			return true;
		default:
			break;
	}
	e.preventDefault();
};

export const handleSortByKeyDown = (
	e,
	column,
	currentRow,
	maxRows,
	maxColumns
) => {
	switch (e.key) {
		case 'Enter':
			column && column.toggleSortBy();
			break;
		case 'ArrowDown':
			focusCell(0, currentRow);
			break;
		case 'ArrowRight':
			focusTableHead(currentRow + 1);
			break;
		case 'ArrowLeft':
			focusTableHead(currentRow - 1);
			break;
		case 'Tab':
			return true;
		case 'Home':
			handleCtrlHome(e);
			break;
		case 'End':
			handleCtrlEnd(e, maxRows, maxColumns, currentRow);
			break;
		case 'PageDown':
			focusCell(maxRows - 1, 0);
			break;
		case 'PageUp':
			if (currentRow !== 0) {
				focusCell(0, 0);
			}
			break;
		default:
			return true;
	}
	e.preventDefault();
};

export const formatAndGetCellValue = (cell) => {
	const cellId = cell && cell.column && cell.column.id;
	const cellData = cell && cell.row && cell.row.original;
	switch (cellId) {
		case 'date':
			return cell.value;
		case 'externalId':
			return <CellLink href={cellData.link}>{cell.value}</CellLink>;
		default:
			return cell.render('Cell');
	}
};
