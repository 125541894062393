import { LocalizationContext } from 'context/localization.context';
import { useContext } from 'react';

/**
 * Custom hook for validating a field
 */
export const useFieldValidation = (fieldRef, fieldProps) => {
	const {
		required,
		pattern,
		label,
		id,
		type,
		validationMessage,
		patternMessage,
		single,
		min,
		max,
		rangeMessage,
		translations,
	} = fieldProps;

	const validateField = (value) => {
		let field = { id, label };

		const fieldIsNotValid = value !== '' && !fieldRef.current.checkValidity();
		const requiredFieldIsNotFilledIn =
			(required && value === '') ||
			(required && value === 'fieldset' && !fieldsetHasOneCheckedInput());
		const fieldDoesNotMatchPattern =
			pattern && !value.toString().match(pattern) && value !== '';
		const singleInputIsNotChecked = single && required && !value;
		const fieldIsNotWithinRange = (value - min) * (value - max) > 0;

		if (type === 'url' && fieldIsNotValid) {
			field.message =
				validationMessage ||
				translations['/frontend/shared/form/urlvalidationmessage'];
		} else if (type === 'email' && fieldIsNotValid) {
			field.message =
				patternMessage ||
				validationMessage ||
				translations['/frontend/shared/form/emailvalidationmessage'];
		} else if (requiredFieldIsNotFilledIn || singleInputIsNotChecked) {
			field.message =
				validationMessage ||
				translations['/frontend/shared/form/requiredfieldmessage'];
		} else if (pattern && fieldDoesNotMatchPattern) {
			field.message =
				patternMessage ||
				translations['/frontend/articledetailconfpage/patternmessage'];
		} else if (rangeMessage && fieldIsNotWithinRange) {
			field.message =
				rangeMessage ||
				translations['/frontend/articledetailconfpage/patternmessage'];
		}

		return field;
	};

	/**
	 * Checks if any input (radio/checkbox) within the fieldset group has been checked
	 */
	const fieldsetHasOneCheckedInput = () => {
		const allInputs = fieldRef.current.querySelectorAll('input');
		let hasOneValidInput = false;

		allInputs.forEach((field) => {
			if (field.checked) {
				hasOneValidInput = true;
			}
		});

		return hasOneValidInput;
	};

	/**
	 * Displays a field error with message
	 */
	const showFieldError = (field) => {
		let label = document.querySelector(`#form${id}__desc`);
		fieldRef.current.classList.add('invalid');

		if (label) {
			label.textContent = field.message;
			label.removeAttribute('hidden');
		}
	};

	/**
	 * Hides field error and clears message
	 */
	const hideFieldError = () => {
		let label = document.querySelector(`#form${id}__desc`);
		fieldRef.current.classList.remove('invalid');

		if (label) {
			label.textContent = '';
			label.setAttribute('hidden', 'true');
		}
	};

	return [validateField, showFieldError, hideFieldError];
};
