import { Paragraph } from 'components/ui/Typography';
import styled from 'styled-components';
import {
	spacing05x,
	spacing1x,
	spacing2x,
	spacing3x,
	spacing4x,
	spacing5x,
	spacing9x,
} from 'theme/spacings';
import { blue, grey, greyDarkest } from 'theme/colors';
import FilterField from 'components/ui/FilterField';
import FilterFieldSet from 'components/framework/FilterFieldSet';
import Pager from 'components/ui/Pager';
import { deviceSmallMax } from 'theme/mediaQueries';
import { FiSliders } from 'react-icons/fi';
import Button from 'components/ui/Button';

export const Wrapper = styled.div`
	display: flex;
	margin-bottom: ${spacing5x};

	@media (max-width: ${deviceSmallMax}) {
		display: block;
		margin-bottom: ${spacing2x};
	}
`;

export const TopContent = styled.div`
	width: 100%;
	padding-right: ${spacing4x};

	@media (max-width: ${deviceSmallMax}) {
		width: 100%;
		padding: 0;
	}
`;

export const LeftContent = styled.div`
	width: 50%;
	padding-right: ${spacing4x};
	position: relative;

	@media (max-width: ${deviceSmallMax}) {
		width: 100%;
		padding: 0;
		margin-top: inherit;
	}
`;

export const RightContent = styled.div`
	width: 50%;
	padding-top: ${spacing9x};

	@media (max-width: ${deviceSmallMax}) {
		display: none;
	}
`;

export const Heading = styled.h1`
	@media (max-width: ${deviceSmallMax}) {
		margin: 0;
	}
`;

export const ArticleWrapper = styled.div`
	display: flex;
	margin-bottom: ${spacing5x};

	@media (max-width: 890px) {
		display: block;
	}
`;

export const SlidersIcon = styled(FiSliders)`
	display: inherit;
	color: ${greyDarkest};
	font-size: 2.4rem;

	:focus {
		color: ${blue};
	}
`;
export const SlidersButton = styled.button`
	display: inline-block;
	border: none;
	background: none;
	position: absolute;
	right: 1.4rem;
	top: 1.5rem;
`;

export const FilterCol = styled.div`
	background-color: ${grey};
	width: 25%;
	padding: 1.2rem;
	margin-right: ${spacing2x};
	position: relative;

	@media (max-width: 890px) {
		width: 100%;
		margin: 0;
	}
`;

export const ArticleCol = styled.div`
	width: ${(props) => (props.fullWidth ? '100%' : '75%')};

	@media (max-width: 890px) {
		width: 100%;
	}
`;

export const InputField = styled(FilterField)`
	margin-top: ${spacing1x};
	margin-bottom: ${spacing3x};

	@media (max-width: 890px) {
		width: ${(props) => (props.fullWidth ? '100%' : '85%')};
		display: inline-block;
		margin: 0;
	}
`;

export const FieldSet = styled(FilterFieldSet)`
	@media (max-width: 890px) {
		margin: ${spacing2x} 0;
		display: ${({ showFieldset }) => (showFieldset ? 'inherit' : 'none')};
	}
`;

export const PagerWrapper = styled(Pager)`
	margin: 2rem 0;
`;

export const ClearFilterButton = styled(Paragraph)`
	border: none;
	background: none;
	color: ${blue};
	cursor: pointer;

	:hover {
		text-decoration: underline;
	}
`;

export const LinkButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: ${spacing5x};
`;

export const LinkButton = styled(Button)`
	margin: 0 0 ${spacing2x};
	& > svg {
		margin: 0 ${spacing05x} 0 0;
	}
	@media (max-width: ${deviceSmallMax}) {
		padding: 0.8rem 1.2rem;
	}
`;
