import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	ProjectModel,
	UserObjectModel,
	WarehouseModel,
	UserTypeModel,
} from 'types/common';
import axios, { CancelTokenSource } from 'axios';
import { AppThunk, RootState } from 'store';
import { cancelPricingRequest, cancelToken } from 'store/api/cartApi';

interface UserState {
	userObject: UserObjectModel;
	apiBaseUrl: string;
	error: string | null;
	customerLoading: boolean;
	projectLoading: boolean;
	warehouseLoading: boolean;
	typeLoading: boolean;
}

const initialState: UserState = {
	userObject: {
		customers: [],
		projects: [],
		warehouses: [],
		types: [],
	},
	apiBaseUrl: '',
	error: null,
	customerLoading: false,
	projectLoading: false,
	warehouseLoading: false,
	typeLoading: false,
};

const slice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		userStart: (
			state,
			action: PayloadAction<'customer' | 'project' | 'warehouse' | 'type'>
		) => {
			switch (action.payload) {
				case 'customer':
					state.customerLoading = true;
					break;
				case 'project':
					state.projectLoading = true;
					break;
				case 'warehouse':
					state.warehouseLoading = true;
					break;
				case 'type':
					state.typeLoading = true;
					break;
				default:
					break;
			}
		},
		userCustomer: (state, action: PayloadAction<UserObjectModel>) => {
			state.userObject = { ...state.userObject, ...action.payload };
			state.customerLoading = false;
			state.error = null;
		},
		userProjects: (state, action: PayloadAction<ProjectModel[]>) => {
			state.userObject = { ...state.userObject, projects: action.payload };
			state.projectLoading = false;
			state.error = null;
		},
		userWarehouses: (state, action: PayloadAction<WarehouseModel[]>) => {
			state.userObject = { ...state.userObject, warehouses: action.payload };
			state.warehouseLoading = false;
			state.error = null;
		},
		userTypes: (state, action: PayloadAction<UserTypeModel[]>) => {
			state.userObject = { ...state.userObject, types: action.payload };
			state.typeLoading = false;
			state.error = null;
		},
		userFailed: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.customerLoading = false;
			state.projectLoading = false;
			state.warehouseLoading = false;
		},
	},
});

export const FetchUser = (customerId: string): AppThunk => async (
	dispatch: any,
	getState: any
) => {
	const baseUrl = getState().user.apiBaseUrl;
	const { cartType } = getState().cart.cartObject;

	cancelPricingRequest();

	let data;
	dispatch(userStart('customer'));

	try {
		data = await UserApi(
			`${baseUrl}/user?type=${cartType}`,
			{
				customerId: customerId,
			},
			cancelToken
		);
		dispatch(userCustomer(data));
	} catch (err) {
		console.error(err);
		return;
	}
};

export const UpdateTypes = (id: string): AppThunk => async (
	dispatch: any,
	getState: any
) => {
	const baseUrl = getState().user.apiBaseUrl;
	const { types } = getState().user.userObject;
	const { cartType } = getState().cart.cartObject;

	cancelPricingRequest();

	dispatch(userStart('type'));

	let typeArray = JSON.parse(JSON.stringify(types));

	typeArray.forEach((item: UserTypeModel, i: number) => {
		if (item.id === id) {
			typeArray[i].selected = true;
		} else {
			typeArray[i].selected = false;
		}
	});

	try {
		await UserApi(
			`${baseUrl}/user?type=${cartType}`,
			{ userTypeId: id },
			cancelToken
		);
		dispatch(userTypes(typeArray));
	} catch (err) {
		console.error(err);
		return;
	}
};

export const UpdateProjects = (id: string): AppThunk => async (
	dispatch: any,
	getState: any
) => {
	const baseUrl = getState().user.apiBaseUrl;
	const { projects } = getState().user.userObject;
	const { cartType } = getState().cart.cartObject;

	cancelPricingRequest();

	dispatch(userStart('project'));

	let projectArray = JSON.parse(JSON.stringify(projects));

	projectArray.forEach((item: ProjectModel, i: number) => {
		if (item.id === id) {
			projectArray[i].selected = true;
		} else {
			projectArray[i].selected = false;
		}
	});

	try {
		await UserApi(
			`${baseUrl}/user?type=${cartType}`,
			{ projectId: id },
			cancelToken
		);
		dispatch(userProjects(projectArray));
	} catch (err) {
		console.error(err);
		return;
	}
};

export const UpdateWarehouses = (id: string): AppThunk => async (
	dispatch: any,
	getState: any
) => {
	const baseUrl = getState().user.apiBaseUrl;
	const { warehouses } = getState().user.userObject;
	const { cartType, withPrice } = getState().cart.cartObject;

	if (cartType !== 'slim' && !withPrice) {
		cancelPricingRequest();
	}

	dispatch(userStart('warehouse'));

	let warehouseArray = JSON.parse(JSON.stringify(warehouses));

	warehouseArray.forEach((item: ProjectModel, i: number) => {
		if (item.id === id) {
			warehouseArray[i].selected = true;
		} else {
			warehouseArray[i].selected = false;
		}
	});

	try {
		await UserApi(
			`${baseUrl}/user?type=${cartType}`,
			{ warehouseId: id },
			cancelToken
		);
		dispatch(userWarehouses(warehouseArray));
	} catch (err) {
		console.error(err);
		return;
	}
};

export const UserApi = async (
	apiUrl: string,
	userData: any,
	cancelSource: CancelTokenSource
): Promise<any> => {
	const { data } = await axios({
		method: 'PATCH',
		url: apiUrl,
		data: userData,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
		},
		cancelToken: cancelSource?.token,
	});
	return data;
};

export const selectUserObject = (state: RootState) => state.user.userObject;

export const selectUserData = (state: RootState) => state.user;

export const {
	userStart,
	userCustomer,
	userProjects,
	userTypes,
	userWarehouses,
	userFailed,
} = slice.actions;

export default slice.reducer;
