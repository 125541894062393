/**
 * ContentArea
 * @module components/ContentArea
 */
import Layout from 'components/ui/Layout';
import Carousel from 'components/common/Carousel';
import PromoBlock from 'components/common/PromoBlock';
import ColumnBlock from 'components/common/ColumnBlock';
import RteBlock from 'components/common/RteBlock';
import RteWithImageBlock from 'components/common/RteWithImageBlock';
import EpiForms from 'components/ui/EpiForms';
import PressCalendariumBlock from 'components/common/PressCalendariumBlock';
import NewsBlock from 'components/common/NewsBlock';
import {
	BlockModel,
	NewsBlockModel,
	PressCalendariumBlockModel,
	RteWithImageBlockModel,
	RteBlockModel,
	ColumnBlockModel,
	CarouselBlockModel,
	ImageGalleryBlockModel,
	IframeBlockModel,
	AccordionBlockModel,
	MediaBlockModel,
	FloatingBlockModel,
} from 'types/block-types';
import { ImageGalleryBlock } from 'components/common/ImageGalleryBlock';
import IframeBlock from 'components/common/IframeBlock';
import ConfiguratorIframeBlock from 'components/common/ConfiguratorIframeBlock';
import AccordionBlock from 'components/common/AccordionBlock';
import MediaBlock from 'components/common/MediaBlock';
import FloatingBlock from 'components/common/FloatingBlock';

const renderBlock = (block: BlockModel, i: number) => {
	switch (block.modelType) {
		case 'FormContainer':
			return (
				<Layout backgroundColor="white" key={`block-${i}`}>
					<EpiForms {...(block as any)} />
				</Layout>
			);
		case 'CarouselBlock':
			const carouselBlock = block as CarouselBlockModel;
			return (
				<Carousel
					modelType={carouselBlock.modelType}
					carouselItems={carouselBlock.carouselItems}
					autoTransition={carouselBlock.autoTransition}
					transitionTime={carouselBlock.transitionTime}
					headingLevel={carouselBlock.headingLevel}
					headingStyleLevel={carouselBlock.headingStyleLevel}
					heightLevel={carouselBlock.heightLevel}
					key={`block-${i}`}
				/>
			);
		case 'ColumnBlock':
			const columnBlock = block as ColumnBlockModel;
			return (
				<ColumnBlock
					items={columnBlock.items}
					button={columnBlock.button}
					layout={columnBlock.layout}
					headingLevel={columnBlock.headingLevel}
					columnAlignment={columnBlock.columnAlignment}
					key={`block-${i}`}
				/>
			);
		case 'PromoBlock':
			return <PromoBlock block={block} key={`block-${i}`} />;
		case 'RteBlock':
			return (
				<RteBlock
					backgroundColor={(block as RteBlockModel).backgroundColor}
					text={(block as RteBlockModel).text}
					key={`block-${i}`}
				/>
			);
		case 'RteWithImageBlock':
			return (
				<RteWithImageBlock
					{...(block as RteWithImageBlockModel)}
					key={`block-${i}`}
				/>
			);
		case 'PressCalendariumBlock':
			return (
				<PressCalendariumBlock
					{...(block as PressCalendariumBlockModel)}
					key={`block-${i}`}
				/>
			);
		case 'NewsBlock':
			return <NewsBlock {...(block as NewsBlockModel)} key={`block-${i}`} />;
		case 'ImageGalleryBlock':
			const imageGalleryBlock = block as ImageGalleryBlockModel;
			return (
				<ImageGalleryBlock
					columnAlignment={imageGalleryBlock.columnAlignment}
					button={imageGalleryBlock.button}
					items={imageGalleryBlock.imageGalleryBlockItems}
					key={`block-${i}`}
					translations={imageGalleryBlock.translations}
				/>
			);
		case 'FrameBlock':
			const iframeBlock = block as IframeBlockModel;
			switch (iframeBlock.type) {
				case 'BLConfig':
					return (
						<ConfiguratorIframeBlock
							title={iframeBlock.title}
							url={iframeBlock.url}
							translations={iframeBlock.translations}
						/>
					);

				default:
					return (
						<IframeBlock
							title={iframeBlock.title}
							url={iframeBlock.url}
							width={iframeBlock.width}
							height={iframeBlock.height}
						/>
					);
			}
		case 'AccordionBlock':
			const accordionBlock = block as AccordionBlockModel;
			return (
				<AccordionBlock
					key={`block-${i}`}
					id={`block-${i}`}
					title={accordionBlock.title}
				>
					{accordionBlock.contentArea.map((content, index) =>
						renderBlock(content, index)
					)}
				</AccordionBlock>
			);
		case 'MediaBlock':
			const mediaBlock = block as MediaBlockModel;
			return <MediaBlock {...mediaBlock} key={`block-${i}`} />;
		case 'FloatingBlock':
			const floatingBlock = block as FloatingBlockModel;
			return <FloatingBlock {...floatingBlock} key={`block-${i}`} />;

		default:
			return '';
	}
};

const ContentArea = ({ contentArea, className }: any) => {
	return (
		<div className={className}>
			{contentArea ? contentArea.map(renderBlock) : null}
		</div>
	);
};

export default ContentArea;
