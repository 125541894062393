import React from 'react';
import {
	Field,
	TextArea,
	Checkbox,
	RadioButton,
	Select,
	Multiselect,
	Button,
	Fieldset,
} from '../index';
import FormStep from 'components/ui/EpiForms/FormStep';

const isValidFormElement = element => {
	switch (element.type) {
		case Checkbox:
		case Field:
		case TextArea:
		case RadioButton:
		case Button:
		case Select:
		case Multiselect:
		case Fieldset:
		case FormStep:
			return true;
		default:
			return false;
	}
};

/**
 * Custom hook for cloning pand rendering children with custom props in form
 */
export const useChildClones = (showError, visibleFields) => {
	const formFieldNameCounter = {};

	const renderChildren = (
		children,
		initialValues,
		resetForm,
		onFieldChange
	) => {
		if (!children) {
			return null;
		}

		const renderedChildren = React.Children.map(children, child => {
			const props = child && child.props ? { ...child.props } : {};

			if (!React.isValidElement(child)) {
				return child;
			}

			if (isValidFormElement(child)) {
				const { name } = props;

				props.onFieldChange = onFieldChange;
				props.resetForm = resetForm;
				props.showError = showError;
				props.visibleFields = visibleFields;

				if (initialValues[name]) {
					props.defaultValue = initialValues[name];
				}

				if (!formFieldNameCounter[name]) {
					formFieldNameCounter[name] = 1;
				} else {
					formFieldNameCounter[name] += 1;
				}
			}

			if (props && props.children) {
				props.children = renderChildren(
					props.children,
					initialValues,
					resetForm,
					onFieldChange
				);
			}

			return React.cloneElement(child, props);
		});

		return renderedChildren.length === 1
			? renderedChildren[0]
			: renderedChildren;
	};

	return [renderChildren, formFieldNameCounter];
};
