/**
 * NewsListItem
 * @module components/NewsListItem
 */
import { Li, Link, Text, Title, Category } from './NewsList.styles';

interface NewsListItemModel {
	title: string;
	category: string;
	date: string;
	url: string;
}

const NewsListItem = ({ category, date, title, url }: NewsListItemModel) => (
	<Li>
		<Link href={url}>
			<Text>
				{category && <Category>{category}</Category>}
				{date && <span>{date}</span>}
			</Text>
			<Title>{title}</Title>
		</Link>
	</Li>
);

export default NewsListItem;
