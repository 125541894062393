/**
 * ResetPasswordPage
 * @module pages/ResetPasswordPage
 */

import { useRef, useState, useEffect, useContext } from 'react';
import Layout from 'components/ui/Layout';
import { LocalizationContext } from 'context/localization.context';
import ContentArea from 'components/framework/ContentArea';
import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal';
import { useChangePassword } from 'api/forgotpassword';

import {
	renderElementBasedOnEditMode,
	formElementsAreEqual,
} from 'common/helpers';
import { Cell, Grid } from 'components/ui/Grid';
import RichText from 'components/ui/RichText';
import clsx from 'clsx';
import Icon from 'components/ui/Icon';
import { ResetPasswordPageModel } from 'types/page-types';

const ResetPasswordPage = ({
	code,
	heading,
	text,
	button,
	contentArea,
	userId,
}: ResetPasswordPageModel): JSX.Element => {
	const { t }: any = useContext(LocalizationContext);
	const formRef = useRef<any>();
	const [message, setMessage] = useState({ message: '', isError: false });
	const modalDefault = { open: false, url: '' };
	const [modal, setModal] = useState(modalDefault);
	const [passwordRequest, setPasswordRequest] = useState<any>({});
	const [response, error, loading] = useChangePassword(passwordRequest);
	const [key, setKey] = useState(1);

	useEffect(() => {
		if (error) {
			setMessage({ message: t('shared/toast/servererror'), isError: true });
		} else if (!(response as any).success) {
			setMessage({ message: (response as any).responseText, isError: true });
		} else if ((response as any).success) {
			setMessage({ message: (response as any).responseText, isError: false });
			setModal({ open: true, url: (response as any).redirectUrl });
		}
		// change key to reset form inputs
		setKey(key + 1);
		setPasswordRequest({});
		//eslint-disable-next-line
	}, [error, response]);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		const antiForgeryElement = document.querySelector(
			'#antiforgery > input[name="__RequestVerificationToken"]'
		);
		const antiforgeryValue =
			antiForgeryElement && (antiForgeryElement as HTMLInputElement).value;
		const formElements = formRef.current && formRef.current.elements;
		const password = formElements['password'];
		const repeatpassword = formElements['repeatpassword'];

		if (formElementsAreEqual(password, repeatpassword)) {
			setPasswordRequest({
				url: button && button.link,
				form: formRef.current,
				antiForgery: antiforgeryValue,
			});
		} else if (!password.value || !repeatpassword.value) {
			setMessage({
				message: t('forgotpasswordpage/bothfieldsarerequired'),
				isError: true,
			});
			setPasswordRequest({});
		} else {
			setMessage({
				message: t('forgotpasswordpage/passwordsdonotmatch'),
				isError: true,
			});
			setPasswordRequest({});
		}
	};

	return (
		<>
			<main id="main-content">
				<form
					method="POST"
					action={button.link}
					onSubmit={handleSubmit}
					ref={formRef}
					key={key}
				>
					<Layout backgroundColor="white">
						{heading &&
							renderElementBasedOnEditMode(
								true,
								'heading',
								'h1',
								{
									className:
										'font-standard mb-6 mt-8 font-normal text-h2 md:text-h1 text-blue',
								},
								heading
							)}
						<Grid padding={false} className="mb-16">
							<Cell span={12} tablet={6} className="lg:pr-1/12">
								{text && (
									<RichText
										isInEditMode={true}
										name="text"
										size="large"
										text={text}
									/>
								)}
							</Cell>
							<Cell span={12} tablet={6} className="lg:pr-1/12">
								<div className="relative mb-4">
									<label
										htmlFor="password"
										className="text-p text-black font-bold block mb-2"
									>
										{t('forgotpasswordpage/password')}
									</label>
									<input
										placeholder={t('forgotpasswordpage/passwordplaceholder')}
										id="password"
										name="password"
										type="password"
										className="w-full pl-10 pr-6 py-2 border border-greyDark rounded text-p placeholder-greyDarkest"
									/>
									<Icon
										icon="lock"
										size={1.25}
										color="#949494"
										className="absolute top-0 left-0 mt-10.5 ml-3"
										aria-hidden={true}
									/>
								</div>
								<div className="relative mb-4">
									<label
										htmlFor="repeatpassword"
										className="text-p text-black font-bold block mb-2"
									>
										{t('forgotpasswordpage/repeatpassword')}
									</label>
									<input
										placeholder={t(
											'forgotpasswordpage/passwordrepeatplaceholder'
										)}
										id="repeatpassword"
										name="repeatpassword"
										type="password"
										className="w-full pl-10 pr-6 py-2 border border-greyDark rounded text-p placeholder-greyDarkest"
									/>
									<Icon
										icon="lock"
										size={1.25}
										color="#949494"
										className="absolute top-0 left-0 mt-10.5 ml-3"
										aria-hidden={true}
									/>
								</div>
								<input type="hidden" name="code" value={code} />
								<input type="hidden" name="userid" value={userId} />
								{message && message.message && (
									<p
										className={clsx(
											'text-h4 mb-4',
											message.isError ? 'text-red' : 'text-green'
										)}
									>
										{message.message}
									</p>
								)}

								{button && button.text && button.link && (
									<Button
										type="submit"
										buttonColor="blue"
										disabled={loading || false}
										className="my-2"
									>
										{button.text}
									</Button>
								)}
							</Cell>
						</Grid>
					</Layout>
					{contentArea && <ContentArea contentArea={contentArea} />}
				</form>
			</main>
			{modal.open && modal.url && (
				<Modal
					toggleModal={() =>
						setModal({ open: false, url: (response as any).redirectUrl })
					}
					width="small"
					title={t('forgotpasswordpage/passwordhasbeenreset')}
					heading={t('forgotpasswordpage/passwordhasbeenreset')}
				>
					<p className="text-p text-black mb-6">
						{t('forgotpasswordpage/passwordhasbeenresettext')}
					</p>
					<div className="text-right">
						<Button type="link" buttonColor="blue" url={modal.url}>
							{t('forgotpasswordpage/tologinpage')}
						</Button>
					</div>
				</Modal>
			)}
		</>
	);
};

export default ResetPasswordPage;
