/* eslint-disable jsx-a11y/no-access-key */
/**
 * PageHeader
 * @module components/PageHeader
 */

import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { LocalizationContext } from 'context/localization.context';

import logotype from './logotype.svg';
import {
	Heading,
	Header,
	LogoLink,
	LogoImg,
	TopBar,
	LeftWrapper,
	RightWrapper,
	MenuWrapper,
	LinkItem,
	CartWrapper,
	CartWrapperMobile,
	MobileMenuWrapper,
	MobileMegaMenuWrapper,
	MobileMenuOptionsWrapper,
	HeaderLayout,
	MegaMenuLayout,
	TickerWrapperDesktop,
	TickerWrapperMobile,
} from './PageHeader.styles';
import QuickSearch from 'components/ui/QuickSearch';
import MenuOptions from 'components/framework/MenuOptions';
import { MegaMenu } from 'components/common/MegaMenu';
import Cart from 'components/ui/Cart';
import MobilMenu from 'components/framework/MobileMenu';
import MobileMegaMenu from 'components/common/MobileMegaMenu';

import MyPagesMenuDropdown from 'components/ui/MyPagesMenuDropdown';
import LanguageSelector from '../LanguageSelector';
import LanguageSelectorOld from 'components/framework/LanguageSelector/LanguageSelectorOld';
import AnnouncementBar from '../AnnouncementBar';
import Ticker from '../Ticker';
import { useSelector } from 'react-redux';
import { selectCartObject } from 'store/modules/cart';
import { toast } from 'react-toastify';

import useWindowSize from 'hooks/useWindowSize';
import { ViewContext } from 'context/view.context';

const PageHeader = ({ header, api, includeHeading, siteType, modelType }) => {
	const { t } = useContext(LocalizationContext);
	const { view } = useContext(ViewContext);
	const {
		cartStatus,
		errorList,
		cartDisabled,
		cartHidden,
		cartLink,
	} = useSelector(selectCartObject);
	const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);
	const isMobile = useWindowSize(1150);
	const isSmallMobile = useWindowSize(768);

	useEffect(() => {
		if (cartStatus === 'invalid' && errorList && errorList?.length > 0) {
			errorList?.forEach((error) => {
				const errormsg = `Error ${error.errorCode} - ${error.message}`;
				error.errorCode !== 550 &&
					toast(errormsg, {
						type: toast.TYPE.ERROR,
					});
			});
		}
		//eslint-disable-next-line
	}, [cartStatus]);

	const {
		search,
		logo,
		megaMenu,
		myPagesLink,
		loginLink,
		logoutLink,
		warehouseTooltip,
		announcements,
		ticker,
	} = header;
	return (
		<>
			{announcements &&
				announcements.length > 0 &&
				announcements.map((item, index) => {
					return (
						<AnnouncementBar
							color={item.color}
							heading={item.heading}
							text={item.text}
							buttonText={item.button && item.button.text}
							url={item.button && item.button.link}
							id={item.id}
							key={index}
						/>
					);
				})}
			<Header>
				<a
					href="#main-content"
					accessKey="s"
					className="is-visuallyhidden focusable"
				>
					{t('shared/header/jumptomaincontent')}
				</a>

				<HeaderLayout backgroundColor="white">
					<TopBar>
						<LeftWrapper>
							<LogoLink
								as={logo && logo.link ? 'a' : 'div'}
								href={logo.link}
								title={logo && logo.link && t('shared/header/gotostartpage')}
							>
								<LogoImg
									src={header.logoSrc ?? logotype}
									alt={header.logoAlt ?? logo.alt ? logo.alt : ''}
								/>
								{includeHeading && <Heading>Lindab</Heading>}
							</LogoLink>
							{siteType === 'Lindab' && !cartDisabled && (
								<CartWrapperMobile
									style={{
										display:
											(isMobile || view === 'mobile') && view !== 'desktop'
												? 'inline-flex'
												: 'none',
										top: isSmallMobile
											? '0.4rem'
											: (isMobile || view === 'mobile') && view !== 'desktop'
											? '3.5rem'
											: '0',
										right: isSmallMobile
											? '4.2rem'
											: (isMobile || view === 'mobile') && view !== 'desktop'
											? '8rem'
											: '0',
									}}
								>
									<Cart
										link={cartLink && cartLink.link}
										target={cartLink && cartLink.target}
										cartDisabled={cartDisabled}
									/>
								</CartWrapperMobile>
							)}
							<MobileMenuWrapper
								style={{
									display:
										(isMobile || view === 'mobile') && view !== 'desktop'
											? 'inline-flex'
											: 'none',
									top: isSmallMobile
										? '0.4rem'
										: (isMobile || view === 'mobile') && view !== 'desktop'
										? '3.5rem'
										: '0',
									right: isSmallMobile
										? '0'
										: (isMobile || view === 'mobile') && view !== 'desktop'
										? '2.4rem'
										: '0',
								}}
							>
								<MobilMenu onChange={setMobileMenuExpanded} />
							</MobileMenuWrapper>
							{search && (
								<QuickSearch
									searchLink={search.searchLink}
									quickSearchLink={search.quickSearchLink}
									searchPlaceholder={search.searchPlaceholder}
									activeMarket={header.activeMarket}
									api={api}
									shoppingCartUrl={(cartLink && cartLink.link) || ''}
									id="query"
									name="query"
								/>
							)}
							{megaMenu && megaMenu.items && megaMenu.items.length > 0 && (
								<MobileMegaMenuWrapper
									style={{
										display:
											(isMobile || view === 'mobile') && view !== 'desktop'
												? 'block'
												: 'none',
									}}
									expanded={mobileMenuExpanded}
								>
									<MobileMegaMenu
										items={megaMenu}
										loginLink={loginLink}
										logoutLink={logoutLink}
										myPagesLink={myPagesLink}
										expanded={
											mobileMenuExpanded &&
											(isMobile || view === 'mobile') &&
											view !== 'desktop'
										}
									/>

									{((siteType === 'Lindab' && cartDisabled) ||
										siteType === 'LindabGroup') && (
										<>
											{header.languageSelector &&
												header.languageSelector.languages && (
													<LanguageSelector
														preamble={header.languageSelector.preamble}
														modalHeading={header.languageSelector.modalHeading}
														heading={header.languageSelector.heading}
														languages={header.languageSelector.languages}
														mobile={true}
													/>
												)}
											{!header.languageSelector && header?.languages && (
												<LanguageSelectorOld
													languages={header.languages}
													mobile={true}
												/>
											)}
										</>
									)}
									{siteType === 'Lindab' && !cartDisabled && (
										<MobileMenuOptionsWrapper>
											<MenuOptions warehouseTooltip={warehouseTooltip} />
										</MobileMenuOptionsWrapper>
									)}
								</MobileMegaMenuWrapper>
							)}
						</LeftWrapper>
						{siteType === 'LindabGroup' && ticker && (
							<TickerWrapperDesktop>
								<Ticker
									date={ticker.date}
									price={ticker.price}
									tradeCurrency={ticker.tradeCurrency}
									delta={ticker.delta}
								/>
							</TickerWrapperDesktop>
						)}

						<RightWrapper
							style={{
								display:
									(isMobile || view === 'mobile') && view !== 'desktop'
										? 'none'
										: 'flex',
							}}
						>
							{siteType === 'LindabGroup' &&
								header.languageSelector &&
								header.languageSelector.languages && (
									<LanguageSelector
										preamble={header.languageSelector.preamble}
										modalHeading={header.languageSelector.modalHeading}
										heading={header.languageSelector.heading}
										languages={header.languageSelector.languages}
									/>
								)}

							{siteType === 'LindabGroup' &&
								!header.languageSelector &&
								header?.languages && (
									<LanguageSelectorOld languages={header.languages} />
								)}

							{siteType === 'Lindab' &&
								cartDisabled &&
								header &&
								header.languageSelector &&
								header.languageSelector.languages && (
									<LanguageSelector
										preamble={header.languageSelector.preamble}
										modalHeading={header.languageSelector.modalHeading}
										heading={header.languageSelector.heading}
										languages={header.languageSelector.languages}
									/>
								)}
							{siteType === 'Lindab' &&
								cartDisabled &&
								!header.languageSelector &&
								header?.languages && (
									<LanguageSelectorOld languages={header.languages} />
								)}
							{siteType === 'Lindab' && !cartDisabled && (
								<MenuOptions warehouseTooltip={warehouseTooltip} />
							)}
						</RightWrapper>
					</TopBar>
					{siteType === 'LindabGroup' && ticker && (
						<TickerWrapperMobile>
							<Ticker
								date={ticker.date}
								price={ticker.price}
								tradeCurrency={ticker.tradeCurrency}
								delta={ticker.delta}
							/>
						</TickerWrapperMobile>
					)}
				</HeaderLayout>

				<MegaMenuLayout
					style={{
						display:
							(isMobile || view === 'mobile') && view !== 'desktop'
								? 'none'
								: 'block',
					}}
				>
					<MenuWrapper
						style={{
							display:
								(isMobile || view === 'mobile') && view !== 'desktop'
									? 'none'
									: 'flex',
						}}
					>
						<MegaMenu menuItems={megaMenu.items} />
						{siteType === 'Lindab' && (
							<>
								{cartDisabled ? (
									<>
										{loginLink && loginLink.link && (
											<LinkItem
												href={loginLink && loginLink.link}
												target={loginLink && loginLink.target}
											>
												{t('shared/header/login')}
											</LinkItem>
										)}
									</>
								) : (
									<>
										{myPagesLink && (
											<MyPagesMenuDropdown
												myPagesLink={myPagesLink}
												logoutLink={logoutLink}
											/>
										)}
									</>
								)}
								{!cartHidden && (
									<CartWrapper>
										<Cart
											link={cartLink && cartLink.link}
											target={cartLink && cartLink.target}
											cartDisabled={cartDisabled}
										/>
									</CartWrapper>
								)}
							</>
						)}
					</MenuWrapper>
				</MegaMenuLayout>
			</Header>
		</>
	);
};

PageHeader.propTypes = {
	header: PropTypes.shape({
		search: PropTypes.shape({
			searchLink: PropTypes.string,
			searchPlaceholder: PropTypes.string,
			ajaxSearchLink: PropTypes.string,
			autoCompleteLink: PropTypes.string,
		}),
		logo: PropTypes.shape({
			link: PropTypes.string,
			alt: PropTypes.string,
		}),
		logoSrc: PropTypes.string,
		logoAlt: PropTypes.string,
		markets: PropTypes.arrayOf(
			PropTypes.shape({
				link: PropTypes.string,
				text: PropTypes.string,
				active: PropTypes.bool,
			})
		),
		customers: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
				id: PropTypes.string,
			})
		),
		projects: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
				id: PropTypes.string,
			})
		),
		warehouses: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
				id: PropTypes.string,
			})
		),
		megaMenu: PropTypes.shape({
			items: PropTypes.arrayOf(
				PropTypes.shape({
					link: PropTypes.string,
					text: PropTypes.string,
					active: PropTypes.bool,
					children: PropTypes.array,
				})
			),
		}),
		loginLink: PropTypes.shape({
			link: PropTypes.string,
			target: PropTypes.string,
		}),
		logoutLink: PropTypes.string,
		myPagesLink: PropTypes.objectOf(
			PropTypes.shape({
				link: PropTypes.string,
				text: PropTypes.string,
				active: PropTypes.bool,
				children: PropTypes.array,
			})
		),
		cart: PropTypes.shape({
			items: PropTypes.number,
		}),
	}),
	api: PropTypes.shape({
		projectsUrl: PropTypes.string,
	}),
	includeHeading: PropTypes.bool,
	modelType: PropTypes.string,
};

export default PageHeader;
