/**
 * ContactPage
 * @module pages/ContactPage
 */

import React, {
	useState,
	useEffect,
	useRef,
	useContext,
	useReducer,
} from 'react';
import { LocalizationContext } from 'context/localization.context';

import Layout from 'components/ui/Layout';
import SectionHeader from 'components/ui/SectionHeader';
import Breadcrumbs from 'components/framework/Breadcrumbs';
import { useMedia } from 'hooks';
import { useFilterSearch } from 'api/general';
import {
	FilterCol,
	InputField,
	ClearFilterButton,
	SlidersButton,
	SlidersIcon,
	ArticleCol,
	FieldSet,
} from 'pages/ProductDetailPage/ProductDetailPage.styles';
import { Paragraph } from 'components/ui/Typography';
import {
	composeQueryString,
	setUrlLocation,
	scrollToTop,
} from 'common/helpers';
import listFilterReducer from 'reducers/listFilterReducer';
import FilterChip from 'components/ui/FilterChip';
import ContentArea from 'components/framework/ContentArea';
import LocationRow from 'components/framework/LocationRow';
import { PagerWrapper } from 'pages/SearchPage/SearchPage.styles';
import { usePopState } from 'hooks/usePopState';
import { ContactPageModel } from 'types/page-types';
import {
	FacetItemModel,
	FacetsItemModel,
	FilterState,
} from 'types/filter-types';
import { ContactPageTheme, LocationRowModel } from 'types/common';
import Icon from 'components/ui/Icon';
import NewContactPage from './NewContactPage';

const ContactPage = ({
	breadcrumbs,
	contentArea,
	filter,
	heading,
	subHeading,
	isInEditMode,
	locations,
	pagination,
	text,
	topContentArea,
	theme,
	slideContentArea,
}: ContactPageModel) => {
	const { t }: any = useContext(LocalizationContext);
	const [query, setQuery] = useState<string | null>(null);
	const [showFilter, setShowFilter] = useState(false);

	const initialState: FilterState = {
		sortBy: undefined,
		group: undefined,
		display: 16,
		query: filter.query,
		currentPage: pagination.currentPage,
		facets: filter.facets as FacetsItemModel[],
	};

	const [state, dispatch] = useReducer(listFilterReducer, initialState);

	let didRender = useRef<any>(false);
	const preventHistoryEntry = useRef(false);

	const popState = usePopState(state);
	const popStateString = JSON.stringify(popState);
	useEffect(() => {
		if (popState) {
			dispatch({ type: 'SET_STATE', payload: popState });
			preventHistoryEntry.current = true;
		}
	}, [popState, popStateString]);

	const [response, error, loading]: any = useFilterSearch(
		query,
		window && window.location ? window.location.pathname : null,
		false
	);

	/* State handling START */

	useEffect(() => {
		const hasRendered = didRender.current;
		const queryString = composeQueryString(
			state.query,
			state.sortBy,
			state.display,
			state.currentPage,
			state.facets
		);
		// Only set the query to fetch new data if it's not the first render.
		didRender.current
			? setQuery(queryString ? queryString : null)
			: (didRender.current = !didRender.current);
		let hash = (window && window.location && window.location.hash) || null;
		setUrlLocation(
			queryString,
			state.query,
			state.sortBy,
			state.display,
			state.currentPage,
			state.facets,
			null,
			hasRendered,
			preventHistoryEntry.current,
			hash
		);
	}, [state]);
	/* State handling END */

	/* Functions START */
	const toggleMobileFilter = () => {
		setShowFilter(!showFilter);
	};

	const handleFacet = (facetId: string, itemId: string, value: boolean) => {
		const facet = state.facets?.find((f: FacetsItemModel) => f.id === facetId);
		const item = facet?.items.find((i: FacetItemModel) => i.id === itemId);
		item && (item.active = value);

		dispatch({ type: 'FACETS', facets: state.facets ?? [] });
	};

	const handleFacetToggle = (
		facetId: string,
		itemId: string,
		value: boolean
	) => {
		const facet = state.facets?.find((f: FacetsItemModel) => f.id === facetId);
		if (facet) {
			// Iterate through the items in the same fieldset
			facet.items.forEach((item: FacetItemModel) => {
				// Set the active property based on the chosen item
				item.active = item.id === itemId && value;
			});
		}

		dispatch({ type: 'FACETS', facets: state.facets ?? [] });
	};

	const handlePagination = (value: number) => {
		scrollToTop();
		dispatch({ type: 'CURRENT_PAGE', currentPage: value });
	};

	const clearFacets = () => {
		state.facets?.forEach((facet: FacetsItemModel) =>
			facet.items.forEach((item) => (item.active = false))
		);
		dispatch({ type: 'FACETS', facets: state.facets ?? [] });
	};

	const handleSearch = (value: string) => {
		if (state.query !== value) {
			dispatch({ type: 'QUERY', query: value });
		}
	};

	/*Functions END */

	/* Display constants START */

	const locationsArray = (response && response.locations) || locations;
	const paginationObject = (response && response.pagination) || pagination;

	const isMobile = useMedia('(max-width: 490px)', true);
	const tabletView = useMedia('(max-width: 890px)', true);
	const hasLocations = locationsArray && locationsArray.length > 0;
	const anyActiveFacetFilter = state.facets?.some((facet: FacetsItemModel) =>
		facet.items.some((item: FacetItemModel) => item.active === true)
	);
	const inputplaceholder = t('contactpage/inputplaceholder');

	useEffect(() => {
		if (response && response.filter && response.filter.facets) {
			dispatch({ type: 'FACETS', facets: response.filter.facets });
		}
	}, [response]);

	return (
		<>
			<main id="main-content">
				{ContactPageTheme.VERSION_2 === theme?.toLocaleLowerCase() ? (
					<>
						<NewContactPage
							topContentArea={topContentArea}
							subHeading={subHeading}
							isInEditMode={false}
							contentArea={contentArea}
							// filter props
							filter={filter}
							handleSearch={handleSearch}
							inputplaceholder={inputplaceholder}
							state={state}
							onChangeFacet={handleFacetToggle}
							showFilter={showFilter}
							// loading & error
							loading={loading}
							error={error}
							// slideContentArea
							slideContentArea={slideContentArea}
							// location
							hasLocations={hasLocations}
							locations={locationsArray}
							// pagination
							pagination={paginationObject}
							isMobile={isMobile}
							handlePagination={handlePagination}
							breadcrumbs={breadcrumbs}
						/>
					</>
				) : (
					<Layout backgroundColor="white">
						{breadcrumbs && <Breadcrumbs links={breadcrumbs} />}
						<SectionHeader
							heading={heading}
							text={text}
							size={'large'}
							isInEditMode={isInEditMode}
						/>
						<ContentArea contentArea={topContentArea} />
						<div className="mt-10 w-full h-10 bg-blue" aria-hidden={true}></div>
						<div className="flex mb-10 smMax:block">
							<FilterCol>
								<InputField
									method="GET"
									action="/"
									id="filter"
									name="filter"
									value={state.query ?? ''}
									onSearch={handleSearch}
									placeholder={inputplaceholder}
								/>
								{tabletView && (
									<SlidersButton
										onClick={() => toggleMobileFilter()}
										title={t('shared/filter/showfilter')}
									>
										<SlidersIcon />
									</SlidersButton>
								)}
								{state && state.facets && (
									<form id="testing">
										{state.facets.map(
											(fieldset: FacetsItemModel, i: number) => (
												<React.Fragment key={i}>
													{fieldset.name && fieldset.items.length > 0 && (
														<FieldSet
															name={fieldset.name}
															items={fieldset.items}
															facetId={fieldset.id}
															key={fieldset.name}
															onChange={handleFacet}
															//@ts-ignore
															showFieldset={showFilter}
															defaultNumberToShow={8}
														/>
													)}
												</React.Fragment>
											)
										)}
									</form>
								)}
							</FilterCol>
							<ArticleCol>
								{anyActiveFacetFilter && (
									<ul className="p-0 inline-block">
										{state.facets?.map((facet: FacetsItemModel, i: number) => {
											return facet.items.map((facetItem, j) => {
												return facetItem.active ? (
													<FilterChip
														name={facet.name}
														item={facetItem}
														facetId={facet.id}
														onDelete={handleFacet}
														key={`${facetItem.name}-${i}-${j}`}
													/>
												) : null;
											});
										})}
									</ul>
								)}
								{anyActiveFacetFilter && (
									<ClearFilterButton as="button" onClick={() => clearFacets()}>
										{t('shared/filter/clearallfilters')}
									</ClearFilterButton>
								)}
								{!loading && error && (
									<div className="my-6 mx-0 text-center w-full">
										<Paragraph>{t('shared/filter/errormessage')}</Paragraph>
									</div>
								)}
								{loading && (
									<div className="my-6 mx-0 text-center w-full">
										<Icon icon="loader" animate="spin" size={2} />
									</div>
								)}
								{hasLocations && !error && !loading
									? locationsArray.map((item: LocationRowModel, i: number) => (
											<LocationRow
												{...item}
												key={i}
												query={state.query ?? ''}
											/>
									  ))
									: !error &&
									  !loading && (
											<div className="my-6 mx-0 text-center w-full">
												<Paragraph>
													{t('shared/filter/noresultmessage')}
												</Paragraph>
											</div>
									  )}
								<PagerWrapper
									nrOfPages={
										paginationObject.pageCount ? paginationObject.pageCount : 0
									}
									currentPage={
										paginationObject.currentPage
											? paginationObject.currentPage
											: 0
									}
									onPageChange={handlePagination}
									total={!error && !loading ? paginationObject.total : 0}
									isMobile={isMobile}
								/>
							</ArticleCol>
						</div>
						<ContentArea contentArea={contentArea} />
					</Layout>
				)}
			</main>
		</>
	);
};

export default ContactPage;
