export default ({ className, ...htmlAttributes }: { className?: string }) => (
	<svg
		stroke="currentColor"
		fill="none"
		stroke-width="2"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		stroke-linecap="round"
		stroke-linejoin="round"
		className={className}
		{...htmlAttributes}
	>
		<polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
	</svg>
);
