/**
 * FilterChip
 * @module components/FilterChip
 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Name, RemoveIcon, Option, Item, Button } from './FilterChip.styles';
import { LocalizationContext } from 'context/localization.context';

const FilterChip = ({ name, facetId, item, onDelete }) => {
	const { t } = useContext(LocalizationContext);
	return (
		<Item>
			<Button
				onClick={e => {
					e.preventDefault();
					onDelete(facetId, item.id, false);
				}}
				title={`${t('shared/filter/removefilter')} ${name}`}
			>
				<Name as="span">{name}</Name>
				<Option as="span">{item.name}</Option>
				<RemoveIcon />
			</Button>
		</Item>
	);
};

FilterChip.propTypes = {
	name: PropTypes.string,
	item: PropTypes.shape({
		id: PropTypes.string,
		count: PropTypes.number,
		name: PropTypes.string,
	}),
};

export default FilterChip;
