/**
 * CalendariumListPage
 * @module pages/CalendariumListPage
 */

import { useState, useContext, useEffect } from 'react';
import Layout from 'components/ui/Layout';
import AddToCalendar from 'react-add-to-calendar';
import moment from 'moment-timezone';
import Breadcrumbs from 'components/framework/Breadcrumbs';
import SectionHeader from 'components/ui/SectionHeader';
import { LocalizationContext } from 'context/localization.context';
import clsx from 'clsx';
import Icon from 'components/ui/Icon';
import { CalendariumListPageModel } from 'types/page-types';

let hasRendered = false;

const CalendariumListPage = ({
	heading,
	calendarium,
	breadcrumbs,
	isInEditMode,
	preamble,
}: CalendariumListPageModel) => {
	const { t }: any = useContext(LocalizationContext);
	const [windowUrl, setWindowUrl] = useState('');

	useEffect(() => {
		if (!hasRendered) hasRendered = true;
		if (window && window.location && window.location.href) {
			setWindowUrl(window.location.href);
		}
	}, []);
	const [isActive, setIsActive] = useState(
		calendarium && calendarium.length > 0 && calendarium[0].id
	);

	const renderAddToCalendar = (
		heading: string,
		itemPreamble: string,
		startTime: string,
		endTime: string,
		location: string,
		text: string
	) => {
		const formattedStartTime = moment(startTime).format();
		const formattedEndTime = moment(endTime).format();

		const event = {
			title: heading,
			description: itemPreamble,
			location: location,
			startTime: formattedStartTime,
			endTime: formattedEndTime,
		};

		return (
			<div className="flex text-blue text-h4 hover:underline hover:cursor-pointer">
				<Icon
					icon="calendarPlus"
					color="#0075BF"
					size={1.25}
					className="mr-2"
				/>
				{hasRendered && (
					<AddToCalendar
						event={event}
						buttonLabel={text}
						listItems={[
							{ outlook: 'Outlook' },
							{ apple: 'Apple' },
							{ google: 'Google' },
						]}
					/>
				)}
			</div>
		);
	};

	return (
		<main id="main-content">
			<Layout backgroundColor="white">
				{breadcrumbs && <Breadcrumbs links={breadcrumbs} />}
				<div className="flex ssm:block ssmMax:mb-2">
					<div className="w-full">
						<SectionHeader
							className="mb-0"
							heading={heading}
							size={'large'}
							isInEditMode={isInEditMode}
						/>
						<div className="lgMax:mb-6 lgMax:mr-6 lgMax:ml-6 mb-6">
							{preamble}
						</div>
					</div>
					<div className="flex pt-8 ssmMax:pt-0 smMax:pt-10 smMax:pl-2 space-x-2">
						<a
							className="m-0 flex items-center justify-center w-9 h-9 bg-grey hover:bg-greyDarker"
							href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
								windowUrl
							)}`}
							title={t('shared/socialmedia/shareonfacebook')}
							target="_blank"
						>
							<Icon icon="facebook" size={2} className="text-greyDarkest" />
						</a>
						<a
							className="m-0 flex items-center justify-center w-9 h-9 bg-grey hover:bg-greyDarker"
							href={`https://twitter.com/home?status=${encodeURIComponent(
								document.title
							)}%20${encodeURIComponent(windowUrl)}`}
							title={t('shared/socialmedia/shareontwitter')}
							target="_blank"
						>
							<Icon icon="twitterX" size={2} className="text-greyDarkest" />
						</a>
						<a
							className="m-0 flex items-center justify-center w-9 h-9 bg-grey hover:bg-greyDarker"
							href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
								windowUrl
							)}`}
							title={t('shared/socialmedia/shareonlinkedin')}
							target="_blank"
						>
							<Icon icon="linkedin" size={2} className="text-greyDarkest" />
						</a>
						<a
							className="m-0 flex items-center justify-center w-9 h-9 bg-grey hover:bg-greyDarker"
							href={`mailto:?subject=[title]&body=${encodeURIComponent(
								windowUrl
							)}`}
							title={t('shared/socialmedia/sharewithemail')}
							target="_blank"
						>
							<Icon icon="mail" size={2} className="text-greyDarkest" />
						</a>
					</div>
				</div>
				<div className="w-full lg:w-8/12">
					<div role="tablist" className="relative calendar-tab-list">
						{calendarium &&
							calendarium.length > 0 &&
							calendarium.map((list, index) => {
								return (
									<button
										key={index}
										role="tab"
										id={list.id}
										onClick={() => setIsActive(list.id)}
										className={clsx(
											isActive === list.id && 'calendar-tab-active',
											'relative py-4 px-5 text-p focus:outline-none'
										)}
									>
										{list.heading}
									</button>
								);
							})}
					</div>
					{calendarium &&
						calendarium.length > 0 &&
						calendarium.map((list, index) => (
							<div
								className={clsx(
									isActive === list.id ? 'block' : 'sr-only',
									'mt-10 mb-20'
								)}
								id={list.id}
								key={index}
							>
								{list.items &&
									list.items.length > 0 &&
									list.items.map((item, i) => {
										return (
											<div
												className="flex border-b border-greyDark my-5 pb-5"
												key={i}
											>
												<div className="uppercase text-center w-18 min-w-18">
													<p className="bg-indigo text-white p-1 text-sm">
														{item.eventMonthFormatted}
													</p>
													<p className="text-indigo h-13.5 text-h2 font-bold p-1 calendar">
														{item.eventDayFormatted}
													</p>
												</div>
												<div className="ml-8">
													<a href={item.url} className="group">
														<p className="text-black text-p mb-2">
															{item.eventDateFormatted}
														</p>
														<h3 className="text-blue text-h3 mb-2 group-hover:underline">
															{item.heading}
														</h3>
														<p className="text-black mb-5 text-p line-clamp-1">
															{item.preamble}
														</p>
													</a>
													{item.addToCalendarStartDate &&
														item.addToCalendarEndDate &&
														renderAddToCalendar(
															item.heading,
															item.preamble,
															item.addToCalendarStartDate,
															item.addToCalendarEndDate,
															item.addToCalendarLocation,
															item.addToCalendarText
														)}
												</div>
											</div>
										);
									})}
							</div>
						))}
				</div>
			</Layout>
		</main>
	);
};

export default CalendariumListPage;
