/**
 * ErrorPage404
 * @module pages/ErrorPage404
 */

import React from 'react';
import Layout from 'components/ui/Layout';
import CustomExternalScript from 'components/framework/CustomExternalScript';
import SectionHeader from 'components/ui/SectionHeader';

const ErrorPage404 = ({ heading, text, isInEditMode }) => (
	<main id="main-content" className="error-page">
		<Layout>
			<div className="my-20">
				<SectionHeader
					heading={heading}
					text={text}
					size={'large'}
					isInEditMode={false}
				/>
				<CustomExternalScript />
			</div>
		</Layout>
	</main>
);

export default ErrorPage404;
