import { Paragraph } from 'components/ui/Typography';
import styled from 'styled-components';
import FilterField from 'components/ui/FilterField';
import RichTextWrapper from 'components/ui/RichText';
import { fontWeightSemiBold } from 'theme/typography';
import { deviceSmallMax, deviceMediumMax } from 'theme/mediaQueries';
import { spacing05x, spacing2x, spacing3x } from 'theme/spacings';
import Button from 'components/ui/Button';

export const Preamble = styled(Paragraph)`
	max-width: 66%;
	font-weight: ${fontWeightSemiBold};

	@media (max-width: ${deviceMediumMax}) {
		max-width: 100%;
	}
	@media (max-width: ${deviceSmallMax}) {
		margin: ${spacing05x} 0;
	}
`;

export const RichText = styled(RichTextWrapper)`
	max-width: 66%;
	@media (max-width: ${deviceMediumMax}) {
		max-width: 100%;
	}
	@media (max-width: ${deviceSmallMax}) {
		margin: ${spacing05x} 0;
	}
`;

export const LinkButton = styled(Button)`
	margin: 0 0 ${spacing2x};

	@media (max-width: ${deviceSmallMax}) {
		padding: 0.8rem 1.2rem;
	}
`;

export const InputField = styled(FilterField)`
	float: right;
	margin-bottom: ${spacing3x};
	width: 40%;
	@media (max-width: ${deviceSmallMax}) {
		float: none;
		width: 100%;
		padding: ${spacing05x};
	}
	svg {
		height: 2rem;
		width: 2rem;
	}
`;
