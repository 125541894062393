/**
 * Button
 * @module components/Button
 */

import React from 'react';
import PropTypes from 'prop-types';
import { DefaultButton } from './Form.styles';

const Button = props => {
	const {
		type,
		buttonColor,
		onFieldChange,
		resetForm,
		showError,
		visibleFields,
		...propsToTransfer
	} = props;

	if (type.toLowerCase() === 'reset') {
		return (
			<DefaultButton
				type={type}
				{...propsToTransfer}
				onClick={props.resetForm}
				buttonColor={buttonColor}
			>
				{props.children}
			</DefaultButton>
		);
	} else {
		return (
			<DefaultButton type={type} {...propsToTransfer} buttonColor={buttonColor}>
				{props.children}
			</DefaultButton>
		);
	}
};

Button.propTypes = {
	type: PropTypes.string,
	onFieldChange: PropTypes.func,
	resetForm: PropTypes.func,
	showError: PropTypes.bool,
	visibleFields: PropTypes.any,
};

Button.defaultProps = {
	type: 'button',
};

export default Button;
