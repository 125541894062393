/**
 * Checkbox
 * @module components/Checkbox
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFieldValidation } from 'hooks';
import { useFormValue } from 'context/form.context';

/**
 * Checks if the checkbox should be checked.
 */
const shouldBeChecked = (value, defaultValue) => {
	if (Array.isArray(defaultValue)) {
		return defaultValue.indexOf(value) !== -1;
	} else {
		return value === defaultValue;
	}
};

const Checkbox = (props) => {
	const {
		id,
		name,
		onFieldChange,
		autoSubmit,
		value,
		single,
		showError,
		className,
		visibleFields,
		index,
		validate,
	} = props;
	const [checked, setChecked] = useState(
		shouldBeChecked(props.value, props.defaultValue)
	);

	const [{ invalidFields }, dispatch] = useFormValue();
	const [valid, setValid] = useState(false);
	const [touched, setTouched] = useState(false);
	const fieldRef = useRef(null);
	const [validateField, showFieldError, hideFieldError] = useFieldValidation(
		fieldRef,
		props
	);

	useEffect(() => {
		if (single) {
			const validatedField = validateField(checked);

			if (validatedField.message) {
				setValid(false);
				dispatch({ type: 'FIELD_INVALID', field: validatedField });
			} else {
				setValid(true);

				if (invalidFields.includes(validatedField.id)) {
					dispatch({ type: 'FIELD_VALID', field: validatedField });
				}
			}

			if (touched && !valid) {
				showFieldError(validatedField);
			} else if (
				(showError && !valid && visibleFields && visibleFields.includes(id)) ||
				(showError && !valid && !visibleFields)
			) {
				setTouched(true);
				showFieldError(validatedField);
			} else {
				hideFieldError();
			}
		} else if (validate && touched) {
			validate({ name, value });
		}
		//eslint-disable-next-line
	}, [
		valid,
		dispatch,
		touched,
		showError,
		setValid,
		validateField,
		showFieldError,
		hideFieldError,
		invalidFields,
		visibleFields,
		id,
		single,
		checked,
	]);

	const onChange = (e) => {
		setChecked(e.target.checked);
		if (!touched) {
			setTouched(true);
		}

		onFieldChange({
			name,
			value: e.target.value,
			checked: e.target.checked,
			autoSubmit,
			type: 'Checkbox',
		});
	};

	return (
		<input
			type="checkbox"
			id={index >= 0 ? `${id}-${index}` : id}
			name={name}
			className={className}
			value={value}
			checked={checked}
			onChange={onChange}
			ref={fieldRef}
			aria-describedby={`form${id}__desc`}
			aria-invalid={single ? !valid : undefined}
		/>
	);
};

Checkbox.propTypes = {
	type: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	autoSubmit: PropTypes.bool,
	onFieldChange: PropTypes.func,
	single: PropTypes.bool,
	showError: PropTypes.bool,
	visibleFields: PropTypes.any,
	index: PropTypes.number,
};

export default Checkbox;
