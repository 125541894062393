import React from 'react';
import Layout from 'components/ui/Layout';
import RichText from 'components/ui/RichText';
import { MegaMenuItemModel } from 'types/common';
import clsx from 'clsx';

interface Props {
	items: MegaMenuItemModel[];
	setCurrentExpanded: Function;
	expanded: boolean;
	onMouseEnter: any;
}
const MegaMenuDropdown: React.FC<Props> = ({
	items,
	setCurrentExpanded,
	expanded,
	onMouseEnter,
}) => {
	const MegaMenuDropdownColumn = (
		shouldGrow: boolean,
		item: MegaMenuItemModel,
		columnKey: number
	) => {
		return (
			<ul
				className={clsx(
					'flex flex-col my-4 mr-5',
					shouldGrow ? 'megamenu__column--grow' : 'megamenu__column'
				)}
				key={columnKey + 1}
			>
				<li className="pt-0 px-0 pb-2 border-b border-greyDarker hover:underline">
					{item.isLink ? (
						<a
							className="text-black font-semibold w-full inline-block m-0 hover:underline"
							href={item.link}
							target={item.target || ''}
						>
							<RichText text={item.text} />
						</a>
					) : (
						<div className="text-black font-semibold w-full inline-block m-0">
							<RichText text={item.text} />
						</div>
					)}
				</li>
				<div
					className={clsx(
						'flex flex-col flex-wrap overflow-visible',
						shouldGrow
							? 'megamenu__column-wrapper--grow'
							: 'megamenu__column-wrapper'
					)}
				>
					{item?.children?.map((item: MegaMenuItemModel, key: number) => {
						return (
							<li
								key={`${columnKey + 1}-${key}`}
								className="my-2 w-full max-w-50 min-h-6"
							>
								{item.isLink ? (
									<a
										className="text-black w-full inline-block m-0 hover:underline"
										href={item.link}
										target={item.target || ''}
									>
										<RichText text={item.text} />
									</a>
								) : (
									<div className="text-black w-full inline-block m-0">
										<RichText text={item.text} />
									</div>
								)}
							</li>
						);
					})}
				</div>
			</ul>
		);
	};
	let sizeCount = 0;
	if (items && items.length > 0) {
		const blockItem = items.find((i) => i.isBlock);
		return (
			<div
				onMouseLeave={() => setCurrentExpanded(-1)}
				onMouseEnter={onMouseEnter}
				className={clsx(
					'megamenu-dropdown text-black text-p bg-grey my-0 mx-auto absolute',
					expanded ? 'h-auto visible megamenu-dropdown--after' : 'h-0 invisible'
				)}
			>
				<Layout>
					<div className="flex flex-row flex-no-wrap overflow-hidden">
						{items
							.filter((i) => !i.isBlock)
							.map((item, index) => {
								let shouldGrow =
									(item && item.children && item.children.length > 10) || false;
								shouldGrow ? (sizeCount += 2) : sizeCount++;
								if (sizeCount > 8) {
									return null;
								}
								return (
									<React.Fragment key={`${item.text}-${index}`}>
										{MegaMenuDropdownColumn(shouldGrow, item, index)}
									</React.Fragment>
								);
							})}
						{blockItem && (
							<div className="flex flex-wrap">
								<div className="flex-100 pt-8 pb-2 font-semibold text-blue">
									{blockItem.text}
								</div>
								<div className="flex flex-wrap shadow-custom bg-white px-6 rounded-xl mb-2 w-full">
									{blockItem?.children?.map((item, i) => {
										let shouldGrow =
											(item && item.children && item.children.length > 10) ||
											false;
										shouldGrow ? (sizeCount += 2) : sizeCount++;
										if (sizeCount > 8) {
											return null;
										}
										return (
											<React.Fragment key={i}>
												{MegaMenuDropdownColumn(shouldGrow, item, i)}
											</React.Fragment>
										);
									})}
								</div>
							</div>
						)}
					</div>
				</Layout>
			</div>
		);
	}
	return null;
};
export default MegaMenuDropdown;
