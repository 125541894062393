import styled from 'styled-components';
import { spacing1x, spacing2x, spacing5x, spacing10x } from 'theme/spacings';
import { deviceSmallMax } from 'theme/mediaQueries';
import { FiTrash2 } from 'react-icons/fi';
import {
	greyDark,
	greyPlaceholder,
	blue,
	grey,
	errorRed,
	greyDarkest,
} from 'theme/colors';
import { fontFamily, fontWeightBold } from 'theme/typography';
import { paragraphCss } from 'components/ui/Typography';
import Button from 'components/ui/Button';
import { Popout } from 'components/ui/Tooltip/Tooltip.styles';

export const Body = styled.div`
	padding-bottom: ${spacing10x};
	overflow-x: auto;
	position: static;

	@media (max-width: ${deviceSmallMax}) {
		padding-bottom: ${spacing5x};
	}
`;

export const VisuallyHidden = styled.td`
	border: 0;
	clip: rect(0 0 0 0);
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.1rem;
`;

export const EditAddressLink = styled.a`
	pointer-events: all;
	text-decoration: none;
	color: ${blue};
	&:hover {
		text-decoration: underline;
	}
	@media (max-width: ${deviceSmallMax}) {
	}
`;

export const DeleteAddressButton = styled.button`
	border: 0;
	background-color: transparent;
	transition: opacity 0.4s ease-out;
	pointer-events: all;
	text-decoration: none;
	cursor: pointer;
	@media (max-width: ${deviceSmallMax}) {
		right: 0;
	}
`;

export const UndeletableButton = styled(DeleteAddressButton)`
	> svg {
		width: 1.6rem;
		height: 1.6rem;
		color: ${greyDark};
	}
`;

export const DeleteAddressIcon = styled(FiTrash2)`
	width: 1.6rem;
	height: 1.6rem;
	color: ${greyDarkest};
`;

export const ModalInputWrapper = styled.div`
	display: inline-block;
	width: 50%;
	&:not(:first-of-type) {
		padding-left: ${spacing2x};
	}
`;

export const ModalLabel = styled.label`
	${paragraphCss};
	font-weight: ${fontWeightBold};
`;
export const ModalInputField = styled.input`
	flex: 1 1;
	padding: ${spacing1x} ${spacing2x};
	border: 0.1rem solid ${greyDark};
	border-radius: 0.4rem;
	font-family: ${fontFamily};
	font-size: 1.6rem;
	line-height: 2.4rem;
	width: 100%;
	@media (max-width: 890px) {
		font-size: 1.4rem;
		height: 3.2rem;
	}
	:read-only,
	:disabled {
		background-color: ${grey};
	}
	:invalid {
		border-color: ${errorRed};
	}
`;

export const ModalTextArea = styled.textarea`
	width: 100%;
	max-width: 100%;
	min-height: 5rem;
	height: 10rem;
	padding: ${spacing1x};
	border: 0.1rem solid ${greyDark};
	border-radius: 0.4rem;

	font-family: ${fontFamily};
	font-size: 1.6rem;
	line-height: 2.4rem;
	:read-only,
	:disabled {
		background-color: ${grey};
	}
	:invalid {
		border-color: ${errorRed};
	}
	::placeholder {
		color: ${greyPlaceholder};
	}

	@media (max-width: ${deviceSmallMax}) {
		margin-bottom: ${spacing2x};
	}
`;

export const SaveChangesButton = styled(Button)``;

export const SaveChangesButtonWrapper = styled.div`
	text-align: right;
`;

export const DeleteDialogMessage = styled.p`
	${paragraphCss};
	font-weight: ${fontWeightBold};
`;
export const DeleteDialogButton = styled(Button)`
	:first-child {
		margin-right: ${spacing2x};
	}
`;
export const DeleteDialogButtonWrapper = styled.div`
	text-align: right;
`;

export const ErrorText = styled.span`
	${paragraphCss};
	color: ${errorRed};
	margin-top: 1.2rem;
	display: ${(props) => (props.displayBlock ? 'block' : 'inline')};
`;

export const ErrorTextWrapper = styled.div`
	width: 50%;
	display: inline-block;
	&:not(:first-child) {
		padding-left: ${spacing2x};
	}
`;

export const UndeletablePopout = styled(Popout)`
	box-shadow: 0 0.2rem 0.5rem 0rem rgba(0, 0, 0, 0.38);
	white-space: pre-wrap;
	max-width: 20rem;
	min-width: 25rem;
	:after {
		content: '';
		position: absolute;
		bottom: 100%;
		left: 96%;
		margin-left: -1rem;
		border-width: 1rem;
		border-style: solid;
		border-color: transparent transparent ${grey} transparent;
	}
`;
