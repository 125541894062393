/**
 * ExportDocuments
 * @module components/ExportDocuments
 */

import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { LocalizationContext } from 'context/localization.context';
import clsx from 'clsx';
import Button from '../Button';
import Icon from '../Icon';

interface DocumentItem {
	link: string;
	text: string;
	active: boolean;
	id: number;
	isLink: boolean;
	linkType: string;
}

interface ExportDocumentsProps {
	documents?: DocumentItem[];
	forMobile?: boolean;
	query?: string;
}

const ExportDocuments: React.FC<ExportDocumentsProps> = ({
	documents,
	query,
	forMobile,
}) => {
	const { t }: any = useContext(LocalizationContext);
	const [selectedDocumnet, setSelectedDocumnet] = useState<
		DocumentItem | undefined
	>(undefined);

	const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
		e.preventDefault();
		setSelectedDocumnet(
			documents?.find((doc) => doc.text === e?.target?.value) ?? undefined
		);
	};

	useEffect(() => {
		if (documents && documents?.length > 0) {
			setSelectedDocumnet(documents[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			className={clsx(
				'mt-4 bg-grey p-4 gap-2 lg:gap-12 flex flex-col items-center lg:flex-row'
			)}
		>
			<div className="w-full lg:w-1/4">
				<h2 className="text-blue font-semibold inline-block m-0 p-0 w-10/12 leading-20 text-h5 whitespace-no-wrap">
					{t('orderhistorypage/exportdocumentheading')}
				</h2>
				<p className="my-2 text-p">
					{t('orderhistorypage/exportdocumenttext')}
				</p>
			</div>
			{documents && documents?.length > 0 && (
				<form className="flex flex-col items-center gap-2 lg:gap-4 lg:flex-row w-full lg:w-3/4">
					<div className="w-full mb-2 lg:mb-0 lg:w-2/3">
						<select
							id={'documents'}
							name={'documents'}
							className="font-standard text-p px-4 py-2 border rounded border-greyDark w-full placeholder-greyDarkest"
							onChange={handleChange}
							placeholder={t('orderhistorypage/selectdocument')}
							value={selectedDocumnet?.text}
							required
						>
							<option value="" disabled hidden>
								{t('orderhistorypage/selectdocument')}
							</option>
							{documents?.map((document: DocumentItem, index: number) => (
								<option key={index} value={document.text}>
									{document.text}
								</option>
							))}
						</select>
					</div>

					<Button
						type="link"
						noMargin
						onClick={(e) => {
							e.preventDefault();
							if (window && window.open) {
								window.open(`${selectedDocumnet?.link}?${query}`);
							} else {
								return true;
							}
						}}
						buttonColor="greyDarkest"
						target="_blank"
						className="pl-1 w-full  lg:w-1/3"
					>
						<Icon
							icon="download"
							size={1.25}
							aria-hidden="true"
							className="mr-2 -mt-1"
						/>
						{t('orderhistorypage/exportdocumentbuttontext')}
					</Button>
				</form>
			)}
		</div>
	);
};

export default ExportDocuments;
