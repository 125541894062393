export const white = '#ffffff';
export const black = '#2A2A2A';

export const greyLight = '#FAFAFA';
export const grey = '#F6F6F6';
export const greyDark = '#E4E4E4';
export const greyDarker = '#C9C9C9';
export const greyDarkest = '#949494';
export const greyPlaceholder = '#919191';

export const blueLight = '#E5F6FF';
export const blue = '#0075BF';
export const blueDark = '#005E94';
export const blueDarker = '#003D61';

export const indigo = '#01416F';
export const turquoise = '#3AB5E8';
export const green = '#00B900';

export const orangeLight = '#F5822B';
export const orange = '#FF6600';
export const orangeDark = '#BE5709';

export const errorRed = '#D0021B';
