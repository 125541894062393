/**
 * Pager
 * @module components/Pager
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	PagerList,
	PreviousPageIcon,
	NextPageIcon,
	ActiveItem,
	ActiveItemLink,
	InactiveItem,
	InactiveItemLink,
	EmptyItem,
} from './Pager.styles';
import { LocalizationContext } from 'context/localization.context';

class Pager extends Component {
	static contextType = LocalizationContext;

	/**
	 * Handles page change when a new page is clicked
	 * @param {Object} e - The event object
	 */

	handlePageChange(e, pageNumber) {
		e.preventDefault();

		this.props.onPageChange(pageNumber);
	}

	/**
	 * Returns the array of page numbers to display, based on current page
	 * @returns {Array}
	 */
	getPageNumbers() {
		const { currentPage, nrOfPages } = this.props;
		let numbers = [];
		if (!this.props.isMobile) {
			if (currentPage >= 1) {
				// If current is bigger than first
				numbers.push(currentPage);
			}

			if (currentPage >= 3) {
				// If current is bigger than 3
				numbers.push(currentPage - 1);
				numbers.push(currentPage - 2);
			}

			if (currentPage + 2 <= nrOfPages) {
				//if current + 2 is bigger than total number of pages
				numbers.push(currentPage + 2);
				numbers.push(currentPage + 1);
			}
		} else if (nrOfPages < 5) {
			// when all pages fits on the screen add all.
			for (let index = 0; index < nrOfPages; index++) {
				numbers.push(index + 1);
			}
		} else {
			// If current is firstPage show 2 and 3
			if (currentPage === 1) {
				numbers.push(currentPage + 1);
				numbers.push(currentPage + 2);
			}
			// If current is bigger than first add current
			if (currentPage >= 1) {
				numbers.push(currentPage);
			}
			// if current is 2 show third.
			if (currentPage === 2) {
				numbers.push(currentPage + 1);
			}
			// If current is third show second.
			if (currentPage === 3) {
				numbers.push(currentPage - 1);
			}
			// If currentPage is last show second and third to last.
			if (currentPage === nrOfPages) {
				numbers.push(currentPage - 1);
				numbers.push(currentPage - 2);
			}
			// If current is second to last show third to last.
			if (currentPage === nrOfPages - 1) {
				numbers.push(currentPage - 1);
			}
			//If current is third to last show second to last.
			if (currentPage === nrOfPages - 2) {
				numbers.push(currentPage + 1);
			}
		}

		numbers.sort((a, b) => a - b);
		return numbers;
	}

	/**
	 * Adds link to first page if it doesn't already exist, and '...' if needed
	 * @param {Array} pageNumbers - Array of page numbers to display
	 * @param {Array} pages - Array of pagination list elements
	 */
	getFirstLink(pageNumbers, pages) {
		const { currentPage } = this.props;

		if (!pageNumbers.includes(0)) {
			if (currentPage === 1) {
				pages.push(
					<ActiveItem key={0}>
						<ActiveItemLink
							href="?page=1"
							onClick={e => this.handlePageChange(e, 1)}
						>
							1
						</ActiveItemLink>
					</ActiveItem>
				);
			} else {
				pages.push(
					<InactiveItem key={0} btn={'first'}>
						<InactiveItemLink
							href="?page=1"
							onClick={e => this.handlePageChange(e, 1)}
						>
							1
						</InactiveItemLink>
					</InactiveItem>
				);
			}

			if (
				!this.props.isMobile &&
				pageNumbers.length > 1 &&
				!pageNumbers.includes(2)
			) {
				pages.push(<EmptyItem key={-1}>...</EmptyItem>);
			}
			if (this.props.isMobile && currentPage > 3) {
				pages.push(<EmptyItem key={-1}>...</EmptyItem>);
			}
		}

		return pages;
	}

	/**
	 * Loops through numbers array and adds JSX element for each number
	 * @param {Array} pageNumbers - Array of page numbers to display
	 * @param {Array} pages - Array of pagination list elements
	 */
	getLinks(pageNumbers, pages) {
		const { currentPage } = this.props;

		pageNumbers.forEach(number => {
			if (number === 1) {
				return;
			}

			if (currentPage === number) {
				pages.push(
					<ActiveItem key={number}>
						<ActiveItemLink
							href={`?page=${number}`}
							onClick={e => this.handlePageChange(e, number)}
						>
							{number}
						</ActiveItemLink>
					</ActiveItem>
				);
			} else {
				pages.push(
					<InactiveItem key={number}>
						<InactiveItemLink
							href={`?page=${number}`}
							onClick={e => this.handlePageChange(e, number)}
						>
							{number}
						</InactiveItemLink>
					</InactiveItem>
				);
			}
		});

		return pages;
	}

	/**
	 * Adds link to last page if it doesn't already exist, and '...' if needed
	 * @param {Array} pageNumbers - Array of page numbers to display
	 * @param {Array} pages - Array of pagination list elements
	 */
	getLastLink(pageNumbers, pages) {
		const { nrOfPages, currentPage, isMobile } = this.props;

		if (!pageNumbers.includes(nrOfPages)) {
			if (!isMobile && !pageNumbers.includes(nrOfPages - 1)) {
				pages.push(<EmptyItem key={nrOfPages - 1}>...</EmptyItem>);
			}
			if (isMobile && currentPage < nrOfPages - 2) {
				pages.push(<EmptyItem key={nrOfPages - 1}>...</EmptyItem>);
			}

			if (currentPage === nrOfPages) {
				pages.push(
					<ActiveItem key={nrOfPages} btn={'last'}>
						<ActiveItemLink
							href={`?page=${nrOfPages}`}
							onClick={e => this.handlePageChange(e, nrOfPages)}
						>
							{nrOfPages}
						</ActiveItemLink>
					</ActiveItem>
				);
			} else {
				pages.push(
					<InactiveItem key={nrOfPages} btn={'last'}>
						<InactiveItemLink
							href={`?page=${nrOfPages}`}
							onClick={e => this.handlePageChange(e, nrOfPages)}
						>
							{nrOfPages}
						</InactiveItemLink>
					</InactiveItem>
				);
			}
		}

		return pages;
	}

	/**
	 * Gets JSX for all page numbers
	 * @param {Array} pageNumbers - The page numbers to render
	 * @param {Array} pages - Array of pagination links (JSX)
	 */
	getPageLinks(pageNumbers, pages) {
		pages = this.getFirstLink(pageNumbers, pages);
		pages = this.getLinks(pageNumbers, pages);
		pages = this.getLastLink(pageNumbers, pages);

		return pages;
	}

	/**
	 * Returns a Pagination button for next/previous behavior
	 * @param {JSX} content - Content to render inside link
	 * @param {String} pageNumber - Page number to go to
	 * @param {String} key - Element key
	 * @param {String} title - Title to make link accessible
	 * @returns {JSX}
	 */
	getPagerBtn(content, pageNumber, key, title) {
		return (
			<InactiveItem key={key} btn={key}>
				<InactiveItemLink
					href={`?page=${pageNumber}`}
					onClick={e => this.handlePageChange(e, pageNumber)}
					aria-label={title}
				>
					{content}
				</InactiveItemLink>
			</InactiveItem>
		);
	}

	/**
	 * Renders the pagination
	 * @param {Array} pageNumbers - Array of page numbers to render
	 */
	renderPagination(pageNumbers) {
		const { t } = this.context;

		const { nrOfPages, currentPage } = this.props;

		const shouldRenderPreviousBtn = currentPage !== 1;

		const shouldRenderNextBtn = currentPage < nrOfPages;

		let pages = [];

		if (shouldRenderPreviousBtn) {
			pages.push(
				this.getPagerBtn(
					<PreviousPageIcon />,
					currentPage - 1,
					'prev',
					t('shared/pager/previouspage')
				)
			);
		}

		pages = this.getPageLinks(pageNumbers, pages);

		if (shouldRenderNextBtn) {
			pages.push(
				this.getPagerBtn(
					<NextPageIcon />,
					currentPage + 1,
					'next',
					t('shared/pager/nextpage')
				)
			);
		}

		return pages;
	}

	render() {
		const pageNumbers = this.getPageNumbers();
		const { t } = this.context;
		const { total } = this.props;
		return (
			<nav
				aria-label={t('shared/pager/arialabelnav')}
				className={this.props.className}
			>
				{total > 0 && (
					<PagerList>{this.renderPagination(pageNumbers)}</PagerList>
				)}
			</nav>
		);
	}
}

Pager.propTypes = {
	nrOfPages: PropTypes.number,
	currentPage: PropTypes.number,
	onPageChange: PropTypes.func,
};

export default Pager;
