// eslint-disable-next-line
import { useState, useContext, useEffect, useRef } from 'react';
import {
	useFilterSearch,
	usePressRelease,
	usePriceStockDetail,
	trackClick,
} from './general.js';
import { cancelPricingRequest, cancelToken } from 'store/api/cartApi';

import { useAutoComplete } from './autocomplete.js';
import { useLogin } from './login.js';

import axios, { Method } from 'axios';

const useFetch = ({
	url,
	data = {},
	method = 'get',
	responseDefault = null,
	preventRequest = false,
	headers = {},
	disableCancel = false,
	dataHash,
}: {
	url: string;
	data: any;
	method: Method;
	responseDefault: null;
	preventRequest: boolean;
	headers: {};
	disableCancel: boolean;
	dataHash: string;
}) => {
	const [response, setResponse] = useState(responseDefault);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (preventRequest) {
			return;
		}

		// Cancel previous ongoing pricing request
		if (!disableCancel) {
			cancelPricingRequest();
		}
		setLoading(true);

		axios({
			method,
			url,
			data,
			cancelToken: cancelToken?.token,
			headers: {
				...headers,
				'X-Requested-With': 'XMLHttpRequest',
				'content-type': 'application/json; charset=utf-8',
			},
		})
			.then(({ data }) => {
				setLoading(false);
				setResponse(data);
			})
			.catch((error) => {
				setError(error);
				if (!axios.isCancel) {
					setLoading(false);
				}
			});

		// eslint-disable-next-line
	}, [url, dataHash]);
	return [response, error, loading];
};

export {
	useFetch,
	useFilterSearch,
	usePressRelease,
	usePriceStockDetail,
	useLogin,
	trackClick,
	useAutoComplete,
};
