import ReactDOM from 'react-dom';
import App from './App';
import initStore from 'store';
import { Provider } from 'react-redux';
import { getPageData } from 'common/dataHandler';
import 'react-app-polyfill/stable';
import 'styles/fonts.scss';
import { getPreloadedState } from 'common/helpers';

// Start mock API serviceworker
if (process.env.NODE_ENV === 'development') {
	const { worker } = require('./mocks/browser');
	worker.start();
}

const model = window.__model || {
	framework: {
		react: {
			containerId: 'root',
			clientOnly: true,
			renderWithMockupData: true,
		},
	},
};

const render = (model) => {
	const element = document.getElementById(model.framework.react.containerId);

	if (element) {
		if (model.framework.react.clientOnly) {
			if (model.framework.react.renderWithMockupData) {
				getPageData().then((data) => {
					const { framework, ...pageData } = data;

					model = {
						framework: {
							...model.framework,
							...framework,
						},
						...pageData,
					};
					ReactDOM.render(
						<Provider store={initStore(getPreloadedState(model))}>
							<App {...model} />
						</Provider>,
						element
					);
				});
			} else {
				ReactDOM.render(
					<Provider store={initStore(getPreloadedState(model))}>
						<App {...model} />
					</Provider>,
					element
				);
			}
		} else {
			ReactDOM.render(
				<Provider store={initStore(getPreloadedState(model))}>
					<App {...model} />
				</Provider>,
				element
			);
		}
	}
};

render(model);
