import styled, { css } from 'styled-components';
import {
	spacing05x,
	spacing1x,
	spacing3x,
	spacing4x,
	spacing5x,
} from 'theme/spacings';
import { deviceSmallMax } from 'theme/mediaQueries';
import { white } from 'theme/colors';
import { fontWeightBold } from 'theme/typography';
import { Paragraph, paragraphCss } from 'components/ui/Typography';

import { FiFacebook, FiYoutube, FiLinkedin, FiInstagram } from 'react-icons/fi';
import { FaXTwitter } from 'react-icons/fa6';
const IconCss = css`
	color: ${white};
	font-size: 2rem;
	margin: -${spacing05x} ${spacing05x};
	opacity: 0.75;

	:hover {
		opacity: 1;
	}

	@media (max-width: ${deviceSmallMax}) {
		margin: 0 ${spacing05x} 0 0;
	}
`;

export const FooterContainer = styled.div`
	max-width: 144rem;
	margin: 0 auto;
	box-shadow: 0 9px 0px 0px white, 0 -9px 0px 0px white,
		0 0 8px 0 rgba(42, 42, 42, 0.2), 0 0 8px 0 rgba(42, 42, 42, 0.2);
`;

export const BottomWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin: ${spacing1x} 0;
	text-align: center;
	@media (max-width: ${deviceSmallMax}) {
		display: block;
		margin: 0 ${spacing5x};
	}
`;

export const ColumnsWrapper = styled.div`
	width: 100%;
	display: flex;
	margin: ${spacing4x} 0;

	position: relative;
	&:: before {
		content: ' ';
		display: inline-block;
		position: absolute;
		top: -1.5rem;
		width: 100%;
		height: 0.1rem;
		opacity: 0.25;
		background-color: ${white};
	}

	@media (max-width: ${deviceSmallMax}) {
		display: block;
	}
`;

export const Column = styled.section`
	width: 25%;
	display: inline-block;

	@media (max-width: ${deviceSmallMax}) {
		width: 100%;
		padding: 1.2rem 0;
	}
`;

export const Heading = styled(Paragraph)`
	color: ${white};
	font-weight: ${fontWeightBold};
	margin: 0;
`;

export const LogoImg = styled.img`
	margin-top: ${spacing3x};
	max-height: 48px;
`;

export const Text = styled(Paragraph)`
	color: ${white};
	margin: 0;
	display: inline-block;
`;

export const Link = styled.a`
	color: ${white};
	text-decoration: none;
	margin: 0;
	font-size: 1.3rem;

	&::before {
		content: '|';
		margin: 0 ${spacing1x};
		color: ${white};
	}

	:hover {
		text-decoration: underline;
	}
`;

export const SocialLink = styled.a`
	margin: 0;
`;

export const LinkWrapper = styled.div`
	display: inline-block;

	@media (max-width: ${deviceSmallMax}) {
		margin: ${spacing1x} 0;
	}
`;

export const FacebookIcon = styled(FiFacebook)`
	${IconCss}
`;

export const TwitterIcon = styled(FaXTwitter)`
	${IconCss}
`;
export const YoutubeIcon = styled(FiYoutube)`
	${IconCss}
`;
export const InstagramIcon = styled(FiInstagram)`
	${IconCss}
`;
export const LinkedinIcon = styled(FiLinkedin)`
	${IconCss}
`;

export const RichTextWrapper = styled.div`
	p {
		${paragraphCss}
		color: ${white};
	}
	a {
		${paragraphCss}
		text-decoration: none;
		color: ${white};
		cursor: pointer;
		:hover {
			text-decoration: underline;
		}
	}

	ul {
		${paragraphCss}
		color: ${white};
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			margin: ${spacing1x} 0;
		}
	}
`;
