import clsx from 'clsx';
import { forwardRef } from 'react';
import { EpiserverFormsPageModel } from 'types';

interface ErrorSummaryItemModel {
	id: string;
	label: string;
	message: string;
}

interface ErrorSummaryModel {
	[key: string]: ErrorSummaryItemModel[];
	translations: any;
}

const ErrorSummary = ({
	validationMessages,
	translations,
}: ErrorSummaryModel) => {
	return (
		<>
			<h2 className="text-p">
				{translations['shared/form/formcontains']}{' '}
				{Object.keys(validationMessages).length}{' '}
				{translations['shared/form/formissues']}:
			</h2>
			<div className="text-sm">
				<ul className="mt-0 pb-0">
					{validationMessages &&
						Object.keys(validationMessages).map((key: any, i) => (
							<li className="mb-0 text-sm" key={i}>
								<div
									dangerouslySetInnerHTML={{
										__html: validationMessages[key].label,
									}}
								/>
								<span>{validationMessages[key].message}</span>
							</li>
						))}
				</ul>
			</div>
		</>
	);
};

interface FormMessageModel {
	confirmationMessage: string;
	state: EpiserverFormsPageModel;
	translations: any;
}

const FormMessage = forwardRef(
	(
		{ confirmationMessage, state, translations }: FormMessageModel,
		ref: any
	) => {
		const {
			successMessage,
			invalidFields,
			serverValidationError,
			validationMessages,
		} = state;
		const hasMessageToShow = confirmationMessage || successMessage;
		const messageType =
			serverValidationError || invalidFields.length > 0 ? 'error' : 'success';

		return (
			<div
				className="my-6"
				hidden={messageType === 'success' && !hasMessageToShow}
			>
				<div
					className={clsx(
						'border border-solid p-3',
						messageType !== 'success' &&
							messageType !== 'error' &&
							'border-grey',
						messageType === 'success' && 'border-green',
						messageType === 'error' && 'border-red'
					)}
					tabIndex={-1}
					ref={ref}
				>
					{(serverValidationError || invalidFields.length > 0) && (
						<ErrorSummary
							validationMessages={validationMessages}
							translations={translations}
						/>
					)}

					{confirmationMessage && <p>{confirmationMessage}</p>}
					{successMessage && (
						<div dangerouslySetInnerHTML={{ __html: successMessage }} />
					)}
				</div>
			</div>
		);
	}
);

export default FormMessage;
