/**
 * CategoryListItem
 * @module components/CategoryListItem
 */

import clsx from 'clsx';
import Icon from 'components/ui/Icon';
import { MouseEventHandler } from 'react';

interface Props {
	/** Pass an optional className */
	className?: string;

	/** Link text */
	text: string;

	/** Link url */
	url: string;

	/** Link target */
	target?: string;

	/** Pass an optional onClick Function */
	onClick?: MouseEventHandler;
}

const CategoryListItem = ({
	className,
	text,
	url,
	target,
	onClick = () => {},
}: Props): JSX.Element => {
	return (
		<li className={clsx(className)}>
			<a
				href={url}
				target={target}
				onClick={(e) => onClick(e)}
				className="flex items-baseline text-p hover:underline"
			>
				<Icon
					icon="chevron"
					color="#007DC5"
					aria-hidden={true}
					direction="left"
					size={0.75}
				/>
				<span className="ml-1">{text}</span>
			</a>
		</li>
	);
};

export default CategoryListItem;
