import { useState, useRef, useEffect } from 'react';

/**
 * For toggle content functionality
 * Returns expanded state, toggle function and containerRef
 *
 * @param {Boolean} initialState - Initial toggle state
 * @param {Boolean} dropdown - If toggled content is a dropdown or not
 * @returns {Array}
 */
export const useToggle = (initialState, dropdown) => {
	const [expanded, setExpanded] = useState(initialState || false);
	const containerRef = useRef(null);

	const handleClickOutsideComponent = (e) => {
		if (containerRef && !containerRef.current?.contains(e.target) && expanded) {
			setExpanded(false);
		}
	};

	const toggle = (value) => setExpanded(value || !expanded);

	useEffect(() => {
		if (dropdown) {
			document.addEventListener('click', handleClickOutsideComponent);
		}

		return () => {
			if (dropdown) {
				document.removeEventListener('click', handleClickOutsideComponent);
			}
		};
	});

	return [expanded, toggle, containerRef];
};
