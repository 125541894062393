/**
 * Breadcrumbs
 * @module components/Breadcrumbs
 */

import { useContext } from 'react';
import {
	BreadCrumbsList,
	BreadCrumbsItem,
	BreadCrumbsLink,
	SeperatorIcon,
	HomeIcon,
	PreviousPageIcon,
	LinkRichText,
} from './Breadcrumbs.styles';
import { LinkModel } from 'types/common';
import { LocalizationContext } from 'context/localization.context';
import clsx from 'clsx';

interface BreadCrumbsModel {
	links: LinkModel[];
	className?: string;
}

const Breadcrumbs = ({ links, className }: BreadCrumbsModel) => {
	const { t }: any = useContext(LocalizationContext);
	return (
		<nav
			aria-label={t('shared/breadcrumbs/label')}
			className={clsx(className, 'my-2 md:my-4')}
		>
			<BreadCrumbsList>
				{links &&
					links.map((item, i) => {
						const isLastLink = i === links.length - 1;
						const isFirstLink = i === 0;

						return (
							<BreadCrumbsItem key={i}>
								{isFirstLink ? (
									<>
										<HomeIcon />
										<BreadCrumbsLink href={item.link}>
											<LinkRichText text={item.text} />
										</BreadCrumbsLink>
									</>
								) : isLastLink ? (
									<>
										<PreviousPageIcon />
										<SeperatorIcon />
										<BreadCrumbsLink href={item.link} aria-current="page">
											<LinkRichText text={item.text} />
										</BreadCrumbsLink>
									</>
								) : (
									<>
										<SeperatorIcon />
										<BreadCrumbsLink href={item.link}>
											<LinkRichText text={item.text} />
										</BreadCrumbsLink>
									</>
								)}
							</BreadCrumbsItem>
						);
					})}
			</BreadCrumbsList>
		</nav>
	);
};

export default Breadcrumbs;
