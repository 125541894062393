/**
 * MyAccountPage
 * @module pages/MyAccountPage
 */

import React, { useContext, useEffect, useState, useRef } from 'react';
import Layout from 'components/ui/Layout';
import {
	Wrapper,
	Table,
	Row,
	Property,
	Value,
	Caption,
} from './MyAccountPage.styles';
import ContentArea from 'components/framework/ContentArea';
import Breadcrumbs from 'components/framework/Breadcrumbs';
import { LocalizationContext } from 'context/localization.context';
import SectionHeader from 'components/ui/SectionHeader';
import { useSelector } from 'react-redux';
import { selectUserObject } from 'store/modules/user';
import { fetchAccount } from 'api/myaccount';

const MyAccountPage = ({
	heading,
	breadcrumbs,
	text,
	columnList,
	isInEditMode,
	contentArea,
}) => {
	const { t } = useContext(LocalizationContext);
	const { customers } = useSelector(selectUserObject);
	const [accountColumns, setAccountColumns] = useState(columnList);

	const didRender = useRef(false);

	const fetchAccountColumns = async () => {
		try {
			if (window?.location?.href) {
				const data = await fetchAccount(window.location.href);
				setAccountColumns(data);
			}
		} catch (error) {
			console.error(error);
		}
	};
	useEffect(() => {
		if (didRender.current) {
			fetchAccountColumns();
		}
		if (!didRender.current && typeof customers !== 'undefined') {
			didRender.current = true;
		}
		//eslint-disable-next-line
	}, [customers]);

	return (
		<main id="main-content">
			<Layout backgroundColor="white">
				<Breadcrumbs links={breadcrumbs} />
				<SectionHeader
					heading={heading}
					text={text}
					size="large"
					isInEditMode={isInEditMode}
				/>
				<Wrapper>
					{accountColumns &&
						accountColumns.map((item, i) => (
							<Table as="table" key={i}>
								{item.heading && <Caption as="caption">{item.heading}</Caption>}

								<thead className="sr-only">
									<tr>
										<th scope="col">
											{t('shared/specificationslist/property')}
										</th>
										<th scope="col">{t('shared/specificationslist/value')}</th>
									</tr>
								</thead>
								<tbody>
									{item &&
										item.dictionary &&
										Object.keys(item.dictionary).map((key, i) => {
											return (
												<React.Fragment key={i}>
													<Row>
														<Property>{key}</Property>
														<Value>{item.dictionary[key]}</Value>
													</Row>
												</React.Fragment>
											);
										})}
								</tbody>
							</Table>
						))}
				</Wrapper>
			</Layout>
			{contentArea && <ContentArea contentArea={contentArea} />}
		</main>
	);
};

export default MyAccountPage;
