/**
 * ItemRow
 * @module components/ItemRow
 */

import { useContext } from 'react';
import {
	Article,
	Image,
	ContentImg,
	Img,
	Body,
	LinkWrapper,
	Heading,
	Text,
	Value,
	SeparatorIcon,
} from './ItemRow.styles';
import { FaCamera, FaFileAlt, FaDownload, FaTh } from 'react-icons/fa';
import { LocalizationContext } from 'context/localization.context';
import { BreadCrumbsModel, ImageModel } from 'types/common';

interface ItemRowModel {
	item: ItemModel;
	trackClick: (hitId: string, trackId: string) => void;
}

interface ItemModel {
	modelType: string;
	link: string;
	category: string;
	name: string;
	fileType: string;
	fileSize: string;
	text?: string;
	image: ImageModel;
	breadcrumbs: BreadCrumbsModel[];
	hitId: string;
	trackId: string;
}

const ItemRow = ({ item, trackClick }: ItemRowModel) => {
	const { t }: any = useContext(LocalizationContext);

	const renderIcon = () => {
		switch (item.modelType) {
			case 'Document':
				return <FaFileAlt />;
			case 'Download':
				return <FaDownload />;
			case 'Category':
				return <FaTh />;
			case 'ProductDivisionPage':
				return <FaTh />;
			case 'ProductDetailPage':
				return <FaCamera />;
			case 'ArticlePage':
				return <FaCamera />;
			case 'Content':
				return (
					<ContentImg
						src="/assets/images/lindab-big-logo.png"
						alt="lindab logo"
					/>
				);
			default:
				return false;
		}
	};

	const renderBreadCrumbs = (breadcrumbs: BreadCrumbsModel[]) => {
		return (
			<ul className="m-0 p-0 inline-block list-none text-greyDarker">
				{breadcrumbs.map((item, key) => {
					const shouldHaveSeparator = key !== breadcrumbs.length - 1;
					return (
						<li className="inline-block text-greyDarker" key={key}>
							<a
								className="text-black text-p text-opacity-50 no-underline hover:underline active:underline"
								href={item.link}
								key={key}
							>
								{item.text}
							</a>
							{shouldHaveSeparator && <SeparatorIcon aria-hidden />}
						</li>
					);
				})}
			</ul>
		);
	};

	const getTarget = () => {
		switch (item.modelType) {
			case 'Document':
			case 'Download':
				return '_blank';
			default:
				return '_self';
		}
	};
	return (
		<Article>
			<LinkWrapper
				href={item.link}
				className="order-2 md:order-first"
				target={getTarget()}
				onClick={(e) => {
					trackClick && trackClick(item.hitId, item.trackId);
				}}
			>
				<Image>
					{item.image && item.image.thumbnail ? (
						<Img
							src={item.image.thumbnail}
							alt={item.image.alt ? item.image.alt : ''}
						/>
					) : (
						renderIcon()
					)}
				</Image>
			</LinkWrapper>
			<Body>
				<LinkWrapper
					href={item.link}
					target={getTarget()}
					onClick={() => {
						trackClick && trackClick(item.hitId, item.trackId);
					}}
				>
					{item && item.category && <Text>{item.category}</Text>}
					{item && item.name && <Heading>{item.name}</Heading>}
					{item && item.text && <Text>{item.text}</Text>}
				</LinkWrapper>

				{item.fileType && (
					<div className="relative whitespace-nowrap">
						<Text>{t('searchpage/filetype')}</Text>
						<Value>{item.fileType}</Value>
					</div>
				)}
				{item.fileSize && (
					<div className="relative whitespace-nowrap">
						<Text>{t('searchpage/filesize')}</Text>
						<Value>{item.fileSize}</Value>
					</div>
				)}
				{item.modelType === 'Category' &&
					item.breadcrumbs &&
					renderBreadCrumbs(item.breadcrumbs)}
			</Body>
		</Article>
	);
};

export default ItemRow;
