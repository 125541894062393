import axios from 'axios';

let cancelToken = axios.CancelToken.source();

export const customerSearch = (url: string, query: any) => {
	if (cancelToken) {
		cancelToken.cancel();
	}

	const CancelToken = axios.CancelToken;
	cancelToken = CancelToken.source();

	return axios({
		url: `${url}/customer/search?name=${query.name}&number=${query.number}&email=${query.email}`,
		method: 'post',
		cancelToken: cancelToken?.token,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'content-type': 'application/json; charset=utf-8',
		},
	})
		.then((response) => {
			return response.data || null;
		})
		.catch((error) => {
			if (axios.isCancel(error)) {
				return { cancel: true };
			}
			console.log(error);
		});
};
