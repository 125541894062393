import { useDispatch } from 'react-redux';
import { fetchConfArticle } from 'store/modules/configurator';

import {
	QuickSearchResultRow,
	RowTitle,
	ParentCategory,
	ArticleText,
	BoldText,
	Link,
} from './QuickSearch.styles';
const ContentResult = (props) => {
	const { link, name, parentCategory } = props;
	return (
		<QuickSearchResultRow>
			<Link href={link}>
				<RowTitle>{name}</RowTitle>
				{parentCategory && <ParentCategory>{parentCategory}</ParentCategory>}
			</Link>
		</QuickSearchResultRow>
	);
};

const ArticleResult = (props) => {
	const {
		modelType,
		link,
		name,
		itemCode,
		itemName,
		openModal,
		toggleConfModal,
	} = props;

	const isConfArticle = modelType === 'ConfArticle';

	const dispatch = useDispatch();

	const getConfArticle = async () => {
		try {
			toggleConfModal();
			await dispatch(fetchConfArticle(link));
		} catch (err) {
			console.log(err);
		}
	};

	const handleClick = (e) => {
		e.preventDefault();
		if (isConfArticle) {
			getConfArticle();
		} else {
			openModal({ link: link, isActive: true });
		}
	};

	return (
		<QuickSearchResultRow>
			<Link href="#" onClick={(e) => handleClick(e)}>
				<RowTitle>{name}</RowTitle>
				<ArticleText>
					<BoldText bold>#{itemCode}</BoldText>
					{itemName}
				</ArticleText>
			</Link>
		</QuickSearchResultRow>
	);
};

const QuickSearchResult = (props) => {
	const { modelType } = props;
	const isProductOrContent = modelType === 'Product' || modelType === 'Content';

	return isProductOrContent ? (
		<ContentResult {...props} />
	) : (
		<ArticleResult {...props} />
	);
};

export default QuickSearchResult;
