import styled from 'styled-components';
import { FaShoppingCart } from 'react-icons/fa';
import { deviceSmallMax, deviceMediumMin } from 'theme/mediaQueries';
import { white, orangeLight, blue } from 'theme/colors';
import { spacing05x } from 'theme/spacings';

export const CartIcon = styled(FaShoppingCart)`
	font-size: 2.4rem;
	position: relative;
	opacity: ${({ opacity }) => opacity};
	margin-right: 2rem;
`;

export const Items = styled.span`
	background-color: ${orangeLight};
	color: ${white};
	padding: 0rem ${spacing05x};
	position: absolute;
	font-size: 1rem;
	top: -0.6rem;
	left: 1.8rem;
`;
