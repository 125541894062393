/**
 * GridNew
 * @module components/GridNew
 */

import React from 'react';
import clsx from 'clsx';

// Interfaces
interface GridProps {
	/** Option to remove default grid padding */
	padding?: boolean;

	/** Option to remove default grid margin */
	margin?: boolean;

	/** Pass optional classnames for the html */
	className?: string;

	children?: React.ReactNode;
}

interface CellProps {
	/** Specifies the number of columns the cell spans */
	span?: number;

	/** Specifies the number of columns the cell spans on a type of device (desktop) */
	desktop?: number;

	/** Specifies the number of columns the cell spans on a type of device (tablet) */
	tablet?: number;

	/** Pass optional classnames for the html */
	className?: string;

	/** Option to remove default grid margin */
	margin?: boolean;

	children?: React.ReactNode;
}

const renderChildren = (children: any, margin: boolean) => {
	const renderedChildren = React.Children.map(children, (child) => {
		const props: any = child && child.props ? { ...child.props } : {};
		props.margin = margin;
		if (child) {
			return React.cloneElement(child, props);
		} else return false;
	});

	return renderedChildren;
};

/** Grid component using tailwind classes. */
const Grid = ({
	padding = true,
	margin = true,
	className,
	children,
}: GridProps): JSX.Element => {
	return (
		<div className="mx-auto max-w-site">
			{children && (
				<div
					className={clsx(
						className,
						'flex grid-item',
						margin && '-ml-4 -mr-4',
						padding && 'lg:py-6 lg:pl-6 py-4 pl-4'
					)}
				>
					{renderChildren(children, margin)}
				</div>
			)}
		</div>
	);
};

const getWidthClass = (span: number, margin: boolean) => {
	if (!span) {
		return span;
	}
	if (span === 12) {
		return 'w-full';
	} else if (margin) {
		return `w-grid-${span}/12`;
	} else {
		return `w-${span}/12`;
	}
};

/** Grid Cell component using tailwind classes. */
const Cell = ({
	span,
	desktop,
	tablet,
	className,
	margin = true,
	children,
}: CellProps): JSX.Element => {
	return (
		<div
			className={clsx(
				className,
				margin && 'mx-4',
				span && getWidthClass(span, margin),
				desktop && `lg:${getWidthClass(desktop, margin)}`,
				tablet && `md:${getWidthClass(tablet, margin)}`
			)}
		>
			{children}
		</div>
	);
};

export { Grid, Cell };
