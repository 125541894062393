/**
 * ColorBlocks
 * @module components/ColorBlocks
 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
	ColorList,
	ColorItem,
	ColorImg,
	Tooltip,
	Title,
	Text,
	Property,
	Value,
} from './ColorBlocks.styles';
import { LocalizationContext } from 'context/localization.context';

const ColorBlocks = ({ colors }) => {
	const { t } = useContext(LocalizationContext);
	return (
		<ColorList>
			{colors.map((item, i) => {
				return (
					<ColorItem key={i}>
						<ColorImg src={item.image.src} alt={item.image.alt} />

						<Tooltip>
							<Title>{item.colorName}</Title>
							{item.ncs && (
								<Text>
									<Property>{`${t('productdetailpage/nearest')} NCS`}</Property>
									<Value>{item.ncs}</Value>
								</Text>
							)}

							{item.ral && (
								<Text>
									<Property>{`${t('productdetailpage/nearest')} RAL`}</Property>
									<Value>{item.ral}</Value>
								</Text>
							)}
						</Tooltip>
					</ColorItem>
				);
			})}
		</ColorList>
	);
};

ColorBlocks.propTypes = {
	colors: PropTypes.arrayOf(
		PropTypes.shape({
			colorName: PropTypes.string,
			ncs: PropTypes.string,
			ral: PropTypes.string,
			image: PropTypes.shape({
				src: PropTypes.string,
				alt: PropTypes.string,
			}),
		})
	),
};

export default ColorBlocks;
