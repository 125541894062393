/**
 * Polyfill for forEach functions on objects to work on IE/Edge
 */
export const polyfillForEach = () => {
	if (
		typeof NodeList !== 'undefined' &&
		typeof NodeList.prototype.forEach !== 'function'
	) {
		NodeList.prototype.forEach = Array.prototype.forEach;
	}
};

/**
 * Polyfill for find functions on objects to work on IE/Edge
 */
export const polyfillFind = () => {
	if (!Array.prototype.find) {
		Object.defineProperty(Array.prototype, 'find', {
			value: function(predicate) {
				if (this == null) {
					throw new TypeError('this is null or not defined');
				}

				var obj = Object(this);
				var len = obj.length >>> 0;

				if (typeof predicate !== 'function') {
					throw new TypeError('predicate must be a function');
				}

				var thisArg = arguments[1];

				var index = 0;

				while (index < len) {
					var iValue = obj[index];
					if (predicate.call(thisArg, iValue, index, obj)) {
						return iValue;
					}
					index++;
				}

				return undefined;
			},
		});
	}
};

export const polyfillDownload = () => {
	if (typeof window !== 'undefined' && typeof document !== 'undefined') {
		const downloadAttributeSupport = 'download' in document.createElement('a');
		const msSaveBlob = typeof window.navigator.msSaveBlob !== 'undefined';

		if (!downloadAttributeSupport && msSaveBlob) {
			document.addEventListener('click', evt => {
				const { target } = evt;
				const { tagName } = target;

				if (tagName === 'A' && target.hasAttribute('download')) {
					evt.preventDefault();

					const { href } = target;
					const fileName = new URL(href).pathname.split('/').pop();

					const xhr = new XMLHttpRequest();

					xhr.open('GET', href);

					xhr.responseType = 'blob';

					xhr.onreadystatechange = () => {
						if (xhr.readyState !== 4) {
							return;
						}

						if (xhr.status === 200) {
							window.navigator.msSaveBlob(xhr.response, fileName);
						} else {
							console.error(
								'download-attribute-polyfill:',
								xhr.status,
								xhr.statusText
							);
						}
					};

					xhr.send();
				}
			});
		}
	}
};

export const polyFillStartsWith = () => {
	if (!String.prototype.startsWith) {
		Object.defineProperty(String.prototype, 'startsWith', {
			value: function(search, rawPos) {
				var pos = rawPos > 0 ? rawPos | 0 : 0;
				return this.substring(pos, pos + search.length) === search;
			},
		});
	}
};
