/**
 * Form - styles
 */

import styled, { css } from 'styled-components';
import { spacing1x, spacing2x } from 'theme/spacings';
import {
	black,
	greyDark,
	errorRed,
	white,
	greyDarker,
	greyPlaceholder,
	grey,
} from 'theme/colors';
import * as colors from 'theme/colors';
import { fontFamily, fontWeightBold } from 'theme/typography';
import { darken } from 'polished';

export const FieldWrapper = styled.div`
	margin-bottom: ${spacing2x};
`;

export const fieldCss = css`
	padding: ${spacing1x} ${spacing2x};
	border: 0.1rem solid ${greyDark};
	border-radius: 0.4rem;
	font-family: ${fontFamily};
	font-size: 1.6rem;
	line-height: 2.4rem;
	width: 100%;
	@media (max-width: 1150px) {
		font-size: 1.4rem;
		height: 3.2rem;
		padding: 0 ${spacing1x};
	}
	:read-only,
	:disabled {
		background-color: ${grey};
	}
	&.invalid {
		border-color: ${errorRed};
	}
	&[type='file'] {
		height: 4.2rem;
		padding: ${spacing1x};
	}
`;

export const Input = styled.input`
	${fieldCss};

	::placeholder {
		color: ${greyPlaceholder};
	}
`;

export const Select = styled.select`
	${fieldCss};

	:read-only {
		background-color: ${white};
	}
	:required:invalid {
		color: ${greyDarker};
	}
	> option {
		color: ${black};
	}
	> option[value=''][disabled] {
		display: none;
	}
`;

export const CheckboxLabel = styled.label`
	display: block;
	font-size: 1.6rem;
	line-height: 2.4rem;
`;

export const Textarea = styled(Input)`
	${fieldCss};
	height: 10rem;
	min-height: 10rem;
`;

export const Fieldset = styled.fieldset`
	border: 0;
	padding: 0;

	label {
		display: block;
		font-size: 1.6rem;
		line-height: 2.4rem;
	}
`;

export const Legend = styled.legend`
	display: block;
	font-size: 1.6rem;
	line-height: 2.4rem;
	font-weight: ${fontWeightBold};

	abbr {
		text-decoration: none;
	}
`;

export const Button = styled.button`
	margin: 0 ${spacing2x} 0 0;
	font-family: ${fontFamily};
	background-color: ${({ buttonColor }) => colors[buttonColor]};
	color: ${({ buttonColor }) =>
		buttonColor !== 'white' && buttonColor !== 'grey'
			? colors['white']
			: colors['greyDarkest']};
	border: 0.2rem solid ${({ buttonColor }) => colors[buttonColor]};
	:hover {
		background-color: ${({ buttonColor }) =>
			darken('0.1', colors[buttonColor])};
	}

	:disabled {
		color: ${greyDarker};
		background-color: ${grey};
		border: 0.2rem solid ${grey};
		cursor: inherit;
	}
`;
