/**
 * MobileMegaMenu
 * @module components/MobileMegaMenu
 */

import React, { useState, useEffect, useContext } from 'react';
import { LocalizationContext } from 'context/localization.context';

import PropTypes from 'prop-types';
import {
	MobileNav,
	MainLink,
	SublinkWrapper,
	PlusButton,
	MinusButton,
	LoginIcon,
} from './MobileMegaMenu.styles';
import { useSelector } from 'react-redux';
import { selectCartObject } from 'store/modules/cart';
import MobileMegaMenuSubSection from './MobileMegaMenuSubSection';

const MobileMegaMenu = (props) => {
	const { t } = useContext(LocalizationContext);
	const { items, expanded, myPagesLink, loginLink, logoutLink } = props;
	const [mainIndex, setMainIndex] = useState(-1);
	const [sublinkIndex1, setSubLinkIndex1] = useState(-1);
	const [sublinkIndex2, setSubLinkIndex2] = useState(-1);
	const { cartDisabled } = useSelector(selectCartObject);

	useEffect(() => {
		if (mainIndex !== -1) {
			setSubLinkIndex1(-1);
			setSubLinkIndex2(-1);
		}
	}, [mainIndex]);

	useEffect(() => {
		document.body.style.overflow = expanded ? 'hidden' : 'unset';
		return () => {
			document.body.style.overflow = 'unset';
		};
	}, [expanded]);

	const getMobileMegaMenuItems = () => {
		const megaMenu = items;
		if (!megaMenu || !megaMenu.items) return [];
		if (!cartDisabled && myPagesLink) {
			let mypagesItem = {
				link: myPagesLink?.link,
				active: false,
				text: myPagesLink?.text,
				isLink: true,
			};

			if (logoutLink) {
				mypagesItem['children'] = [
					{
						link: logoutLink,
						active: false,
						isLink: true,
						target: '',
						text: t('shared/header/logout'),
					},
				];
			}
			let mobileMegaMenu = {
				...megaMenu,
				items: [...megaMenu.items, mypagesItem],
			};

			return mobileMegaMenu.items;
		} else if (cartDisabled && loginLink && loginLink.link) {
			const loginItem = {
				link: loginLink.link,
				target: loginLink.target || null,
				isLogin: true,
				active: false,
				isLink: true,
				text: t('shared/header/login'),
			};
			let mobileMegaMenu = {
				...megaMenu,
				items: [...megaMenu.items, loginItem],
			};

			return mobileMegaMenu.items;
		} else {
			return megaMenu.items;
		}
	};

	const mobileMegaMenuItems = getMobileMegaMenuItems();
	return (
		<MobileNav
			id={'mobilenav'}
			aria-label={t('shared/header/mobilemenuarialabel')}
		>
			{mobileMegaMenuItems &&
				mobileMegaMenuItems.map((item, i) => {
					const isActive = mainIndex === i;
					const hasChildren = item.children && item.children.length > 0;
					const blockItem =
						item.children && item.children.find((i) => i.isBlock);
					return (
						<div key={`mobile-menu-${i}`}>
							<MainLink
								onClick={() => {
									let value = isActive ? -1 : i;
									setMainIndex(value);
								}}
							>
								{item.isLink ? (
									<a href={item.link} target={item.target || '_self'}>
										{item.text}
									</a>
								) : (
									<p>{item.text}</p>
								)}
								{hasChildren && (isActive ? <MinusButton /> : <PlusButton />)}
								{item.isLogin && <LoginIcon />}
							</MainLink>
							{hasChildren && (
								<SublinkWrapper active={isActive}>
									<MobileMegaMenuSubSection
										sublinkIndex={sublinkIndex1}
										i={i}
										setSubLinkIndex={setSubLinkIndex1}
										items={item.children.filter((i) => !i.isBlock)}
									/>
									{blockItem && (
										<MobileMegaMenuSubSection
											blockItem={blockItem}
											isBlockList={true}
											sublinkIndex={sublinkIndex2}
											i={i}
											setSubLinkIndex={setSubLinkIndex2}
											items={blockItem && blockItem.children}
										/>
									)}
								</SublinkWrapper>
							)}
						</div>
					);
				})}
		</MobileNav>
	);
};

MobileMegaMenu.propTypes = {
	title: PropTypes.string,
};

export default MobileMegaMenu;
