import axios from 'axios';
import { cancelPricingRequest, cancelToken } from 'store/api/cartApi';

export const quickAddSearch = (
	query: string,
	url: string,
	withPrice?: boolean
) => {
	if (!withPrice) {
		cancelPricingRequest();
	}

	const display = 5;
	return axios({
		url: `${url}?query=${query}&display=${display}`,
		method: 'post',
		cancelToken: cancelToken?.token,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'content-type': 'application/json; charset=utf-8',
		},
	})
		.then((response) => {
			return response.data || null;
		})
		.catch((error) => {
			console.log(error);
		});
};

export const quickAddDetail = async (url: string, withPrice?: boolean) => {
	try {
		const response = await axios({
			url: `${url}`,
			method: 'post',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'content-type': 'application/json; charset=utf-8',
			},
		});
		return response.data;
	} catch (error) {
		console.log(error);
	}
};

interface ItemProp {
	code: string;
	quantity?: number;
}

export const quickAddValidateCode = async (
	url: string,
	itemCodes: ItemProp[]
) => {
	const body = { itemCodes };
	try {
		const result = await axios({
			url: `${url}`,
			method: 'post',
			data: body,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'content-type': 'application/json; charset=utf-8',
			},
		});

		return (result.data && result.data.itemCodes) || null;
	} catch (err) {
		console.error(err);
		return null;
	}
};
