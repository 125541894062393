/**
 * ProductMatrix
 */

import Tooltip from 'components/ui/Tooltip';
import React from 'react';
import { ProductMatrixPropertyModel } from 'types/matrix-types';
import { ImageModel } from 'types/common';

interface Props {
	/** Heading for productMatrix */
	heading: string;

	/** Image for the article */
	image: ImageModel;

	/** Optional text for productMatrix */
	text?: string;

	/** Help text for productMatrix */
	helpText?: string;

	/** Array with table headers */
	properties: ProductMatrixPropertyModel[];

	/** Table row/rows */
	children: JSX.Element | JSX.Element[];
}

/** Main description for this component. */
const ProductMatrix = ({
	heading,
	image,
	text,
	helpText,
	properties,
	children,
}: Props): JSX.Element => {
	return (
		<div className="product-matrix">
			<table className="w-full">
				<caption className=" py-2 border-t border-b border-greyDark">
					<div className="flex items-center">
						<div className="w-10 min-w-10 h-10 mr-2">
							{image && (
								<img
									src={image.src}
									alt=""
									className="w-full h-full image-objectfit"
								/>
							)}
						</div>
						<span className="block text-p text-left font-semibold text-blue">
							{heading}
							{helpText && <Tooltip>{helpText}</Tooltip>}
						</span>
					</div>
					{text && (
						<span className="block text-left text-sm ml-12">{text}</span>
					)}
				</caption>
				{/* <thead>
					<tr className="sr-only">
						{properties.map((header, index) => {
							return (
								<th
									key={index}
									colSpan={header.name === 'customizedText' ? 3 : 1}
								>
									{header.translation}
								</th>
							);
						})}
					</tr>
				</thead> */}
				<tbody className="text-sm">{children}</tbody>
			</table>
		</div>
	);
};

export default ProductMatrix;
