export default ({ className, ...htmlAttributes }: { className?: string }) => (
	<svg
		width="40"
		height="40"
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg "
		{...htmlAttributes}
	>
		<rect width="40" height="40" rx="4" fill="#F2F2F2" />
		<path
			d="M18.0768 12.3076H14.3076C13.203 12.3076 12.3076 13.203 12.3076 14.3076V25.6922C12.3076 26.7968 13.203 27.6922 14.3076 27.6922H25.6922C26.7968 27.6922 27.6922 26.7968 27.6922 25.6922V21.923M19.9999 19.9999L27.6922 12.3076M27.6922 12.3076V17.1153M27.6922 12.3076H22.8845"
			stroke="#2A2A2A"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);
