/**
 * Button
 * @module components/Button
 */

import { ButtonColorTypes } from 'types/common';
import clsx from 'clsx';

interface ButtonModel {
	disabled?: boolean;
	type?: 'link' | 'button' | 'submit' | 'reset';
	buttonColor: ButtonColorTypes;
	url?: string;
	onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
	darkBackground?: any;
	target?: string;
	tabIndex?: number;
	children?: React.ReactNode;
	className?: string;
	name?: string;
	id?: string | number;
	noMarginBottom?: string;
	noMargin?: boolean;
}

const Button = ({
	type,
	buttonColor,
	onClick,
	url,
	children,
	disabled,
	className,
	target,
	tabIndex,
	name,
	id,
	noMarginBottom,
	noMargin = false,
}: ButtonModel) => {
	return (
		<>
			{type === 'link' ? (
				<a
					href={url}
					onClick={onClick}
					className={clsx(
						className,
						disabled
							? 'text-greyDarker bg-grey border-grey pointer-events-none'
							: `bg-${buttonColor} border-${buttonColor}`,
						buttonColor !== 'white' && buttonColor !== 'grey'
							? 'text-white'
							: 'text-greyDarkest',
						`font-standard button border-2 border-solid font-bold align-middle text-center cursor-pointer inline-block uppercase py-2 px-3 rounded-lg no-underline text-p smMax:py-0.5 smMax:px-1.5 smMax:text-sm hover:bg-${buttonColor}-hover smMax:${noMarginBottom}`,
						noMargin ? '' : 'mb-3 mt-4 '
					)}
					target={target}
					tabIndex={tabIndex}
					id={id?.toString()}
				>
					{children}
				</a>
			) : (
				<button
					onClick={onClick}
					disabled={disabled}
					className={clsx(
						className,
						buttonColor !== 'white' && buttonColor !== 'grey'
							? 'text-white'
							: 'text-greyDarkest',
						!disabled && `hover:bg-${buttonColor}-hover`,
						`font-standard button bg-${buttonColor} border-${buttonColor} border-2 border-solid font-bold align-middle text-center cursor-pointer inline-block uppercase mb-3 mt-4 py-2 px-3 rounded-lg no-underline text-p smMax:py-0.5 smMax:px-1.5 smMax:text-sm disabled:text-greyDarker disabled:bg-grey disabled:border-grey disabled:border-solid disabled:border-2 smMax:${noMarginBottom}`
					)}
					tabIndex={tabIndex}
					type={type || 'button'}
					name={name}
					id={id?.toString()}
				>
					{children}
				</button>
			)}
		</>
	);
};

export default Button;
