/**
 * ExpansionPanel
 * @module components/ExpansionPanel
 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LocalizationContext } from 'context/localization.context';
import {
	ShowMore,
	Expander,
	Link,
	Div,
	IconDown,
	IconUp,
} from './ExpansionPanel.styles';

const ExpansionPanel = ({ toggleExpansionPanel, expanded, className }) => {
	const { t } = useContext(LocalizationContext);

	return (
		<Link
			role="button"
			href="#related-links"
			aria-expanded={expanded}
			onClick={e => {
				e.preventDefault();
				toggleExpansionPanel();
			}}
			className={className}
		>
			<Expander>
				<Div>
					{expanded ? (
						<>
							<ShowMore>{t('shared/expansionpanel/showless')}</ShowMore>
							<IconUp />
						</>
					) : (
						<>
							<ShowMore>{t('shared/expansionpanel/showmore')}</ShowMore>
							<IconDown />
						</>
					)}
				</Div>
			</Expander>
		</Link>
	);
};

ExpansionPanel.propTypes = {
	toggleExpansionPanel: PropTypes.func,
	expanded: PropTypes.bool,
};

export default ExpansionPanel;
