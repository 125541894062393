/**
 * OrderHistoryFilter
 * @module components/OrderHistoryFilter
 */

import { useContext, useState, useEffect, ChangeEvent } from 'react';
import { LocalizationContext } from 'context/localization.context';
import { useMedia } from 'hooks';
import FilterFieldSet from 'components/framework/FilterFieldSet/FilterFieldSet';
import Icon from '../Icon';
import Button from '../Button';
import clsx from 'clsx';
import DatePickerInput from '../DatePickerInput';
import {
	FacetModel,
	OrderHistoryFilterModel,
} from 'types/order-history-filter';

interface OrderHistoryFilterProps {
	/** filter object */
	filter: OrderHistoryFilterModel;

	/** onFilter callback */
	onFilter: Function;

	/** onReset callback to reset filter object*/
	onReset: () => void;

	/** loading state*/
	loading?: boolean;

	/** Active market for user */
	activeMarket: string;

	/** user language */
	language?: string;
}

const OrderHistoryFilter: React.FC<OrderHistoryFilterProps> = ({
	filter,
	onFilter,
	onReset,
	loading,
	activeMarket,
	language,
}) => {
	const { t }: any = useContext(LocalizationContext);
	const [showFilter, setShowFilter] = useState(false);
	const isMobile = useMedia('(max-width: 767px)');
	const [filterState, setFilterState] = useState(filter);

	useEffect(() => {
		setFilterState(filter);
	}, [filter]);

	const toggleFilters = () => {
		setShowFilter(!showFilter);
	};

	const handleFilterInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const newState = { ...filterState };
		(newState[
			e.target.name as keyof OrderHistoryFilterModel
		] as OrderHistoryFilterModel[keyof OrderHistoryFilterModel]) = e.target.value;

		setFilterState(newState);
	};

	const adjustForTimezone = (date: Date) => {
		let timeOffsetInMS = date.getTimezoneOffset() * 60000;
		date.setTime(date.getTime() - timeOffsetInMS);
		return date;
	};

	const getDateString = (date: Date) => {
		return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
			'0' + date.getDate()
		).slice(-2)}`;
	};

	const handleDateChange = (name: string, value: Date) => {
		const newState = { ...filterState };
		(newState[
			name as keyof OrderHistoryFilterModel
		] as OrderHistoryFilterModel[keyof OrderHistoryFilterModel]) =
			(value && getDateString(adjustForTimezone(value))) || null;
		setFilterState(newState);
	};

	const handleFacet = (facetId: string, itemId: string, value: boolean) => {
		const newFacets: FacetModel[] = [...(filterState?.facets as FacetModel[])];

		const facet = newFacets.find((f) => f.id === facetId);
		const item = facet?.items.find((i: any) => i.id === itemId);
		item && (item.active = value);

		setFilterState({ ...filterState, facets: newFacets });
	};

	const handleApplyFilter = (e: any) => {
		e.preventDefault();
		onFilter && onFilter(filterState);
	};

	const handleReset = (e: any) => {
		e.preventDefault();
		const newState = { ...filterState };
		Object.keys(newState).forEach((key) => {
			if (key === 'facets' && newState?.facets) {
				newState?.facets.forEach((facet: FacetModel) => {
					facet.items &&
						facet.items.forEach((item) => {
							item.active = false;
						});
				});
			} else {
				(newState[
					key as keyof OrderHistoryFilterModel
				] as OrderHistoryFilterModel[keyof OrderHistoryFilterModel]) = '';
			}
		});
		setFilterState(newState);
		onReset && onReset();
	};

	const renderInput = (
		id: string,
		label: string,
		placeholder: string,
		value: string
	) => {
		return (
			<div className="relative mb-2 mt-1">
				<span className="sr-only">{label}</span>

				<input
					className="relative pl-7 flex-1 py-1 border rounded text-sm w-full"
					placeholder={placeholder}
					id={id}
					name={id}
					value={value}
					onChange={handleFilterInputChange}
					autoComplete="off"
					type="text"
				/>
				<Icon
					icon="filter"
					className="text-greyDark absolute mt-1 bottom-3 left-3 h-4"
					aria-hidden="true"
				/>
			</div>
		);
	};

	return (
		<div className="bg-grey px-6 py-4">
			{isMobile && (
				<div>
					<span className="font-semibold text-h5">
						{t('orderhistorypage/filterordersheading')}
					</span>
					<button
						className="cursor-pointer float-right mb-3"
						onClick={toggleFilters}
						title={t('shared/filter/showfilter')}
					>
						<span className="sr-only">{t('shared/filter/showfilter')}</span>
						<Icon
							size={2}
							icon="sliders"
							aria-hidden="true"
							className="text-greyDarkest"
						/>
					</button>
				</div>
			)}
			<form
				className={clsx(
					!showFilter && isMobile
						? 'hidden h-0 absolute'
						: 'visible auto static',
					'flex w-full gap-4 md:gap-6 lg:gap-8 flex-col lg:flex-row'
				)}
				action="#"
				method="post"
				onSubmit={handleApplyFilter}
				autoComplete={'off'}
			>
				<fieldset className="w-full lg:w-2/5">
					{!isMobile && (
						<legend className="text-p text-blue font-semibold m-0">
							{t('orderhistorypage/filterordersheading')}
						</legend>
					)}
					<div className="flex flex-col sm:flex-row gap-0 sm:gap-6 w-full lg:gap-8 mt-3px">
						<div className="w-full">
							{renderInput(
								'orderId',
								t('shared/order/ordernumber'),
								t('shared/order/ordernumberplaceholder'),
								filterState && (filterState.orderId as string)
							)}
							{renderInput(
								'projectId',
								t('shared/order/projectnumber'),
								t('shared/order/projectnumberplaceholder'),
								filterState && (filterState.projectId as string)
							)}
							{renderInput(
								'projectName',
								t('shared/order/projectname'),
								t('shared/order/projectnameplaceholder'),
								filterState && (filterState.projectName as string)
							)}
						</div>
						<div className="w-full">
							{renderInput(
								'goodsReceiverName',
								t('shared/order/goodsreceiver'),
								t('shared/order/goodsreceiverplaceholder'),
								filterState && (filterState.goodsReceiverName as string)
							)}
							{renderInput(
								'customerRequisition',
								t('shared/order/customerrequisition'),
								t('shared/order/customerrequisitionplaceholder'),
								filterState && (filterState.customerRequisition as string)
							)}
							{renderInput(
								'invoiceNumber',
								t('shared/order/invoiceNumber'),
								t('shared/order/invoiceNumber'),
								filterState && (filterState?.invoiceNumber as string)
							)}
						</div>
					</div>
				</fieldset>
				<div className="w-full flex flex-col sm:flex-row gap-2 sm:gap-6 lg:gap-8 lg:w-2/5">
					<fieldset className="w-full">
						<legend className="text-p font-semibold text-blue m-0">
							{t('orderhistorypage/orderdate')}
						</legend>
						<div className="flex my-2">
							<label
								className="text-p m-0 p-0 leading-14 w-1/6 lg:w-1/5"
								htmlFor="createdFrom"
							>
								{t('shared/order/from')}
							</label>
							<DatePickerInput
								className="m-0 flex-1-1-80 h-8"
								id={'createdFrom'}
								minDate={new Date('1970-01-01')}
								selected={
									filterState && filterState.createdFrom
										? new Date(filterState.createdFrom)
										: undefined
								}
								onChange={(date: Date) => handleDateChange('createdFrom', date)}
								activeMarket={activeMarket}
								language={language}
								size="small"
							/>
						</div>
						<div className="flex my-2">
							<label
								className="text-p m-0 p-0 leading-14 w-1/6 lg:w-1/5"
								htmlFor="createdTo"
							>
								{t('shared/order/to')}
							</label>
							<DatePickerInput
								className="m-0 flex-1-1-80 h-8"
								id={'createdTo'}
								minDate={new Date('1970-01-01')}
								selected={
									filterState && filterState.createdTo
										? new Date(filterState.createdTo)
										: undefined
								}
								onChange={(date: Date) => handleDateChange('createdTo', date)}
								activeMarket={activeMarket}
								language={language}
								size="small"
							/>
						</div>
					</fieldset>
					{filterState?.facets &&
						filterState?.facets.map((fieldset: any) => (
							<FilterFieldSet
								isOrderHistory
								className="w-full"
								name={fieldset.name}
								items={fieldset.items}
								facetId={fieldset.id}
								key={fieldset.name}
								onChange={handleFacet}
								showFieldset={showFilter}
							/>
						))}
				</div>

				<div className="w-full lg:w-1/5 lg:mt-4">
					<Button
						className="pl-0 h-8 flex items-center justify-center w-full -mb-1"
						type="submit"
						buttonColor="orange"
						disabled={loading}
					>
						<Icon icon="search" aria-hidden="true" className="mr-2" />

						{t('orderhistorypage/applyfilter')}
					</Button>

					<Button
						className="pl-0 h-8 flex items-center justify-center w-full"
						type="button"
						buttonColor={'greyDarker'}
						onClick={handleReset}
						disabled={loading}
					>
						<Icon icon="redo" aria-hidden="true" className="mr-2" />

						{t('shared/filter/resetfilter')}
					</Button>
				</div>
			</form>
		</div>
	);
};

export default OrderHistoryFilter;
