/**
 * MobileSelect
 * @module components/MobileSelect
 */
import {
	Select,
	Wrapper,
	Option,
	Link,
	SelectedValue,
} from './MobileSelect.styles';
import { useToggle } from 'hooks';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { ChangeEvent, KeyboardEvent } from 'react';
import { MouseEvent } from 'react';
import clsx from 'clsx';

interface ItemModel {
	value?: any;
	name: string | number;
	id?: string | number;
}

interface MobileSelectModel {
	id: string;
	className?: string;
	preselected?: string | number;
	items: ItemModel[];
	onClick: any;
	onChange: any;
	queryBase: string;
	isMobile: boolean;
}

// This component shows a native select if the device has orientation,
// otherwise show a dropdown for smaller screens.
const MobileSelect = (props: MobileSelectModel) => {
	const {
		id,
		className,
		preselected,
		items,
		onClick,
		onChange,
		queryBase,
		isMobile,
	} = props;
	const [expanded, toggle, ref] = useToggle(false, true);

	const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const value = e.target.value;
		onChange(e, value);
	};

	const handleClick = () => {
		toggle();

		if (isMobile) {
			ref.current.focus();
			return true;
		}
	};

	const handleLinkClick = (
		e: MouseEvent<HTMLAnchorElement>,
		id: string | number
	) => {
		onClick(e, id);
		toggle();
	};

	const handleKeyboardNavigation = (e: KeyboardEvent<HTMLSelectElement>) => {
		if (e.keyCode === 13) {
			toggle();
			if (expanded) {
				ref.current.focus();
			}
		}
	};

	const handleBlur = () => {
		if (expanded) {
			toggle();
		}
	};

	const value = items && items.find((x) => x.id === preselected);
	if (items) {
		return (
			<Wrapper ref={ref}>
				{isMobile && (
					<Select
						id={id}
						name={id}
						className={className}
						onChange={handleChange}
						value={value && value.id}
						ref={ref}
						tabIndex={0}
						onClick={handleClick}
						onKeyDown={handleKeyboardNavigation}
						onBlur={handleBlur}
					>
						{items.map((item, i) => {
							return (
								<Option value={item.id} key={i}>
									{item.name}
								</Option>
							);
						})}
					</Select>
				)}
				<SelectedValue
					onClick={handleClick}
					//@ts-ignore
					onKeyDown={handleKeyboardNavigation}
					role="listbox"
					aria-expanded={expanded}
					aria-controls={`${id}-list`}
					aria-owns={id}
					tabIndex={!isMobile ? 0 : -1}
				>
					{value && value.name}
					{expanded ? <FiChevronUp /> : <FiChevronDown />}
				</SelectedValue>
				{!isMobile && (
					<ul
						className={clsx(
							'absolute top-3 bg-white p-2 z-100 list-none border-top-none border rounded border-greyDark ',
							expanded ? 'block' : 'hidden'
						)}
						id={`${id}-list`}
						aria-hidden={expanded}
						onBlur={handleBlur}
					>
						{items.map((item, i) => {
							return (
								<Link key={`${id}-${i}`}>
									<a
										href={`?${queryBase}=${item.value}`}
										//@ts-ignore
										selected={preselected}
										onClick={(e) => handleLinkClick(e, item.id ? item.id : '')}
									>
										{item.name}
									</a>
								</Link>
							);
						})}
					</ul>
				)}
			</Wrapper>
		);
	} else {
		return null;
	}
};

export default MobileSelect;
