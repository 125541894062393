// Large (desktop)
export const deviceLargeMax = '1190px';
export const deviceLargeMin = '1025px';

// Medium (tablet landscape)
export const deviceMediumLargeMax = '1024px';
export const deviceMediumLargeMin = '770px';

// Medium (tablet portrait)
export const deviceMediumMax = '769px';
export const deviceMediumMin = '768px';

// Small (phone)
export const deviceSmallMax = '767px';
export const deviceSmallMin = '0px';
