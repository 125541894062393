import styled from 'styled-components';
import RichText from 'components/ui/RichText';
import { spacing2x } from 'theme/spacings';
import { FiAlignLeft } from 'react-icons/fi';
import { IconCss } from '../LocationRow.styles';
import { deviceSmallMax } from 'theme/mediaQueries';
import { blue } from 'theme/colors';
import { H2Css, H3Css, H4Css, H5Css } from 'components/ui/Typography';
import { fontWeightLight } from 'theme/typography';

export const MilkrunWrapper = styled.div`
	padding: ${(props) => (props.padTop ? '1.6rem' : '0 1.6rem')};
	overflow: hidden;
	padding: 0 1.6rem 0 0;
	padding-left: 18.2rem;
	@media (max-width: ${deviceSmallMax}) {
		padding-left: 0rem;
	}
`;

export const MilkrunRichText = styled(RichText)`
	visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
	height: ${(props) => (props.open ? 'auto' : '0')};
	overflow: hidden;
	& > h1:first-child {
		margin-top: 0;
	}
	h1 {
		${H2Css};
		color: ${blue};
		font-weight: ${fontWeightLight};
	}
	h2 {
		${H3Css};
	}
	h3 {
		${H4Css};
	}
	h4 {
		${H5Css};
	}
	h5 {
		font-size: 1.6rem;
	}
	p {
		font-size: 1.6rem;
	}
	a {
		font-size: 1.6rem;
	}
	ul,
	ol {
		font-size: 1.6rem;
	}
`;

export const MilkRunToggleWrapper = styled.div`
	display: flex;
	flex-wrap: none;
	text-align: right;
	margin: ${(props) =>
		props.isOpen
			? `${spacing2x} ${spacing2x} 0 ${spacing2x}`
			: `0 ${spacing2x}`};
	min-width: 100%;
	justify-content: flex-end;
`;

export const MilkRunIcon = styled(FiAlignLeft)`
	${IconCss};
	margin-top: 0;
	margin-bottom: 1rem;
`;

export const MilkrunText = styled.p`
	margin: 0;
	padding: 0;
	font-size: 1.6rem;
	line-height: 2.4rem;
	color: ${blue};
`;
