/**
 * Tooltip
 * @module components/Tooltip
 */

import { useRef, useState, useEffect } from 'react';
import { Button, Icon, Popout } from './Tooltip.styles';

let buttonRefRef: HTMLButtonElement;

interface ToolTipModel {
	children: JSX.Element | JSX.Element[] | string;
	title?: string;
	icon?: any;
	hover?: boolean;
	noPadding?: boolean;
}

const Tooltip = ({
	children,
	title,
	icon,
	hover = false,
	noPadding,
}: ToolTipModel) => {
	const buttonRef = useRef<HTMLButtonElement>(null);
	const popoutRef = useRef<HTMLDivElement>(null);
	const [expanded, setExpanded] = useState(false);

	const handleClickOutside = (e: any) => {
		if (buttonRef.current && !buttonRef.current.contains(e.target)) {
			setExpanded(false);
		}
	};

	const handleKeyPress = (e: KeyboardEvent) => {
		if ((e.keyCode || e.which) === 27) {
			setExpanded(false);
		}
	};

	const handleBlur = () => {
		setExpanded(false);
	};

	const handleTouchStart = () => {
		document.addEventListener('touchend', handleTouchEnd);
	};

	const handleTouchEnd = () => {
		document.removeEventListener('touchend', handleTouchEnd);
		setExpanded(false);
	};

	const handleTouchMove = () => {
		document.removeEventListener('touchend', handleTouchEnd);
	};

	useEffect(() => {
		if (buttonRef.current) {
			buttonRefRef = buttonRef.current;
			buttonRefRef.addEventListener('blur', handleBlur);
		}
		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('keydown', handleKeyPress);
		document.addEventListener('touchstart', handleTouchStart);
		document.addEventListener('touchmove', handleTouchMove);
		return () => {
			if (buttonRefRef && buttonRefRef.removeEventListener) {
				buttonRefRef.removeEventListener('blur', handleBlur);
			}
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('keydown', handleKeyPress);
			document.removeEventListener('touchstart', handleTouchStart);
			document.removeEventListener('touchmove', handleTouchMove);
		};
	});

	// Makes sure the tooltip is within view
	useEffect(() => {
		if (window && expanded && popoutRef && popoutRef.current) {
			let bounding = popoutRef.current.getBoundingClientRect();
			let viewportWidth =
				window.innerWidth || document.documentElement.clientWidth;

			if (bounding.right > viewportWidth) {
				popoutRef.current.style.whiteSpace = 'normal';
				popoutRef.current.style.minWidth = '320px';
				popoutRef.current.style.left = `-110px`;
			}
		}
	}, [expanded]);

	const openTooltip = () => {
		if (expanded) {
			setExpanded(false);
		}

		window.setTimeout(() => {
			setExpanded(true);
		}, 100);
	};

	return (
		<span className="relative inline-block">
			{children && (
				<Button
					type="button"
					noPadding={noPadding}
					aria-label={`${title}`}
					onMouseOver={() => hover && setExpanded(true)}
					onMouseOut={() => hover && setExpanded(false)}
					onClick={openTooltip}
					ref={buttonRef}
					//@ts-ignore
					expanded={expanded}
				>
					{icon ? icon : <Icon aria-hidden="true" />}
				</Button>
			)}

			{expanded && children && (
				<Popout ref={popoutRef} role="status">
					{children}
				</Popout>
			)}
		</span>
	);
};

export default Tooltip;
