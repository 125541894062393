interface LabelProp {
	htmlFor: string;
	children?: JSX.Element | string;
	required: boolean;
}

interface ErrorSpanProp {
	fieldId: string;
	invalid?: boolean;
	children?: JSX.Element;
	props?: any;
}

export const Label = ({ htmlFor, required, children }: LabelProp) => (
	<label
		className="block text-2xl leading-9 font-bold text-black"
		htmlFor={htmlFor}
	>
		{children}
		{required && (
			<abbr className="no-underline" title="Obligatoriskt">
				*
			</abbr>
		)}
	</label>
);

export const ErrorSpan = ({
	fieldId,
	invalid,
	children,
	...props
}: ErrorSpanProp) => (
	<label
		className="block text-2xl leading-9 text-red"
		id={`form${fieldId}__desc`}
		aria-live="polite"
		hidden={!invalid}
		{...props}
	>
		{children}
	</label>
);
