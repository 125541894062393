/**
 * LanguageSelector
 * @module components/LanguageSelectorOld
 */

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
	DropdownItem,
	LanguageSelectorWrapper,
	Text,
	Link,
	GlobeIcon,
	LanguageSelectorDropDown,
	ArrowDown,
	ArrowUp,
	DropDownToggle,
	VisuallyHidden,
	ToggleText,
} from './LanguageSelectorOld.styles';
import { LocalizationContext } from 'context/localization.context';

const renderDropDownItem = (item) => {
	if (!item.isLink || item.active) {
		return (
			<Text bold={!item.isLink} active={item.active}>
				{item.text}
			</Text>
		);
	} else {
		return (
			<Link
				href={item.link}
				target={item.target ? item.target : '_self'}
				active={item.active}
			>
				{item.text}
			</Link>
		);
	}
};

const LanguageSelectorOld = ({ languages, mobile }) => {
	const { t } = useContext(LocalizationContext);
	const [expanded, setExpanded] = useState(false);
	const [offsetHeight, setOffset] = useState(false);
	const active = languages?.find((x) => x.active);

	return (
		<LanguageSelectorWrapper mobile={mobile}>
			<VisuallyHidden id={mobile ? 'langlabelmobile' : 'langlabeldesktop'}>
				{t('shared/header/languagechooser')}
			</VisuallyHidden>
			<DropDownToggle
				onClick={() => {
					setOffset(document.getElementById('mobilenav').offsetHeight);
					setExpanded(!expanded);
				}}
				aria-haspopup="listbox"
				aria-expanded={expanded}
				aria-controls={mobile ? 'langselectmobile' : 'langselectdesktop'}
				aria-labelledby={mobile ? 'langlabelmobile' : 'langlabeldesktop'}
			>
				<GlobeIcon aria-hidden="true" />
				{active && <ToggleText title={active.text}>{active.text}</ToggleText>}
				{expanded ? (
					<ArrowUp aria-hidden="true" />
				) : (
					<ArrowDown aria-hidden="true" />
				)}
			</DropDownToggle>
			{expanded && (
				<LanguageSelectorDropDown
					offSetHeight={offsetHeight}
					mobile={mobile}
					role="listbox"
					id={mobile ? 'langselectmobile' : 'langselectdesktop'}
				>
					{languages?.map((item, key) => {
						return (
							<DropdownItem key={key}>{renderDropDownItem(item)}</DropdownItem>
						);
					})}
				</LanguageSelectorDropDown>
			)}
		</LanguageSelectorWrapper>
	);
};

LanguageSelectorOld.propTypes = {
	languages: PropTypes.arrayOf(
		PropTypes.shape({
			marketId: PropTypes.string,
			language: PropTypes.string,
			languageName: PropTypes.string,
			link: PropTypes.string,
			text: PropTypes.string,
			active: PropTypes.boolean,
			isLink: PropTypes.boolean,
		})
	),
};

export default LanguageSelectorOld;
