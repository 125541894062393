import { GenericWarning, SendTableError, ServerError } from 'common/helpers';
import { toastWithLink } from 'common/toastHelpers';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AddToCart, sendCartTable, selectCartObject } from 'store/modules/cart';
import { AddToCartModel } from 'types/cart-types';
import IframeBlock from '../IframeBlock';

interface Props {
	/** Iframe title */
	title: string;

	/** Iframe url */
	url: string;

	/** Optional className */
	className?: string;

	translations: {
		'/frontend/shared/toast/cartaddsuccess': any;
		'/frontend/shared/toast/cartupdateerror': any;
	};
}

/** Main description for this component. */
const ConfiguratorIframeBlock = ({
	title,
	url,
	className,
	translations,
}: Props): JSX.Element => {
	const dispatch = useDispatch();
	const { cartLink } = useSelector(selectCartObject);
	const handleAddToCart = async (items: AddToCartModel[]) => {
		try {
			await dispatch(AddToCart('POST', items));
			toastWithLink(
				translations['/frontend/shared/toast/cartaddsuccess'],
				cartLink?.link,
				toast.TYPE.SUCCESS
			);
		} catch (err) {
			if (err instanceof ServerError) {
				toast(translations['/frontend/shared/toast/cartupdateerror'], {
					type: toast.TYPE.ERROR,
				});
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		}
	};

	const handleSendTableData = async (body: string) => {
		try {
			const data = await dispatch(sendCartTable('POST', body));

			if (data.errorCode === 200) {
				toast(data.message, { type: toast.TYPE.SUCCESS });
			}
		} catch (err) {
			// Handle errors during the API call
			if (err instanceof SendTableError) {
				toast(err.message, {
					type: toast.TYPE.ERROR,
				});
			} else {
				toast(err.message || 'An error occurred', { type: toast.TYPE.ERROR });
			}
		}
	};

	useEffect(() => {
		window.addEventListener('message', function (e) {
			// Get the sent data
			if (e.data && typeof e.data === 'string') {
				const decodedData = JSON.parse(e.data);
				if (
					decodedData?.data?.length > 0 &&
					decodedData?.type === 'ADD_TO_CART'
				) {
					let newArray: any = [];
					decodedData?.data.forEach((item: any) =>
						newArray.push({
							...item,
							quantity: parseFloat(item.quantity),
						})
					);
					handleAddToCart(newArray);
				} else if (
					decodedData?.data?.length > 0 &&
					decodedData?.type === 'CART_TABLE'
				) {
					handleSendTableData(e.data);
				}
			}
		});
		//eslint-disable-next-line
	}, []);
	return <IframeBlock title={title} url={url} className="h-400 mb-20" />;
};

export default ConfiguratorIframeBlock;
