/**
 * Form
 * @module components/Form
 */

import styled, { css } from 'styled-components';
import { spacing1x, spacing2x } from 'theme/spacings';
import {
	black,
	greyDark,
	errorRed,
	white,
	blue,
	greyDarker,
	greyPlaceholder,
	green,
	grey,
} from 'theme/colors';
import * as colors from 'theme/colors';
import { fontFamily, fontWeightBold, fontWeightNormal } from 'theme/typography';
import { paragraphCss } from '../Typography';
import { deviceSmallMax } from 'theme/mediaQueries';
import { darken } from 'polished';

export const fieldCss = css`
	padding: ${spacing1x} ${spacing2x};
	border: 0.1rem solid ${greyDark};
	border-radius: 0.4rem;
	font-family: ${fontFamily};
	font-size: 1.6rem;
	line-height: 2.4rem;
	width: 100%;
	@media (max-width: 1150px) {
		font-size: 1.4rem;
		height: 3.2rem;
	}
	:read-only,
	:disabled {
		background-color: ${grey};
	}
	:invalid {
		border-color: ${props => (props.touched ? errorRed : greyDark)};
	}
	:valid {
		border-color: ${props => (props.touched ? green : greyDark)};
	}
`;

export const Input = styled.input`
	${fieldCss};

	::placeholder {
		color: ${greyPlaceholder};
	}
`;

export const SelectField = styled.select`
	${fieldCss};
	height: 4.2rem;

	:read-only {
		background-color: ${white};
	}
	:required:invalid {
		color: ${greyDarker};
	}
	> option {
		color: ${black};
	}
	> option[value=''][disabled] {
		display: none;
	}
	@media (max-width: ${deviceSmallMax}) {
		padding: 0 ${spacing1x};
	}
`;
export const SelectPlaceholderOption = styled.option`
	color: ${greyDark};
`;

export const ErrorText = styled.span`
	${paragraphCss};
	color: ${errorRed};
	margin-top: 1.2rem;
	display: ${props => (props.displayBlock ? 'block' : 'inline')};
`;

export const Label = styled.label`
	${paragraphCss};
	font-weight: ${fontWeightBold};
`;

export const DefaultButton = styled.button`
	${paragraphCss};
	font-weight: ${fontWeightNormal};
	vertical-align: middle;
	text-align: center;
	padding: ${spacing1x} 1.2rem;
	display: inline-block;
	border-radius: 0.5rem;
	text-transform: uppercase;
	transition: background-color 0.4s ease-out, border-color 0.4s ease-out;
	cursor: pointer;
	text-decoration: none;
	background-color: ${({ buttonColor }) => colors[buttonColor]};
	color: ${({ buttonColor }) =>
		buttonColor !== 'white' && buttonColor !== 'grey'
			? colors['white']
			: colors['greyDarkest']};
	border: 0.2rem solid ${({ buttonColor }) => colors[buttonColor]};
	:hover {
		background-color: ${({ buttonColor }) =>
			darken('0.1', colors[buttonColor])};
	}

	:hover {
		background-color: ${darken('0.1', blue)};
	}

	:disabled {
		color: ${greyDarker};
		background-color: ${grey};
		border: 0.2rem solid ${grey};
		cursor: inherit;
	}
	& svg {
		color: inherit;
		font-size: 2rem;
		vertical-align: middle;
		margin-left: 0.6rem;
	}
	@media (max-width: ${deviceSmallMax}) {
		font-size: 1.3rem;
		padding: 0.2rem 0.6rem;
		& svg {
			font-size: 1.6rem;
		}
	}
`;

export const StyledTextArea = styled.textarea`
	width: 100%;
	height: 10rem;
	padding: ${spacing1x};
	border: 0.1rem solid ${greyDark};
	border-radius: 0.4rem;
	min-width: 100%;
	max-width: 100%;
	min-height: 10rem;

	font-family: ${fontFamily};
	font-size: 1.6rem;
	line-height: 2.4rem;
	::placeholder {
		color: ${greyPlaceholder};
	}
	:read-only {
		background-color: ${grey};
	}
	:invalid {
		border-color: ${props => (props.touched ? errorRed : grey)};
	}
	:valid {
		border-color: ${props => (props.touched ? green : grey)};
	}

	@media (max-width: ${deviceSmallMax}) {
		margin-bottom: ${spacing2x};
	}
`;
