/**
 * Layout
 * @module components/Layout
 */

import { Content } from './Layout.styles';
import { ReactNode } from 'react';
import clsx from 'clsx';

type LayoutProps = {
	backgroundColor?: string;
	className?: string;
	childClassName?: string;
	children: ReactNode;
};

const Layout = ({
	backgroundColor,
	className,
	children,
	childClassName,
}: LayoutProps) => (
	<div
		className={clsx(
			backgroundColor ? `bg-${backgroundColor}` : 'bg-transparent',
			'block overflow-hidden',
			className
		)}
	>
		<Content className={childClassName}>{children}</Content>
	</div>
);

export default Layout;
