/**
 * StartPage
 */
import ContentArea from 'components/framework/ContentArea';
import SectionHeader from 'components/ui/SectionHeader';
import { StartPageModel } from 'types/page-types';

const StartPage = ({
	heading,
	text,
	contentArea,
	topContentArea,
}: StartPageModel): JSX.Element => {
	return (
		<main id="main-content">
			<ContentArea contentArea={topContentArea} />
			<SectionHeader
				heading={heading || ''}
				text={text}
				size={'large'}
				headingLevel="h2"
				isInEditMode={false}
			/>
			<ContentArea contentArea={contentArea} />
		</main>
	);
};

export default StartPage;
