import { useEffect, useState } from 'react';

//Extracted from react-use lib https://github.com/streamich/react-use

const isClient =
	typeof window === 'object' && typeof window.matchMedia === 'function';

export const useMedia = (query, defaultState = false) => {
	const [state, setState] = useState(defaultState);

	useEffect(() => {
		if (isClient) {
			let mounted = true;
			const mql = window.matchMedia(query);
			const onChange = () => {
				if (!mounted) {
					return;
				}
				setState(mql.matches);
			};

			mql.addListener(onChange);
			setState(mql.matches);

			return () => {
				mounted = false;
				mql.removeListener(onChange);
			};
		}
		//eslint-disable-next-line
	}, [query]);
	return state;
};
