/**
 * SpecificationsList
 * @module components/SpecificationsList
 */

import { useContext } from 'react';
import PropTypes from 'prop-types';
import {
	Heading,
	SpecificationList,
	Row,
	Value,
	Property,
	VisuallyHiddenTd,
	BlueText,
} from './SpecificationsList.styles';
import { LocalizationContext } from 'context/localization.context';
import ColorBlocks from 'components/ui/ColorBlocks';

const SpecificationsList = ({
	specifications,
	heading,
	prices,
	unit,
	currency,
	className,
}) => {
	const { t } = useContext(LocalizationContext);
	const renderPricelist = (key, i) => {
		if (specifications.dictionary[key]) {
			return (
				<Row key={i}>
					<Property>{key}</Property>
					{prices && i === 0 ? (
						<Value priceList={prices}>
							<BlueText>
								{specifications.dictionary[key]}
								{currency && ` ${currency}`}
							</BlueText>
							{unit && ` /${unit}`}
						</Value>
					) : prices && i === 1 ? (
						<Value priceList={prices}>
							{specifications.dictionary[key]}
							{currency && unit && ` ${currency}/${unit}`}
						</Value>
					) : prices && i === 2 ? (
						<Value priceList={prices}>
							{`- ${specifications.dictionary[key]} %`}
						</Value>
					) : null}
				</Row>
			);
		} else {
			return null;
		}
	};

	return (
		<SpecificationList as="table" className={className}>
			{specifications &&
				specifications.dictionary &&
				Object.keys(specifications.dictionary).length > 0 && (
					<Heading as="caption">{heading}</Heading>
				)}

			<thead className="sr-only">
				<tr>
					<th scope="col">{t('shared/specificationslist/property')}</th>
					<th scope="col">{t('shared/specificationslist/value')}</th>
				</tr>
			</thead>
			<tbody>
				{specifications &&
					specifications.dictionary &&
					Object.keys(specifications.dictionary).length > 0 && (
						<>
							{prices &&
							specifications.dictionary[
								Object.keys(specifications.dictionary)[0]
							] &&
							specifications.dictionary[
								Object.keys(specifications.dictionary)[1]
							] ? (
								Object.keys(specifications.dictionary).map((key, i) =>
									renderPricelist(key, i)
								)
							) : (prices &&
									specifications.dictionary[
										Object.keys(specifications.dictionary)[0]
									] === null) ||
							  specifications.dictionary[
									Object.keys(specifications.dictionary)[1]
							  ] === null ? (
								<Row>
									<VisuallyHiddenTd>{heading}</VisuallyHiddenTd>
									<Value priceList={prices}>
										<BlueText>
											{t('articledetailconfpage/priceondemand')}
										</BlueText>
									</Value>
								</Row>
							) : !prices ? (
								Object.keys(specifications.dictionary).map((key, i) => {
									return (
										<Row key={key}>
											<Property>{key}</Property>
											<Value priceList={prices}>
												{specifications.dictionary[key]}
											</Value>
										</Row>
									);
								})
							) : null}
						</>
					)}

				{specifications.colors && specifications.colors.length > 0 && (
					<Row key="productColors">
						<Property>
							{specifications.colors.length === 1
								? t('shared/specificationslist/color')
								: t('shared/specificationslist/colors')}
						</Property>
						<Value colorBlocks={true}>
							<ColorBlocks colors={specifications.colors} />
						</Value>
					</Row>
				)}
			</tbody>
		</SpecificationList>
	);
};

SpecificationsList.propTypes = {
	rangeSpecifications: PropTypes.shape({
		dictionary: PropTypes.shape,
		colors: PropTypes.arrayOf(
			PropTypes.shape({
				colorName: PropTypes.string,
				ncs: PropTypes.string,
				ral: PropTypes.string,
				image: PropTypes.shape({
					src: PropTypes.string,
					alt: PropTypes.string,
				}),
			})
		),
	}),
	heading: PropTypes.string,
	prices: PropTypes.bool,
	unit: PropTypes.string,
	currency: PropTypes.string,
	activeMarket: PropTypes.string,
	className: PropTypes.string,
};

export default SpecificationsList;
