import React, { useEffect } from 'react';
import * as Pages from './pages';
import './styles/global.scss';
import './styles/tailwind.output.css';
import objectFitImages from 'object-fit-images';
import { StyledToastContainer } from 'components/ui/StyledToast';
import { LocalizationProvider } from 'context/localization.context';

import {
	//polyfillFind,
	polyfillForEach,
	polyfillDownload,
	//polyFillStartsWith,
} from 'common/polyfills';

import StandardLayout from 'layouts/StandardLayout';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
import ViewProvider from 'context/view.context';

// polyfillFind();
polyfillForEach();
polyfillDownload();
// polyFillStartsWith();

const App = (props) => {
	const { footer, header, api, localization, siteType } = props.framework;
	let Page = Pages.get(props);

	useEffect(() => {
		objectFitImages();

		if (window.dataLayer && siteType === 'Lindab') {
			window.dataLayer.push({
				event: 'epilogin_status',
				loggedIn: header.userObject.customers.length > 0 ? true : false,
			});
		}
		//eslint-disable-next-line
	}, []);

	const PageLayout =
		props.modelType === 'CheckoutPage' ? LogoOnlyLayout : StandardLayout;

	return (
		<ViewProvider>
			<LocalizationProvider model={localization}>
				<PageLayout
					header={header}
					footer={footer}
					api={api}
					modelType={props.modelType}
					siteType={siteType}
				>
					<Page {...props} />
					<StyledToastContainer />
				</PageLayout>
			</LocalizationProvider>
		</ViewProvider>
	);
};

export default App;
