import React, { useState, useRef, useEffect } from 'react';

interface TooltipProps {
	imageUrl?: string;
	alt?: string;
	children: React.ReactNode;
	delay?: number; // Optional delay prop for hover show/hide (milliseconds)
	id?: string; // Optional ID prop for the tooltip element
}

const Tooltip: React.FC<TooltipProps> = ({
	imageUrl,
	alt,
	children,
	delay = 0,
	id,
}) => {
	// State to manage tooltip visibility
	const [hover, setHover] = useState(false);

	// Ref to store the tooltip element
	const tooltipRef = useRef<HTMLDivElement>(null);
	const triggerRef = useRef<HTMLDivElement>(null); // Ref for the trigger element (image or other)

	// Timeout ID for show/hide delays
	let timeoutId: any | null = null;

	// Functions to handle hover events with delay
	const handleMouseEnter = () => {
		if (timeoutId) clearTimeout(timeoutId);
		timeoutId = setTimeout(() => setHover(true), delay);
	};
	const handleMouseLeave = () => {
		if (timeoutId) clearTimeout(timeoutId);
		timeoutId = setTimeout(() => setHover(false), delay);
	};

	// Function to dismiss tooltip on click anywhere
	const handleClick = () => setHover(!hover);

	// Function to handle clicks outside the tooltip (excluding trigger element)
	const handleClickOutside = (event: MouseEvent) => {
		if (
			tooltipRef.current &&
			!tooltipRef.current.contains(event.target as Node) &&
			!triggerRef.current?.contains(event.target as Node)
		) {
			setHover(false);
		}
	};

	// Add/remove click listener on document for outside clicks and re-enter handling
	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => document.removeEventListener('click', handleClickOutside);
	}, [hover]); // Re-run useEffect only on hover state change

	// Generate a unique ID for the tooltip if not provided
	const tooltipId = id || `tooltip-${Math.random().toString(36).substr(2, 9)}`;

	return (
		<div
			className="relative flex items-center text-gray-500 hover:text-gray-600"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={handleClick} // Dismiss tooltip on click on trigger element
			aria-haspopup="true"
			aria-expanded={hover}
			ref={triggerRef}
		>
			{imageUrl && (
				<img className="h-8" src={imageUrl} alt={alt || 'Tooltip Image'} /> // Assuming image size is small
			)}
			<div className="relative">
				{hover && (
					<div
						className="absolute bottom-0 inline-block px-4 py-3 mb-5 -ml-32 text-white bg-greyDarkest rounded-lg transition ease-out duration-300"
						role="tooltip"
						id={tooltipId} // Set unique ID for the tooltip
						aria-labelledby={triggerRef.current?.id || tooltipId} // Associate tooltip with trigger element
					>
						<span
							className="absolute bottom-0 right-0 w-5 h-5 -mb-2 transform rotate-45 bg-greyDarkest"
							style={{ left: '13%' }}
						/>
						{children}
					</div>
				)}
			</div>
		</div>
	);
};

export default Tooltip;
