/**
 * ArticlePage
 * @module pages/ArticlePage
 */

import ContentArea from 'components/framework/ContentArea';
import SectionHeader from 'components/ui/SectionHeader';
import Layout from 'components/ui/Layout';
import Breadcrumbs from 'components/framework/Breadcrumbs';

const ArticlePage = (props) => {
	const {
		heading,
		text,
		contentArea,
		topContentArea,
		isInEditMode,
		breadcrumbs,
		slideContentArea,
	} = props;
	if (slideContentArea && slideContentArea?.length > 0) {
		return (
			<main id="main-content">
				{breadcrumbs && (
					<Layout backgroundColor="white">
						<Breadcrumbs links={breadcrumbs} />
					</Layout>
				)}

				<Layout backgroundColor="white">
					<ContentArea contentArea={topContentArea} />
					<div className="grid grid-cols-12 sm:gap-2 md:gap-6 xl:gap-11">
						<section
							id="filter-contact"
							className="col-span-12 sm:col-span-6 lg:col-span-7 xl:col-span-8 order-2 sm:order-1"
						>
							<SectionHeader
								heading={heading}
								text={text}
								size={'large'}
								isInEditMode={isInEditMode}
							/>
							<ContentArea contentArea={contentArea} />
						</section>
						<section
							id="floating-menu"
							className="order-1 mt-30 md.mt-0 sm:order-2 place-items-center col-span-12 sm:col-span-6 lg:col-span-5 xl:col-span-4"
						>
							<ContentArea contentArea={slideContentArea} />
						</section>
					</div>
				</Layout>
			</main>
		);
	} else {
		return (
			<main id="main-content">
				{breadcrumbs && (
					<Layout backgroundColor="white">
						<Breadcrumbs links={breadcrumbs} />
					</Layout>
				)}
				<ContentArea contentArea={topContentArea} />
				<SectionHeader
					heading={heading}
					text={text}
					size={'large'}
					isInEditMode={isInEditMode}
				/>
				<ContentArea contentArea={contentArea} />
			</main>
		);
	}
};

export default ArticlePage;
