import axios from 'axios';

let cancelToken = axios.CancelToken.source();

export const quickSearch = (url: string, query: string, display: number) => {
	if (process.env.NODE_ENV === 'development') {
		url = `http://localhost:4000${url}`;
	} else {
		if (
			window &&
			window.location &&
			window.location.origin &&
			typeof url !== 'undefined'
		) {
			url = `${window.location.origin}${url}`;
		}
	}

	if (cancelToken) {
		cancelToken.cancel();
	}

	const CancelToken = axios.CancelToken;
	cancelToken = CancelToken.source();

	return axios({
		url: `${url}?query=${query}&display=${display}`,
		method: 'post',
		cancelToken: cancelToken?.token,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'content-type': 'application/json; charset=utf-8',
		},
	})
		.then((response) => {
			return response.data || null;
		})
		.catch((error) => {
			if (axios.isCancel(error)) {
				return { cancel: true };
			}
			console.log(error);
		});
};
