import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { getFocusableElements, nextFocus, usePortal } from './utils';

const Frame: React.FC<{
	closeOnClickOutside?: boolean;
	closeOnEsc?: boolean;
	onClose: () => void;
	open?: boolean;
}> = ({
	children,
	closeOnClickOutside = true,
	closeOnEsc = true,
	onClose,
	open = true,
}) => {
	const portal = usePortal();
	const previousFocus = useRef<HTMLElement | null>(null);

	// close on click outside
	const container = useRef<HTMLDivElement>(null);
	const onOverlayClick = (e: React.MouseEvent) => {
		if (!container.current?.contains(e.target as Node)) onClose();
	};

	// close on esc
	useEffect(() => {
		const onKeyDown = (e: KeyboardEvent) => {
			if (!open) return;

			switch (e.key) {
				case 'Escape': {
					if (closeOnEsc) onClose();
					break;
				}
				case 'Tab': {
					e.preventDefault();
					nextFocus(getFocusableElements(container.current), !e.shiftKey);
					break;
				}
			}
		};

		window.addEventListener('keydown', onKeyDown);
		return () => window.removeEventListener('keydown', onKeyDown);
	}, [closeOnEsc, onClose, open]);

	useEffect(() => {
		// aria-hidden
		document
			.getElementById('root')
			?.setAttribute('aria-hidden', open.toString());
		portal.current?.setAttribute('aria-hidden', (!open).toString());

		if (open) {
			previousFocus.current = (document.activeElement as HTMLElement) ?? null;
			nextFocus(getFocusableElements(container.current));
		} else {
			previousFocus.current?.focus?.();
			previousFocus.current = null;
		}
	}, [open, portal]); // note: when importing, eslint doesn't recognise that portal is a ref, so it doesn't need to be in the deps array

	return ReactDOM.createPortal(
		// transparent overlay: `inset-0` to stretch over the entire screen (combines`top-0`, `right-0`, `bottom-0`, and `left-0`)
		<div
			className={classNames(
				'fixed inset-0 top-0 flex justify-center items-center w-screen h-screen',
				`${open ? 'visible' : 'invisible'}` // control visibility via `open` attribute (or render conditionally)
			)}
			style={{ zIndex: 2000 }}
			onClick={closeOnClickOutside ? onOverlayClick : undefined}
		>
			{/* container: `max-w-sm` to make it reasonably narrow, `mx-auto` to center horizontally */}
			<div className="absolute" ref={container}>
				{/* contents */}
				<div className="overflow-hidden bg-gray-800 rounded shadow-xl">
					{children}
				</div>
			</div>
		</div>,
		portal.current
	);
};

const Head: React.FC = ({ children }) => (
	<div className="block px-4 py-2 bg-gray-900">
		<h1 className="text-lg">{children}</h1>
	</div>
);

const Body: React.FC = ({ children }) => <>{children}</>;

export const Modal = { Frame, Head, Body };
