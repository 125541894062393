/**
 * FloatingBlock
 */

import Icon from 'components/ui/Icon';
import { FloatingBlockModel } from 'types/block-types';
import { ExtendedLinkModel } from 'types/common';
import Text from 'components/ui/Text';
import clsx from 'clsx';

/** Main description for this component. */
const FloatingBlock = (props: FloatingBlockModel) => {
	const { title, links, contactList } = props;

	return (
		<nav className="floating-block" aria-label="Sidebar">
			<Text
				as="h3"
				className="mb-5 text-center max-w-50 sm:text-left sm:max-w-none"
			>
				{title}
			</Text>
			{links &&
				links.map((item: ExtendedLinkModel) => {
					return (
						<div className="block my-5">
							<a
								className={clsx(
									item.active
										? 'floating-block-link-active'
										: 'floating-block-link'
								)}
								href={item.link}
							>
								<Icon
									icon="chevrons"
									direction="left"
									size={0.75}
									className="mr-2"
									aria-hidden={true}
								/>
								{item.text}
							</a>
						</div>
					);
				})}
			<div className="mt-7.5">
				{contactList &&
					contactList.map((contact) => {
						return (
							<div className="floating-block-contact-list">
								<p className="font-semibold">{contact.text}</p>
								<p className="font-medium">{contact.value}</p>
							</div>
						);
					})}
			</div>
			<WaterMark />
		</nav>
	);
};

export default FloatingBlock;

export const WaterMark = () => (
	<div>
		<img
			src="/assets/images/bgWatermark.png"
			className="floating-block-image"
			alt="lindab watermark"
		/>
	</div>
);
