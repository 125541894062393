/**
 * CisionPressReleasePage
 * @module pages/CisionPressReleasePage
 */

import { useContext, useEffect, useState } from 'react';
import ContentArea from 'components/framework/ContentArea';
import Layout from 'components/ui/Layout';
import { renderElementBasedOnEditMode } from 'common/helpers';
import { LocalizationContext } from 'context/localization.context';
import { FiChevronsLeft } from 'react-icons/fi';
import Breadcrumbs from 'components/framework/Breadcrumbs';
import {
	Label,
	SubHeading,
	FacebookIcon,
	TwitterIcon,
	LinedInIcon,
	EmailIcon,
	Preamble,
	RichText,
	Link,
	FileIcon,
	Container,
	DownloadIcon,
} from './CisionPressReleasePage.styles';
import { Grid, Cell } from 'components/ui/Grid';
import { CisionPressReleasePageModel } from 'types/page-types';
import Button from 'components/ui/Button';

const CisionPressReleasePage = ({
	breadcrumbs,
	heading,
	preamble,
	text,
	contact,
	company,
	highlight,
	publishDate,
	image,
	documents,
	mediaGallery,
	returnButton,
	contentArea,
	isInEditMode,
	downloadImageButton,
}: CisionPressReleasePageModel) => {
	const { t }: any = useContext(LocalizationContext);
	const [windowUrl, setWindowUrl] = useState('');
	useEffect(() => {
		setWindowUrl(window.location.href);
	}, []);

	const renderSocialLinks = () => {
		return (
			<>
				<a
					className="m-0"
					href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
						windowUrl
					)}`}
					title={t('shared/socialmedia/shareonfacebook')}
					target="_blank"
				>
					<FacebookIcon />
				</a>
				<a
					className="m-0"
					href={`https://twitter.com/home?status=${encodeURIComponent(
						document.title
					)}%20${encodeURIComponent(windowUrl)}`}
					title={t('shared/socialmedia/shareontwitter')}
					target="_blank"
				>
					<TwitterIcon />
				</a>
				<a
					className="m-0"
					href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
						windowUrl
					)}`}
					title={t('shared/socialmedia/shareonlinkedin')}
					target="_blank"
				>
					<LinedInIcon />
				</a>
				<a
					className="m-0"
					href={`mailto:?subject=[title]&body=${encodeURIComponent(windowUrl)}`}
					title={t('shared/socialmedia/sharewithemail')}
					target="_blank"
				>
					<EmailIcon />
				</a>
			</>
		);
	};

	return (
		<main id="main-content">
			<Layout backgroundColor="white">
				{breadcrumbs && <Breadcrumbs links={breadcrumbs} />}
				{heading &&
					renderElementBasedOnEditMode(
						isInEditMode,
						'heading',
						'h1',
						{
							className:
								'font-standard mb-6 mt-8 font-normal text-h2 md:text-h1 text-blue',
						},
						heading
					)}
				<Container>
					<div className="flex justify-between ssmMax:block">
						<div className="ssmMax:mt-4">
							{highlight && (
								<Label color={highlight.color}>{highlight.text}</Label>
							)}
							{publishDate && (
								<span className="text-span text-black opacity-50 no-underline font-standard">
									{publishDate}
								</span>
							)}
						</div>

						<div className="ssmMax:mt-4">{renderSocialLinks()}</div>
					</div>
					{image && image.src && image.sizes && image.sizes.length > 0 && (
						<picture>
							<img
								className="w-auto"
								src={image.src}
								srcSet={`${image.sizes[0]} 800w, ${image.sizes[1]} 400w`}
								sizes="(max-width: 767px) 70vw, 800px"
								alt={image.alt ? image.alt : ''}
							/>
						</picture>
					)}

					{preamble &&
						renderElementBasedOnEditMode(
							isInEditMode,
							'preamble',
							Preamble,
							{ size: 'large' },
							preamble
						)}

					{text && (
						<RichText name="text" text={text} isInEditMode={isInEditMode} />
					)}

					{contact && (
						<RichText
							name="contact"
							text={contact}
							isInEditMode={isInEditMode}
						/>
					)}
					{company && (
						<RichText
							name="company"
							text={company}
							isInEditMode={isInEditMode}
						/>
					)}

					{documents && documents.length > 0 && (
						<>
							<SubHeading>{t('cisionpressreleasepage/attachments')}</SubHeading>
							<ul className="list-none p-0 mt-0">
								{documents.map((item, index) => {
									return (
										<li key={index}>
											<Link href={item.link} download>
												<FileIcon />
												<span className="align-middle">{item.text}</span>
											</Link>
										</li>
									);
								})}
							</ul>
						</>
					)}

					{mediaGallery && mediaGallery.length > 0 && (
						<>
							<SubHeading>{t('cisionpressreleasepage/images')}</SubHeading>
							<Grid padding={false}>
								{mediaGallery.map((image, index) => {
									return (
										<Cell span={12} tablet={6} key={index} className="mb-8">
											<picture>
												{image.webpSizes && (
													<source
														type="image/webp"
														srcSet={`${image.webpSizes[0]} 800w, ${image.webpSizes[1]} 400w`}
														sizes="(max-width: 767px) 70vw, (min-width: 768px) 5vw, 400px"
													/>
												)}
												{image.sizes && (
													<img
														src={image.src}
														srcSet={`${image.sizes[0]} 800w, ${image.sizes[1]} 400w`}
														sizes="(max-width: 767px) 70vw, (min-width: 768px) 5vw, 400px"
														alt={image.alt ? image.alt : ''}
														className="pressrelease-mediagallery-img"
													/>
												)}
											</picture>
										</Cell>
									);
								})}
							</Grid>
							{downloadImageButton &&
								downloadImageButton.link &&
								downloadImageButton.text && (
									<Link href={downloadImageButton.link} download>
										<DownloadIcon />
										<span className="align-middle">
											{downloadImageButton.text}
										</span>
									</Link>
								)}
						</>
					)}
					<div className="flex flex-wrap justify-end my-6 mx-0">
						<Button
							className="my-2 mx-0"
							url={returnButton.link}
							type="link"
							buttonColor="grey"
						>
							<FiChevronsLeft className="mt-0 mr-2 mb-0 ml-0" />
							{returnButton.text}
						</Button>
					</div>
				</Container>
			</Layout>

			<ContentArea contentArea={contentArea} />
		</main>
	);
};

export default CisionPressReleasePage;
