/**
 * MegaMenuItem
 * @module components/MegaMenuItem
 */
import React, { useEffect, useState } from 'react';
import MegaMenuDropdown from './MegaMenuDropdown';
import { MegaMenuItemModel } from 'types/common';
import clsx from 'clsx';

/** OBS! Glöm inte att länka in Modernizr-skript filen i index.html i public-mappen. */
/** <script src="%PUBLIC_URL%/modernizr.custom.js" defer></script> */
interface Props {
	item: MegaMenuItemModel;
	currentExpanded: number;
	setCurrentExpanded: Function;
	index: number;
}
const MegaMenuItem: React.FC<Props> = ({
	item,
	currentExpanded,
	setCurrentExpanded,
	index,
}) => {
	const [expanded, setExpanded] = useState(false);

	let currentlyExpanded = currentExpanded === index;
	const toggleDropDown = (e?: any) => {
		if (window.Modernizr && !window.Modernizr.touchevents) {
			setCurrentExpanded(index);
			setExpanded(!expanded);
		}
	};
	/**
	 * Fixes so hover works on touch device
	 */
	const handleTouchHover = (e: any) => {
		if (window.Modernizr && window.Modernizr.touchevents) {
			if (!expanded || (expanded && e.target.tagName !== 'A')) {
				e.preventDefault();
			}
			setExpanded(true);
			setCurrentExpanded(index);
		}
	};
	/**
	 * Adds the possibility to navigate the menu with keyboard
	 */
	const handleKeyboardNavigation = (e: any) => {
		if (e.keyCode === 13) {
			if (!expanded && !currentlyExpanded) {
				e.preventDefault();
			}
			if (expanded && currentExpanded !== -1 && !currentlyExpanded) {
				setExpanded(false);
				e.preventDefault();
			}
			toggleDropDown();
		}
	};
	useEffect(() => {
		setExpanded(false);
		setCurrentExpanded(-1);
		//eslint-disable-next-line
	}, []);
	if (item.children && item.children.length > 0) {
		return (
			<li
				onClick={(e: any) => handleTouchHover(e)}
				onKeyDown={(e: any) => handleKeyboardNavigation(e)}
				onMouseEnter={(e: any) => toggleDropDown(e)}
				onMouseLeave={(e: any) => {
					setExpanded(false);
					setCurrentExpanded(-1);
				}}
				className="float-left"
			>
				<a
					href={item.link}
					target={item.target}
					className={clsx(
						'relative inline-block py-6 px-4 text-white font-semibold uppercase hover:underline',
						item.active && 'underline'
					)}
					// onChange={() => {
					// 	setExpanded(false);
					// }}
				>
					{item.text}
					<div
						className={clsx(
							'tooltip-triangle',
							currentlyExpanded ? 'visible' : 'hidden'
						)}
						aria-hidden={true}
					/>
				</a>
				<MegaMenuDropdown
					items={item.children}
					expanded={currentlyExpanded}
					setCurrentExpanded={setCurrentExpanded}
					onMouseEnter={(e: any) => toggleDropDown(e)}
				/>
			</li>
		);
	} else {
		return (
			<li onMouseEnter={() => setCurrentExpanded(-1)} className="float-left">
				<a
					href={item.link}
					target={item.target}
					className={clsx(
						'relative inline-block py-6 px-4 text-white font-semibold uppercase hover:underline',
						item.active && 'underline'
					)}
				>
					{item.text}
				</a>
			</li>
		);
	}
};
export default MegaMenuItem;
