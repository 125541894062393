import { Paragraph } from 'components/ui/Typography';
import styled from 'styled-components';
import { fontWeightMedium } from 'theme/typography';
import { deviceSmallMax, deviceMediumMax } from 'theme/mediaQueries';
import FilterField from 'components/ui/FilterField';
import Pager from 'components/ui/Pager';

import { spacing05x, spacing3x } from 'theme/spacings';

export const Preamble = styled(Paragraph)`
	max-width: 66%;
	font-weight: ${fontWeightMedium};

	@media (max-width: ${deviceMediumMax}) {
		max-width: 100%;
	}
	@media (max-width: ${deviceSmallMax}) {
		margin: ${spacing05x} 0;
	}
`;

export const SearchInput = styled(FilterField)`
	margin-bottom: ${spacing3x};
	width: 50rem;
	@media (max-width: ${deviceSmallMax}) {
		float: none;
		padding: ${spacing05x};
	}
	svg {
		height: 2.4rem;
		width: 2.4rem;
		margin-bottom: 0.4rem;
	}
`;

export const PagerWrapper = styled(Pager)`
	margin: 2rem 0;
`;

export const FilterBarWrapper = styled.div`
	margin-top: 2rem;
`;
