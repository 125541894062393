/**
 * AnnouncementBar
 * @module components/AnnouncementBar
 */

import React, { useContext, useEffect, useState } from 'react';
import Layout from 'components/ui/Layout';
import { getCookie, setCookie } from 'common/helpers';
import { LocalizationContext } from 'context/localization.context';
import Button from 'components/ui/Button';
import clsx from 'clsx';
import Icon from 'components/ui/Icon';

interface Props {
	color: string;
	heading: string;
	text?: string;
	buttonText: string;
	url: string;
	id: string;
}

const AnnouncementBar = ({
	color,
	heading,
	text,
	buttonText,
	url,
	id,
}: Props) => {
	const [isVisible, setIsVisible] = useState(false);
	const announcementCookie = getCookie('Announcement-' + id);
	const { t }: any = useContext(LocalizationContext);

	useEffect(() => {
		if (!announcementCookie) {
			setIsVisible(true);
		}
		//eslint-disable-next-line
	}, []);

	return (
		<div
			className={clsx(
				isVisible ? 'block' : 'hidden',
				color === 'orange'
					? 'bg-orangeLight'
					: color === 'blue'
					? 'bg-blueDarker'
					: `bg-${color}`,
				'max-w-360 my-0 mx-auto py-6 px-0 announcement-bar'
			)}
		>
			<Layout>
				<div className="flex justify-between items-baseline">
					<div>
						{heading && (
							<h2
								className={clsx(
									color === 'lightGrey' ? 'text-black' : 'text-white',
									'text-preamble font-semibold mb-2'
								)}
							>
								{heading}
							</h2>
						)}
						{text && (
							<p
								className={clsx(
									color === 'lightGrey' ? 'text-black' : 'text-white',
									'text-p'
								)}
							>
								{text}
							</p>
						)}
						{buttonText && url && (
							<Button
								type="link"
								buttonColor="green"
								url={url}
								className="mt-4"
							>
								{buttonText}
							</Button>
						)}
					</div>
					<button
						onClick={(e) => {
							e.preventDefault();
							setCookie('Announcement-' + id, 'true', null, '/');
							setIsVisible(false);
						}}
						className={clsx(
							color === 'lightGrey' ? 'text-black' : 'text-white'
						)}
						aria-label={`${t('shared/header/closeannouncement')} ${heading}`}
					>
						<Icon icon="close" size={3} />
					</button>
				</div>
			</Layout>
		</div>
	);
};

export default AnnouncementBar;
