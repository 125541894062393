export default ({ className, ...htmlAttributes }: { className?: string }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		strokeWidth="2"
		strokeLinecap="round"
		strokeLinejoin="round"
		className={className}
		{...htmlAttributes}
	>
		<rect
			x="3"
			y="11"
			width="18"
			height="11"
			rx="2"
			ry="2"
			fill="currentColor"
		></rect>
		<path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
	</svg>
);
