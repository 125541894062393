import styled from 'styled-components';
import { paragraphCss, H6Css } from 'components/ui/Typography';
import { fieldCss } from 'components/ui/Form/Form.styles';
import {
	white,
	greyDark,
	greyPlaceholder,
	blue,
	greyDarkest,
	errorRed,
} from 'theme/colors';
import { buttonBaseStyle } from 'components/ui/Button/Button.styles';
import { fontFamily, fontWeightBold } from 'theme/typography';
import { spacing1x } from 'theme/spacings';
import squareIcon from './CustomIcons/square.svg';
import checkSquareIcon from './CustomIcons/check-square.svg';
import circleIcon from './CustomIcons/circle.svg';
import checkCircleIcon from './CustomIcons/check-circle.svg';

export const CustomExternalScriptContainer = styled.div`
	> form,
	> form > * {
		padding: ${spacing1x};

		p,
		span,
		legend {
			${paragraphCss};
		}
		label,
		label > span {
			${H6Css};
			width: auto;
			display: inline-block;
			font-weight: ${fontWeightBold};
			font-family: ${fontFamily};
			margin-bottom: ${spacing1x};
			position: relative;
			display > svg {
				color: ${greyDarkest};
				margin-left: ${spacing1x};
			}
			:hover div {
				visibility: visible;
			}
			:first-child {
				margin-top: 0;
			}
		}
		ul,
		ol {
			list-style: none;
			padding: 0;
		}
		li {
			text-decoration: none;
			list-style: none;
		}
		textarea {
			width: 100%;
			height: 10rem;
			padding: ${spacing1x};
			border: 0.1rem solid ${greyDark};
			border-radius: 0.4rem;

			font-family: ${fontFamily};
			font-size: 1.6rem;
			line-height: 2.4rem;
		}

		select,
		input[type='email'],
		input[type='text'],
		input[type='number'] {
			${fieldCss};

			::placeholder {
				color: ${greyPlaceholder};
			}
		}
		select {
			background-color: ${white} !important;
		}

		input[type='submit'] {
			${buttonBaseStyle};
			color: ${white};
			background-color: ${blue};
			border: 0.2rem solid ${white};
			font-family: ${fontFamily};
			padding: 0.8rem 1.2rem;
			font-size: 1.6rem;
		}
		.hs-form-checkbox,
		.hs-form-booleancheckbox {
			display: inline-block;
			> label > input[type='checkbox'] {
				border-color: white;
				position: relative;
				width: 2rem;
				margin-right: ${spacing1x};

				&:before {
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					content: ' ';
					background-image: url(${squareIcon});
					background-color: white;
					background-size: 2rem 2rem;
					height: 2rem;
					width: 2rem;
					color: ${greyDarkest};
					opacity: 1;
					cursor: pointer;
				}
			}
			> label > input:checked[type='checkbox'] {
				&:before {
					background-image: url(${checkSquareIcon});
				}
			}
			> label > span {
				display: block;
				float: left;
				margin: 0;
			}
		}
		.hs-form-checkbox {
			display: block;
			> label > input {
				float: left;
			}
		}
		.hs-form-booleancheckbox-display {
			display: flex;
		}

		.hs-form-radio-display {
			> input[type='radio'] {
				border-color: white;
				position: relative;
				float: left;
				width: 2rem;
				margin-right: ${spacing1x};

				&:before {
					display: block;
					content: ' ';
					background-image: url(${circleIcon});
					background-size: 2rem 2rem;
					background-color: white;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					position: absolute;
					height: 2rem;
					width: 2rem;
					color: ${greyDarkest};
					opacity: 1;
					cursor: pointer;
				}
			}
			> input:checked[type='radio'] {
				&:before {
					background-image: url(${checkCircleIcon});
				}
			}
			> span {
				display: block;
				float: left;
				margin: 0;
			}
		}

		.hs-form-required {
			margin-top: 0;
			color: ${greyDarkest};
		}
		.hs-error-msgs > li {
			> label {
				color: ${errorRed};
			}
			a {
				color: ${blue};
				text-decoration: none;
				:hover {
					text-decoration: underline;
				}
			}
		}
	}
	.submitted-message {
		${paragraphCss};
		padding: 0 ${spacing1x};
	}

	.hs-richtext {
		a {
			color: ${blue};
			text-decoration: none;
			:hover {
				text-decoration: underline;
			}
		}
	}
`;
