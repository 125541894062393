import styled, { css } from 'styled-components';
import { Paragraph, H3Css } from 'components/ui/Typography';
import * as colors from 'theme/colors';
import { FiDownload, FiFile } from 'react-icons/fi';
import { blue, greyDarkest, greyDark, grey } from 'theme/colors';
import { spacing1x, spacing05x, spacing2x, spacing4x } from 'theme/spacings';
import { fontFamily } from 'theme/typography';
import { fontWeightMedium } from 'theme/typography';
import { deviceSmallMax, deviceMediumMax } from 'theme/mediaQueries';
import RichTextWrapper from 'components/ui/RichText';
import {
	FaFacebookF,
	FaTwitter,
	FaLinkedinIn,
	FaEnvelope,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
const SubHeadingCss = css`
	${H3Css};
	margin: ${spacing4x} 0 ${spacing1x};
	color: ${blue};
`;

const IconCss = `
color: ${greyDarkest};
background-color: ${grey};
font-size: 3.5rem;
padding: ${spacing1x};
margin-left: ${spacing05x};

:hover {
	background-color: ${greyDark};
}

@media (max-width: 520px) {
	margin-left: 0;
	margin-right: ${spacing05x};
}
`;

export const Container = styled.div`
	width: 66%;
	@media (max-width: ${deviceMediumMax}) {
		width: 100%;
	}
`;

export const SubHeading = styled.h2`
	${SubHeadingCss};
`;

export const Label = styled.span`
	font-family: ${fontFamily};
	text-transform: uppercase;
	background-color: ${({ color }) =>
		color === 'orange' ? colors.orangeLight : colors[color]};
	color: ${colors.white};
	font-size: 1.3rem;
	padding: ${spacing05x} ${spacing1x};
	margin-right: ${spacing1x};
	font-weight: ${fontWeightMedium};
`;

export const FacebookIcon = styled(FaFacebookF)`
	${IconCss};
`;

export const TwitterIcon = styled(FaXTwitter)`
	${IconCss};
`;

export const LinedInIcon = styled(FaLinkedinIn)`
	${IconCss};
`;

export const EmailIcon = styled(FaEnvelope)`
	${IconCss};
`;

export const Preamble = styled(Paragraph)`
	font-weight: ${fontWeightMedium};
	font-size: 2.2rem;
	line-height: 2.8rem;
	margin-bottom: ${spacing4x};
	@media (max-width: ${deviceSmallMax}) {
		margin: ${spacing05x} 0;
	}
`;

export const RichText = styled(RichTextWrapper)`
	@media (max-width: ${deviceSmallMax}) {
		margin: ${spacing05x} 0;
	}

	h2 {
		${SubHeadingCss};
	}

	p {
		margin: 0 0 ${spacing2x};
	}
`;

export const Link = styled.a`
	text-decoration: none;
	font-size: 1.8rem;
	line-height: 2.6rem;
	color: ${blue};
	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
`;

export const FileIcon = styled(FiFile)`
	vertical-align: middle;
	font-size: 2.2rem;
	margin-right: ${spacing2x};
`;

export const DownloadIcon = styled(FiDownload)`
	vertical-align: middle;
	font-size: 2.2rem;
	margin-right: ${spacing2x};
`;
