export default ({
	className,
	'aria-hidden': ariaHidden,
	color,
}: {
	className?: string;
	'aria-hidden'?: boolean;
	color?: string;
}) => (
	<svg
		width="6px"
		height="10px"
		viewBox="0 0 6 10"
		version="1.1"
		className={className}
		aria-hidden={ariaHidden}
	>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				transform="translate(-21.000000, -15.000000)"
				fill={color ? color : 'currentColor'}
			>
				<g>
					<g transform="translate(16.000000, 0.000000)">
						<g transform="translate(0.000000, 12.000000)">
							<path d="M10.4714045,4.47140451 C10.7317541,4.21105498 10.7317541,3.78894499 10.4714045,3.52859546 C10.211055,3.26824593 9.78894501,3.26824593 9.52859549,3.52859546 L5.52859547,7.52859548 C5.26824594,7.78894501 5.26824594,8.21105499 5.52859547,8.47140452 L9.52859549,12.4714045 C9.78894501,12.7317541 10.211055,12.7317541 10.4714045,12.4714045 C10.7317541,12.211055 10.7317541,11.788945 10.4714045,11.5285955 L6.94280904,8 L10.4714045,4.47140451 Z"></path>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);
