export default ({ className, ...htmlAttributes }: { className?: string }) => (
	<svg
		width="16px"
		height="16px"
		viewBox="0 0 16 16"
		version="1.1"
		className={className}
		{...htmlAttributes}
	>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g fill="currentColor">
				<path
					d="M9.51555027,-2.98983061e-13 L15.999,7.999 L16,8 L16,8 C15.9787734,8.02618776 13.9042323,10.585592 9.77622454,15.6784007 L9.51555027,16 L8,14.6970696 L12.674,8.999 L-1.02282627e-11,9 L-1.02282627e-11,7 L12.675,6.999 L8,1.30293044 L9.51555027,-2.98983061e-13 Z"
					transform="translate(8.000000, 8.000000) scale(-1, -1) rotate(-180.000000) translate(-8.000000, -8.000000) "
				></path>
			</g>
		</g>
	</svg>
);
