import styled, { css } from 'styled-components';
import { blue, white, grey, black, greyDark, greyDarker } from 'theme/colors';
import {
	fontWeightBold,
	fontWeightLight,
	fontWeightNormal,
	fontWeightSemiBold,
} from 'theme/typography';
import { spacing2x, spacing1x, spacing3x } from 'theme/spacings';
import { FaRegMinusSquare, FaRegPlusSquare } from 'react-icons/fa';
import { transparentize } from 'polished';
import { FiLogIn } from 'react-icons/fi';

export const MobileNav = styled.nav`
	width: 101vw;
	background-color: ${blue};
	list-style: none;
`;
const linkBaseCss = css`
	font-weight: ${fontWeightLight};
	font-size: 1.6rem;
	max-height: 8rem;
	line-height: 4rem;
`;

export const MainLink = styled.li`
	${linkBaseCss};
	background-color: ${blue};
	color: ${white};
	font-weight: ${fontWeightBold};
	border-bottom: 0.2rem solid ${white};
	padding: 0 ${spacing2x};

	> a {
		color: white;
		text-decoration: none;
		font-weight: ${fontWeightNormal};
	}
	> p {
		color: white;
		text-decoration: none;
		margin: 0;
		display: inline-block;
		font-weight: ${fontWeightNormal};
	}
`;

export const SublinkWrapper = styled.div`
	background-color: ${grey};
	padding: 0 ${spacing2x};
	padding-bottom: ${(props) => (props.active ? spacing1x : '0')};
	height: ${(props) => (props.active ? 'auto' : '0')};
	visibility: ${(props) => (props.active ? 'visibile' : 'hidden')};
`;
export const SubSubWrapper = styled.ul`
	background-color: ${grey};
	padding-left: ${spacing2x};
	height: ${(props) => (props.active ? 'auto' : '0')};
	visibility: ${(props) => (props.active ? 'visibile' : 'hidden')};
`;

export const SubLink = styled.li`
	${linkBaseCss};
	color: ${black};
	font-weight: ${(props) =>
		props.isHeading ? fontWeightSemiBold : fontWeightNormal};
	border-bottom: ${(props) => (props.isLastItem ? '0' : '0.1rem')} solid
		${greyDark};
	display: flex;
	> a {
		color: ${black};
		text-decoration: none;
		font-weight: ${fontWeightNormal};
	}
	> p {
		color: ${black};
		text-decoration: none;
		font-weight: ${fontWeightNormal};
		margin: 0;
	}
`;

export const PlusButton = styled(FaRegPlusSquare)`
	color: ${transparentize(0.2, greyDarker)};
	position: absolute;
	right: ${spacing3x};
	margin: 1.2rem 0;
`;
export const MinusButton = styled(FaRegMinusSquare)`
	color: ${transparentize(0.2, greyDarker)};
	position: absolute;
	right: ${spacing3x};
	margin: 1.2rem 0;
`;

export const LoginIcon = styled(FiLogIn)`
	color: ${transparentize(0.2, greyDarker)};
	position: absolute;
	right: ${spacing3x};
	margin: 1rem 0;
`;
