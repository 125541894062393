/**
 * Carousel
 * @module components/CarouselItem
 */

import { useState, useEffect, MouseEvent } from 'react';

import { ContentButton } from './Carousel.styles';
import { FiChevronsRight } from 'react-icons/fi';
import { CarouselItemModel } from 'types/block-types';
import clsx from 'clsx';
import React from 'react';
import { getHeadingLevel } from 'common/helpers.js';

interface MediaProps {
	item: CarouselItemModel;
}

interface CarouselModel {
	item: CarouselItemModel;
	headingLevel: number;
	headingStyleLevel: number;
	currentIndex: number;
	index: number;
	heightLevel: string;
	keyNav: boolean;
	setKeyNav: (value: boolean) => void;
}

const Media = (props: MediaProps) => {
	const { video, image, videoPosterImage } = props && props.item;
	const [canPlay, setCanPlay] = useState(false);

	const handleClick = (e: MouseEvent<HTMLVideoElement>) => {
		let videoElement = e.currentTarget;
		if (videoElement) {
			videoElement.paused ? videoElement.play() : videoElement.pause();
		}
	};

	if (video) {
		return (
			<div className="h-full w-full relative">
				{videoPosterImage && (
					<img
						className={clsx(
							'w-full max-h-300 object-cover object-center absolute h-auto',
							canPlay ? 'animate-fadeOut' : ''
						)}
						src={videoPosterImage}
						alt="placeholder loading video"
					/>
				)}
				<video
					playsInline
					className="w-full object-cover object-center md:max-h-full md:h-auto max-h-45"
					autoPlay
					preload="none"
					muted
					loop
					onClick={handleClick}
					onCanPlayThrough={(e) => {
						setCanPlay(true);
						if (e.currentTarget && e.currentTarget.play) {
							e.currentTarget.play();
						}
					}}
				>
					<source src={video} type="video/mp4" />
				</video>
			</div>
		);
	}
	if (image && image.src) {
		return (
			<picture>
				<source
					type="image/webp"
					srcSet={
						image.webpSizes &&
						`${image.webpSizes[0]} 1600w,${image.webpSizes[1]} 1200w, ${image.webpSizes[2]} 800w, ${image.webpSizes[3]} 400w`
					}
					sizes="(min-width: 768px) 60vw, (max-width: 767px) 70vw, 800px"
				/>
				<img
					className="h-full w-full object-cover sm:max-h-45 md:max-h-full"
					src={image.src}
					srcSet={
						image.sizes &&
						`${image.sizes[0]} 1600w,${image.sizes[1]} 1200w, ${image.sizes[2]} 800w, ${image.sizes[3]} 400w`
					}
					sizes="(min-width: 768px) 60vw, (max-width: 767px) 70vw, 800px"
					alt={image.alt}
				/>
			</picture>
		);
	}
	return null;
};

const CarouselItem = (props: CarouselModel) => {
	const {
		item,
		currentIndex,
		index,
		keyNav,
		setKeyNav,
		headingLevel,
		headingStyleLevel,
		heightLevel,
	} = props;

	const carouselItem = React.createRef() as React.RefObject<HTMLDivElement>;
	const carouselContentRef = React.createRef() as React.RefObject<HTMLDivElement>;
	const [focused, setFocused] = useState(currentIndex === index);

	// This handles aria tags of the shadow copies the carousel creates to loop infinitely.
	useEffect(() => {
		let shouldFocus = currentIndex === index;
		if (
			carouselItem &&
			carouselItem.current &&
			carouselItem?.current?.parentElement?.parentElement?.classList.contains(
				'slick-cloned'
			)
		) {
			shouldFocus = false;
		}

		setFocused(shouldFocus);
		//eslint-disable-next-line
	}, [currentIndex, index, setFocused]);

	// If this is navigated to by keyboard focus the first paragraph in the slide.
	useEffect(() => {
		if (focused && keyNav) {
			carouselContentRef?.current?.focus();
			setKeyNav(false);
		}
		//eslint-disable-next-line
	}, [focused, keyNav, setKeyNav]);

	if (!item) return null;

	const tabIndex = focused ? { tabIndex: 0 } : {};
	const Heading = getHeadingLevel(headingLevel);
	const headingCss =
		headingStyleLevel === 1
			? 'text-h2 md:text-h1'
			: `text-${getHeadingLevel(headingStyleLevel)}`;

	return (
		<div
			className={clsx(
				'relative w-full sm:h-auto',
				heightLevel === 'low' && 'md:h-45',
				heightLevel === 'medium' && 'md:h-65',
				heightLevel === 'normal' && 'md:h-120'
			)}
			style={{ backgroundColor: item.backgroundColor }}
			ref={carouselItem}
		>
			{(heightLevel === 'normal' || heightLevel === 'medium') && (
				<Media item={item} />
			)}
			{item.heading && (
				<div
					className={clsx(
						'bg-transparent static max-w-297.5 my-auto mx-0 left-10 md:left-48 md:absolute',
						heightLevel === 'low' && 'md:pb-5 pt-5',
						heightLevel === 'medium' && 'top-1/10',
						heightLevel === 'normal' && 'top-1/5'
					)}
				>
					{heightLevel === 'normal' || heightLevel === 'medium' ? (
						<div
							className="inline-block bg-white-whiteOpacity md:pt-0 md:pr-4 md:pb-4 md:pl-6 md:max-w-3xl relative max-w-full py-4 px-2 min-w-110"
							{...tabIndex}
							ref={carouselContentRef}
							role="alert"
						>
							<Heading
								className={clsx(
									'text-blue font-normal md:mr-0 md:mb-2 md:ml-0 md:mt-6 mb-3 m-0',
									headingCss
								)}
							>
								{item.heading}
							</Heading>
							{item.description && (
								<p className="md:mt-3 md:mr-0 md:ml-0 md:mb-5 md:mt-2 font-standard font-normal text-black md:text-p text-sm my-3 mx-0">
									{item.description}
								</p>
							)}
							{item.button && item.button.text && item.button.link && (
								//@ts-ignore too much work for now
								<ContentButton
									buttonColor="orange"
									url={item.button.link}
									target={item.button.target}
									tabIndex={focused ? 0 : -1}
									type="link"
								>
									<FiChevronsRight />
									{item.button.text}
								</ContentButton>
							)}
						</div>
					) : (
						<div className="inline-block p-0 relative sm:max-w-full sm:py-1 sm:px-2">
							<Heading
								className={clsx(
									'font-normal mr-0 md:mb-3 ml-0 mb-6',
									headingCss,
									heightLevel === 'low' ? 'mb-3' : 'mt-6 mb-2',
									item.backgroundColor === '#dadada'
										? 'text-black'
										: 'text-white'
								)}
							>
								{item.heading}
							</Heading>
							<p
								className={clsx(
									'md:mr-0 md:ml-0 font-standard font-normal text-black md:text-p text-sm my-3 mx-0',
									heightLevel === 'low' ? 'md:my-2' : 'my-4',
									item.backgroundColor === '#dadada'
										? 'text-black'
										: 'text-white'
								)}
							>
								{item.description}
							</p>
							{item.button && (
								<a
									className={clsx(
										'font-standard font-normal text-black md:text-h5 text-sm my-3 mx-0',
										item.backgroundColor === '#dadada'
											? 'text-black'
											: 'text-white'
									)}
									href={item.button.link}
									target={item.button.target}
									tabIndex={focused ? 0 : -1}
								>
									<FiChevronsRight />
									<span>{item.button.text}</span>
								</a>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default CarouselItem;
