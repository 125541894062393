/**
 * ArticleRow
 * @module components/ArticleRow
 */

import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { LocalizationContext } from 'context/localization.context';
import { GenericWarning, ServerError } from 'common/helpers';
import { useMedia } from 'hooks';
import { FiShoppingCart } from 'react-icons/fi';
import { FaEdit, FaCamera } from 'react-icons/fa';
import { FiBox, FiHome, FiTruck } from 'react-icons/fi';
import Certificate from 'components/ui/Certificate';
import {
	Article,
	Body,
	Img,
	Text,
	OrangeText,
	Content,
	LinkWrapper,
	Span,
	Counter,
	ContentRight,
	Image,
	TextWrapper,
	ContentLeft,
	ContentMiddle,
	AddButton,
	ConfButton,
	ItemCodeAttributeNameWrapper,
	LoginForPriceWrapper,
	LoginForPriceText,
	PriceOnDemandWrapper,
	PriceOnDemandText,
} from './ArticleRow.styles';
import { toastWithLink } from 'common/toastHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConfArticle } from 'store/modules/configurator';
import { MouseEvent } from 'react';
import ProductCounter from 'components/ui/ProductCounter';
import { ArticlePriceInfoModel, ArticleRowItemModel } from 'types/article';
import { AddToCartModel } from 'types/cart-types';
import { CertificatesItem, LinkModel } from 'types/common';

import { AddToCart, selectCartObject } from 'store/modules/cart';
import Icon from 'components/ui/Icon';

export interface ArticleRowModel {
	activeMarket?: string;
	item: ArticleRowItemModel;
	priceInfo?: ArticlePriceInfoModel;
	priceLoading?: boolean;
	certificates?: CertificatesItem[];
	openModal?: any;
	trackClick?: (hitId: string, trackId: string) => void;
	loginLink?: LinkModel;
}

const ArticleRow = ({
	activeMarket,
	item,
	priceInfo,
	priceLoading,
	certificates,
	openModal,
	trackClick,
	loginLink,
}: ArticleRowModel) => {
	const { t } = useContext<any>(LocalizationContext);
	const dispatch = useDispatch();
	const { cartDisabled, cartHidden, cartLink } = useSelector(selectCartObject);
	const isDesktopOrSmaller = useMedia('(max-width: 1024px)');
	const isMobile = useMedia('(max-width: 767px)');
	const [currentQuantity, setCurrentQuantity] = useState<number>(1);

	const renderNetPrice = (price: string) => {
		return (
			<p className="whitespace-nowrap text-blue font-medium text-h3 mt-0 -my-1.5 md:mb-2 md:mx-0 px-0">
				{`${price} ${item && item.currency ? item.currency : ''}`}
				{item && item.unit && <Span>{item.unit}</Span>}
			</p>
		);
	};

	const handleAddToCart = async () => {
		try {
			let quantity = (document.getElementById(
				'Counter-' + item.itemCode
			) as HTMLInputElement).value;

			if (item) {
				const articleList: AddToCartModel[] = [
					{
						itemCode: item?.itemCode,
						quantity: currentQuantity,
					},
				];
				await dispatch(AddToCart('POST', articleList));
			}
			toastWithLink(
				t('shared/toast/cartaddsuccess'),
				cartLink?.link,
				toast.TYPE.SUCCESS
			);

			if (window.dataLayer) {
				window.dataLayer.push({
					event: 'add_to_cart',
					domain: activeMarket,
					ecommerce: {
						items: [
							{
								item_name: item.itemName,
								item_id: item.itemCode,
								price: item.defaultPrice,
								item_variant: item.attributeName,
								quantity: quantity,
							},
						],
					},
				});
			}
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/cartupdateerror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		} finally {
			trackClick && trackClick(item.hitId, item.trackId);
		}
	};

	const renderAddToCartButton = () => {
		return (
			<AddButton
				type="button"
				buttonColor="blue"
				onClick={() => {
					handleAddToCart();
					setCurrentQuantity(1);
				}}
				disabled={cartDisabled}
				//@ts-ignore
				item={item}
			>
				<span aria-hidden={true}>
					{isDesktopOrSmaller
						? t('shared/articlerow/add')
						: t('shared/articlerow/addtocart')}
				</span>

				<span className="sr-only">
					`${t('shared/articlerow/add')} ${item.itemName}-${item.itemCode}`
				</span>

				<FiShoppingCart aria-hidden="true" />
			</AddButton>
		);
	};

	const getConfArticle = async (e: any, url: string) => {
		e.preventDefault();
		try {
			openModal && openModal();
			await dispatch(fetchConfArticle(url));
		} catch (err: any) {
			console.log(err);
		}
	};

	const handleLinkClick = (e: MouseEvent) => {
		e.preventDefault();

		if (isConfArticle) {
			getConfArticle(e, item.link);
		} else {
			openModal && openModal({ link: item.link, isActive: true });
		}
	};

	const renderConfButton = () => {
		return (
			<ConfButton
				type="button"
				buttonColor="blue"
				url={item.link}
				onClick={(e: MouseEvent) => getConfArticle(e, item.link)}
				disabled={cartDisabled}
				//@ts-ignore
				item={item}
			>
				<span aria-hidden={true}>{t('shared/articlerow/configure')}</span>

				<span className="sr-only">
					{`${t('shared/articlerow/configure')} ${item.itemName}-${
						item.itemCode
					}`}
				</span>
				<FaEdit aria-hidden="true" />
			</ConfButton>
		);
	};

	const isConfArticle = item.modelType === 'ConfArticle' || item.configurable;
	return (
		<Article>
			<ContentLeft>
				<a
					href="#"
					className="order-2 md:order-first"
					onClick={(e) => {
						e.preventDefault();
						trackClick && trackClick(item.hitId, item.trackId);
						handleLinkClick(e);
					}}
				>
					<Image>
						{isMobile ? (
							<>
								{item.image && item.image.thumbnail ? (
									<Img
										src={item.image.thumbnail}
										alt={item.image.alt ? item.image.alt : ''}
									/>
								) : (
									<FaCamera />
								)}
							</>
						) : (
							<>
								{item.image && item.image.thumbnail ? (
									<Img
										src={item.image.thumbnail}
										alt={item.image.alt ? item.image.alt : ''}
									/>
								) : (
									<FaCamera />
								)}
							</>
						)}
					</Image>
				</a>
				<Body>
					<LinkWrapper
						href="#"
						onClick={(e) => {
							e.preventDefault();
							trackClick && trackClick(item.hitId, item.trackId);
							handleLinkClick(e);
						}}
					>
						{item.category && (
							<div>
								<p className="text-p my-1">{item.category}</p>
							</div>
						)}
						<ItemCodeAttributeNameWrapper>
							{/*@ts-ignore*/}
							<Text bold>{`#${item.itemCode}`}</Text>
							<Text>{item.attributeName}</Text>
						</ItemCodeAttributeNameWrapper>
						<h2 className="mt-0 mb-2 text-black text-h3 font-medium">
							{item.itemName}
						</h2>
					</LinkWrapper>

					{item.packingQuantity && item.unit && (
						<Text>
							<FiBox />
							{t('shared/articlerow/packagesize')}
							<OrangeText>{`${item.packingQuantity} ${item.unit}`}</OrangeText>
						</Text>
					)}
					{!cartDisabled && (
						<>
							{priceInfo && priceInfo.stockQty && (
								<Text>
									<FiHome />
									{t('shared/articlerow/stocklevel')}
									<OrangeText>{priceInfo.stockQty}</OrangeText>
								</Text>
							)}
							{item.leadTime && (
								<Text>
									<FiTruck />
									{t('shared/articlerow/leadtime')}
									<OrangeText>{`${item.leadTime} ${t(
										'shared/articlerow/days'
									)}`}</OrangeText>
								</Text>
							)}
						</>
					)}
					{certificates && certificates?.length > 0 && (
						<div className="mr-2 flex w-full">
							{certificates?.map((c) => (
								<Certificate
									tooltipLink={c?.link}
									imageUrl={c?.icon}
									tooltipText={c?.text}
									altText={c.text}
								/>
							))}
						</div>
					)}
				</Body>
			</ContentLeft>
			{cartDisabled && !priceInfo?.netPrice && !isConfArticle ? (
				<>
					<ContentMiddle>
						{item.defaultPrice != null ? (
							<>
								<TextWrapper>
									{priceInfo && priceInfo.netPrice && (
										<Text>{t('shared/articlerow/netprice')}</Text>
									)}
								</TextWrapper>
								<Content>{item && renderNetPrice(item.defaultPrice)}</Content>
							</>
						) : (
							<LoginForPriceWrapper>
								<LoginForPriceText>
									{loginLink ? (
										<a className="hover:underline" href={loginLink?.link}>
											{t('shared/articlerow/loginforprice')}
										</a>
									) : (
										t('shared/articlerow/loginforprice')
									)}
								</LoginForPriceText>
							</LoginForPriceWrapper>
						)}
					</ContentMiddle>
					<ContentRight>
						<Content>
							{!cartDisabled && (
								<div className="inline-block md:block">
									<ProductCounter
										className="justify-center h-8 md:h-auto"
										id={`Counter-${item.itemCode}`}
										itemCode={item.itemCode}
										productName={item.itemName}
										quantity={currentQuantity}
										onChange={(itemCode: string, quantity: number) => {
											setCurrentQuantity(quantity);
										}}
									/>
								</div>
							)}
							{!cartHidden && renderAddToCartButton()}
						</Content>
					</ContentRight>
				</>
			) : (
				<>
					<ContentMiddle>
						{priceInfo ? (
							<>
								{!!priceInfo.netPrice && priceInfo.netPrice != null ? (
									<TextWrapper>
										{!!priceInfo.netPrice && (
											<Text>{t('shared/articlerow/netprice')}</Text>
										)}
										{!!priceInfo.grossPrice && (
											<Text>{t('shared/articlerow/grossprice')}</Text>
										)}

										{!!priceInfo.discount && (
											<Text>{t('shared/articlerow/discount')}</Text>
										)}
									</TextWrapper>
								) : (
									!priceLoading && (
										<PriceOnDemandWrapper>
											<PriceOnDemandText>
												{t('shared/articlerow/priceondemand')}
											</PriceOnDemandText>
										</PriceOnDemandWrapper>
									)
								)}
								{!isConfArticle && priceInfo?.netPrice && (
									<Content>
										{item && renderNetPrice(priceInfo.netPrice)}
										{!priceLoading && priceInfo?.grossPrice && (
											<Text>{`${priceInfo.grossPrice} ${
												item && item.currency ? item.currency : ''
											}`}</Text>
										)}

										{!priceLoading && priceInfo?.discount && (
											<Text>{`- ${priceInfo.discount} ${t(
												'shared/articlerow/discountunit'
											)}`}</Text>
										)}
										{priceLoading && (
											<div className="my-4">
												<Icon icon="loader" animate="spin" size={2} />
											</div>
										)}
									</Content>
								)}
							</>
						) : cartDisabled && isConfArticle ? (
							<LoginForPriceWrapper>
								<LoginForPriceText>
									{loginLink ? (
										<a className="hover:underline" href={loginLink?.link}>
											{t('shared/articlerow/loginforprice')}
										</a>
									) : (
										t('shared/articlerow/loginforprice')
									)}
								</LoginForPriceText>
							</LoginForPriceWrapper>
						) : (
							<div className="my-4 text-center">
								<Icon icon="loader" animate="spin" size={2} />
							</div>
						)}
					</ContentMiddle>
					<ContentRight>
						{!cartHidden && (
							<>
								{isConfArticle ? (
									renderConfButton()
								) : (
									<>
										<div className="inline-block md:block">
											<Counter
												id={`Counter-${item.itemCode}`}
												itemCode={item.itemCode}
												productName={item.itemName}
												quantity={currentQuantity}
												onChange={(itemCode: string, quantity: number) => {
													setCurrentQuantity(quantity);
												}}
											/>
										</div>
										{renderAddToCartButton()}
									</>
								)}
							</>
						)}
					</ContentRight>
				</>
			)}
		</Article>
	);
};

export default ArticleRow;
