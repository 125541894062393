/**
 * StockDiagramPage
 * @module pages/StockDiagramPage
 */

import React from 'react';
import Layout from 'components/ui/Layout';
import SectionHeader from 'components/ui/SectionHeader';
import { Grid, Cell } from 'components/ui/Grid';
import { Header } from './CisionStockDiagramPage.styles';
import Breadcrumbs from 'components/framework/Breadcrumbs';
import Icon from 'components/ui/Icon';
const StockDiagramPage = (props) => {
	const { heading, breadcrumbs, inEditMode } = props;
	return (
		<main id="main-content">
			<Layout backgroundColor="white">
				{breadcrumbs && <Breadcrumbs links={breadcrumbs} />}
				<Grid padding={false}>
					<Cell className="overflow-y-auto" span={12}>
						<Header>
							<SectionHeader
								heading={heading}
								size={'large'}
								isInEditMode={inEditMode}
							/>
						</Header>
					</Cell>
					<Cell className="overflow-y-auto" span={12}>
						<div id="target-ticker"></div>
						<div id="target-sharegraph-wrapper">
							<div className="text-center my-65">
								<Icon
									icon="loader"
									animate="spin"
									size={7}
									aria-hidden={true}
								/>
							</div>
						</div>
					</Cell>
				</Grid>
			</Layout>
		</main>
	);
};

export default StockDiagramPage;
