/**
 * MediaGallery
 * @module components/MediaGallery
 */

import { useState } from 'react';
import MediaThumbnail from './MediaThumbnail';
import MediaView from './MediaView';
import MediaModal from './MediaModal';
import { MediaGalleryItemModel } from 'types/common';

export interface MediaGalleryProps {
	mediaGallery: MediaGalleryItemModel[];
	currentValues?: any;
}

const MediaGallery = ({ mediaGallery, currentValues }: MediaGalleryProps) => {
	const defaultItemArray =
		mediaGallery && mediaGallery.filter((item) => item.isMain);
	const defaultItem =
		defaultItemArray.length > 0 ? defaultItemArray[0] : mediaGallery[0];
	const [activeItem, setActiveItem] = useState(defaultItem);
	const [modalActive, setModalActive] = useState(false);
	const [autoplay, setAutoplay] = useState(0);

	const renderMediaView = (item: any) => {
		setActiveItem(item);
	};

	const toggleModal = () => {
		setModalActive(!modalActive);
	};

	const showPreviousMedia = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		const currentItemIndex = mediaGallery.indexOf(activeItem);
		if (currentItemIndex > 0) {
			const previousItemIndex = currentItemIndex - 1;
			setActiveItem(mediaGallery[previousItemIndex]);
		} else {
			setActiveItem(mediaGallery[mediaGallery.length - 1]);
		}
	};

	const showNextMedia = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		const currentItemIndex = mediaGallery.indexOf(activeItem);
		if (currentItemIndex < mediaGallery.length - 1) {
			const nextItemIndex = currentItemIndex + 1;
			setActiveItem(mediaGallery[nextItemIndex]);
		} else {
			setActiveItem(mediaGallery[0]);
		}
	};

	if (!activeItem) {
		return null;
	}

	return (
		<>
			<MediaView
				item={activeItem as any}
				toggleModal={toggleModal}
				setAutoplay={setAutoplay}
				currentValues={currentValues}
			/>
			<MediaThumbnail
				items={mediaGallery as any}
				renderMediaView={renderMediaView}
				activeItem={activeItem as any}
			/>

			{modalActive && (
				<MediaModal
					modalActive={modalActive}
					item={activeItem as any}
					currentValues={currentValues}
					toggleModal={toggleModal}
					showPreviousMedia={showPreviousMedia}
					showNextMedia={showNextMedia}
					isAutoplay={autoplay}
					setAutoplay={setAutoplay}
				/>
			)}
		</>
	);
};

export default MediaGallery;
