/**
 * MobileMegaMenu
 * @module components/MobileMegaMenu
 */

import {
	SubLink,
	SubSubWrapper,
	PlusButton,
	MinusButton,
} from './MobileMegaMenu.styles';
import RichText from 'components/ui/RichText';

const MobileMegaMenuSubSection = (props) => {
	return (
		<div className={props.isBlockList && 'bg-white mt-0 pt-0 -m-4 p-4'}>
			{props.isBlockList && (
				<div className="flex-100 py-2 font-semibold text-blue">
					{props.blockItem && props.blockItem.text}
				</div>
			)}
			{props.items &&
				props.items.length > 0 &&
				props.items.map((sublink, j) => {
					const subIsActive = props.sublinkIndex === j;
					const subHasChildren =
						sublink.children && sublink.children.length > 0;
					return (
						<ul key={`mobile-menu-${props.i}-${j}`}>
							<SubLink
								isLastItem={j + 1 === props.items.length}
								className={props.isBlockList && 'bg-white'}
								isHeading={true}
								onClick={() => {
									let value = subIsActive ? -1 : j;
									props.setSubLinkIndex(value);
								}}
							>
								{sublink.isLink ? (
									<a href={sublink.link} target={sublink.target}>
										<RichText text={sublink.text} />
									</a>
								) : (
									<div className="font-normal">
										<RichText text={sublink.text} />
									</div>
								)}
								{subHasChildren &&
									(subIsActive ? <MinusButton /> : <PlusButton />)}
							</SubLink>
							{subHasChildren && (
								<SubSubWrapper
									className={props.isBlockList && 'bg-white'}
									active={subIsActive}
								>
									{sublink.children.map((subsub, k) => {
										return (
											<SubLink
												isLastItem={k + 1 === sublink.children.length}
												className={props.isBlockList && 'bg-white'}
												key={`mobile-menu-${props.i}-${j}-${k}`}
											>
												{subsub.isLink ? (
													<a href={subsub.link} target={subsub.target}>
														<RichText text={subsub.text} />
													</a>
												) : (
													<div className="font-normal">
														<RichText text={subsub.text} />
													</div>
												)}
											</SubLink>
										);
									})}
								</SubSubWrapper>
							)}
						</ul>
					);
				})}
		</div>
	);
};

export default MobileMegaMenuSubSection;
