/**
 * MediaThumbnail
 * @module components/MediaThumbnail
 */

import { useContext, useState } from 'react';
import { LocalizationContext } from 'context/localization.context';
import {
	ThumbnailWrapper,
	Image,
	ToggleWrapper,
	Wrapper,
	PlayIcon,
	BoxIcon,
} from './MediaGallery.styles';
import { MediaGalleryItemModel } from 'types/common';

export interface MediaThumbnailProps {
	items: MediaGalleryItemModel[];
	renderMediaView: any;
	activeItem: MediaGalleryItemModel;
}

const MediaThumbnail = ({ items, renderMediaView }: MediaThumbnailProps) => {
	const [showMore, setShowMore] = useState(false);
	const [activeIndex, setActiveIndex] = useState<number | null>(0);
	const { t } = useContext(LocalizationContext as any);
	const totalItems = items.length;
	const nrOfhiddenItems = totalItems - 4;
	let hiddenItemsArray = [];

	if (nrOfhiddenItems > 0) {
		for (var i = 4; i < totalItems; i++) {
			hiddenItemsArray.push(items[i]);
		}
	}

	const sortedItems = items.reduce((acc, item) => {
		if (item.type === '3DConfig') {
			return [item, ...acc]; // Add 3DConfig item first
		}
		return [...acc, item]; // Add other items to the end
	}, [] as MediaGalleryItemModel[]);

	const renderThumbnail = (item: MediaGalleryItemModel, i: number) => {
		return (
			<ThumbnailWrapper
				role="button"
				aria-controls="media-view"
				title={`${t('shared/mediagallery/thumbnail')}-${i}`}
				isActive={i === activeIndex}
				key={`image-${i}`}
				onClick={(e) => {
					e.preventDefault();
					setActiveIndex(i);
					renderMediaView(item);
				}}
				href="#"
				overlay={item?.type}
			>
				{item.thumbnail && (
					<Image
						src={item.thumbnail}
						alt={`${t('shared/mediagallery/showimage')}-${
							item.alt ? item.alt : `nr${i}`
						}`}
					/>
				)}
				{item.type === 'Video' && <PlayIcon iconsize="small" />}
				{item.type === '3DConfig' && <BoxIcon iconsize="small" />}
			</ThumbnailWrapper>
		);
	};

	const toggleHiddenItems = () => {
		setShowMore(!showMore);
	};

	return (
		<Wrapper>
			{sortedItems.map((item, i) => {
				return i < 4 && renderThumbnail(item, i);
			})}
			{showMore &&
				hiddenItemsArray.map((item, i) => {
					return renderThumbnail(item, i + 4);
				})}
			{nrOfhiddenItems > 0 && (
				<ToggleWrapper onClick={toggleHiddenItems} aria-expanded={showMore}>
					{!showMore
						? `+ ${nrOfhiddenItems} ${t('shared/mediagallery/showmore')}`
						: t('shared/mediagallery/showless')}
				</ToggleWrapper>
			)}
		</Wrapper>
	);
};

export default MediaThumbnail;
