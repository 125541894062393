import styled from 'styled-components';
import { H3Css } from 'components/ui/Typography';
import { spacing1x, spacing3x } from 'theme/spacings';
import { deviceSmallMax, deviceMediumMax } from 'theme/mediaQueries';
import Layout from 'components/ui/Layout';

export const SectionHeaderLayout = styled(Layout)`
	margin-bottom: ${spacing3x};
	@media (max-width: ${deviceSmallMax}) {
		${H3Css};
		margin: ${spacing1x} 0;
	}
`;

export const SectionHeaderWrapper = styled.div`
	max-width: 66%;
	@media (max-width: ${deviceMediumMax}) {
		max-width: 100%;
	}
`;
