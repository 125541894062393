import styled, { css } from 'styled-components';
import ProductCounter from 'components/ui/ProductCounter';
import { deviceSmallMax } from 'theme/mediaQueries';
import { fontWeightMedium, fontWeightSemiBold } from 'theme/typography';
import { grey, greyDarker, black, blue, orange } from 'theme/colors';
import {
	spacing05x,
	spacing4x,
	spacing5x,
	spacing2x,
	spacing1x,
} from 'theme/spacings';
import Button from 'components/ui/Button';

const textCss = css`
	font-size: 1.6rem;
	line-height: 2.4rem;
	font-weight: ${({ bold }) => (bold ? fontWeightSemiBold : fontWeightMedium)};
	margin: ${spacing05x} 0 ${spacing05x};
	text-transform: lowercase;
`;

export const Article = styled.article`
	display: flex;
	padding: ${spacing2x};
	border-bottom: 0.1rem solid ${greyDarker};
	align-items: center;

	@media (max-width: ${deviceSmallMax}) {
		flex-wrap: wrap;
		padding: 0;
		display: block;
	}
`;

export const ContentLeft = styled.div`
	flex: 4 1 45%;
	display: flex;

	@media (max-width: ${deviceSmallMax}) {
		justify-content: space-between;
	}
`;

export const Image = styled.div`
	display: flex;
	border: 0.2rem solid ${grey};
	height: 14rem;
	width: 14rem;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;

	svg {
		font-size: 8rem;
		opacity: 0.1;

		@media (max-width: 1150px) {
			height: 5.6rem;
			width: 5.6rem;
		}
	}

	img {
		width: 14rem;
	}

	@media (max-width: 1150px) {
		height: 8.6rem;
		width: 8.6rem;

		img {
			width: 8.6rem;
		}
	}
	@media (max-width: ${deviceSmallMax}) {
		margin-top: ${spacing5x};
	}
`;

export const Body = styled.div`
	padding: 0 ${spacing2x};
	display: inline-block;

	@media (max-width: ${deviceSmallMax}) {
		order: 1;
		padding: ${spacing2x} ${spacing2x} ${spacing2x} 0;
	}
`;

export const ContentMiddle = styled.div`
	flex: 1 1 20%;
	@media (max-width: ${deviceSmallMax}) {
		display: flex;
		justify-content: space-between;
	}
`;

export const Content = styled.div`
	margin: auto 0;
	text-align: center;
	@media (max-width: ${deviceSmallMax}) {
		padding: 0;
	}
`;

export const PriceWrapper = styled.div`
	@media (max-width: ${deviceSmallMax}) {
		display: flex;
		justify-content: space-between;
	}
`;

export const TextWrapper = styled.div`
	display: none;
	@media (max-width: ${deviceSmallMax}) {
		display: flex;
		flex-direction: column;
	}
`;

export const PriceOnDemandWrapper = styled(TextWrapper)`
	display: flex;
	margin: auto 0;
	text-align: center;
	padding: 0 1.6rem;
	@media (max-width: ${deviceSmallMax}) {
		padding: 0;
	}
`;

export const LoginForPriceWrapper = styled.div`
	margin: auto 0;
	text-align: center;
	padding: 0 1.6rem;
	@media (max-width: ${deviceSmallMax}) {
		display: flex;
		flex-direction: column;
		padding: 0;
	}
`;

export const LoaderWrapper = styled.div`
	margin: auto 0;
	text-align: center;
	padding: 0 ${spacing4x};
	min-width: 17rem;
`;

export const ContentRight = styled(Content)`
	flex: 1 1 25%;
	padding-right: 0;
	@media (max-width: ${deviceSmallMax}) {
		display: flex;
		padding: 0;
		margin: ${spacing1x} 0;
	}
`;

export const Counter = styled(ProductCounter)`
	justify-content: center;

	@media (max-width: ${deviceSmallMax}) {
		height: 3.2rem;
	}
`;

export const AddButton = styled(Button)`
	white-space: nowrap;
	@media (max-width: ${deviceSmallMax}) {
		margin: 0 0 ${spacing1x} ${spacing2x};
		:disabled {
			margin: ${spacing2x} 0;
		}
	}
`;

export const ConfButton = styled(AddButton)`
	@media (max-width: ${deviceSmallMax}) {
		margin-left: 0;
	}
`;
export const Img = styled.img`
	max-width: 100%;
	max-height: 100%;
`;
export const LinkWrapper = styled.a`
	text-decoration: none;
	:hover {
		h1 {
			text-decoration: underline;
		}
		p {
			text-decoration: underline;
		}
	}
`;

export const Heading = styled.h2`
	font-size: 2.5rem;
	color: ${blue};
	font-weight: ${fontWeightMedium};
	margin-top: 0;
	margin-bottom: ${spacing1x};
`;

export const Text = styled.p`
	${textCss}
	color: ${black};

	svg {
		margin-right: ${spacing1x};
	}

	@media (max-width: ${deviceSmallMax}) {
		font-size: 1.3rem;
		margin: -0.1rem 0;
	}
`;

export const LoginForPriceText = styled(Text)`
	text-transform: none;
`;
export const PriceOnDemandText = styled(Text)`
	text-transform: none;
	text-align: center;
	width: 100%;
`;

export const OrangeText = styled.span`
	${textCss}
	color: ${orange};
	margin-left: ${spacing1x};

	@media (max-width: ${deviceSmallMax}) {
		font-size: 1.3rem;
	}
`;

export const BlueText = styled(Heading)`
	white-space: ${(props) => (props.noWrap ? 'nowrap' : 'normal')};
	@media (max-width: ${deviceSmallMax}) {
		margin: -0.5rem 0;
		padding: ${(props) => (props.withPadding ? `${spacing1x} 0` : '0')};
	}
`;

export const Span = styled.span`
	${textCss};
	color: ${black};

	:before {
		content: ' / ';
	}
	@media (max-width: ${deviceSmallMax}) {
		font-size: 1.3rem;
	}
`;

export const ItemCodeAttributeNameWrapper = styled.div`
	> p {
		text-transform: uppercase;
		display: inline-block;
		:first-child {
			margin-right: ${spacing1x};
		}
	}
`;
