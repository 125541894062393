/**
 * ImageGalleryModal
 * @module components/ImageGalleryModal
 */

import { useState } from 'react';
import Modal from 'react-aria-modal';
import Icon from 'components/ui/Icon';
import { ImageModel } from 'types/common';
import clsx from 'clsx';

interface Props {
	image: ImageModel;

	onToggle: any;

	showPreviousMedia?: any;

	showNextMedia?: any;

	items?: any;

	setActiveItem?: any;

	translations: {
		'/frontend/shared/mediagallery/pagination': any;
		'/frontend/shared/modal/closemodal': any;
		'/frontend/shared/modal/showpreviousmedia': any;
		'/frontend/shared/modal/shownextmedia': any;
	};
}

const ImageGalleryModal = ({
	image,
	onToggle = () => {},
	showPreviousMedia,
	showNextMedia,
	items,
	setActiveItem,
	translations,
}: Props) => {
	const isMobile = window.innerWidth <= 768;

	const imagesPerPage = isMobile ? 2 : 7;

	const [visible, setVisible] = useState({
		start: 0,
		end: items.length > 7 ? 7 : items.length,
	});

	const handlePreviousClick = (e: any) => {
		showPreviousMedia(e);
		if (visible.start === 0) {
			setVisible({
				start: items.length > 7 ? items.length - imagesPerPage : 0,
				end: items.length,
			});
		} else {
			setVisible({ start: visible.start - 1, end: visible.end - 1 });
		}
	};

	const handleNextClick = (e: any) => {
		showNextMedia(e);
		if (visible.end === items.length) {
			setVisible({
				start: 0,
				end: items.length > 7 ? imagesPerPage : items.length,
			});
		} else {
			setVisible({ start: visible.start + 1, end: visible.end + 1 });
		}
	};

	const handleSetActiveItem = (tn: ImageModel) => {
		setActiveItem(tn);
	};

	const paginationInfo = () => {
		let msg = translations['/frontend/shared/mediagallery/pagination'];

		msg = msg.replace('{START}', visible.start + 1);
		msg = msg.replace('{END}', visible.end);
		msg = msg.replace('{TOTAL}', items.length);

		return msg;
	};

	return (
		<Modal
			onExit={onToggle}
			titleText={'Gallery'}
			underlayClickExits={true}
			focusDialog={true}
			verticallyCenter={true}
			underlayClass="overlay"
		>
			<div className="relative flex flex-col items-center justify-center bg-white rounded image-modal">
				<div className="w-full overflow-hidden">
					{image && (
						<figure className="h-11/12">
							{image.webpSizes && (
								<source
									type="image/webp"
									srcSet={`${image.webpSizes[1]} 1200w, ${image.webpSizes[2]} 800w, ${image.webpSizes[3]} 400w`}
									sizes="(max-width: 767px) 60vw, (min-width: 768px) and (max-width: 1024px) 70vw, 1200px"
								/>
							)}
							{image.sizes && (
								<img
									className="h-full py-6 mx-auto image-objectfit"
									src={image.src}
									srcSet={`${image.sizes[1]} 1200w, ${image.sizes[2]} 800w, ${image.sizes[3]} 400w`}
									sizes="(max-width: 767px) 60vw, (min-width: 768px) and (max-width: 1024px) 70vw, 1200px"
									alt={image.alt}
								/>
							)}

							{image.text && (
								<figcaption className="font-light h-1/12 px-7 pb-7 text-p">
									{image.text}
								</figcaption>
							)}
						</figure>
					)}
					<button
						className="absolute top-0 right-0 p-3 bg-transparent hover:bg-greyDark"
						aria-label={translations['/frontend/shared/modal/closemodal']}
						onClick={(e) => {
							e.preventDefault();
							onToggle();
						}}
					>
						<Icon icon="close" color="#949494" size={isMobile ? 1 : 2} />
					</button>
					{showPreviousMedia && (
						<button
							className="absolute left-0 p-3 transform -translate-y-1/2 bg-transparent top-1/2 hover:bg-greyDark"
							onClick={(e) => {
								handlePreviousClick(e);
							}}
							title={translations['/frontend/shared/modal/showpreviousmedia']}
						>
							<Icon
								icon="chevron"
								direction="right"
								color="#949494"
								size={isMobile ? 1 : 2}
							/>
						</button>
					)}
					{showNextMedia && (
						<button
							className="absolute right-0 p-3 transform -translate-y-1/2 bg-transparent top-1/2 hover:bg-greyDark"
							onClick={(e) => {
								handleNextClick(e);
							}}
							title={translations['/frontend/shared/modal/shownextmedia']}
						>
							<Icon
								icon="chevron"
								direction="left"
								color="#949494"
								size={isMobile ? 1 : 2}
							/>
						</button>
					)}
				</div>
			</div>
			{items && (
				<>
					<div className="flex items-center justify-center w-7/12 mx-auto mt-3 space-x-3">
						<button
							title={translations['/frontend/shared/modal/showpreviousmedia']}
							onClick={(e) => {
								handlePreviousClick(e);
							}}
						>
							<Icon icon="chevron" direction="right" color="#fff" size={1} />
						</button>
						<div className="flex items-center justify-center mx-auto space-x-3">
							{items
								.slice(visible.start, visible.end)
								.map((thumbnail: any, index: number) => {
									return (
										<div
											className="w-32 h-12 cursor-pointer"
											onClick={() => handleSetActiveItem(thumbnail)}
										>
											<img
												src={thumbnail.image.thumbnail}
												alt={thumbnail.alt}
												className={clsx(
													'object-cover w-full h-full rounded',
													thumbnail.image !== image && 'opacity-75'
												)}
											/>
										</div>
									);
								})}
						</div>
						<button
							title={translations['/frontend/shared/modal/shownextmedia']}
							onClick={(e) => {
								handleNextClick(e);
							}}
						>
							<Icon icon="chevron" direction="left" color="#fff" size={1} />
						</button>
					</div>
					<div className="mt-3 text-sm font-bold text-center text-white">
						{paginationInfo()}
					</div>
				</>
			)}
		</Modal>
	);
};

export default ImageGalleryModal;
