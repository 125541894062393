import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	ConfNumberModel,
	ConfSelectModel,
	ConfTextModel,
	DictionaryModel,
	DocumentModel,
	LinkModel,
	MediaGalleryItemModel,
	SpecificationsModel,
} from 'types/common';

import axios, { CancelTokenSource } from 'axios';
import { RootState } from 'store';
import { cancelPricingRequest, cancelToken } from 'store/api/cartApi';
import Axios from 'axios';
import { FrameworkModel } from 'types/framework';

interface ArticleDetailConfigurationModel {
	mediaGallery?: MediaGalleryItemModel[];
	documents?: DocumentModel[];
	rangeSpecifications?: SpecificationsModel;
	confData?: ConfDataModel;
	unit?: string;
	currency?: string;
	itemCode?: string;
	itemName?: string;
	longDescription?: string;
	shortDescription?: string;
	name?: string;
	framework?: FrameworkModel;
	breadcrumbs?: LinkModel[];
	copyUrl?: string;
}

interface ConfDataModel {
	confOptions: Array<ConfSelectModel | ConfNumberModel | ConfTextModel>;
	summary?: string;
	itemId: string;
	quantity: number;
	configId: string;
}

interface ConfState {
	confContent: ArticleDetailConfigurationModel;
	confPrices: { dictionary: DictionaryModel } | null;
	priceLoading: boolean;
	fieldLoading: boolean;
	error: string | null;
	loading: boolean;
}

const initialState: ConfState = {
	confContent: {},
	confPrices: null,
	fieldLoading: false,
	priceLoading: false,
	error: null,
	loading: false,
};

const slice = createSlice({
	name: 'configurator',
	initialState,
	reducers: {
		confStart: (state) => {
			state.loading = true;
		},
		confSuccess: (
			state,
			action: PayloadAction<ArticleDetailConfigurationModel>
		) => {
			state.confContent = { ...state.confContent, ...action.payload };
			state.loading = false;
			state.error = null;
		},
		confValidateStart: (state) => {
			state.fieldLoading = true;
		},
		confValidateSuccess: (state, action: PayloadAction<ConfDataModel>) => {
			state.confContent.confData = { ...action.payload };
			state.confPrices = null;
			state.fieldLoading = false;
			state.error = null;
		},
		confPriceStart: (state) => {
			state.priceLoading = true;
		},
		confPriceSuccess: (
			state,
			action: PayloadAction<{ dictionary: DictionaryModel }>
		) => {
			state.confPrices = { ...action.payload };
			state.priceLoading = false;
			state.error = null;
		},
		confFailed: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.loading = false;
		},
		confReset: () => initialState,
	},
});

export const fetchConfArticle = (routeApiUrl: string) => async (
	dispatch: any
) => {
	let data;
	dispatch(confStart());
	try {
		data = await getConfArticle(routeApiUrl, cancelToken);
	} catch (err: any) {
		if (Axios.isCancel(err)) {
			console.log('Request cancelled: ', err.message);
		} else {
			console.log(err);
		}
		return;
	}
	dispatch(confSuccess(data));
};

export const fetchConfValidate = (
	routeApiUrl: string,
	values: any,
	itemCode: string,
	quantity: number,
	fieldIndex: number
) => async (dispatch: any) => {
	let data;
	dispatch(confValidateStart());
	try {
		data = await getConfData(
			routeApiUrl,
			values,
			itemCode,
			quantity,
			fieldIndex
		);
		dispatch(confValidateSuccess(data));
	} catch (err) {
		console.log(err);
		return;
	}
};

export const fetchConfPrices = (routeApiUrl: string) => async (
	dispatch: any,
	getState: any
) => {
	const {
		configId,
		quantity,
		itemId,
	} = getState().configurator.confContent.confData;

	dispatch(confPriceStart());
	let data;
	let requestData = {
		configId: configId,
		quantity: quantity,
		itemId: itemId,
	};

	cancelPricingRequest();
	try {
		data = await confPriceApi(routeApiUrl, requestData, cancelToken);
		dispatch(confPriceSuccess(data as { dictionary: DictionaryModel }));
	} catch (err) {
		console.log(err);
		return;
	}
};

export const getConfData = async (
	apiUrl: string,
	values: string,
	itemCode: string,
	quantity: number,
	fieldIndex: number
) => {
	const url = `${apiUrl}`;
	let body = {
		confOptions: values,
		itemId: itemCode,
		quantity: quantity,
		//If changedField is -1 that means it's manual validation
		changedField: fieldIndex === -1 ? 999 : fieldIndex,
	};

	axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	return axios.post(url, body).then(
		(response) => {
			return response.data;
		},
		(error) => {
			return error;
		}
	);
};

export const getConfArticle = async (
	apiUrl: string,
	cancelSource?: CancelTokenSource
) => {
	const url = `${apiUrl}`;
	const { data } = await axios.get<ArticleDetailConfigurationModel>(url, {
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
		},
		cancelToken: cancelSource?.token,
	});
	return data;
};

export const confPriceApi = async (
	apiUrl: string,
	requestData: { configId: string; quantity: number; itemId: string },
	cancelSource?: CancelTokenSource
) => {
	const url = `${apiUrl}`;
	const { data } = await axios.get<ArticleDetailConfigurationModel>(url, {
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
		},
		params: requestData,
		cancelToken: cancelSource?.token,
	});
	return data;
};

export const selectConfigurator = (state: RootState) => state.configurator;
export const selectConfContent = (state: RootState) =>
	state.configurator.confContent;
export const selectConfOptions = (state: RootState) =>
	state.configurator.confContent.confData?.confOptions;
export const selectConfSummary = (state: RootState) =>
	state.configurator.confContent.confData?.summary;
export const selectConfQuantity = (state: RootState) =>
	state.configurator.confContent.confData?.quantity;
export const selectConfConfigId = (state: RootState) =>
	state.configurator.confContent.confData?.configId;
export const selectConfItemId = (state: RootState) =>
	state.configurator.confContent.confData?.itemId;
export const selectConfPrices = (state: RootState) =>
	state.configurator.confPrices;
export const selectConfPriceLoading = (state: RootState) =>
	state.configurator.priceLoading;
export const selectConfFieldLoading = (state: RootState) =>
	state.configurator.fieldLoading;

export const {
	confStart,
	confSuccess,
	confValidateStart,
	confValidateSuccess,
	confPriceStart,
	confPriceSuccess,
	confFailed,
	confReset,
} = slice.actions;

export default slice.reducer;
