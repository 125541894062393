/**
 * ProductCounter
 * @module components/ProductCounter
 */

import React, { useContext, useState, useEffect, useRef } from 'react';
import { LocalizationContext } from 'context/localization.context';
import Icon from 'components/ui/Icon';
import { useDebounce } from 'hooks';
import { Int32MaxValue } from 'common/constants';
import { useSelector } from 'react-redux';
import { selectCartObject } from 'store/modules/cart';
import clsx from 'clsx';

interface Props {
	/** The ProductCounter id  */
	id: string;

	/** The product name of what ProductCounter is counting */
	productName: string;

	/** Optional className  */
	className?: string;

	/** Initial quantity */
	quantity: number;

	/** Itemcode the counter belongs to */
	itemCode: string;

	/** Optional callback function  */
	onChange?: Function;
}

const ProductCounter = ({
	id,
	productName,
	className,
	quantity,
	itemCode,
	onChange = () => {},
}: Props): JSX.Element => {
	const { t } = useContext<any>(LocalizationContext);
	const { cartDisabled } = useSelector(selectCartObject);
	const inputRef = useRef<any>();
	const [value, setValue] = useState<number | undefined>(quantity || 1);
	const debouncedValue = useDebounce(value, 300);

	const handleChange = (e: any) => {
		let inputValue = e.currentTarget.value;
		if (inputValue > Int32MaxValue) {
			inputValue = Int32MaxValue;
		}

		if (isNaN(inputValue) || inputValue === '' || inputValue === 0) {
			handleQuantityChanged(undefined);
		} else {
			handleQuantityChanged(parseInt(inputValue));
		}
	};

	const handleKeyDown = (e: any) => {
		if (e.keyCode === 69 || e.keyCode === 189 || e.keyCode === 187) {
			e.preventDefault();
		}
	};

	const handleQuantityChanged = (quantity: number | undefined) => {
		setValue(quantity);
	};

	useEffect(() => {
		if (quantity !== value) {
			setValue(quantity || 1);
		}
		//eslint-disable-next-line
	}, [quantity]);

	useEffect(() => {
		if (value === debouncedValue && debouncedValue !== quantity) {
			onChange(itemCode, debouncedValue);
		}
		//eslint-disable-next-line
	}, [debouncedValue, value]);

	return (
		<div className={clsx('flex', className)}>
			<button
				className={clsx(
					'border border-grey rounded-tl-md rounded-bl-md px-2 bg-grey disabled:cursor-default disabled:text-greyDarker disabled:bg-grey disabled:border-grey disabled:border-solid disabled:border-2',
					!(!value || value <= 1) && 'hover:bg-grey-hover'
				)}
				onClick={(e) => {
					e.preventDefault();
					handleQuantityChanged(value && (value <= 1 ? 0 : value - 1));
				}}
				disabled={!value || value <= 1}
			>
				<Icon icon="minus" aria-hidden={true} />
				<span className="sr-only">
					{`${t('shared/articlerow/decreaseamountfor')} ${productName}-${id}`}
				</span>
			</button>

			<label className="sr-only" htmlFor={id}>
				{`${t('shared/articlerow/selectedamountfor')} ${productName}-${id}`}
			</label>
			<input
				type="number"
				aria-live="polite"
				id={id}
				ref={inputRef}
				value={value || ''}
				min={1}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				disabled={cartDisabled}
				className="product-counter p-2 border-2 border-grey border-l-0 border-r-0 w-12 md:w-16 text-center text-sm md:text-p font-bold md:font-normal"
			/>

			<button
				className={clsx(
					'border border-grey rounded-tr-md rounded-br-md px-2 bg-grey disabled:cursor-default disabled:text-greyDarker disabled:bg-grey disabled:border-grey disabled:border-solid disabled:border-2',
					!cartDisabled && 'hover:bg-grey-hover'
				)}
				onClick={(e) => {
					e.preventDefault();
					handleQuantityChanged(value ? value + 1 : 1);
				}}
				disabled={cartDisabled}
			>
				<Icon icon="plus" aria-hidden={true} />
				<span className="sr-only">
					{`${t('shared/articlerow/increaseamountfor')} ${productName}-${id}`}
				</span>
			</button>
		</div>
	);
};

export default ProductCounter;
