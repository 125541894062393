/**
 * FilterFieldSet
 * @module components/FilterFieldSet
 */

import { useContext, useState } from 'react';
import { LocalizationContext } from 'context/localization.context';
import { Fieldset, Heading, Button } from './FilterFieldSet.styles';
import { FacetItemModel } from 'types/filter-types';
import FilterOption from './FilterOption';
import FilterByRegion from './FilterByRegion';
import { FilterFacets } from 'types/common';
import FilterByLocationType from './FilterByLocationType';
import { useMedia } from 'hooks';
import { FiChevronDown } from 'react-icons/fi';

interface FilterFieldSetModel {
	name: string;
	items: FacetItemModel[];
	facetId: string;
	className?: string;
	onChange: (facetId: string, id: string, active: boolean) => void;
	defaultNumberToShow?: number;
	alternateDisplay?: boolean;
	showFieldset?: boolean;
	isNewContactPage?: boolean;
	isOrderHistory?: boolean;
}

const FilterFieldSet = ({
	alternateDisplay,
	name,
	items,
	facetId,
	className,
	onChange,
	defaultNumberToShow,
	isNewContactPage = false,
	isOrderHistory = false,
}: FilterFieldSetModel) => {
	const { t }: any = useContext(LocalizationContext);
	const isMobile = useMedia('(max-width: 1300px)');
	const [expanded, setExpanded] = useState(false);

	const toggleShowMore = () => {
		setExpanded(!expanded);
	};

	const numberToShow = defaultNumberToShow || 5;

	const handleChange = (event: any) => {
		onChange(facetId, event.target.value, true);
	};

	// Calculate if there are more items to show to show dropdown if it does not fit in the container
	const names: string[] = items.map((item) => item.name);
	const totalLength: number = names.reduce(
		(acc, name) =>
			acc + name.length + (FilterFacets.LOCATION_TYPE === facetId ? 0 : 3),
		0
	);

	return (
		<>
			{isNewContactPage ? (
				<>
					{(isMobile || totalLength > 114) &&
					(FilterFacets.REGION === facetId ||
						FilterFacets.LOCATION_TYPE === facetId) ? (
						<div className="w-full relative inline-block text-left">
							<select
								className="focus:outline-none new-location-row-details new-location-row-heading my-2.5 appearance-none"
								name={facetId}
								id={facetId}
								onChange={handleChange}
							>
								{items.map((item, index) => (
									<option value={item.id} key={index + item.id + item.name}>
										{item.name}
									</option>
								))}
							</select>
							<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
								<FiChevronDown size={25} />
							</div>
						</div>
					) : (
						<>
							{FilterFacets.REGION === facetId && (
								<ul
									id={facetId}
									className="flex my-5 h-49px border-b-1 border-grey-hover"
								>
									{items.map((item) => (
										<FilterByRegion
											key={`${name}-${item.id}`}
											item={item}
											facetId={facetId}
											onChange={onChange}
											className="mr-4"
										/>
									))}
								</ul>
							)}
							{FilterFacets.LOCATION_TYPE === facetId && (
								<ul id={facetId} className="flex my-5">
									{items.map((item) => (
										<FilterByLocationType
											key={`${name}-${item.id}`}
											item={item}
											facetId={facetId}
											onChange={onChange}
										/>
									))}
								</ul>
							)}
						</>
					)}
				</>
			) : (
				<Fieldset className={className} aria-live="polite">
					{name && <Heading as="legend">{name}</Heading>}
					{items && items.length < numberToShow ? (
						items.map((item) => (
							<FilterOption
								isOrderHistory={isOrderHistory}
								alternateDisplay={alternateDisplay}
								item={item}
								facetId={facetId}
								onChange={onChange}
								key={`${name}-${item.id}`}
							/>
						))
					) : (
						<>
							{items.map((item, i) => {
								if (expanded || i < numberToShow - 1) {
									return (
										<FilterOption
											alternateDisplay={alternateDisplay}
											item={item}
											facetId={facetId}
											onChange={onChange}
											key={`${name}-${item.id}`}
										/>
									);
								}
								return false;
							})}
							<Button
								as="button"
								onClick={(e: any) => {
									e.preventDefault();
									toggleShowMore();
								}}
								aria-expanded={expanded}
							>
								{expanded ? (
									<>{`-  ${t('shared/filter/lessoptions')}`}</>
								) : (
									<>{`+  ${t('shared/filter/moreoptions')}`}</>
								)}
							</Button>
						</>
					)}
				</Fieldset>
			)}
		</>
	);
};

export default FilterFieldSet;
