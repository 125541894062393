/**
 * FilterBar
 * @module components/FilterBar
 */

import { useContext, useState, useEffect } from 'react';
import { LocalizationContext } from 'context/localization.context';
import MobileSelect from 'components/ui/MobileSelect';
import clsx from 'clsx';

interface Props {
	defaultDisplayOption?: number;
	defaultSortByItem: string;
	sortByItems: { name: string; id: string }[];
	onFilter: Function;
	displayOptions?: number[];
	sortLabel?: string;
	sortLabelMobile?: string;
	isFilter?: boolean;
}

const FilterBar = ({
	defaultDisplayOption,
	defaultSortByItem,
	displayOptions,
	sortByItems,
	onFilter,
	sortLabel,
	sortLabelMobile,
	isFilter = false,
}: Props) => {
	const [isMobile, setIsMobile] = useState(false);
	const [sortBy, setSortBy] = useState<string>(defaultSortByItem);
	const [display, setDisplay] = useState<any>(defaultDisplayOption || []);
	const { t } = useContext<any>(LocalizationContext);

	const handleSortChange = (e: any, id: string) => {
		e.preventDefault();
		setSortBy(id);
		onFilter(id, display);
	};

	const handleDisplayChange = (e: any, value: number) => {
		e.preventDefault();
		setDisplay(value);
		onFilter(sortBy, value);
	};

	useEffect(() => {
		if (defaultSortByItem !== sortBy) {
			setSortBy(defaultSortByItem);
		}
		//eslint-disable-next-line
	}, [defaultSortByItem]);

	useEffect(() => {
		if (defaultDisplayOption !== display) {
			setDisplay(defaultDisplayOption);
		}
		//eslint-disable-next-line
	}, [defaultDisplayOption]);

	useEffect(() => {
		const isDevice = () => {
			if (
				/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
					navigator.userAgent
				)
			) {
				return true;
			} else {
				return false;
			}
		};
		setIsMobile(isDevice());
		//eslint-disable-next-line
	}, []);

	return (
		<div
			id="filterbarContentWrapper"
			className="clear-both bg-blue flex justify-between font-normal text-white p-1 md:p-2"
		>
			<div className="flex flex-wrap ml-2">
				<div className="hidden md:flex">
					<p className="font-extralight inline-block text-p tracking-wide">
						{sortLabel || t('shared/filter/sortarticlesby')}
					</p>
					{sortByItems &&
						sortByItems.map((item: any, i: number) => {
							return (
								<a
									className={clsx(
										sortBy === item.id ? 'font-bold' : 'font-extralight',
										'filterbar-item text-p tracking-wide hover:underline'
									)}
									href={isFilter ? `?group=${item.id}` : `?sort=${item.id}`}
									onClick={(e) => {
										handleSortChange(e, item.id);
									}}
									key={`sortBy-link-${i}`}
								>
									{item.name}
								</a>
							);
						})}
				</div>
				<div className="flex flex-wrap items-center md:hidden">
					{isMobile ? (
						<label
							className="font-extralight inline-block text-p tracking-wide"
							htmlFor={'sortbyselect'}
						>
							{sortLabelMobile || t('shared/filter/sortby')}
						</label>
					) : (
						<p className="font-extralight inline-block text-p tracking-wide">
							{sortLabelMobile || t('shared/filter/sortby')}
						</p>
					)}
					<MobileSelect
						id={'sortbyselect'}
						items={sortByItems}
						preselected={sortBy}
						onClick={handleSortChange}
						onChange={handleSortChange}
						queryBase={'sortby'}
						isMobile={isMobile}
					/>
				</div>
			</div>
			{displayOptions && (
				<div className="hidden md:flex md:flex-wrap items-center">
					<p className="font-extralight inline-block text-p tracking-wide">
						{t('shared/filter/display')}
					</p>
					<div className="hidden md:flex-wrap items-center md:flex text-p">
						{displayOptions &&
							displayOptions.length > 0 &&
							displayOptions.map((displayItem: number, i: number) => {
								return (
									<a
										className={clsx(
											displayItem === display ? 'font-bold' : 'font-extralight',
											'filterbar-item text-p tracking-wide hover:underline'
										)}
										href={`?display=${displayItem}`}
										onClick={(e) => handleDisplayChange(e, displayItem)}
										key={`display-link-${i}`}
									>
										{displayItem}
									</a>
								);
							})}
					</div>
				</div>
			)}
			{displayOptions && displayOptions.length > 0 && (
				<div className="flex flex-wrap items-center md:hidden">
					{isMobile ? (
						<label
							className="font-extralight inline-block text-p tracking-wide"
							htmlFor={'displayselect'}
						>
							{t('shared/filter/display')}
						</label>
					) : (
						<p className="font-extralight inline-block text-p tracking-wide">
							{t('shared/filter/display')}
						</p>
					)}
					<MobileSelect
						id={'displayselect'}
						items={displayOptions.map((item: any) => ({
							name: item,
							value: item,
						}))}
						preselected={display}
						isMobile={isMobile}
						onClick={handleDisplayChange}
						onChange={handleDisplayChange}
						queryBase={'display'}
					/>
				</div>
			)}
		</div>
	);
};

export default FilterBar;
