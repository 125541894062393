/**
 * CartArticleRow
 */

import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import ProductCounter from 'components/ui/ProductCounter';
import { useMedia } from 'hooks';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
	AddNotes,
	AddToCart,
	selectCartObject,
	UpdateArticleDeliveryDates,
} from 'store/modules/cart';

import { ArticleDeliveryDatesModel, ArticleModel } from 'types/article';
import { AddToCartModel } from 'types/cart-types';
import { AddNotesModel, CertificatesItem } from 'types/common';

import { GenericWarning, ServerError } from 'common/helpers';
import { LocalizationContext } from 'context/localization.context';
import AddNotesModal from 'components/ui/AddNotesModal';
import DatePickerInput from 'components/ui/DatePickerInput';
import { getDay } from 'date-fns';
import clsx from 'clsx';
import Certificate from 'components/ui/Certificate';

interface Props extends ArticleModel {
	handleQuantityChange?: Function;

	handleOpenArticleModal?: any;

	handleRemoveArticle?: any;

	handleCheck?: any;

	checked?: boolean;

	addToCartBtnDisabled?: boolean;

	notesModalDisabled?: boolean;

	cartLoading?: boolean;

	firstItem?: boolean;

	shipDate?: string;

	earliestShipDate?: string;

	shipDateChanged?: boolean;

	disabled?: boolean;

	sort?: string;

	activeMarket?: string;

	certificates?: CertificatesItem[];

	language?: string;

	message?: string;

	configId?: string;
}

/** Main description for this component. */
const CartArticleRow = (prop: Props): JSX.Element => {
	const {
		attributeName,
		itemName,
		unit,
		lineItemId,
		itemCode,
		packingQuantity,
		stockQuantity,
		quantity,
		configDescription,
		image,
		leadTime,
		notes,
		certificates,
		prices,
		link,
		handleQuantityChange,
		handleOpenArticleModal,
		handleRemoveArticle,
		handleCheck,
		checked,
		addToCartBtnDisabled = true,
		notesModalDisabled = false,
		cartLoading = false,
		shipDate,
		earliestShipDate,
		shipDateChanged,
		sort,
		activeMarket,
		language,
		isRemovable,
		isEditableQuantity,
		message,
		configId,
	} = prop;

	const {
		withPrice,
		goodsMarkingHidden,
		shipments,
		singleShipment,
		commentHidden,
	} = useSelector(selectCartObject);

	const { t }: any = useContext(LocalizationContext);
	const dispatch = useDispatch();
	const isMobile = useMedia('(max-width: 1024px)');
	const [noteModalActive, setNoteModalActive] = useState(false);
	const toggleNoteModal = () => {
		setNoteModalActive(!noteModalActive);
	};

	const handleAddNotes = async (goodsmarking?: string, comment?: string) => {
		try {
			const notesArray: AddNotesModel[] = [
				{
					itemCode: itemCode,
					lineItemId: lineItemId,
					goodsMarking: goodsmarking,
					comments: comment,
				},
			];
			await dispatch(AddNotes(notesArray, sort));
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/cartaddnoteserror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		} finally {
			toggleNoteModal();
		}
	};

	const handleAddToCart = async () => {
		try {
			const articleList: AddToCartModel[] = [
				{
					itemCode: itemCode,
					quantity: quantity,
					configId: configId,
					configDescription: configDescription,
					goodsMarking: notes.goodsMarking,
					comments: notes.comments,
				},
			];
			await dispatch(AddToCart('POST', articleList));
			toast(t('shared/toast/cartaddsuccess'), { type: toast.TYPE.SUCCESS });

			if (window.dataLayer) {
				window.dataLayer.push({
					event: 'add_to_cart',
					domain: activeMarket,
					ecommerce: {
						items: [
							{
								item_name: itemName,
								item_id: itemCode,
								price: prices.amountGrossPriceFormatted,
								item_variant: attributeName,
								quantity: quantity,
							},
						],
					},
				});
			}
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/cartupdateerror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		}
	};
	const adjustForTimezone = (date: Date) => {
		let timeOffsetInMS = date.getTimezoneOffset() * 60000;
		date.setTime(date.getTime() - timeOffsetInMS);
		return date;
	};
	const getDateString = (date: Date) => {
		return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
			'0' + date.getDate()
		).slice(-2)}`;
	};
	const handleDateChanged = async (date: Date) => {
		try {
			const updateDeliveryDates: ArticleDeliveryDatesModel[] = [
				{
					itemCode: itemCode,
					lineItemId: lineItemId,
					shipDate: getDateString(adjustForTimezone(date)),
				},
			];
			await dispatch(UpdateArticleDeliveryDates(updateDeliveryDates));
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/cartaddnoteserror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		}
	};

	return (
		<>
			<article className="articlerow block lg:flex justify-between py-4 md:px-4 relative item-start border-b border-greyDarker flex-nowrap">
				<div
					className={clsx(
						'flex justify-between lg:justify-start article-row__left-item',
						shipments && shipments.length > 0
							? 'lg:max-w-137.5'
							: 'lg:max-w-160'
					)}
				>
					{!goodsMarkingHidden && handleCheck && (
						<label
							htmlFor={`select-article-${itemCode}-${lineItemId}`}
							className="flex mr-4"
						>
							<input
								id={`select-article-${itemCode}-${lineItemId}`}
								type="checkbox"
								onClick={() => handleCheck(itemCode, lineItemId)}
								defaultChecked={checked}
								className="w-4.5 h-4.5"
							/>
							<span className="sr-only">
								{t('shared/goodsmark/selectarticle')}
							</span>
						</label>
					)}
					<div
						className={clsx(
							'flex border-2 border-grey w-20 h-20 md:w-35 md:h-35 justify-center items-center flex-shrink-0 order-2 lg:order-none',
							isRemovable && 'mt-8 lg:mt-0'
						)}
					>
						{image && image.src ? (
							<img
								className="w-35 max-w-full max-h-full"
								src={image?.src}
								alt={image?.alt || ''}
							/>
						) : (
							<Icon icon="camera" size={isMobile ? 7 : 9} color="#E4E4E4" />
						)}
					</div>
					<div className="md:px-4 py-0 inline-block order-1 lg:order-none">
						{link ? (
							<a
								href="#"
								className="group"
								onClick={(e) => {
									e.preventDefault();
									handleOpenArticleModal({ link: link, isActive: true });
								}}
							>
								<div>
									<p className="text-p font-semibold inline-block mr-2 uppercase">{`#${itemCode}`}</p>
									<p className="text-p inline-block uppercase">
										{attributeName}
									</p>
								</div>
								{itemName && (
									<h2 className="text-blue text-h3 font-medium mb-2 group-hover:underline">
										{itemName}
									</h2>
								)}
							</a>
						) : (
							<>
								<div>
									<p className="text-p font-semibold inline-block mr-2 uppercase">{`#${itemCode}`}</p>
									<p className="text-p inline-block uppercase">
										{attributeName}
									</p>
								</div>
								{itemName && (
									<h2 className="text-blue text-h3 font-medium mb-2 group-hover:underline">
										{itemName}
									</h2>
								)}
							</>
						)}
						{packingQuantity && (
							<p className="text-p lowercase flex items-center">
								<span>
									<Icon icon="box" className="mr-2" color="#2A2A2A" />
								</span>
								{t('shared/articlerow/packagesize')}
								<span className="text-orange lowercase ml-2 font-medium">{`${packingQuantity} ${unit}`}</span>
							</p>
						)}
						{stockQuantity && (
							<p className="text-p lowercase flex items-center">
								<span>
									<Icon icon="home" className="mr-2" color="#2A2A2A" />
								</span>
								{t('shared/articlerow/stocklevel')}
								<span className="text-orange lowercase ml-2 font-medium">
									{stockQuantity}
								</span>
							</p>
						)}
						{leadTime && (
							<p className="text-p lowercase flex items-center">
								<span>
									<Icon icon="truck" className="mr-2" color="#2A2A2A" />
								</span>
								{t('shared/articlerow/leadtime')}
								<span className="text-orange lowercase ml-2 font-medium">{`${leadTime} ${t(
									'shared/articlerow/days'
								)}`}</span>
							</p>
						)}
						{notesModalDisabled && (notes?.goodsMarking || notes?.comments) && (
							<p className="text-p lowercase flex items-baseline">
								<span>
									<Icon icon="tag" className="mr-2" color="#2A2A2A" />
								</span>
								<span>
									{!goodsMarkingHidden && notes.goodsMarking && (
										<span className="block">{notes.goodsMarking}</span>
									)}
									{notes.comments && (
										<span className="block">{notes.comments}</span>
									)}
								</span>
							</p>
						)}
						{configDescription && (
							<p className="text-p flex">
								<span>
									<Icon icon="sliders" className="mr-2" color="#2A2A2A" />
								</span>
								<a
									href="#"
									onClick={(e) => {
										e.preventDefault();
										handleOpenArticleModal({ link: link, isActive: true });
									}}
									className="text-blue hover:underline"
								>
									<span>{configDescription}</span>
								</a>
							</p>
						)}
						{!(commentHidden && goodsMarkingHidden) &&
							!notesModalDisabled &&
							notes && (
								<p className="text-p flex">
									<span>
										<Icon icon="tag" className="mr-2" color="#2A2A2A" />
									</span>
									<a
										href="#"
										onClick={(e) => {
											e.preventDefault();
											toggleNoteModal();
										}}
										className="text-blue hover:underline"
									>
										{notes.goodsMarking || notes.comments ? (
											<>
												{!goodsMarkingHidden && (
													<span className="block truncate max-w-50">
														{notes.goodsMarking}
													</span>
												)}
												<span className="block truncate max-w-50">
													{notes.comments}
												</span>
											</>
										) : (
											t('shared/articlerow/addanote')
										)}
									</a>
								</p>
							)}
						{certificates && certificates?.length > 0 && (
							<div className="flex w-75 items-start p-4">
								{certificates?.map((c) => (
									<Certificate
										tooltipLink={c?.link}
										imageUrl={c?.icon}
										tooltipText={c?.text}
										altText={c.text}
									/>
								))}
							</div>
						)}
						{!handleQuantityChange && shipments && shipments?.length > 0 && (
							<p className="flex text-p">
								<span className="mr-2 items-center flex">
									<Icon icon="calendar" />
								</span>
								<span className="text-p align-center mr-2">
									{t('checkoutpage/shipdate')}
								</span>
							</p>
						)}
					</div>
				</div>
				<div className="mt-8 flex justify-between items-end lg:block">
					{isEditableQuantity && handleQuantityChange && (
						<>
							<div className="inline-block md:flex items-center flex-col">
								<ProductCounter
									id={`Counter-${itemCode}`}
									productName={itemName}
									quantity={quantity}
									itemCode={itemCode}
									onChange={(
										itemCode: string,
										count: number,
										lineItemId: string
									) => {
										if (handleQuantityChange)
											handleQuantityChange(prop, itemCode, count);
									}}
								/>

								{!addToCartBtnDisabled && (
									<div className="flex justify-center">
										<Button
											type="button"
											buttonColor="blue"
											onClick={handleAddToCart}
										>
											<span aria-hidden={true}>
												{isMobile
													? t('shared/articlerow/add')
													: t('shared/articlerow/addtocart')}
											</span>

											<span className="sr-only">
												{`${t(
													'shared/articlerow/add'
												)} ${itemName}-${itemCode}`}
											</span>
											<Icon icon="cart" size={1.25} aria-hidden="true" />
										</Button>
									</div>
								)}
							</div>
						</>
					)}{' '}
					{!isEditableQuantity && (
						<div className="inline-block md:flex flex-col h-full">
							<>
								<p
									className={clsx(
										'text-h3 lg:flex items-end',
										shipments && shipments.length > 0
											? 'justify-end'
											: 'justify-center'
									)}
								>
									{quantity}
									<span className="ml-1.5 text-p">{unit}</span>
								</p>
								{((!handleQuantityChange &&
									shipments &&
									shipments.length > 0) ||
									shipDateChanged ||
									message) && (
									<div className="h-full flex justify-end flex-col">
										{shipDateChanged && (
											<p className="px-2 md:px-4 md:py-1 flex items-baseline bg-orangeLightest rounded-xl w-full">
												<span>
													<Icon
														icon="alertTriangle"
														color="#D0021B"
														className="mr-2 text-orangeLight"
														size={1}
													/>
												</span>
												<span className="text-sm md:text-p text-orangeLight">
													{t('checkoutpage/newdatesetbythesystem')}
												</span>
											</p>
										)}

										{!handleQuantityChange &&
											shipments &&
											shipments.length > 0 && (
												<DatePickerInput
													id={'desireddate'}
													selected={new Date(shipDate as string)}
													minDate={new Date(earliestShipDate as string)}
													onChange={(date: Date) => handleDateChanged(date)}
													daysDisabled={(date: Date) =>
														getDay(date) !== 0 && getDay(date) !== 6
													}
													// @ts-ignore:next-line
													className="mt-0 mb-0"
													disabled={singleShipment}
													activeMarket={activeMarket}
													language={language}
												/>
											)}
										{message && (
											<div className="text-sm  text-orangeLight bg-orangeLightest p-2 flex rounded">
												<span>
													<Icon
														icon="alertTriangle"
														className="mr-2 text-orangeLight"
														aria-hidden
													/>
												</span>

												<span className="block">{message}</span>
											</div>
										)}
									</div>
								)}
								{!addToCartBtnDisabled && (
									<div className="flex justify-center">
										<Button
											type="button"
											buttonColor="blue"
											onClick={handleAddToCart}
										>
											<span aria-hidden={true}>
												{isMobile
													? t('shared/articlerow/add')
													: t('shared/articlerow/addtocart')}
											</span>

											<span className="sr-only">
												{`${t(
													'shared/articlerow/add'
												)} ${itemName}-${itemCode}`}
											</span>
											<Icon icon="cart" size={1.25} aria-hidden="true" />
										</Button>
									</div>
								)}
							</>
						</div>
					)}
					<p className="text-h4 md:text-h3 font-medium text-right md:text-center text-blue inline-block lg:hidden">
						{prices?.amountNetPriceFormatted
							? `${prices.amountNetPriceFormatted} ${prices?.currency || ''}`
							: t('shared/articlerow/priceondemand')}
					</p>{' '}
				</div>
				<div className="text-center lg:mt-8 lg:mx-6 min-w-55">
					{cartLoading && !withPrice ? (
						<div className="w-55">
							<Icon animate="spin" size={2} aria-hidden={true} icon="loader" />
						</div>
					) : (
						<>
							<p className="text-h4 md:text-h3 text-right md:text-center font-medium text-blue hidden lg:block">
								{prices?.amountNetPriceFormatted
									? `${prices.amountNetPriceFormatted} ${
											prices?.currency || ''
									  }`
									: t('shared/articlerow/priceondemand')}
							</p>
							{(prices?.netPriceFormatted || prices?.grossPriceFormatted) && (
								<div className="text-center block lg:flex mt-4">
									<div className="flex flex-col items-stretch lg:items-start">
										{prices?.netPriceFormatted && (
											<p className="text-p lowercase flex mb-1 justify-between lg:justify-start">
												<span className="text-left w-25 mr-2">
													{t('shared/articlerow/netprice')}
												</span>
												<span className="whitespace-nowrap">
													{`${prices?.netPriceFormatted} ${prices.currency} / ${prices.unit}`}
												</span>
											</p>
										)}
										{prices?.grossPriceFormatted && (
											<p className="text-p lowercase flex mb-1 justify-between lg:justify-start">
												<span className="text-left w-25 mr-2">
													{t('shared/articlerow/grossprice')}
												</span>
												<span className="whitespace-nowrap">
													{`${prices?.grossPriceFormatted} ${prices.currency} / ${prices.unit}`}
												</span>
											</p>
										)}
										{prices?.discountFormatted && (
											<p className="text-p lowercase flex mb-1 justify-between lg:justify-start">
												<span className="text-left w-25 mr-2">
													{t('shared/articlerow/discount')}
												</span>
												<span className="whitespace-nowrap">{`- ${
													prices.discountFormatted
												} ${t('shared/articlerow/discountunit')}`}</span>
											</p>
										)}
									</div>
								</div>
							)}
						</>
					)}
				</div>
				{isRemovable && handleRemoveArticle && (
					<button
						className="absolute top-0 right-0 mt-3 mr-3"
						title={t('shared/articlerow/deletearticle')}
						aria-label={t('shared/articlerow/deletearticle')}
						onClick={() => handleRemoveArticle(itemCode, lineItemId)}
					>
						<Icon icon="close" size={2} color="#949494" className="m-2" />
					</button>
				)}
			</article>

			{noteModalActive && (
				<AddNotesModal
					onToggle={() => setNoteModalActive(false)}
					commentHidden={commentHidden!}
					handleAddNotes={handleAddNotes}
					goodsMarking={notes.goodsMarking}
					comment={notes.comments}
				/>
			)}
		</>
	);
};

export default CartArticleRow;
