import styled, { css } from 'styled-components';
import { blue } from 'theme/colors';
import { spacing1x, spacing2x } from 'theme/spacings';
import { FiGlobe, FiChevronDown, FiChevronUp } from 'react-icons/fi';

export const GlobeIcon = styled(FiGlobe)`
	color: ${blue};
	margin-right: ${spacing1x};
	position: absolute;
	display: inline-block;
	width: 1.8rem;
	top: 50%;
	transform: translateY(-50%);
	@media (max-width: 1150px) {
		top: 0.4rem;
		left: 1rem;
		position: relative;
		transform: none;
	}
`;

const arrowStyling = css`
	color: ${blue};
	position: relative;
	top: ${spacing2x};
	margin-left: ${spacing1x};
	display: inline-block;
	transform: translateY(-50%);
	width: 1.8rem;
`;

export const ArrowUp = styled(FiChevronUp)`
	${arrowStyling};
`;
export const ArrowDown = styled(FiChevronDown)`
	${arrowStyling};
`;
