/**
 * EpiForms
 */

import { useReducer, useRef, useEffect } from 'react';
import { useFormRenderer, usePrevious, useForceUpdate } from 'hooks';
import { getInitialFormValues, getStepFields } from './EpiForms.helpers';
import { submitForm } from './EpiForms.submit';
import { Form } from 'components/ui/FormEpi';
import FormStep from './FormStep';
import FormMessage from './FormMessage';
import { H2 } from '../Typography';
import { EpiFormsBlockModel } from 'types/block-types';
import { EpiserverFormsPageModel } from 'types/page-types';

const initialState: EpiserverFormsPageModel = {
	isLoading: false,
	invalidFields: [],
	validationMessages: {},
	successMessage: '',
	currentStep: 0,
	values: {},
	submissionId: '',
	serverValidationError: false,
	imageHash: Date.now(),
};

const reducer = (state: EpiserverFormsPageModel, action: any) => {
	switch (action.type) {
		case 'UPDATE_STATE':
			return { ...state, ...action.payload };
		default:
			return state;
	}
};

/** Rendering of an EpiForms block. */
const EpiForms = ({
	fields,
	method = 'GET',
	action,
	encodingType = 'multipart/form-data',
	steps,
	dependencies,
	id,
	title,
	description,
	confirmationMessage,
	redirectUrl,
	_properties = {},
	htmlAttributes,
	loadFailureText,
	translations,
}: EpiFormsBlockModel) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const { currentStep, successMessage, submissionId, invalidFields } = state;

	const forceUpdate = useForceUpdate();
	const [renderFormElement, fieldActionExists] = useFormRenderer(
		fields,
		state,
		dependencies,
		translations
	);

	const showForm = !confirmationMessage && !successMessage;
	const formValues = getInitialFormValues(fields, currentStep);

	const messageHolderRef = useRef<HTMLDivElement>(null);
	const prevInvalidFields = usePrevious(invalidFields);
	let prevStep = usePrevious(currentStep) as any;

	if (prevStep === undefined) {
		prevStep = 0;
	}

	// Focus messageHolderRef if success or error
	useEffect(() => {
		const successfullySubmitted =
			invalidFields.length > 0 || confirmationMessage || successMessage;
		const formMessageElement = messageHolderRef && messageHolderRef.current;

		if (
			successfullySubmitted &&
			formMessageElement &&
			prevInvalidFields !== invalidFields
		) {
			messageHolderRef?.current?.focus();
		}
		//eslint-disable-next-line
	}, [invalidFields, messageHolderRef]);

	// Jump back to top if step changes
	useEffect(() => {
		if (window && prevStep !== currentStep && steps.length > 2) {
			window.location.href = `#${id}`;
		}
		//eslint-disable-next-line
	}, [currentStep]);

	// If we have dependencies (conditional fields), we re-render.
	const onChange = (field: any, values: any) => {
		if ((dependencies && dependencies.length > 0) || fieldActionExists) {
			dispatch({
				type: 'UPDATE_STATE',
				payload: { values },
			});

			if (dependencies && dependencies.length > 0) {
				forceUpdate();
			}
		}
	};

	const onSubmit = (
		values: any[],
		stepIndex: number,
		currentValidationMessages: any[],
		currentInvalidFields: string[]
	) => {
		if (currentInvalidFields && currentInvalidFields.length > 0) {
			dispatch({
				type: 'UPDATE_STATE',
				payload: {
					invalidFields: currentInvalidFields,
					validationMessages: currentValidationMessages,
				},
			});
			return;
		}

		submitForm(values, stepIndex, dispatch, steps, {
			currentStep,
			submissionId,
			action,
			method,
			redirectUrl,
		});
	};

	return (
		<div className="max-w-full md:max-w-2/3">
			<Form
				initialValues={formValues}
				sendDataType="formdata"
				method={method}
				action={action}
				enctype={encodingType}
				onSubmit={onSubmit}
				onChange={onChange}
				multiStep={steps.length > 2}
				currentStep={currentStep}
				getStepFields={getStepFields}
				id={id}
				{...htmlAttributes}
			>
				<header>
					{title && <H2 {..._properties.title}>{title}</H2>}

					{description && (
						<div className="text-sm">
							<p className="text-sm" {..._properties.description}>
								{description}
							</p>
						</div>
					)}

					{loadFailureText && <div className="mb-7">{loadFailureText}</div>}
					<FormMessage
						ref={messageHolderRef}
						//@ts-ignore
						confirmationMessage={confirmationMessage}
						state={state}
						translations={translations}
					/>
				</header>

				{showForm && (
					<div {..._properties.fields}>
						{steps.map((step, i) =>
							step.index === -1 ? (
								<div id={`form-${id}-hiddenfields`} key="-1" hidden>
									{step.fields.map(renderFormElement)}
								</div>
							) : steps.length === 2 ? (
								step.fields.map(renderFormElement)
							) : (
								<FormStep
									key={i}
									step={step}
									nrOfSteps={steps.length}
									formId={id}
									currentStep={currentStep}
									onPrevious={(state: EpiserverFormsPageModel) => {
										dispatch({
											type: 'UPDATE_STATE',
											payload: state,
										});
									}}
									translations={translations}
								>
									{step.fields.map(renderFormElement)}
								</FormStep>
							)
						)}
					</div>
				)}
			</Form>
		</div>
	);
};

export default EpiForms;
