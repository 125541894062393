/**
 * MyPage
 * @module pages/MyPage
 */

import Layout from 'components/ui/Layout';
import ContentArea from 'components/framework/ContentArea';
import Breadcrumbs from 'components/framework/Breadcrumbs';
import SectionHeader from 'components/ui/SectionHeader';
import {
	CategoryList,
	CategoryListItem,
} from 'components/framework/CategoryList';
import { Cell, Grid } from 'components/ui/Grid';

const MyPage = ({
	heading,
	breadcrumbs,
	text,
	isInEditMode,
	columnList,
	contentArea,
}) => {
	return (
		<main id="main-content">
			<Layout backgroundColor="white">
				<Breadcrumbs links={breadcrumbs} />
				<SectionHeader
					heading={heading}
					text={text}
					size="large"
					isInEditMode={isInEditMode}
				/>
				<Grid padding={false} className="my-4 md:mt-12 md:mb-25">
					<Cell span={12} desktop={8}>
						<CategoryList className="md:flex justify-between">
							{columnList?.map((column, index) => (
								<div key={index} className="mb-5">
									<h2 className="text-blue text-h4 font-normal mb-2">
										{column.heading}
									</h2>
									{column.links?.map((item, subIndex) => (
										<CategoryListItem
											key={subIndex}
											url={item.link}
											text={item.text}
										/>
									))}
								</div>
							))}
						</CategoryList>
					</Cell>
				</Grid>
			</Layout>
			{contentArea && <ContentArea contentArea={contentArea} />}
		</main>
	);
};

export default MyPage;
