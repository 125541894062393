/**
 * CalendarItem
 * @module components/CalendarItem
 */
import PropTypes from 'prop-types';
import {
	Link,
	Text,
	CalendarItemStyle,
	DateStyle,
	DateHeader,
	DateContent,
	CalendarEventStyle,
} from './PressCalendariumBlock.styles';

const CalendarItem = ({ day, month, date, name, link }) => (
	<CalendarItemStyle>
		<DateStyle>
			<DateHeader>{month}</DateHeader>
			<DateContent>{day}</DateContent>
		</DateStyle>
		<CalendarEventStyle>
			<Text>{date}</Text>
			<Link href={link}>{name}</Link>
		</CalendarEventStyle>
	</CalendarItemStyle>
);

CalendarItem.propTypes = {
	day: PropTypes.string,
	month: PropTypes.string,
	date: PropTypes.string,
	name: PropTypes.string,
};

export default CalendarItem;
