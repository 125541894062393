import { useEffect, useRef } from 'react';
import { checkFieldActions } from 'components/ui/EpiForms/EpiForms.actions';
import {
	Field,
	TextArea,
	Select,
	Multiselect,
	RadioButton,
	Checkbox,
	Fieldset,
	Button,
	Label,
	ErrorSpan,
} from 'components/ui/FormEpi';
import { checkDependency } from 'components/ui/EpiForms/EpiForms.dependencies';
import Icon from 'components/ui/Icon';
import { FieldWrapper, CheckboxLabel } from 'components/ui/FormEpi/Form.styles';
import RichText from 'components/ui/RichText';

export const useFormRenderer = (fields, state, dependencies, translations) => {
	const { invalidFields, validationMessages, isLoading, values } = state;
	let fieldActionExists = false;

	const renderFormElement = (fieldName) => {
		const field = fields[fieldName];
		const { name, paragraphText } = fields[fieldName];
		let { type } = fields[fieldName];

		if (type === null) {
			type = 'text';
		}

		const renderAsFieldset =
			(type === 'radio' || type === 'checkbox') && field.options.length > 1;
		const singleCheckboxOrRadio =
			(type === 'radio' || type === 'checkbox') && field.options.length === 1;

		const fieldHasDependency =
			dependencies &&
			dependencies.filter((field) => field.fieldName === name).length > 0;

		if (fieldHasDependency) {
			const fieldShouldBeRendered = checkDependency(name, dependencies, values);

			if (!fieldShouldBeRendered) {
				return;
			}
		}

		if (renderAsFieldset) {
			return <FieldWrapper key={name}>{renderFieldset(field)}</FieldWrapper>;
		} else if (singleCheckboxOrRadio) {
			return (
				<FieldWrapper key={name}>
					{renderSingleInput(field)}
					{renderFieldError(field.name)}
				</FieldWrapper>
			);
		} else if (type === 'paragraphtext') {
			return <RichText key={name} text={paragraphText} />;
		} else {
			return (
				<FieldWrapper
					key={name}
					id={`form${name}`}
					hidden={type === 'visitordatahidden'}
				>
					{field.type !== 'multiselect' && renderLabel(field)}
					{renderField(field)}
					{renderFieldError(field.name)}
				</FieldWrapper>
			);
		}
	};

	const renderLabel = ({ id, label, required, type }) => {
		if (!label || type === 'submit' || type === 'reset') {
			return null;
		}

		return (
			<Label htmlFor={id} required={required}>
				{label}
			</Label>
		);
	};

	const renderFieldError = (id) => {
		const validationMessage =
			validationMessages[id] && validationMessages[id].message;
		const isInvalid = invalidFields.indexOf(id) !== -1;

		return (
			<ErrorSpan fieldId={id} invalid={isInvalid}>
				{validationMessage}
			</ErrorSpan>
		);
	};

	// RECAPTCHA RELATED METHODS

	const loadRecaptcha = (siteKey, inputId) => {
		if (typeof document !== 'undefined' && typeof window !== 'undefined') {
			let existingScripts = document.head.getElementsByTagName('script');
			let scriptExists = false;
			for (var i = 0; i < existingScripts.length; i++) {
				if (
					existingScripts[i].src ===
					'https://www.google.com/recaptcha/api.js?render=' + siteKey
				) {
					scriptExists = true;
					break;
				}
			}
			if (!scriptExists) {
				var script = document.createElement('script');

				script.src =
					'https://www.google.com/recaptcha/api.js?render=' + siteKey;

				document.head.appendChild(script);
			}

			document.addEventListener('click', (e) => {
				window.grecaptcha &&
					window.grecaptcha.ready((_) => {
						window.grecaptcha
							.execute(siteKey, { action: 'submit' })
							.then((token) => {
								let gId = document.getElementById(inputId);
								gId && gId.setAttribute('value', token);
							})
							.catch((error) => console.log(error));
					});
			});
		}
	};

	const ref = useRef();

	// useEffect(() => {
	// 	ref.current && loadRecaptcha(ref.current.dataset.sk, ref.current.id);
	// }, []);

	const renderField = ({
		name,
		type,
		required,
		pattern,
		placeholder,
		disabled,
		description,
		min,
		max,
		label,
		failedMessage,
		patternMessage,
		options,
		fieldActions,
		captchaImageHandler,
		imageWidth,
		imageHeight,
		allowMultiple,
		siteKey,
	}) => {
		let buttonText = label || type;

		switch (type) {
			case 'submit':
			case 'reset':
				if (type === 'submit' && isLoading) {
					buttonText = 'Loading...';
				}

				return (
					<Button type={type} name={name} disabled={isLoading} id={name}>
						{buttonText}
					</Button>
				);
			case 'textarea':
				return (
					<TextArea
						id={name}
						name={name}
						description={description}
						required={required}
						placeholder={placeholder}
						disabled={disabled}
						label={label}
						validationMessage={failedMessage}
						translations={translations}
					/>
				);
			case 'select':
				return (
					<Select
						id={name}
						name={name}
						label={label}
						description={description}
						required={required}
						options={options}
						validationMessage={failedMessage}
					/>
				);
			case 'multiselect':
				return (
					<Multiselect
						id={name}
						label={label}
						description={description}
						required={required}
						options={options}
						validationMessage={failedMessage}
						isInvalid={invalidFields.indexOf(name) !== -1}
					/>
				);
			case 'captcha':
				return (
					<>
						<img
							alt="captcha"
							src={`${captchaImageHandler}&hash=${Date.now()}`}
							className="inline-block"
							style={{ height: imageHeight, width: imageWidth }}
						/>
						<button
							name="RefreshCaptchaSubmit"
							className="inline-block"
							onClick={(e) => {
								e.preventDefault();
								window.location.reload();
							}}
							data-f-captcha-image-handler={
								captchaImageHandler.split('elementGuid=')[1] &&
								captchaImageHandler.split('elementGuid=')[1]
							}
							value="RefreshCaptcha"
							data-f-captcha-refresh=""
						>
							<Icon icon="redo" size={2} className="ml-4" />
						</button>
						<Field
							type={type}
							id={name}
							name={name}
							pattern={pattern}
							required={(actions && actions.required) || required}
							placeholder={placeholder}
							description={description}
							label={label}
							min={min}
							max={max}
							disabled={disabled}
							validationMessage={failedMessage}
							patternMessage={patternMessage}
						/>
					</>
				);
			case 'googlecaptcha':
				return (
					<input
						ref={ref}
						id={name}
						type="hidden"
						name={name}
						data-sk={siteKey}
					/>
				);
			case 'text':
			default:
				let actions = {};
				if (fieldActions) {
					actions = checkFieldActions(fieldActions, values);
					fieldActionExists = true;
				}
				return (
					<Field
						type={type}
						id={name}
						name={name}
						pattern={pattern}
						required={(actions && actions.required) || required}
						placeholder={placeholder}
						description={description}
						label={label}
						min={min}
						max={max}
						disabled={disabled}
						validationMessage={failedMessage}
						patternMessage={patternMessage}
						multiple={allowMultiple}
						translations={translations}
					/>
				);
		}
	};

	const renderFieldset = ({
		name,
		type,
		options,
		label,
		required,
		description,
		validationMessage,
	}) => {
		const isInvalid = invalidFields.indexOf(name) !== -1;
		const FieldComponent = type === 'radio' ? RadioButton : Checkbox;

		return (
			<Fieldset
				id={name}
				description={description}
				required={required}
				label={label}
				isInvalid={isInvalid}
				validationMessage={validationMessage}
				translations={translations}
			>
				{options.map((opt, index) => {
					return (
						<label htmlFor={`${name}-${index}`} key={`${name}-${index}`}>
							<FieldComponent
								id={name}
								index={index}
								name={name}
								value={opt.value}
								label={opt.caption}
								translations={translations}
							/>
							<span>{opt.caption}</span>
						</label>
					);
				})}
			</Fieldset>
		);
	};

	const renderSingleInput = ({
		name,
		type,
		options,
		required,
		disabled,
		description,
		validationMessage,
	}) => {
		const fieldLabel = options[0].caption;
		const fieldValue = options[0].value;

		const FieldComponent = type === 'radio' ? RadioButton : Checkbox;

		return (
			<CheckboxLabel htmlFor={name} title={description}>
				<FieldComponent
					id={name}
					name={name}
					value={fieldValue}
					required={required}
					disabled={disabled}
					single={true}
					label={fieldLabel}
					validationMessage={validationMessage}
					translations={translations}
				/>
				<span>
					{fieldLabel}
					{required && <abbr title="Obligatoriskt">*</abbr>}
				</span>
			</CheckboxLabel>
		);
	};

	return [renderFormElement, fieldActionExists];
};
