import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { LocalizationContext } from 'context/localization.context';

import {
	ContactRow,
	ContactRowsWrapper,
	ContactInfo,
	LeftContent,
	ContactWrapper,
	ToggleRow,
	ToggleText,
	ToggleButton,
	ContactImage,
	RightContent,
	Text,
	TextWrapper,
	ToggleIcon,
	UsersIcon,
	IconWrapper,
} from './Contacts.styles';
import { PhoneIcon, MailIcon } from '../LocationRow.styles';

const renderImage = image => {
	return (
		<picture>
			<source
				type="image/webp"
				srcSet={`${image.webpSizes[0]} 800w,${image.webpSizes[1]} 400w`}
				sizes="(min-width: 768px) 5vw, (max-width: 767px) 5vw, 800px"
			/>
			<ContactImage
				src={image.src}
				srcSet={`${image.sizes[0]} 800w,${image.sizes[1]} 400w`}
				sizes="(min-width: 768px) 5vw, (max-width: 767px) 5vw, 800px"
				alt={image.alt}
			/>
		</picture>
	);
};

const contactOpenDefault = (contacts, query) => {
	let result = false;
	if (!contacts || contacts.length === 0 || query === '') return result;
	for (let i = 0; i < contacts.length; i++) {
		if (contacts[i].name) {
			const lowerCaseName = contacts[i].name.toLowerCase();
			if (lowerCaseName !== '' && lowerCaseName.includes(query.toLowerCase())) {
				result = true;
				break;
			}
		}
	}
	return result;
};

const Contacts = ({ contacts, query }) => {
	const { t } = useContext(LocalizationContext);
	const [contactDropDownOpen, setContactDropdownOpen] = useState(
		contactOpenDefault(contacts, query)
	);

	const toggleDropDown = e => {
		e.preventDefault();
		setContactDropdownOpen(!contactDropDownOpen);
	};
	return (
		<ContactWrapper padTop={contactDropDownOpen}>
			<ContactRowsWrapper
				as="ul"
				isOpen={contactDropDownOpen}
				aria-hidden={!contactDropDownOpen}
			>
				{contacts.map((contact, i) => {
					const { name, role, productArea, image, phone, email } = contact;
					return (
						<ContactRow key={i} as="li">
							<LeftContent>{image && renderImage(image)}</LeftContent>
							<RightContent first={i === 0}>
								<TextWrapper>
									{name && (
										<Text bold upper>
											{name}
										</Text>
									)}
									{role && <Text>{role}</Text>}
									{productArea && <Text lower>{productArea}</Text>}
								</TextWrapper>
								<ContactInfo>
									{phone && (
										<Text>
											<PhoneIcon aria-hidden />
											<a href={`tel:${phone}`}>{phone}</a>
										</Text>
									)}
									{email && (
										<Text>
											<MailIcon aria-hidden />
											<a href={`mailto:${email}`}>{email}</a>
										</Text>
									)}
								</ContactInfo>
							</RightContent>
						</ContactRow>
					);
				})}
			</ContactRowsWrapper>
			<ToggleRow isOpen={contactDropDownOpen}>
				<ToggleButton onClick={toggleDropDown}>
					<IconWrapper aria-hidden>
						<UsersIcon aria-hidden />
					</IconWrapper>
					<ToggleText>
						{contactDropDownOpen
							? t('contactpage/hidesellersandexperts')
							: t('contactpage/showsellersandexperts')}
					</ToggleText>
					<ToggleIcon isOpen={contactDropDownOpen} />
				</ToggleButton>
			</ToggleRow>
		</ContactWrapper>
	);
};

export default Contacts;

Contacts.propTypes = {
	name: PropTypes.string,
	role: PropTypes.string,
	email: PropTypes.string,
	phone: PropTypes.string,
	image: PropTypes.shape({
		src: PropTypes.string,
		alt: PropTypes.string,
		sizes: [PropTypes.string, PropTypes.string],
		webpSizes: [PropTypes.string, PropTypes.string],
	}),
	productAreaList: PropTypes.string,
};
