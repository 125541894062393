/**
 * PressReleaseItem
 * @module components/PressReleaseItem
 */

import PropTypes from 'prop-types';
import { Link, Text, ReleaseItemStyle } from './PressCalendariumBlock.styles';

const PressReleaseItem = ({ date, name, link }) => (
	<ReleaseItemStyle>
		<Text>{date}</Text>
		<Link href={link}>{name}</Link>
	</ReleaseItemStyle>
);

PressReleaseItem.propTypes = {
	date: PropTypes.string,
	name: PropTypes.string,
};

export default PressReleaseItem;
