/**
 * ImageBlock
 * @module components/ImageBlock
 */

import LazyLoad from 'react-lazyload';
import { Hightlight, ImageModel } from 'types/common';

import {
	BlockLink,
	ImageWrapper,
	Heading,
	Description,
	Text,
	Label,
	Image,
	PublishedDate,
} from './ImageBlock.styles';

interface ImageBlockProp {
	link: string;
	heading: string;
	shortDescription?: string;
	preamble: string;
	publishedDate?: string;
	headingSize?: string;
	image?: ImageModel;
	highlight?: Hightlight;
}

const ImageBlock = (block: ImageBlockProp) => {
	const {
		link,
		image,
		highlight,
		publishedDate,
		heading,
		shortDescription,
		preamble,
		headingSize,
	} = block;
	return (
		<article>
			<BlockLink href={link}>
				<LazyLoad>
					<ImageWrapper>
						{image ? (
							<>
								<picture>
									{image.webpSizes && (
										<source
											type="image/webp"
											srcSet={`${image.webpSizes[0]} 800w, ${image.webpSizes[1]} 400w`}
											sizes="(max-width: 767px) 70vw, (min-width: 768px) 5vw, 400px"
										/>
									)}
									{image.sizes && (
										<Image
											src={image.src}
											srcSet={`${image.sizes[0]} 800w, ${image.sizes[1]} 400w`}
											sizes="(max-width: 767px) 70vw, (min-width: 768px) 5vw, 400px"
											alt={image.alt ? image.alt : ''}
										/>
									)}
								</picture>
								{highlight && (
									<Label color={highlight.color}>{highlight.text}</Label>
								)}
							</>
						) : (
							highlight && (
								<Label color={highlight.color}>{highlight.text}</Label>
							)
						)}
					</ImageWrapper>
				</LazyLoad>
				<div>
					{publishedDate && <PublishedDate>{publishedDate}</PublishedDate>}
					{heading && (
						<Heading as="h2" size={headingSize}>
							{heading}
							{shortDescription && (
								<>
									<Description>{` - ${shortDescription}`}</Description>
								</>
							)}
						</Heading>
					)}
					{preamble && <Text>{preamble}</Text>}
				</div>
			</BlockLink>
		</article>
	);
};

export default ImageBlock;
