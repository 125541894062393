import styled from 'styled-components';
import { Table, TableData } from 'components/ui/Table/table.styles';
import Pager from 'components/ui/Pager';
import { paragraphCss } from 'components/ui/Typography';
import { spacing3x } from 'theme/spacings';
import { deviceSmallMax } from 'theme/mediaQueries';

export const SavedCartsTable = styled(Table)`
	overflow-y: auto;
`;

export const SavedCartsCell = styled(TableData)`
	text-align: ${(props) => (props.alignRight ? 'right' : 'left')};
	white-space: nowrap;
	padding: 0;
`;

export const PagerWrapper = styled(Pager)`
	margin: 2rem 0;
`;

export const NoResultsMessage = styled.p`
	${paragraphCss};
	text-align: center;
`;

export const Body = styled.div`
	max-width: 100%;
	padding-bottom: ${spacing3x};
	overflow-y: auto;

	@media (max-width: ${deviceSmallMax}) {
		max-width: 100%;
	}
`;

export const DeleteCartButton = styled.button`
	width: 6rem;
	border: 0;
	background-color: transparent;
	white-space: nowrap;
	text-decoration: none;
	cursor: pointer;
`;
export const DeleteLinkWrapper = styled.td`
	width: 6rem;
	text-align: right;
`;
