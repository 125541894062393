/**
 * ProductDetailPage
 * @module pages/ProductDetailPage
 */

import React, {
	useState,
	useEffect,
	useContext,
	useRef,
	useReducer,
} from 'react';

import { LocalizationContext } from 'context/localization.context';
import { renderElementBasedOnEditMode, scrollToTop } from 'common/helpers';
import { useFilterSearch } from 'api';
import { useMedia } from 'hooks';
import { composeQueryString, setUrlLocation } from 'common/helpers';
import Breadcrumbs from 'components/framework/Breadcrumbs';
import MediaGallery from 'components/framework/MediaGallery';
import ArticleRow from 'components/framework/ArticleRow';
import Layout from 'components/ui/Layout';
import FilterBar from 'components/ui/FilterBar';
import FilterChip from 'components/ui/FilterChip';
import { Paragraph } from 'components/ui/Typography';
import ArticleDetail from 'components/common/ArticleDetail';
import ArticleDetailConfiguration from 'components/common/ArticleDetailConfiguration';
import PromoBlock from 'components/common/PromoBlock';
import listFilterReducer from 'reducers/listFilterReducer';

import {
	Wrapper,
	Heading,
	TopContent,
	LeftContent,
	RightContent,
	ArticleWrapper,
	FilterCol,
	SlidersButton,
	SlidersIcon,
	ArticleCol,
	InputField,
	PagerWrapper,
	ClearFilterButton,
	LinkButton,
	LinkButtonWrapper,
} from './ProductDetailPage.styles';
import CustomExternalScript from 'components/framework/CustomExternalScript';
import { usePopState } from 'hooks/usePopState';
import ContentArea from 'components/framework/ContentArea';
import { FiChevronsRight } from 'react-icons/fi';
import {
	FacetItemModel,
	FacetsItemModel,
	FilterStateAction,
} from 'types/filter-types';
import clsx from 'clsx';
import SpecificationsList from 'components/framework/SpecificationsList/SpecificationsList';
import ExpansionPanel from 'components/ui/ExpansionPanel/ExpansionPanel';
import LinkList from 'components/framework/LinkList/LinkList';
import FilterFieldSet from 'components/framework/FilterFieldSet';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserObject } from 'store/modules/user';
import { usePriceStockDetail } from 'api/general';
import { selectCartObject } from 'store/modules/cart';
import Icon from 'components/ui/Icon';
import RichText from 'components/ui/RichText';
import { confReset } from 'store/modules/configurator';
import { ProductDetailPageModel } from 'types/page-types';
import { ArticleRowItemModel } from 'types/article';

const ProductDetailPage = ({
	name,
	breadcrumbs,
	contentArea,
	shortDescription,
	longDescription,
	rangeSpecifications,
	documents,
	mediaGallery,
	pagination,
	filter,
	articleList,
	priceInfo,
	framework,
	isInEditMode,
	confArticle,
	links,
	articleModal,
}: ProductDetailPageModel) => {
	const { t }: any = useContext(LocalizationContext);
	const confDispatch = useDispatch();
	const { cartDisabled } = useSelector(selectCartObject);

	const { customers, projects, warehouses } = useSelector(selectUserObject);
	const [showMore, setShowMore] = useState(false);
	const toggleExpansionPanel = () => {
		setShowMore(!showMore);
	};
	const [articleDetailModal, setArticleModal] = useState({
		isActive: false,
		link: null as any,
	});

	const [confModalOpen, setConfModalOpen] = useState(false);
	const toggleConfModal = () => {
		setConfModalOpen(!confModalOpen);
		confDispatch(confReset());
	};

	const [showFilter, setShowFilter] = useState(false);

	const toggleMobileFilter = () => {
		setShowFilter(!showFilter);
	};

	const [query, setQuery] = useState<string | null>(null);
	const [response, error, loading] = useFilterSearch(
		query,
		window && window.location ? window.location.pathname : null
	);

	//const [priceInformation, setPriceInformation] = useState([]);
	//const [priceLoading, setPriceLoading] = useState(false);

	const isMobile = useMedia('(max-width: 490px)');
	const mobileView = useMedia('(max-width: 767px)');
	const tabletView = useMedia('(max-width: 890px)');

	// Handle filter, query and pagination.
	const [state, dispatch] = useReducer(listFilterReducer, {
		sortBy: filter.sortBy,
		display: pagination.display,
		query: filter.query,
		currentPage: pagination.currentPage,
		facets: filter.facets,
		article: filter.article,
	});

	let didRender = useRef(false);
	const preventHistoryEntry = useRef(false);

	const popState = usePopState(state);
	const popStateString = JSON.stringify(popState);
	useEffect(() => {
		if (popState) {
			dispatch({ type: 'SET_STATE', payload: popState });
			preventHistoryEntry.current = true;
		}
	}, [popState, popStateString]);

	useEffect(() => {
		const hasRendered = didRender.current;
		const queryString = composeQueryString(
			state.query,
			state.sortBy,
			state.display,
			state.currentPage,
			state.facets,
			null,
			null,
			state.article
		);
		// Only set the query to fetch new data if it's not the first render.
		didRender.current
			? setQuery(queryString ? queryString : null)
			: (didRender.current = !didRender.current);

		if (queryString !== query) {
			setUrlLocation(
				queryString,
				state.query,
				state.sortBy,
				state.display,
				state.currentPage,
				state.facets,
				null,
				hasRendered,
				preventHistoryEntry.current,
				state.article
			);
		}
	}, [query, state]);

	useEffect(() => {
		articleModal && setArticleModal({ isActive: true, link: articleModal });
	}, []);

	const removeArticleParam = () => {
		dispatch({ type: 'ARTICLE', article: '' } as FilterStateAction);
	};

	const handleFilterRequest = (
		id?: string | number,
		display?: string | number,
		article?: string | number
	) => {
		dispatch({ type: 'SORT_BY', sortBy: id } as FilterStateAction);
		dispatch({ type: 'DISPLAY', display: display } as FilterStateAction);
		dispatch({ type: 'ARTICLE', article: article } as FilterStateAction);
	};

	const handleFacet = (facetId: string, itemId: string, value: any) => {
		const facet = state.facets?.find((f: FacetsItemModel) => f.id === facetId);
		const item = facet?.items.find((i: FacetItemModel) => i.id === itemId);
		item && (item.active = value);

		dispatch({ type: 'FACETS', facets: state.facets ?? [] });
	};

	const clearFacets = () => {
		state.facets?.forEach((facet: FacetsItemModel) =>
			facet.items.forEach((item) => (item.active = false))
		);
		dispatch({ type: 'FACETS', facets: state.facets ?? [] });
	};

	const handleSearch = (value: string) => {
		if (state.query !== value) {
			dispatch({ type: 'QUERY', query: value } as FilterStateAction);
		}
	};

	const handlePagination = (value: number) => {
		scrollToTop(tabletView ? 0 : 800);
		dispatch({ type: 'CURRENT_PAGE', currentPage: value } as FilterStateAction);
	};

	const paginationObject = response.pagination || pagination;

	const articlesArray = response.articleList || articleList;

	const anyActiveFacetFilter = state.facets?.some((facet: FacetsItemModel) =>
		facet.items.some((item) => item.active === true)
	);

	useEffect(() => {
		if (response && response.filter && response.filter.facets) {
			dispatch({ type: 'FACETS', facets: response.filter.facets });
		}
	}, [response]);

	const hasArticles = articlesArray && articlesArray.length > 0;
	const shouldShowListing =
		articleList && articleList.length >= 0 && articleList !== null;

	const [priceResponse, priceError, priceLoading] = usePriceStockDetail({
		articlesArray: response.articleList
			? [...response.articleList]
			: articleList,
		companyId: framework.header.activeMarket,
		customerId: customers?.filter((item: any) => item.selected === true)[0]?.id,
		projectId: projects?.filter((item: any) => item.selected === true)[0]?.id,
		warehouseId: warehouses?.filter((item: any) => item.selected === true)[0]
			?.id,
		url: framework.api.priceStockDetailsUrl,
		desiredDeliveryDate: '',
		preventRequest: cartDisabled,
	});

	const priceInformation =
		priceResponse && priceResponse.articleList ? priceResponse.articleList : [];

	return (
		<main id="main-content">
			<Layout backgroundColor="white">
				<Breadcrumbs links={breadcrumbs} />
				<Wrapper>
					<LeftContent>
						<TopContent>
							{(shortDescription || name) && (
								<Heading>
									{name &&
										renderElementBasedOnEditMode(
											isInEditMode,
											'name',
											'span',
											{ className: 'text-orange text-h3 block mt-4' },
											name
										)}
									{shortDescription &&
										renderElementBasedOnEditMode(
											isInEditMode,
											'shortDescription',
											'span',
											{ className: 'text-blue text-h2-large md:block mb-3' },
											shortDescription
										)}
								</Heading>
							)}

							{longDescription && (
								<RichText
									name="longDescription"
									text={longDescription}
									isInEditMode={isInEditMode}
								/>
							)}
							{links && links.length > 0 && (
								<LinkButtonWrapper>
									{links.map((item, index) => (
										//@ts-ignore
										<LinkButton
											type="link"
											buttonColor="orange"
											url={item.link}
											key={index}
											target={item.target}
										>
											<FiChevronsRight aria-hidden={true} />
											{item.text}
										</LinkButton>
									))}
								</LinkButtonWrapper>
							)}
							<CustomExternalScript />
						</TopContent>
						{mobileView && mediaGallery && mediaGallery.length > 0 && (
							<div
								className={clsx(
									'w-full md:w-65 p-0',
									showMore ? 'inherit' : 'none'
								)}
							>
								<MediaGallery mediaGallery={mediaGallery} />
							</div>
						)}

						{rangeSpecifications && (
							<SpecificationsList
								specifications={rangeSpecifications}
								heading={t('shared/specificationslist/rangespecifications')}
								className={clsx(showMore ? 'smMax:block' : 'smMax:hidden')}
							/>
						)}

						{documents && documents.length === 0 ? (
							false
						) : documents.length > 3 ? (
							<>
								<div
									className={clsx(
										'overflow-hidden smMax:h-inherit',
										showMore ? 'h-inherit' : 'h-42.5'
									)}
								>
									<LinkList
										className={clsx(showMore ? 'smMax:block' : 'smMax:hidden')}
										documents={documents}
										expanded={showMore}
									/>
								</div>
								<div
									className={clsx(
										'w-full bottom-0 absolute h-22.5 from-transparent to-white bg-gradient-to-b smMax:hidden',
										showMore ? 'hidden' : 'block'
									)}
								></div>
								<ExpansionPanel
									className="smMax:hidden"
									toggleExpansionPanel={toggleExpansionPanel}
									expanded={showMore}
								/>
							</>
						) : documents && documents.length < 4 ? (
							<LinkList
								className={clsx(showMore ? 'smMax:block' : 'smMax:hidden')}
								documents={documents}
								expanded={showMore}
							/>
						) : (
							false
						)}
					</LeftContent>
					<RightContent>
						{mediaGallery && mediaGallery.length > 0 && (
							<MediaGallery mediaGallery={mediaGallery} />
						)}
					</RightContent>
					<ExpansionPanel
						className={clsx('hidden smMax:inline')}
						toggleExpansionPanel={toggleExpansionPanel}
						expanded={showMore}
					/>
				</Wrapper>

				{shouldShowListing && (
					<FilterBar
						defaultDisplayOption={state.display}
						defaultSortByItem={state.sortBy || ''}
						onFilter={handleFilterRequest}
						displayOptions={[16, 32, 48]}
						sortByItems={filter?.sortProperties || []}
					/>
				)}

				{shouldShowListing && (
					<ArticleWrapper>
						<FilterCol>
							<InputField
								method="GET"
								action="/"
								id="filter"
								name="filter"
								value={state.query ? state.query : ''}
								onSearch={handleSearch}
							/>
							{tabletView && (
								<SlidersButton
									onClick={toggleMobileFilter}
									title={t('shared/filter/showfilter')}
								>
									<SlidersIcon />
								</SlidersButton>
							)}

							{state.facets && state.facets && (
								<form>
									{state.facets.map((fieldset: FacetsItemModel, i: number) => (
										<React.Fragment key={i}>
											{fieldset.name && fieldset.items.length > 0 && (
												<FilterFieldSet
													className={clsx(
														'mt-6 mb-6 smMax:mt-4 smMax:mb-4',
														showFilter
															? 'smMax:display-inherit'
															: 'smMax:hidden'
													)}
													name={fieldset.name}
													items={fieldset.items}
													facetId={fieldset.id}
													key={fieldset.name}
													onChange={handleFacet}
												/>
											)}
										</React.Fragment>
									))}
								</form>
							)}
						</FilterCol>
						<ArticleCol>
							{anyActiveFacetFilter && (
								<ul className="p-0 inline-block">
									{state.facets?.map((facet: FacetsItemModel, i: number) => {
										return facet.items.map((facetItem, j) => {
											return facetItem.active ? (
												<FilterChip
													name={facet.name}
													item={facetItem}
													facetId={facet.id}
													onDelete={handleFacet}
													key={`${facetItem.name}-${i}-${j}`}
												/>
											) : null;
										});
									})}
								</ul>
							)}
							{anyActiveFacetFilter && (
								<ClearFilterButton as="button" onClick={() => clearFacets()}>
									{t('shared/filter/clearallfilters')}
								</ClearFilterButton>
							)}
							{!loading && error && (
								<div className="my-6 mx-0 text-center w-full">
									<Paragraph>{t('shared/filter/errormessage')}</Paragraph>
								</div>
							)}
							{loading && (
								<div className="my-6 mx-0 text-center w-full">
									<Icon icon="loader" animate="spin" size={2} />
								</div>
							)}
							{hasArticles && !error && !loading
								? articlesArray.map((item: ArticleRowItemModel, i: number) => (
										<ArticleRow
											loginLink={framework.header.loginLink}
											item={item}
											activeMarket={framework.header.activeMarket}
											priceInfo={priceInformation?.find(
												(x: any) => x.itemCode === item.itemCode
											)}
											openModal={
												item.configurable ? toggleConfModal : setArticleModal
											}
											priceLoading={priceLoading}
											certificates={item?.certificates}
											key={`articleRow-${i}`}
										/>
								  ))
								: !error &&
								  !loading && (
										<div className="my-6 mx-0 text-center w-full">
											<Paragraph>
												{t('shared/filter/noresultmessage')}
											</Paragraph>
										</div>
								  )}

							<PagerWrapper
								nrOfPages={
									paginationObject.pageCount ? paginationObject.pageCount : 0
								}
								currentPage={
									paginationObject.currentPage
										? paginationObject.currentPage
										: 0
								}
								onPageChange={handlePagination}
								total={!error && !loading ? paginationObject.total : 0}
								isMobile={isMobile}
							/>
						</ArticleCol>
					</ArticleWrapper>
				)}
			</Layout>
			{confArticle && (
				<PromoBlock
					block={{
						...confArticle,
						toggleModal: toggleConfModal,
						confArticle: confArticle,
						imagePreference: 'imageLeftOutside',
						backgroundColor: 'greyLight',
					}}
				/>
			)}
			{contentArea && contentArea.length > 0 && (
				<ContentArea contentArea={contentArea} />
			)}

			{articleDetailModal.isActive && (
				<ArticleDetail
					onToggle={() => {
						removeArticleParam();
						setArticleModal({ link: null, isActive: false });
					}}
					articleApiUrl={articleDetailModal.link}
					activeMarket={framework.header.activeMarket}
				/>
			)}

			{confModalOpen && (
				<ArticleDetailConfiguration closeModal={toggleConfModal} />
			)}
		</main>
	);
};

export default ProductDetailPage;
