import styled from 'styled-components';
import { deviceSmallMax } from 'theme/mediaQueries';
import { spacing05x } from 'theme/spacings';
import { white, black } from 'theme/colors';
import { fontFamily, fontWeightBold, fontWeightSlim } from 'theme/typography';

export const Select = styled.select`
	display: none;
	@media (max-width: ${deviceSmallMax}) {
		display: inline-block;
		border: none;
		outline: none;
		appearance: none;
		color: transparent;
		background-color: transparent;
		font-family: ${fontFamily};
		font-weight: ${fontWeightBold};
		font-size: 1.4rem;
		line-height: 2rem;
		margin: ${spacing05x};
		cursor: pointer;
		width: 100%;
		position: absolute;
		left: 0;
		height: 100%;
	}
`;
export const Option = styled.option`
	color: ${black};
	background-color: transparent;
`;

export const Wrapper = styled.div`
	display: flex;
	position: relative;
`;

export const Link = styled.li`
	margin: ${spacing05x};
	a {
		font-size: 1.4rem;
		font-weight: ${fontWeightSlim};
		line-height: 2rem;
		color: ${black};
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
`;
export const SelectedValue = styled.p`
	color: ${white};
	background-color: transparent;
	font-weight: ${fontWeightBold};
	font-size: 1.4rem;
	line-height: 2.2rem;
	margin: ${spacing05x};
	display: flex;
	align-items: center;
	cursor: pointer;
	svg {
		height: 1.6rem;
		width: 1.6rem;
		line-height: 2rem;
	}
`;
