/**
 * IframeBlock
 */

import clsx from 'clsx';
import Layout from 'components/ui/Layout';
import React from 'react';

interface Props {
	/** Iframe title */
	title: string;

	/** Iframe url */
	url: string;

	/** Optional className */
	className?: string;

	/** Iframe Height */
	height?: string;

	/** Iframe Width */
	width?: string;
}

/** Main description for this component. */
const IframeBlock = ({
	title,
	url,
	className,
	height = '100%',
	width = '100%',
}: Props): JSX.Element => {
	return (
		<Layout className="pt-12">
			<iframe
				height={height}
				width={width}
				title={title}
				src={url}
				className={clsx('w-full', className)}
			/>
		</Layout>
	);
};

export default IframeBlock;
