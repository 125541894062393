import styled from 'styled-components';
import { Input } from 'components/ui/Form/Form.styles';
import { spacing1x, spacing3x, spacing5x, spacing6x } from 'theme/spacings';
import { blue, greyDarker } from 'theme/colors';
import { deviceSmallMax, deviceMediumMin } from 'theme/mediaQueries';

export const Form = styled.form`
	position: relative;
	width: 100%;
`;

export const Button = styled.button`
	background-color: transparent;
	border: none;
	position: absolute;
	left: 0;
	padding: ${spacing1x} 1rem;

	svg {
		display: block;
		font-size: 2.2rem;
		color: ${blue};
	}
	@media (max-width: 1150px) {
		svg {
			font-size: 1.6rem;
		}
	}

	@media (min-width: ${deviceMediumMin}) and (max-width: 1150px) {
		top: 0rem;
	}
`;

export const ClearButton = styled(Button)`
	right: 0;
	left: auto;
	line-height: 3.4rem;
	height: 100%;
	svg {
		display: block;
		font-size: 1.6rem;
		color: ${greyDarker};
	}
	@media (max-width: 1150px) {
		line-height: 2.4rem;
		right: 0;
	}
`;

export const VisuallyHidden = styled.span`
	border: 0;
	clip: rect(0 0 0 0);
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.1rem;
`;

export const InputField = styled(Input)`
	height: 4rem;
	padding: ${spacing1x} ${spacing3x} ${spacing1x} ${spacing5x};
	width: ${({ fieldSize }) => (fieldSize ? fieldSize : '31.2rem')};

	@media (max-width: ${deviceSmallMax}) {
		max-width: 100%;
		padding: ${spacing1x} ${spacing6x} ${spacing1x} ${spacing5x};
		width: 100%;
	}
`;
