import { Paragraph } from 'components/ui/Typography';
import styled from 'styled-components';
import { blue, grey, white } from 'theme/colors';
import {
	deviceSmallMax,
	deviceMediumMax,
	deviceMediumMin,
} from 'theme/mediaQueries';
import {
	spacing4x,
	spacing15x,
	spacing7x,
	spacing3x,
	spacing05x,
	spacing1x,
} from 'theme/spacings';
import { fontWeightSemiBold } from 'theme/typography';

export const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: ${spacing15x};

	@media (max-width: ${deviceSmallMax}) {
		display: block;
		margin: ${spacing3x} 0 ${spacing7x};
	}
`;

export const Table = styled(Paragraph)`
	border-collapse: collapse;
	width: 49%;
	margin-bottom: ${spacing3x};

	@media (min-width: ${deviceMediumMax}) and (max-width: 890px) {
		width: 66%;
	}

	@media (max-width: ${deviceMediumMin}) {
		width: 100%;
	}
`;

export const Caption = styled(Paragraph)`
	font-weight: ${fontWeightSemiBold};
	text-align: left;
	background-color: ${blue};
	color: ${white};
	padding: ${spacing05x} ${spacing4x} ${spacing05x} ${spacing1x};
	margin-bottom: 0;
`;

export const Row = styled.tr`
	:nth-of-type(odd) {
		background-color: ${grey};
	}
`;

export const Property = styled.td`
	float: left;
	clear: left;
	text-align: left;
	width: 20rem;
	padding: ${spacing05x} ${spacing4x} ${spacing05x} ${spacing1x};

	:after {
		content: ':';
	}
	@media (max-width: ${deviceSmallMax}) {
		width: 16rem;
	}
`;

export const Value = styled.td`
	margin: 0;
	width: 100%;
	padding: 0.4rem 3.2rem;
	text-align: inherit;

	@media (max-width: ${deviceSmallMax}) {
		text-align: inherit;
		padding: 0.4rem 0.8rem 0.4rem 0;
	}
`;
