/**
 * StandardLayout
 * @module layouts/StandardLayout
 */

import React from 'react';
import { useAnchorLinkNavigation } from 'hooks';

import PageHeader from 'components/framework/PageHeader';
import PageFooter from 'components/framework/PageFooter';

interface StandardLayoutProps {
	children?: any;

	header?: object;

	api?: object;

	footer?: object;

	modelType?: string;

	siteType?: string;
}

const StandardLayout: React.FC<StandardLayoutProps> = ({
	children,
	header,
	api,
	footer,
	modelType,
	siteType,
}) => {
	useAnchorLinkNavigation();
	return (
		<>
			<PageHeader
				header={header}
				api={api}
				includeHeading={modelType === 'StartPage'}
				siteType={siteType}
				modelType={modelType}
			/>
			{children}
			{footer && <PageFooter footer={footer} className={''} />}
		</>
	);
};

export default StandardLayout;
