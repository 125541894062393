import styled, { css } from 'styled-components';
import { deviceSmallMax, deviceMediumMin } from 'theme/mediaQueries';
import { spacing1x } from 'theme/spacings';
import { white, greyDark, blue, black } from 'theme/colors';
import { fontWeightBold } from 'theme/typography';
import Loader from 'components/ui/Loader';

export const QuickSearchWrapper = styled.div`
	position: relative;
	display: block;
	@media (max-width: ${deviceSmallMax}) {
		min-width: 100%;
		margin-top: ${spacing1x};
	}
`;

export const QuickSearchResultContainer = styled.ul`
	padding: ${spacing1x};
	position: absolute;
	border-left: 0.1rem solid ${greyDark};
	border-right: 0.1rem solid ${greyDark};
	border-bottom: 0.2rem solid ${blue};
	margin-top: 0;
	z-index: 900;
	background-color: ${white};

	list-style: none;
	visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};

	@media (max-width: ${deviceSmallMax}) {
		min-width: 100%;
	}

	@media (min-width: ${deviceMediumMin}) and (max-width: 1150px) {
		width: 45.1rem;
	}

	@media (min-width: 1011px) {
		width: 31.2rem;
	}
`;

export const QuickSearchSpinner = styled(Loader)``;

// QuickSearchResult

export const QuickSearchResultRow = styled.li`
	position: relative;
	height: 5.6rem;
	:not(:first-child) {
		border-top: 0.1rem solid ${greyDark};
	}
	padding: ${spacing1x};
`;

export const Link = styled.a`
	text-decoration: none;
`;

export const RowTitle = styled.h4`
	text-transform: uppercase;
	color: ${blue};
	line-height: 2.4rem;
	font-size: 1.6rem;
	margin: 0;
	max-width: 80%;
	max-height: 2.4rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const text = css`
	color: ${black};
	font-size: 1.3rem;
	line-height: 1.3rem;
	display: block;
`;

export const ParentCategory = styled.span`
	${text};
`;

export const ItemCodeAndNameWrapper = styled.span`
	display: block;
`;

export const ArticleText = styled.span`
	${text};
	display: block;
`;

export const BoldText = styled.span`
	${text};
	font-weight: ${fontWeightBold};
	margin-right: ${spacing1x};
	display: inline-block;
`;

export const NoResultMessage = styled.span`
	${text};
	font-size: 1.3rem;
	text-align: center;
	margin-top: 0.8rem;
`;

export const SmallMessage = styled.p`
	${text};
	font-size: 1.3rem;
	text-align: center;
`;
