/**
 * Field
 * @module components/Field
 */

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFieldValidation } from 'hooks';
import { useFormValue } from 'context/form.context';
import { Input } from './Form.styles';

const Field = (props) => {
	const {
		className,
		placeholder,
		description,
		onFieldChange,
		defaultValue,
		resetForm,
		autoSubmit,
		name,
		id,
		showError,
		setFocus,
		visibleFields,
		validationMessage,
		patternMessage,
		noValidate,
		type,
		validate,
		label,
		helpText,
		onBlur,
		describedby,
		required,
		isValid,
		maxLength,
		...allProps
	} = props;

	const [{ invalidFields }, dispatch] = useFormValue();
	const [value, setValue] = useState(defaultValue || '');
	const [valid, setValid] = useState(false);
	const [touched, setTouched] = useState(false);
	const fieldRef = useRef(null);
	const [validateField, showFieldError, hideFieldError] = useFieldValidation(
		fieldRef,
		props
	);

	useEffect(() => {
		const validatedField = validateField(value);
		const fieldIsValid = !validatedField.message;

		if (validatedField.message) {
			setValid(false);
			dispatch({ type: 'FIELD_INVALID', field: validatedField });
			if (isValid) {
				isValid(false, id);
			}
		} else {
			setValid(true);

			if (invalidFields.includes(validatedField.id)) {
				dispatch({ type: 'FIELD_VALID', field: validatedField });
			}
			if (isValid) {
				isValid(true, id);
			}
		}

		if (!noValidate) {
			if (touched && !fieldIsValid) {
				showFieldError(validatedField);
			} else if (
				(showError &&
					!fieldIsValid &&
					visibleFields &&
					visibleFields.includes(id)) ||
				(showError && !fieldIsValid && !visibleFields)
			) {
				setTouched(true);
				showFieldError(validatedField);
			} else {
				hideFieldError();
			}

			if (setFocus) {
				fieldRef.current.focus();
			}
		} else if (validate && touched) {
			validate({ name, value });
		}
		//eslint-disable-next-line
	}, [touched, showError, value, setFocus, visibleFields, required]);

	const onChange = (e) => {
		setValue(e.target.value);
		onFieldChange({
			name,
			value: e.target.value,
			type: 'Field',
			autoSubmit,
		});
	};

	if (noValidate) {
		return (
			<Input
				className={className || ''}
				title={description}
				placeholder={placeholder || ''}
				aria-invalid={validate ? undefined : !valid}
				value={value}
				name={name}
				onChange={onChange}
				touched={touched}
				onBlur={() => {
					setTouched(true);
					if (onBlur) onBlur(value);
				}}
				ref={fieldRef}
				id={id}
				type={type === null ? 'text' : type}
				{...allProps}
			/>
		);
	}

	return (
		<Input
			maxLength={maxLength}
			className={className || ''}
			title={description}
			placeholder={placeholder || ''}
			aria-invalid={validate ? undefined : !valid}
			value={value}
			name={name}
			required={required}
			onChange={onChange}
			touched={touched}
			onBlur={() => {
				setTouched(true);
				if (onBlur) onBlur({ [name]: value }, valid);
			}}
			ref={fieldRef}
			id={id}
			aria-describedby={`form${id}__desc${
				describedby ? ` ${describedby}` : ''
			}`}
			type={type === null ? 'text' : type}
			{...allProps}
		/>
	);
};

Field.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	id: PropTypes.string,
	description: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	pattern: PropTypes.string,
	required: PropTypes.bool,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.bool,
	]),
	defaultValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.any,
	]),
	setFocus: PropTypes.bool,
	autoSubmit: PropTypes.bool,
	onFieldChange: PropTypes.func,
	disabled: PropTypes.bool,
	showError: PropTypes.bool,
	visibleFields: PropTypes.any,
	validationMessage: PropTypes.string,
	patternMessage: PropTypes.string,
	noValidate: PropTypes.bool,
};

export default Field;
