/**
 * ArticleDetail
 * @module components/ArticleDetail
 */

import { useContext, useState, useEffect } from 'react';
import Modal from 'components/ui/Modal';
import { LocalizationContext } from 'context/localization.context';
import MediaGallery from 'components/framework/MediaGallery';
import {
	GenericWarning,
	renderElementBasedOnEditMode,
	ServerError,
} from 'common/helpers';
import LinkList from 'components/framework/LinkList';
import Button from 'components/ui/Button';
import ProductCounter from 'components/ui/ProductCounter';
import { useMedia } from 'hooks';
import { toast } from 'react-toastify';
import { toastWithLink } from 'common/toastHelpers';
import RichText from 'components/ui/RichText';

import { AddToCartModel } from 'types/cart-types';
import { ArticleRowItemModel } from 'types/article';
import Certificate from 'components/ui/Certificate/Certificate';
import {
	BreadCrumbsModel,
	DocumentModel,
	LinkModel,
	MediaGalleryItemModel,
	SpecificationsModel,
} from 'types/common';

import { AddToCart, selectCartObject } from 'store/modules/cart';
import SpecificationsList from 'components/framework/SpecificationsList/SpecificationsList';
import { SeparatorIcon } from 'components/framework/ItemRow/ItemRow.styles';
import Icon from 'components/ui/Icon';
import { fetchArticleDetail } from 'api/general';
import { useDispatch, useSelector } from 'react-redux';

interface ArticleDetailModel {
	onToggle: any;
	articleApiUrl: string | null;
	disableControls?: boolean;
	api?: ArticleApiModel;
	activeMarket?: string;
}

interface ArticleApiModel {
	productConfiguratorValidate: string;
	productConfiguratorCreate: string;
	productConfiguratorShutdown: string;
}

export interface ArticleDetailArticleModel extends ArticleRowItemModel {
	mediaGallery: MediaGalleryItemModel[];
	documents: DocumentModel[];
	shortDescription: string;
	name: string;
	isInEditMode: boolean;
	longDescription: string;
	prices: SpecificationsModel;
	rangeSpecifications: SpecificationsModel;
	contentsAndAvailability: SpecificationsModel;
	breadcrumbs: LinkModel[];
	informationHeading?: string;
	informationText?: string;
	copyUrl?: string;
}

const ArticleDetail = ({
	onToggle,
	articleApiUrl = null,
	disableControls,
	activeMarket,
}: ArticleDetailModel) => {
	const { t }: any = useContext(LocalizationContext);
	const [item, setItem] = useState<ArticleDetailArticleModel>();
	const [currentQuantity, setCurrentQuantity] = useState(1);
	const isMobile = useMedia('(max-width: 767px)');
	const dispatch = useDispatch();
	const { cartDisabled, cartHidden, cartLink } = useSelector(selectCartObject);

	const getArticleData = async () => {
		try {
			const data = await fetchArticleDetail(articleApiUrl);
			setItem(data);
		} catch (error) {
			console.error(error);
		}
	};
	useEffect(() => {
		if (articleApiUrl) {
			getArticleData();
		}
		//eslint-disable-next-line
	}, []);

	const handleAddToCart = async () => {
		try {
			if (item) {
				const articleList: AddToCartModel[] = [
					{
						itemCode: item?.itemCode,
						quantity: currentQuantity,
					},
				];
				await dispatch(AddToCart('POST', articleList));
			}
			toastWithLink(
				t('shared/toast/cartaddsuccess'),
				cartLink?.link,
				toast.TYPE.SUCCESS
			);
			if (item?.itemCode !== undefined && window.dataLayer) {
				window.dataLayer.push({
					event: 'add_to_cart',
					domain: activeMarket,
					ecommerce: {
						items: [
							{
								item_name: item?.itemName,
								item_id: item?.itemCode,
								price: item?.defaultPrice,
								quantity: 1,
								item_category: item?.breadcrumbs[0].text,
								item_category2: item?.breadcrumbs[1].text,
							},
						],
					},
				});
			}
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/cartupdateerror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		}
	};

	useEffect(() => {
		if (item?.itemCode !== undefined && window.dataLayer) {
			window.dataLayer.push({
				event: 'view_item',
				ecommerce: {
					items: [
						{
							item_name: item?.itemName,
							item_id: item?.itemCode,
							price: item?.defaultPrice,
							quantity: 1,
							item_category: item?.breadcrumbs[0].text,
							item_category2: item?.breadcrumbs[1].text,
						},
					],
				},
			});
		}
	}, [item]);

	const renderBreadCrumbs = (breadcrumbs: BreadCrumbsModel[]) => {
		return (
			<ul className="my-4 p-0 inline-block list-none text-greyDarker w-full">
				{breadcrumbs.map((item, key) => {
					const shouldHaveSeparator = key !== breadcrumbs.length - 1;
					return (
						<li className="inline-block text-greyDarker" key={key}>
							<a
								className="text-black text-p text-opacity-50 no-underline hover:underline active:underline"
								href={item.link}
								key={key}
							>
								{item.text}
							</a>
							{shouldHaveSeparator && <SeparatorIcon aria-hidden />}
						</li>
					);
				})}
			</ul>
		);
	};

	return (
		<Modal
			heading={`${item?.itemName !== undefined ? item?.itemName : ''} #${
				item?.itemCode !== undefined ? item?.itemCode : ''
			}`}
			title={t('articledetail/contentdescription')}
			width="large"
			toggleModal={() => {
				onToggle && onToggle();
			}}
			hasBreadcrumbs={item?.breadcrumbs ? true : false}
			itemCode={item?.itemCode}
			copyUrl={item?.copyUrl}
		>
			{item ? (
				<div className="flex-col flex md:flex-row md:flex-wrap">
					{item.breadcrumbs && renderBreadCrumbs(item.breadcrumbs)}
					<div className="w-full p-0 md:w-1/2 md:pr-4">
						{!isMobile && item.mediaGallery && (
							<MediaGallery
								currentValues={{}}
								mediaGallery={item.mediaGallery}
							/>
						)}
						{!isMobile && item.documents && (
							<LinkList documents={item.documents} expanded={false} />
						)}
					</div>
					<div className="w-full p-0 md:w-1/2 md:pl-4">
						{(item.shortDescription || item.name) && (
							<h1>
								{item.name &&
									renderElementBasedOnEditMode(
										item.isInEditMode,
										'name',
										'span',
										{ className: 'text-orange text-h3 block' },
										item.name
									)}
								{item.shortDescription &&
									renderElementBasedOnEditMode(
										item.isInEditMode,
										'shortDescription',
										'span',
										{
											className: 'text-blue text-h2-large md:block mb-3',
										},
										item.shortDescription
									)}
							</h1>
						)}
						{item.longDescription && (
							<RichText
								name="longDescription"
								text={item.longDescription}
								isInEditMode={item.isInEditMode}
							/>
						)}

						{isMobile && item?.mediaGallery?.length > 0 && (
							<div className="w-full p-0">
								<MediaGallery mediaGallery={item.mediaGallery} />
							</div>
						)}
						{isMobile && item?.certificates && item?.certificates?.length > 0 && (
							<div className="mr-2 my-2 flex w-full">
								{item?.certificates?.map((c) => (
									<Certificate
										tooltipLink={c?.link}
										imageUrl={c?.icon}
										tooltipText={c?.text}
										altText={c.text}
									/>
								))}
							</div>
						)}
						{isMobile && item.documents && (
							<LinkList documents={item.documents} expanded={false} />
						)}
						{item.prices && (
							<SpecificationsList
								className="m-0"
								specifications={item.prices}
								heading={t('articledetailpage/price')}
								prices={true}
								unit={item.unit ? item.unit : ''}
								currency={item.currency ? item.currency : ''}
							/>
						)}
						{cartDisabled && (
							<p className="text-h4">{t('shared/articlerow/loginforprice')}</p>
						)}
						{!disableControls && !cartHidden && (
							<div className="flex justify-end">
								<div className="my-auto mx-0">
									<ProductCounter
										id={`Counter-${item.itemCode}`}
										productName={item.itemName}
										itemCode={item.itemCode}
										quantity={currentQuantity}
										onChange={(itemCode: string, quantity: number) => {
											setCurrentQuantity(quantity);
										}}
									/>
								</div>
								<div className="mb-2 ml-2">
									<Button
										type="button"
										buttonColor="blue"
										onClick={() => {
											handleAddToCart();
											setCurrentQuantity(1);
										}}
										disabled={cartDisabled}
									>
										{isMobile
											? t('shared/articlerow/add')
											: t('shared/articlerow/addtocart')}
										<Icon icon="cart" size={1.25} />
									</Button>
								</div>
							</div>
						)}
						{item?.certificates && item?.certificates?.length > 0 && (
							<div className="mr-2 my-2 flex w-full">
								{item?.certificates?.map((c) => (
									<Certificate
										tooltipLink={c?.link}
										imageUrl={c?.icon}
										tooltipText={c?.text}
										altText={c.text}
									/>
								))}
							</div>
						)}
						{item.rangeSpecifications && (
							<SpecificationsList
								className="m-0"
								specifications={item.rangeSpecifications}
								heading={t('shared/specificationslist/rangespecifications')}
							/>
						)}

						{(item?.informationHeading || item.informationText) && (
							<div className="bg-blue py-3 px-4 mt-8 mb-4">
								{item.informationHeading && (
									<h2 className="text-white text-p font-bold mb-1">
										{item.informationHeading}
									</h2>
								)}
								{item.informationText && (
									<p className="text-white text-p font-thin">
										{item.informationText}
									</p>
								)}
							</div>
						)}

						{item.contentsAndAvailability && (
							<SpecificationsList
								className="m-0"
								specifications={item.contentsAndAvailability}
								heading={t('articledetailpage/contentsandavailability')}
							/>
						)}
					</div>
				</div>
			) : (
				<div className="text-center">
					<Icon icon="loader" animate="spin" size={5} aria-hidden={true} />
				</div>
			)}
		</Modal>
	);
};

export default ArticleDetail;
