import { configureStore, combineReducers, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import configuratorReducer from './modules/configurator';
import cartReducer from './modules/cart';
import localizationReducer from './modules/localization';
import ordermatrixReducer from './modules/ordermatrix';
import userReducer from './modules/user';

const rootReducer = combineReducers({
	localization: localizationReducer,
	configurator: configuratorReducer,
	cart: cartReducer,
	ordermatrix: ordermatrixReducer,
	user: userReducer,
});
export type RootState = ReturnType<typeof rootReducer>;

let store: any = null;

export default (preloadedState?: any) => {
	if (preloadedState != null) {
		store = configureStore({
			reducer: rootReducer,
			preloadedState,
		});
	}

	return store;
};

// export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
