import React from 'react';
import styled, { css } from 'styled-components';
import { spacing2x, spacing1x, spacing05x } from 'theme/spacings';
import { fontFamily, fontWeightBold, fontWeightNormal } from 'theme/typography';
import { blue, white, greyLight } from 'theme/colors';
import { deviceSmallMax } from 'theme/mediaQueries';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { paragraphCss } from '../Typography';

export const Table = styled.table`
	border-spacing: 0;
	width: 100%;
	font-family: ${fontFamily};
`;

export const TableRow = styled.tr`
	background-color: ${white};
	:nth-child(even) {
		background-color: ${greyLight};
	}
`;

export const TableHeadRow = styled.thead`
	background-color: ${blue};
`;
export const TableHead = styled.tr`
	color: ${white};
	font-size: 1.6rem;
	padding: ${spacing1x};
	font-family: ${fontFamily};
`;
export const TableHeadContent = styled.th`
	padding: ${spacing1x} ${spacing2x};
	font-size: 1.6rem;
	font-weight: ${fontWeightNormal};
	text-align: ${props => (props.center ? 'center' : 'left')};
	white-space: nowrap;

	&:active {
		font-weight: ${fontWeightBold};
	}

	@media (max-width: ${deviceSmallMax}) {
		font-size: 1.4rem;
		line-height: 2rem;
	}
`;
export const TableData = styled.div`
	font-size: 1.6rem;
	padding: ${spacing1x} ${spacing2x};
	font-family: ${fontFamily};
	@media (max-width: ${deviceSmallMax}) {
		font-size: 1.4rem;
		line-height: 2rem;
	}
`;

export const SortWrapper = styled.div`
	position: relative;
	height: 100%;
	width: 1rem;
	display: inline-block;
	margin-left: ${spacing05x};
`;

const sortIconCss = css`
	position: absolute;
	color: ${white};
	fill: ${white};
	font-size: 1.4rem;
	opacity: 0.4;
`;

const filterProps = props => {
	const { isSorted, isDescending, ...rest } = props;
	return rest;
};

export const SortAscIcon = styled(props => (
	<FiChevronUp {...filterProps(props)} />
))`
	${sortIconCss}
	top: -1.5rem;
	opacity: ${props => (props.isSorted && !props.isDescending ? 1 : 0.4)};
`;

export const SortDscIcon = styled(props => (
	<FiChevronDown {...filterProps(props)} />
))`
	${sortIconCss}
	top: -0.9rem;
	opacity: ${props => (props.isSorted && props.isDescending ? 1 : 0.4)};
`;

export const VisuallyHiddenCaption = styled.caption`
	border: 0;
	clip: rect(0 0 0 0);
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.1rem;
`;

export const CellLink = styled.a`

	${paragraphCss}
    color: ${blue};
    display: inline-block;
	margin: 0;
	padding: 0;
	cursor: pointer;
	text-decoration: none;
	font-weight: ${fontWeightNormal};

    :hover {
        text-decoration: underline;
    }
`;
