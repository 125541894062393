/**
 * CisionFinancialReportsPage
 * @module pages/CisionFinancialReportsPage
 */

import React from 'react';
import Layout from 'components/ui/Layout';
import ReportBlock from 'components/common/ReportBlock';
import ArchiveBlock from 'components/common/ArchiveBlock';
import SectionHeader from 'components/ui/SectionHeader';
import Breadcrumbs from 'components/framework/Breadcrumbs';
import { Grid, Cell } from 'components/ui/Grid';
import { ButtonShowMore } from './CisionFinancialReportsPage.styles';

const CisionFinancialReportsPage = ({
	heading,
	latestInterimReport,
	latestAnnualReport,
	cisionFinancialReports,
	showOlderReportsText,
	showOlderReportsLink,
	breadcrumbs,
	isInEditMode,
}) => (
	<main id="main-content">
		<Layout backgroundColor="white">
			<Breadcrumbs links={breadcrumbs} />
			<SectionHeader
				heading={heading}
				size={'large'}
				isInEditMode={isInEditMode}
			/>
			<Grid padding={false}>
				{latestInterimReport && (
					<Cell span={12} desktop={6} className="mb-6">
						<ReportBlock
							heading={latestInterimReport.heading}
							date={latestInterimReport.date}
							name={latestInterimReport.name}
							linkToPdf={latestInterimReport.linkToPdf}
							image={latestInterimReport.image}
							buttonText={latestInterimReport.orderPrintedReportText}
							buttonLink={latestInterimReport.orderPrintedReportLink}
						></ReportBlock>
					</Cell>
				)}

				{latestAnnualReport && (
					<Cell span={12} desktop={6} className="mb-6">
						<ReportBlock
							heading={latestAnnualReport.heading}
							date={latestAnnualReport.date}
							name={latestAnnualReport.name}
							linkToPdf={latestAnnualReport.linkToPdf}
							image={latestAnnualReport.image}
							buttonText={latestAnnualReport.orderPrintedReportText}
							buttonLink={latestAnnualReport.orderPrintedReportLink}
						></ReportBlock>
					</Cell>
				)}
			</Grid>

			{cisionFinancialReports &&
				Object.keys(cisionFinancialReports)
					.reverse()
					.map((year, i) => {
						return (
							<ArchiveBlock
								key={i}
								year={year}
								reports={cisionFinancialReports[year]}
								className="mt-3"
							/>
						);
					})}
			{showOlderReportsLink && showOlderReportsText && (
				<ButtonShowMore
					as="a"
					buttonColor={'orange'}
					href={showOlderReportsLink}
				>
					{showOlderReportsText}
				</ButtonShowMore>
			)}
		</Layout>
	</main>
);

export default CisionFinancialReportsPage;
