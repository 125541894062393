/**
 * Carousel
 * @module components/Carousel
 */

import React, { useState, useRef, useEffect } from 'react';

import { SliderWrapper, SliderDot } from './Carousel.styles';
import { useMedia } from 'hooks';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import CarouselItem from './CarouselItem';
import { CarouselBlockModel } from 'types/block-types';

const Carousel = (props: CarouselBlockModel) => {
	const {
		carouselItems,
		autoTransition,
		transitionTime,
		headingLevel,
		headingStyleLevel,
		heightLevel,
	} = props;
	const isMobile = useMedia('(max-width: 768px)');
	const [autoPlay, setAutoPlay] = useState(
		autoTransition ? autoTransition : false
	);

	useEffect(() => {
		setAutoPlay(autoTransition ? !isMobile : false);
	}, [autoTransition, isMobile]);

	const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
	const [navByKey, setNavByKey] = useState(false);
	const slider = useRef();

	const handleKeyDown = (
		e: React.KeyboardEvent<HTMLDivElement>,
		index: number
	) => {
		if (e.keyCode === 13) {
			// @ts-ignore
			slider.current.slickGoTo(index);
			setNavByKey(true);
		}
	};
	const sliderSettings = {
		accessibility: true,
		dots: true,
		arrows: false,
		adaptiveHeight: true,
		lazyLoad: true,
		customPaging: (i: number) => {
			return (
				<SliderDot
					tabIndex={0}
					onKeyDown={(e) => handleKeyDown(e, i)}
					onFocus={() => setAutoPlay(false)}
				>
					<span className="sr-only">
						{`${i + 1} ${carouselItems && carouselItems[i].heading}`}
					</span>
				</SliderDot>
			);
		},
		autoplay: autoPlay,
		autoplaySpeed: autoPlay && transitionTime ? transitionTime * 1000 : 3000,
		speed: 300,
		inifinite: true,
		pauseOnHover: true,
		pauseOnDotsHover: true,
		afterChange: (current: any) => {
			setCurrentSlideIndex(current);
		},
	};

	if (carouselItems && carouselItems.length > 0) {
		return (
			<div className="border-solid border-grey border-2 bg-transparent md:border-0 md:m-0 mx-auto max-w-360">
				<SliderWrapper {...sliderSettings} ref={slider}>
					{carouselItems.map((item, i) => (
						<CarouselItem
							item={item}
							key={i}
							index={i}
							keyNav={navByKey}
							setKeyNav={setNavByKey}
							currentIndex={currentSlideIndex}
							headingLevel={headingLevel ? headingLevel : 1}
							headingStyleLevel={headingStyleLevel ? headingStyleLevel : 1}
							heightLevel={heightLevel ? heightLevel : 'normal'}
						/>
					))}
				</SliderWrapper>
			</div>
		);
	} else {
		return null;
	}
};

export default Carousel;
