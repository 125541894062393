/**
 * MySavedCartsPage
 * @module pages/MySavedCartsPage
 */

import React, { useMemo, useContext, useEffect, useState, useRef } from 'react';
import { LocalizationContext } from 'context/localization.context';
import { useTable, useSortBy, usePagination } from 'react-table';
import Breadcrumbs from 'components/framework/Breadcrumbs';
import {
	handleSortByKeyDown,
	handleKeyboardNavigation,
	formatAndGetCellValue,
} from 'components/ui/Table';
import { useMedia } from 'hooks';

import Layout from 'components/ui/Layout';
import { useSelector } from 'react-redux';
import {
	Table,
	TableHeadRow,
	TableHead,
	TableHeadContent,
	TableData,
	TableRow,
	SortWrapper,
	SortAscIcon,
	SortDscIcon,
	CellLink,
} from 'components/ui/Table/table.styles';

import {
	Body,
	NoResultsMessage,
	PagerWrapper,
	SavedCartsCell,
	DeleteCartButton,
	DeleteLinkWrapper,
} from './MySavedCartsPage.styles';

import SectionHeader from 'components/ui/SectionHeader';
//import OverflowMessage from 'components/common/OverflowMessage';
import ContentArea from 'components/framework/ContentArea';
import { toast } from 'react-toastify';
import { useDefaultPopState } from 'hooks/usePopState';
import {
	getURLParameter,
	scrollToTop,
	ServerError,
	GenericWarning,
} from 'common/helpers';
import { useDispatch } from 'react-redux';
import { DeleteCart } from 'store/modules/cart';
import AlertModal from 'components/ui/AlertModal';
import Icon from 'components/ui/Icon';
import { selectUserData } from 'store/modules/user';
const MySavedCartsPage = ({
	breadcrumbs,
	heading,
	text,
	carts,
	contentArea,
	overflow,
	isInEditMode,
	owner,
	owners,
}) => {
	const { t } = useContext(LocalizationContext);
	const isMobile = useMedia('(max-width: 490px)');
	const [savedCarts, setSavedCarts] = useState(carts || []);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [itemToDelete, setItemToDelete] = useState(null);
	const currentPage = parseInt(getURLParameter('page'));
	const { userObject } = useSelector(selectUserData);
	const [selectedOwner, setSelectedOwner] = useState(
		owner ?? getURLParameter('owner') ?? 'shared'
	);
	const dispatch = useDispatch();
	const data = useMemo(() => savedCarts, [savedCarts]);
	const columns = useMemo(
		() => [
			{ Header: t('mysavedcartspage/date'), accessor: 'date' },
			{ Header: t('mysavedcartspage/carttitle'), accessor: 'name' },
			{ Header: t('mysavedcartspage/numberofrows'), accessor: 'articleRows' },
			{
				Header: t('mysavedcartspage/totalarticles'),
				accessor: 'totalArticles',
			},
			{
				Header: t('mysavedcartspage/visibility'),
				accessor: 'visibility',
			},
		],
		//eslint-disable-next-line
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		pageOptions,
		gotoPage,
		state: { pageIndex },
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: !isNaN(currentPage) ? currentPage - 1 : 0,
				pageSize: 25,
			},
		},
		useSortBy,
		usePagination
	);

	const removeCartItemFromState = (cart) => {
		if (cart && cart.id) {
			let newState = savedCarts && savedCarts.filter((c) => c.id !== cart.id);
			newState && setSavedCarts(newState);
		}
	};

	const didRender = useRef(false);
	const preventHistoryEntry = useRef(false);

	const popState = useDefaultPopState({ page: pageIndex + 1 });
	const popStateString = JSON.stringify(popState);
	useEffect(() => {
		if (popState && popState.page !== pageIndex + 1) {
			preventHistoryEntry.current = true;
			gotoPage(popState.page - 1);
		}
		//eslint-disable-next-line
	}, [popStateString]);

	useEffect(() => {
		if (!preventHistoryEntry.current) {
			if (didRender.current) {
				window.history.pushState(
					{
						page: pageIndex + 1,
					},
					null,
					window.location.pathname + `?page=${pageIndex + 1}`
				);

				if (selectedOwner) {
					window.history.pushState(
						{
							page: pageIndex + 1,
						},
						null,
						window.location.pathname +
							`?page=${pageIndex + 1}&owner=${selectedOwner}`
					);
				}
			} else {
				window.history.replaceState(
					{
						page: pageIndex + 1,
					},
					null,
					window.location.pathname + `?page=${pageIndex + 1}`
				);

				if (selectedOwner) {
					window.history.replaceState(
						{
							page: pageIndex + 1,
						},
						null,
						window.location.pathname +
							`?page=${pageIndex + 1}&owner=${selectedOwner}`
					);
				}
			}
		}

		if (!didRender.current) {
			didRender.current = true;
		}
		if (preventHistoryEntry.current) {
			didRender.current = false;
		}
	}, [pageIndex, selectedOwner]);

	useEffect(() => {
		try {
			fetch(window.location.href.split('?')[0] + '?owner=' + selectedOwner, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
				},
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					data && setSavedCarts(data.carts);
				});
		} catch (err) {
			console.error(err);
		}
	}, [selectedOwner, userObject]);

	const handleDeleteCart = async () => {
		try {
			await dispatch(DeleteCart(itemToDelete.id));
			removeCartItemFromState(itemToDelete);
			toast(t('mysavedcartspage/carthasbeenremoved'), {
				type: toast.TYPE.SUCCESS,
			});
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/servererror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		} finally {
			setItemToDelete(null);
			setDeleteDialogOpen(false);
		}
	};

	return (
		<main id="main-content">
			{breadcrumbs && (
				<Layout backgroundColor="white">
					<Breadcrumbs links={breadcrumbs} />
				</Layout>
			)}
			<SectionHeader
				heading={heading}
				text={text}
				size={'large'}
				isInEditMode={isInEditMode}
			/>
			{/* <Layout> // Not currently in use
				<OverflowMessage overflow={overflow} />
			</Layout> */}

			<Layout backgroundColor="white">
				<select
					className="bg-white py-2 px-3 mb-5 border rounded-md cursor-pointer min-w-55"
					value={selectedOwner}
					onChange={(e) => setSelectedOwner(e.target.value)}
				>
					{owners &&
						owners.map((ownerOption, index) => (
							<option key={index} value={ownerOption.id}>
								{ownerOption.name}
							</option>
						))}
				</select>
				<Body>
					<Table {...getTableProps({ role: 'grid' })}>
						<caption className="sr-only">{heading}</caption>
						<TableHeadRow>
							{headerGroups.map((headerGroup) => (
								<TableHead {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column, i) => (
										<TableHeadContent
											{...column.getHeaderProps(
												column.getSortByToggleProps({
													title: `${t('shared/table/sortby')} ${column.Header}`,
													role: 'button',
													tabIndex: 0,
												})
											)}
											aria-sort={
												column.isSorted && !column.isSortedDesc
													? t('shared/table/ascending')
													: t('shared/table/descending')
											}
											data-header-col={i}
											onKeyDown={(e) => {
												if (column.canSort) {
													return handleSortByKeyDown(
														e,
														column,
														i,
														page.length,
														columns.length
													);
												}
											}}
										>
											{column.render('Header')}
											<SortWrapper>
												<SortAscIcon
													isSorted={column.isSorted}
													isDescending={column.isSortedDesc}
												/>
												<SortDscIcon
													isSorted={column.isSorted}
													isDescending={column.isSortedDesc}
												/>
											</SortWrapper>
										</TableHeadContent>
									))}
									<TableHeadContent
										tabIndex={0}
										data-header-col={data && data.length + 1}
										onKeyDown={(e) => {
											return handleSortByKeyDown(
												e,
												null,
												data.length + 1,
												page.length,
												columns.length
											);
										}}
									>
										<p className="sr-only">
											{t('mysavedcartspage/removecart')}
										</p>
									</TableHeadContent>
								</TableHead>
							))}
						</TableHeadRow>
						<tbody {...getTableBodyProps()}>
							{page.map((row, i) => {
								prepareRow(row);
								return (
									<TableRow {...row.getRowProps()}>
										{row.cells.map((cell, index) => {
											const cellColumnId = cell.column.id;
											return (
												<td
													{...cell.getCellProps({
														tabIndex: i === 0 && index === 0 ? 0 : -1,
													})}
													onKeyDown={(e) => {
														return handleKeyboardNavigation(
															e,
															page.length,
															columns.length,
															i
														);
													}}
													data-row={i}
													data-col={index}
												>
													<TableData>
														{cellColumnId !== 'name' ? (
															<SavedCartsCell>
																{formatAndGetCellValue(cell)}
															</SavedCartsCell>
														) : (
															<SavedCartsCell>
																<CellLink href={cell.row.original.editLink}>
																	{cell.render('Cell')}
																</CellLink>
															</SavedCartsCell>
														)}
													</TableData>
												</td>
											);
										})}
										<>
											{row.original.deletable ? (
												<DeleteLinkWrapper
													role="cell"
													data-row={i}
													data-col={row.cells.length}
													onKeyDown={(e) => {
														return handleKeyboardNavigation(
															e,
															page.length,
															columns.length,
															i
														);
													}}
												>
													<DeleteCartButton
														onClick={(e) => {
															e.preventDefault();
															setItemToDelete(row.original);
															setDeleteDialogOpen(true);
														}}
														onKeyDown={(e) => {
															if (e.key === ' ' || e.key === 'Enter') {
																setItemToDelete(row.original);
																setDeleteDialogOpen(true);
															}
														}}
													>
														<span className="sr-only">
															{t('mysavedcartspage/removecart')}
														</span>
														<Icon icon="trash" className="text-greyDarker" />
													</DeleteCartButton>
												</DeleteLinkWrapper>
											) : (
												<td></td>
											)}
										</>
									</TableRow>
								);
							})}
						</tbody>
					</Table>
				</Body>
				{carts && carts.length === 0 && (
					<NoResultsMessage>
						{t('mysavedcartspage/noresultmessage')}
					</NoResultsMessage>
				)}
				<PagerWrapper
					nrOfPages={pageOptions.length}
					currentPage={pageIndex + 1}
					total={pageOptions.length}
					onPageChange={(value) => {
						scrollToTop();
						gotoPage(value - 1);
					}}
					isMobile={isMobile}
				/>
			</Layout>
			<ContentArea contentArea={contentArea} />
			{deleteDialogOpen && (
				<AlertModal
					title={t('mysavedcartspage/deletedialogtitle')}
					heading={t('mysavedcartspage/deletedialogtitle')}
					message={t('mysavedcartspage/deletedialogmessage')}
					cancelButtonText={t('mysavedcartspage/deletedialogcancel')}
					actionButtonText={t('mysavedcartspage/removecart')}
					onToggle={() => setDeleteDialogOpen(false)}
					onClick={handleDeleteCart}
				/>
			)}
		</main>
	);
};

export default MySavedCartsPage;
