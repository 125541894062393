/**
 * RteWithImageBlock
 * @module components/NewsBlock
 */

import ImageBlock from '../../framework/ImageBlock/ImageBlock';
import { NewsBlockModel } from 'types/block-types';
import { Grid, Cell } from 'components/ui/Grid';
import Layout from 'components/ui/Layout';
import clsx from 'clsx';
import { renderElementBasedOnEditMode } from 'common/helpers';
import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';

const NewsBlock = (block: NewsBlockModel) => {
	const {
		heading,
		linkToListingPage,
		textLinkToListingPage,
		items,
		description,
	} = block;
	return (
		<div className="mb-4 md:mb-10">
			<Layout>
				{heading &&
					renderElementBasedOnEditMode(
						false,
						'heading',
						'h1',
						{
							className:
								'font-standard mb-6 mt-2 font-regular text-h2 md:mt-8 md:text-h1 text-blue',
						},
						heading
					)}
				{description && <span>{description}</span>}
				<Grid padding={false}>
					{items.map((item, i) => {
						const {
							link,
							heading,
							preamble,
							image,
							highlight,
							published,
						} = item;
						return (
							<Cell
								span={12}
								tablet={6}
								desktop={4}
								className={clsx(
									items.length === 1 && 'columnblock-offset-1',
									items.length === 2 && i === 0 && 'columnblock-offset-2',
									'my-4 md:my-10 '
								)}
								key={`${heading}-${i}`}
							>
								<ImageBlock
									link={link}
									heading={heading}
									preamble={preamble}
									image={image}
									highlight={highlight}
									publishedDate={published}
								/>
							</Cell>
						);
					})}
				</Grid>
				<div className="text-center my-6">
					<Button
						onClick={() => true}
						className=""
						darkBackground=""
						target=""
						tabIndex={0}
						type="link"
						buttonColor={'blue'}
						url={linkToListingPage}
					>
						<Icon
							icon="chevrons"
							direction="left"
							size={1.25}
							className="mr-2"
						/>
						<span>{textLinkToListingPage}</span>
					</Button>
				</div>
			</Layout>
		</div>
	);
};

export default NewsBlock;
