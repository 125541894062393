import styled from 'styled-components';
import { Input } from '../Form/Form.styles';
import { spacing5x, spacing2x, spacing4x } from 'theme/spacings';
import { Paragraph } from '../Typography';
import Button from 'components/ui/Button';

export const Label = styled(Paragraph)`
	display: block;
`;

export const InputField = styled(Input)`
	display: block;
	width: 100%;
	margin-bottom: ${spacing2x};
	padding-left: ${spacing2x};
`;

export const SaveButton = styled(Button)`
	display: block;
	margin: 0;
`;
export const OverwriteButton = styled(SaveButton)`
	margin-left: ${spacing2x};
`;
export const CancelButton = styled(SaveButton)``;

export const Wrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: ${spacing4x} 0 ${spacing4x} 0;
	margin: 0;
`;
