import React, { useContext } from 'react';
import ToolTip from 'components/ui/Certificate/ToolTip';
import { LocalizationContext } from 'context/localization.context';

interface CertificateProps {
	imageUrl: string;
	altText: string;
	tooltipText: string;
	tooltipLink: string;
}

const Certificate: React.FC<CertificateProps> = ({
	imageUrl,
	altText,
	tooltipText,
	tooltipLink,
}) => {
	const { t } = useContext<any>(LocalizationContext);

	return (
		<ToolTip imageUrl={imageUrl} alt={altText}>
			<div className="flex flex-col w-75 items-start text-p p-2">
				{tooltipText && <p className="">{tooltipText}</p>}
				{tooltipLink && (
					<a target="_blank" href={tooltipLink} className="cursor-pointer mt-4">
						<span className="underline">{t('shared/popover/buttonText')}</span>
					</a>
				)}
			</div>
		</ToolTip>
	);
};

export default Certificate;
