import axios from 'axios';

export const saveAddressChanges = (url, address) => {
	return axios({
		url: `${url}`,
		method: 'post',
		data: address,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'content-type': 'application/json; charset=utf-8',
		},
	})
		.then(response => {
			return response.data || false;
		})
		.catch(error => {
			console.log(error);
			return false;
		});
};

export const deleteAddress = (url, addressId, organizationId) => {
	return axios({
		url: `${url}?addressId=${addressId}&organizationId=${organizationId}`,
		method: 'post',
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'content-type': 'application/json; charset=utf-8',
		},
	})
		.then(response => {
			return response.data || false;
		})
		.catch(error => {
			console.log(error);
			return false;
		});
};
