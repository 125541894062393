/**
 * DatePickerInput
 * @module components/DatePickerInput
 */

import React, { useContext, useRef } from 'react';
import { LocalizationContext } from 'context/localization.context';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import clsx from 'clsx';
import {
	sv,
	da,
	de,
	fi,
	nlBE,
	nb,
	ro,
	hu,
	cs,
	enGB,
	enUS,
	enIE,
	fr,
	nl,
	frCH,
	it,
	et,
} from 'date-fns/locale';
import Icon from '../Icon';

registerLocale('sv', sv);
registerLocale('hu', hu);
registerLocale('nl', nl);
registerLocale('fr', fr);
registerLocale('cs', cs);
registerLocale('da', da);
registerLocale('fi', fi);
registerLocale('de', de);
registerLocale('enGB', enGB);
registerLocale('enUS', enUS);
registerLocale('enIE', enIE);
registerLocale('frCH', frCH);
registerLocale('nlBE', nlBE);
registerLocale('it', it);
registerLocale('no', nb);
registerLocale('ro', ro);
registerLocale('et', et);

interface DatePickerProps {
	minDate?: Date;

	daysDisabled?: any;

	id?: any;

	activeMarket?: any;

	selected?: Date;

	onSelected?: any;

	onChange: any;

	className?: string;

	disabled?: boolean;

	language?: string;

	size?: 'small' | 'default';
}

const DatePickerInput: React.FC<DatePickerProps> = (props) => {
	const {
		minDate,
		className,
		daysDisabled,
		id,
		activeMarket,
		disabled,
		language,
		size = 'default',
	} = props;
	const minimumDate = minDate || new Date();

	let locale = language ? language.toLowerCase() : 'sv';

	// specific cases, e.g. when the activeMarket is Switzerland but the chosen language is French

	switch (language + '-' + activeMarket) {
		case 'fr-CHE':
			locale = 'frCH';
			break;
		case 'en-IRL':
			locale = 'enIE';
			break;
		case 'en-GBR':
			locale = 'enGB';
			break;
		case 'en-COM':
			locale = 'enUS';
			break;
		case 'nl-BEL':
			locale = 'nlBE';
			break;
		default:
			break;
	}

	const { t }: any = useContext(LocalizationContext);
	const datePicker = useRef();

	return (
		<div
			className={clsx(
				className,
				disabled && 'text-greyDarker bg-grey border-grey',
				'datepicker__wrapper',
				size === 'small' && 'h-8'
			)}
		>
			<DatePicker
				{...props}
				id={id}
				locale={locale}
				dateFormat="P"
				minDate={minimumDate}
				ref={(ref: any) => (datePicker.current = ref)}
				timeInputLabel="Date"
				filterDate={daysDisabled}
			/>
			<button
				aria-label={t('cart/selectdate')}
				onClick={(e) => {
					e.preventDefault();
					(datePicker.current as any).setOpen(true);
				}}
				className={clsx(
					disabled && 'bg-greyDark',
					'datepicker__button',
					size === 'small'
						? 'datepicker__button--small'
						: 'datepicker__button--default'
				)}
				disabled={disabled}
			>
				<Icon icon="calendar" color="#ffffff" />
			</button>
		</div>
	);
};

export default DatePickerInput;
