import { FiArrowDownRight, FiArrowRight, FiArrowUpRight } from 'react-icons/fi';
import styled, { css } from 'styled-components';
import { blue, errorRed, green } from 'theme/colors';
import { spacing2x } from 'theme/spacings';

interface TickerProps {
	/** Set the color of Ticker arrow   */
	deltaColor: string;
}

const ArrowCss = css`
	font-size: 3.4rem;
	vertical-align: bottom;
	@media (max-width: 900px) {
		font-size: 2.4rem;
		vertical-align: sub;
	}
`;

export const TickerWrapper = styled.div`
	display: block;
	@media (max-width: 900px) {
		display: flex;
		align-items: center;
	}
`;

export const Date = styled.p`
	font-size: 1.6rem;
	line-height: 2.4rem;
	margin: 0;
	@media (max-width: 900px) {
		font-size: 1.4rem;
	}
`;
export const PriceWrapper = styled.p`
	font-weight: bold;
	font-size: 2.5rem;
	line-height: 3.2rem;
	margin: 0;
	display: flex;
	align-items: center;
	@media (max-width: 900px) {
		margin-left: ${spacing2x};
		font-size: 1.8rem;
	}
`;

export const Delta = styled.span<TickerProps>`
	margin-left: ${spacing2x};
	color: ${({ deltaColor }) => deltaColor};
`;
export const ArrowDownRight = styled(FiArrowDownRight)`
	color: ${errorRed};
	${ArrowCss};
`;

export const ArrowRight = styled(FiArrowRight)`
	color: ${blue};
	${ArrowCss};
`;
export const ArrowUpRight = styled(FiArrowUpRight)`
	color: ${green};
	${ArrowCss};
`;
