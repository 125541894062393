/**
 * SearchPage
 * @module pages/SearchPage
 */

import React, {
	useContext,
	useState,
	useEffect,
	useReducer,
	useRef,
} from 'react';

import { useMedia, useDebounce } from 'hooks';
import { useFilterSearch, trackClick, useAutoComplete } from 'api';
import { LocalizationContext } from 'context/localization.context';
import { listFilterReducer } from 'reducers/listFilterReducer';
import { renderElementBasedOnEditMode, scrollToTop } from 'common/helpers';
import { composeQueryString, setUrlLocation } from 'common/helpers';
import ArticleRow from 'components/framework/ArticleRow';
import ItemRow from 'components/framework/ItemRow';
import ContentArea from 'components/framework/ContentArea';
import Breadcrumbs from 'components/framework/Breadcrumbs';
import SearchResultSummary from 'components/ui/SearchResultSummary';
import AutoComplete from 'components/ui/AutoComplete';
import FilterChip from 'components/ui/FilterChip';
import { Paragraph } from 'components/ui/Typography';
import Layout from 'components/ui/Layout';
import ArticleDetail from 'components/common/ArticleDetail';
import LocationRow from 'components/framework/LocationRow';
import { Preamble, PagerWrapper, FilterBarWrapper } from './SearchPage.styles';
import {
	ArticleWrapper,
	FilterCol,
	SlidersButton,
	SlidersIcon,
	ArticleCol,
	FieldSet,
	ClearFilterButton,
} from '../ProductDetailPage/ProductDetailPage.styles';
import CustomExternalScript from 'components/framework/CustomExternalScript';
import FilterBar from 'components/ui/FilterBar';
import { usePopState } from 'hooks/usePopState';
import ArticleDetailConfiguration from 'components/common/ArticleDetailConfiguration';
import { Cell, Grid } from 'components/ui/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { usePriceStockDetail } from 'api/general';
import { selectUserObject } from 'store/modules/user';
import Icon from 'components/ui/Icon';
import { selectCartObject } from 'store/modules/cart';
import { confReset } from 'store/modules/configurator';

const SearchPage = ({
	breadcrumbs,
	heading,
	preamble,
	filter,
	articleList,
	pagination,
	contentArea,
	isInEditMode,
	suggestions,
	framework,
}) => {
	const { t } = useContext(LocalizationContext);
	const confDispatch = useDispatch();
	const { customers, projects, warehouses } = useSelector(selectUserObject);
	const { cartDisabled } = useSelector(selectCartObject);
	const [query, setQuery] = useState(null);
	const [searchTerm, setSearchTerm] = useState((filter && filter.query) || '');
	const [response, error, loading] = useFilterSearch(
		query,
		window && window.location ? window.location.pathname : null
	);

	const isMobile = useMedia('(max-width: 490px)', true);
	const tabletView = useMedia('(max-width: 890px)', true);

	const [state, dispatch] = useReducer(listFilterReducer, {
		sortBy: filter.sortBy,
		display: pagination.display,
		query: filter.query,
		currentPage: pagination.currentPage,
		group: filter.group,
		facets: filter.facets,
	});

	const [articleModal, setArticleModal] = useState({
		isActive: false,
		link: null,
	});

	const [confModalOpen, setConfModalOpen] = useState(false);

	const toggleConfModal = () => {
		setConfModalOpen(!confModalOpen);
		confDispatch(confReset());
	};

	const articleWrapper = useRef();

	let didRender = useRef(false);
	const preventHistoryEntry = useRef(false);

	const popState = usePopState(state);
	const popStateString = JSON.stringify(popState);
	useEffect(() => {
		if (popState) {
			dispatch({ type: 'SET_STATE', payload: popState });
			preventHistoryEntry.current = true;
		}
	}, [popState, popStateString]);

	useEffect(() => {
		const hasRendered = didRender.current;
		const queryString = composeQueryString(
			state.query,
			null,
			state.display,
			state.currentPage,
			state.facets,
			state.group
		);
		// Only set the query to fetch new data if it's not the first render.
		didRender.current
			? setQuery(queryString ? queryString : null)
			: (didRender.current = !didRender.current);
		if (queryString !== query) {
			setUrlLocation(
				queryString,
				state.query,
				null,
				state.display,
				state.currentPage,
				state.facets,
				state.group,
				hasRendered,
				preventHistoryEntry.current
			);
		}
	}, [query, state]);

	useEffect(() => {
		if (response && response.filter && response.filter.facets) {
			dispatch({ type: 'FACETS', facets: response.filter.facets });
		}
	}, [response]);

	const handleFilterRequest = (sortBy, display) => {
		dispatch({ type: 'SORT_BY', sortBy: 1 });
		dispatch({ type: 'GROUP', group: sortBy });
		dispatch({ type: 'DISPLAY', display: display });
	};

	const handleFacet = (facetId, itemId, value) => {
		const facet = state.facets.find((f) => f.id === facetId);
		const item = facet.items.find((i) => i.id === itemId);
		item && (item.active = value);

		dispatch({ type: 'FACETS', facets: state.facets });
	};

	const clearFacets = () => {
		state.facets.forEach((facet) =>
			facet.items.forEach((item) => (item.active = false))
		);
		dispatch({ type: 'FACETS', facets: state.facets });
	};

	const handleSearch = (value) => {
		if (state.query !== value) {
			dispatch({ type: 'QUERY', query: value });
		}
	};

	const handleAutocompleteChange = (value) => {
		if (value !== searchTerm) {
			setSearchTerm(value);
		}
	};

	const handlePagination = (value) => {
		scrollToTop();
		dispatch({ type: 'CURRENT_PAGE', currentPage: value });
	};

	const [showFilter, setShowFilter] = useState(false);
	const toggleMobileFilter = () => {
		setShowFilter(!showFilter);
	};

	const paginationObject = response.pagination || pagination;

	const articlesArray = response.articleList || articleList;

	const filters = response.filter || filter;

	const suggestionList = response.suggestions || suggestions;

	const anyActiveFacetFilter = state.facets.some((facet) =>
		facet.items.some((item) => item.active === true)
	);

	const hasFacets = filters && filters.facets && filters.facets.length > 0;

	const handleSuggestion = (newQuery) => {
		if (state.query !== newQuery) {
			setSearchTerm(newQuery);
			dispatch({ type: 'QUERY', query: newQuery });
		}
	};

	const handleTrackClick = (hitId, trackId) => {
		trackClick(framework.api.clickTrackUrl, {
			query: state.query,
			hitId,
			trackId,
		});
	};
	const debouncedSearchTerm = useDebounce(searchTerm, 100);
	const [
		autoCompleteResponse,
		autoCompleteError,
		autoCompleteLoading,
	] = useAutoComplete(debouncedSearchTerm, framework.api.autocompleteSearchUrl);

	const priceArticles = response.articleList
		? [...response.articleList]
		: articleList;
	const [priceResponse, priceError, priceLoading] = usePriceStockDetail({
		articlesArray: priceArticles.filter(
			(pa) => pa.modelType === 'Article' || pa.modelType === 'ConfArticle'
		),
		companyId: framework.header.activeMarket,
		customerId: customers?.filter((item) => item.selected === true)[0]?.id,
		projectId: projects?.filter((item) => item.selected === true)[0]?.id,
		warehouseId: warehouses?.filter((item) => item.selected === true)[0]?.id,
		url: framework.api.priceStockDetailsUrl,
		desiredDeliveryDate: '',
		preventRequest: cartDisabled,
	});

	const priceInformation =
		priceResponse && priceResponse.articleList ? priceResponse.articleList : [];

	return (
		<main id="main-content">
			<Layout backgroundColor="white">
				<Breadcrumbs links={breadcrumbs} />

				{heading &&
					renderElementBasedOnEditMode(
						isInEditMode,
						'heading',
						'h1',
						{
							className:
								'font-standard mb-6 mt-8 font-normal text-h2 md:text-h1 text-blue',
						},
						heading
					)}

				{preamble &&
					renderElementBasedOnEditMode(
						isInEditMode,
						'preamble',
						Preamble,
						{ size: 'large' },
						preamble
					)}
				<CustomExternalScript />
				{!tabletView && (
					<Grid padding={false}>
						<Cell span={4}>
							<AutoComplete
								query={searchTerm}
								handleSearch={handleSearch}
								handleChange={handleAutocompleteChange}
								items={
									(autoCompleteResponse.length > 0 && autoCompleteResponse) ||
									[]
								}
								itemToString={(item) => (item ? item.name : '')}
								autoCompleteLoading={autoCompleteLoading}
							/>
						</Cell>
					</Grid>
				)}
				{!tabletView && (
					<SearchResultSummary
						query={state.query}
						pagination={paginationObject}
						suggestions={articlesArray.length === 0 && suggestionList}
						handleSuggestion={handleSuggestion}
						className="mt-4"
					/>
				)}
				<FilterBarWrapper>
					<FilterBar
						defaultDisplayOption={state.display}
						defaultSortByItem={state.group}
						onFilter={handleFilterRequest}
						displayOptions={[16, 32, 48]}
						sortByItems={filters.groups}
						sortLabel={t('shared/filter/filterby')}
						sortLabelMobile={t('shared/filter/mobilefilterby')}
						isFilter
					/>
				</FilterBarWrapper>

				<ArticleWrapper ref={articleWrapper}>
					{(hasFacets || tabletView) && (
						<FilterCol>
							{tabletView && (
								<>
									<AutoComplete
										query={searchTerm}
										handleSearch={handleSearch}
										handleChange={handleAutocompleteChange}
										items={
											(autoCompleteResponse.length > 0 &&
												autoCompleteResponse) ||
											[]
										}
										itemToString={(item) => (item ? item.name : '')}
									/>
									{tabletView && (
										<SearchResultSummary
											query={state.query}
											pagination={paginationObject}
											suggestions={articlesArray.length === 0 && suggestionList}
											handleSuggestion={handleSuggestion}
										/>
									)}
									{hasFacets && (
										<SlidersButton
											onClick={toggleMobileFilter}
											title={t('shared/filter/showfilter')}
										>
											<SlidersIcon />
										</SlidersButton>
									)}
								</>
							)}
							{hasFacets && (
								<form>
									{state.facets.map((fieldset, i) => (
										<React.Fragment key={i}>
											{fieldset.name && fieldset.items.length > 0 && (
												<FieldSet
													name={fieldset.name}
													items={fieldset.items}
													facetId={fieldset.id}
													key={fieldset.name}
													onChange={handleFacet}
													showFieldset={showFilter}
												/>
											)}
										</React.Fragment>
									))}
								</form>
							)}
						</FilterCol>
					)}
					<ArticleCol fullWidth={!hasFacets}>
						{hasFacets && anyActiveFacetFilter && !loading && (
							<>
								<ul className="p-0 inline-block">
									{state.facets.map((facet, i) => {
										return facet.items.map((facetItem, j) => {
											return facetItem.active ? (
												<FilterChip
													name={facet.name}
													item={facetItem}
													facetId={facet.id}
													onDelete={handleFacet}
													key={`${facetItem.name}-${i}-${j}`}
												/>
											) : null;
										});
									})}
								</ul>

								<ClearFilterButton as="button" onClick={() => clearFacets()}>
									{t('shared/filter/clearallfilters')}
								</ClearFilterButton>
							</>
						)}
						{!loading && error && (
							<div className="my-6 mx-0 text-center w-full">
								<Paragraph>{t('shared/filter/errormessage')}</Paragraph>
							</div>
						)}
						{loading && (
							<div className="my-6 mx-0 text-center w-full">
								<Icon icon="loader" animate="spin" size={2} />
							</div>
						)}
						{articlesArray && !error && !loading && articlesArray.length > 0
							? articlesArray.map((item, i) => (
									<React.Fragment key={`articleRow-${i}`}>
										{item.modelType === 'Article' ||
										item.modelType === 'ConfArticle' ? (
											<ArticleRow
												loginLink={framework.header.loginLink}
												activeMarket={framework.header.activeMarket}
												item={item}
												priceInfo={
													priceInformation &&
													priceInformation.find(
														(x) => x.itemCode === item.itemCode
													)
												}
												priceLoading={priceLoading}
												certificates={item?.certificates}
												openModal={
													item.modelType === 'Article'
														? setArticleModal
														: toggleConfModal
												}
												trackClick={handleTrackClick}
											/>
										) : item.modelType === 'LocationPage' ? (
											<LocationRow
												{...item}
												trackClick={handleTrackClick}
												query={state.query}
											/>
										) : (
											<ItemRow item={item} trackClick={handleTrackClick} />
										)}
									</React.Fragment>
							  ))
							: !error &&
							  !loading && (
									<div className="my-6 mx-0 text-center w-full">
										<Paragraph>{t('shared/filter/noresultmessage')}</Paragraph>
									</div>
							  )}
						<PagerWrapper
							nrOfPages={
								paginationObject.pageCount ? paginationObject.pageCount : 0
							}
							currentPage={
								paginationObject.currentPage ? paginationObject.currentPage : 0
							}
							onPageChange={handlePagination}
							total={!error && !loading ? paginationObject.total : 0}
							isMobile={isMobile}
						/>
					</ArticleCol>
				</ArticleWrapper>
			</Layout>
			<ContentArea contentArea={contentArea} />
			{articleModal.isActive && (
				<ArticleDetail
					onToggle={() => setArticleModal({ link: null, isActive: false })}
					articleApiUrl={articleModal.link}
				/>
			)}
			{confModalOpen && (
				<ArticleDetailConfiguration closeModal={toggleConfModal} />
			)}
		</main>
	);
};

export default SearchPage;
