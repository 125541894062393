/**
 * NewLocationRow
 */

import { useContext, useState } from 'react';
import { LocalizationContext } from 'context/localization.context';
import Tooltip from 'components/ui/Tooltip';
import { ContactModel, LocationRowModel } from 'types/common';
import RichText from 'components/ui/RichText';
import Icon from 'components/ui/Icon';
import { PhoneIcon } from '../LocationRow/LocationRow.styles';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import clsx from 'clsx';

const renderContacts = (items: ContactModel[]) => {
	if (!items || items?.length < 0) return;
	return items.map((contact: any, i: number) => {
		const { name, role, image, productArea, phone, email } = contact;
		return (
			<li
				id="contact-list"
				className={clsx(
					'w-full flex flex-col lg:flex-row mt-10 border-greyDark pb-5',
					i === items.length - 1 ? 'border-0' : 'border-b'
				)}
				key={i.toString()}
			>
				<div className="flex flex-1 mb-3">
					{image && (
						<img
							src={image.src}
							alt={image.alt}
							className="new-location-row-contact-img"
						/>
					)}
					<div className="flex flex-col">
						<h4 className="new-location-row-contact-heading">{name}</h4>
						<span className="new-location-row-contact-role">{role}</span>
						<span className="new-location-row-contact-productArea">
							{productArea}
						</span>
					</div>
				</div>
				<div className="flex flex-1 flex-col new-location-row-contact-phone-email">
					<span>
						<PhoneIcon aria-hidden color="#0075BF" /> {phone}
					</span>
					<span>
						<Icon icon="mail" color="#0075BF" /> {email}
					</span>
				</div>
			</li>
		);
	});
};

/** Main description for this component. */
const NewLocationRow = ({
	title,
	categories,
	city,
	contacts,
	highlightedContacts,
	email,
	emailTooltip,
	email2,
	email2Tooltip,
	mapLinkUrl,
	openingHours,
	phone,
	phoneTooltip,
	phone2,
	phone2Tooltip,
	phone3,
	phone3Tooltip,
	streetAddress,
	website,
	websiteTooltip,
	query,
	milkRun,
	hitId,
	...restProps
}: LocationRowModel) => {
	const { t }: any = useContext(LocalizationContext);
	const [nestedOpen, setNestedOpen] = useState(false);

	return (
		<article className="new-location-row w-full xl:w-768px ">
			{title && <h2 className="new-location-row-title">{title}</h2>}
			<div className="flex flex-col lg:flex-row justify-between mb-7">
				<div className="w-full lg:w-grid-4/12 mb-5 lg:mb-0">
					<div className="new-location-row-heading-block">
						<Icon
							icon="mapPin"
							size={1}
							className="new-location-row-icon-right"
							color="#0075BF"
						/>
						<h3 className="new-location-row-heading">{city}</h3>
					</div>
					<address className="not-italic" id="location-address">
						<RichText text={streetAddress} />
					</address>
					<a className="new-location-row-link" href={mapLinkUrl}>
						{t('contactpage/openmap')}{' '}
						<Icon
							icon="externalLink"
							size={1}
							className="new-location-row-icon-left"
							color="#0075BF"
						/>
					</a>
				</div>
				<div className="w-full lg:w-grid-4/12 mb-5 lg:mb-0">
					<div className="new-location-row-heading-block">
						<Icon
							icon="clock"
							size={1}
							className="new-location-row-icon-right"
							color="#0075BF"
						/>
						<h3 className="new-location-row-heading">
							{t('contactpage/oppetider')}
						</h3>
					</div>
					<RichText text={openingHours} />
				</div>
				<div className="w-full lg:w-grid-4/12 mb-5 lg:mb-0">
					<div className="new-location-row-heading-block">
						<PhoneIcon aria-hidden color="#0075BF" />
						<h3 className="new-location-row-heading">
							{t('contactpage/kontakt')}
						</h3>
					</div>
					<a href={`tel:${phone}`}>{phone}</a>
					{phoneTooltip && <Tooltip hover={true}>{phoneTooltip}</Tooltip>}
				</div>
			</div>
			{highlightedContacts && (
				<details className="new-location-row-details mb-2.5" open>
					<summary className="cursor-pointer list-none new-location-row-heading flex justify-between">
						{t('contactpage/showfilteredsellersandexperts')}
						{<FiChevronDown size={25} />}
					</summary>
					<ul className="">{renderContacts(highlightedContacts)}</ul>
					{contacts && (
						<details className="mb-2.5">
							<summary className="cursor-pointer list-none new-location-row-heading flex justify-between">
								{t('contactpage/showunfilteredsellersandexperts')}
								{nestedOpen ? (
									<FiChevronDown
										onClick={() => setNestedOpen(!nestedOpen)}
										size={25}
									/>
								) : (
									<FiChevronUp
										onClick={() => setNestedOpen(!nestedOpen)}
										size={25}
									/>
								)}
							</summary>
							<ul>{renderContacts(contacts)}</ul>
						</details>
					)}
				</details>
			)}

			{contacts && !highlightedContacts && (
				<details className="new-location-row-details mb-2.5">
					<summary className="cursor-pointer list-none new-location-row-heading flex justify-between">
						{t('contactpage/showsellersandexperts')}
						{<FiChevronDown size={25} />}
					</summary>
					<ul>{renderContacts(contacts)}</ul>
				</details>
			)}

			{milkRun && (
				<details className="new-location-row-details">
					<summary className="cursor-pointer list-none new-location-row-heading flex justify-between">
						{t('contactpage/showmilkrun')}
						{milkRun && <FiChevronDown size={25} />}
					</summary>

					<RichText text={milkRun} className="p-5" />
				</details>
			)}
		</article>
	);
};

export default NewLocationRow;
