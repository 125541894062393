import styled from 'styled-components';
import {
	FaFacebookF,
	FaTwitter,
	FaLinkedinIn,
	FaEnvelope,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { greyDarkest, greyDark, grey } from 'theme/colors';
import { spacing1x, spacing05x } from 'theme/spacings';

const IconCss = `
    color: ${greyDarkest};
    background-color: ${grey};
    font-size: 3.5rem;
    padding: ${spacing1x};
    margin-left: ${spacing05x};

    :hover {
        background-color: ${greyDark};
    }
`;

export const FacebookIcon = styled(FaFacebookF)`
	${IconCss};
`;

export const TwitterIcon = styled(FaXTwitter)`
	${IconCss};
`;

export const LinkedInIcon = styled(FaLinkedinIn)`
	${IconCss};
`;

export const EmailIcon = styled(FaEnvelope)`
	${IconCss};
`;
