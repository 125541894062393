/**
 * Typography
 * @module components/Typography
 */

import styled, { css } from 'styled-components';
import { deviceSmallMax } from 'theme/mediaQueries';
import {
	fontFamily,
	fontWeightNormal,
	fontWeightMedium,
	fontWeightSemiBold,
} from 'theme/typography';
import { blue } from 'theme/colors';

const paragraph = {
	desktop: {
		large: {
			fontSize: '1.6rem',
			lineHeight: '2.4rem',
		},
		default: {
			fontSize: '1.6rem',
			lineHeight: '2.4rem',
		},
		small: {
			fontSize: '1.4rem',
			lineHeight: '2.4rem',
		},
	},
	mobile: {
		large: {
			fontSize: '1.4rem',
			lineHeight: '2rem',
		},
		default: {
			fontSize: '1.4rem',
			lineHeight: '2rem',
		},
		small: {
			fontSize: '1.4rem',
			lineHeight: '2rem',
		},
	},
};

export const paragraphCss = css`
	font-family: ${fontFamily};
	font-size: ${({ size }) =>
		size
			? paragraph.desktop[size].fontSize
			: paragraph.desktop.default.fontSize};
	line-height: ${({ size }) =>
		size
			? paragraph.desktop[size].lineHeight
			: paragraph.desktop.default.lineHeight};
	margin-bottom: 1.2rem;
	margin-top: 1.6rem;
	font-weight: ${fontWeightNormal};
	@media (max-width: ${deviceSmallMax}) {
		font-size: ${({ size }) =>
			size
				? paragraph.mobile[size].fontSize
				: paragraph.mobile.default.fontSize};
		line-height: ${({ size }) =>
			size
				? paragraph.mobile[size].lineHeight
				: paragraph.mobile.default.lineHeight};
	}
`;

export const H1Css = css`
	color: ${blue};
	font-weight: ${fontWeightNormal};
	font-size: 4.8rem;
	line-height: 5.6rem;
	margin-bottom: 2rem;
	@media (max-width: ${deviceSmallMax}) {
		font-size: 3.2rem;
		line-height: 4rem;
		margin-bottom: 1.6rem;
	}
`;

export const H2Css = css`
	color: ${blue};
	font-weight: ${fontWeightNormal};
	font-size: 3.2rem;
	line-height: 4rem;
	margin-bottom: 1.8rem;
	@media (max-width: ${deviceSmallMax}) {
		font-size: 2.4rem;
		line-height: 3.2rem;
		margin-bottom: 1.6rem;
	}
`;
export const H3Css = css`
	color: ${blue};
	font-weight: ${fontWeightMedium};
	font-size: 2.4rem;
	line-height: 3.2rem;
	margin-bottom: 1.8rem;
	@media (max-width: ${deviceSmallMax}) {
		font-size: 1.8rem;
		line-height: 2.4rem;
		margin-bottom: 1.6rem;
	}
`;

export const H4Css = css`
	color: ${blue};
	font-weight: ${fontWeightMedium};
	font-size: 1.8rem;
	line-height: 2.4rem;
	margin-bottom: 1.6rem;
`;

export const H5Css = css`
	color: ${blue};
	font-weight: ${fontWeightMedium};
	font-size: 1.6rem;
	line-height: 2.4rem;
	margin-bottom: 1.6rem;
`;

export const H6Css = css`
	color: ${blue};
	font-weight: ${fontWeightSemiBold};
	font-size: 1.6rem;
	line-height: 2.4rem;
	margin-bottom: 1.6rem;
`;

export const Hero = styled.h1`
	font-weight: ${fontWeightSemiBold};
	font-size: 6.1rem;
	line-height: 7.2rem;
	margin-bottom: 3.2rem;
`;

export const H1 = styled.h1`
	${H1Css}
`;

export const H2 = styled.h2`
	${H2Css}
`;

export const H3 = styled.h3`
	${H3Css}
`;

export const H4 = styled.h4`
	${H4Css}
`;

export const H5 = styled.h5`
	${H5Css}
`;

export const H6 = styled.h6`
	${H6Css}
`;

export const Paragraph = styled.p`
	${paragraphCss}
`;

export const HtmlText = styled.div`
	p {
		${paragraphCss}
	}

	a {
		color: ${blue};
	}
`;
