import Axios from 'axios';

export const fetchAccount = async (url: string) => {
	if (process.env.NODE_ENV === 'development') {
		// if Biltema is selected
		//url = `http://localhost:4000/api/myaccount?customerCookie=55443`;

		// if XL-bygg is selected
		url = `http://localhost:4000/api/myaccount?customerCookie=12312`;
	}
	try {
		const result = await Axios({
			method: 'PATCH',
			url: url,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'content-type': 'application/json; charset=utf-8',
			},
		});
		return (result.data && result.data.columnList) || null;
	} catch (err) {
		console.error(err);
		return null;
	}
};
