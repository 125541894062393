/**
 * MyProjectsPage
 * @module pages/MyProjectsPage
 */

import React, { useMemo, useContext } from 'react';
import { LocalizationContext } from 'context/localization.context';
import { useTable, useSortBy } from 'react-table';
import {
	handleSortByKeyDown,
	handleKeyboardNavigation,
} from 'components/ui/Table';

import Breadcrumbs from 'components/framework/Breadcrumbs';
import ContentArea from 'components/framework/ContentArea';
import SectionHeader from 'components/ui/SectionHeader';
import Layout from 'components/ui/Layout';
import { Body, VisuallyHiddenCaption } from './MyProjectsPage.styles';

import {
	Table,
	TableHeadRow,
	TableHead,
	TableHeadContent,
	TableData,
	TableRow,
	SortWrapper,
	SortAscIcon,
	SortDscIcon,
} from 'components/ui/Table/table.styles';

const MyProjectsPage = ({
	heading,
	text,
	projects,
	contentArea,
	isInEditMode,
	breadcrumbs,
}) => {
	const { t } = useContext(LocalizationContext);

	const data = useMemo(() => projects, [projects]);
	const columns = useMemo(
		() => [
			{ Header: t('myprojectspage/customer'), accessor: 'organizationName' },
			{ Header: t('myprojectspage/project'), accessor: 'projectName' },
			{ Header: t('myprojectspage/projectId'), accessor: 'projectId' },
		],
		//eslint-disable-next-line
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable(
		{
			columns,
			data,
		},
		useSortBy
	);

	return (
		<main id="main-content">
			{breadcrumbs && (
				<Layout backgroundColor="white">
					<Breadcrumbs links={breadcrumbs} />
				</Layout>
			)}
			<SectionHeader
				heading={heading}
				text={text}
				size={'large'}
				isInEditMode={isInEditMode}
			/>
			<Layout childClassName="flex-1" backgroundColor="white">
				<Body>
					<Table {...getTableProps({ role: 'grid' })}>
						<VisuallyHiddenCaption>{heading}</VisuallyHiddenCaption>
						<TableHeadRow>
							{headerGroups.map((headerGroup) => (
								<TableHead {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column, i) => (
										<TableHeadContent
											{...column.getHeaderProps(
												column.getSortByToggleProps({
													title: `${t('shared/table/sortby')} ${column.Header}`,
													role: 'button',
													tabIndex: 0,
												})
											)}
											aria-sort={
												column.isSorted && !column.isSortedDesc
													? t('shared/table/ascending')
													: t('shared/table/descending')
											}
											data-header-col={i}
											onKeyDown={(e) => {
												if (column.canSort) {
													return handleSortByKeyDown(
														e,
														column,
														i,
														rows.length,
														columns.length
													);
												}
											}}
										>
											{column.render('Header')}
											<SortWrapper>
												<SortAscIcon
													isSorted={column.isSorted}
													isDescending={column.isSortedDesc}
												/>
												<SortDscIcon
													isSorted={column.isSorted}
													isDescending={column.isSortedDesc}
												/>
											</SortWrapper>
										</TableHeadContent>
									))}
								</TableHead>
							))}
						</TableHeadRow>
						<tbody {...getTableBodyProps()}>
							{rows.map((row, i) => {
								prepareRow(row);
								return (
									<TableRow {...row.getRowProps()}>
										{row.cells.map((cell, index) => {
											return (
												<td
													{...cell.getCellProps({
														tabIndex: i === 0 && index === 0 ? 0 : -1,
													})}
													onKeyDown={(e) => {
														return handleKeyboardNavigation(
															e,
															rows.length,
															columns.length,
															i
														);
													}}
													data-row={i}
													data-col={index}
												>
													<TableData>{cell.render('Cell')}</TableData>
												</td>
											);
										})}
									</TableRow>
								);
							})}
						</tbody>
					</Table>
				</Body>
			</Layout>
			<ContentArea contentArea={contentArea} />
		</main>
	);
};

export default MyProjectsPage;
