/* eslint-disable jsx-a11y/no-access-key */
/**
 * LogoHeader
 */

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { LocalizationContext } from 'context/localization.context';
import { ViewContext } from 'context/view.context';
import logotype from './logotype.svg';
import {
	Header,
	LogoLink,
	LogoImg,
	TopBar,
	LeftWrapper,
	MenuWrapper,
	LinkItem,
	CartWrapper,
	CartWrapperMobile,
	MobileMenuWrapper,
	MobileMegaMenuWrapper,
	HeaderLayout,
	MegaMenuLayout,
} from 'components/framework/PageHeader/PageHeader.styles';

import { MegaMenu } from 'components/common/MegaMenu';
import Cart from 'components/ui/Cart';
import MobileMenu from 'components/framework/MobileMenu';
import MobileMegaMenu from 'components/common/MobileMegaMenu';

import MyPagesMenuDropdown from 'components/ui/MyPagesMenuDropdown';
import { useSelector } from 'react-redux';
import { selectCartObject } from 'store/modules/cart';

import useWindowSize from 'hooks/useWindowSize';

const LogoHeader = ({ header, api }) => {
	const { t } = useContext(LocalizationContext);
	const { view } = useContext(ViewContext);
	const { cartDisabled } = useSelector(selectCartObject);
	const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);
	const isMobile = useWindowSize(1150);
	const isSmallMobile = useWindowSize(768);

	const { logo, megaMenu, cart, myPagesLink, loginLink, logoutLink } = header;

	return (
		<Header>
			<a
				href="#main-content"
				accessKey="s"
				className="is-visuallyhidden focusable"
			>
				{t('shared/header/jumptomaincontent')}
			</a>

			<HeaderLayout backgroundColor="white">
				<TopBar>
					<LeftWrapper>
						<LogoLink href={logo.link} title={t('shared/header/gotostartpage')}>
							<LogoImg
								src={header.logoSrc ?? logotype}
								alt={header.logoAlt ?? logo.alt ? logo.alt : ''}
							/>
						</LogoLink>

						{!cartDisabled && (
							<CartWrapperMobile
								style={{
									display:
										(isMobile || view === 'mobile') && view !== 'desktop'
											? 'inline-flex'
											: 'none',
									top:
										(isMobile || view === 'mobile') && view !== 'desktop'
											? '3.5rem'
											: isSmallMobile
											? '0.4rem'
											: '0',
									right:
										(isMobile || view === 'mobile') && view !== 'desktop'
											? '8rem'
											: isSmallMobile
											? '4.2rem'
											: '0',
								}}
							>
								<Cart
									link={cart && cart.link && cart.link.link}
									target={cart && cart.link && cart.link.target}
									isActive
								/>
							</CartWrapperMobile>
						)}

						<MobileMenuWrapper
							style={{
								display:
									(isMobile || view === 'mobile') && view !== 'desktop'
										? 'inline-flex'
										: 'none',
								top:
									(isMobile || view === 'mobile') && view !== 'desktop'
										? '3.5rem'
										: '0.4rem',
								right:
									(isMobile || view === 'mobile') && view !== 'desktop'
										? '2.4rem'
										: '0',
							}}
						>
							<MobileMenu onChange={setMobileMenuExpanded} />
						</MobileMenuWrapper>

						{megaMenu && megaMenu.items && megaMenu.items.length > 0 && (
							<MobileMegaMenuWrapper
								style={{
									display:
										(isMobile || view === 'mobile') && view !== 'desktop'
											? 'block'
											: 'none',
								}}
								expanded={mobileMenuExpanded}
								noSearch={true}
							>
								<MobileMegaMenu
									items={megaMenu}
									loginLink={loginLink}
									logoutLink={logoutLink}
									myPagesLink={myPagesLink}
									expanded={
										mobileMenuExpanded &&
										(isMobile || view === 'mobile') &&
										view !== 'desktop'
									}
								/>
							</MobileMegaMenuWrapper>
						)}
					</LeftWrapper>
				</TopBar>
			</HeaderLayout>

			<MegaMenuLayout
				style={{
					display:
						(isMobile || view === 'mobile') && view !== 'desktop'
							? 'none'
							: 'block',
				}}
			>
				<MenuWrapper
					style={{
						display:
							(isMobile || view === 'mobile') && view !== 'desktop'
								? 'none'
								: 'flex',
					}}
				>
					<MegaMenu menuItems={megaMenu.items} />
					{!cartDisabled ? (
						<>
							<MyPagesMenuDropdown
								myPagesLink={myPagesLink}
								logoutLink={logoutLink}
							/>
							<CartWrapper>
								<Cart
									link={cart && cart.link && cart.link.link}
									target={cart && cart.link && cart.link.target}
									isActive
								/>
							</CartWrapper>
						</>
					) : (
						<>
							<LinkItem href={loginLink}>{t('shared/header/login')}</LinkItem>
							<CartWrapper>
								<Cart
									link={cart && cart.link && cart.link.link}
									target={cart && cart.link && cart.link.target}
								/>
							</CartWrapper>
						</>
					)}
				</MenuWrapper>
			</MegaMenuLayout>
		</Header>
	);
};

LogoHeader.propTypes = {
	header: PropTypes.shape({
		logo: PropTypes.shape({
			link: PropTypes.string,
			alt: PropTypes.string,
		}),
		logoSrc: PropTypes.string,
		logoAlt: PropTypes.string,
		megaMenu: PropTypes.shape({
			items: PropTypes.arrayOf(
				PropTypes.shape({
					link: PropTypes.string,
					text: PropTypes.string,
					active: PropTypes.bool,
					children: PropTypes.array,
				})
			),
		}),
		loginLink: PropTypes.string,
		logoutLink: PropTypes.string,
		myPagesLink: PropTypes.string,
		cart: PropTypes.shape({
			items: PropTypes.number,
		}),
	}),
	api: PropTypes.shape({
		projectsUrl: PropTypes.string,
	}),
};

export default LogoHeader;
