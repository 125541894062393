import { ArticleModel } from './article';

export interface LinkModel {
	imageGallery?: any;
	text: string;
	link: string;
	name?: string;
	target?: string;
	linkType?: 'Document' | 'Arrow';
	color?: string;
}

export interface CertificatesItem {
	icon: string;
	link: string;
	text: string;
}

export interface ExtendedLinkModel extends LinkModel {
	active?: boolean;
	id?: number;
	isLink?: boolean;
}

export type ImageType = 'Image' | 'Video';

export interface ImageModel {
	src?: string;
	alt?: string;
	sizes?: string[];
	webpSizes?: string[];
	thumbnail?: string;
	type?: ImageType;
	isMain?: boolean;
	text?: string;
}

export interface VideoModel {
	link: string;
	autoplay: boolean;
}

export interface MediaModel {
	video?: VideoModel;
	videoPreference?: VideoPreferenceTypes;
	type?: string;
	image?: ImageModel;
	imageLink?: string;
	imageLinkTarget?: string;
	imagePreference?: ImagePreferenceTypes;
	videoPosterImage?: string;
	imageGallery?: any;
}

export interface MediaGalleryItemModel {
	type: 'Image' | 'Video' | '3DConfig';
	isMain?: boolean;
	src: string;
	id?: string;
	url?: string;
	alt?: string;
	sizes: string[];
	keyValues?: Array<Record<string, string>>;
	title?: string;
	webpSizes: string[];
	thumbnail?: string;
}

export type BackgroundColorTypes =
	| 'blue'
	| 'blueLight'
	| 'white'
	| 'greyLight'
	| 'orange'
	| 'transparent';

export type ImagePreferenceTypes =
	| 'imageLeft'
	| 'imageRight'
	| 'imageLeftOutsideBlock'
	| 'imageRightOutsideBlock'
	| 'fullWidthBackgroundImage'
	| 'fullWidthBackgroundImageFullHeight';

export type VideoPreferenceTypes = 'videoLeft' | 'videoRight' | 'videoFull';

export interface BreadCrumbsModel {
	link: string;
	text: string;
}

export interface PaginationModel {
	total?: number;
	display?: number;
	currentPage?: number;
	pageCount?: number;
}

export interface SearchDocumentModel {
	name: string;
	entryName: string;
	type: string;
	size: string;
	format: string;
	downloadLink: string;
}

export interface DocumentModel {
	text: string;
	link: string;
	type: 'document' | 'download' | 'external-link' | 'internal-link' | 'video';
}

export interface ErrorObjectModel {
	errorCode: number;
	message: string;
}

export enum HighlightColor {
	orange = 'orange',
	blue = 'blue',
}

export interface Hightlight {
	text: string;
	color: HighlightColor;
}

export type ButtonColorTypes =
	| 'blue'
	| 'orange'
	| 'green'
	| 'white'
	| 'grey'
	| 'greyDark'
	| 'greyDarker'
	| 'greyDarkest';

export interface ContactModel {
	name: string;
	role: string;
	email: string;
	phone: string;
	image: ImageModel;
	productAreaList: string;
}

export interface CategoryModel {
	region: string;
	city: string;
	locationType: string;
}

export type AjaxMethodTypes = 'POST' | 'GET' | 'PATCH' | 'DELETE';

export interface NotesModel {
	goodsMarking: string;
	comments: string;
}

export interface CustomerModel {
	id: string;
	externId: string;
	name: string;
	selected: boolean;
}

export interface WarehouseModel {
	id: string;
	externId: string;
	name: string;
	selected: boolean;
}

export interface ProjectModel {
	id: string;
	name: string;
	selected: boolean;
}

export interface DeliveryAddressModel {
	addressId: string;
	name: string;
	countryName: string;
	countryCode: string;
	city: string;
	postalCode: string;
	street: string;
	organizationId: string;
	isEditable: boolean;
	selected: boolean;
}

export interface UserObjectModel {
	customers: CustomerModel[];
	projects?: ProjectModel[];
	warehouses?: WarehouseModel[];
	salesUser?: boolean;
	types?: UserTypeModel[];
}

export interface UserTypeModel {
	id: string;
	extenalId: string;
	name: string;
	selected: boolean;
}

export interface ShipmentModel {
	shipDate?: string;
	shipDateFormatted?: string;
	shipDateChanged?: boolean;
	articleList: ArticleModel[];
}

export interface SingleShipmentModel {
	selectedSort?: string;
	selectedDeliveryMethod?: string;
	selectedLocation?: string;
	selectedDesiredDate?: string;
	singleShipment?: boolean;
}

export interface AddNotesModel {
	itemCode: string;
	lineItemId: number;
	goodsMarking?: string;
	comments?: string;
}

export interface CheckoutReqestDataModel {
	address: {
		addressId: string;
		name: string;
		countryName: string;
		countryCode: string;
		city: string;
		postalCode: string;
		street: string;
		organizationId: string;
		save?: boolean;
	};
	goodsReceptionContactName: string;
	goodsReceptionPhoneNumber: string;
	responsiblePersonId: string;
	customerRequisition: string;
	projectName: string;
	projectNumber: string;
	comment: string;
}

export interface LocationRowModel {
	categories: CategoryModel;
	city: string;
	contacts: ContactModel[];
	highlightedContacts: ContactModel[];
	email: string;
	emailTooltip?: string;
	email2?: string;
	email2Tooltip?: string;
	mapImageUrl: string;
	mapLinkUrl: string;
	openingHours: string;
	phone: string;
	phoneTooltip?: string;
	phone2?: string;
	phone2Tooltip?: string;
	phone3?: string;
	phone3Tooltip?: string;
	streetAddress: string;
	title: string;
	trackClick: (hitId: string, trackId: string) => void;
	website: string;
	websiteTooltip?: string;
	query: string;
	milkRun: string;
	hitId: string;
	trackId: string;
}

export interface SpecificationsModel {
	dictionary: DictionaryModel;
	colors?: Array<{
		colorName: string;
		ncs: string;
		ral: string;
		image: {
			src: string;
			alt: string;
		};
	}>;
}

export interface DictionaryModel {
	[key: string]: string;
}

export interface FieldChangeModel {
	name: string;
	value: string;
	type?: string;
	autoSubmit: boolean;
}

export interface FieldGeneralPropsModel {
	required?: boolean;
	label?: string;
	id: string;
	type?: string;
	validationMessage?: string;
	multiple?: string | boolean;
}

export interface FieldPropsModel extends FieldGeneralPropsModel {
	pattern?: string;
	patternMessage?: string;
	single?: boolean;
	min?: number;
	max?: number;
	rangeMessage?: string;
}

export interface ConfSelectModel {
	name: string;
	id: string;
	type: 'select';
	value: string;
	options: Array<{
		name: string;
		value: string;
	}>;
	disabled: boolean;
}

export interface ConfNumberModel {
	name: string;
	id: string;
	type: 'number';
	value: string;
	minValue: string;
	maxValue: string;
	disabled: boolean;
}

export interface ConfTextModel {
	name: string;
	id: string;
	type: 'text';
	value: string;
	disabled?: boolean;
}

export interface MegaMenuModel {
	items: MegaMenuItemModel[];
}

export interface MegaMenuItemModel {
	link: string;
	text: string;
	active: boolean;
	isLink: boolean;
	isBlock?: boolean;
	target?: string;
	children?: MegaMenuItemModel[];
}

export enum FilterFacets {
	REGION = 'region',
	LOCATION_TYPE = 'locationType',
}

export enum ContactPageTheme {
	VERSION_1 = 'v1',
	VERSION_2 = 'v2',
}
