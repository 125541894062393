import { FilterState, FilterStateAction } from 'types/filter-types';

export const listFilterReducer = (
	state: FilterState,
	action: FilterStateAction
) => {
	switch (action.type) {
		case 'SET_STATE':
			return action.payload;
		case 'SORT_BY':
			return {
				...state,
				sortBy: action.sortBy,
				sortDir: action.sortDir,
				currentPage: 1,
			} as FilterState;
		case 'DISPLAY':
			return {
				...state,
				display: action.display,
				currentPage: 1,
			} as FilterState;
		case 'CURRENT_PAGE':
			return { ...state, currentPage: action.currentPage } as FilterState;
		case 'QUERY':
			return { ...state, query: action.query, currentPage: 1 } as FilterState;
		case 'FACETS':
			return action.currentPage
				? ({
						...state,
						facets: action.facets,
						currentPage: action.currentPage,
				  } as FilterState)
				: ({ ...state, facets: action.facets } as FilterState);
		case 'GROUP':
			return { ...state, group: action.group } as FilterState;
		case 'ARTICLE':
			return { ...state, article: action.article } as FilterState;

		default:
			throw new Error();
	}
};

export default listFilterReducer;
